<div class="table-responsive table-hover">
  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Template Name</th>
        <th>Description</th>
        <th>Value</th>
        <th>Active</th>
      </tr>
    </thead>
    <tbody>
      <ng-template [ngIf]="tableData?.length > 0 && !loading">
        <tr *ngFor="let item of tableData">
          <td>
            <app-mail-templates-variables-create
              [variable]="item"
              (saved)="onUpdated($event)"
            ></app-mail-templates-variables-create>
          </td>
          <td>
            <mark *ngIf="item?.name_template">{{
              '\{\{' + item?.name_template + '\}\}'
            }}</mark>
          </td>
          <td>{{ item?.description }}</td>
          <td>{{ item?.value }}</td>
          <td>
            <app-mail-templates-activation
              [id]="item?.id"
              [enabled]="item?.enabled"
              [type]="type"
              (activated)="onUpdated($event)"
            ></app-mail-templates-activation>
          </td>
        </tr>
      </ng-template>
      <ng-template [ngIf]="!tableData?.length && !loading">
        <tr class="without_hover">
          <td colspan="3">
            <p class="py-5 mb-0">Nothing Found</p>
          </td>
        </tr>
      </ng-template>
      <tr *ngIf="loading" class="without_hover">
        <td colspan="3">
          <p class="py-5 mb-0">
            <img
              width="30px"
              height="30px"
              src="assets/img/backoffice/loading.gif"
            />
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
