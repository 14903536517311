<div *ngIf="isLoading" class="text-center">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading">
    <div class="d-flex justify-content-between mb-3">
        <strong>Personal ID front</strong>
        <button
            *ngIf="docs?.personalid_front_face"
            (click)="open(content); view('personalid_front_face')"
            class="btn btn-accept"
        >
            View File
        </button>
        <div *ngIf="!docs?.personalid_front_face">No photo</div>
    </div>

    <div class="d-flex justify-content-between mb-3">
        <strong>Personal ID back</strong>
        <button
            *ngIf="docs?.personalid_back_face"
            (click)="open(content); view('personalid_back_face')"
            class="btn btn-accept"
        >
            View File
        </button>
        <div *ngIf="!docs?.personalid_back_face">No photo</div>
    </div>

    <div class="d-flex justify-content-between mb-3">
        <strong>Proof of address</strong>
        <button
            *ngIf="docs?.document_confirming_address"
            (click)="open(content); view('document_confirming_address')"
            class="btn btn-accept"
        >
            View File
        </button>
        <div *ngIf="!docs?.document_confirming_address">No photo</div>
    </div>

    <div class="d-flex justify-content-between mb-3">
        <strong>Selfie</strong>
        <button
            *ngIf="docs?.credit_card_selfie"
            (click)="open(content); view('credit_card_selfie')"
            class="btn btn-accept"
        >
            View File
        </button>
        <div *ngIf="!docs?.credit_card_selfie">No photo</div>
    </div>

    <div class="d-flex justify-content-between mb-3">
        <strong>Additional document</strong>
        <button
            *ngIf="docs?.additional_document"
            (click)="open(content); view('additional_document')"
            class="btn btn-accept"
        >
            View File
        </button>
        <div *ngIf="!docs?.additional_document">No photo</div>
    </div>

    <hr />
    <div class="d-flex justify-content-between align-items-center">
        <div *ngIf="euform" class="btn-group w-100 pr-3">
            <button
                (click)="openLoanDocs(loanDocs); viewEuform()"
                type="button"
                class="btn btn-accept"
            >
                EU FORM
            </button>
            <button
                type="button"
                class="btn btn-secondary"
                (click)="downloadEuform($event)"
            >
                <i class="fa fa-download"></i>
            </button>
        </div>
        <div *ngIf="!euform" class="w-100 pr-3">EU Form Not Found</div>

        <div *ngIf="contract" class="btn-group w-100 float-xl-right pl-3">
            <button
                (click)="openLoanDocs(loanDocs); viewContract()"
                type="button"
                class="btn btn-accept"
            >
                CONTRACT
            </button>
            <button
                type="button"
                class="btn btn-secondary"
                (click)="downloadContract($event)"
            >
                <i class="fa fa-download"></i>
            </button>
        </div>
        <div *ngIf="!contract" class="text-right w-100 pl-3">
            Contract Not Found
        </div>
    </div>
</div>

<!-- Modal Personal Docs -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body-lg">
        <img
            *ngIf="isLoadingPersonalDoc"
            class="loading-img"
            width="50px"
            height="50px"
            src="assets/img/backoffice/loading.gif"
        />
        <ngx-imageviewer
            class="angImageViewer"
            *ngIf="image && !isLoadingPersonalDoc"
            [src]="image"
            [width]="canvasWidth"
            [height]="canvasHeight"
        ></ngx-imageviewer>

        <div class="btn-group viewer-btn-group">
            <button class="btn btn-primary btn-sm" (click)="openImage()">
                Open in New Tab <i class="fa fa-external-link"></i>
            </button>
            <button class="btn btn-primary btn-sm" (click)="donwloadImage()">
                Download <i class="fa fa-download"></i>
            </button>
        </div>
    </div>
</ng-template>

<!-- Modal Loan Docs -->
<ng-template #loanDocs let-c="close" let-d="dismiss">
    <div class="modal-body-lg">
        <img
            *ngIf="loading_docs"
            width="50px"
            height="50px"
            src="assets/img/backoffice/loading.gif"
        />
        <pdf-viewer
            *ngIf="!isLoadingDocs"
            [src]="url"
            [render-text]="true"
            [render-text]="true"
            [external-link-target]="'self'"
            [autoresize]="true"
            [original-size]="true"
            style="display: block"
        ></pdf-viewer>
    </div>
</ng-template>
