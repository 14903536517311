<button href="#0" class="btn btn-primary" (click)="open(affiliate)">
    Add New +
</button>

<ng-template #affiliate let-c="close">
    <div class="modal-header">
        <h4 class="modal-title">Create Affiliate</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <h4>Information</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Affiliate Name"
                            type="text"
                            name="name"
                            formControlName="name"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="VAT Number(CIF)"
                            type="text"
                            name="information_cif"
                            formControlName="information_cif"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Address"
                            type="text"
                            name="information_address"
                            formControlName="information_address"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Zip Code"
                            type="text"
                            name="information_zip_code"
                            formControlName="information_zip_code"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="City"
                            type="text"
                            name="information_city"
                            formControlName="information_city"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Country"
                            type="text"
                            name="information_country"
                            formControlName="information_country"
                        />
                    </div>
                </div>
            </div>

            <h4>Contacts</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Name"
                            type="text"
                            name="contact_one_name"
                            formControlName="contact_one_name"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Phone Mobile"
                            type="tel"
                            name="contact_one_mobile_phone"
                            formControlName="contact_one_mobile_phone"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Email"
                            type="email"
                            name="contact_one_email"
                            formControlName="contact_one_email"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Role"
                            type="text"
                            name="contact_one_role"
                            formControlName="contact_one_role"
                            required
                        />
                    </div>
                </div>

                <ng-template [ngIf]="contactIndex > 0">
                    <div class="col-12"><hr class="mt-0" /></div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Name"
                                type="text"
                                name="contact_two_name"
                                formControlName="contact_two_name"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Phone Mobile"
                                type="tel"
                                name="contact_two_mobile_phone"
                                formControlName="contact_two_mobile_phone"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Email"
                                type="email"
                                name="contact_two_email"
                                formControlName="contact_two_email"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Role"
                                type="text"
                                name="contact_two_role"
                                formControlName="contact_two_role"
                            />
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="contactIndex > 1">
                    <div class="col-12"><hr class="mt-0" /></div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Name"
                                type="text"
                                name="contact_three_name"
                                formControlName="contact_three_name"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Phone Mobile"
                                type="tel"
                                name="contact_three_mobile_phone"
                                formControlName="contact_three_mobile_phone"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                placeholder="Email"
                                type="email"
                                name="contact_three_email"
                                formControlName="contact_three_email"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-0">
                            <input
                                class="form-control"
                                placeholder="Role"
                                type="text"
                                name="contact_three_role"
                                formControlName="contact_three_role"
                            />
                        </div>
                    </div>
                </ng-template>
                <div class="col-6 form-group" *ngIf="contactIndex < 2">
                    <a
                        href="#"
                        class="link--dotted"
                        (click)="addContact($event)"
                        >Add сontact +</a
                    >
                </div>
            </div>

            <h4>Terms</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Lead Condition"
                            type="text"
                            name="term_lead_condition"
                            formControlName="term_lead_condition"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Value"
                            type="number"
                            name="term_value"
                            formControlName="term_value"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Payment Terms"
                            type="text"
                            name="term_payment"
                            formControlName="term_payment"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Statistics Email"
                            type="email"
                            name="term_statistic_email"
                            formControlName="term_statistic_email"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            class="form-control"
                            placeholder="Rate"
                            type="number"
                            name="rate"
                            formControlName="rate"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="api"
                                name="api"
                                formControlName="api"
                            />
                            <label class="custom-control-label" for="api"
                                >Api</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="pixel"
                                name="pixel"
                                formControlName="pixel"
                            />
                            <label class="custom-control-label" for="pixel"
                                >Pixel</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="enabled"
                                name="enabled"
                                formControlName="enabled"
                            />
                            <label class="custom-control-label" for="enabled"
                                >Active</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            [disabled]="form.invalid || sending"
            class="btn btn-primary"
            (click)="submit(form)"
        >
            {{ sending ? 'Sending...' : 'Create' }}
        </button>
    </div>
</ng-template>
