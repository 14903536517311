<div style="margin-left: 3vw" *ngIf="lead || creditLine">
    <a
        [routerLink]="messageUrl"
        class="btn btn-primary"
        style="
            margin-right: 10px;
            border-radius: 50%;
            width: 40px;
            height: 40px;
        "
        ><i class="fa fa-comments"></i
    ></a>
    <a (click)="open(sms, 'sms')" style="margin-right: 10px"
        ><img src="assets/img/backoffice/sms.svg" width="40" height="40"
    /></a>
    <a (click)="open(sms, 'email')" style="margin-right: 10px"
        ><img src="assets/img/backoffice/mail.svg" width="40" height="40"
    /></a>
    <a *ngIf="lead" (click)="open(content, 'tracking')"
        ><img src="assets/img/backoffice/x.svg" width="40" height="40"
    /></a>
</div>

<ng-template #sms let-c="close" let-d="dismiss">
    <div class="modal-header" *ngIf="lead || creditLine">
        <h4 class="modal-title" id="modal-basic-title">
            {{ openModal | uppercase }}
            <small class="text-primary">{{
                lead?.code_str ? lead?.code_str : creditLine?.code_str
            }}</small>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form
            [formGroup]="formModel[openModal].form"
            (ngSubmit)="formSubmit(openModal)"
            novalidate
        >
            <div class="form-group">
                <select
                    class="form-control"
                    name="type"
                    formControlName="type"
                    [ngClass]="{
                        'is-invalid':
                            (formModel[openModal].form.controls.type.invalid &&
                                formModel[openModal].form.controls.type
                                    .touched) ||
                            (formModel[openModal].form.controls.type.errors
                                ?.required &&
                                formModel[openModal].form.controls.type.touched)
                    }"
                    required
                >
                    <option value="" disabled>
                        {{ formModel[openModal].placeholders.type }}
                    </option>
                    <option
                        *ngFor="let template of formModel[openModal].templates"
                        [value]="template.id"
                    >
                        {{ template.description }}
                    </option>
                </select>
                <div class="invalid-feedback">
                    {{ formModel[openModal].errors.type }}
                </div>
            </div>
            <div class="form-group">
                <textarea
                    name="text"
                    formControlName="text"
                    rows="5"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid':
                            (formModel[openModal].form.controls.text.invalid &&
                                formModel[openModal].form.controls.text
                                    .touched) ||
                            (formModel[openModal].form.controls.text.errors
                                ?.required &&
                                formModel[openModal].form.controls.text.touched)
                    }"
                    [placeholder]="formModel[openModal].placeholders.text"
                    [maxlength]="formModel[openModal].maxlength"
                    required
                ></textarea>
                <div class="invalid-feedback">
                    {{ formModel[openModal].errors.text }}
                </div>
            </div>
            <div class="text-right">
                <button
                    type="submit"
                    [disabled]="formModel[openModal].form.invalid"
                    class="btn btn-primary"
                >
                    Send {{ openModal }}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header" *ngIf="lead">
        <h4 class="modal-title" id="modal-basic-title">Tracking</h4>
        <button type="button" class="close" aria-label="Close" (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img
            *ngIf="loading"
            width="50px"
            height="50px"
            src="assets/img/backoffice/loading.gif"
        />

        <!-- LEAD   !-->
        <div *ngIf="lead">
            <div *ngIf="trackings?.data?.length > 0">
                <div>
                    Displaying {{ trackings?.from ? trackings?.from : '00' }}-{{
                        trackings?.to ? trackings?.to : '00'
                    }}
                    of {{ trackings?.total ? trackings?.total : '00' }} results
                </div>
                <app-pager
                    [perPage]="15"
                    [currentPage]="trackings?.current_page"
                    [total]="trackings?.total"
                    (changePage)="getTrackingLead($event)"
                >
                </app-pager>
                <table width="100%" class="table table-responsive">
                    <thead>
                        <tr>
                            <th width="9%">Created</th>
                            <th width="9%">Type</th>
                            <th width="9%">Comment</th>
                            <th width="9%">Created By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let track of trackings?.data">
                            <td width="9%">{{ track?.created_at }}</td>
                            <td width="9%">{{ track?.type?.name }}</td>
                            <td width="9%">{{ track?.comment }}</td>
                            <td width="9%">{{ track?.created_by?.name }}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    Displaying {{ trackings?.from ? trackings?.from : '00' }}-{{
                        trackings?.to ? trackings?.to : '00'
                    }}
                    of {{ trackings?.total ? trackings?.total : '00' }} results
                </div>
                <app-pager
                    [perPage]="15"
                    [currentPage]="trackings?.current_page"
                    [total]="trackings?.total"
                    (changePage)="getTrackingLead($event)"
                >
                </app-pager>
            </div>
            <div *ngIf="!trackings?.data?.length" class="text-center">
                No entries
            </div>
        </div>
    </div>
</ng-template>
