import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {}

    getList(status, params): Observable<any> {
        return this.http.get(
            environment.api_url + `/user/${status}/list?${params}`
        );
    }

    getFilters(filterType): Observable<any> {
        return this.http.get(environment.api_url + filterType.link);
    }

    getAsnefList(params): Observable<any> {
        return this.http.get(
            environment.api_url + `/asnef/file-records?${params}`
        );
    }

    getAsnefCurrentStatus(loan_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/asnef/file-records/loan/${loan_id}/show`
        );
    }

    getAsnefStatuses(): Observable<any> {
        return this.http.get(environment.api_url + '/asnef/status/choices');
    }

    getAsnefExludeReasons(): Observable<any> {
        return this.http.get(
            environment.api_url + '/asnef/exclude-reasons/choices'
        );
    }

    exludeFromAsnef(record_id, reason_id): Observable<any> {
        return this.http.post(
            environment.api_url + `/asnef/file-records/${record_id}/exclude`,
            {
                reason_id,
            }
        );
    }

    getUserAsnefHistory(user_id): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/asnef/file-records/status/historys?user_id=${user_id}`
        );
    }

    addToBlacklist(reason_id = '', user_id: number): Observable<any> {
        return this.http.post(environment.api_url + `/blacklist/add`, {
            reason_id,
            user_id,
        });
    }

    addToDropOut(user_id: number): Promise<any> {
        return this.http
            .post(environment.api_url + `/dropout/add`, { user_id })
            .toPromise();
    }

    removeFromBlacklist(user_id: number): Observable<any> {
        return this.http.post(environment.api_url + `/blacklist/delete`, {
            user_id,
        });
    }

    getBlacklistReasonsList(): Promise<any> {
        return this.http
            .get(environment.api_url + `/blacklist/reason/list`)
            .toPromise();
    }

    getBlacklistHistory(user_id): Promise<any> {
        return this.http
            .get(environment.api_url + `/blacklist/${user_id}/history/list`)
            .toPromise();
    }
}
