import { Component, OnInit, Input } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { PaymentBankAccountService } from '../../../services/payment-bank-account.service';

const COMMERTIAL = { id: 1, name: 'Commercial' };
const TRIAL_DECISION = { id: 2, name: 'Trial Decision' };
const FRAUD = { id: 3, name: 'Fraud' };
@Component({
    selector: 'app-loan-rectification',
    templateUrl: './loan-rectification.component.html',
    styleUrls: ['./loan-rectification.component.scss'],
})
export class LoanRectificationComponent implements OnInit {
    @Input() loan_id: any = null;

    modalReference: NgbModalRef;

    type = '';
    reason = '';

    types = {
        cancellation: {
            name: 'Contract Cancellation',
            reasones: [],
        },
        nullification: {
            name: 'Contract Nullification',
            reasones: [],
        },
        discount: {
            name: 'Collection Discount',
            reasones: [COMMERTIAL],
        },
        lost: {
            name: 'Due Discount',
            reasones: [COMMERTIAL, TRIAL_DECISION, FRAUD],
        },
    };

    isSubmiting = false;

    constructor(
        private modalService: NgbModal,
        private errorMessageService: ErrorMessageService,
        private paymentBankAccount: PaymentBankAccountService
    ) {}

    ngOnInit() {}

    open(content) {
        this.modalReference = this.modalService.open(content, { size: 'lg' });

        this.modalReference.result.then(
            (result) => {
                this.resetData();
            },
            (reason) => {
                this.resetData();
            }
        );
    }

    resetData() {
        this.type = '';
        this.reason = '';
    }

    onTypeChange() {
        this.reason = '';
    }

    onSubmit() {
        this.isSubmiting = true;

        this.paymentBankAccount
            .loanResolution(this.type, this.loan_id, this.reason)
            .toPromise()
            .then((response) => {
                if (response) {
                    setTimeout(
                        function (self) {
                            self.errorMessageService.showSuccess(
                                'Loan Rectification succesfully!'
                            );
                            self.isSubmiting = false;
                            self.modalReference.close(true);
                        },
                        600,
                        this
                    );
                }
            })
            .catch((error) => {
                if (error.error.error) {
                    setTimeout(
                        function (self) {
                            self.isSubmiting = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                }
            });
    }
}
