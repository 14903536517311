import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { PaymentTreeService } from '../../../../services/payment-tree.service';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-tree-node-list',
  templateUrl: './payment-tree-node-list.component.html',
  styleUrls: ['./payment-tree-node-list.component.scss'],
})
export class PaymentTreeNodeListComponent implements OnInit, OnDestroy {
  routerSubscribe: ISubscription;
  dataSubscribe: ISubscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  nodes = [];
  treeId = null;
  deletedId: number;
  error = true;

  constructor(
    private paymentTreeService: PaymentTreeService,
    private debtrecovery: DebtRecoveryService,
    private router: Router
  ) {}

  ngOnInit() {
    this.routerSubscribe = this.paymentTreeService.active$.subscribe((resp) => {
      if (resp) {
        this.treeId = resp;
        this.dataSubscribe = this.debtrecovery
          .getPaymentTreeAmountNodes(this.treeId)
          .subscribe(
            (nodes) => {
              this.nodes = nodes;
              this.error = false;
            },
            () => {
              this.error = true;
              this.router.navigate(['/backoffice/settings/payment-tree/']);
            }
          );
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscribe.unsubscribe();
    this.dataSubscribe.unsubscribe();
  }

  onCreate(e) {
    if (e) {
      this.nodes.unshift(e);
    }
  }

  onUpdate(e) {
    if (e.id) {
      const index = this.nodes.findIndex(function (node) {
        return node.id === e.id;
      });

      if (index !== -1) {
        this.nodes[index] = e;
      }
    }
  }

  onDelete(e: number) {
    if (e) {
      const index = this.nodes.findIndex(function (node) {
        return node.id === e;
      });

      if (index !== -1) {
        this.deletedId = e;
        this.nodes.splice(index, 1);
      }
    }
  }
}
