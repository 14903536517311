import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-setting-create-permission',
  templateUrl: './setting-create-permission.component.html',
  styleUrls: ['./setting-create-permission.component.scss']
})
export class SettingCreatePermissionComponent implements OnInit {

  permissionForm: FormGroup;
  constructor(private fb: FormBuilder, 
              private authService: AuthService, 
              private errorMessage: ErrorMessageService) {

    this.permissionForm = fb.group({
      name: ['', Validators.required],
      display_name: ['', Validators.required],
      description: ['', Validators.required],
    });

  }
ngOnInit() {

}

onFormSubmit()
{
  if(this.permissionForm.valid){
    this.authService.createPermission(this.permissionForm.value).subscribe(
      resp => {
        if(resp['accepted'] === true){
          this.errorMessage.showSuccess(`El Permiso ${resp.role.display_name} ha sido creado`);
        }
      }, 
      error => { 
          this.errorMessage.showError(error.error.error.show_message);
      }
    );
  }
}


}
