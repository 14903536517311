import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { saveAs } from 'file-saver';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { CreditLineService } from '../../../services/credit-line.service';

declare var moment: any;

@Component({
    selector: 'app-losses',
    templateUrl: './losses.component.html',
    styleUrls: ['./losses.component.scss'],
})
export class LossesComponent implements OnInit, OnDestroy {
    loading: boolean = true;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    tableData;
    total: any = null;

    startData = {};

    filters;

    queryString: string = '';

    // invoicesTypes: any = null;

    promises = [];

    exportFile = null;
    exportLink = null;

    loadingExport = false;

    calendarDates = {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private creditLineService: CreditLineService,
        private router: Router
    ) {}

    ngOnInit() {
        // this.getTypes();
        Promise.all(this.promises).then();

        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    last_status_date_start: this.calendarDates.startDate,
                    last_status_date_end: this.calendarDates.endDate,
                    number_invoice: '',
                    code_str: '',
                    personalid: '',
                    full_name: '',
                    amount: '',
                    type_id: '',
                    status_id: 8,
                };

                if (this.dataSubscriber) {
                    this.dataSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getTableData();
            }
        );
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.creditLineService
            .getAll(this.queryString)
            .subscribe((response) => {
                if (response) {
                    this.tableData = response;
                    this.getTotals(this.queryString);
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([
                `/backoffice/loans/overview/${lead_id}/loanInfo/`,
                lead_id,
            ]);
        }
    }

    async getExportLink(e) {
        e.preventDefault();

        this.loadingExport = true;

        try {
            const exel = await this.creditLineService.getLoansExport(
                this.queryString
            );

            const file = new Blob([exel], {
                type: 'application/vnd.ms-excel',
            });
            this.exportFile = URL.createObjectURL(file);
            this.exportLink = this.sanitizer.bypassSecurityTrustUrl(
                this.exportFile
            );

            saveAs(file, 'losses.xlsx');

            this.loadingExport = false;
        } catch (error) {
            console.error(error);
        }
    }

    getTotals(filterUrlParams) {
        this.creditLineService
            .getLoansTotal(filterUrlParams)
            .toPromise()
            .then((response) => {
                if (response) {
                    this.total = response;
                }
            });
    }

    // getTypes() {
    //     this.promises.push(
    //         this.financeService
    //             .getInvoiceTypes()
    //             .toPromise()
    //             .then((response) => {
    //                 if (response) {
    //                     this.invoicesTypes = response;
    //                 }
    //             })
    //     );
    // }
}
