import { Injectable } from '@angular/core';

@Injectable()
export class HeaderService {

	private headerText: string ="";
	private lineRequestStatus: number;

  constructor() { }

  	setHeader(header: string, status: number){
  		this.headerText = header;
  		this.lineRequestStatus = status; 
  	}
  	getHeaderText(){
  		return this.headerText;
  	}

	getLineRequestStatus(){
	 	return this.lineRequestStatus;
	}
}
