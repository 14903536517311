import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DebtRecoveryService } from '../../../services/debt-recovery.service';

@Component({
    selector: 'app-queue-history',
    templateUrl: './queue-history.component.html',
    styleUrls: ['./queue-history.component.scss'],
})
export class QueueHistoryComponent implements OnInit, OnDestroy {
    tableData;

    loading: boolean = true;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    startData: any = {};
    filters: any = {};

    queryString = '';

    constructor(
        private debtRecovery: DebtRecoveryService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        const queryParams$ = this.activatedRoute.queryParams;

        this.querySubscriber = queryParams$
            .pipe(debounceTime(300))
            .subscribe((queryParams) => {
                this.startData = {
                    created_at_start: '',
                    created_at_end: '',
                    executed_at_start: '',
                    executed_at_end: '',
                    finished_at_start: '',
                    finished_at_end: '',
                    task_name: '',
                };

                if (this.dataSubscriber) {
                    this.dataSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getTableData();
            });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.debtRecovery
            .getHistoryList(this.queryString)
            .subscribe((response) => {
                if (response) {
                    this.tableData = response;
                    if (this.tableData.data && this.tableData.data.length > 0) {
                        this.tableData.data.map((item) => {
                            if (item.payment_request) {
                                if (item.payment_request.amount) {
                                    item.payment_request.amount.total = Number(
                                        item.payment_request.amount.total
                                    );
                                    item.payment_request.amount.success = Number(
                                        item.payment_request.amount.success
                                    );
                                }
                            }
                        });
                    }
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    formatDate(stringDate) {
        let formatDateString;
        if (stringDate) {
            formatDateString = new Date(stringDate.replace(/-/g, '/'));
        }
        return formatDateString;
    }
}
