import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-backoffice-settings',
  templateUrl: './backoffice-settings.component.html',
  styleUrls: ['./backoffice-settings.component.css']
})
export class BackofficeSettingsComponent implements DoCheck {

  show: boolean = true;
  constructor(private router:Router) { }
  
    ngDoCheck(){
      if (window.location.href.match('.backoffice/settings$')){
        this.show=true;
      }else{
        this.show=false;
      }
    }

    goto(route){
      this.show=false;
      this.router.navigate(['/backoffice/settings',route]);
    }

}
