import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DebtRecoveryService } from '../../../services/debt-recovery.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { DataPagesMenu } from '../../../../shared/models/data-pages-menu';
import { combineLatest, SubscriptionLike as ISubscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var moment: any;

@Component({
    selector: 'app-debtrecovery-due',
    templateUrl: './debtrecovery-due.component.html',
    styleUrls: ['./debtrecovery-due.component.scss']
})
export class DebtrecoveryDueComponent implements OnInit, OnDestroy {
    menu: DataPagesMenu = {
        secondaryItem: 'all',
        menu: [
            {
                slug: 'new',
                label: 'New'
            },
            {
                slug: 'payment_commitment',
                label: 'Payment Commitments'
            },
            {
                slug: 'payment_undone',
                label: 'Payment Undone'
            },
            {
                slug: 'recall',
                label: 'Recall'
            },
            {
                slug: 'payment_done',
                label: 'Payment Done'
            },
            {
                slug: 'no_contact',
                label: 'No Contact'
            },
            {
                slug: 'unlocatable',
                label: 'Unlocatable'
            },
            {
                slug: 'payment_refused',
                label: 'Payment Refused'
            },
            {
                slug: 'payment_incident',
                label: 'Incident'
            },
            {
                slug: 'all',
                label: 'All'
            }
        ]
    };

    resetFilters: boolean = false;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    specificOnes = {
        loan_ids: [],
        debt_collector_id_to: '',
        action: 'assign_to'
    };

    selectedPageName = '';

    promises = [];

    startData = {};

    filters = {};

    queryString: string = '';

    filterLists = {
        cardStatus: {
            link: '/bank_card/status/list',
            list: <any>null
        },
        traking: {
            link: '/tracking/filter/list',
            list: <any>null
        },
        jobRole: {
            link: '/lead/info/request',
            list: <any>null
        }
    };

    tableData;

    loading: boolean = true;

    users: any;

    activeDue;

    modalReference: NgbModalRef;
    exportFile = null;
    exportLink = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private debtRecovery: DebtRecoveryService,
        private modalService: NgbModal,
        private errorMessageService: ErrorMessageService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        const params$ = this.activatedRoute.params;
        const queryParams$ = this.activatedRoute.queryParams;

        this.getFiltersList();

        this.querySubscriber = combineLatest(params$, queryParams$)
            .pipe(debounceTime(300))
            .subscribe(([params, queryParams]) => {
                if (params && this.menu.menu.some(item => item.slug === params['status'])) {
                    this.selectedPageName = params['status'];

                    this.authService.getFilteredUsersList(['dc']).then(data => (this.users = data));

                    this.startData = {
                        date_start: '',
                        date_end: '',
                        loan_due_date_start: '',
                        loan_due_date_end: '',
                        loan_activation_date_start: '',
                        loan_activation_date_end: '',
                        loan_code_str: '',
                        loan_amount: '',
                        commitment_amount: '',
                        weighted: '',
                        loan_total_debt_start: '',
                        loan_total_debt_end: '',
                        loan_debt_left_start: '',
                        loan_debt_left_end: '',
                        amount_paid: '',
                        percent: '',
                        card_status_id: '',
                        personalid: '',
                        full_name: '',
                        job_type_id: '',
                        debt_collector_id: '',
                        tracking_type_incident_id: ''
                    };

                    if (this.dataSubscriber) {
                        this.dataSubscriber.unsubscribe();
                    }

                    // Set Init Filters
                    this.setInitFilters(queryParams);
                    // Create Query String
                    this.createQueryString(this.filters);

                    this.getTableData();
                }
            });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.debtRecovery
            .getDueList(this.selectedPageName, this.queryString)
            .subscribe(response => {
                if (response) {
                    this.tableData = response;
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            key => (!clearFilters[key] || clearFilters[key] === 'empty') && delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(key => !filters[key] && delete filters[key]);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeRange(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getFiltersList() {
        let _this = this;
        Object.keys(_this.filterLists).forEach(function(key) {
            _this.promises.push(
                _this.debtRecovery
                    .getFilters(_this.filterLists[key])
                    .toPromise()
                    .then(response => {
                        _this.filterLists[key].list = response;
                    })
            );
        });
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([`/backoffice/loans/overview/${lead_id}/loanInfo/`, lead_id]);
        }
    }

    open(content) {
        this.modalReference = this.modalService.open(content);
        // this.testFormEl.nativeElement.submit();
    }

    reassign(due, reassign) {
        this.activeDue = due;
        this.specificOnes.loan_ids = [due.id];

        this.open(reassign);
    }

    submitPortfolio(form, model) {
        if (this.confirming()) {
            this.debtRecovery
                .assignTo(model)
                .toPromise()
                .then(responce => {
                    if (responce) {
                        this.modalReference.close();
                        this.errorMessageService.showSuccess('Loans Assigned Successfully');
                        this.getTableData();
                        form.resetForm();
                        Object.keys(model).forEach(function(key) {
                            if (key !== 'action') {
                                model[key] = '';
                            }
                        });
                    } else {
                        this.errorMessageService.showError('Loans Assigned Failed');
                    }
                })
                .catch(error => {
                    if (error.error.error) {
                        this.errorMessageService.showError(error.error.error.show_message);
                    }
                });
        }
    }

    confirming() {
        if (confirm('¿Esta seguro que desea realizar esta operación?')) {
            return true;
        } else {
            return false;
        }
    }

    getExportLink(e) {
        e.preventDefault();

        this.debtRecovery.getDueExport(this.queryString, this.selectedPageName).subscribe(exel => {
            let file = new Blob([exel], {
                type: 'application/vnd.ms-excel'
            });
            this.exportFile = URL.createObjectURL(file);
            this.exportLink = this.sanitizer.bypassSecurityTrustUrl(this.exportFile);

            saveAs(file, 'due_manager.xlsx');
        });
    }
}
