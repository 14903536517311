import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable()
export class AsnefService {
    constructor(private http: HttpClient) {}
    getConfigValidatorList(): Observable<any> {
        return this.http.get(environment.api_url + `/config/validator/asnef/list`);
    }
    updateConfigValidator(validator): Observable<any> {
        return this.http.post(environment.api_url + `/config/validator/asnef/${validator.id}/edit`, validator);
    }
    getValidationAsnef(lead_id) {}

    getStatuses() {
        return this.http.get(environment.api_url + '/asnef/code/user_status/list');
    }


    getAsnefConsult(): Observable<any> {
        return this.http.get(environment.api_url + `/config/setting/asnef/consult`);
    }

    switchAsnefConsult(value: boolean): Observable<any> {
        return this.http.post(environment.api_url + `/config/setting/asnef/consult`, {value});
    }
}
