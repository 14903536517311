import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { AuthService } from '../../../services/auth.service';
import { CreditLineService } from '../../../services/credit-line.service';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';

@Component({
    selector: 'app-credit-line-overview',
    templateUrl: './credit-line-overview.component.html',
    styleUrls: ['./credit-line-overview.component.css']
})
export class CreditLineOverviewComponent implements OnInit {
    id: string;
    lead: any = null;
    creditLine: any = null;
    leadStatus: any = null;
    leadRejectedReason: any = null;
    user: any = null;
    debt_collector: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private leadService: LeadService,
        private authService: AuthService,
        private creditLineService: CreditLineService,
        private formatPhoneService: FormatPhoneNumberService
    ) {}

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.getLead(this.id);
        });
    }

    getLead(id) {
        this.leadService.get(id).then(resp => {
            if (Object.values(resp).length) {
                this.lead = resp;

                this.creditLineService.getLoanByLeadId(id).subscribe(creditLine => {
                    if (Object.values(creditLine).length) {
                        this.creditLine = creditLine;

                        this.user = this.creditLine.user;

                        if (this.creditLine.debt_collector_id) {
                            this.authService.getUsers().subscribe(answer => {
                                if (answer.data.length > 0) {
                                    const debt_collector = answer.data.filter(
                                        item => item.id === this.creditLine.debt_collector_id
                                    )[0];
                                    this.debt_collector = `${debt_collector.name ||
                                        ''} ${debt_collector.first_surname || ''} ${debt_collector.second_surname ||
                                        ''}`;
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    goto(route, event) {
        if (event) {
            let target = event.target || event.srcElement || event.currentTarget;
            if (document.getElementsByClassName('tablinks-selected').length > 0) {
                document.getElementsByClassName('tablinks-selected')[0].className = 'tablinks';
            }
            (<HTMLInputElement>target).className = 'tablinks-selected';
        }
        this.router.navigate([`/backoffice/loans/overview/${this.id}/${route}`, this.id]);
    }

    formatPhone(phone) {
        if (phone) {
            return this.formatPhoneService.format(phone);
        }
    }
}
