import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-asnef-status',
    templateUrl: './asnef-status.component.html',
    styleUrls: ['./asnef-status.component.scss'],
})
export class AsnefStatusComponent implements OnInit, OnChanges {
    @Input() user_id = null;
    @Input() loan_id = null;
    list = [];
    admins = [];
    reasonsList = [];
    currentStatus = null;
    constructor(
        private userService: UserService,
        private authService: AuthService
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.user_id) {
            this.authService
                .getUsers()
                .toPromise()
                .then((response) => {
                    this.admins = response.data;
                })
                .then(() => {
                    this.getUserHistory(this.user_id);
                });
            this.getAsnefCurrentStatus(this.loan_id);
            this.userService
                .getAsnefExludeReasons()
                .toPromise()
                .then((reasons) => (this.reasonsList = reasons));
        }
    }

    getUserHistory(user_id) {
        this.userService
            .getUserAsnefHistory(user_id)
            .toPromise()
            .then((history) => {
                history.forEach((item) => {
                    const created_by: any = this.admins.filter(
                        (admin) => admin.id === +item.created_by
                    );

                    if (created_by[0]) {
                        let fullName = created_by[0].name || '';
                        fullName += created_by[0].first_surname
                            ? ' ' + created_by[0].first_surname
                            : '';
                        item.created_by = fullName;
                    }
                });

                this.list = history;
            });
    }

    getAsnefCurrentStatus(loan_id) {
        if (loan_id) {
            this.userService
                .getAsnefCurrentStatus(loan_id)
                .toPromise()
                .then((current) => {
                    this.currentStatus = current;
                });
        } else {
            this.currentStatus = null;
        }
    }

    onUserExluded(user_id) {
        this.getUserHistory(user_id);
        this.getAsnefCurrentStatus(this.loan_id);
    }
}
