<div *ngIf="isLoading" class="text-center py-5">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading && tableData?.data.length" class="table-responsive">
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>

    <table class="table table-hover">
        <thead>
            <tr>
                <th>Fecha Entrada</th>
                <th>Préstamo</th>
                <th>Importe</th>
                <th>Total a pagar</th>
                <th>Pagado</th>
                <th>Deuda Pendiente</th>
                <th>%</th>
                <th>DNI/NIE</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableData?.data">
                <td>
                    {{
                        item?.created_at | dateFormat | date: 'dd/MM/yyyy HH:mm'
                    }}
                </td>
                <td>{{ item?.loan_code_str }}</td>
                <td>{{ item?.loan_amount | currency: 'EUR' }}</td>
                <td>{{ item?.loan_total_pay | currency: 'EUR' }}</td>
                <td>{{ item?.loan_amount_paid | currency: 'EUR' }}</td>
                <td>{{ item?.loan_debt_left | currency: 'EUR' }}</td>
                <td>
                    {{
                        item?.loan_debt_left_percent
                            ? (item?.loan_debt_left_percent
                              | percent: '1.0-2':'es')
                            : '-'
                    }}
                </td>
                <td>{{ item?.user_personalid }}</td>
                <td>
                    <a
                        [routerLink]="[
                            '/backoffice/legal/monitoring/trial-proceeding/' +
                                item.id
                        ]"
                        ><i class="fa fa-gear"></i
                    ></a>
                </td>
            </tr>
        </tbody>
    </table>

    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>

<p *ngIf="!isLoading && !tableData?.data.length" class="text-center py-5">
    Nada Encontrado
</p>
