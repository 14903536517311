<div class="container-fluid">
    <div class="form-row mb-3">
        <div class="col-12 col-xl mb-3 align-self-end">
            <input
                type="text"
                name="date"
                daterangepicker
                [options]="settings"
                class="form-control"
                (selected)="selectedDate($event)"
                placeholder="Choose date"
                (cancelDaterangepicker)="clearCalendar($event)"
                (keyup.enter)="clearCalendar($event)"
                autocomplete="off"
                [value]="formDateValue"
            />
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">
                        Total Initial
                        <br />
                        Debt Left
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">{{ 2565760 | currency: 'EUR' }}</h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">
                        Total Debt
                        <br />
                        Left
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-danger mb-0">{{ 1055201 | currency: 'EUR' }}</h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">
                        Total
                        <br />
                        Recovered
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-success mb-0">{{ 1601559 | currency: 'EUR' }}</h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">{{ 1601559 / 2565760 | percent: '1.0-2' }}</h5>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-xl mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">
                        <small class="text-body">Loans in Due</small>
                        <br />
                        {{ 3950 | number }}
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="col-6 col-md-3 col-lg-2">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">New Due</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In units</small>
                        </h5>
                        <h3 class="mb-0">{{ 10 | number }}</h3>
                    </div>
                </div>
            </div>
            <div class="card rounded-0 mb-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In €</small>
                        </h5>
                        <h3 class="mb-0">{{ 950 | number }}</h3>
                    </div>
                </div>
            </div>
            <div class="card rounded-0 mb-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">% of Total Debt Left</small>
                        </h5>
                        <h3 class="mb-0">{{ 950 / 1055291 | percent: '1.0-2' }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-lg-2">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">Recovered</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In units</small>
                        </h5>
                        <h3 class="mb-0">{{ 5 | number }}</h3>
                    </div>
                </div>
            </div>
            <div class="card rounded-0 mb-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In €</small>
                        </h5>
                        <h3 class="mb-0">{{ 11950 | number }}</h3>
                    </div>
                </div>
            </div>
            <div class="card rounded-0 mb-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">% of Total Debt Left</small>
                        </h5>
                        <h3 class="mb-0">{{ 11950 / 1055291 | percent: '1.0-2' }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-lg-2">
            <div class="card rounded-0 mb-3 bg-light">
                <div class="card-header bg-light">
                    <h5 class="card-title text-primary mb-0">Differnce</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In units</small>
                        </h5>
                        <h3 class="mb-0 text-danger">+{{ 10 - 5 | number }}</h3>
                    </div>
                </div>
            </div>
            <div class="card rounded-0 mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In €</small>
                        </h5>
                        <h3 class="mb-0 text-success">{{ 950 - 11950 | number }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-3 col-lg-2">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">Commitments Lost</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In units</small>
                        </h5>
                        <h3 class="mb-0">{{ 10 | number }}</h3>
                    </div>
                </div>
            </div>
            <div class="card rounded-0 mb-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between pt-2 pb-2">
                        <h5 class="mb-0">
                            <small class="text-body">In €</small>
                        </h5>
                        <h3 class="mb-0">{{ 950 | number }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card rounded-0">
                <div class="card-header bg-white border-bottom-0">
                    <h5 class="card-title text-primary mb-0">Due payment method status</h5>
                </div>
            </div>
            <div class="form-row">
                <div class="col-6">
                    <div class="card rounded-0 mb-3 border-top-0">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <h5 class="mb-0">
                                    <small class="text-body">Credit Card Locked</small>
                                </h5>
                                <h3 class="mb-0">{{ 120 | number }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-0 mb-3">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <h5 class="mb-0">
                                    <small class="text-body">Credit Card Expired</small>
                                </h5>
                                <h3 class="mb-0">{{ 25 | number }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-0 mb-3">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <h5 class="mb-0">
                                    <small class="text-body">Credit Card Not Authorized</small>
                                </h5>
                                <h3 class="mb-0">{{ 35 | number }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card rounded-0 mb-3 border-top-0">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <h5 class="mb-0">
                                    <small class="text-body">In €</small>
                                </h5>
                                <h3 class="mb-0">{{ 45670.67 | number }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-0 mb-3">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <h5 class="mb-0">
                                    <small class="text-body">In €</small>
                                </h5>
                                <h3 class="mb-0">{{ 2500.69 | number }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-0 mb-3">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <h5 class="mb-0">
                                    <small class="text-body">In €</small>
                                </h5>
                                <h3 class="mb-0">{{ 6567.9 | number }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container"></div>
</div>
