<div class="card mb-3">
    <div class="card-header"><h5 class="card-header-title">Milinea Validation</h5></div>
    <div class="card-body">
        <div class="fluid-row">
            <div class="card mb-3">
                <div class="card-header"><h5 class="card-header-title">Basic info</h5></div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Name</strong><span>{{ milinea_customer?.name }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Last Name</strong><span>{{ milinea_customer?.first_surname }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Mobile:</strong><span>{{ milinea_customer?.mobile_phone }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Fix Phone:</strong><span>{{ milinea_customer?.fix_phone }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Work Phone:</strong><span>{{ milinea_customer?.work_phone }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header"><h5 class="card-header-title">Leads History</h5></div>
                <div class="card-body">
                    <div class="margin-top-20 table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead class="table-head bg-green-light">
                                <th>Code</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Reason</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lead of milinea_customer?.savso_leads">
                                    <td>
                                        <pre>{{ lead | json }}</pre>
                                    </td>
                                    <td>{{ lead.code }}</td>
                                    <td>{{ lead.lead_created_at }}</td>
                                    <td>{{ lead.status }}</td>
                                    <td>{{ lead.status_reason }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header"><h5 class="card-header-title">Loans History</h5></div>
                <div class="card-body">
                    <div class="margin-top-20 table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead class="table-head bg-green-light">
                                <th>Code</th>
                                <th>Date</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let loan of milinea_customer?.savso_loans">
                                    <td>{{ loan.code }}</td>
                                    <td>{{ loan.loan_created_at }}</td>
                                    <td>{{ loan.status }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
