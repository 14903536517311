import { Injectable } from '@angular/core';
import { FilterBase } from '../../shared/models/FilterBase.model';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { FilterRangeForm } from '../../shared/models/FilterRangeForm.model';
import { FilterDateRangeForm } from '../../shared/models/FilterDateRangeForm.model';
import { Observable ,  BehaviorSubject } from 'rxjs';

const formStartEnd: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('start').value;
    const end = fg.get('end').value;

    return (end !== 0 && !end) || start <= end ? null : { startEnd: true };
};

@Injectable()
export class FiltersFormService {
    private group: any = {};
    private form: BehaviorSubject<FormGroup | undefined>;

    constructor(private fb: FormBuilder) {}

    private createFiltersGroup(filters: FilterBase<any>[]) {
        filters.forEach(filter => {
            switch (filter.filterType) {
                case 'range':
                    this.group[filter.name] = this.fb.group(new FilterRangeForm(filter), {
                        validator: formStartEnd
                    });
                    break;
                case 'dateRange':
                    this.group[filter.name] = this.fb.group(new FilterDateRangeForm(filter));
                    break;
                default:
                    this.group[filter.name] = this.fb.control(filter.value || '');
                    break;
            }
        });

        this.form = new BehaviorSubject(this.fb.group(this.group));
    }

    getFiltersForm(filters: FilterBase<any>[]): Observable<FormGroup> {
        this.createFiltersGroup(filters);

        return this.form.asObservable();
    }
}
