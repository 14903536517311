import { Injectable } from '@angular/core';

@Injectable()
export class FormatPhoneNumberService {

  constructor() { }

  format(phone) {
    let phone_numeric = String(phone).replace(/[^\d]+/g, "");
    let phone_formatted = '';
    if (phone_numeric.length === 11) {
      phone_formatted = phone_numeric.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$2 $3 $4");
    } else {
      phone_formatted = phone_numeric.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    }

    return phone_formatted;

  }

  formatWithOutSpaces(phone) {
    let formatePhone = this.format(phone);
    let endFormat = formatePhone.replace(/\s+/g, '');

    return endFormat;
  }
}
