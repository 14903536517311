import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ValidDni][formControlName],[ValidDni][formControl],[ValidDni][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidDniDirective)}]
})

export class ValidDniDirective implements Validator{

  constructor() { }


validate(input: AbstractControl):{[key: string]:any} {

  	var dniLetters: String= 'TRWAGMYFPDXBNJZSQVHLCKE';
  	var valid:boolean = false;
       
	if (input.value){

		if (input.value.length == 9) {

	    	var DNI = input.value.toUpperCase().replace(/\s/, '');
	    	var niePrefix = DNI.charAt(0);
		        switch (niePrefix) {
		            case 'X':
		                niePrefix = '0';
		                break;
		            case 'Y':
		                niePrefix = '1';
		                break;
		            case 'Z':
		                niePrefix = '2';
		                break;
	        	}
	        DNI = niePrefix + DNI.substr(1);
	        if (/^[XYZ]?([0-9]{7,8})([A-Z])$/.test(DNI)) {
	            if (DNI.charAt(8) === dniLetters.charAt(parseInt(DNI, 10) % 23)){
	            	
            		return null;
	            }

	  		}
	    }
	}
	return {ValidDni: false}
               	
}
  
}

 

