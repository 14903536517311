<div>
    <h3>Validation Scoring</h3>
</div>
<table class="table table-striped table-responsive">
    <thead>
        <tr>
            <th>Profile</th>
            <th>Min Scoring</th>
            <th>Max Scoring</th>
            <th>Decision</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let form of forms; let index = index">
            <td>
                <input type="hidden" class="form-control" [(ngModel)]="forms[index].validator_id" />
                {{ forms[index]?.description }}
            </td>
            <td>
                <input type="number" class="form-control" [(ngModel)]="forms[index].min" />
            </td>
            <td>
                <input type="number" class="form-control" [(ngModel)]="forms[index].max" />
            </td>
            <td>
                <input type="checkbox" class="form-control" [(ngModel)]="forms[index].decision" />
                <label class="form-check-label">Accept</label>
            </td>
            <td>
                <input type="checkbox" class="form-control" [(ngModel)]="forms[index].actived" />
                <label class="form-check-label">Actived</label>
            </td>
        </tr>
    </tbody>
</table>
<div class="text-center py-4 mt-3">
    <button
        class="btn btn-cyan waves-light"
        type="submit"
        [disabled]="!forms || saving"
        (click)="save()"
        mdbWavesEffect
    >
        Save
    </button>
</div>
