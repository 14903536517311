import {
    Component,
    ViewChild,
    AfterViewInit,
    ViewContainerRef,
    TemplateRef,
    Input,
    OnChanges
} from '@angular/core';
import { TrackingService } from '../../../services/tracking.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-tracking-content',
    templateUrl: './tracking-content.component.html',
    styleUrls: ['./tracking-content.component.scss']
})
export class TrackingContentComponent implements OnChanges, AfterViewInit {
    @Input() creditline: any = null;

    @ViewChild('vc', { read: ViewContainerRef })
    vc: ViewContainerRef;
    @ViewChild('vc1', { read: ViewContainerRef })
    vc1: ViewContainerRef;
    @ViewChild('vc2', { read: ViewContainerRef })
    vc2: ViewContainerRef;
    @ViewChild('vc3', { read: ViewContainerRef })
    vc3: ViewContainerRef;
    @ViewChild('vc4', { read: ViewContainerRef })
    vc4: ViewContainerRef;

    @ViewChild('buttonTpl') buttonTpl: TemplateRef<any>;
    @ViewChild('amountTpl') amountTpl: TemplateRef<any>;
    @ViewChild('weightedTpl') weightedTpl: TemplateRef<any>;
    @ViewChild('dateTpl') dateTpl: TemplateRef<any>;
    @ViewChild('timeTpl') timeTpl: TemplateRef<any>;
    @ViewChild('typeTpl') typeTpl: TemplateRef<any>;

    trackingModel: any = {};
    trackingList: any = [];
    events: any = {};
    incidents: any = {};
    trackings: any = {};

    pageFilter: string = '';

    loading: boolean = false;
    isSaving: boolean = false;
    defaultAction: string = '2';

    singlePicker = {
        locale: { format: 'DD/MM/YYYY' },
        singleDatePicker: true,
        alwaysShowCalendars: true,
        autoUpdateInput: false
    };

    public mask = {
        mask: [/\d/, /\d/, ':', /\d/, /\d/],
        guide: false
    };

    trackingForm: FormGroup;

    constructor(
        private trackingService: TrackingService,
        private errorMessageService: ErrorMessageService
    ) {
        this.setModel();
        this.trackingForm = new FormGroup({
            comment: new FormControl('', [Validators.required])
        });
    }

    ngOnChanges() {
        if (this.creditline) {
            this.getTrackings();
            this.trackingService
                .filterList()
                .toPromise()
                .then(filterList => {
                    this.events = filterList.types;
                    this.incidents = filterList.incidents;
                });
        }
    }

    getTrackings(page = 0) {
        this.loading = true;
        this.setPageFilter(page);

        this.trackingService
            .getList(this.creditline.id, this.pageFilter)
            .subscribe(
                response => {
                    if (response) {
                        this.trackings = response;
                        this.trackingList = response.data;
                        this.loading = false;
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    setPageFilter(page) {
        this.pageFilter = '';
        if (page) {
            this.pageFilter = 'page=' + page;
        }
    }

    ngAfterViewInit() {
        setTimeout(
            self => {
                self.createTemplate();
            },
            0,
            this
        );
    }

    actionChanged() {
        this.createTemplate();

        this.trackingModel.amount = '';
        this.trackingModel.weighted = '';
        this.trackingModel.date = '';
        this.trackingModel.hour = '';
        this.trackingModel.incident_id = '1';
        this.trackingModel.comment = '';
    }

    createTemplate() {
        let action = Number(this.trackingModel.action);
        this.clearTemplate();

        let viewAmount = this.amountTpl.createEmbeddedView(null);
        let viewWeighted = this.weightedTpl.createEmbeddedView(null);
        let viewDate = this.dateTpl.createEmbeddedView(null);
        let viewTime = this.timeTpl.createEmbeddedView(null);
        let viewButton = this.buttonTpl.createEmbeddedView(null);
        let viewType = this.typeTpl.createEmbeddedView(null);

        switch (action) {
            case 4:
                this.vc.insert(viewAmount);
                this.vc1.insert(viewWeighted);
                this.vc2.insert(viewDate);
                this.vc3.insert(viewTime);
                this.vc4.insert(viewButton);
                this.trackingForm = new FormGroup({
                    comment: new FormControl('', [Validators.required]),
                    amount: new FormControl('', [
                        Validators.required,
                        Validators.min(0)
                    ]),
                    weighted: new FormControl('', [
                        Validators.required,
                        Validators.min(0)
                    ]),
                    date: new FormControl('', [
                        Validators.required,
                        Validators.pattern(
                            /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
                        ),
                        Validators.minLength(10),
                        Validators.maxLength(10)
                    ]),
                    hour: new FormControl('', [
                        Validators.pattern(/^([01]\d|2[0-3]):?([0-5]\d)$/),
                        Validators.minLength(5),
                        Validators.maxLength(5)
                    ])
                });
                break;
            case 3:
                this.vc2.insert(viewDate);
                this.vc3.insert(viewTime);
                this.vc4.insert(viewButton);
                this.trackingForm = new FormGroup({
                    comment: new FormControl('', [Validators.required]),
                    date: new FormControl('', [
                        Validators.required,
                        Validators.pattern(
                            /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
                        ),
                        Validators.minLength(10),
                        Validators.maxLength(10)
                    ]),
                    hour: new FormControl('', [
                        Validators.required,
                        Validators.pattern(/^([01]\d|2[0-3]):?([0-5]\d)$/),
                        Validators.minLength(5),
                        Validators.maxLength(5)
                    ])
                });
                break;
            case 7:
                this.vc.insert(viewType);
                this.vc1.insert(viewButton);
                this.trackingForm = new FormGroup({
                    comment: new FormControl('', [Validators.required]),
                    incident_id: new FormControl('1', [Validators.required])
                });
                break;
            default:
                this.vc1.insert(viewButton);
                this.trackingForm = new FormGroup({
                    comment: new FormControl('', [Validators.required])
                });
                break;
        }
    }

    clearTemplate() {
        this.vc.clear();
        this.vc1.clear();
        this.vc2.clear();
        this.vc3.clear();
        this.vc4.clear();
    }

    setModel() {
        this.trackingModel = {
            action: <string>this.defaultAction,
            amount: <number>null,
            weighted: <number>null,
            date: <string>'',
            hour: <string>'',
            incident_id: <string>'1',
            comment: <string>''
        };
    }

    selectedDateSingle(value: any) {
        this.trackingModel.date = value.end.format('DD/MM/YYYY');
    }

    onSubmit(value) {
        this.isSaving = true;
        let data = value;
        data.type_id = this.trackingModel.action;
        data.loan_id = this.creditline.id;
        data.added_by = 1;

        if (data.date) {
            data.date = data.date.toString().replace(/\//g, '-');
        }

        this.trackingService.create(data).subscribe(
            response => {
                if (response) {
                    this.getTrackings();

                    setTimeout(
                        function(self) {
                            self.errorMessageService.showSuccess(
                                'Tracking Entry added successful!'
                            );
                            self.isSaving = false;
                            self.trackingModel.action = self.defaultAction;
                            self.actionChanged();
                        },
                        600,
                        this
                    );
                }
            },
            error => {
                setTimeout(
                    function(self) {
                        self.isSaving = false;
                    },
                    600,
                    this
                );
                this.errorMessageService.showError(
                    error.error.error.show_message
                );
            }
        );
    }
}
