import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DocsService } from '../../../services/docs.service';
import { ActivatedRoute } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { CreditLineService } from '../../../services/credit-line.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CheckNavBarService } from '../../../services/check-nav-bar.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentBankAccountService } from '../../../services/payment-bank-account.service';
import { Observable, Observer } from 'rxjs';
import {
    createButtonConfig,
    ImageViewerConfig,
    IMAGEVIEWER_CONFIG,
} from '@hallysonh/ngx-imageviewer';

export const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
    bgStyle: '#fff',
    buttonStyle: {
        iconFontFamily: 'FontAwesome',
        bgStyle: '#69356e',
        alpha: 1,
    },
    rotateRightButton: createButtonConfig('\uF01e', 'Rotate right', 4),
    rotateLeftButton: createButtonConfig('\uF0e2', 'Rotate left', 3),
    zoomInButton: createButtonConfig('\uF00e', 'Zoom in', 2),
    zoomOutButton: createButtonConfig('\uF010', 'Zoom out', 1),
    resetButton: createButtonConfig('\uF021', 'Reset', 0),
};

@Component({
    selector: 'app-credit-line-docs',
    templateUrl: './credit-line-docs.component.html',
    styleUrls: ['./credit-line-docs.component.scss'],
    providers: [
        {
            provide: IMAGEVIEWER_CONFIG,
            useValue: MY_IMAGEVIEWER_CONFIG,
        },
    ],
})
export class CreditLineDocsComponent implements OnInit {
    @ViewChild('credit_line_check_reject') credit_line_check_reject: ElementRef;
    @ViewChild('credit_line_active') credit_line_active: ElementRef;

    images = [];
    lead_id: any = null;
    editing: boolean = false;
    credit_line_check: any = null;
    client_document: any = null;
    imageURL: any = null;

    credit_line: any = null;
    uploadErrorMessage = '';

    imageForm: FormGroup = null;
    uploading = false;
    loading = false;
    closeResult: string;

    lead = null;
    paymentBankCard: any = null;
    promises = [];
    rotateValue: number = 0;

    canvasWidth = '';
    canvasHeight = '';

    constructor(
        private docsService: DocsService,
        private activate: ActivatedRoute,
        private leadService: LeadService,
        private creditLineService: CreditLineService,
        public checkNavBarService: CheckNavBarService,
        private errorMessageService: ErrorMessageService,
        private modalService: NgbModal,
        private paymentBankAccount: PaymentBankAccountService
    ) {
        this.imageForm = new FormGroup({
            personalid_front_face: new FormControl('', Validators.required),
            personalid_back_face: new FormControl('', Validators.required),
            credit_card_selfie: new FormControl('', Validators.required),
            document_confirming_address: new FormControl(
                '',
                Validators.required
            ),
            additional_document: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        this.activate.params.subscribe((params) => {
            this.lead_id = params['id'];
            /*Obteniendo el chequeo de la linea de credito
                Esto se usa cuando se va a utilizar esta pantalla
                para chequear la linea de credito
            */
            this.leadService.get(this.lead_id).then((lead: any) => {
                if (Object.values(lead).length) {
                    this.lead = lead;
                }
            });
            this.activate.parent.url.subscribe((url) => {
                /* Este caso es para cuando se está usando esta pantalla
                 *  para el proceso de validacion y activacion de la linea de credito
                 */
                if (url[0].path === 'loan-check') {
                    this.editing = true;
                    this.checkNavBarService.docs();
                }
                /*Este caso es cuando se usa esta pantalla para visualizar la informacion de la linea de credito */
                this.creditLineService
                    .getByLeadId(this.lead_id)
                    .subscribe((cl) => {
                        if (Object.values(cl).length) {
                            this.credit_line = cl;
                            this.leadService
                                .getCreditLineCheck(cl.id)
                                .subscribe((_lead) => {
                                    if (Object.values(_lead).length) {
                                        this.credit_line_check = _lead;
                                    }
                                });
                            this.getClientDocument(cl.id);
                        }
                    });
            });
        });

        this.getCreditLineWithRelationshipedEntities();

        Promise.all(this.promises).then(() => {
            this.loading = false;
        });
    }

    open(content) {
        this.modalService.open(content, {
            size: 'lg',
            windowClass: 'docsModal',
            backdropClass: 'docsBackdrop',
        });

        setTimeout(() => {
            const modal = document.querySelector('.docsModal .modal-body-lg');

            this.canvasWidth = `${modal.clientWidth}`;
            this.canvasHeight = `${modal.clientHeight}`;
        }, 300);
    }
    checkCompleted(resp) {
        if (resp.completed === true) {
            this.checkNavBarService.completed = true;
        }
    }

    getClientDocument(credit_line_id) {
        this.docsService
            .getClientDocumentByCreditLine(credit_line_id)
            .subscribe((client_document) => {
                if (Object.values(client_document).length) {
                    this.client_document = client_document;
                }
            });
    }

    viewClientDocument(client_document_name) {
        this.images[0] = '';
        this.loading = true;

        this.docsService
            .viewClientDocument(
                client_document_name,
                this.client_document.loan_id
            )
            .subscribe((image) => {
                this.dataURItoBlob(image as string).subscribe((blob: Blob) => {
                    const imageBlob: Blob = blob;
                    const imageName: string = this.generateName();
                    const imageFile: File = new File([imageBlob], imageName, {
                        type: 'image/jpeg',
                    });

                    this.images[0] = imageFile;
                    this.loading = false;
                });
            });
    }

    openImage() {
        const generatedImage = window.URL.createObjectURL(this.images[0]);
        window.open(generatedImage);
    }

    donwloadImage() {
        const generatedImage = window.URL.createObjectURL(this.images[0]);
        const tag = document.createElement('a');
        tag.href = generatedImage;
        tag.download = this.generateName();
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }

    /* Method to convert Base64Data Url as Image Blob */
    dataURItoBlob(dataURI: string): Observable<Blob> {
        return new Observable((observer: Observer<Blob>) => {
            const byteString: string = window.atob(dataURI);
            const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([int8Array], { type: 'image/png' });
            observer.next(blob);
            observer.complete();
        });
    }

    /**Method to Generate a Name for the Image */
    generateName(): string {
        const date: number = new Date().valueOf();
        let text: string = '';
        const possibleText: string =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(
                Math.floor(Math.random() * possibleText.length)
            );
        }
        // Replace extension according to your media type like this
        return date + '.' + text + '.jpeg';
    }

    checkPersonalidFrontFace() {
        if (this.client_document) {
            this.creditLineService
                .checkDocPersonalidFrontFace(this.client_document.loan_id)
                .subscribe((resp) => {
                    if (resp.doc_personalid_front_face) {
                        this.checkNavBarService.checkedPersonalIdFront = true;
                        this.checkCompleted(resp);
                    }
                });
        }
    }
    checkPersonalidBackFace() {
        if (this.client_document) {
            this.creditLineService
                .checkDocPersonalidBackFace(this.client_document.loan_id)
                .subscribe((resp) => {
                    if (resp.doc_personalid_back_face) {
                        this.checkNavBarService.checkedPersonalIdBack = true;
                        this.checkCompleted(resp);
                    }
                });
        }
    }
    checkCreditCardSelfie() {
        if (this.client_document) {
            this.creditLineService
                .checkDocCreditCardSelfie(this.client_document.loan_id)
                .subscribe((resp) => {
                    if (resp.doc_credit_card_selfie) {
                        this.checkNavBarService.checkedSelfie = true;
                        this.checkCompleted(resp);
                    }
                });
        }
    }
    checkDocumentConfirmingAddress() {
        if (this.client_document) {
            this.creditLineService
                .checkDocDocumentConfirmingAddress(this.client_document.loan_id)
                .subscribe((resp) => {
                    if (resp.doc_document_confirming_address) {
                        this.checkNavBarService.checkedAddress = true;
                        this.checkCompleted(resp);
                    }
                });
        }
    }

    onFileChange(event, name) {
        /*reiniciando las variables de error y nomnbre de archivo*/
        this.uploadErrorMessage = '';

        let files =
            (<HTMLInputElement>event.target).files ||
            (<HTMLInputElement>event.srcElement).files;
        if (files.length > 0) {
            this.uploading = true;
            let file = files[0];

            if (!this.validFileSize(file)) {
                this.uploading = false;
                return;
            }
            if (!this.validFileType(file)) {
                this.uploading = false;
                return;
            }

            this.submit(name, file);
        }
    }

    submit(name, file) {
        //this.loading = true;
        //console.log(this.signupForm.value.dni1);

        let input = new FormData();
        input.append('document', file);
        input.append('loan_id', this.client_document.loan_id);
        input.append('document_name', name);

        this.docsService.updateClientDocument(input, name).subscribe(
            (resp) => {
                this.imageForm.get(name).setValue('');
                this.uploading = false;
                this.errorMessageService.showSuccess('Imagen guardada');
                if (
                    !this.client_document.additional_document &&
                    name === 'additional_document'
                ) {
                    this.getClientDocument(this.client_document.loan_id);
                }
            },
            (error) => {
                if (error.error.error) {
                    this.errorMessageService.showError(
                        'No se ha podido guardar la imagen'
                    );
                    this.uploading = false;
                }
            }
        );
    }
    validFileSize(file) {
        this.uploadErrorMessage = '';
        if (file.size <= 0) {
            this.uploadErrorMessage = 'required';
            return false;
        }

        if (file.size < 1024) {
            console.log(file.size + 'bytes');
        } else if (file.size >= 1024 && file.size < 1048576) {
            console.log((file.size / 1024).toFixed(1) + 'KB');
        } else if (file.size >= 1048576) {
            console.log((file.size / 1048576).toFixed(1) + 'MB');
        }
        return true;
    }
    validFileType(file) {
        let fileTypes = ['image/jpg', 'image/jpeg', 'image/pjpeg', 'image/png'];
        for (let i = 0; i < fileTypes.length; i++) {
            if (file.type === fileTypes[i]) {
                return true;
            }
        }
        this.uploadErrorMessage = 'Image type not allow';
        return false;
    }

    getBankCardList(user_id) {
        this.promises.push(
            this.paymentBankAccount
                .getListByUserId(user_id)
                .subscribe((paymentBankCard) => {
                    if (paymentBankCard.length) {
                        this.paymentBankCard = paymentBankCard;
                    }
                })
        );
    }

    getCreditLineWithRelationshipedEntities() {
        this.promises.push(
            this.creditLineService
                .getByLeadIdWithRelationshipedEntities(this.lead_id)
                .toPromise()
                .then((cl) => {
                    if (Object.values(cl).length && cl.user.id) {
                        this.getBankCardList(cl.user.id);
                    }
                })
        );
    }
}
