import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-payment-tree-create',
  templateUrl: './payment-tree-create.component.html',
  styleUrls: ['./payment-tree-create.component.scss'],
})
export class PaymentTreeCreateComponent implements OnInit {
  @Output() created: EventEmitter<any> = new EventEmitter();
  isSending = false;

  form: FormGroup;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private debtRecoveryService: DebtRecoveryService,
    private errorService: ErrorMessageService
  ) {
    this.buildForm();
  }

  open(content) {
    this.modalService.open(content);
  }

  ngOnInit() {}

  onSubmit() {
    const data = this.form.getRawValue();

    this.isSending = true;

    this.debtRecoveryService
      .createPaymentTreeAmount(data)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.errorService.showSuccess('Tree created successfuly!');
          this.isSending = false;
          this.created.emit(response);
          this.form.reset({
            name: '',
            description: '',
            enabled: false,
          });

          this.modalService.dismissAll();
        },
        (err) => {
          this.errorService.showError(err.error.error.show_message);
          this.isSending = false;
        }
      );
  }

  buildForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      enabled: [false],
    });
  }
}
