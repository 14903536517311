import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mail-providers-statuses',
    templateUrl: './mail-providers-statuses.component.html',
    styleUrls: ['./mail-providers-statuses.component.scss'],
})
export class MailProvidersStatusesComponent implements OnInit {
    item;
    constructor() {}

    ngOnInit(): void {}

    onItemCreated(item) {
        if (item) {
            this.item = item;
        }
    }
}
