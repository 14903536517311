import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { forkJoin, SubscriptionLike as ISubscription } from 'rxjs';
import { KpiService } from '../../../services/kpi.service';

Chart.plugins.unregister(ChartDataLabels);

declare const moment: any;

const MOMENT_FORMAT = 'YYYY-MM-DD';
@Component({
    selector: 'app-kpis-business',
    templateUrl: './kpis-business.component.html',
    styleUrls: ['./kpis-business.component.scss'],
})
export class KpisBusinessComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('totalChart') totalChart: ElementRef;
    @ViewChild('collectionChart')
    collectionChart: ElementRef;
    @ViewChild('dueChart') dueChart: ElementRef;
    @ViewChild('leadsChart') leadsChart: ElementRef;
    @ViewChild('inversionChart') inversionChart: ElementRef;
    @ViewChild('differenceChart')
    differenceChart: ElementRef;

    querySubscriber: ISubscription;
    forkJoinSubscription: ISubscription;
    graphSubscription: ISubscription;

    startData: any = {};
    filters;
    clearFilters: any = {};

    loading = false;
    graphLoading = false;

    billing: any;
    charges: any;
    difference: any;
    emmited: any;

    calendarDates = {
        startDate: moment().startOf('year').format(MOMENT_FORMAT),
        endDate: moment().format(MOMENT_FORMAT),
    };

    minDate = '2015-01-01';
    maxDate = moment().format(MOMENT_FORMAT);

    chartInstances = {
        total: null,
        collection: null,
        due: null,
        leads: null,
        inversion: null,
        difference: null,
    };

    time_unit: 'month' | 'week' = 'month';
    graphQuery: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private readonly kpiService: KpiService
    ) {}

    ngOnInit(): void {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    date_start: this.calendarDates.startDate,
                    date_end: this.calendarDates.endDate,
                    time_unit: 'month',
                };

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getData();
            }
        );

        this.getGraphDataInit();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.totalChart?.nativeElement) {
                this.createChart(this.totalChart.nativeElement, 'total');
            }
            if (this.collectionChart?.nativeElement) {
                this.createChart(
                    this.collectionChart.nativeElement,
                    'collection'
                );
            }
            if (this.dueChart?.nativeElement) {
                this.createChart(this.dueChart.nativeElement, 'due');
            }
            if (this.leadsChart?.nativeElement) {
                this.createChart(this.leadsChart.nativeElement, 'leads');
            }
            if (this.inversionChart?.nativeElement) {
                this.createChart(
                    this.inversionChart.nativeElement,
                    'inversion'
                );
            }
            if (this.differenceChart?.nativeElement) {
                this.createChart(
                    this.differenceChart.nativeElement,
                    'difference'
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.querySubscriber.unsubscribe();
        this.forkJoinSubscription.unsubscribe();
        this.graphSubscription.unsubscribe();
    }

    getData() {
        this.loading = true;
        const billing = this.kpiService.getBilling(this.clearFilters);
        const charges = this.kpiService.getBusinessCharges(this.clearFilters);
        const difference = this.kpiService.getBusinessDifferenceTotal(
            this.clearFilters
        );

        const filtersString = Object.keys(this.clearFilters)
            .map((key) => key + '=' + this.clearFilters[key])
            .join('&');
        const emmited = this.kpiService.getEmitedTotal(filtersString);

        this.forkJoinSubscription = forkJoin([
            billing,
            charges,
            difference,
            emmited,
        ]).subscribe(
            ([
                billingResponse,
                chargesResponce,
                differenceResponce,
                emmitedResponce,
            ]: any) => {
                if (billingResponse) {
                    this.billing = billingResponse;
                }

                if (chargesResponce) {
                    this.charges = chargesResponce;
                }

                if (differenceResponce) {
                    this.difference = differenceResponce;
                } else {
                    this.difference = {};
                }

                if (emmitedResponce) {
                    this.emmited = emmitedResponce;
                } else {
                    this.difference = {};
                }

                this.loading = false;
            }
        );
    }

    getGraphDataInit() {
        this.graphLoading = true;

        this.graphQuery = {
            date_start: moment()
                .subtract(11, this.time_unit)
                .startOf(this.time_unit === 'month' ? 'month' : 'isoWeek')
                .format(MOMENT_FORMAT),
            date_end: this.maxDate,
            time_unit: this.time_unit,
        };

        const total = this.getGraphData();
        const collection = this.getGraphData('loan', 4);
        const due = this.getGraphData('loan', 5);
        const leads = this.getGraphData('lead');
        const inversion = this.kpiService.getBusinessInversion(this.graphQuery);
        const difference = this.kpiService.getBusinessDifference(
            this.graphQuery
        );

        this.graphSubscription = forkJoin([
            total,
            collection,
            due,
            leads,
            inversion,
            difference,
        ]).subscribe(
            ([
                totalResponce,
                collectionResponce,
                dueResponce,
                leadsResponce,
                inversionResponce,
                differenceResponce,
            ]: any) => {
                if (totalResponce) {
                    this.updateChart(totalResponce, 'total');
                }

                if (collectionResponce) {
                    this.updateChart(collectionResponce, 'collection');
                }

                if (dueResponce) {
                    this.updateChart(dueResponce, 'due');
                }

                if (leadsResponce) {
                    this.updateChart(leadsResponce, 'leads');
                }

                if (inversionResponce) {
                    this.updateChart(inversionResponce, 'inversion', {
                        amount: 0,
                    });
                }

                if (differenceResponce) {
                    this.updateChart(differenceResponce, 'difference');
                }

                this.graphLoading = false;
            }
        );
    }

    getGraphData(
        groupBy: string | 'loan' | 'lead' = '',
        loansStatus?: number | number[]
    ) {
        let query = {};

        if (groupBy === 'loan') {
            query = { ['loan_status_ids[]']: loansStatus };
        }
        if (groupBy === 'lead') {
            query = { lead: 1 };
        }

        return this.kpiService.getChargesEvolution({
            ...this.graphQuery,
            ...query,
        });
    }

    generateDates(type: 'isoWeek' | 'month' = 'isoWeek') {
        const subtract = type === 'isoWeek' ? 'week' : type;

        const length = 12;
        const data: any[] = [
            {
                first_day_time_unit: moment()
                    .startOf(type)
                    .format('YYYY-MM-DD'),
                last_day_time_unit: moment()
                    .subtract(subtract)
                    .format('YYYY-MM-DD'),
            },
        ];

        for (let i = 1; i < length; i++) {
            data.push({
                first_day_time_unit: moment()
                    .subtract(i, subtract)
                    .startOf(type)
                    .format('YYYY-MM-DD'),
                last_day_time_unit: moment()
                    .subtract(i, subtract)
                    .endOf(type)
                    .format('YYYY-MM-DD'),
            });
        }

        data.reverse();

        return data;
    }

    formatData(data: any[], replacement: any = { total_amount: 0 }) {
        const array = this.generateDates(
            this.time_unit === 'week' ? 'isoWeek' : this.time_unit
        ).map((date) => {
            const exist = data.find(
                (item) => item.first_day_time_unit === date.first_day_time_unit
            );

            return exist ? { ...exist, ...date } : { ...date, ...replacement };
        });

        return array;
    }

    createData(dataArray: any[]) {
        const data = dataArray.map(({ total_amount, amount }) =>
            Math.round(total_amount || amount || 0)
        );

        const labels = dataArray.map((item) =>
            this.time_unit === 'week'
                ? `${moment(item?.first_day_time_unit).format(
                      'DD/MM/YYYY'
                  )} - ${moment(item?.last_day_time_unit).format('DD/MM/YYYY')}`
                : moment(item?.first_day_time_unit).format('MMM YYYY')
        );

        return { data, labels };
    }

    updateChart(
        responce,
        instanceName: string,
        replacement: any = { total_amount: 0 }
    ) {
        if (this.chartInstances[instanceName]) {
            const { data, labels } = this.createData(
                this.formatData(responce, replacement)
            );

            this.chartInstances[instanceName].data.labels = labels;
            this.chartInstances[instanceName].data.datasets[0].data = data;

            this.chartInstances[instanceName].update();
        }
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.clearFilters = { ...clearFilters };
    }

    createChart(ctx, instanceName) {
        if (ctx) {
            this.chartInstances[instanceName] = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: 'rgba(105,53,110,0.5)',
                        },
                    ],
                },
                plugins: [ChartDataLabels],
                options: {
                    layout: {
                        padding: {
                            top: 16,
                        },
                    },
                    responsive: true,
                    aspectRatio: 5,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [
                            {
                                display: false,
                            },
                        ],
                        yAxes: [
                            {
                                display: false,
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            offset: 0,
                            font: {
                                size: 10,
                            },
                        },
                    },
                },
            });
        }
    }
}
