import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-blacklist-history',
    templateUrl: './blacklist-history.component.html',
    styleUrls: ['./blacklist-history.component.scss']
})
export class BlacklistHistoryComponent implements OnInit {
    @Input() history = null;

    constructor() { }

    ngOnInit() {
    }
}
