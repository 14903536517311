import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PaymentBankAccountService {
    constructor(private http: HttpClient) {}

    list(): Observable<any> {
        return this.http.get(environment.api_url + '/bank_accounts/list');
    }

    create(iban: string): Observable<any> {
        return this.http.post(environment.api_url + '/bank_accounts/create', {
            iban: iban,
        });
    }

    getListByUserId(user_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/user/${user_id}/bank_card/list`
        );
    }

    getListBankAccounts(): Observable<any> {
        return this.http.get(
            environment.api_url + '/payment/company_bank_account/list'
        );
    }

    getListProviders(): Observable<any> {
        return this.http.get(environment.api_url + '/payment/provider/list');
    }

    getListResources(): Observable<any> {
        return this.http.get(environment.api_url + '/payment/resource/list');
    }

    makePaymentBankAccount(data): Observable<any> {
        return this.http.post(
            environment.api_url + '/payment/bank_account_bank_transfer/make',
            data
        );
    }

    makePaymentBankCard(data): Observable<any> {
        return this.http.post(
            environment.api_url +
                '/payment/not_secure/bank_card_tokenized/make',
            data
        );
    }

    makePaymentBankCardSecure(data): Observable<any> {
        return this.http.post(
            environment.api_url + '/payment/secure/bank_card_tokenized/make',
            data
        );
    }

    makePaymentNewCard(data): Observable<any> {
        return this.http.post(
            environment.api_url + '/payment/bank_card_authorized/make',
            data
        );
    }

    addCard(data): Observable<any> {
        return this.http.post(
            environment.api_url + '/backoffice_bank_card/create',
            data
        );
    }

    checkStatus(id): Observable<any> {
        return this.http.get(
            environment.api_url + `/payment/${id}/status/check`
        );
    }

    sendRedsysForm(action, data) {
        let headers = new HttpHeaders({
            'access-control-allow-origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        return this.http.post(action, data, { headers: headers });
    }

    rectificationPayment(data): Observable<any> {
        return this.http.post(
            environment.api_url +
                `/payment/${data.payment_id}/fix/${data.type}`,
            {
                date: data.date,
            }
        );
    }

    loanDesist(loan_id): Observable<any> {
        return this.http.get(environment.api_url + `/loan/${loan_id}/desist`);
    }

    loanResolution(type, loan_id, reason_id): Observable<any> {
        return this.http.post(
            environment.api_url + `/loan/resolution/${type}`,
            { loan_id, reason_id }
        );
    }

    lateFeeRectification(loan_id, quota_id): Observable<any> {
        return this.http.post(
            environment.api_url +
                `/loan/${loan_id}/quota/${quota_id}/late_fee/revoke`,
            {}
        );
    }
    /*
  add(bank_account_id): Observable<any>
  {
    return this.http.post(environment.api_url + '/bank_accounts/create', {iban: bank_account_id});
  }
  */
}
