<section>
    <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <lead>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">LEAD</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">{{ lead?.code_str }}</p>
                    </div>
                </div>
            </lead>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <status>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">STATUS</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">{{ lead?.status.name }}</p>
                    </div>
                </div>
            </status>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <dni>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">DNI</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">{{ lead?.user?.personalid }}</p>
                    </div>
                </div>
            </dni>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <customer>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">
                            <div>CUSTOMER</div>
                        </h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">
                            {{ lead?.user?.name }}
                            {{ lead?.user?.first_surname }}
                            {{
                                lead?.user?.second_surname
                                    ? lead?.user?.second_surname
                                    : ''
                            }}
                        </p>
                        <div class="badges d-flex justify-content-center">
                            <span
                                *ngIf="lead?.user?.blacklisted"
                                class="badge badge-pill badge-dark"
                                >Blacklist</span
                            >
                            <span
                                *ngIf="lead?.user?.dropout"
                                class="badge badge-pill badge-warning"
                                >Drop out</span
                            >
                            <span
                                *ngIf="lead?.user?.is_asnef"
                                class="badge badge-pill badge-danger"
                                >ASNEF</span
                            >
                        </div>
                    </div>
                </div>
            </customer>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <mobile>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">Mobile</h5>
                    </div>
                    <div class="card-body">
                        <p
                            class="text-center"
                            [innerHTML]="formatPhone(lead?.mobile_phone)"
                        ></p>
                    </div>
                </div>
            </mobile>
        </div>
    </div>
</section>
<!-- top area end -->
