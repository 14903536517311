import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LeadService } from '../../../services/lead.service';
import { CreditLineService } from '../../../services/credit-line.service';
import { ValidCreditCardDirective } from '../../../../shared/directives/valid-credit-card.directive';

declare var googleChart: any;

@Component({
    selector: 'app-lead-info',
    templateUrl: './lead-info.component.html',
    styleUrls: ['./lead-info.component.css']
})
export class LeadInfoComponent implements OnInit {
    id: string;
    lead = null;
    leads: any = null;
    milinea_customer: any = null;
    savso_validation: any = null;
    asnef_validation: any = null;
    asnef_snapshot: any = null;
    credit_lines: any = null;
    scoring: any = null;
    loading = true;
    promises = [];
    bank_owner;
    constructor(
        private activate: ActivatedRoute,
        private router: Router,
        private leadService: LeadService,
        private creditLineService: CreditLineService
    ) {}

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];

            this.leadService.get(this.id).then((data: any) => {
                this.lead = data;

                this.getCreditLine(this.lead.user.id);
                this.getAllLeadByUserId(this.lead.user.id);
                this.getbankAggregationShow(this.id);
                this.getScoringShow(this.id);
                this.getMilineaCustomer(this.id);
                this.getAsnefShow(this.id);
                this.loading = false;
            });
        });
    }

    getMilineaCustomer(lead_id) {
        this.leadService.getMilineaCustomer(lead_id).then(customer => {
            this.milinea_customer = customer;
        });
    }

    getCreditLine(user_id) {
        this.creditLineService
            .getAllByUserId(user_id)
            .toPromise()
            .then((credit_lines: any) => {
                this.credit_lines = credit_lines;
            });
    }

    getAllLeadByUserId(user_id) {
        this.leadService.getAllByUserId(user_id).then((leads: any) => {
            this.leads = leads;
        });
    }

    getbankAggregationShow(lead_id) {
        this.leadService.bankAggregationShow(lead_id).then(response => {
            this.bank_owner = response;
        });
    }

    getScoringShow(lead_id) {
        this.leadService.scoringShow(lead_id).then(response => {
            this.scoring = response;
        });
    }

    getAsnefShow(lead_id) {
        this.leadService.asnefShow(lead_id).then(response => {
            this.asnef_snapshot = response;
        });
    }
}
