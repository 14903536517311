<div *appHasPermissions="['kpis-concessions']" class="container pt-3">
    <div class="row">
        <div class="col-lg-7">
            <div class="row justify-content-between align-items-end mb-3">
                <div class="col-auto">
                    <app-daterange-filter
                        fieldName="date"
                        [start]="filters.date_start"
                        [end]="filters.date_end"
                        (changeDate)="onChangeDate($event)"
                        withStartDate="true"
                        [disabled]="loading"
                        [resetDates]="calendarDates"
                    ></app-daterange-filter>
                    <small *ngIf="loading" class="ml-2 text-primary"
                        >Loading...</small
                    >
                </div>
                <div class="col-auto">
                    <h5 class="text-primary text-uppercase mb-0">Resumen</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 col-md-3 text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Leads</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-0">
                                    {{
                                        conversions?.solicitudes?.status_count
                                            | number: '1.0-0':'es'
                                    }}
                                </h4>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Completed</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-0">
                                    {{
                                        conversions?.completed?.status_count
                                            | number: '1.0-0':'es'
                                    }}<small class="ml-3 d-inline-block"
                                        >{{
                                            conversions?.completed?.percent
                                                | number: '1.0-0'
                                        }}%</small
                                    >
                                </h4>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Préstamos Emitidos</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-0">
                                    {{
                                        emitedTotal?.count
                                            | number: '1.0-0':'es'
                                    }}<small class="d-inline-block ml-3"
                                        >{{
                                            (emitedTotal?.count /
                                                conversions?.completed
                                                    ?.status_count) *
                                                100 | number: '1.0-0':'es'
                                        }}%</small
                                    >
                                </h4>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 text-center">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Inversión Crediticia</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-0">
                                    {{
                                        emitedTotal?.amount
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 pr-0">
                    <div class="card mb-3 h-100">
                        <div class="card-body">
                            <h5 class="text-primary text-uppercase">
                                Rendimiento conversión
                            </h5>
                            <table class="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="pl-0">Visitas</th>
                                        <th>-</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="pl-0">Solicitudes</td>
                                        <td
                                            class="bg-primary text-white"
                                            style="border-radius: 1rem 0 0 0"
                                        >
                                            {{
                                                conversions?.solicitudes
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white h6 mb-0"
                                            style="border-radius: 0 1rem 0 0"
                                        ></td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">SMS</td>
                                        <td class="bg-primary text-white">
                                            {{
                                                conversions?.prevalidated
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                        >
                                            {{
                                                conversions?.prevalidated
                                                    ?.percent | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">Card Validation</td>
                                        <td class="bg-primary text-white">
                                            {{
                                                conversions?.cardvalidation
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                        >
                                            {{
                                                conversions?.cardvalidation
                                                    ?.percent | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">Bank Login</td>
                                        <td class="bg-primary text-white">
                                            {{
                                                conversions?.banklogin
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                        >
                                            {{
                                                conversions?.banklogin?.percent
                                                    | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">Rejected</td>
                                        <td class="bg-primary text-white">
                                            {{
                                                conversions?.rejected
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                        >
                                            {{
                                                conversions?.rejected?.percent
                                                    | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">Offer</td>
                                        <td class="bg-primary text-white">
                                            {{
                                                conversions?.offeravailable
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                        >
                                            {{
                                                conversions?.offeravailable
                                                    ?.percent | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">Pending Contract</td>
                                        <td class="bg-primary text-white">
                                            {{
                                                conversions?.pendingcontract
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                        >
                                            {{
                                                conversions?.pendingcontract
                                                    ?.percent | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">Completed</td>
                                        <td
                                            class="bg-primary text-white"
                                            style="border-radius: 0 0 0 1rem"
                                        >
                                            {{
                                                conversions?.completed
                                                    ?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="bg-primary text-white font-weight-bold mb-0 text-right"
                                            style="border-radius: 0 0 1rem 0"
                                        >
                                            {{
                                                conversions?.completed?.percent
                                                    | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0 h5 mb-0 text-primary">
                                            Pagan
                                        </td>
                                        <td class="h5 mb-0 text-primary">
                                            {{
                                                conversions?.pagan?.status_count
                                                    | number: '1.0-0':'es'
                                            }}
                                        </td>
                                        <td
                                            class="h5 mb-0 text-primary text-right"
                                        >
                                            {{
                                                conversions?.pagan?.percent
                                                    | number: '1.0-0'
                                            }}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="card mb-3 h-100">
                        <div class="card-body">
                            <h5 class="text-primary text-uppercase mb-3">
                                Rechazo call center
                            </h5>
                            <div class="chart">
                                <canvas #pieChart></canvas>
                            </div>
                            <div
                                *ngIf="pieChartLegend"
                                [innerHtml]="pieChartLegend"
                            ></div>
                            <ul class="pieChartLegend border-top pb-2">
                                <li
                                    class="pieChartLegend__item mb-0 font-weight-bold"
                                >
                                    <span class="pieChartLegend__box"></span>
                                    <span class="pieChartLegend__text"
                                        >Total</span
                                    >
                                    <span class="pieChartLegend__value">{{
                                        pieChartTotal | number: '1.0-0':'es'
                                    }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Leads Semanales
                    </h5>
                    <div class="chart">
                        <canvas #leadWeeklyChart></canvas>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Completed Semanales
                    </h5>
                    <div class="chart">
                        <canvas #leadCompletedWeeklyChart></canvas>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Emitidos Semanales
                    </h5>
                    <div class="chart">
                        <canvas #leadIssuedWeeklyChart></canvas>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Leads Mensuales
                    </h5>
                    <div class="chart">
                        <canvas #leadMonthlyChart></canvas>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Completed Mensuales
                    </h5>
                    <div class="chart">
                        <canvas #leadCompletedMonthlyChart></canvas>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Emitidos Mensuales
                    </h5>
                    <div class="chart">
                        <canvas #leadIssuedMonthlyChart></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
