<div class="row">
    <div class="offset-xl-3 col-xl-6">
        <div class="card mb-3">
            <div class="card-header"><h5 class="card-header-title">LEAD DOCS</h5></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-6 text-center">
                        <button *ngIf="euform" (click)="open(content); viewEuform()" class="btn btn-accept">
                            EU FORM
                        </button>
                        <div *ngIf="!euform" class="btn btn-accept">Not Found</div>
                    </div>
                    <div class="col-xl-6 text-center">
                        <button *ngIf="contract" (click)="open(content); viewContract()" class="btn btn-accept">
                            CONTRACT
                        </button>
                        <div *ngIf="!contract" class="btn btn-accept">Not Found</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- lead doc tab page end -->
<!-- Modal -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body-lg">
        <img *ngIf="loading" width="50px" height="50px" src="assets/img/backoffice/loading.gif" />
        <pdf-viewer
            *ngIf="!loading"
            [src]="url"
            [render-text]="true"
            [render-text]="true"
            [external-link-target]="'self'"
            [autoresize]="true"
            [original-size]="true"
            style="display: block;"
        ></pdf-viewer>
    </div>
</ng-template>
