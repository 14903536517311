import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { TRIAL_PROCEEDING_STATUS } from '../../../../shared/enums/legal.enum';
import { LegalMonitoringService } from '../../../services/legal-monitoring.service';

type Status = {
    description: string;
    name: string;
    id: number;
};
type Situaion = Status;
@Component({
    selector: 'app-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent implements OnInit, OnDestroy {
    statuses: Status[] = [];
    situations: Situaion[] = [];

    listLoadingSubscriber: SubscriptionLike;
    showSublist = false;

    TRIAL_PROCEEDING_STATUS = TRIAL_PROCEEDING_STATUS;

    constructor(
        private legalMonitoringService: LegalMonitoringService,
        private router: Router,
        private activeRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.getStatus();

        this.listLoadingSubscriber =
            this.legalMonitoringService.listLoadingEmitted$.subscribe(
                (event) =>
                    (this.showSublist =
                        +event.status ===
                        this.TRIAL_PROCEEDING_STATUS.SUBMITTED)
            );
    }

    ngOnDestroy(): void {
        if (this.listLoadingSubscriber) {
            this.listLoadingSubscriber.unsubscribe();
        }
    }

    getStatus() {
        this.legalMonitoringService
            .getStatus()
            .toPromise()
            .then((response: Status[]) => {
                this.statuses = response;

                if (this.router.url === '/backoffice/legal/monitoring')
                    this.router.navigate([this.statuses[0].id], {
                        relativeTo: this.activeRoute,
                        replaceUrl: true,
                    });
            });

        this.legalMonitoringService
            .getSituations()
            .toPromise()
            .then((response: Situaion[]) => {
                this.situations = response;
            });
    }
}
