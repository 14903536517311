<form [formGroup]="rolePermissionForm" style="width: 22rem;" (ngSubmit)="onFormSubmit()">
    <!-- input roles -->
  Roles: 
  <select class="form-control" formControlName="role_id" (change)="getRolePermissions()"  required>
      <option [ngValue]="role.id" *ngFor="let role of authService.allRoles">{{role.display_name}}</option>
  </select>
</form>


    <div class="form-check" *ngFor="let permission of allPermissions" #containerPermissions>
        <input class="form-check-input" type="checkbox" (click)="changePermission($event)"  id="{{permission.name}}" [name]="permission.name">
        <label class="form-check-label" for="{{permission.name}}">
            {{permission.display_name}}
        </label>
    </div>
