import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../services/email.service';

@Component({
    selector: 'app-lead-email-log',
    templateUrl: './lead-email-log.component.html',
    styleUrls: ['./lead-email-log.component.css']
})
export class LeadEmailLogComponent implements OnInit {
    id: any = '';
    email_list: any = null;
    loading = true;
    constructor(private activate: ActivatedRoute, private emailService: EmailService) {}

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];
            this.getEmailList(this.id);
        });
    }

    getEmailList(lead_id) {
        this.emailService.getByLeadId(lead_id).subscribe(email => {
            if (Object.values(email).length) {
                this.email_list = email;
            }
            this.loading = false;
        });
    }
}
