import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ErrorMessageService } from '../../shared/error-message.service';
import { Promise } from 'q';

@Injectable()
export class LeadTrackingService {
    trackings: any = null;
    tracking_type: any = null;
    constructor(
        private http: HttpClient,
        private errorMessageService: ErrorMessageService
    ) {}

    getAllPaginatedByLeadId(lead_id, page) {
        return this.http
            .get(environment.api_url + `/tracking/lead/${lead_id}/list?${page}`)
            .toPromise()
            .then(
                (resp: any) => {
                    if (Object.values(resp.data).length) {
                        this.trackings = resp;
                    }
                    // this.tracking_type = resp.types;
                    return true;
                },
                error => {
                    if (error.error.error) {
                        this.errorMessageService.showError(
                            error.error.error.show_message
                        );
                    } else {
                        this.errorMessageService.showError(error.error.message);
                    }
                    return false;
                }
            );
    }

    getTemplates(type): Observable<any> {
        return this.http.get(environment.api_url + `/${type}/template/list`);
    }

    sendSms(data): Observable<any> {
        return this.http.post(environment.api_url + `/sms/send`, data);
    }
}
