import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    SimpleChange,
} from '@angular/core';
import { DaterangepickerComponent } from 'ng2-daterangepicker';

declare var moment: any;

@Component({
    selector: 'app-datepicker-filter',
    templateUrl: './datepicker-filter.component.html',
    styleUrls: ['./datepicker-filter.component.scss'],
})
export class DatepickerFilterComponent implements OnInit, OnChanges {
    @ViewChild(DaterangepickerComponent)
    private picker: DaterangepickerComponent;

    @Input() start: any = moment().format('YYYY-MM-DD');
    @Input() end: any = moment().format('YYYY-MM-DD');
    defaultDate: any = {};
    @Input()
    fieldName: string = '';
    @Input()
    withStartDate: boolean = false;
    @Input()
    reset: boolean = false;

    @Output()
    changeDate: EventEmitter<any> = new EventEmitter();

    settings: any = null;

    dateInputValue: string = '';

    minDate = '2015-01-01';
    maxDate = moment().format('YYYY-MM-DD');

    constructor() {}

    ngOnInit() {
        this.defaultDate = {
            start: this.start,
            end: this.end,
        };

        this.settings = {
            locale: {
                format: 'YYYY-MM-DD',
                cancelLabel: 'Clear',
            },
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            showDropdowns: true,
            ranges: {
                Today: [
                    moment().format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                Yesterday: [
                    moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    moment().subtract(1, 'days').format('YYYY-MM-DD'),
                ],
                'Last 5 Days': [
                    moment().subtract(4, 'days').format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                'This Week': [
                    moment().startOf('week').format('YYYY-MM-DD'),
                    moment().endOf('week').format('YYYY-MM-DD'),
                ],
                'Last Week': [
                    moment()
                        .subtract(1, 'week')
                        .startOf('week')
                        .format('YYYY-MM-DD'),
                    moment()
                        .subtract(1, 'week')
                        .endOf('week')
                        .format('YYYY-MM-DD'),
                ],
                'This Month': [
                    moment().startOf('month').format('YYYY-MM-DD'),
                    moment().endOf('month').format('YYYY-MM-DD'),
                ],
                'Last Month': [
                    moment()
                        .subtract(1, 'month')
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                    moment()
                        .subtract(1, 'month')
                        .endOf('month')
                        .format('YYYY-MM-DD'),
                ],
            },
            minDate: this.minDate,
            maxDate: this.withStartDate ? this.defaultDate.end : this.maxDate,
            startDate: this.defaultDate.start || moment().format('YYYY-MM-DD'),
            endDate: this.defaultDate.end || moment().format('YYYY-MM-DD'),
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.start && this.end) {
            this.defaultDate = {
                start: this.start,
                end: this.end,
            };
        }

        this.setStartDate();

        const reset: SimpleChange = changes.reset;
        const withStartDate: SimpleChange = changes.withStartDate;
        if (withStartDate) {
        }

        if (
            this.picker &&
            this.picker.datePicker &&
            !reset &&
            this.start &&
            this.end
        ) {
            this.picker.datePicker.hide();
            this.updateDateRange(this.defaultDate);
        }

        if (reset && reset.previousValue) {
            this.clearCalendar('reset');
        }
    }

    selectedDate(e: any) {
        const oldDate = this.dateInputValue;
        const dates = this.setDates(
            this.fieldName,
            e.picker.startDate.format('YYYY-MM-DD'),
            e.picker.endDate.format('YYYY-MM-DD')
        );

        this.dateInputValue =
            dates[this.fieldName + '_start'] +
            ' - ' +
            dates[this.fieldName + '_end'];

        if (oldDate !== this.dateInputValue) {
            this.changeDate.emit(dates);
        }
    }

    setStartDate() {
        this.dateInputValue =
            this.withStartDate && this.start !== '' && this.end !== ''
                ? this.defaultDate.start + ' - ' + this.defaultDate.end
                : '';
    }

    clearCalendar(e: any) {
        const reset = e === 'reset';
        if (reset || (e.picker && this.dateInputValue)) {
            this.dateInputValue =
                reset &&
                this.withStartDate &&
                this.start !== '' &&
                this.end !== ''
                    ? this.defaultDate.start + ' - ' + this.defaultDate.end
                    : '';

            const updateDates =
                reset && this.withStartDate
                    ? {
                          start: this.defaultDate.start,
                          end: this.defaultDate.end,
                      }
                    : {
                          start: moment().format('YYYY-MM-DD'),
                          end: moment().format('YYYY-MM-DD'),
                      };

            this.updateDateRange(updateDates);

            if (!reset) {
                this.changeDate.emit(this.setDates(this.fieldName, '', ''));
            }
        }
    }

    setDates(name, start, end) {
        const startName = name + '_start';
        const endName = name + '_end';
        return {
            [startName]: start,
            [endName]: end,
        };
    }

    updateDateRange(dates) {
        this.picker.datePicker.setStartDate(dates.start);
        this.picker.datePicker.setEndDate(dates.end);
    }
}
