import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocsService } from '../../../../../services/docs.service';
import { ErrorMessageService } from '../../../../../../shared/error-message.service';
import { Observable, Observer } from 'rxjs';
import {
    createButtonConfig,
    ImageViewerConfig,
    IMAGEVIEWER_CONFIG,
} from '@hallysonh/ngx-imageviewer';
import { saveAs } from 'file-saver';

export const LEGAL_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
    bgStyle: '#fff',
    buttonStyle: {
        iconFontFamily: 'FontAwesome',
        bgStyle: '#69356e',
        alpha: 1,
    },
    rotateRightButton: createButtonConfig('\uF01e', 'Rotate right', 4),
    rotateLeftButton: createButtonConfig('\uF0e2', 'Rotate left', 3),
    zoomInButton: createButtonConfig('\uF00e', 'Zoom in', 2),
    zoomOutButton: createButtonConfig('\uF010', 'Zoom out', 1),
    resetButton: createButtonConfig('\uF021', 'Reset', 0),
};

@Component({
    selector: 'app-monitoring-personal-docs',
    templateUrl: './monitoring-personal-docs.component.html',
    styleUrls: ['./monitoring-personal-docs.component.scss'],
    providers: [
        {
            provide: IMAGEVIEWER_CONFIG,
            useValue: LEGAL_IMAGEVIEWER_CONFIG,
        },
    ],
})
export class MonitoringPersonalDocsComponent implements OnInit {
    @Input() loan;
    docs: any;
    image;
    isLoading = false;

    canvasWidth = '';
    canvasHeight = '';

    leadId;

    contract = null;
    euform = null;
    fileURL = null;
    url = null;

    isLoadingPersonalDoc = false;
    isLoadingDocs = false;

    constructor(
        private docsService: DocsService,
        private modalService: NgbModal,
        private errorService: ErrorMessageService
    ) {}

    ngOnInit(): void {
        if (this.loan) {
            this.getLeadDocs(this.loan);
        }
    }

    getLeadDocs(loan) {
        this.isLoading = true;
        const { document, lead_id } = loan;
        if (Object.values(document).length) {
            this.docs = document;
        }
        if (lead_id) {
            this.leadId = lead_id;
            this.getLoanDocs(lead_id);
        } else {
            this.isLoading = false;
        }
    }

    open(content) {
        this.modalService.open(content, {
            size: 'lg',
            windowClass: 'docsModal',
            backdropClass: 'docsBackdrop',
        });

        setTimeout(() => {
            const modal = document.querySelector('.docsModal .modal-body-lg');

            this.canvasWidth = `${modal.clientWidth}`;
            this.canvasHeight = `${modal.clientHeight}`;
        }, 300);
    }

    view(docName) {
        this.image = '';
        this.isLoadingPersonalDoc = true;

        this.docsService
            .viewClientDocument(docName, this.docs.loan_id)
            .subscribe(
                (image) => {
                    this.dataURItoBlob(image as string).subscribe(
                        (blob: Blob) => {
                            const imageBlob: Blob = blob;
                            const imageName: string = this.generateName();
                            const imageFile: File = new File(
                                [imageBlob],
                                imageName,
                                {
                                    type: 'image/jpeg',
                                }
                            );

                            this.image = imageFile;
                            this.isLoadingPersonalDoc = false;
                        }
                    );
                },
                (err) => {
                    this.errorService.showError(
                        err?.error?.error?.show_message
                    );
                }
            );
    }

    openImage() {
        const generatedImage = window.URL.createObjectURL(this.image);
        window.open(generatedImage);
    }

    donwloadImage() {
        const generatedImage = window.URL.createObjectURL(this.image);
        const tag = document.createElement('a');
        tag.href = generatedImage;
        tag.download = this.generateName();
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }

    /* Method to convert Base64Data Url as Image Blob */
    dataURItoBlob(dataURI: string): Observable<Blob> {
        return new Observable((observer: Observer<Blob>) => {
            const byteString: string = window.atob(dataURI);
            const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([int8Array], { type: 'image/png' });
            observer.next(blob);
            observer.complete();
        });
    }

    /**Method to Generate a Name for the Image */
    generateName(): string {
        const date: number = new Date().valueOf();
        let text: string = '';
        const possibleText: string =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(
                Math.floor(Math.random() * possibleText.length)
            );
        }
        // Replace extension according to your media type like this
        return date + '.' + text + '.jpeg';
    }

    openLoanDocs(content) {
        this.modalService.open(content, { size: 'lg' });
    }

    getLoanDocs(lead_id) {
        this.docsService.getLeadDocs(lead_id).subscribe((docs) => {
            if (Object.values(docs).length) {
                this.contract = docs.contract;
                this.euform = docs.eu_form;
            }
            this.isLoading = false;
        });
    }

    viewContract() {
        if (this.contract) {
            this.isLoadingDocs = true;
            this.docsService.view(this.leadId).subscribe((pdf) => {
                let file = new Blob([pdf], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                this.url = this.fileURL;
                this.isLoadingDocs = false;
            });
        }
    }

    viewEuform() {
        if (this.euform) {
            this.isLoadingDocs = true;
            this.docsService.viewEuform(this.leadId).subscribe((pdf) => {
                let file = new Blob([pdf], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                this.url = this.fileURL;
                this.isLoadingDocs = false;
            });
        }
    }

    downloadContract(e) {
        e.preventDefault();
        if (this.contract) {
            this.docsService.view(this.leadId).subscribe((pdf) => {
                let file = new Blob([pdf], {
                    type: 'application/pdf',
                });
                saveAs(file, 'contract.pdf');
            });
        }
    }

    downloadEuform(e) {
        e.preventDefault();
        if (this.euform) {
            this.docsService.viewEuform(this.leadId).subscribe((pdf) => {
                let file = new Blob([pdf], {
                    type: 'application/pdf',
                });
                saveAs(file, 'euform.pdf');
            });
        }
    }
}
