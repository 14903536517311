import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-credit-line-test',
    templateUrl: './credit-line-test.component.html',
    styleUrls: ['./credit-line-test.component.scss']
})
export class CreditLineTestComponent implements OnInit {
    filterParams: object = {};
    i = 0;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private location: Location) {}

    ngOnInit() {
        // router.events
        //     .filter(event => event instanceof NavigationEnd)
        //     .subscribe((e: any) => {
        //         this.previousUrl = this.currentUrl;
        //         this.currentUrl = e.url;
        //     });

        console.log(this.activatedRoute.snapshot.params);

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e => {
            if (e) {
                // console.log(event);
                console.log(this.activatedRoute.snapshot.params);
            }
        });
    }

    ngOnChange() {}

    addParams() {
        this.filterParams[`param${this.i ? this.i : ''}`] = this.i++;

        const url = this.router.createUrlTree([this.filterParams], { relativeTo: this.activatedRoute }).toString();
        console.log(url);
        this.location.go(url);
    }
}
