import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lead-info-customer-credit-line',
  templateUrl: './lead-info-customer-credit-line.component.html',
  styleUrls: ['./lead-info-customer-credit-line.component.css']
})
export class LeadInfoCustomerCreditLineComponent implements OnInit {

  @Input() credit_lines: any = null;

  constructor() { }

  ngOnInit() {
  }

}
