import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-validation-savso',
  templateUrl: './setting-validation-savso.component.html',
  styleUrls: ['./setting-validation-savso.component.scss']
})
export class SettingValidationSavsoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
