<a href="#" style="text-decoration: none" (click)="open($event, modal)">
    <i class="fa fa-eye d-block" style="font-size: 2rem; cursor: pointer; line-height: 24px;"></i>
</a>

<ng-template #modal let-c="close">
    <div class="modal-header">
        <h4 class="modal-title">Affiliate Logs({{ (logs?.data)[0]?.affiliate_name }})</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            Displaying {{ logs?.from ? logs?.from : '00' }}-{{ logs?.to ? logs?.to : '00' }} of
            {{ logs?.total ? logs?.total : '00' }} results
        </div>
        <app-pager
            [perPage]="15"
            [currentPage]="logs?.current_page"
            [total]="logs?.total"
            (changePage)="getLogs($event)"
        >
        </app-pager>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Lead</th>
                        <th>Created</th>
                        <th>Full Name</th>
                        <th>DNI/NIE</th>
                        <th>Channel</th>
                        <th>Campaign</th>
                        <th>Medium</th>
                        <th>Source</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template [ngIf]="logs?.data?.length > 0 && !loading">
                        <tr *ngFor="let log of logs?.data">
                            <td>{{ log?.code_str }}</td>
                            <td>{{ log?.created_at }}</td>
                            <td>{{ log?.full_name }}</td>
                            <td>{{ log?.personalid }}</td>
                            <td>{{ log?.channel_name }}</td>
                            <td>{{ log?.utm_campaign }}</td>
                            <td>{{ log?.utm_medium }}</td>
                            <td>{{ log?.utm_source }}</td>
                            <td>{{ log?.utm_data }}</td>
                        </tr>
                    </ng-template>

                    <ng-template [ngIf]="!logs?.data?.length && !loading">
                        <tr class="tr-no-hover">
                            <td colspan="9">
                                <p class="py-5 mb-0">
                                    Nothing Found
                                </p>
                            </td>
                        </tr>
                    </ng-template>
                    <tr class="tr-no-hover" *ngIf="loading">
                        <td colspan="9">
                            <p class="py-5 mb-0">
                                <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            Displaying {{ logs?.from ? logs?.from : '00' }}-{{ logs?.to ? logs?.to : '00' }} of
            {{ logs?.total ? logs?.total : '00' }} results
        </div>
        <app-pager
            [perPage]="15"
            [currentPage]="logs?.current_page"
            [total]="logs?.total"
            (changePage)="getLogs($event)"
        >
        </app-pager>
    </div>
</ng-template>
