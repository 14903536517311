import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolConfig,
} from '@editorjs/editorjs';

const TOOLBOX_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 336 276"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"/></svg>`;
enum UI_STATUS {
  INIT = 'INIT',
  INIT_WITH_DATA = 'INIT_WITH_DATA',
  LOADING = 'LOADING',
  IMAGE_LOADED = 'IMAGE_LOADED',
}

type IData = {
  url: string;
  variable: string;
  caption: string;
  width: number;
  height: number;
  stretched: boolean;
  link: string;
};

export default class BenkiSimpeImage implements BlockTool {
  private _data: IData;
  private _block: HTMLDivElement;
  private _uiStatus: UI_STATUS;
  private _templateVaribles: any[];

  api: API;
  config: ToolConfig;
  readOnly: boolean;
  block: BlockAPI;

  settings: { name: string; icon: string }[];

  private _CSS: {
    block: string;
    loading: string;
    input: string;
    settingsButton: string;
    settingsButtonActive: string;
    wrapper: string;
    imageHolder: string;
    imageInput: string;
    caption: string;
  };

  private _nodes: {
    wrapper: HTMLElement;
    imageHolder: HTMLElement;
    image: HTMLImageElement;
    imageInput: HTMLElement;
    caption: HTMLElement;
  } = {
    wrapper: null,
    imageHolder: null,
    image: null,
    imageInput: null,
    caption: null,
  };

  constructor({
    api,
    data,
    config,
    readOnly,
    block,
  }: BlockToolConstructorOptions) {
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    this.config = config;

    this._templateVaribles = this.config.varibles;

    this._CSS = {
      block: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      wrapper: 'cdx-benki-simple-image',
      imageHolder: 'cdx-benki-simple-image__picture',
      imageInput: 'cdx-benki-simple-image__scr',
      caption: 'cdx-benki-simple-image__caption',
    };

    this.data = {
      url: data.url || '',
      variable: data.variable || '',
      caption: data.caption || '',
      width: data.width || '',
      height: data.height || '',
      stretched: !!data?.stretched,
      link: data.link || '',
    } as IData;

    this.settings = [
      {
        name: 'stretched',
        icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`,
      },
    ];

    this._block = this._drawView();
  }

  private _drawView(): HTMLDivElement {
    const wrapper = this._make('DIV', [
      this._CSS.wrapper,
      this._CSS.block,
    ]) as HTMLDivElement;
    const imageInput = this._make(
      'DIV',
      [this._CSS.input, this._CSS.imageInput],
      { contentEditable: !this.readOnly, innerHTML: this.data.url }
    ) as HTMLElement;

    imageInput.dataset.placeholder =
      'Enter link to image or shortcode variable';

    wrapper.appendChild(imageInput);

    this._nodes.wrapper = wrapper;
    this._nodes.imageInput = imageInput;

    if (this.data.url) {
      this.validate(this.data);
    }

    return wrapper;
  }

  private _drawImg(imgUrl: string) {
    this._uiStatus = UI_STATUS.LOADING;

    const image = this._make('IMG') as HTMLImageElement;
    const imageHolder = this._make('div', this._CSS.imageHolder);
    const loader = this._make('div', this._CSS.loading);
    const caption = this._make('DiV', [this._CSS.input, this._CSS.caption], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.caption || '',
    });

    caption.dataset.placeholder = 'Enter a caption or leave it empty';

    if (imgUrl) {
      image.src = imgUrl;
    }

    this._nodes.wrapper.classList.add(this._CSS.loading);
    this._nodes.wrapper.appendChild(loader);
    this._nodes.imageInput.classList.add('d-none');

    image.onload = () => {
      this._nodes.wrapper.classList.remove(this._CSS.loading);
      imageHolder.appendChild(image);
      this._nodes.wrapper.appendChild(imageHolder);
      this._nodes.wrapper.appendChild(caption);

      this.data.width = image.width;
      this.data.height = image.height;
      this.data.link = image.src;

      loader.remove();
    };

    this._nodes.imageHolder = imageHolder;
    this._nodes.image = image;
    this._nodes.caption = caption;

    this._uiStatus = UI_STATUS.IMAGE_LOADED;
  }

  render(): HTMLDivElement {
    return this._block;
  }

  renderSettings() {
    const wrapper = document.createElement('div');

    this.settings.forEach((tune) => {
      const el = document.createElement('div');

      el.classList.add(this._CSS.settingsButton);
      el.innerHTML = tune.icon;

      el.addEventListener('click', () => {
        if (
          this._uiStatus === UI_STATUS.INIT_WITH_DATA ||
          this._uiStatus === UI_STATUS.IMAGE_LOADED
        ) {
          this._toggleTune(tune.name);
          el.classList.toggle(this._CSS.settingsButtonActive);
        }
      });

      if (
        this._uiStatus === UI_STATUS.INIT_WITH_DATA ||
        this._uiStatus === UI_STATUS.IMAGE_LOADED
      ) {
        el.classList.toggle(
          this._CSS.settingsButtonActive,
          this.data[tune.name]
        );
      }

      wrapper.appendChild(el);
    });

    return wrapper;
  }

  validate(savedData: IData) {
    const { url } = savedData;

    if (url.trim() === '') {
      return false;
    }

    const templateReg = new RegExp(/^{{\w+}}$/);
    const imgUrlReg = new RegExp(/https:\/\/\S+\.(gif|jpe?g|tiff|png|webp)$/);
    const isTemplateVar = templateReg.test(url);
    const isImgUrl = imgUrlReg.test(url);

    if (!isTemplateVar && !isImgUrl) {
      return false;
    }

    if (isImgUrl && this._uiStatus !== UI_STATUS.IMAGE_LOADED) {
      this._drawImg(url);
    }

    if (isTemplateVar && this._uiStatus !== UI_STATUS.IMAGE_LOADED) {
      const templateVarValue = this._checkTemplateVariable(url);

      if (imgUrlReg.test(templateVarValue)) {
        this._drawImg(templateVarValue);
      } else {
        return false;
      }
    }

    return true;
  }

  save(toolsContent: Element) {
    const imageSrc = toolsContent.querySelector(
      '.' + this._CSS.imageInput
    ) as HTMLElement;

    const caption = toolsContent.querySelector(
      '.' + this._CSS.caption
    ) as HTMLElement;

    if (!imageSrc) {
      return this.data;
    }

    const data = {
      ...this.data,
      url: imageSrc.innerHTML,
      caption: caption?.innerHTML || '',
    };

    return data;
  }

  get data(): IData {
    return this._data;
  }

  set data(data: IData) {
    this._data = { ...this.data, ...data };

    this._uiStatus = this._data.url ? UI_STATUS.INIT_WITH_DATA : UI_STATUS.INIT;

    if (this._nodes?.imageInput) {
      this._nodes.imageInput.innerHTML = this._data.url;
    }
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      icon: TOOLBOX_ICON,
      title: 'Image',
    };
  }

  static get sanitize() {
    return {
      url: {},
      width: {},
      height: {},
      stretched: {},
      caption: {
        br: true,
      },
    };
  }

  private _toggleTune(tune) {
    this.data[tune] = !this.data[tune];
    this._acceptTuneView();
  }

  private _acceptTuneView() {
    this.settings.forEach((tune) => {
      this._nodes.imageHolder.classList.toggle(
        this._CSS.imageHolder +
          '--' +
          tune.name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`),
        !!this.data[tune.name]
      );

      if (tune.name === 'stretched') {
        this.block.stretched = !!this.data.stretched;
      }
    });
  }

  private _checkTemplateVariable(url: string): string {
    let variableValue = '';

    const variable = this._templateVaribles.filter(
      (varible) => `{{${varible.name_template}}}` === url
    );

    if (variable.length > 0 && variable[0].value) {
      variableValue = variable[0].value;
    }

    return variableValue;
  }

  private _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
