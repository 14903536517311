import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalMonitoringService } from '../../../../../services/legal-monitoring.service';

@Component({
    selector: 'app-presentation-list',
    templateUrl: './presentation-list.component.html',
    styleUrls: ['./presentation-list.component.scss'],
})
export class PresentationListComponent implements OnInit {
    @Input() proceedingId: number;
    @Input() presentationId: number;

    isLoading = false;
    tableData = [];

    presentation;

    constructor(
        private legalMonitoringService: LegalMonitoringService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.getTableData(this.proceedingId);
    }

    open(content, presentation) {
        this.presentation = presentation;
        this.modalService.open(content, { scrollable: true }).result.then(
            (result) => {
                this.presentation = null;
            },
            (reason) => {
                this.presentation = null;
            }
        );
    }

    getTableData(proceedingId) {
        this.isLoading = true;
        this.legalMonitoringService
            .getPresentations(proceedingId)
            .then((response) => {
                if (response.length > 1) {
                    this.tableData = response.filter(
                        ({ id }) => +id !== +this.presentationId
                    );

                    if (this.tableData.length > 1) {
                        this.tableData.sort(
                            (a, b) =>
                                +(
                                    new Date(a?.closed_at) >
                                    new Date(b?.closet_at)
                                )
                        );
                    }
                }
                this.isLoading = false;
            });
    }
}
