import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../../services/faq.service';

@Component({
    selector: 'app-settings-faq-list',
    templateUrl: './settings-faq-list.component.html',
    styleUrls: ['./settings-faq-list.component.scss']
})
export class SettingsFaqListComponent implements OnInit {
    constructor(public faqService: FaqService) {}

    ngOnInit() {
        this.faqService.getAll();
    }
}
