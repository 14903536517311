<td class="align-middle border-top-0 pt-2 pb-1">
  <input
    class="form-control"
    name="amount"
    [(ngModel)]="model.amount"
    #amount="ngModel"
    type="number"
    placeholder="Amount"
    required
    [ngClass]="{ 'is-invalid': amount.invalid && !amount.pristine }"
  />
</td>
<td class="align-middle border-top-0 pt-2 pb-1">
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      name="success"
      [(ngModel)]="model.success"
      #success="ngModel"
      (ngModelChange)="onModelChange('success')"
      id="createNodeSuccess"
    />
    <label class="custom-control-label" for="createNodeSuccess"></label>
  </div>
</td>
<td class="align-middle border-top-0 pt-2 pb-1">
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      name="failure"
      [(ngModel)]="model.failure"
      #failure="ngModel"
      (ngModelChange)="onModelChange('failure')"
      id="createNodeFailure"
    />
    <label class="custom-control-label" for="createNodeFailure"></label>
  </div>
</td>
<td class="align-middle border-top-0 pt-2 pb-1">
  <select
    *ngIf="nodes && nodes.length > 0"
    class="form-control"
    name="success_node_id"
    [(ngModel)]="model.success_node_id"
    #successNodeId="ngModel"
    [disabled]="!model.success"
  >
    <option value="">Select Node</option>
    <option *ngFor="let item of nodes" [value]="item.id">{{
      item?.amount | currency: 'EUR'
    }}</option>
  </select>
  <span *ngIf="!nodes.length">No Success Nodes</span>
</td>
<td class="align-middle border-top-0 pt-2 pb-1">
  <select
    *ngIf="nodes && nodes.length > 0"
    class="form-control"
    name="failure_node_id"
    [(ngModel)]="model.failure_node_id"
    #failureNodeId="ngModel"
    [disabled]="!model.failure"
  >
    <option value="">Select Node</option>
    <option *ngFor="let item of nodes" [value]="item.id">{{
      item?.amount | currency: 'EUR'
    }}</option>
  </select>
  <span *ngIf="!nodes.length">No Failure Nodes</span>
</td>
<td class="align-middle border-top-0 pt-2 pb-1 text-right">
  <button
    (click)="onSubmit(amount)"
    class="btn btn-primary"
    [disabled]="amount.invalid || isSending"
  >
    {{ isSending ? 'Saving...' : 'Create' }}
  </button>
</td>
