import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-setting-user-list',
    templateUrl: './setting-user-list.component.html',
    styleUrls: ['./setting-user-list.component.scss']
})
export class SettingUserListComponent implements OnInit {
    constructor(public authService: AuthService) {}

    ngOnInit() {
        this.getAllUsers();
    }

    getAllUsers() {
        this.authService.getAllUsersWithRoles();
    }
    separateByComma(list) {
        if (list) {
            let listSeparateByComma = '';
            list.forEach(function(item, ind, array) {
                const coma = ind === array.length - 1 ? '' : ', ';
                listSeparateByComma += item.display_name + coma;
            }, listSeparateByComma);
            return listSeparateByComma;
        }
    }
}
