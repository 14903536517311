export enum TEMPLATE_ACTIVATION_ENUM {
    TEMPLATE = 'templates',
    TEMPLATE_VARIABLE = 'template_variable',
    MESSAGE_TYPE = 'message_type',
}

export enum TEMPLATE_CONTENT_TYPE_ENUM {
    HTML = 'email',
    PLAIN_TEXT = 'sms',
}
