<div class="mb-3 container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="mb-3">
            <h2>{{ id ? 'Edit' : 'New' }} Template</h2>
            <div class="custom-control custom-switch">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="content_type"
                    formControlName="content_type"
                    name="content_type"
                />
                <label class="custom-control-label" for="content_type"
                    >EMAIL</label
                >
            </div>
        </div>

        <div class="row align-items-end">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Name*"
                        formControlName="name"
                        required
                    />
                </div>
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Description*"
                        formControlName="description"
                        required
                    />
                </div>
                <div class="form-group d-flex align-items-center">
                    <div
                        class="custom-control custom-checkbox"
                        [ngClass]="{ 'd-none': isPublished }"
                    >
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            formControlName="enabled"
                            id="customCheck1"
                        />
                        <label class="custom-control-label" for="customCheck1"
                            >Enabled</label
                        >
                    </div>
                    <div
                        *ngIf="isPublished"
                        class="d-none w-100 align-items-center"
                        [ngClass]="{ 'd-flex': isPublished }"
                    >
                        Enabled:
                        <app-mail-templates-activation
                            [id]="id"
                            [enabled]="form.controls.enabled.value"
                            [type]="type"
                            class="ml-3"
                        ></app-mail-templates-activation>
                    </div>
                    <button
                        *ngIf="!isPublished"
                        type="submit"
                        [ngClass]="{
                            'btn-primary': !id,
                            'btn-outline-primary': id
                        }"
                        class="btn ml-auto"
                        [disabled]="isSending || form.invalid"
                    >
                        {{ isSending ? 'Saving...' : 'Save' }}
                    </button>
                    <app-mail-templates-publishing
                        *ngIf="!isPublished && id"
                        [id]="id"
                        [type]="type"
                        [class]="'ml-3 btn btn-primary'"
                        (published)="onPublish($event)"
                        [disabled]="isSending || form.invalid"
                    ></app-mail-templates-publishing>
                </div>
            </div>
            <div class="col-12 col-lg-auto ml-auto mb-3">
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="openVariables(contenVaribles)"
                >
                    See Variables
                </button>
                <button
                    type="button"
                    class="ml-3 btn btn-outline-primary"
                    [disabled]="form.controls.content.invalid"
                    (click)="open(content)"
                >
                    Preview
                </button>
            </div>
            <div class="col-12">
                <app-email-editor
                    *ngIf="ready"
                    (onChange)="onEditorChange($event)"
                    [startEditorData]="startTemplate"
                    [templateVariables]="templateVariables"
                    [isReadonly]="isPublished"
                    [isHTML]="form.controls.content_type.value"
                ></app-email-editor>
                <textarea
                    *ngIf="!form.controls.content_type.value"
                    name="content"
                    formControlName="content"
                    class="form-control"
                    required
                    (input)="onContentInput()"
                ></textarea>
            </div>
        </div>
    </form>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Template Preview</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="sanitazedTemplate"></div>
    </div>
</ng-template>

<ng-template #contenVaribles let-modal>
    <div class="modal-header align-items-center">
        <h4 class="modal-title" id="modal-basic-title">Template Variables</h4>
        <app-mail-templates-variables-create
            class="ml-3"
            (saved)="onSaved($event)"
        ></app-mail-templates-variables-create>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-mail-templates-variables-list
            [addedItem]="addedItem"
        ></app-mail-templates-variables-list>
    </div>
</ng-template>
