<!-- line info tab page -->

<div class="row">
    <div class="col-md-3">
        <!-- left accordion -->
        <div id="left-accordion" role="tablist">
            <app-line-info-basic-info
                [creditLine]="creditLine"
                [creditLineCheck]="creditLine?.creditLineCheck"
                [accountManager]="activetedUser"
                [lead]="creditLine?.lead"
                [loading]="loading"
                [link]="leadLink"
            ></app-line-info-basic-info>

            <div class="card mt-3">
                <div class="card-header" role="tab" id="headingLeftTwo">
                    <h5 class="mb-0">Loan Documents</h5>
                </div>
                <div
                    id="collapseLeftTwo"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingLeftTwo"
                    data-parent="#accordion"
                >
                    <div class="card-body">
                        <div class="form-row">
                            <div class="col-xl-6 text-left mb-2 mb-xl-0">
                                <div *ngIf="euform" class="btn-group">
                                    <button
                                        (click)="open(content); viewEuform()"
                                        type="button"
                                        class="btn btn-accept"
                                    >
                                        EU FORM
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        (click)="downloadEuform($event)"
                                    >
                                        <i class="fa fa-download"></i>
                                    </button>
                                </div>
                                <div *ngIf="!euform" class="btn btn-accept">
                                    Not Found
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div
                                    *ngIf="contract"
                                    class="btn-group btn-group float-xl-right"
                                >
                                    <button
                                        (click)="open(content); viewContract()"
                                        type="button"
                                        class="btn btn-accept"
                                    >
                                        CONTRACT
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        (click)="downloadContract($event)"
                                    >
                                        <i class="fa fa-download"></i>
                                    </button>
                                </div>
                                <div
                                    *ngIf="!contract"
                                    class="btn btn-secondary"
                                >
                                    Not Found
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- left accordion end -->
    </div>
    <div class="col-md-3">
        <!-- center accordion -->
        <div id="center-accordion" role="tablist">
            <app-line-info-customer-info
                [lead]="creditLine?.lead"
                [user]="creditLine?.user"
                [loading]="loading"
            >
            </app-line-info-customer-info>
        </div>
        <!-- center accordion end -->
    </div>
    <div class="col-md-3">
        <!-- right accordion -->
        <div id="right-accordion" role="tablist">
            <div class="card mb-3">
                <div class="card-header" role="tab" id="headingRigthOne">
                    <h5 class="mb-0">
                        Debt Left
                        <span style="float: right">{{
                            debtLeftSummary?.debt_left | currency: 'EUR'
                        }}</span>
                    </h5>
                </div>
                <div
                    id="collapseRigthOne"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingRigthOne"
                    data-parent="#accordion"
                >
                    <div class="card-body">
                        <img
                            *ngIf="loading"
                            width="50px"
                            height="50px"
                            src="assets/img/backoffice/loading.gif"
                        />
                        <ul class="list-group" *ngIf="!loading">
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Amount Owed</strong
                                ><span>{{
                                    debtLeftSummary?.amount | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Fee Owed</strong
                                ><span>{{
                                    debtLeftSummary?.fee | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Late Fee Owed</strong
                                ><span>{{
                                    debtLeftSummary?.late_fee_amount
                                        | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Discount</strong
                                ><span>{{
                                    debtLeftSummary?.discount_amount
                                        | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Loss</strong
                                ><span>{{
                                    debtLeftSummary?.debt_lost | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Total Paid</strong
                                ><span>{{
                                    debtLeftSummary?.total_amount_paid
                                        | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Total Debt</strong
                                ><strong>{{
                                    debtLeftSummary?.totalDebt | currency: 'EUR'
                                }}</strong>
                            </li>
                        </ul>
                        <hr *ngIf="!loading" />
                        <ul class="list-group" *ngIf="!loading">
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Loan Amount</strong
                                ><span>{{
                                    debtLeftSummary?.amount | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Weeks</strong
                                ><span>{{ debtLeftSummary?.weeks }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Interest Rate</strong
                                ><span>{{
                                    debtLeftSummary?.interest_rate
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Weekly Quota</strong
                                ><span>{{
                                    debtLeftSummary?.weekly | currency: 'EUR'
                                }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <app-loan-rectification
            *ngIf="!isCheckView"
            [loan_id]="creditLine?.id"
        ></app-loan-rectification>
        <!-- right accordion end -->
    </div>
    <div class="col-md-3">
        <div class="fluid-row">
            <!-- <minimum-payment>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title">Minimum Payment</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong></strong><span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </minimum-payment> -->
            <fess-summary>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="mb-0">
                            Fees Summary
                            <span style="float: right">{{
                                feesSummary | currency: 'EUR'
                            }}</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <img
                            *ngIf="loading"
                            width="50px"
                            height="50px"
                            src="assets/img/backoffice/loading.gif"
                        />
                        <ul class="list-group" *ngIf="!loading">
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Fees</strong
                                ><span>{{
                                    debtLeftSummary?.fee | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Late Fees</strong
                                ><span>{{
                                    debtLeftSummary?.late_fee_amount
                                        | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Discount</strong
                                ><span>{{
                                    debtLeftSummary?.discount_amount
                                        | currency: 'EUR'
                                }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Loss</strong
                                ><span>{{
                                    debtLeftSummary?.debt_lost | currency: 'EUR'
                                }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </fess-summary>

            <div class="card mb-3" *ngIf="paymentBankCard?.length">
                <div class="card-header" role="tab" id="headingCenterTwo">
                    <h5 class="mb-0">Credit Card info</h5>
                </div>
                <div
                    id="collapseCenterTwo"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingCenterTwo"
                    data-parent="#accordion"
                >
                    <div
                        class="card-body"
                        style="max-height: 230px; overflow: auto"
                    >
                        <ul
                            class="list-group"
                            *ngFor="
                                let card of paymentBankCard;
                                let last = last
                            "
                        >
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Number</strong
                                ><span>{{ card?.pan }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Expiration Month</strong
                                ><span>{{ card?.expiration_month }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Expiration Year</strong
                                ><span>{{ card?.expiration_year }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Card Type</strong
                                ><span>{{ card?.type.name }}</span>
                            </li>
                            <li
                                class="
                                    list-group-item
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <strong>Card Status</strong
                                ><span>{{ card?.status?.name }}</span>
                            </li>
                            <li *ngIf="!last" class="list-group-item p-0">
                                <hr />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="debtLeftSummary?.debt_left != 0 && !isCheckView">
                <app-process-payment
                    [pageUrl]="pageHref"
                    [debt]="debtLeftSummary"
                    [creditline]="creditLine"
                    (paymentDone)="updateCreditline($event)"
                ></app-process-payment>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isCheckView" class="margin-top-20 table-responsive">
    <table class="table table-bordered table-hover">
        <thead class="bg-green-light">
            <tr>
                <th></th>
                <th>Order</th>
                <th>Status</th>
                <th>Planned</th>
                <th>Done</th>
                <th>Attempts</th>
                <th>Last Payment</th>
                <th>Amount</th>
                <th>Remaining Amount</th>
                <th>Initial Amount</th>
                <th>Late Fee</th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="!loading">
            <ng-template
                let-item
                ngFor
                [ngForOf]="creditLineOperations"
                let-i="index"
            >
                <tr
                    [ngClass]="{
                        'table-success': item?.quota?.status === 'Paid',
                        'table-danger': item?.quota?.status === 'Unpaid',
                        'table-warning': item?.pendingDanger
                    }"
                >
                    <td class="text-center align-middle">
                        <button
                            *ngIf="item.quota.exists_payment"
                            type="button"
                            class="btn text-primary"
                            (click)="openQuotaPaymentList(item.quota.id, i)"
                        >
                            <i
                                *ngIf="!item[item.quota.id].open"
                                class="fa fa-plus"
                            ></i
                            ><i
                                *ngIf="item[item.quota.id].open"
                                class="fa fa-minus"
                            ></i>
                        </button>
                    </td>
                    <td class="text-center align-middle">
                        {{ item.quota.order }}
                    </td>
                    <td class="align-middle">{{ item.quota.status }}</td>
                    <td class="align-middle">
                        {{ formatDate(item.quota.date) | date: 'dd/MM/yyyy' }}
                    </td>
                    <td class="align-middle">
                        {{
                            formatDate(item.quota.last_payment_date)
                                | date: 'dd/MM/yyyy'
                        }}
                    </td>
                    <td class="align-middle">{{ item.quota.attemps }}</td>
                    <td class="align-middle">
                        {{
                            formatDate(item.quota.last_attemp_date)
                                | date: 'dd/MM/yyyy'
                        }}
                    </td>
                    <td class="align-middle">
                        {{ item.quota.amount | currency: 'EUR' }}
                    </td>
                    <td class="align-middle">
                        {{ item.quota.debt_left | currency: 'EUR' }}
                    </td>
                    <td class="align-middle">
                        {{ item.quota.initial_amount | currency: 'EUR' }}
                    </td>
                    <td class="align-middle">
                        <div
                            class="
                                d-flex
                                align-items-center
                                justify-content-between
                            "
                        >
                            {{ item.quota.late_fee_amount | currency: 'EUR' }}
                            <app-fee-rectification
                                *ngIf="item.quota.late_fee_exists"
                                [loan]="creditLine?.id"
                                [quota]="item.quota"
                                (sended)="quotaRectification()"
                            ></app-fee-rectification>
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <i
                            style="font-size: 2em; cursor: pointer"
                            class="fa fa-eye text-primary"
                            (click)="
                                openPaymentsRequests(
                                    item.quota.id,
                                    paymentRequest
                                )
                            "
                        ></i>
                    </td>
                </tr>
                <tr *ngIf="item[item.quota.id].open">
                    <td class="border-0"></td>
                    <td colspan="10" class="border-0 pl-0 pr-0 pb-0 pt-0">
                        <table class="table mb-0 border-0">
                            <thead>
                                <tr class="table-secondary">
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Late Fee</th>
                                    <th>Fee</th>
                                    <th>Capital</th>
                                    <th>Payment Amount</th>
                                    <th>Payment Gateway</th>
                                    <th>Payment Method</th>
                                    <th>Payment Source</th>
                                    <th></th>
                                    <!-- <th></th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let payment of item[item.quota.id].list
                                    "
                                >
                                    <td>{{ payment.created_at }}</td>
                                    <td class="text-right">
                                        {{
                                            payment.amount_applied
                                                | currency: 'EUR'
                                        }}
                                    </td>
                                    <td class="text-right">
                                        {{
                                            payment.late_fee_amount
                                                | currency: 'EUR'
                                        }}
                                    </td>
                                    <td class="text-right">
                                        {{
                                            payment.fee_amount | currency: 'EUR'
                                        }}
                                    </td>
                                    <td class="text-right">
                                        {{
                                            payment.capital_amount
                                                | currency: 'EUR'
                                        }}
                                    </td>
                                    <td class="text-right">
                                        {{ payment.amount | currency: 'EUR' }}
                                    </td>
                                    <td>{{ payment.provider }}</td>
                                    <td>{{ payment.type }}</td>
                                    <td>{{ payment.source }}</td>
                                    <td class="text-center align-middle">
                                        <i
                                            style="
                                                font-size: 2em;
                                                cursor: pointer;
                                            "
                                            (click)="
                                                openQuotaPaymentDetails(
                                                    payment.id,
                                                    paymentModal
                                                )
                                            "
                                            class="fa fa-eye text-primary"
                                        ></i>
                                    </td>
                                    <td class="text-center align-middle">
                                        <app-payment-rectification
                                            [payment]="payment"
                                        ></app-payment-rectification>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="border-0"></td>
                </tr>
            </ng-template>
        </tbody>
    </table>
</div>

<app-pager
    *ngIf="creditLineOperationsPager?.data && !isCheckView"
    [currentPage]="creditLineOperationsPager?.current_page"
    [firstPageUrl]="creditLineOperationsPager?.first_page_url"
    [from]="creditLineOperationsPager?.from"
    [lastPage]="creditLineOperationsPager?.last_page"
    [lastPageUrl]="creditLineOperationsPager?.last_page_url"
    [nextPageUrl]="creditLineOperationsPager?.next_page_url"
    [path]="creditLineOperationsPager?.path"
    [perPage]="creditLineOperationsPager?.per_page"
    [prev_page_url]="creditLineOperationsPager?.prev_page_url"
    [to]="creditLineOperationsPager?.to"
    [total]="creditLineOperationsPager?.total"
    (changePage)="getCreditLineOperationSummaryPaginated($event)"
>
</app-pager>

<!-- line info tab page end -->

<!-- Modal -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body-lg">
        <img
            *ngIf="loading_docs"
            width="50px"
            height="50px"
            src="assets/img/backoffice/loading.gif"
        />
        <pdf-viewer
            *ngIf="!loading_docs"
            [src]="url"
            [render-text]="true"
            [render-text]="true"
            [external-link-target]="'self'"
            [autoresize]="true"
            [original-size]="true"
            style="display: block"
        ></pdf-viewer>
    </div>
</ng-template>

<ng-template #paymentModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Payment Details
            <span class="step-label">{{ creditLine?.code_str }}</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-striped">
            <tr>
                <td><strong>Payment ID</strong></td>
                <td>{{ paymentDetails?.basic?.id }}</td>
            </tr>
            <tr>
                <td><strong>Payment Gateway</strong></td>
                <td>{{ paymentDetails?.provider?.name }}</td>
            </tr>
            <tr>
                <td><strong>Signature</strong></td>
                <td>{{ paymentDetails?.provider?.transaction_signature }}</td>
            </tr>
            <tr>
                <td><strong>Response Code</strong></td>
                <td>{{ paymentDetails?.basic?.response_code }}</td>
            </tr>
            <tr>
                <td><strong>Response Description</strong></td>
                <td>{{ paymentDetails?.basic?.response_description }}</td>
            </tr>
            <tr>
                <td><strong>Authorisation Code</strong></td>
                <td>{{ paymentDetails?.provider?.auth_code }}</td>
            </tr>
            <tr>
                <td><strong>Created</strong></td>
                <td>{{ paymentDetails?.basic?.created_at }}</td>
            </tr>
            <tr>
                <td><strong>Status</strong></td>
                <td>{{ paymentDetails?.basic?.status }}</td>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #paymentRequest let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Payments Requests
            <span class="step-label">{{ creditLine?.code_str }}</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table" *ngIf="paymentsRequests?.data?.length">
            <thead class="bg-green-light">
                <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Gateway</th>
                    <th>Response Code</th>
                    <th>Response Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let request of paymentsRequests?.data">
                    <td>{{ request?.created_at }}</td>
                    <td>{{ request?.status }}</td>
                    <td>{{ request?.amount | currency: 'EUR' }}</td>
                    <td>{{ request?.provider }}</td>
                    <td>{{ request?.code }}</td>
                    <td>{{ request?.description }}</td>
                </tr>
            </tbody>
        </table>
        <p *ngIf="!paymentsRequests?.data?.length" class="text-center">
            No Requests
        </p>
    </div>
</ng-template>
