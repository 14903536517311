<div *appHasPermissions="['finance-collection', 'debtrecovery-collection']">
    <div class="row justify-content-between">
        <div class="col-sm-12 col-lg-2"><h2>Collection</h2></div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <th>Collection Date</th>
                    <th>Loan</th>
                    <th>Activated</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Payment Gateway</th>
                    <th>Payment Source</th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-daterange-filter
                            fieldName="created_at"
                            [start]="filters.created_at_start"
                            [end]="filters.created_at_end"
                            (changeDate)="onChangeDate($event)"
                            [withStartDate]="true"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loan Code"
                            name="loan_code_str"
                            [(ngModel)]="filters.loan_code_str"
                        />
                    </td>
                    <td>
                        <app-daterange-filter
                            fieldName="activation_date"
                            [start]="filters.activation_date_start"
                            [end]="filters.activation_date_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="status_id"
                            [(ngModel)]="filters.status_id"
                        >
                            <option value="">Select Status</option>
                            <option
                                *ngFor="let status of selects.statuses"
                                value="{{ status.id }}"
                            >
                                {{ status.name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount"
                            name="amount"
                            [(ngModel)]="filters.amount"
                        />
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="provider_id"
                            [(ngModel)]="filters.provider_id"
                        >
                            <option value="">Select Gateway</option>
                            <option
                                *ngFor="let gateway of selects.gateways"
                                value="{{ gateway.id }}"
                            >
                                {{ gateway.name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="source_id"
                            [(ngModel)]="filters.source_id"
                        >
                            <option value="">Select Source</option>
                            <option
                                *ngFor="let source of selects.sources"
                                value="{{ source.id }}"
                            >
                                {{ source.name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input
                            size="10"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="DNI / NIE"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Customer Name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>{{ item?.date }}</td>
                        <td>
                            <a
                                class="miLineaFontColor"
                                (click)="goto(item.lead_id)"
                                >{{ item?.code_str }}</a
                            >
                        </td>
                        <td>
                            {{
                                item.activation_date
                                    ? item.activation_date
                                    : 'activation_date'
                            }}
                        </td>
                        <td>{{ item?.status }}</td>
                        <td>{{ item?.amount | currency: 'EUR' }}</td>
                        <td>{{ item?.provider }}</td>
                        <td>{{ item?.source }}</td>
                        <td>{{ item?.personalid }}</td>
                        <td>{{ item?.full_name }}</td>
                        <td>
                            <a (click)="openPayment(payment, item)"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 57 36"
                                    width="40"
                                    height="26"
                                >
                                    <style>
                                        .st0 {
                                            fill: #69356e;
                                        }
                                    </style>
                                    <path
                                        class="st0"
                                        d="M.3 19.2c2.1 3.8 5 7.2 8.4 9.9 1.8 1.4 3.7 2.6 5.7 3.6s4.2 1.8 6.3 2.3c4.6 1.1 9.3 1.2 13.9.3 4.3-.8 8.4-2.6 11.9-5.1 3.7-2.6 6.8-5.9 9.2-9.8.4-.7.9-1.4 1-2.1.1-.6-.1-1.1-.4-1.6-.1-.1-.1-.2-.2-.3-.1-.2-.3-.5-.4-.7-.6-.9-1.2-1.8-1.8-2.6-1.4-1.8-2.9-3.5-4.6-5-3.5-3-7.5-5.3-11.8-6.7C35.3.7 32.9.3 30.6.1c-2.4-.2-4.7-.1-7 .3-4.1.6-8.3 2.2-12 4.5-3.8 2.4-7.1 5.6-9.7 9.4-.6.8-1.1 1.7-1.6 2.5-.3.6-.4 1.2-.2 1.8.2.5.6 1.1 1.1 1.4 1.1.6 2.6.3 3.2-.8.7-1.4 1.6-2.7 2.6-3.9-.1.2-.2.3-.4.5 1.6-2.1 3.5-4 5.6-5.6-.2.1-.3.2-.5.4 2-1.6 4.3-2.9 6.7-3.9-.2.1-.4.2-.6.2 2.4-1 4.9-1.7 7.4-2-.2 0-.4.1-.6.1 2.6-.3 5.2-.3 7.7 0-.2 0-.4-.1-.6-.1 2.5.3 5 1 7.4 2-.2-.1-.4-.2-.6-.2 2.4 1 4.7 2.3 6.7 3.9-.2-.1-.3-.2-.5-.4 2.1 1.6 4.1 3.6 5.7 5.7-.1-.2-.2-.3-.4-.5.9 1.2 1.8 2.5 2.5 3.8v-2.4c-.8 1.4-1.6 2.7-2.6 3.9.1-.2.2-.3.4-.5-1.6 2.1-3.5 4-5.6 5.6.2-.1.3-.2.5-.4-2 1.6-4.3 2.9-6.7 3.9.2-.1.4-.2.6-.2-2.4 1-4.9 1.7-7.4 2 .2 0 .4-.1.6-.1-2.6.3-5.2.3-7.8 0 .2 0 .4.1.6.1-2.5-.3-5-1-7.4-2 .2.1.4.2.6.2-2.4-1-4.6-2.3-6.7-3.9.2.1.3.2.5.4-2.1-1.6-4-3.5-5.6-5.6.1.2.2.3.4.5-1-1.2-1.8-2.6-2.6-3.9-.6-1.1-2.2-1.5-3.2-.8s-1.4 2-.8 3.2z"
                                    />
                                    <path
                                        class="st0"
                                        d="M39.2 18.1c0-2.1-.6-4.3-1.9-6.1-.7-1-1.5-1.8-2.5-2.6-.8-.5-1.8-1-2.8-1.3-4-1.5-8.9-.2-11.7 3.1-.4.5-.8 1-1.1 1.5-.3.5-.6 1.1-.8 1.7-.4 1-.6 2.1-.7 3.2-.2 2.1.4 4.3 1.5 6.1 1.1 1.8 2.7 3.3 4.6 4.2.6.2 1.1.5 1.7.7.6.2 1.2.3 1.9.3 1.1.1 2.2 0 3.3-.2 4.1-.8 7.7-4.4 8.3-8.5.1-.8.2-1.4.2-2.1"
                                    />
                                </svg>
                            </a>
                        </td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>

    <ng-template #payment let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Payment Details
                <a class="step-label" (click)="goto(item.lead_id)">{{
                    item?.code_str
                }}</a>
            </h4>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="c()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <table class="table table-striped">
                <tr>
                    <td><strong>Payment ID</strong></td>
                    <td>{{ item?.id }}</td>
                </tr>
                <tr>
                    <td><strong>Payment Gateway</strong></td>
                    <td>{{ item?.provider?.name }}</td>
                </tr>
                <tr>
                    <td><strong>Signature</strong></td>
                    <td style="white-space: pre">
                        {{ item?.provider?.transaction_signature }}
                    </td>
                </tr>
                <tr>
                    <td><strong>Response Code</strong></td>
                    <td>{{ item?.basic?.response_code }}</td>
                </tr>
                <tr>
                    <td><strong>Response Description</strong></td>
                    <td>{{ item?.basic?.response_description }}</td>
                </tr>
                <tr>
                    <td><strong>Authorisation Code</strong></td>
                    <td>{{ item?.provider?.auth_code }}</td>
                </tr>
                <tr>
                    <td><strong>Created</strong></td>
                    <td>{{ item?.basic?.created_at }}</td>
                </tr>
                <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ item?.status }}</td>
                </tr>
                <tr>
                    <td><strong>Card</strong></td>
                    <td>
                        {{
                            item?.means?.card?.type?.name
                                ? item?.means?.card?.type?.name
                                : '-'
                        }}:
                        {{
                            item?.means?.card?.pan
                                ? item?.means?.card?.pan
                                : '-'
                        }}
                        {{
                            item?.means?.card?.expiration_month
                                ? item?.means?.card?.expiration_month
                                : '-'
                        }}/{{
                            item?.means?.card?.expiration_year
                                ? item?.means?.card?.expiration_year
                                : '-'
                        }}
                    </td>
                </tr>
            </table>
        </div>
    </ng-template>
</div>
