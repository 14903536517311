import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-setting-create-role',
    templateUrl: './setting-create-role.component.html',
    styleUrls: ['./setting-create-role.component.scss']
})
export class SettingCreateRoleComponent implements OnInit {
    roles = [];
    roleID: null;
    roleForm: FormGroup;

    permissions: any;
    permissionsSelected = [];
    newRole = {
        name: '',
        display_name: '',
        description: ''
    };

    newPermission = {
        name: '',
        display_name: '',
        description: ''
    };

    isSending = false;
    saving = false;
    constructor(private fb: FormBuilder,
        private authService: AuthService,
        private errorMessage: ErrorMessageService,
        private modal: NgbModal) {

        this.roleForm = fb.group({
            name: ['', Validators.required],
            display_name: ['', Validators.required],
            description: ['', Validators.required],
        });

    }
    ngOnInit() {
        this.getAllRoles();
        this.getAllPermissions();
    }

    getAllRoles() {
        this.authService.getAllRoles().toPromise().then(response => {
            this.roles = response;
            this.roleID = response[0].id;

            this.getRolePermissions(this.roleID);
        });
    }

    getAllPermissions() {
        this.authService.getAllPermissions().toPromise().then(response => {
            this.permissions = response;
        });
    }

    getRolePermissions(role_id) {
        this.authService.getRolePermissions(role_id).toPromise().then(response => {
            this.permissionsSelected = response;

            this.permissions.forEach(item => {
                const existPermission = this.permissionsSelected.find(permission => permission.id === item.id);

                if (!!existPermission) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
            });
        });
    }

    changeAttachedPermission(role_id, permission_id) {
        const changedPermission = this.permissions.find(item => item.id === permission_id);
        this.saving = true;

        if (changedPermission.selected) {
            this.authService.attachPermission(role_id, permission_id).toPromise().then(response => {
                setTimeout(() => {
                    this.saving = false;
                }, 500, this);
            }).catch(err => {
                setTimeout(() => {
                    this.saving = false;
                }, 500, this);
            });
        } else {
            this.authService.detachPermission(role_id, permission_id).toPromise().then(response => {
                setTimeout(() => {
                    this.saving = false;
                }, 500, this);
            }).catch(err => {
                setTimeout(() => {
                    this.saving = false;
                }, 500, this);
            });
        }
    }

    open(content) {
        this.modal.open(content);
    }

    addNewRole() {
        this.isSending = true;

        this.authService.createRole(this.newRole).toPromise()
            .then(response => {
                this.isSending = false;
                this.roles.push(response);
                this.errorMessage.showSuccess('New Role added successfully!');
            })
            .catch(error => {
                this.isSending = false;
                this.errorMessage.showError(error.error.error.show_message);
            });
    }

    addNewPermission() {
        this.isSending = true;

        this.authService.createPermission(this.newPermission).toPromise()
            .then(response => {
                this.isSending = false;
                this.isSending = false;
                this.permissions.push(response);
                this.errorMessage.showSuccess('New Permission added successfully!');
            })
            .catch(error => {
                this.isSending = false;
                this.errorMessage.showError(error.error.error.show_message);
            });
    }

}
