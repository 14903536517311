<br />
<form [formGroup]="imageForm">
    <div class="row">
        <div class="col-md-6">
            <table
                id="docs"
                class="table table--docs table-bordered table-hover"
            >
                <thead class="table-head bg-green-light">
                    <tr>
                        <th>To</th>
                        <th>Sent</th>
                        <th *ngIf="credit_line_check && editing">Check</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left">Personal ID front</td>
                        <td class="text-center">
                            <button
                                *ngIf="client_document?.personalid_front_face"
                                (click)="
                                    open(content);
                                    viewClientDocument('personalid_front_face')
                                "
                                class="btn btn-accept"
                            >
                                View File
                            </button>
                            <div
                                *ngIf="
                                    !checkNavBarService?.checkedPersonalIdFront &&
                                    editing &&
                                    client_document?.personalid_front_face
                                "
                                class="d-inline-block"
                            >
                                <label
                                    for="personalid_front_face"
                                    class="custom-file btn btn-primary"
                                >
                                    <img
                                        *ngIf="uploading"
                                        width="20px"
                                        height="20px"
                                        src="assets/img/backoffice/loading.gif"
                                    />
                                    Update File
                                </label>
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/pjpeg, image/png"
                                    class="btn btn-primary"
                                    id="personalid_front_face"
                                    name="personalid_front_face"
                                    formControlName="personalid_front_face"
                                    (change)="
                                        onFileChange(
                                            $event,
                                            'personalid_front_face'
                                        )
                                    "
                                    hidden
                                />
                            </div>
                            <div
                                *ngIf="!client_document?.personalid_front_face"
                            >
                                No photo
                            </div>
                            <div
                                class="uploadErrorMessage"
                                *ngIf="uploadErrorMessage"
                            >
                                {{ uploadErrorMessage }}
                            </div>
                        </td>
                        <td
                            *ngIf="credit_line_check && editing"
                            class="text-center"
                        >
                            <label
                                class="checkbox__label"
                                (click)="checkPersonalidFrontFace()"
                                *ngIf="
                                    !checkNavBarService.checkedPersonalIdFront
                                "
                            >
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                />
                                <span class="checkbox__checkmark"></span>
                            </label>
                            <div
                                class="docs__checked"
                                *ngIf="
                                    checkNavBarService.checkedPersonalIdFront
                                "
                            >
                                Checked
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">Personal ID back</td>
                        <td class="text-center">
                            <button
                                *ngIf="client_document?.personalid_back_face"
                                (click)="
                                    open(content);
                                    viewClientDocument('personalid_back_face')
                                "
                                class="btn btn-accept"
                            >
                                View File
                            </button>
                            <div
                                *ngIf="
                                    !checkNavBarService?.checkedPersonalIdBack &&
                                    editing &&
                                    client_document?.personalid_back_face
                                "
                                class="d-inline-block"
                            >
                                <label
                                    for="personalid_back_face"
                                    class="custom-file btn btn-primary"
                                >
                                    <img
                                        *ngIf="uploading"
                                        width="20px"
                                        height="20px"
                                        src="assets/img/backoffice/loading.gif"
                                    />
                                    Update File
                                </label>
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/pjpeg, image/png"
                                    class="btn btn-primary"
                                    id="personalid_back_face"
                                    name="personalid_back_face"
                                    formControlName="personalid_back_face"
                                    (change)="
                                        onFileChange(
                                            $event,
                                            'personalid_back_face'
                                        )
                                    "
                                    hidden
                                />
                            </div>
                            <div *ngIf="!client_document?.personalid_back_face">
                                No photo
                            </div>
                            <div
                                class="uploadErrorMessage"
                                *ngIf="uploadErrorMessage"
                            >
                                {{ uploadErrorMessage }}
                            </div>
                        </td>
                        <td
                            *ngIf="credit_line_check && editing"
                            class="text-center"
                        >
                            <label
                                class="checkbox__label"
                                (click)="checkPersonalidBackFace()"
                                *ngIf="
                                    !checkNavBarService.checkedPersonalIdBack
                                "
                            >
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                />
                                <span class="checkbox__checkmark"></span>
                            </label>
                            <div
                                class="docs__checked"
                                *ngIf="checkNavBarService.checkedPersonalIdBack"
                            >
                                Checked
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">Selfie</td>
                        <td class="text-center">
                            <button
                                *ngIf="client_document?.credit_card_selfie"
                                (click)="
                                    open(content);
                                    viewClientDocument('credit_card_selfie')
                                "
                                class="btn btn-accept"
                            >
                                View File
                            </button>
                            <div
                                *ngIf="
                                    !checkNavBarService?.checkedSelfie &&
                                    editing &&
                                    client_document?.credit_card_selfie
                                "
                                class="d-inline-block"
                            >
                                <label
                                    for="credit_card_selfie"
                                    class="custom-file btn btn-primary"
                                >
                                    <img
                                        *ngIf="uploading"
                                        width="20px"
                                        height="20px"
                                        src="assets/img/backoffice/loading.gif"
                                    />
                                    Update File
                                </label>
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/pjpeg, image/png"
                                    class="btn btn-primary"
                                    id="credit_card_selfie"
                                    name="credit_card_selfie"
                                    formControlName="credit_card_selfie"
                                    (change)="
                                        onFileChange(
                                            $event,
                                            'credit_card_selfie'
                                        )
                                    "
                                    hidden
                                />
                            </div>
                            <div *ngIf="!client_document?.credit_card_selfie">
                                No photo
                            </div>
                            <div
                                class="uploadErrorMessage"
                                *ngIf="uploadErrorMessage"
                            >
                                {{ uploadErrorMessage }}
                            </div>
                        </td>
                        <td
                            *ngIf="credit_line_check && editing"
                            class="text-center"
                        >
                            <label
                                class="checkbox__label"
                                (click)="checkCreditCardSelfie()"
                                *ngIf="!checkNavBarService.checkedSelfie"
                            >
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                />
                                <span class="checkbox__checkmark"></span>
                            </label>
                            <div
                                class="docs__checked"
                                *ngIf="checkNavBarService.checkedSelfie"
                            >
                                Checked
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">Proof of address</td>
                        <td class="text-center">
                            <button
                                *ngIf="
                                    client_document?.document_confirming_address
                                "
                                (click)="
                                    open(content);
                                    viewClientDocument(
                                        'document_confirming_address'
                                    )
                                "
                                class="btn btn-accept"
                            >
                                View File
                            </button>
                            <div
                                *ngIf="
                                    !checkNavBarService?.checkedAddress &&
                                    editing &&
                                    client_document?.document_confirming_address
                                "
                                class="d-inline-block"
                            >
                                <label
                                    for="document_confirming_address"
                                    class="custom-file btn btn-primary"
                                >
                                    <img
                                        *ngIf="uploading"
                                        width="20px"
                                        height="20px"
                                        src="assets/img/backoffice/loading.gif"
                                    />
                                    Update File
                                </label>
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/pjpeg, image/png"
                                    class="btn btn-primary"
                                    id="document_confirming_address"
                                    name="document_confirming_address"
                                    formControlName="document_confirming_address"
                                    (change)="
                                        onFileChange(
                                            $event,
                                            'document_confirming_address'
                                        )
                                    "
                                    hidden
                                />
                            </div>
                            <div
                                *ngIf="
                                    !client_document?.document_confirming_address
                                "
                            >
                                No photo
                            </div>
                            <div
                                class="uploadErrorMessage"
                                *ngIf="uploadErrorMessage"
                            >
                                {{ uploadErrorMessage }}
                            </div>
                        </td>
                        <td
                            *ngIf="credit_line_check && editing"
                            class="text-center"
                        >
                            <label
                                class="checkbox__label"
                                (click)="checkDocumentConfirmingAddress()"
                                *ngIf="!checkNavBarService.checkedAddress"
                            >
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                />
                                <span class="checkbox__checkmark"></span>
                            </label>
                            <div
                                class="docs__checked"
                                *ngIf="checkNavBarService.checkedAddress"
                            >
                                Checked
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">Additional document</td>
                        <td class="text-center">
                            <button
                                *ngIf="client_document?.additional_document"
                                (click)="
                                    open(content);
                                    viewClientDocument('additional_document')
                                "
                                class="btn btn-accept"
                            >
                                View File
                            </button>
                            <div
                                *ngIf="
                                    !checkNavBarService?.checkedAddress &&
                                    editing &&
                                    client_document?.additional_document
                                "
                                class="d-inline-block"
                            >
                                <label
                                    for="additional_document"
                                    class="custom-file btn btn-primary"
                                >
                                    <img
                                        *ngIf="uploading"
                                        width="20px"
                                        height="20px"
                                        src="assets/img/backoffice/loading.gif"
                                    />
                                    Update File
                                </label>
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/pjpeg, image/png"
                                    class="btn btn-primary"
                                    id="additional_document"
                                    name="additional_document"
                                    formControlName="additional_document"
                                    (change)="
                                        onFileChange(
                                            $event,
                                            'additional_document'
                                        )
                                    "
                                    hidden
                                />
                            </div>
                            <div
                                *ngIf="!client_document?.additional_document"
                                class="d-inline-block"
                            >
                                <label
                                    for="additional_document"
                                    class="custom-file btn btn-primary"
                                >
                                    <img
                                        *ngIf="uploading"
                                        width="20px"
                                        height="20px"
                                        src="assets/img/backoffice/loading.gif"
                                    />
                                    Upload File
                                </label>
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/pjpeg, image/png"
                                    class="btn btn-primary"
                                    id="additional_document"
                                    name="additional_document"
                                    formControlName="additional_document"
                                    (change)="
                                        onFileChange(
                                            $event,
                                            'additional_document'
                                        )
                                    "
                                    hidden
                                />
                            </div>
                            <div
                                class="uploadErrorMessage"
                                *ngIf="uploadErrorMessage"
                            >
                                {{ uploadErrorMessage }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-3">
            <app-lead-info-address-info
                [lead]="lead"
            ></app-lead-info-address-info>
        </div>
        <div class="col-md-3">
            <div class="card" *ngIf="paymentBankCard?.length">
                <div class="card-header" role="tab" id="headingCenterTwo">
                    <h5 class="mb-0">Credit Card info</h5>
                </div>
                <div
                    id="collapseCenterTwo"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingCenterTwo"
                    data-parent="#accordion"
                >
                    <div
                        class="card-body"
                        style="max-height: 230px; overflow: auto"
                    >
                        <ul
                            class="list-group"
                            *ngFor="let card of paymentBankCard"
                        >
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <strong>Number</strong
                                ><span>{{ card?.pan }}</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <strong>Expiration Month</strong
                                ><span>{{ card?.expiration_month }}</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <strong>Expiration Year</strong
                                ><span>{{ card?.expiration_year }}</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <strong>Card Type</strong
                                ><span>{{ card?.type.name }}</span>
                            </li>
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <strong>Card Status</strong
                                ><span>{{ card?.status?.name }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Modal -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body-lg">
        <img
            *ngIf="loading"
            class="loading-img"
            width="50px"
            height="50px"
            src="assets/img/backoffice/loading.gif"
        />
        <ngx-imageviewer
            class="angImageViewer"
            *ngIf="images[0] && !loading"
            [src]="images[0]"
            [width]="canvasWidth"
            [height]="canvasHeight"
        ></ngx-imageviewer>

        <div class="btn-group viewer-btn-group">
            <button class="btn btn-primary btn-sm" (click)="openImage()">
                Open in New Tab <i class="fa fa-external-link"></i>
            </button>
            <button class="btn btn-primary btn-sm" (click)="donwloadImage()">
                Download <i class="fa fa-download"></i>
            </button>
        </div>
    </div>
</ng-template>
