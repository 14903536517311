<div *ngIf="presentation?.closed" class="d-flex justify-content-between mb-2">
    <strong>Fecha de Terminación:</strong>
    {{
        presentation?.closed_at
            ? (presentation.closed_at | dateFormat | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div *ngIf="presentation?.closed" class="d-flex justify-content-between mb-2">
    <strong>Fecha de Finalización:</strong>
    {{
        presentation?.end_at
            ? (presentation.end_at | dateFormat | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Referencia Monitorio:</strong>
    <span class="flex-grow-1"></span>
    {{ presentation?.proceeding?.reference_code || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Juzgado:</strong>
    {{ presentation?.tribunal_code || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Referencia:</strong>
    {{ presentation?.reference_code || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Fecha de Presentacion:</strong>
    {{
        presentation?.start_at
            ? (presentation.start_at | dateFormat | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Situacion:</strong>
    {{ presentation?.status?.name || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Referencia Procedimiento:</strong>
    {{ presentation?.tribunal_proceeding_reference || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Referencia Ejecución:</strong>
    {{ presentation?.tribunal_proceeding_execution_reference || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Importe Aceptado:</strong>
    {{
        presentation?.tribunal_amount_accepted
            ? (presentation.tribunal_amount_accepted | currency: 'EUR')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong>Fecha de la Vista:</strong>
    {{
        presentation?.tribunal_hearing_at
            ? (presentation.tribunal_hearing_at
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-3">
    <strong>Comentarios:</strong>
    {{ presentation?.comment || '-' }}
</div>

<hr />
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Población del Juzgado:</strong>
    {{ presentation?.tribunal_population || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Fecha:</strong>
    {{
        presentation?.tribunal_acceptance_amount_proposal_auto_date
            ? (presentation.tribunal_acceptance_amount_proposal_auto_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Referencia Procedimiento Verbal:</strong>
    {{ presentation?.tribunal_proceeding_verbal_reference || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Título en el Tipo de Documento:</strong>
    {{ presentation?.tribunal_document_type_title || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">CIF de la Empresa del Deudor:</strong>
    {{ presentation?.tribunal_debtor_company_cif || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Fecha de Providencia:</strong>
    {{
        presentation?.tribunal_providence_date
            ? (presentation.tribunal_providence_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Referencia de Providencia:</strong>
    {{ presentation?.tribunal_providence_number || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Fecha de Recepción de Providencia:</strong>
    {{
        presentation?.tribunal_providence_reception_date
            ? (presentation.tribunal_providence_reception_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Referencia del Decreto de Ejecución de Sentencia:</strong
    >
    {{
        presentation?.tribunal_approval_execution_sentence_decree_number || '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Fecha del Decreto de Ejecución de Sentencia:</strong
    >
    {{
        presentation?.tribunal_approval_execution_sentence_decree_date
            ? (presentation.tribunal_approval_execution_sentence_decree_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Fecha Recepción del Decreto de Ejecución de Sentencia:</strong
    >
    {{
        presentation?.tribunal_approval_execution_sentence_decree_reception_date
            ? (presentation.tribunal_approval_execution_sentence_decree_reception_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Referencia de la Ejecución de Sentencia:</strong>
    {{ presentation?.tribunal_execution_sentence_number || '-' }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50">Fecha de la Ejecución de Sentencia:</strong>
    {{
        presentation?.tribunal_execution_sentence_date
            ? (presentation.tribunal_execution_sentence_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Fecha de Recepción de la Ejecución de Sentencia:</strong
    >
    {{
        presentation?.tribunal_execution_sentence_reception_date
            ? (presentation.tribunal_execution_sentence_reception_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Fecha de Inicio para Liquidación de Interes Legal:</strong
    >
    {{
        presentation?.tribunal_liquidation_legal_interest_strat_date
            ? (presentation.tribunal_liquidation_legal_interest_strat_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Fecha de Final para Liquidación de Interes Legal:</strong
    >
    {{
        presentation?.tribunal_liquidation_legal_interest_end_date
            ? (presentation.tribunal_liquidation_legal_interest_end_date
              | dateFormat
              | date: 'dd/MM/yyyy')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >Cantidad de Dinero Para Liquidación de Interes Legal:</strong
    >
    {{
        presentation?.tribunal_liquidation_legal_interest_amount
            ? (presentation.tribunal_liquidation_legal_interest_amount
              | currency: 'EUR')
            : '-'
    }}
</div>
<div class="d-flex justify-content-between mb-2">
    <strong class="small w-50"
        >30% del Dinero para Liquidación de Interes Legal:</strong
    >
    {{
        presentation?.tribunal_liquidation_legal_interest_thirty_percent_amount
            ? (presentation.tribunal_liquidation_legal_interest_thirty_percent_amount
              | currency: 'EUR')
            : '-'
    }}
</div>

<hr />
<app-presentation-docs-list
    [proceedingId]="presentation?.proceeding_id"
    [presentationId]="presentation?.id"
    [closed]="presentation?.closed"
></app-presentation-docs-list>
<hr />
<app-presentation-attachments-list
    [proceedingId]="presentation?.proceeding_id"
    [presentationId]="presentation?.id"
    [closed]="presentation?.closed"
></app-presentation-attachments-list>
