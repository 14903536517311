<ng-template [ngIf]="!loading">
    <form
        [formGroup]="userForm"
        style="width: 22rem;"
        (ngSubmit)="onFormSubmit()"
        autocomplete="off"
    >
        <!-- input roles -->
        <!-- <label *ngIf="user_id">Roles:</label>
        <select *ngIf="user_id" class="form-control" multiple formControlName="role_id" required>
            <option *ngFor="let role of roles" value="{{ role?.id }}">{{ role?.display_name }}</option>
        </select> -->
        <!-- input names -->
        <div class="md-form mb-3">
            <i class="fa fa-user prefix grey-text"></i>
            <label for="name" class="font-weight-light">Name</label>
            <input
                type="text"
                id="name"
                formControlName="name"
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.name.touched &&
                        !userForm.controls.name.valid
                }"
                class="form-control"
                mdbInputDirective
                autocomplete="off"
            />
        </div>
        <div class="md-form mb-3">
            <i class="fa fa-user prefix grey-text"></i>
            <label for="first_surname" class="font-weight-light"
                >First Surname</label
            >
            <input
                type="text"
                id="first_surname"
                formControlName="first_surname"
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.first_surname.touched &&
                        !userForm.controls.first_surname.valid
                }"
                class="form-control"
                mdbInputDirective
                autocomplete="off"
            />
        </div>
        <div class="md-form mb-3">
            <i class="fa fa-user prefix grey-text"></i>
            <label for="second_surname" class="font-weight-light"
                >Second Surname</label
            >
            <input
                type="text"
                id="second_surname"
                formControlName="second_surname"
                class="form-control"
                mdbInputDirective
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.second_surname.touched &&
                        !userForm.controls.second_surname.valid
                }"
                autocomplete="off"
            />
        </div>
        <div class="md-form mb-3 new-user-select">
            <label class="font-weight-light">Role</label>
            <ng-multiselect-dropdown
                formControlName="role_id"
                [placeholder]="'Select role'"
                [settings]="dropdownSettings"
                [data]="dropdownList"
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.role_id.touched &&
                        !userForm.controls.role_id.valid
                }"
            >
            </ng-multiselect-dropdown>
        </div>
        <!-- input username -->
        <div class="md-form mb-3" *ngIf="!user_id">
            <i class="fa fa-user-o prefix grey-text"></i>
            <label for="username" class="font-weight-light">Username</label>
            <input
                type="text"
                id="username"
                formControlName="username"
                class="form-control"
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.username.touched &&
                        !userForm.controls.username.valid
                }"
                mdbInputDirective
                autocomplete="off"
                autocomplete="new-password"
            />
        </div>

        <!-- input password -->
        <div class="md-form mb-3">
            <i class="fa fa-key prefix grey-text"></i>
            <label for="password" class="font-weight-light">Password</label>
            <input
                type="password"
                id="password"
                formControlName="password"
                class="form-control"
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.password.touched &&
                        !userForm.controls.password.valid
                }"
                mdbInputDirective
                autocomplete="off"
                autocomplete="new-password"
            />
        </div>

        <!-- input password -->
        <div class="md-form mb-3" *ngIf="!user_id">
            <i class="fa fa-key prefix grey-text"></i>
            <label for="confirm_password" class="font-weight-light"
                >Confirm Password</label
            >
            <input
                type="password"
                id="confirm_password"
                formControlName="confirm_password"
                class="form-control"
                pattern="{{ userForm.controls.password.value }}"
                mdbInputDirective
                [ngClass]="{
                    'is-invalid':
                        userForm.controls.confirm_password.touched &&
                        !userForm.controls.confirm_password.valid
                }"
                autocomplete="off"
                autocomplete="new-password"
            />
        </div>
        <!-- input blocked -->
        <div class="form-check mr-3">
            <input
                type="checkbox"
                id="blocked"
                formControlName="blocked"
                class="form-control form-check-input"
                mdbInputDirective
                autocomplete="off"
            />
            <label for="blocked" class="form-check-label"
                ><i class="fa fa-lock prefix grey-text"></i
            ></label>
        </div>
        <div class="text-center py-4 mt-3">
            <button
                class="btn btn-primary waves-light"
                type="submit"
                [disabled]="userForm.invalid"
                mdbWavesEffect
            >
                {{ user_id ? 'Edit' : 'Create' }}
            </button>
        </div>
    </form>
</ng-template>
