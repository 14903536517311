import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../../services/email.service';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-mail-message-types-create',
  templateUrl: './mail-message-types-create.component.html',
  styleUrls: ['./mail-message-types-create.component.scss'],
})
export class MailMessageTypesCreateComponent implements OnInit, OnDestroy {
  @Input() item;

  id;
  isSending = false;
  isLoading = true;

  form = this.fb.group({
    name: ['', Validators.required],
    subject: ['', Validators.required],
    description: ['', Validators.required],
    template_id: ['', Validators.required],
    enabled: [false],
  });

  templatesSubscriber: SubscriptionLike;
  templates = [];

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private emailService: EmailService,
    private errorMessage: ErrorMessageService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.templatesSubscriber) {
      this.templatesSubscriber.unsubscribe();
    }
  }

  edit(e, item, content) {
    e.preventDefault();

    const { name, subject, description, template_id, enabled } = item;

    this.form.setValue({
      name,
      subject,
      description,
      template_id: '' + template_id,
      enabled,
    });

    if (item?.published) {
      this.form.disable();
    }

    this.open(content);
  }

  open(content) {
    this.getTemplates();

    this.modalService.open(content).result.then(
      (result) => {
        this.resetForm();
      },
      (reason) => {
        this.resetForm();
      }
    );
  }

  resetForm() {
    this.form.setValue({
      name: '',
      subject: '',
      description: '',
      template_id: '',
      enabled: false,
    });

    this.templates = [];
    this.isLoading = true;
  }

  onSubmit() {
    let args;
    const params = this.form.getRawValue();
    const id = this.item?.id || this.id;
    const method = id ? 'editMessagesType' : 'createMessagesType';

    args = id ? { id, params } : { params };

    this.form.disable();

    this.emailService[method](args).subscribe(
      (response) => {
        this.onSuccess(response, id);
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  onSuccess(item, id = null) {
    this.errorMessage.showSuccess('', 'Saved Successfully');
    this.isSending = false;
    this.form.enable();

    if (id) {
      this.emailService.updateMessagesTypes(item);
    } else {
      this.emailService.addMessageType(item);
      this.id = item?.id;
    }
  }

  onError(error) {
    this.errorMessage.showError(error?.error?.error?.details, 'Saving Failed');
    this.isSending = false;
    this.form.enable();
  }

  getTemplates() {
    this.templatesSubscriber = this.emailService
      .getTemplates({})
      .subscribe((response) => {
        this.templates = (response || []).filter(
          ({ enabled, published }) => enabled && published
        );
        this.isLoading = false;
      });
  }
}
