import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { EmailService } from '../../../../../services/email.service';

@Component({
    selector: 'app-mail-providers-statuses-list',
    templateUrl: './mail-providers-statuses-list.component.html',
    styleUrls: ['./mail-providers-statuses-list.component.scss'],
})
export class MailProvidersStatusesListComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() newItem;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;
    providersSubscriber: ISubscription;
    statusesSubscriber: ISubscription;

    startData = {};

    filters;

    queryString: string = '';

    tableData;
    providers;
    statuses;

    loading = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private emailService: EmailService
    ) {}

    ngOnInit(): void {
        const queryParams$ = this.activatedRoute.queryParams;

        this.getLists();

        this.querySubscriber = queryParams$.subscribe((queryParams) => {
            this.startData = {
                status_id: '',
                provider_id: '',
                provider_status_name: '',
            };

            if (this.dataSubscriber) {
                this.dataSubscriber.unsubscribe();
            }

            // Set Init Filters
            this.setInitFilters(queryParams);
            // Create Query String
            this.createQueryString(this.filters);

            this.getTableData();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const { newItem } = changes;
        if (newItem.currentValue) {
            this.tableData['data'].push(newItem.currentValue);
        }
    }

    ngOnDestroy() {
        if (this.querySubscriber) {
            this.querySubscriber.unsubscribe();
        }
        if (this.dataSubscriber) {
            this.dataSubscriber.unsubscribe();
        }
        if (this.statusesSubscriber) {
            this.statusesSubscriber.unsubscribe();
        }
        if (this.providersSubscriber) {
            this.providersSubscriber.unsubscribe();
        }
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.emailService
            .getProvidersStatuses(this.queryString)
            .subscribe((response) => {
                if (response) {
                    this.tableData = response;
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getLists() {
        this.providersSubscriber = this.emailService
            .getProviders()
            .subscribe((response) => {
                this.providers = response;
            });

        this.statusesSubscriber = this.emailService
            .getStatuses()
            .subscribe((response) => (this.statuses = response));
    }

    onUpdated(item) {
        this.tableData?.data.forEach((element, index) => {
            if (+element?.id === +item?.id) {
                this.tableData.data[index] = item;
            }
        });
    }
}
