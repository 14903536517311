import { Component, OnInit, OnDestroy } from '@angular/core';
import { AffiliatesService } from '../../../services/affiliates.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-affiliates-manager',
    templateUrl: './affiliates-manager.component.html',
    styleUrls: ['./affiliates-manager.component.scss']
})
export class AffiliatesManagerComponent implements OnInit, OnDestroy {
    affiliates: {}[] = [];
    affiliatesSubscriber: ISubscription;
    querySubscriber: ISubscription;

    startData = {
        name: '',
        source_name: '',
        page: 0
    };

    filters: any = {};
    queryString: string = '';

    loading: boolean = true;

    constructor(
        private affiliatesService: AffiliatesService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(queryParams => {
            // Set Init Filters
            this.setInitFilters(queryParams);
            // Create Query String
            this.createQueryString(this.filters);

            this.getAffiliates();
        });
    }

    ngOnDestroy() {
        this.affiliatesSubscriber.unsubscribe();
        this.querySubscriber.unsubscribe();
    }

    getAffiliates() {
        this.loading = true;
        this.affiliatesSubscriber = this.affiliatesService.getAffiliatesList(this.queryString).subscribe(affiliates => {
            if (affiliates) {
                this.affiliates = affiliates;
                this.loading = false;
            }
        });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(key => !clearFilters[key] && delete clearFilters[key]);

        this.queryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(key => !filters[key] && delete filters[key]);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters.page = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }
}
