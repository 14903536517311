<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">
            Address info <span *ngIf="editInfo" (click)="openEdit(editAddress, data)">Edit</span>
        </h5>
    </div>
    <div class="card-body">
        <ul class="list-group">
            <li *ngIf="streetTypes" class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Street Type</strong>
                <span>{{ data?.street_type?.name ? data?.street_type?.name : streetTypes[(data?.street_type)] }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Street</strong><span>{{ data?.street }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Street Number</strong><span>{{ data?.street_number }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Street Additional</strong><span>{{ data?.street_additional }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>City</strong><span>{{ data?.city }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Postal Code</strong><span>{{ data?.postal_code }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Last Update</strong><span>{{ data?.updated_at }}</span>
            </li>
        </ul>
    </div>
</div>

<ng-template #editAddress let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Address update</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-group" #editAddressForm="ngForm">
            <div class="form-group">
                <label for="edit_street_type">Street Type</label>
                <select
                    id="edit_street_type"
                    [ngClass]="{
                        'has-danger':
                            (streetTypeControl.invalid && streetTypeControl.touched) ||
                            (streetTypeControl.errors?.required && streetTypeControl.touched)
                    }"
                    class="form-control selectpicker dataSelect dropdown-select"
                    type="text"
                    name="street_type"
                    [(ngModel)]="addressModel.street_type.id"
                    #streetTypeControl="ngModel"
                    required
                >
                    <option value="">Select street type</option>
                    <option *ngFor="let street of (streetTypes | keys); let i = index" value="{{ street }}">{{
                        streetTypes[street]
                    }}</option>
                </select>
                <label *ngIf="streetTypeControl.invalid" style="color:red">Inválido</label>
            </div>

            <div class="form-group">
                <label for="edit_street">Street</label>
                <input
                    type="text"
                    name="street"
                    id="edit_street"
                    class="form-control"
                    [(ngModel)]="addressModel.street"
                    #streetControl="ngModel"
                    required
                />
                <label *ngIf="streetControl.invalid" style="color:red">Inválido</label>
            </div>

            <div class="form-group">
                <label for="edit_street_number">Street Number</label>
                <input
                    type="text"
                    name="street_number"
                    id="edit_street_number"
                    class="form-control"
                    [(ngModel)]="addressModel.street_number"
                    #streetNumberControl="ngModel"
                    required
                />
                <label *ngIf="streetNumberControl.invalid" style="color:red">Inválido</label>
            </div>

            <div class="form-group">
                <label for="edit_street_additional">Street Additional</label>
                <input
                    type="text"
                    name="street_additional"
                    id="edit_street_additional"
                    class="form-control"
                    [(ngModel)]="addressModel.street_additional"
                    #streetAdditionalControl="ngModel"
                />
                <label *ngIf="streetAdditionalControl.invalid && streetAdditionalControl.value" style="color:red"
                    >Inválido</label
                >
            </div>

            <div class="form-group">
                <label for="edit_city">City</label>
                <input
                    type="text"
                    name="city"
                    id="edit_city"
                    class="form-control"
                    [(ngModel)]="addressModel.city"
                    #cityControl="ngModel"
                    required
                />
                <label *ngIf="cityControl.invalid" style="color:red">Inválido</label>
            </div>

            <div class="form-group">
                <label for="edit_postal_code">Postal Code</label>
                <input
                    type="tel"
                    name="postal_code"
                    id="edit_postal_code"
                    class="form-control"
                    [(ngModel)]="addressModel.postal_code"
                    #postalCodeControl="ngModel"
                    pattern="[0-9]{5}"
                    required
                />
                <label *ngIf="postalCodeControl.invalid" style="color:red">Inválido</label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <img src="assets/img/backoffice/loading.gif" width="50px" height="50px" *ngIf="saving" />
        <button
            type="submit"
            [disabled]="editAddressForm.invalid || isSaving"
            class="btn btn-outline-dark"
            (click)="saveInfo(editAddressForm.value)"
        >
            {{ isSaving ? 'Saving' : 'Save' }}
        </button>
    </div>
</ng-template>
