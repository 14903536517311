<!--
<div class="tab">
  <button class="tablinks tablinks-selected" [routerLink]="'/backoffice/settings/users_overview/user_list'">Users</button>
  <button class="tablinks" [routerLink]="'/backoffice/settings/users_overview/create_user'">New User</button>
  <button class="tablinks" [routerLink]="'/backoffice/settings/users_overview/create_role'">New Role</button>
  <button class="tablinks" [routerLink]="'/backoffice/settings/users_overview/create_permission'">New Permission</button>
  <button class="tablinks" [routerLink]="'/backoffice/settings/users_overview/role_permission'">Role-Permission</button>
</div>
!-->

<!-- horizontal menu -->
<section id="submenu">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link active"
                (click)="goto('user_list', $event)"
                id="pills-leadinfo-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-leadinfo"
                aria-selected="true"
                >Users</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                (click)="goto('create_user', $event)"
                id="pills-userinfo-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-userinfo"
                aria-selected="false"
                >New User</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                (click)="goto('create_role', $event)"
                id="pills-antifraud-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-antifraud"
                aria-selected="false"
                >Roles and Permissions</a
            >
        </li>
    </ul>
</section>
<!-- horizontal menu end -->

<router-outlet></router-outlet>
