import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
    userID;
    constructor(private activeRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.userID = this.activeRoute.snapshot.params?.id;
    }
}
