<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">Discard Validations</h5>
    </div>
    <div class="card-body">
        <div id="accordionleft" role="tablist">
            <div class="card" *ngIf="validation_discard_antifraud">
                <div
                    class="card-header"
                    role="tab"
                    id="headingLeftThree"
                    *ngIf="!loading"
                >
                    <h5 class="mb-0">
                        <a
                            class="collapsed"
                            data-toggle="collapse"
                            aria-expanded="false"
                            aria-controls="collapseLeftThree"
                        >
                            {{
                                validation_discard_antifraud
                                    ?.config_validator_discard_antifraud
                                    ?.validator_label
                            }}
                        </a>
                    </h5>
                </div>
                <img
                    *ngIf="loading"
                    width="50px"
                    height="50px"
                    src="assets/img/backoffice/loading.gif"
                />
                <div
                    *ngIf="!loading"
                    id="collapseLeftThree"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingLeftThree"
                    data-parent="#accordion"
                >
                    <div class="card-body">
                        <div class="margin-top-20 table-responsive">
                            <table class="table table-condensed table-hove">
                                <thead>
                                    <th>Value</th>
                                    <th>Coincidence</th>
                                    <th>Customer</th>
                                    <th>Black Listed?</th>
                                    <th>Lead Coincidence</th>
                                </thead>
                                <tbody>
                                    <tr *ngIf="validation_discard_antifraud">
                                        <td>
                                            {{
                                                validation_discard_antifraud?.checked_value
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                validation_discard_antifraud?.matched_value
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                validation_discard_antifraud
                                                    ?.lead?.user?.name
                                            }}
                                            {{
                                                validation_discard_antifraud
                                                    ?.lead?.user?.first_surname
                                            }}
                                            {{
                                                validation_discard_antifraud
                                                    ?.lead?.user?.second_surname
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                validation_discard_antifraud
                                                    ?.lead?.user
                                                    ?.blacklisted === 1
                                                    ? 'Si'
                                                    : 'No'
                                            }}
                                        </td>
                                        <td>
                                            <a
                                                *ngIf="
                                                    validation_discard_antifraud.matched_lead &&
                                                    validation_discard_antifraud
                                                        .matched_lead.code_str
                                                "
                                                [href]="
                                                    '/backoffice/underwriting/overview/' +
                                                    validation_discard_antifraud
                                                        .matched_lead.id +
                                                    '/leadInfo/' +
                                                    validation_discard_antifraud
                                                        .matched_lead.id
                                                "
                                                target="_blank"
                                            >
                                                {{
                                                    validation_discard_antifraud
                                                        ?.matched_lead?.code_str
                                                }}
                                            </a>
                                            <ng-template
                                                [ngIf]="
                                                    !validation_discard_antifraud.matched_lead ||
                                                    !validation_discard_antifraud
                                                        .matched_lead.code_str
                                                "
                                                >No</ng-template
                                            >
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
