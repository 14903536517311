<div class="card mb-3">
    <div class="card-header"><h5 class="card-header-title">Customer Leads</h5></div>
    <div class="card-body">
        <img *ngIf="loading" width="50px" height="50px" src="assets/img/backoffice/loading.gif" />
        <div class="margin-top-20 table-responsive" *ngIf="!loading">
            <table class="table table-condensed table-hover">
                <thead>
                    <th width="35%">Code</th>
                    <th width="30%">Status</th>
                    <th width="35%">Reason</th>
                </thead>
                <tbody>
                    <tr *ngFor="let lead of leads?.data">
                        <td width="35%">
                            <a
                                target="_blank"
                                href="/backoffice/underwriting/overview/{{ lead?.id }}/leadInfo/{{ lead?.id }}"
                                >{{ lead?.code_str }}</a
                            >
                        </td>
                        <td width="30%">{{ lead?.status.name }}</td>
                        <td width="35%">{{ lead?.status_reason?.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
