import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-lead-info-contract-tracking',
    templateUrl: './lead-info-contract-tracking.component.html',
    styleUrls: ['./lead-info-contract-tracking.component.css']
})
export class LeadInfoContractTrackingComponent implements OnInit {
    @Input() contact_tracking: any = null;
    constructor() {}

    ngOnInit() {}
}
