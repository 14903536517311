<div class="row">
    <div class="col-auto pr-0">
        <h3 class="mb-0">Roles <button class="btn btn-link create-new" (click)="open(content)">Add New
                +</button></h3>

        <div class="border-right border-primary mt-3">
            <div *ngFor="let role of roles" class="custom-control custom-radio">
                <input type="radio" id="roleID{{role?.id}}" name="roleID" class="custom-control-input"
                    [value]="role?.id" [(ngModel)]="roleID" (change)="getRolePermissions(roleID)">
                <label class="custom-control-label" for="roleID{{role?.id}}">{{role?.display_name}}</label>
            </div>
        </div>
    </div>
    <div class="col-auto pl-0">
        <h6 class="mb-0">Permissions attached to the Role <button class="btn btn-link create-new"
                (click)="open(newPermissionModal)">Add New
                +</button></h6>
        <div class="permissions-list mt-3 px-3">
            <div *ngFor="let permission of permissions; let i = index" class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="permissionID{{permission?.id}}"
                    name="permissionID" [value]="permission?.id" [(ngModel)]="permissions[i].selected"
                    (change)="changeAttachedPermission(roleID, permission.id)">
                <label class=" custom-control-label"
                    for="permissionID{{permission?.id}}">{{permission?.display_name ? permission?.display_name : permission?.name}}</label>
            </div>
        </div>

    </div>
</div>


<div class="full-page-loading" *ngIf="saving">Saving...</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add New Role</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form id="newRoleForm" #newRoleForm="ngForm" (ngSubmit)="addNewRole()" novalidate>
            <div class="form-group">
                <label for="roleName">Name</label>
                <input id="roleName" class="form-control" name="name" type="text" [(ngModel)]="newRole.name"
                    #roleNamectrl="ngModel" required placeholder="Enter name">
                <div class="text-danger small" *ngIf="roleNamectrl.touched && roleNamectrl.invalid">
                    The field is required
                </div>
            </div>
            <div class="form-group">
                <label for="roleDisplayName">Display Name</label>
                <input id="roleDisplayName" class="form-control" name="display_name" type="text"
                    [(ngModel)]="newRole.display_name" placeholder="Enter display name">
            </div>
            <div class="form-group">
                <label for="roleDescription">Description</label>
                <input id="roleDescription" class="form-control" name="description" type="text"
                    [(ngModel)]="newRole.description" placeholder="Enter description">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" form="newRoleForm" class="btn btn-primary"
            [disabled]="newRoleForm.invalid || isSending">{{isSending? 'Sending...': 'Save'}}</button>
    </div>
</ng-template>


<ng-template #newPermissionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add New Permission</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form id="newPermissionForm" #newPermissionForm="ngForm" (ngSubmit)="addNewPermission()" novalidate>
            <div class="form-group">
                <label for="permissionName">Name</label>
                <input id="permissionName" class="form-control" name="name" type="text" [(ngModel)]="newPermission.name"
                    #permissionNamectrl="ngModel" required placeholder="Enter name">
                <div class="text-danger small" *ngIf="permissionNamectrl.touched && permissionNamectrl.invalid">
                    The field is required
                </div>
            </div>
            <div class="form-group">
                <label for="permissionDisplayName">Display Name</label>
                <input id="permissionDisplayName" class="form-control" name="display_name" type="text"
                    [(ngModel)]="newPermission.display_name" placeholder="Enter display name">
            </div>
            <div class="form-group">
                <label for="permissionDescription">Description</label>
                <input id="permissionDescription" class="form-control" name="description" type="text"
                    [(ngModel)]="newPermission.description" placeholder="Enter description">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" form="newPermissionForm" class="btn btn-primary"
            [disabled]="newPermissionForm.invalid || isSending">{{isSending? 'Sending...': 'Save'}}</button>
    </div>
</ng-template>
