<td class="align-middle" [ngClass]="{ 'no-edit': !isEditing }">
  <input
    class="form-control"
    name="amount"
    [(ngModel)]="model.amount"
    #amount="ngModel"
    type="number"
    placeholder="Amount"
    required
    [ngClass]="{
      'is-invalid': amount.invalid && !amount.pristine,
      'd-none': !isEditing
    }"
    [disabled]="!isEditing || isSending"
  />
  <span
    [ngClass]="{
      'd-none': isEditing
    }"
    >{{ node?.amount | currency: 'EUR' }}</span
  >
</td>
<td class="align-middle">
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      id="success{{ node?.id }}"
      name="success"
      [(ngModel)]="model.success"
      #success="ngModel"
      [disabled]="!isEditing || isSending"
      (ngModelChange)="onModelChange('success')"
    />
    <label class="custom-control-label" for="success{{ node?.id }}"></label>
  </div>
</td>
<td class="align-middle">
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      id="failure{{ node?.id }}"
      name="failure"
      [(ngModel)]="model.failure"
      #failure="ngModel"
      [disabled]="!isEditing || isSending"
      (ngModelChange)="onModelChange('failure')"
    />
    <label class="custom-control-label" for="failure{{ node?.id }}"></label>
  </div>
</td>
<td class="align-middle" [ngClass]="{ 'no-edit': !isEditing }">
  <select
    *ngIf="nodes && nodes.length > 0"
    class="form-control"
    name="success_node_id"
    [(ngModel)]="model.success_node_id"
    #successNodeId="ngModel"
    [disabled]="!model.success || !isEditing || isSending"
  >
    <option value="">{{ isEditing ? 'Select Node' : '-' }}</option>
    <option *ngFor="let item of nodes" [value]="item.id">{{
      item?.amount | currency: 'EUR'
    }}</option>
  </select>
  <span *ngIf="!nodes.length">No Success Nodes</span>
</td>
<td class="align-middle" [ngClass]="{ 'no-edit': !isEditing }">
  <select
    *ngIf="nodes && nodes.length > 0"
    class="form-control"
    name="failure_node_id"
    [(ngModel)]="model.failure_node_id"
    #failureNodeId="ngModel"
    [disabled]="!model.failure || !isEditing || isSending"
  >
    <option value="">{{ isEditing ? 'Select Node' : '-' }}</option>
    <option *ngFor="let item of nodes" [value]="item.id">{{
      item?.amount | currency: 'EUR'
    }}</option>
  </select>
  <span *ngIf="!nodes?.length">No Failure Nodes</span>
</td>
<td class="align-middle text-right">
  <button
    *ngIf="isEditing"
    class="btn btn-sm btn-success"
    [disabled]="(isEditing && amount && amount.invalid) || isSending"
    (click)="update(node?.tree_id, node?.id)"
  >
    <i class="fa fa-save"></i>
  </button>
  <button
    class="btn btn-sm ml-2"
    [ngClass]="{ 'btn-success': !isEditing, 'btn-dark': isEditing }"
    (click)="toggleEdit()"
    [disabled]="isSending"
  >
    <i *ngIf="!isEditing" class="fa fa-edit"></i>
    <i *ngIf="isEditing" class="fa fa-times-circle"></i>
  </button>
  <button
    class="btn btn-sm btn-danger ml-2"
    (click)="delete(node?.tree_id, node?.id)"
    [disabled]="isSending"
  >
    <i class="fa fa-trash"></i>
  </button>
</td>
