<button
  type="button"
  class="btn"
  [ngClass]="cssClass"
  [disabled]="disabled"
  (click)="open(content)"
>
  <i class="fa fa-eye"></i>
</button>

<ng-template #content let-modal>
  <div *ngIf="!isLoading" class="modal-body" [innerHTML]="draft"></div>
  <div *ngIf="isLoading" class="modal-body text-center">
    <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
  </div>
</ng-template>
