import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting-user-overview',
  templateUrl: './setting-user-overview.component.html',
  styleUrls: ['./setting-user-overview.component.scss']
})
export class SettingUserOverviewComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.navigate(['backoffice/settings/users_overview/user_list']);
  }

  goto(route, event){
    if(event){
      let target = event.target || event.srcElement || event.currentTarget;
      let a = document.querySelectorAll('section[id="submenu"] a');
      for(let i=0; i<a.length; i++){
        a[i].className = 'nav-link';
      } 
      (<HTMLInputElement>target).className= "nav-link active";
    }
    this.router.navigate(['/backoffice/settings/users_overview',route]);
  }
}
