<div>
    <div class="row justify-content-between mb-3">
        <div class="col-12 col-lg-auto">
            <h2 class="mb-0">Queue History</h2>
        </div>
    </div>

    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="table-responsive mt-3">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <th>Created at</th>
                    <th>Executed at</th>
                    <th>Finished at</th>
                    <th>Queue Name</th>
                    <th>Cash In(€)</th>
                    <th>Goal(€)</th>
                    <th>%</th>
                    <th>Total Attempts</th>
                    <th>Succesful Attempts</th>
                    <th>%</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-daterange-filter
                            fieldName="created_at"
                            [start]="filters.created_at_start"
                            [end]="filters.created_at_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <app-daterange-filter
                            fieldName="executed_at"
                            [start]="filters.executed_at_start"
                            [end]="filters.executed_at_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <app-daterange-filter
                            fieldName="finished_at"
                            [start]="filters.finished_at_start"
                            [end]="filters.finished_at_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Queue Name"
                            name="task_name"
                            [(ngModel)]="filters.task_name"
                        />
                    </td>
                    <td colspan="7"></td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>
                            {{
                                formatDate(item?.created_at)
                                    | date: 'dd/MM/yyyy HH:mm:ss'
                            }}
                        </td>
                        <td>
                            {{
                                formatDate(item?.executed_at)
                                    | date: 'dd/MM/yyyy HH:mm:ss'
                            }}
                        </td>
                        <td>
                            {{
                                item?.finished_at
                                    ? (formatDate(item?.finished_at)
                                      | date: 'dd/MM/yyyy HH:mm:ss')
                                    : '-'
                            }}
                        </td>
                        <td>
                            <a
                                [routerLink]="[
                                    '/backoffice/debtrecovery/queue-manager/collection/' +
                                        item?.task?.id
                                ]"
                                >{{ item?.task?.name }}</a
                            >
                        </td>
                        <td>
                            {{
                                item?.payment_request?.amount?.success
                                    | currency: 'EUR'
                            }}
                        </td>
                        <td>
                            {{
                                item?.payment_request?.amount?.total
                                    | currency: 'EUR'
                            }}
                        </td>
                        <td>
                            {{
                                (item?.payment_request?.amount?.total
                                    ? item?.payment_request?.amount?.success /
                                      item?.payment_request?.amount?.total
                                    : 0
                                ) | percent
                            }}
                        </td>
                        <td>{{ item?.payment_request?.count?.total }}</td>
                        <td>
                            {{ item?.payment_request?.count?.success }}
                        </td>
                        <td>
                            {{
                                (item?.payment_request?.count?.total
                                    ? item?.payment_request?.count?.success /
                                      item?.payment_request?.count?.total
                                    : 0
                                ) | percent
                            }}
                        </td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="7">
                            <p class="py-5 mb-0">
                                Nothing Found
                            </p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="7">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
