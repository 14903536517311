import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../components/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    loggedIn = false;
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.userPermissions) {
            return this.existsPermission(this.authService.userPermissions, route.routeConfig.path);
        } else {
            this.authService.getUserPermissions().subscribe(
                (resp: any) => {
                    this.authService.userPermissions = resp;

                    if (this.existsPermission(resp, route.routeConfig.path)) {
                        return this.router.navigateByUrl(state.url);
                    } else {
                        this.authService.userPermissions = null;
                        this.router.navigate(['']);
                        return true;
                    }
                },
                error => {
                    return false;
                }
            );
        }
        return true;
    }
    existsPermission(permissions, name) {
        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].name === name) {
                return true;
            }
        }
        return false;
    }
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }
}
