<div *ngIf="isLoading" class="text-center">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading && tableData?.length" class="table-responsive">
    <h5 class="mb-4">
        Historia de presentaciones
        <span class="badge badge-info">{{ tableData.length }}</span>
    </h5>

    <table class="table table-sm" style="font-size: 90%">
        <thead>
            <tr>
                <th>Fecha de Terminación</th>
                <th>Referencia</th>
                <th>Fecha de Presentacion</th>
                <th>Fecha de Finalización</th>
                <th>Situacion</th>
                <th>Juzgado</th>
                <th>Referencia Procedimiento</th>
                <th>Referencia Ejecución</th>
                <th>Importe Aceptado</th>
                <th>Fecha de la Vista</th>
                <th>Comentarios</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableData">
                <td>
                    {{
                        item?.closed_at
                            ? (item.closed_at | dateFormat | date: 'dd/MM/yyyy')
                            : '-'
                    }}
                </td>
                <td>
                    {{ item?.reference_code || '-' }}
                </td>
                <td>
                    {{
                        item?.start_at
                            ? (item.start_at | dateFormat | date: 'dd/MM/yyyy')
                            : '-'
                    }}
                </td>
                <td>
                    {{
                        item?.end_at
                            ? (item.end_at | dateFormat | date: 'dd/MM/yyyy')
                            : '-'
                    }}
                </td>
                <td>
                    {{ item?.status?.name || '-' }}
                </td>
                <td>{{ item?.tribunal_code || '-' }}</td>
                <td>{{ item?.tribunal_proceeding_reference || '-' }}</td>
                <td>
                    {{ item?.tribunal_proceeding_execution_reference || '-' }}
                </td>
                <td>
                    {{
                        item?.tribunal_amount_accepted
                            ? (item.tribunal_amount_accepted | currency: 'EUR')
                            : '-'
                    }}
                </td>
                <td>
                    {{
                        item?.tribunal_hearing_at
                            ? (item.tribunal_hearing_at
                              | dateFormat
                              | date: 'dd/MM/yyyy')
                            : '-'
                    }}
                </td>
                <td>{{ item?.comment || '-' }}</td>
                <td class="py-0">
                    <button
                        class="btn btn-info btn-sm"
                        (click)="open(content, item)"
                    >
                        <i class="fa fa-eye"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            Presentacion {{ presentation?.reference_code || '-' }}
        </h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-presentation-show
            [presentation]="presentation"
        ></app-presentation-show>
    </div>
</ng-template>
