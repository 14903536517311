import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AffiliatesService } from '../../../services/affiliates.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create-affiliate',
    templateUrl: './create-affiliate.component.html',
    styleUrls: ['./create-affiliate.component.scss'],
})
export class CreateAffiliateComponent implements OnInit {
    modalReference: NgbModalRef;
    form: FormGroup;

    contactIndex: number = 0;
    sending: boolean = false;

    constructor(
        private modal: NgbModal,
        private fb: FormBuilder,
        private affiliatesService: AffiliatesService,
        private errorMessageService: ErrorMessageService,
        private router: Router
    ) {}

    ngOnInit() {
        this.createForm();
    }

    open(content) {
        this.modalReference = this.modal.open(content, {
            size: 'lg',
            backdrop: 'static',
        });

        this.modalReference.result.then(
            (result) => {
                this.resetData();
            },
            (reason) => {
                this.resetData();
            }
        );
    }

    createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            information_cif: ['', Validators.required],
            information_address: [''],
            information_zip_code: [''],
            information_city: [''],
            information_country: [''],
            contact_one_name: ['', Validators.required],
            contact_one_mobile_phone: ['', Validators.required],
            contact_one_email: ['', [Validators.required, Validators.email]],
            contact_one_role: ['', Validators.required],
            contact_two_name: [''],
            contact_two_mobile_phone: [''],
            contact_two_email: [''],
            contact_two_role: [''],
            contact_three_name: [''],
            contact_three_mobile_phone: [''],
            contact_three_email: [''],
            contact_three_role: [''],
            term_lead_condition: [''],
            term_value: [''],
            term_payment: [''],
            term_statistic_email: [''],
            rate: [''],
            api: [''],
            pixel: [''],
            enabled: [0, Validators.required],
        });
    }

    resetData() {
        this.contactIndex = 0;
        this.form.reset();
    }

    addContact(e: Event) {
        e.preventDefault();

        if (this.contactIndex < 2) {
            this.contactIndex++;
        }
    }

    submit(form: FormGroup) {
        this.sending = true;

        this.affiliatesService
            .createAffiliate(form.value)
            .toPromise()
            .then(
                (response) => {
                    if (response) {
                        setTimeout(
                            function (self) {
                                self.errorMessageService.showSuccess(
                                    'Affiliate created succesfully!'
                                );
                                self.modalReference.close();
                                self.router.navigate([
                                    `/backoffice/marketing/affiliate_info/${response.id}`,
                                ]);
                            },
                            600,
                            this
                        );
                        this.sending = false;
                    }
                },
                (error) => {
                    if (error.error.error) {
                        this.errorMessageService.showError(
                            error.error.error.show_message
                        );
                        setTimeout(
                            function (self) {
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );
                        this.sending = false;
                    }
                }
            );
    }
}
