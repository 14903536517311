<button
    class="btn"
    [ngClass]="{
        'btn-primary': action === 'create' || action === 'edit',
        'btn-danger': action === 'close',
        'w-100': action === 'create',
        'btn-sm': action === 'edit' || action === 'close'
    }"
    (click)="open(content, action)"
>
    <span *ngIf="action === 'create'">Crear Presentacion</span>
    <span *ngIf="action === 'edit'">Editar</span>
    <span *ngIf="action === 'close'">Terminar</span>
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ switchTitle(action) }} Presentacion</h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form
            *ngIf="form"
            id="presentationForm"
            [formGroup]="form"
            autocomplete="off"
            (ngSubmit)="onSubmit(action)"
        >
            <div class="col-12">
                <input
                    type="hidden"
                    class="form-control d-none"
                    id="proceeding_id"
                    name="proceeding_id"
                    formControlName="proceeding_id"
                />
            </div>
            <div
                *ngIf="action === 'create' || action === 'edit'"
                class="form-row"
            >
                <div class="col-md-6 form-group">
                    <label for="start_at">
                        Fecha de Presentacion
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="date"
                        class="form-control"
                        id="start_at"
                        name="start_at"
                        formControlName="start_at"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls.start_at.touched &&
                                !form.controls.start_at.valid,
                            'is-valid':
                                form.controls.start_at.touched &&
                                form.controls.start_at.valid
                        }"
                        required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="status_id">
                        Situacion
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <div
                            *ngIf="isSituationsLoading"
                            class="input-group-prepend"
                        >
                            <div class="input-group-text">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        </div>
                        <select
                            id="status_id"
                            class="form-control"
                            name="status_id"
                            formControlName="status_id"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.status_id.touched &&
                                    !form.controls.status_id.valid,
                                'is-valid':
                                    form.controls.status_id.touched &&
                                    form.controls.status_id.valid
                            }"
                            required
                        >
                            <option value="" disabled>Seleccione</option>
                            <option
                                *ngFor="let item of situations"
                                [value]="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12"><hr /></div>
                <div class="col-md-6 form-group">
                    <label for="tribunal_code">Juzgado</label>
                    <input
                        type="text"
                        class="form-control"
                        id="tribunal_code"
                        name="tribunal_code"
                        formControlName="tribunal_code"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_code.touched &&
                                !form.controls.tribunal_code.valid,
                            'is-valid':
                                form.controls.tribunal_code.touched &&
                                form.controls.tribunal_code.valid
                        }"
                    />
                </div>
                <div class="col-12">
                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <label for="tribunal_proceeding_reference">
                                Referencia Procedimiento
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="tribunal_proceeding_reference"
                                name="tribunal_proceeding_reference"
                                formControlName="tribunal_proceeding_reference"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls
                                            .tribunal_proceeding_reference
                                            .touched &&
                                        !form.controls
                                            .tribunal_proceeding_reference
                                            .valid,
                                    'is-valid':
                                        form.controls
                                            .tribunal_proceeding_reference
                                            .touched &&
                                        form.controls
                                            .tribunal_proceeding_reference.valid
                                }"
                            />
                        </div>
                        <div class="col-md-6 form-group">
                            <label
                                for="tribunal_proceeding_execution_reference"
                            >
                                Referencia Ejecución
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="tribunal_proceeding_execution_reference"
                                name="tribunal_proceeding_execution_reference"
                                formControlName="tribunal_proceeding_execution_reference"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls
                                            .tribunal_proceeding_execution_reference
                                            .touched &&
                                        !form.controls
                                            .tribunal_proceeding_execution_reference
                                            .valid,
                                    'is-valid':
                                        form.controls
                                            .tribunal_proceeding_execution_reference
                                            .touched &&
                                        form.controls
                                            .tribunal_proceeding_execution_reference
                                            .valid
                                }"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12"><hr /></div>
                <div class="col-md-6 form-group">
                    <label for="tribunal_amount_accepted">
                        Importe Aceptado
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="tribunal_amount_accepted"
                        name="tribunal_amount_accepted"
                        formControlName="tribunal_amount_accepted"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_amount_accepted
                                    .touched &&
                                !form.controls.tribunal_amount_accepted.valid,
                            'is-valid':
                                form.controls.tribunal_amount_accepted
                                    .touched &&
                                form.controls.tribunal_amount_accepted.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="tribunal_hearing_at"> Fecha de la Vista </label>
                    <input
                        type="date"
                        class="form-control"
                        id="tribunal_hearing_at"
                        name="tribunal_hearing_at"
                        formControlName="tribunal_hearing_at"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_hearing_at.touched &&
                                !form.controls.tribunal_hearing_at.valid,
                            'is-valid':
                                form.controls.tribunal_hearing_at.touched &&
                                form.controls.tribunal_hearing_at.valid
                        }"
                    />
                </div>
                <div class="col-12"><hr /></div>
                <div class="col-12 form-group">
                    <label for="comment"> Comentarios </label>
                    <textarea
                        type="text"
                        class="form-control"
                        id="comment"
                        name="comment"
                        formControlName="comment"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.comment.touched &&
                                !form.controls.comment.valid,
                            'is-valid':
                                form.controls.comment.touched &&
                                form.controls.comment.valid
                        }"
                        rows="3"
                    ></textarea>
                </div>
                <div class="col-12"><hr /></div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_population"
                        >Población del Juzgado</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_population"
                        name="tribunal_population"
                        formControlName="tribunal_population"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_population.touched &&
                                !form.controls.tribunal_population.valid,
                            'is-valid':
                                form.controls.tribunal_population.touched &&
                                form.controls.tribunal_population.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_acceptance_amount_proposal_auto_date"
                        >Fecha</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_acceptance_amount_proposal_auto_date"
                        name="tribunal_acceptance_amount_proposal_auto_date"
                        formControlName="tribunal_acceptance_amount_proposal_auto_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_acceptance_amount_proposal_auto_date
                                    .touched &&
                                !form.controls
                                    .tribunal_acceptance_amount_proposal_auto_date
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_acceptance_amount_proposal_auto_date
                                    .touched &&
                                form.controls
                                    .tribunal_acceptance_amount_proposal_auto_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_proceeding_verbal_reference"
                        >Referencia Procedimiento Verbal</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_proceeding_verbal_reference"
                        name="tribunal_proceeding_verbal_reference"
                        formControlName="tribunal_proceeding_verbal_reference"
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_proceeding_verbal_reference
                                    .touched &&
                                !form.controls
                                    .tribunal_proceeding_verbal_reference.valid,
                            'is-valid':
                                form.controls
                                    .tribunal_proceeding_verbal_reference
                                    .touched &&
                                form.controls
                                    .tribunal_proceeding_verbal_reference.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_document_type_title"
                        >Título en el Tipo de Documento</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_document_type_title"
                        name="tribunal_document_type_title"
                        formControlName="tribunal_document_type_title"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_document_type_title
                                    .touched &&
                                !form.controls.tribunal_document_type_title
                                    .valid,
                            'is-valid':
                                form.controls.tribunal_document_type_title
                                    .touched &&
                                form.controls.tribunal_document_type_title.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_debtor_company_cif"
                        >CIF de la Empresa del Deudor</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_debtor_company_cif"
                        name="tribunal_debtor_company_cif"
                        formControlName="tribunal_debtor_company_cif"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_debtor_company_cif
                                    .touched &&
                                !form.controls.tribunal_debtor_company_cif
                                    .valid,
                            'is-valid':
                                form.controls.tribunal_debtor_company_cif
                                    .touched &&
                                form.controls.tribunal_debtor_company_cif.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_providence_date"
                        >Fecha de Providencia</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_providence_date"
                        name="tribunal_providence_date"
                        formControlName="tribunal_providence_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_providence_date
                                    .touched &&
                                !form.controls.tribunal_providence_date.valid,
                            'is-valid':
                                form.controls.tribunal_providence_date
                                    .touched &&
                                form.controls.tribunal_providence_date.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_providence_number"
                        >Referencia de Providencia</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_providence_number"
                        name="tribunal_providence_number"
                        formControlName="tribunal_providence_number"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_providence_number
                                    .touched &&
                                !form.controls.tribunal_providence_number.valid,
                            'is-valid':
                                form.controls.tribunal_providence_number
                                    .touched &&
                                form.controls.tribunal_providence_number.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_providence_reception_date"
                        >Fecha de Recepción de Providencia</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_providence_reception_date"
                        name="tribunal_providence_reception_date"
                        formControlName="tribunal_providence_reception_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_providence_reception_date
                                    .touched &&
                                !form.controls
                                    .tribunal_providence_reception_date.valid,
                            'is-valid':
                                form.controls.tribunal_providence_reception_date
                                    .touched &&
                                form.controls.tribunal_providence_reception_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label
                        for="tribunal_approval_execution_sentence_decree_number"
                        >Referencia del Decreto de Ejecución de Sentencia</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_approval_execution_sentence_decree_number"
                        name="tribunal_approval_execution_sentence_decree_number"
                        formControlName="tribunal_approval_execution_sentence_decree_number"
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_number
                                    .touched &&
                                !form.controls
                                    .tribunal_approval_execution_sentence_decree_number
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_number
                                    .touched &&
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_number
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label
                        for="tribunal_approval_execution_sentence_decree_date"
                        >Fecha del Decreto de Ejecución de Sentencia</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_approval_execution_sentence_decree_date"
                        name="tribunal_approval_execution_sentence_decree_date"
                        formControlName="tribunal_approval_execution_sentence_decree_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_date
                                    .touched &&
                                !form.controls
                                    .tribunal_approval_execution_sentence_decree_date
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_date
                                    .touched &&
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label
                        for="tribunal_approval_execution_sentence_decree_reception_date"
                        >Fecha Recepción del Decreto de Ejecución de
                        Sentencia</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_approval_execution_sentence_decree_reception_date"
                        name="tribunal_approval_execution_sentence_decree_reception_date"
                        formControlName="tribunal_approval_execution_sentence_decree_reception_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_reception_date
                                    .touched &&
                                !form.controls
                                    .tribunal_approval_execution_sentence_decree_reception_date
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_reception_date
                                    .touched &&
                                form.controls
                                    .tribunal_approval_execution_sentence_decree_reception_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_execution_sentence_number"
                        >Referencia de la Ejecución de Sentencia</label
                    >
                    <input
                        type="text"
                        class="form-control align-self-end"
                        id="tribunal_execution_sentence_number"
                        name="tribunal_execution_sentence_number"
                        formControlName="tribunal_execution_sentence_number"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_execution_sentence_number
                                    .touched &&
                                !form.controls
                                    .tribunal_execution_sentence_number.valid,
                            'is-valid':
                                form.controls.tribunal_execution_sentence_number
                                    .touched &&
                                form.controls.tribunal_execution_sentence_number
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_execution_sentence_date"
                        >Fecha de la Ejecución de Sentencia</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_execution_sentence_date"
                        name="tribunal_execution_sentence_date"
                        formControlName="tribunal_execution_sentence_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls.tribunal_execution_sentence_date
                                    .touched &&
                                !form.controls.tribunal_execution_sentence_date
                                    .valid,
                            'is-valid':
                                form.controls.tribunal_execution_sentence_date
                                    .touched &&
                                form.controls.tribunal_execution_sentence_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_execution_sentence_reception_date"
                        >Fecha de Recepción de la Ejecución de Sentencia</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_execution_sentence_reception_date"
                        name="tribunal_execution_sentence_reception_date"
                        formControlName="tribunal_execution_sentence_reception_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_execution_sentence_reception_date
                                    .touched &&
                                !form.controls
                                    .tribunal_execution_sentence_reception_date
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_execution_sentence_reception_date
                                    .touched &&
                                form.controls
                                    .tribunal_execution_sentence_reception_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_liquidation_legal_interest_strat_date"
                        >Fecha de Inicio para Liquidación de Interes
                        Legal</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_liquidation_legal_interest_strat_date"
                        name="tribunal_liquidation_legal_interest_strat_date"
                        formControlName="tribunal_liquidation_legal_interest_strat_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_strat_date
                                    .touched &&
                                !form.controls
                                    .tribunal_liquidation_legal_interest_strat_date
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_strat_date
                                    .touched &&
                                form.controls
                                    .tribunal_liquidation_legal_interest_strat_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_liquidation_legal_interest_end_date"
                        >Fecha de Final para Liquidación de Interes Legal</label
                    >
                    <input
                        type="date"
                        class="form-control align-self-end"
                        id="tribunal_liquidation_legal_interest_end_date"
                        name="tribunal_liquidation_legal_interest_end_date"
                        formControlName="tribunal_liquidation_legal_interest_end_date"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_end_date
                                    .touched &&
                                !form.controls
                                    .tribunal_liquidation_legal_interest_end_date
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_end_date
                                    .touched &&
                                form.controls
                                    .tribunal_liquidation_legal_interest_end_date
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label for="tribunal_liquidation_legal_interest_amount"
                        >Cantidad de Dinero Para Liquidación de Interes
                        Legal</label
                    >
                    <input
                        type="number"
                        class="form-control align-self-end"
                        id="tribunal_liquidation_legal_interest_amount"
                        name="tribunal_liquidation_legal_interest_amount"
                        formControlName="tribunal_liquidation_legal_interest_amount"
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_amount
                                    .touched &&
                                !form.controls
                                    .tribunal_liquidation_legal_interest_amount
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_amount
                                    .touched &&
                                form.controls
                                    .tribunal_liquidation_legal_interest_amount
                                    .valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group d-flex flex-wrap">
                    <label
                        for="tribunal_liquidation_legal_interest_thirty_percent_amount"
                        >30% del Dinero para Liquidación de Interes Legal</label
                    >
                    <input
                        type="number"
                        class="form-control align-self-end"
                        id="tribunal_liquidation_legal_interest_thirty_percent_amount"
                        name="tribunal_liquidation_legal_interest_thirty_percent_amount"
                        formControlName="tribunal_liquidation_legal_interest_thirty_percent_amount"
                        [ngClass]="{
                            'is-invalid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_thirty_percent_amount
                                    .touched &&
                                !form.controls
                                    .tribunal_liquidation_legal_interest_thirty_percent_amount
                                    .valid,
                            'is-valid':
                                form.controls
                                    .tribunal_liquidation_legal_interest_thirty_percent_amount
                                    .touched &&
                                form.controls
                                    .tribunal_liquidation_legal_interest_thirty_percent_amount
                                    .valid
                        }"
                    />
                </div>
            </div>

            <div *ngIf="action === 'close'">
                <div class="form-group">
                    <label for="start_at">
                        Fecha de Finalización
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="date"
                        class="form-control"
                        id="end_at"
                        name="end_at"
                        formControlName="end_at"
                        appValidDate
                        [ngClass]="{
                            'is-invalid':
                                form.controls.end_at.touched &&
                                !form.controls.end_at.valid,
                            'is-valid':
                                form.controls.end_at.touched &&
                                form.controls.end_at.valid
                        }"
                        required
                    />
                </div>
                <div class="form-group">
                    <label for="status_id">
                        Situacion
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <div
                            *ngIf="isSituationsLoading"
                            class="input-group-prepend"
                        >
                            <div class="input-group-text">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        </div>
                        <select
                            id="status_id"
                            class="form-control"
                            name="status_id"
                            formControlName="status_id"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.status_id.touched &&
                                    !form.controls.status_id.valid,
                                'is-valid':
                                    form.controls.status_id.touched &&
                                    form.controls.status_id.valid
                            }"
                            required
                        >
                            <option value="" disabled>Seleccione</option>
                            <option
                                *ngFor="let item of situations"
                                [value]="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="comment"> Comentarios </label>
                    <textarea
                        type="text"
                        class="form-control"
                        id="comment"
                        name="comment"
                        formControlName="comment"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.comment.touched &&
                                !form.controls.comment.valid,
                            'is-valid':
                                form.controls.comment.touched &&
                                form.controls.comment.valid
                        }"
                        rows="3"
                    ></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            Cerrar
        </button>
        <button
            *ngIf="form"
            type="submit"
            class="btn btn-primary"
            form="presentationForm"
            [disabled]="form.invalid || isSending"
        >
            {{ isSending ? 'Guardando...' : switchTitle(action) }}
        </button>
    </div>
</ng-template>
