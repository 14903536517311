<div class="table-responsive table-hover">
  <table class="table">
    <thead>
      <tr>
        <th>Event</th>
        <th>Message Type</th>
        <th>Channel</th>
        <th>Provider</th>
        <th>Active</th>
        <th>Created At</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-template [ngIf]="tableData?.length > 0 && !loading">
        <tr *ngFor="let item of tableData">
          <td>
            {{ item?.event?.name }}
          </td>
          <td>
            {{ item?.type?.name }}
            <app-mail-templates-preview
              class="ml-1"
              *ngIf="item?.type?.template_id"
              [cssClass]="'btn-sm btn-outline-primary'"
              [id]="item?.type?.template_id"
            ></app-mail-templates-preview>
          </td>
          <td>{{ item?.channel?.name }}</td>
          <td>
            {{ item?.provider?.name }}
          </td>
          <td>
            <span
              class="badge"
              [ngClass]="{
                'badge-success': item?.enabled,
                'badge-warning': !item?.enabled
              }"
              >{{ item?.enabled ? 'Yes' : 'No' }}</span
            >
          </td>
          <td>
            {{ formatDate(item?.created_at) | date: 'dd/MM/yyyy HH:mm' }}
          </td>
          <td>
            <app-mail-messages-send-config-create
              [item]="item"
            ></app-mail-messages-send-config-create>
          </td>
        </tr>
      </ng-template>
      <ng-template [ngIf]="!tableData?.length && !loading">
        <tr class="without_hover">
          <td colspan="3">
            <p class="py-5 mb-0">Nothing Found</p>
          </td>
        </tr>
      </ng-template>
      <tr *ngIf="loading" class="without_hover">
        <td colspan="3">
          <p class="py-5 mb-0">
            <img
              width="30px"
              height="30px"
              src="assets/img/backoffice/loading.gif"
            />
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
