import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { CreditLineService } from '../../../services/credit-line.service';

import { debounceTime } from 'rxjs/operators';

import { SubscriptionLike as ISubscription, combineLatest } from 'rxjs';
import { LeadService } from '../../../services/lead.service';

declare var moment: any;

@Component({
    selector: 'app-users-table',
    templateUrl: './users-table.component.html',
    styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnDestroy {
    status: string;
    pageNames: any = {
        dropout: 'Users Drop Out',
        blacklisted: 'Users Blacklisted',
        all: 'All Users',
        customer: 'All Customers',
        asnef_included: 'Customers ASNEF Included',
    };
    selectedPageName = '';
    title: string = '';

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    startData = {};

    filters = {};

    queryString: string = '';

    tableData;

    loading = true;

    calendarDates = {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };

    dateName: string = '';

    permissionsArray: Array<string> = [];

    asnef_statuses = [];
    admins: any;
    genders = [];
    reasonsList = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private leadService: LeadService,
        private usersService: UserService,
        private router: Router,
        private creditLine: CreditLineService
    ) {}

    ngOnInit() {
        const params$ = this.activatedRoute.params;
        const queryParams$ = this.activatedRoute.queryParams;

        this.getSelectsLists();

        this.querySubscriber = combineLatest(params$, queryParams$)
            .pipe(debounceTime(300))
            .subscribe(([params, queryParams]) => {
                if (this.pageNames[params['status']]) {
                    this.selectedPageName = params['status'];
                    this.title = this.pageNames[params['status']];

                    this.permissionsArray = [
                        `datamanagement-users-${this.selectedPageName}`,
                    ];
                    if (this.selectedPageName === 'asnef_included') {
                        this.permissionsArray = [
                            `datamanagement-users-asnef-included`,
                        ];
                    }

                    switch (this.selectedPageName) {
                        case 'dropout':
                            this.dateName = 'Drop Out';
                            break;
                        case 'blacklisted':
                            this.dateName = 'Blacklisted';
                            break;
                        default:
                            this.dateName = 'Inclusion';
                            break;
                    }

                    this.authService
                        .getFilteredUsersList()
                        .then((data) => (this.admins = data));

                    this.startData = {
                        send_date_start: '',
                        send_date_end: '',
                        date_start: '',
                        date_end: '',
                        personalid: '',
                        full_name: '',
                        asnef_status_id: '',
                        debt_left_start: '',
                        debt_left_end: '',
                        quota_refused_count: '',
                        repeat_customer: '',
                        email: '',
                        mobile_phone: '',
                        other_phone: '',
                        work_phone: '',
                        additional_phone: '',
                        sex_id: '',
                        is_customer: '',
                        dropout: '',
                        blacklisted: '',
                        who: '',
                        loan_code_str: '',
                        status_id: '',
                        last_status_at_start: '',
                        last_status_at_end: '',
                        name: '',
                    };

                    if (this.dataSubscriber) {
                        this.dataSubscriber.unsubscribe();
                    }

                    // Set Init Filters
                    this.setInitFilters(queryParams);
                    // Create Query String
                    this.createQueryString(this.filters);

                    this.getTableData();
                }
            });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;

        this.dataSubscriber =
            this.selectedPageName === 'asnef_included'
                ? this.usersService
                      .getAsnefList(this.queryString)
                      .subscribe((response) => {
                          if (response) {
                              this.tableData = response;
                              this.loading = false;
                          }
                      })
                : this.usersService
                      .getList(this.selectedPageName, this.queryString)
                      .subscribe((response) => {
                          if (response) {
                              this.tableData = response;
                              this.loading = false;
                          }
                      });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeRange(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getSelectsLists() {
        this.leadService.getRequestInfo().then(({ user_sex }) => {
            if (user_sex) {
                Object.keys(user_sex).forEach((key) =>
                    this.genders.push({
                        id: key,
                        name: user_sex[key],
                    })
                );
            }
        });

        this.usersService.getAsnefStatuses().subscribe((data) => {
            this.asnef_statuses = data;
        });

        this.usersService
            .getAsnefExludeReasons()
            .toPromise()
            .then((reasons) => (this.reasonsList = reasons));
    }

    userInfo(id) {
        if (id) {
            this.router.navigate([`/backoffice/datamanagement/user/`, id]);
        }
    }

    openUser(e, loan_id) {
        e.preventDefault();

        if (loan_id) {
            this.creditLine.getById(loan_id).then((response) => {
                this.userInfo(response[0].lead_id);
            });
        }
    }

    onUserExluded(record_id) {
        this.tableData.data.map((record) => {
            if (record.id === record_id) {
                this.loading = true;
                this.getTableData();
            }
        });
    }
}
