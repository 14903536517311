import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    SimpleChange,
} from '@angular/core';
import { DaterangepickerComponent } from 'ng2-daterangepicker';

declare var moment: any;

@Component({
    selector: 'app-daterange-filter',
    templateUrl: './daterange-filter.component.html',
    styleUrls: ['./daterange-filter.component.scss'],
})
export class DaterangeFilterComponent implements OnInit, OnChanges {
    @ViewChild(DaterangepickerComponent)
    private picker: DaterangepickerComponent;

    @Input() start: any;
    @Input() end: any;

    @Input() defaultDate: any = {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    };

    @Input()
    fieldName: string = '';
    @Input()
    withStartDate: boolean = false;
    @Input()
    reset: boolean = false;
    @Input() disabled: boolean = false;
    @Input() resetDates: any = null;
    @Input() ranges: any = null;

    @Output()
    changeDate: EventEmitter<any> = new EventEmitter();

    settings: any = null;

    dateInputValue: string = '';

    minDate = '2015-01-01';
    maxDate = moment().format('YYYY-MM-DD');

    constructor() {}

    ngOnInit() {
        this.settings = {
            locale: {
                format: 'YYYY-MM-DD',
                cancelLabel: 'Clear',
                firstDay: 1,
            },
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            showDropdowns: true,
            ranges: this.ranges
                ? this.ranges
                : {
                      Today: [
                          moment().format('YYYY-MM-DD'),
                          moment().format('YYYY-MM-DD'),
                      ],
                      Yesterday: [
                          moment().subtract(1, 'days').format('YYYY-MM-DD'),
                          moment().subtract(1, 'days').format('YYYY-MM-DD'),
                      ],
                      'Last 5 Days': [
                          moment().subtract(4, 'days').format('YYYY-MM-DD'),
                          moment().format('YYYY-MM-DD'),
                      ],
                      'This Week': [
                          moment().startOf('isoWeek').format('YYYY-MM-DD'),
                          moment().endOf('isoWeek').format('YYYY-MM-DD'),
                      ],
                      'Last Week': [
                          moment()
                              .subtract(1, 'week')
                              .startOf('isoWeek')
                              .format('YYYY-MM-DD'),
                          moment()
                              .subtract(1, 'week')
                              .endOf('isoWeek')
                              .format('YYYY-MM-DD'),
                      ],
                      'This Month': [
                          moment().startOf('month').format('YYYY-MM-DD'),
                          moment().endOf('month').format('YYYY-MM-DD'),
                      ],
                      'Last Month': [
                          moment()
                              .subtract(1, 'month')
                              .startOf('month')
                              .format('YYYY-MM-DD'),
                          moment()
                              .subtract(1, 'month')
                              .endOf('month')
                              .format('YYYY-MM-DD'),
                      ],
                  },
            minDate: this.minDate,
            maxDate: this.maxDate,
            startDate: this.start || this.defaultDate.start,
            endDate: this.end || this.defaultDate.end,
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        const reset: SimpleChange = changes.reset;

        // if (this.picker && this.picker.datePicker && !reset && this.start && this.end) {
        //     this.picker.datePicker.hide();
        //     this.updateDateRange({ start: this.start, end: this.end });
        // }

        if (reset && reset.previousValue) {
            this.clearCalendar('reset');
        }

        this.setStartDate();
    }

    selectedDate(e: any) {
        const oldDate = this.dateInputValue;
        const dates = this.setDates(
            this.fieldName,
            e.picker.startDate.format('YYYY-MM-DD'),
            e.picker.endDate.format('YYYY-MM-DD')
        );

        this.dateInputValue =
            dates[this.fieldName + '_start'] +
            ' - ' +
            dates[this.fieldName + '_end'];
        if (oldDate !== this.dateInputValue) {
            this.changeDate.emit(dates);
        }
    }

    setStartDate() {
        this.dateInputValue =
            this.start &&
            this.end &&
            this.start !== 'empty' &&
            this.end !== 'empty'
                ? this.start + ' - ' + this.end
                : '';
    }

    clearCalendar(e: any) {
        const reset = e === 'reset';
        if (reset || (e.picker && this.dateInputValue)) {
            this.dateInputValue =
                reset &&
                this.withStartDate &&
                this.start !== '' &&
                this.end !== ''
                    ? this.start + ' - ' + this.end
                    : '';

            const updateDates =
                reset && this.withStartDate
                    ? { start: this.start, end: this.end }
                    : {
                          start: this.defaultDate.start,
                          end: this.defaultDate.end,
                      };

            this.updateDateRange(updateDates);

            if (!reset) {
                const resetDates = {
                    start: this.resetDates?.startDate || 'empty',
                    end: this.resetDates?.endDate || 'empty',
                };
                this.changeDate.emit(
                    this.setDates(
                        this.fieldName,
                        resetDates.start,
                        resetDates.end
                    )
                );
            }
        }
    }

    setDates(name, start, end) {
        const startName = name + '_start';
        const endName = name + '_end';
        return {
            [startName]: start,
            [endName]: end,
        };
    }

    updateDateRange(dates) {
        this.picker.datePicker.setStartDate(dates.start);
        this.picker.datePicker.setEndDate(dates.end);
    }
}
