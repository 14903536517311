import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-tree-list',
  templateUrl: './payment-tree-list.component.html',
  styleUrls: ['./payment-tree-list.component.scss'],
})
export class PaymentTreeListComponent implements OnInit, OnChanges {
  trees = [];
  @Input() active = null;
  @Input() created;
  constructor(
    private debtRecoveryService: DebtRecoveryService,
    private router: Router
  ) {}

  ngOnInit() {
    this.debtRecoveryService
      .getPaymentTreeAmount()
      .pipe(take(1))
      .subscribe((response) => {
        this.trees = response;

        if (this.trees.length > 0 && !this.active) {
          this.active = this.trees[0].id;
          this.router.navigate([
            `/backoffice/settings/payment-tree/${this.active}`,
          ]);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const createdChange = changes.created;

    if (createdChange && createdChange.currentValue) {
      this.trees.push(createdChange.currentValue);

      this.router.navigate([
        `/backoffice/settings/payment-tree/${createdChange.currentValue.id}`,
      ]);
    }
  }

  onDelete(e: number) {
    if (e) {
      const index = this.trees.findIndex(function (tree) {
        return tree.id === e;
      });

      if (index !== -1) {
        this.trees.splice(index, 1);

        if (this.active && this.active === e && this.trees.length > 0) {
          if (this.trees.length > 0) {
            this.router.navigate([
              `/backoffice/settings/payment-tree/${this.trees[0].id}`,
            ]);
          } else {
            this.router.navigate([`/backoffice/settings/payment-tree/`]);
          }
        }
      }
    }
  }
}
