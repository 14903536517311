import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-asnef-exlude',
    templateUrl: './asnef-exlude.component.html',
    styleUrls: ['./asnef-exlude.component.scss'],
})
export class AsnefExludeComponent implements OnInit {
    @Input() record_id = null;
    @Input() isShort = true;
    @Input() reasonsList = [];
    @Output() userExluded: EventEmitter<any> = new EventEmitter();

    isSending = false;

    modalReference: NgbModalRef;

    constructor(
        private modalService: NgbModal,
        private userService: UserService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {}

    open(content) {
        this.modalReference = this.modalService.open(content);
    }

    onSubmit(form) {
        if (confirm('You are sure to perform this operation?')) {
            this.isSending = true;
            this.userService
                .exludeFromAsnef(+this.record_id, +form.value.reason_id)
                .toPromise()
                .then((response) => {
                    if (response) {
                        this.modalReference.close();
                        this.userExluded.emit(this.record_id);
                        this.errorMessageService.showSuccess(
                            'User Exluded Successfuly'
                        );

                        this.isSending = false;
                    }
                })
                .catch((error) => {
                    this.isSending = false;
                    this.modalReference.close();
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                });
        }
    }
}
