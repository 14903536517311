import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { Chart } from 'chart.js';
import { KpiService } from '../../../services/kpi.service';

declare const moment: any;
@Component({
    selector: 'app-kpis-profitability-evolucion',
    templateUrl: './kpis-profitability-evolucion.component.html',
    styleUrls: ['./kpis-profitability-evolucion.component.scss'],
})
export class KpisProfitabilityEvolucionComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('revenueChart') revenueChart: ElementRef;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    data;

    startData = {};
    filters;
    queryString = '';

    loading = false;

    minDate = '2015-01-01';
    maxDate = moment().format('YYYY-MM-DD');

    ranges: any = {};

    labels = [];
    chartData = [];
    chartInstance: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private kpiService: KpiService
    ) {}

    ngOnInit(): void {
        this.ranges = this.createRanges();

        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    portfolio_date_start: moment()
                        .startOf('year')
                        .format('YYYY-MM-DD'),
                    portfolio_date_end: moment().format('YYYY-MM-DD'),
                    time_unit: 'month',
                };

                if (this.dataSubscriber) {
                    this.dataSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getData();
            }
        );
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.createChart(this.revenueChart?.nativeElement);
        });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getData() {
        this.loading = true;
        this.dataSubscriber = this.kpiService
            .getEvolutionDelinquency(this.queryString)
            .subscribe((response) => {
                if (response) {
                    this.data = response;

                    this.data.data.sort((a, b) =>
                        a.year > b.year
                            ? 1
                            : a.year === b.year
                            ? a.time_unit_count > b.time_unit_count
                                ? 1
                                : -1
                            : -1
                    );

                    if (this.chartInstance) {
                        this.chartInstance.data.labels = this.createLabels(
                            this.data.data
                        );
                        this.chartInstance.data.datasets[0].data = this.data.data.map(
                            (item) => item.tm
                        );
                        this.chartInstance.update();
                    }

                    this.loading = false;
                }
            });
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    createRanges() {
        const startYear = moment(this.minDate).format('YYYY');
        const currentYear = moment().format('YYYY');
        let endYear = moment(this.maxDate).format('YYYY');
        const years = {};

        while (endYear >= startYear) {
            years[endYear] = [
                moment().year(endYear).startOf('year').format('YYYY-MM-DD'),
                currentYear === endYear
                    ? moment().format('YYYY-MM-DD')
                    : moment().year(endYear).endOf('year').format('YYYY-MM-DD'),
            ];

            endYear--;
        }

        return years;
    }

    createLabels(data: any = []) {
        const labels = data.map((item) =>
            moment()
                .month(item?.time_unit_count - 1)
                .year(item?.year)
                .format('MMM YYYY')
        );

        return labels;
    }

    createChart(ctx) {
        if (ctx) {
            this.chartInstance = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [],
                    datasets: [
                        {
                            borderColor: '#69356e',
                            fill: false,
                            data: [],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    legend: {
                        display: false,
                    },
                },
            });
        }
    }
}
