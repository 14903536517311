import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../../services/product.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-settings-product-list',
    templateUrl: './settings-product-list.component.html',
    styleUrls: ['./settings-product-list.component.scss'],
})
export class SettingsProductListComponent implements OnInit {
    constructor(
        public productService: ProductService,
        private errorMessage: ErrorMessageService
    ) {}

    isSending = false;

    ngOnInit() {
        this.getProductList();
    }

    getProductList() {
        this.productService.list();
    }

    active(product_id, visible) {
        if (confirm('You are sure to perform this operation?')) {
            this.isSending = true;
            if (visible) {
                this.productService.deActive(product_id).subscribe(
                    (resp) => {
                        this.errorMessage.showSuccess(
                            `El Producto ${resp.name} ha sido deactivado`
                        );
                        this.productService.products.forEach(
                            (product, index) => {
                                if (+product?.id === +product_id) {
                                    this.productService.products[index] = resp;
                                }
                            }
                        );
                        this.isSending = false;
                    },
                    (error) => {
                        this.isSending = false;
                        this.errorMessage.showError(
                            error.error.error.show_message
                        );
                    }
                );
            } else {
                this.productService.active(product_id).subscribe(
                    (resp) => {
                        this.errorMessage.showSuccess(
                            `El Producto ${resp.name} ha sido activado`
                        );
                        this.productService.products.forEach(
                            (product, index) => {
                                if (+product?.id === +product_id) {
                                    this.productService.products[index] = resp;
                                }
                            }
                        );
                        this.isSending = false;
                    },
                    (error) => {
                        this.isSending = false;
                        this.errorMessage.showError(
                            error.error.error.show_message
                        );
                    }
                );
            }
        }
    }
}
