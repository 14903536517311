import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ScoringService {
    configValidator: any = null;
    constructor(private http: HttpClient) {}

    getByLeadId(lead_id): Observable<any> {
        return this.http.get(environment.api_url + `/lead/${lead_id}/scoring/show`);
    }
    getConfigValidatorList(): Observable<any> {
        return this.http.get(environment.api_url + `/config/validator/scoring/list`);
    }
    updateConfigValidator(data): Observable<any> {
        return this.http.post(environment.api_url + `/config/validator/scoring/edit`, data);
    }
}
