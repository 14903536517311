<div class="container">
    <div class="form-row align-items-stretch mb-3">
        <div *ngFor="let finance of finances" class="col-6 col-sm-4 col-lg">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">
                        {{ finance?.status }}
                        <br />
                        <small class="text-body">in €</small>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-end pt-2">
                        <h3 class="mb-0">{{ finance?.units?.today | number }}</h3>
                        <span>Today</span>
                    </div>
                    <ng-container *ngFor="let item of (finance?.units | keys); let last = last">
                        <div
                            *ngIf="item != 'today'"
                            class="d-flex justify-content-between pt-3"
                            [ngClass]="{ 'border-bottom': !last, 'pb-1': !last }"
                        >
                            <span>
                                {{ item | replace: '_':' ' | titlecase }}
                            </span>
                            <span>{{ (finance?.units)[item] | number }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="col-6 col-sm-4 col-md-3 col-lg-20 mb-3">
            <input
                type="text"
                name="date"
                daterangepicker
                [options]="settings"
                class="form-control"
                (selected)="selectedDate($event)"
                placeholder="Choose date"
                (cancelDaterangepicker)="clearCalendar($event)"
                (keyup.enter)="clearCalendar($event)"
                autocomplete="off"
                [value]="formDateValue"
            />
            <!-- <select
                [(ngModel)]="selectRange"
                class="form-control"
            >
                <option
                    *ngFor="let item of selectRanges"
                    [value]="item"
                >
                    {{
                    item | replace: '_':' ' | titlecase
                }}
                </option>
            </select> -->
        </div>
    </div>

    <div class="form-row">
        <div *ngFor="let finance of finances" class="col-6 col-sm-4 col-lg">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">
                        {{ finance?.status }}
                        <br />
                        <small class="text-body">in €</small>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-end align-items-end pt-2 pb-2">
                        <h3 class="mb-0">
                            {{ (finance?.units)[selectRange] | number }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
