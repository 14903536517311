import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {

  constructor(private http: HttpClient) { }

  existsPending(): Observable<any>{
    return this.http.get(environment.api_url + '/payment/pending/exists');
  }
  makeSecurePaymentByCreditCard(callback_redirect_url: string, amount: number, card_id: any): Observable<any>
  {
    return this.http.post(environment.api_url + '/payment/secure/credit_card/make', {card_id:card_id, amount: amount, callback_redirect_url: callback_redirect_url});
  }
  makeSecurePaymentByFastAccountTransfer(amount: number, success_callback_url: string,  failure_callback_url: string): Observable<any>
  {
    return this.http.post(environment.api_url + '/payment/secure/fast_transfer/make', {amount: amount, success_callback_url: success_callback_url, failure_callback_url:failure_callback_url});
  }
  getCatalogedByDebt(){
    return this.http.get(environment.api_url + '/payment/list');
  }
}
