import { Component, OnInit } from '@angular/core';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { ScoringService } from '../../../services/scoring.service';

@Component({
    selector: 'app-setting-validation-scoring',
    templateUrl: './setting-validation-scoring.component.html',
    styleUrls: ['./setting-validation-scoring.component.scss']
})
export class SettingValidationScoringComponent implements OnInit {
    forms: any[] = null;
    saving = false;
    constructor(private errorMessage: ErrorMessageService, private scoringService: ScoringService) {}
    ngOnInit() {
        this.scoringService.getConfigValidatorList().subscribe(list => {
            if (list.length > 0) {
                this.forms = list;
            }
        });
    }

    save() {
        this.saving = true;

        const data = { values: this.forms };

        this.scoringService.updateConfigValidator(data).subscribe(
            resp => {
                if (resp) {
                    this.errorMessage.showSuccess('El validadors se ha actualizado');
                } else {
                    this.errorMessage.showError('No se puedo actualizar el validador');
                }
            },
            error => {
                this.errorMessage.showError(error.error.error.show_message);
            }
        );

        this.saving = false;
    }
}
