import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PaymentTreeService {
  private active = new BehaviorSubject<number>(0);
  active$ = this.active.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlArray = event.url.split('/');
        const lastParam = Number(urlArray[urlArray.length - 1]);

        !isNaN(lastParam) ? this.active.next(lastParam) : this.active.next(0);
      }
    });
  }
}
