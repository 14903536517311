import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { forkJoin, SubscriptionLike as ISubscription } from 'rxjs';
import { KpiService } from '../../../services/kpi.service';

declare const moment: any;

const FRONTEND = 1;
const BACKOFFICE = 2;
const PAYMENT_SERVICE = 3;
const LAUNCHER = 4;
const TPV = 'tpv';
const BANK_TRANSFER = 'bank_transfer';
const MOMENT_FORMAT = 'YYYY-MM-DD';
@Component({
    selector: 'app-kpis-charges',
    templateUrl: './kpis-charges.component.html',
    styleUrls: ['./kpis-charges.component.scss'],
})
export class KpisChargesComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('paymentServiceChart')
    paymentServiceChart: ElementRef;
    @ViewChild('backOfficeChart')
    backOfficeChart: ElementRef;
    @ViewChild('frontEndChart') frontEndChart: ElementRef;
    @ViewChild('launcherChart') launcherChart: ElementRef;
    @ViewChild('tpvChart') tpvChart: ElementRef;
    @ViewChild('bankTransferChart')
    bankTransferChart: ElementRef;

    querySubscriber: ISubscription;
    forkJoinSubscription: ISubscription;

    startData: any = {};
    filters;
    clearFilters: any = {};

    loading = false;

    calendarDates = {
        startDate: moment().startOf('year').format(MOMENT_FORMAT),
        endDate: moment().format(MOMENT_FORMAT),
    };

    minDate = '2015-01-01';
    maxDate = moment().format(MOMENT_FORMAT);

    total: any = {};

    chartInstances = {
        paymentService: null,
        backoffice: null,
        frontend: null,
        launcher: null,
        tpv: null,
        bankTransfer: null,
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private readonly kpiService: KpiService
    ) {}

    ngOnInit(): void {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    date_start: this.calendarDates.startDate,
                    date_end: this.calendarDates.endDate,
                    time_unit: 'month',
                };

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getData();
            }
        );
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.paymentServiceChart?.nativeElement) {
                this.createChart(
                    this.paymentServiceChart.nativeElement,
                    'paymentService'
                );
            }
            if (this.backOfficeChart?.nativeElement) {
                this.createChart(
                    this.backOfficeChart.nativeElement,
                    'backoffice'
                );
            }
            if (this.frontEndChart?.nativeElement) {
                this.createChart(
                    this.frontEndChart.nativeElement,
                    'frontend',
                    'bar'
                );
            }
            if (this.launcherChart?.nativeElement) {
                this.createChart(
                    this.launcherChart.nativeElement,
                    'launcher',
                    'bar'
                );
            }
            if (this.tpvChart?.nativeElement) {
                this.createChart(this.tpvChart.nativeElement, 'tpv');
            }
            if (this.bankTransferChart?.nativeElement) {
                this.createChart(
                    this.bankTransferChart.nativeElement,
                    'bankTransfer',
                    'bar'
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.querySubscriber.unsubscribe();
        this.forkJoinSubscription.unsubscribe();
    }

    getData() {
        this.loading = true;
        const total = this.kpiService.getChargesTotal(this.clearFilters);

        const frontend = this.getGraphData(FRONTEND);
        const backoffice = this.getGraphData(BACKOFFICE);
        const paymentService = this.getGraphData(PAYMENT_SERVICE);
        const launcher = this.getGraphData(LAUNCHER);
        const tpv = this.getGraphData(TPV, true);
        const bankTransfer = this.getGraphData(BANK_TRANSFER, true);

        this.forkJoinSubscription = forkJoin([
            total,
            frontend,
            backoffice,
            paymentService,
            launcher,
            tpv,
            bankTransfer,
        ]).subscribe(
            ([
                totalResponse,
                frontendResponce,
                backofficeResponse,
                paymentServiceResponce,
                launcherResponce,
                tpvResponce,
                bankTransferResponce,
            ]: any) => {
                if (totalResponse) {
                    this.total = totalResponse;
                }

                if (frontendResponce) {
                    this.updateChart(frontendResponce, 'frontend');
                }

                if (backofficeResponse) {
                    this.updateChart(backofficeResponse, 'backoffice');
                }

                if (paymentServiceResponce) {
                    this.updateChart(paymentServiceResponce, 'paymentService');
                }

                if (launcherResponce) {
                    this.updateChart(launcherResponce, 'launcher');
                }

                if (tpvResponce) {
                    this.updateChart(tpvResponce, 'tpv');
                }

                if (bankTransferResponce) {
                    this.updateChart(bankTransferResponce, 'bankTransfer');
                }

                this.loading = false;
            }
        );
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.clearFilters = { ...clearFilters };
    }

    getGraphData(source, group = false) {
        const groupBy = !group ? 'source_type_ids[]' : 'source_type_group';

        const query = { ...this.clearFilters, [groupBy]: source };

        const type =
            this.filters.time_unit === 'week'
                ? 'isoWeek'
                : this.filters.time_unit;

        return this.kpiService.getChargesEvolution({
            ...query,
            date_start: moment(query?.date_start)
                .startOf(type)
                .format('YYYY-MM-DD'),
            date_end: moment(query?.date_end).endOf(type).format('YYYY-MM-DD'),
        });
    }

    generateDates(type: 'isoWeek' | 'month' = 'isoWeek') {
        const subtract = type === 'isoWeek' ? 'week' : type;

        const length =
            moment(this.filters?.date_end)
                .endOf(type)
                .diff(
                    moment(this.filters?.date_start).startOf(type),
                    subtract
                ) + 1;

        const data: any[] = [
            {
                first_day_time_unit: moment(this.filters?.date_end)
                    .startOf(type)
                    .format('YYYY-MM-DD'),
                last_day_time_unit: moment(this.filters?.date_end)
                    .endOf(type)
                    .format('YYYY-MM-DD'),
            },
        ];

        for (let i = 1; i < length; i++) {
            data.push({
                first_day_time_unit: moment(this.filters?.date_end)
                    .subtract(i, subtract)
                    .startOf(type)
                    .format('YYYY-MM-DD'),
                last_day_time_unit: moment(this.filters?.date_end)
                    .subtract(i, subtract)
                    .endOf(type)
                    .format('YYYY-MM-DD'),
            });
        }

        data.reverse();

        return data;
    }

    formatData(data: any[], replacement: any = { total_amount: 0 }) {
        const array = this.generateDates(
            this.filters.time_unit === 'week'
                ? 'isoWeek'
                : this.filters.time_unit
        ).map((date) => {
            const exist = data.find(
                (item) => item.first_day_time_unit === date.first_day_time_unit
            );

            return exist ? { ...exist, ...date } : { ...date, ...replacement };
        });

        return array;
    }

    createData(dataArray: any[]) {
        const data = dataArray.map(({ total_amount }) => total_amount);

        const labels = dataArray.map((item) =>
            this.filters.time_unit === 'week'
                ? `${moment(item?.first_day_time_unit).format(
                      'DD/MM/YYYY'
                  )} - ${moment(item?.last_day_time_unit).format('DD/MM/YYYY')}`
                : moment(item?.first_day_time_unit).format('MMM YYYY')
        );

        return { data, labels };
    }

    updateChart(responce, instanceName: string) {
        if (this.chartInstances[instanceName]) {
            const { data, labels } = this.createData(this.formatData(responce));

            this.chartInstances[instanceName].data.labels = labels;
            this.chartInstances[instanceName].data.datasets[0].data = data;
            this.chartInstances[instanceName].options.scales.xAxes[0].display =
                this.filters.time_unit === 'month';
            this.chartInstances[instanceName].update();
        }
    }

    createChart(ctx, instanceName, type = 'line') {
        if (ctx) {
            this.chartInstances[instanceName] = new Chart(ctx, {
                type: type,
                data: {
                    labels: [],
                    datasets: [
                        {
                            borderColor: '#69356e',
                            fill: false,
                            data: [],
                            backgroundColor: 'rgba(105,53,110,0.5)',
                        },
                    ],
                },
                options: {
                    responsive: true,
                    legend: {
                        display: false,
                    },
                },
            });
        }
    }
}
