<button
  type="button"
  class="btn btn-sm"
  [ngClass]="{
    'btn-outline-success': enabled,
    'btn-outline-warning': !enabled
  }"
  [ngbTooltip]="enabled ? 'Disable' : 'Enable'"
  (click)="onClick()"
  [disabled]="isSending"
>
  {{ enabled ? 'Yes' : 'No' }}
</button>
