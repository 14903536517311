<!--
<div class="tab">
  <button class="tablinks tablinks-selected" [routerLink]="'/backoffice/settings/product_overview/product_list'">Product List</button>
  <button class="tablinks" [routerLink]="'/backoffice/settings/product_overview/create_product'">Create</button>
</div>
!-->

            <!-- horizontal menu -->
            <section id='submenu'>
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item">
                      <a class="nav-link active" (click)="goto('product_list', $event)" id="pills-leadinfo-tab" data-toggle="pill" role="tab" aria-controls="pills-leadinfo" aria-selected="true">Product List</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" (click)="goto('create_product', $event)" id="pills-userinfo-tab" data-toggle="pill"  role="tab" aria-controls="pills-userinfo" aria-selected="false">Create Product</a>
                  </li>
              </ul>
          </section>

<router-outlet></router-outlet>