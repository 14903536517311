import { Injectable } from '@angular/core';

@Injectable()
export class ShareService {

  private paymentAmount: number;
  private bankAccount1: string;
  private bankAccount2: string;
  private sourcePayment: string;
  private paymentMethod: string;
  private cardId: number;

  constructor() { }

  getPaymentAmount(){
    return this.paymentAmount;
  }

  setPaymentAmount(amount: number){
    this.paymentAmount = amount;
  }

  getPaymentMethod(){
    return this.paymentMethod;
  }

  setPaymentMethod(method: string){
    this.paymentMethod = method;
  }

  getBankAccount1(){
    return this.bankAccount1;
  }

  setBankAccount1(bankacc: string){
    this.bankAccount1 = bankacc;
  }

  getBankAccount2(){
    return this.bankAccount2;
  }

  setBankAccount2(bankacc: string){
    this.bankAccount2 = bankacc;
  }

  setSourcePayment(source: string){
    this.sourcePayment = source;
  }

  getSourcePayment(){
    return this.sourcePayment;
  }

  setCardId(card: string){
    this.cardId = Number (card);
  }

  getCardId(){
    return this.cardId;
  }

}
