<div *appHasPermissions="permissionsArray">
    <div style="min-width: 1135px; margin-left: 10px; margin-right: 10px;">
        <strong>
            <h3 class="miLineaFontColor">{{ title }}</h3>
        </strong>
        <app-pager
            [perPage]="tableData?.per_page"
            [currentPage]="tableData?.current_page"
            [from]="tableData?.from"
            [to]="tableData?.to"
            [total]="tableData?.total"
            (changePage)="getDataOnPageChange($event)"
        >
        </app-pager>
        <div class="table-responsive table-hover">
            <table class="table" width="100%">
                <!-- row -->
                <thead>
                    <tr>
                        <th>Lead</th>
                        <th>Created</th>
                        <th>DNI/NIE</th>
                        <th>Customer Name</th>
                        <th>Product</th>
                        <th>Amount</th>
                        <th *ngIf="selectedPageName === 'all'">Status</th>
                        <th>Reason</th>
                        <th>Source</th>
                        <th>Medium</th>
                        <th>Campaign</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                placeholder="Code"
                                name="code"
                                [(ngModel)]="filters.code"
                            />
                        </td>
                        <td>
                            <app-daterange-filter
                                fieldName="created_at"
                                [start]="filters.created_at_start"
                                [end]="filters.created_at_end"
                                (changeDate)="onChangeDate($event)"
                                withStartDate="true"
                            ></app-daterange-filter>
                            <!-- <input type="text" (keyup)="getParamsFilter($event)" (keyup)="getParamsFilter($event)" (keyup.enter)="makeFilter()" placeholder="Code" name="created_at"> -->
                        </td>
                        <td>
                            <input
                                type="text"
                                (keyup)="getParamsFilter($event)"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                placeholder="Code"
                                name="personalid"
                                [(ngModel)]="filters.personalid"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                placeholder="Full name"
                                name="full_name"
                                [(ngModel)]="filters.full_name"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Product"
                                name="weeks"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                [(ngModel)]="filters.weeks"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Amount"
                                name="amount_requested"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                [(ngModel)]="filters.amount_requested"
                            />
                        </td>
                        <td *ngIf="selectedPageName === 'all'">
                            <select (change)="getDataOnFilterSubmit()" name="status_id" [(ngModel)]="filters.status_id">
                                <option value="">Select status</option>
                                <option
                                    *ngFor="let key of lead_status_filter | keys"
                                    value="{{ lead_status_filter[key].id }}"
                                    >{{ lead_status_filter[key].name }}</option
                                >
                            </select>
                        </td>
                        <td>
                            <select
                                (change)="getDataOnFilterSubmit()"
                                name="status_reason_id"
                                [(ngModel)]="filters.status_reason_id"
                            >
                                <option value="">Select reason</option>
                                <option
                                    *ngFor="let key of lead_rejected_reason_filter | keys"
                                    value="{{ lead_rejected_reason_filter[key].id }}"
                                    >{{ lead_rejected_reason_filter[key].name }}</option
                                >
                            </select>
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Source"
                                name="source"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                [(ngModel)]="filters.source"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Medium"
                                name="medium"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                [(ngModel)]="filters.medium"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Campaign"
                                name="campaign"
                                (keyup.enter)="getDataOnFilterSubmit()"
                                [(ngModel)]="filters.campaign"
                            />
                        </td>
                    </tr>

                    <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                        <tr *ngFor="let item of tableData?.data">
                            <td>
                                <a class="miLineaFontColor" (click)="viewLead(item.id)"> {{ item?.code }} </a>
                            </td>
                            <td>{{ item?.created_at }}</td>
                            <td>{{ item?.personalid }}</td>
                            <td>
                                {{ item?.name + ' ' + item?.first_surname + ' ' }}
                                {{ item?.second_surname ? item?.second_surname : '' }}
                            </td>
                            <td>{{ item?.product }}</td>
                            <td>{{ item?.amount_requested | currency: 'EUR' }}</td>
                            <td *ngIf="selectedPageName === 'all'">{{ item?.status }}</td>
                            <td>{{ item?.rejected_reason ? item?.rejected_reason : '-' }}</td>
                            <td>{{ item?.source }}</td>
                            <td>{{ item?.medium }}</td>
                            <td>{{ item?.campaign }}</td>
                        </tr>
                    </ng-template>

                    <ng-template [ngIf]="!tableData?.data.length && !loading">
                        <tr class="without_hover">
                            <td colspan="3">
                                <p class="py-5 mb-0">
                                    Nothing Found
                                </p>
                            </td>
                        </tr>
                    </ng-template>
                    <tr *ngIf="loading" class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">
                                <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
