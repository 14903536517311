import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';
import { ContactInfoService } from '../../../services/contact-info.service';

@Component({
    selector: 'app-lead-info-contact-info',
    templateUrl: './lead-info-contact-info.component.html',
    styleUrls: ['./lead-info-contact-info.component.css']
})
export class LeadInfoContactInfoComponent implements OnInit, OnChanges {
    @Input() lead: any = null;
    @Input() editInfo: any = null;
    @Input() loan_id: any = null;
    phone_name: string = '';
    phone_number = null;
    work_phone_one = null;
    work_phone_two = null;
    additional_phone_one = null;
    additional_phone_two = null;
    mobile = null;
    otherMobile = null;
    email = null;
    saving = false;

    data: any = null;

    contactModel: any = null;
    isSaving: boolean = false;

    constructor(
        private modalService: NgbModal,
        private formatPhoneService: FormatPhoneNumberService,
        private ContactInfoService: ContactInfoService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.lead) {
            this.data = this.setData(this.lead);
        }
    }
    open(content, phone_name) {
        this.phone_name = phone_name;
        this.modalService.open(content, { size: 'lg' });
    }
    openEdit(edit, data) {
        this.contactModel = this.setData(data);
        this.modalService.open(edit, { size: 'lg' });
    }
    saveNumberPhone() {
        let phone = null;
        if (this.phone_name === 'work_phone_one') {
            this.data.work_phone_one = this.phone_number;
            phone = { work_phone_one: this.phone_number };
        }
        if (this.phone_name === 'work_phone_two') {
            this.data.work_phone_two = this.phone_number;
            phone = { work_phone_two: this.phone_number };
        }
        if (this.phone_name === 'additional_phone_one') {
            this.data.additional_phone_one = this.phone_number;
            phone = { additional_phone_one: this.phone_number };
        }
        if (this.phone_name === 'additional_phone_two') {
            this.data.additional_phone_two = this.phone_number;
            phone = { additional_phone_two: this.phone_number };
        }

        this.saveContactInfo(phone, true);
    }

    setData(lead) {
        let data = {
            mobile_phone: this.formatPhoneService.formatWithOutSpaces(lead.mobile_phone),
            other_phone: this.formatPhoneService.formatWithOutSpaces(lead.other_phone),
            work_phone_one: this.formatPhoneService.formatWithOutSpaces(lead.work_phone_one),
            work_phone_two: this.formatPhoneService.formatWithOutSpaces(lead.work_phone_two),
            additional_phone_one: this.formatPhoneService.formatWithOutSpaces(lead.additional_phone_one),
            additional_phone_two: this.formatPhoneService.formatWithOutSpaces(lead.additional_phone_two),
            email: lead.email,
            verified_mobile_phone: lead.verified_mobile_phone,
            updated_at: lead.updated_at
        };

        return data;
    }

    formatPhone(phone) {
        if (phone) {
            return this.formatPhoneService.format(phone);
        }
    }

    saveContactInfo(value, onePhone) {
        if (this.loan_id) {
            this.isSaving = true;
            value.loan_id = this.loan_id;
            value.added_by = 1;

            this.ContactInfoService.save(value).subscribe(
                data => {
                    if (data) {
                        if (!onePhone) {
                            this.data = this.setData(value);
                        }

                        setTimeout(
                            function(self) {
                                self.errorMessageService.showSuccess('Info updated successful!');
                                self.isSaving = false;
                            },
                            600,
                            this
                        );
                    }
                },
                error => {
                    setTimeout(
                        function(self) {
                            self.isSaving = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(error.error.error.show_message);
                }
            );
        }
    }
}
