<div class="table-responsive table-hover">
  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Subject</th>
        <th>Description</th>
        <th>Template</th>
        <th>Published</th>
        <th>Published At</th>
        <th>Active</th>
      </tr>
    </thead>
    <tbody>
      <ng-template [ngIf]="tableData?.length > 0 && !loading">
        <tr *ngFor="let item of tableData">
          <td>
            <app-mail-message-types-create
              [item]="item"
            ></app-mail-message-types-create>
          </td>
          <td>{{ item?.subject }}</td>
          <td>{{ item?.description }}</td>
          <td>
            {{ item?.template?.name }}
            <app-mail-templates-preview
              class="ml-1"
              *ngIf="item?.template_id"
              [cssClass]="'btn-sm btn-outline-primary'"
              [id]="item?.template_id"
            ></app-mail-templates-preview>
          </td>
          <td>
            <span *ngIf="item?.published" class="badge badge-success">Yes</span>
            <app-mail-templates-publishing
              *ngIf="!item?.published"
              [id]="item?.id"
              [type]="type"
              (published)="onPublish($event)"
            ></app-mail-templates-publishing>
          </td>
          <td>
            {{ formatDate(item?.published_at) | date: 'dd/MM/yyyy HH:mm' }}
          </td>
          <td>
            <app-mail-templates-activation
              [id]="item?.id"
              [enabled]="item?.enabled"
              [type]="type"
              (activated)="onPublish($event)"
            ></app-mail-templates-activation>
          </td>
        </tr>
      </ng-template>
      <ng-template [ngIf]="!tableData?.length && !loading">
        <tr class="without_hover">
          <td colspan="3">
            <p class="py-5 mb-0">Nothing Found</p>
          </td>
        </tr>
      </ng-template>
      <tr *ngIf="loading" class="without_hover">
        <td colspan="3">
          <p class="py-5 mb-0">
            <img
              width="30px"
              height="30px"
              src="assets/img/backoffice/loading.gif"
            />
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
