import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DocsService {
    constructor(private http: HttpClient) {}

    getContractByLeadId(lead_id): Observable<any> {
        return this.http.get(environment.api_url + `/lead/${lead_id}/contract/show`);
    }

    getEuformByLeadId(lead_id): Observable<any> {
        return this.http.get(environment.api_url + `/lead/${lead_id}/eu_form/show`);
    }

    getLeadDocs(lead_id): Observable<any> {
        return this.http.get(environment.api_url + `/lead/${lead_id}/documents/show`);
    }

    view(id) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf'
        });

        return this.http.get(environment.api_url + `/lead/${id}/contract/view`, {
            headers: headers,
            responseType: 'blob'
        });
    }

    viewEuform(id) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf'
        });

        return this.http.get(environment.api_url + `/lead/${id}/eu_form/view`, {
            headers: headers,
            responseType: 'blob'
        });
    }

    getClientDocumentByCreditLine(credit_line_id) {
        return this.http.get(environment.api_url + `/loan/${credit_line_id}/documents/show`);
    }
    viewClientDocument(client_document_name, client_document_id) {
        /*
    let headers = new HttpHeaders({
      'Content-Type':  'application/json'
    });*/
        return this.http.get(
            environment.api_url +
                `/loan/${client_document_id}/document/${client_document_name}/view` /*, {headers: headers, responseType: 'blob'}*/
        );
    }
    viewClientDocumentPersonalidFrontFace(client_document_id) {
        //let headers = new HttpHeaders({
        //  'Content-Type':  'application/json'
        //});
        return this.http.get(
            environment.api_url +
                `/client_document/${client_document_id}/personalid_front_face/view` /*, {headers: headers, responseType: 'blob'}*/
        );
    }
    viewClientDocumentPersonalidBackFace(client_document_id) {
        /*
    let headers = new HttpHeaders({
      'Content-Type':  'application/json'
    });*/
        return this.http.get(
            environment.api_url +
                `/client_document/${client_document_id}/personalid_back_face/view` /*, {headers: headers, responseType: 'blob'}*/
        );
    }
    viewClientDocumentCreditCardSelfie(client_document_id) {
        /*
    let headers = new HttpHeaders({
      'Content-Type':  'application/json'
    });*/
        return this.http.get(
            environment.api_url +
                `/client_document/${client_document_id}/credit_card_selfie/view` /*, {headers: headers, responseType: 'blob'}*/
        );
    }
    viewClientDocumentDocumentConfirmingAddress(client_document_id) {
        /*
    let headers = new HttpHeaders({
      'Content-Type':  'application/json'
    });*/
        return this.http.get(
            environment.api_url +
                `/client_document/${client_document_id}/document_confirming_address/view` /*, {headers: headers, responseType: 'blob'}*/
        );
    }
    updateClientDocument(input, name) {
        return this.http.post(environment.api_url + `/loan/document/${name}/upload`, input);
    }
    updateClientDocumentPersonalidFrontFace(client_document_id, picture) {
        return this.http.post(
            environment.api_url + `/client_document/personalid_front_face/${client_document_id}/update`,
            { personalid_front_face: picture }
        );
    }
    updateClientDocumentPersonalidBackFace(client_document_id, picture) {
        return this.http.post(
            environment.api_url + `/client_document/personalid_back_face/${client_document_id}/update`,
            { personalid_back_face: picture }
        );
    }
    updateClientDocumentCreditCardSelfie(client_document_id, picture) {
        return this.http.post(
            environment.api_url + `/client_document/credit_card_selfie/${client_document_id}/update`,
            { credit_card_selfie: picture }
        );
    }
    updateClientDocumentDocumentConfirmingAddress(client_document_id, picture) {
        return this.http.post(
            environment.api_url + `/client_document/document_confirming_address/${client_document_id}/update`,
            { document_confirming_address: picture }
        );
    }
}
