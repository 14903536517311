import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-lead-info-bank-aggregation-detail',
    templateUrl: './lead-info-bank-aggregation-detail.component.html',
    styleUrls: ['./lead-info-bank-aggregation-detail.component.css'],
})
export class LeadInfoBankAggregationDetailComponent implements OnInit {
    @Input() bankAggregation;
    constructor() {}

    ngOnInit() {}
}
