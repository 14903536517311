<div *appHasPermissions="['loan-checking']">
    <div>
        <h2>{{ title }}</h2>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Loan</th>
                    <th>Date Last Event</th>
                    <th>Created</th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                    <th>Product</th>
                    <th>Amount</th>
                    <th>Last Event</th>
                    <th>Who</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Code"
                            name="code_str"
                            [(ngModel)]="filters.code_str"
                        />
                    </td>
                    <td>
                        <app-daterange-filter
                            fieldName="updated_at"
                            [start]="filters.updated_at_start"
                            [end]="filters.updated_at_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <td>
                        <app-daterange-filter
                            fieldName="created_at"
                            [start]="filters.created_at_start"
                            [end]="filters.created_at_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="ID"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Customer Name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="checked_by"
                            [(ngModel)]="filters.checked_by"
                        >
                            <option value>Added by</option>
                            <option
                                *ngFor="let user of users?.actived"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                            <option *ngIf="users?.blocked.length > 0" disabled>
                                ──────────
                            </option>
                            <option
                                *ngFor="let user of users?.blocked"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                        </select>
                    </td>
                    <td></td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr
                        *ngFor="let item of tableData?.data"
                        [ngClass]="item?.is_customer ? 'table-success' : ''"
                    >
                        <td (click)="goto(item?.lead_id)">
                            {{ item?.code_str }}
                        </td>
                        <td>{{ item?.updated_at }}</td>
                        <td>{{ item?.created_at }}</td>
                        <td>{{ item?.personalid }}</td>
                        <td>{{ item?.full_name }}</td>
                        <td>{{ item?.product_weeks || '-' }}</td>
                        <td>{{ item?.amount | currency: 'EUR' }}</td>
                        <td>Last event</td>
                        <td>{{ item?.checked_by }}</td>
                        <td>
                            <i
                                class="fa fa-cog"
                                *ngIf="!item?.actived_by && !checking"
                                (click)="check(item.lead_id, item.id)"
                            ></i>
                            {{ item?.credit_line_check?.added_by?.name }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
