import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { LegalMonitoringService } from '../../../../services/legal-monitoring.service';

@Component({
    selector: 'app-monitoring-close',
    templateUrl: './monitoring-close.component.html',
    styleUrls: ['./monitoring-close.component.scss'],
})
export class MonitoringCloseComponent implements OnInit {
    @Input() id: number;
    @Input() disabled: boolean;

    @Output() closed = new EventEmitter<boolean>();

    form: FormGroup;
    isSending = false;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private legalMonitoringService: LegalMonitoringService,
        private errorService: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    open(content) {
        this.modalService.open(content);

        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            closed_at: ['', Validators.required],
            comment: [''],
        });
    }

    onSubmit() {
        this.isSending = true;

        this.form.disable();
        const params = this.form.getRawValue();

        if (confirm('¿Estás seguro(a) de realizar esta operación?')) {
            this.legalMonitoringService
                .closeProceeding(this.id, params)
                .then(() => {
                    this.closed.emit(true);
                    this.errorService.showSuccess('Cerrado con Éxito.');
                    this.modalService.dismissAll();
                    this.enableForm();
                })
                .catch(this.showErrors);
        } else {
            this.enableForm();
        }
    }

    private enableForm() {
        this.isSending = false;
        this.form.enable();
    }

    private showErrors = (err) => {
        const { show_message, details } = err?.error?.error;
        const message =
            details && Object.keys(details).length
                ? Object.keys(details)
                      .map((key) => details[key].join('<br />'))
                      .join('<br />')
                : '';

        this.errorService.showError(message, show_message || 'Service Error', {
            timeOut: 6000,
            enableHtml: true,
        });
        this.isSending = false;
        this.form.enable();
    };
}
