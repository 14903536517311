<div *appHasPermissions="['marketing']">
    <!-- creditline tab content -->
    <creditline *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-6 panel-main-action">
                    <div class="row">
                        <div *appHasPermissions="['marketing-loan-all']"
                            class="col-lg-2 col-md-3 col-sm-6 panel-main-separator">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description"
                                            (click)="goto('/backoffice/marketing/loan/all')">
                                            <img class="align-middle" width="90" height="90"
                                                src="assets/img/backoffice/loans.svg" alt="Generic placeholder image" />
                                            <div class="icon__text text-primary">Loans</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['marketing-underwriting-all']" class="col-lg-2 col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description"
                                            (click)="goto('/backoffice/marketing/leads/all')">
                                            <img class="align-middle" width="90" height="90"
                                                src="assets/img/backoffice/underwriting/all_leads.svg"
                                                alt="Generic placeholder image" />
                                            <div class="icon__text text-primary">All leads</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['marketing']" class="col-lg-2 col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description"
                                            (click)="goto('/backoffice/marketing/affiliates_summary')">
                                            <img class="align-middle" width="90" height="90"
                                                src="assets/img/backoffice/marketing/affiliates_summary.svg"
                                                alt="Generic placeholder image" />
                                            <div class="icon__text text-primary">Affiliates Summary</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['marketing']" class="col-lg-2 col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description"
                                            (click)="goto('/backoffice/marketing/affiliates_manager')">
                                            <img class="align-middle" width="90" height="90"
                                                src="assets/img/backoffice/marketing/affiliates_manager.svg"
                                                alt="Generic placeholder image" />
                                            <div class="icon__text text-primary">Affiliates Manager</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </creditline>
    <!-- creditline tab content end -->

    <router-outlet></router-outlet>
</div>
