import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OutputData } from '@editorjs/editorjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike } from 'rxjs';
import {
    TEMPLATE_ACTIVATION_ENUM,
    TEMPLATE_CONTENT_TYPE_ENUM,
} from '../../../../../shared/enums/template-activation.enum';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { EmailService } from '../../../../services/email.service';
import { EMAIL_TEMPLATE } from '../email-editor-blocks/email-tempate';
@Component({
    selector: 'app-create-mail-template',
    templateUrl: './create-mail-template.component.html',
    styleUrls: ['./create-mail-template.component.scss'],
})
export class CreateMailTemplateComponent implements OnInit, OnDestroy {
    id;
    isSending = false;
    isPublished = false;
    addedItem;

    form = this.fb.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        enabled: [false],
        content: ['', Validators.required],
        content_json: ['', Validators.required],
        content_type: [true],
    });

    editorDataSubsriber: SubscriptionLike;
    dataSubscriber: SubscriptionLike;
    variablesSubscriber: SubscriptionLike;

    sanitazedTemplate;
    startTemplate;

    ready = false;

    templateVariables;
    templatePreview;

    type = TEMPLATE_ACTIVATION_ENUM.TEMPLATE;

    constructor(
        private emailService: EmailService,
        private router: Router,
        private fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private activeRoute: ActivatedRoute,
        private errorMessage: ErrorMessageService
    ) {}

    ngOnInit(): void {
        const { id } = this.activeRoute.snapshot.params;

        if (id) {
            this.dataSubscriber = this.emailService.getTemplate(id).subscribe(
                (response) => {
                    if (response) {
                        this.id = id;
                        this.setStartData(response);
                        this.getVariables();
                        this.getEditorData();
                    } else {
                        this.router.navigate([
                            '/backoffice/settings/mail-templates/',
                        ]);
                    }
                },
                (error) => {
                    this.router.navigate([
                        '/backoffice/settings/mail-templates/',
                    ]);
                }
            );
        } else {
            this.getVariables();
            this.getEditorData();
        }
    }

    ngOnDestroy(): void {
        if (this.editorDataSubsriber) {
            this.editorDataSubsriber.unsubscribe();
        }

        if (this.dataSubscriber) {
            this.dataSubscriber.unsubscribe();
        }

        if (this.variablesSubscriber) {
            this.variablesSubscriber.unsubscribe();
        }

        this.emailService.saveEditorData(null);
    }

    onEditorChange(data: OutputData) {
        this.emailService.saveEditorData(data);
    }

    getEditorData() {
        this.editorDataSubsriber = this.emailService
            .getEditorData()
            .subscribe((response) => {
                if (response) {
                    const { blocks } = response;
                    this.form.controls.content_json.setValue(
                        blocks?.length > 0 ? response : ''
                    );
                    this.form.controls.content.setValue(
                        blocks?.length > 0
                            ? this.emailService.getParsedHtml(response)
                            : ''
                    );

                    this.templatePreview =
                        blocks?.length > 0
                            ? this.emailService.getParsedHtml(response, true)
                            : '';
                }
            });
    }

    getVariables() {
        this.variablesSubscriber = this.emailService
            .getTemplateVariables({})
            .subscribe((response) => {
                this.templateVariables = response || [];
                this.ready = true;
            });
    }

    setStartData(data) {
        const {
            name,
            description,
            enabled,
            content,
            content_json,
            published,
            content_type,
        } = data;

        const content_obj = JSON.parse(content_json);

        this.form.setValue({
            name,
            description,
            enabled,
            content,
            content_type: content_type === TEMPLATE_CONTENT_TYPE_ENUM.HTML,
            content_json: content_obj,
        });

        if (published) {
            this.form.disable();
        }

        this.startTemplate = content_obj;
        this.isPublished = published;

        if (this.startTemplate) {
            this.templatePreview = this.emailService.getParsedHtml(
                this.startTemplate,
                true
            );
        } else {
            this.templatePreview = content;
        }
    }

    wrapEmail(content) {
        const replaced = EMAIL_TEMPLATE.replace('{{content}}', content);

        return replaced;
    }

    open(content) {
        this.sanitazedTemplate = this.sanitizer.bypassSecurityTrustHtml(
            this.templatePreview
        );

        this.modalService.open(content, { size: 'lg' });
    }

    openVariables(content) {
        this.modalService.open(content, { size: 'xl' });
    }

    onSubmit() {
        this.isSending = true;
        this.form.disable();

        const data = this.form.getRawValue();
        const { content_json, content, content_type } = data;
        data.content_json = JSON.stringify(content_json);
        data.content = content_type ? this.wrapEmail(content) : content;
        data.content_type = content_type
            ? TEMPLATE_CONTENT_TYPE_ENUM.HTML
            : TEMPLATE_CONTENT_TYPE_ENUM.PLAIN_TEXT;

        if (this.id) {
            this.emailService
                .editTemplate(this.id, data)
                .toPromise()
                .then((response) => {
                    this.errorMessage.showSuccess('', 'Saved Successfully');
                    this.isSending = false;
                    this.form.enable();
                })
                .catch((error) => {
                    this.errorMessage.showError(
                        error?.error?.error?.details,
                        'Save Failed'
                    );
                    this.isSending = false;
                    this.form.enable();
                });
        } else {
            this.emailService
                .createTemplate(data)
                .toPromise()
                .then((response) => {
                    this.errorMessage.showSuccess('', 'Saved Successfully');
                    this.router.navigate([
                        '/backoffice/settings/mail-templates/' + response.id,
                    ]);
                    this.isSending = false;
                })
                .catch((error) => {
                    this.errorMessage.showError(
                        error?.error?.error?.details,
                        'Save Failed'
                    );
                    this.isSending = false;
                    this.form.enable();
                });
        }
    }

    onPublish(e) {
        if (e) {
            this.form.disable();
            this.isPublished = true;
            this.form.controls.enabled.setValue(true);
        }
    }

    onSaved(e) {
        if (e) {
            this.addedItem = e;
        }
    }

    onContentInput() {
        this.form.controls.content_json.setValue({
            time: Date.now(),
            blocks: [
                {
                    type: 'paragraph',
                    data: {
                        text: this.form.controls.content.value,
                    },
                },
            ],
        });

        this.templatePreview = this.emailService.getParsedHtml(
            this.form.controls.content_json.value,
            true
        );

        this.startTemplate = this.form.controls.content_json.value;
    }
}
