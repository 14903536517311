<button
  *ngIf="!variable"
  type="button"
  class="btn btn-sm btn-primary"
  (click)="open(content)"
>
  Create New
</button>

<a *ngIf="variable" href="#0" (click)="edit($event, variable, content)">{{
  variable?.name || 'Noname'
}}</a>

<ng-template #content let-modal>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Template Variable</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label class="mb-0" for="name"
          >Name<sup class="text-danger">*</sup></label
        >
        <input
          id="name"
          type="text"
          name="name"
          formControlName="name"
          class="form-control"
          placeholder="Name"
          required
        />
      </div>
      <div class="form-group">
        <label class="mb-0" for="name_template"
          >Template Name<sup class="text-danger">*</sup></label
        >
        <input
          id="name_template"
          type="text"
          name="name_template"
          formControlName="name_template"
          class="form-control"
          placeholder="Template Name"
          required
        />
        <small class="text-muted">Example: user_name</small>
      </div>
      <div class="form-group">
        <label class="mb-0" for="description"
          >Description<sup class="text-danger">*</sup></label
        >
        <input
          id="description"
          type="text"
          name="description"
          formControlName="description"
          class="form-control"
          placeholder="Description"
          required
        />
      </div>
      <div class="form-group">
        <label class="mb-0" for="value"
          >Value<sup class="text-danger">*</sup></label
        >
        <input
          type="text"
          name="value"
          formControlName="value"
          class="form-control"
          placeholder="Value"
        />
      </div>
      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            formControlName="enabled"
            id="customCheckVarible"
          />
          <label
            class="mb-0"
            class="custom-control-label"
            for="customCheckVarible"
            >Enabled</label
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="isSending || form.invalid"
      >
        {{ isSending ? 'Saving...' : 'Save' }}
      </button>
    </div>
  </form>
</ng-template>
