import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { EmailService } from '../../../../services/email.service';
import { TEMPLATE_ACTIVATION_ENUM } from '../../../../../shared/enums/template-activation.enum';

@Component({
  selector: 'app-mail-templates',
  templateUrl: './mail-templates.component.html',
  styleUrls: ['./mail-templates.component.scss'],
})
export class MailTemplatesComponent implements OnInit, OnDestroy {
  loading = true;
  tableData;

  startData = {};

  filters: any = {};

  queryString: string = '';

  querySubscriber: ISubscription;
  dataSubscriber: ISubscription;

  selectedTemplate: SafeHtml;
  selectedTemplateId: number;

  type = TEMPLATE_ACTIVATION_ENUM.TEMPLATE;

  constructor(
    private emailService: EmailService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // this.emailService.createTemplate().subscribe((response) => {
    //   console.log(response);
    // });
    this.querySubscriber = this.activatedRoute.queryParams.subscribe(
      (queryParams) => {
        this.startData = {
          code: '',
          created_at_start: '',
          created_at_end: '',
          rejected_date_start: '',
          rejected_date_end: '',
          credit_line_status_id: '',
          personalid: '',
          full_name: '',
          limit_amount: '',
          payment_rule_way_id: '',
          added_by: '',
          source: '',
          medium: '',
          campaign: '',
        };

        if (this.dataSubscriber) {
          this.dataSubscriber.unsubscribe();
        }

        // Set Init Filters
        this.setInitFilters(queryParams);
        // Create Query String
        this.createQueryString(this.filters);

        this.getTableData();
      }
    );
  }

  ngOnDestroy(): void {
    this.querySubscriber.unsubscribe();
    this.dataSubscriber.unsubscribe();
  }

  getTableData() {
    this.loading = true;
    this.dataSubscriber = this.emailService
      .getTemplates(this.queryString)
      .subscribe((response) => {
        if (response) {
          this.tableData = response;
          this.loading = false;
        }
      });
  }

  getDataOnFilterSubmit() {
    // Set Init Filters
    this.filters = { ...this.filters, page: 0 };

    // Create Query String
    this.createQueryString(this.filters);

    // Push Query String to history.
    // Navigates without replacing the current state in history.
    this.pushFiltersToHistory(this.filters);
  }

  createQueryString(filters = {}) {
    const clearFilters = { ...filters };

    Object.keys(clearFilters).forEach(
      (key) =>
        (!clearFilters[key] || clearFilters[key] === 'empty') &&
        delete clearFilters[key]
    );

    this.queryString = Object.keys(clearFilters)
      .map((key) => key + '=' + clearFilters[key])
      .join('&');
  }

  pushFiltersToHistory(filters, replaceUrl = false) {
    Object.keys(filters).forEach((key) => !filters[key] && delete filters[key]);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: Object.keys(filters).length > 0 ? filters : {},
      replaceUrl: replaceUrl,
    });
  }

  setInitFilters(query = {}) {
    this.filters = { ...this.startData, ...query };
  }

  getDataOnPageChange(page) {
    this.filters['page'] = page;

    // Create Query String
    this.createQueryString(this.filters);

    // Push Query String to history.
    // Navigates without replacing the current state in history.
    this.pushFiltersToHistory(this.filters);
  }

  onChangeDate(e: any) {
    this.filters = { ...this.filters, ...e, page: 0 };
    // Create Query String
    this.createQueryString(this.filters);

    // Push Query String to history.
    // Navigates without replacing the current state in history.
    this.pushFiltersToHistory(this.filters);
  }

  formatDate(stringDate) {
    let formatDateString;
    if (stringDate) {
      formatDateString = new Date(stringDate.replace(/-/g, '/'));
    }
    return formatDateString;
  }

  open(e, id, content) {
    e.preventDefault();

    const selectedTemplate = this.tableData.filter(
      (item) => +item.id === +id
    )[0];

    const contentFromJson = selectedTemplate?.content_json
      ? this.emailService.getParsedHtml(
          JSON.parse(selectedTemplate?.content_json),
          true
        )
      : '';

    this.selectedTemplate = this.sanitizer.bypassSecurityTrustHtml(
      contentFromJson ? contentFromJson : selectedTemplate?.content || ''
    );

    this.selectedTemplateId = +id;

    this.modalService.open(content, { size: 'lg' });
  }

  onPublish(e) {
    if (e) {
      const tableData = this.tableData.map((element) => {
        if (+element.id === +e.id) {
          return { ...element, ...e };
        }
        return element;
      });

      this.tableData = tableData;
    }
  }
}
