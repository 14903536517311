<nav class="navbar fixed-top navbar-expand-lg navbar-milinea bg-green-dark">
    <div class="navbar-container">
        <a class="navbar-brand p-0" routerLink="/backoffice"
            ><img width="100px" src="assets/img/logo@3x.png" alt=""
        /></a>
        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler6"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler6">
            <div class="col-2 ml-auto">
                <select name="" id="" class="form-control">
                    <option value="">Select option</option>
                    <option value="1">Visual</option>
                    <option value="2">Baño</option>
                    <option value="3">Descanso</option>
                    <option value="4">Operativo</option>
                </select>
            </div>
            <div class="col-8">
                <form class="input-group" #searchForm="ngForm" (ngSubmit)="search(searchForm)">
                    <input
                        type="text"
                        ngModel
                        name="search_query"
                        class="form-control"
                        placeholder="Search for: Tlf / Dni / Name / Last name / Lead code / Loan code / Email"
                    />
                </form>
            </div>
            <ul class="navbar-nav nav-flex-icons">
                <li class="nav-item">
                    <a (click)="logout()" class="nav-link"><i class="fa fa-user mr-2"></i>Log out / {{ name }} </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
