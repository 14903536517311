<div *appHasPermissions="['finance-cashin']">
    <div class="row justify-content-between">
        <div class="col-sm-12 col-lg-auto">
            <div class="row align-items-center">
                <div class="col-auto"><h2 class="mb-0">Cash In</h2></div>
                <div class="col-auto">
                    <div
                        class="btn-group btn-group-toggle"
                        ngbRadioGroup
                        name="view"
                        [(ngModel)]="filters.view"
                        (change)="pushFiltersToHistory(filters)"
                    >
                        <label
                            ngbButtonLabel
                            class="btn-sm btn-outline-primary"
                        >
                            <input
                                ngbButton
                                type="radio"
                                value="payment_type"
                            />
                            Payment Type
                        </label>
                        <label
                            ngbButtonLabel
                            class="btn-sm btn-outline-primary"
                        >
                            <input ngbButton type="radio" value="status" />
                            Status
                        </label>
                    </div>
                </div>
            </div>

            <a
                [href]="exportLink"
                (click)="getExportLink($event)"
                class="d-inline-block mt-2"
                >Export</a
            >
        </div>

        <div class="col-12 col-xl-auto ml-xl-auto">
            <div class="row">
                <div class="col-12 col-lg-auto align-self-end">
                    <div class="h4 mb-3 pb-2">TOTAL</div>
                </div>

                <div class="col-sm-6 col-lg-auto">
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                AMOUNT
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.total
                                        ? (total?.total | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-auto">
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                LEAD FEES
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.lead_fee
                                        ? (total?.lead_fee | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="filters.view === 'payment_type'"
                    class="col-sm-6 col-lg-auto"
                >
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                LATE FEES
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.late_fee
                                        ? (total?.late_fee | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="filters.view === 'payment_type'"
                    class="col-sm-6 col-lg-auto"
                >
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                FEES
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.fee
                                        ? (total?.fee | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="filters.view === 'payment_type'"
                    class="col-sm-6 col-lg-auto"
                >
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                CAPITAL
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.capital
                                        ? (total?.capital | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="filters.view === 'payment_type'"
                    class="col-sm-6 col-lg-auto"
                >
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                CHARGE BACK
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.charge_back
                                        ? (total?.charge_back | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="filters.view === 'status'"
                    class="col-sm-6 col-lg-auto"
                >
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                COLLECTION
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.collection
                                        ? (total?.collection | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="filters.view === 'status'"
                    class="col-sm-6 col-lg-auto"
                >
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title d-block text-center">
                                DUE
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center mb-0">
                                {{
                                    total?.due
                                        ? (total?.due | currency: 'EUR')
                                        : '-'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Cash In Date</th>
                    <th *ngIf="filters.view === 'status'">Activated Date</th>
                    <th>Reference Number</th>
                    <th>Payment Gateway</th>
                    <th>Payment Source</th>
                    <th>Payment Type</th>
                    <th>Loan/Lead</th>
                    <th>Amount</th>
                    <th>Late Fee</th>
                    <th>Withdrawal Coast</th>
                    <th>Fee</th>
                    <th>Capital</th>
                    <th>Lead Fee</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-daterange-filter
                            fieldName="created_at"
                            [start]="filters.created_at_start"
                            [end]="filters.created_at_end"
                            (changeDate)="onChangeDate($event)"
                            [withStartDate]="true"
                        ></app-daterange-filter>
                    </td>
                    <td *ngIf="filters.view === 'status'">
                        <app-daterange-filter
                            fieldName="loan_activation_date"
                            [start]="filters.loan_activation_date_start"
                            [end]="filters.loan_activation_date_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Reference Number"
                            name="merchant_transaction_id"
                            [(ngModel)]="filters.merchant_transaction_id"
                        />
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="provider_id"
                            [(ngModel)]="filters.provider_id"
                        >
                            <option value="">Select Gateway</option>
                            <option
                                *ngFor="let gateway of selects.gateways"
                                value="{{ gateway.id }}"
                            >
                                {{ gateway.name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="source_id"
                            [(ngModel)]="filters.source_id"
                        >
                            <option value="">Select Source</option>
                            <option
                                *ngFor="let source of selects.sources"
                                value="{{ source.id }}"
                            >
                                {{ source.name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="type_id"
                            [(ngModel)]="filters.type_id"
                        >
                            <option value="">Select Type</option>
                            <option
                                *ngFor="let type of selects.types"
                                value="{{ type.id }}"
                            >
                                {{ type.name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loan/Lead Code"
                            name="loan_code_str"
                            [(ngModel)]="filters.loan_code_str"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount"
                            name="amount"
                            [(ngModel)]="filters.amount"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Late Fee"
                            name="late_fee"
                            [(ngModel)]="filters.late_fee"
                        />
                    </td>
                    <td></td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Fee"
                            name="fee"
                            [(ngModel)]="filters.fee"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Capital"
                            name="capital"
                            [(ngModel)]="filters.capital"
                        />
                    </td>
                    <td></td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>{{ item?.date }}</td>
                        <td *ngIf="filters.view === 'status'">
                            {{
                                item.loan_activation_date
                                    ? item.loan_activation_date
                                    : '-'
                            }}
                        </td>
                        <td>{{ item?.merchant_transaction_id }}</td>
                        <td>{{ item?.provider }}</td>
                        <td>{{ item?.source }}</td>
                        <td>{{ item?.type }}</td>
                        <td>
                            <a
                                class="miLineaFontColor"
                                (click)="goto(item?.lead_id)"
                                >{{ item?.code_str }}</a
                            >
                        </td>
                        <td>{{ item?.amount | currency: 'EUR' }}</td>
                        <td>{{ item?.late_fee_amount | currency: 'EUR' }}</td>
                        <td>
                            {{
                                (item?.withdrawal_cost_amount
                                    ? item?.withdrawal_cost_amount
                                    : 0
                                ) | currency: 'EUR'
                            }}
                        </td>
                        <td>{{ item?.fee_amount | currency: 'EUR' }}</td>
                        <td>{{ item?.capital_amount | currency: 'EUR' }}</td>
                        <td>{{ item?.lead_fee | currency: 'EUR' }}</td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
