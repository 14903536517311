import { FormControl, Validators } from '@angular/forms';
import { FilterRange } from './FilterRange.model';
import { FilterBase } from './FilterBase.model';

export class FilterRangeForm {
    start: FormControl;
    end: FormControl;

    constructor(range: FilterBase<any>) {
        this.start = new FormControl(range['start'] || '', [Validators.required, Validators.min(0)]);

        this.end = new FormControl(range['end'] || '', Validators.min(0));
    }
}
