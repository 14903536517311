import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
@Injectable()
export class LeadService {
    public leads = {};
    public status = {};
    constructor(private http: HttpClient, private router: Router) {}
    getLeadRejectedReason(): Promise<Object> {
        return this.http
            .get(environment.api_url + '/lead/status_reason/list')
            .toPromise();
    }

    getLeadStatus(): Promise<Object> {
        return this.http
            .get(environment.api_url + '/lead/status/list')
            .toPromise();
    }

    getAllFiltered(params: string): Observable<any> {
        return this.http.get(environment.api_url + '/lead/list?' + params);
    }
    getAllFilteredByUrl(url: string): Observable<any> {
        return this.http.get(url);
    }
    getCondition(): Observable<any> {
        return this.http.get(environment.api_url + '/lead/status/condition');
    }
    recover(lead: any) {
        if (Object.values(lead).length) {
            /*console.log(lead.lead_status_id);*/
            switch (
                parseInt(lead.lead_status_id, 10) // parse int base 10
            ) {
                case 1: {
                    this.router.navigate(['line-request-MobileCheck']);
                    break;
                }
                case 3: {
                    this.router.navigate(['line-request-AdditionalInfo']);
                    break;
                }
                case 6: {
                    this.router.navigate(['lead/bankaccountlogin']);
                    break;
                }
                case 7: {
                    this.router.navigate(['line-request-Offer']);
                    break;
                }
                case 8: {
                    this.router.navigate(['line-request-ContractSign']);
                    break;
                }
                default: {
                    this.router.navigate(['out-of-service']);
                    break;
                }
            }
        } else {
            this.router.navigate(['my-account-GeneralMenu']);
        }
    }
    getLastAndPending(): Observable<any> {
        return this.http.get(environment.api_url + '/lead/show');
    }

    get(id: string): Promise<Object> {
        return this.http
            .get(environment.api_url + '/lead/' + id + '/show')
            .toPromise();
    }

    getSavsoValidation(id: string): Promise<Object> {
        return this.http
            .get(environment.api_url + '/lead/' + id + '/show')
            .toPromise();
    }

    getMilineaCustomer(lead_id: string): Promise<Object> {
        return this.http
            .get(
                environment.api_url + '/lead/' + lead_id + '/milinea_user/show'
            )
            .toPromise();
    }
    getAllByUserId(user_id: string): Promise<Object> {
        return this.http
            .get(environment.api_url + '/user/' + user_id + '/lead/list')
            .toPromise();
    }
    getRequestInfo(): Promise<any> {
        return this.http
            .get(environment.api_url + '/lead/info/request')
            .toPromise();
    }

    getCreditLineCheck(loan_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/${loan_id}/activation/show`
        );
    }

    addPhone(lead_id, phone: Object): Observable<any> {
        return this.http.post(
            environment.api_url + `/lead/${lead_id}/contact_phone/update`,
            phone
        );
    }

    bankAggregationShow(lead_id): Promise<Object> {
        return this.http
            .get(environment.api_url + `/lead/${lead_id}/bank_aggregation/show`)
            .toPromise();
    }

    scoringShow(lead_id): Promise<Object> {
        return this.http
            .get(environment.api_url + `/lead/${lead_id}/scoring/show`)
            .toPromise();
    }

    asnefShow(lead_id): Promise<Object> {
        return this.http
            .get(environment.api_url + `/lead/${lead_id}/asnef/show`)
            .toPromise();
    }

    antifraudShow(lead_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/lead/${lead_id}/antifraud/show`
        );
    }

    getBackAggregation(lead_id) {
        return this.http.get(
            environment.api_url + `/lead/${lead_id}/bank_aggregation/show`
        );
    }

    /*
    check(): boolean {
      return localStorage.getItem('user') ? true : false;
    }

    login(credentials: { email: string, password: string }): Observable<boolean> {
      return this.http.post<any>(`${environment.api_url}/auth/login`, credentials)
        .do(data => {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', btoa(JSON.stringify(data.user)));
        });
    }

    logout(): void {
      this.http.get(`${environment.api_url}/auth/logout`).subscribe(resp => {
        console.log(resp);
        localStorage.clear();
        this.router.navigate(['auth/login']);
      });
    }

    getUser(): User {
      return localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user'))) : null;
    }

    setUser(): Promise<boolean> {
      return this.http.get<any>(`${environment.api_url}/auth/me`).toPromise()
        .then(data => {
          if (data.user) {
            localStorage.setItem('user', btoa(JSON.stringify(data.user)));
            return true;
          }
          return false;
        });
    }
    */
}
