<span *ngIf="action === 'edit'" (click)="open(source)">Edit</span>
<button *ngIf="action === 'create'" class="btn btn-primary" (click)="open(source)">Add Source +</button>

<ng-template #source let-c="close">
    <div class="modal-header">
        <h4 class="modal-title">{{ action === 'edit' ? 'Edit Source' : 'Create New Source' }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="row" #form="ngForm" novalidate>
            <div *ngIf="action === 'create'" class="col-12">
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        name="key"
                        placeholder="Source Key"
                        [(ngModel)]="sourceModel.key"
                        required
                    />
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <select class="form-control" name="channel_id" [(ngModel)]="sourceModel.channel_id" required>
                        <option value="" disabled>Channel ID</option>
                        <option *ngFor="let channel of affiliateService?.channels" [value]="channel?.id">{{
                            channel?.name
                        }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <input
                        type="url"
                        class="form-control"
                        name="url_callback"
                        [(ngModel)]="sourceModel.url_callback"
                        placeholder="URL Callback"
                    />
                </div>
            </div>
            <div *ngIf="sourceModel?.parameters?.length > 0" class="col-12">
                <div *ngFor="let param of sourceModel?.parameters; let index = index" class="form-group">
                    <div class="form-row">
                        <div class="col-5">
                            <input
                                type="text"
                                class="form-control"
                                [name]="'parameterKey' + index"
                                [(ngModel)]="(sourceModel?.parameters)[index].key"
                                placeholder="Key"
                                required
                            />
                        </div>
                        <div class="col-5">
                            <input
                                type="text"
                                class="form-control"
                                [name]="'parameterValue' + index"
                                [(ngModel)]="(sourceModel?.parameters)[index].value"
                                placeholder="Value"
                                required
                            />
                        </div>
                        <div class="col-2 text-right">
                            <a
                                href="#"
                                class="text-warning"
                                style="font-size: 1.5rem"
                                title="Remove"
                                (click)="removeParametr($event, index)"
                                ><i class="fa fa-minus-circle"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 form-group">
                <a href="#" class="link--dotted" (click)="addParametr($event)">Add parameters +</a>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <input
                        type="number"
                        class="form-control"
                        name="rate"
                        [(ngModel)]="sourceModel.rate"
                        placeholder="Rate"
                    />
                </div>
            </div>
            <div class="col-12">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="make_callback"
                        name="make_callback"
                        [(ngModel)]="sourceModel.make_callback"
                    />
                    <label class="custom-control-label" for="make_callback">Make Callback</label>
                </div>
            </div>
            <div class="col-12">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="enabled"
                        name="enabled"
                        [(ngModel)]="sourceModel.enabled"
                    />
                    <label class="custom-control-label" for="enabled">Active</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button [disabled]="sending" class="btn btn-primary" (click)="submit(action, form)">
            {{ sending ? 'Saving...' : action === 'edit' ? 'Save' : 'Create' }}
        </button>
    </div>
</ng-template>
