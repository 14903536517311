import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { take } from 'rxjs/operators';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { NgModel } from '@angular/forms';

interface NodeCreateModel {
  amount?: string;
  success?: boolean;
  failure?: boolean;
  success_node_id?: string | number;
  failure_node_id?: string | number;
}

@Component({
  selector: '[app-payment-tree-node-create]',
  templateUrl: './payment-tree-node-create.component.html',
  styleUrls: ['./payment-tree-node-create.component.scss'],
})
export class PaymentTreeNodeCreateComponent implements OnInit, OnChanges {
  @Input() treeId;
  @Input() nodes;
  @Input() deletedId;

  @Output() created: EventEmitter<any> = new EventEmitter();

  isSending = false;

  model: NodeCreateModel = {
    amount: '',
    success: false,
    failure: false,
    success_node_id: '',
    failure_node_id: '',
  };

  constructor(
    private debtRecoveryService: DebtRecoveryService,
    private errorService: ErrorMessageService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const deletedIdChange: SimpleChange = changes.deletedId;

    if (deletedIdChange && deletedIdChange.currentValue) {
      this.model.success_node_id =
        Number(deletedIdChange.currentValue) ===
        Number(this.model.success_node_id)
          ? ''
          : this.model.success_node_id;

      this.model.failure_node_id =
        Number(deletedIdChange.currentValue) ===
        Number(this.model.failure_node_id)
          ? ''
          : this.model.failure_node_id;
    }
  }

  onModelChange(model) {
    this.model[`${model}_node_id`] = '';
  }

  onSubmit(amount: NgModel) {
    this.isSending = true;

    const data: NodeCreateModel = {};

    Object.keys(this.model).forEach((key) => {
      data[key] = this.model[key];
    });
    data.success_node_id = Number(data.success_node_id) || null;
    data.failure_node_id = Number(data.failure_node_id) || null;

    this.debtRecoveryService
      .createPaymentTreeAmountNode(this.treeId, data)
      .pipe(take(1))
      .toPromise()
      .then((response) => {
        this.errorService.showSuccess('Node created successfuly!');

        amount.reset();
        this.model.success = false;
        this.model.failure = false;
        this.model.success_node_id = '';
        this.model.failure_node_id = '';

        this.isSending = false;

        this.created.emit(response);
      })
      .catch((err) => {
        this.isSending = false;
        this.errorService.showError(err.error.error.show_message);
      });
  }
}
