<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">
            Basic info <span *ngIf="editInfo" (click)="openEdit(editBasic, data)">Edit</span>
        </h5>
    </div>
    <div class="card-body">
        <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Name</strong><span>{{ data?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Last Name 1</strong><span>{{ data?.first_surname }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Last Name 2</strong><span>{{ data?.second_surname }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>DNI/NIF</strong><span>{{ user?.personalid }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Birth Date</strong><span>{{ user?.birth_date }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Gender</strong><span>{{ user?.sex?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Black List</strong><span>{{ user?.blacklisted === 1 ? 'Si' : 'No' }}</span>
            </li>
        </ul>
    </div>
</div>

<ng-template #editBasic let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Basic Info update</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-group" #editBasicForm="ngForm">
            <div class="form-group">
                <label for="edit_name">Name</label>
                <input
                    type="text"
                    name="name"
                    id="edit_name"
                    class="form-control"
                    [(ngModel)]="basicModel.name"
                    #nameControl="ngModel"
                    required
                />
                <label *ngIf="nameControl.invalid" style="color:red">Inválido</label>
            </div>

            <div class="form-group">
                <label for="edit_first_surname">Last Name 1</label>
                <input
                    type="text"
                    name="first_surname"
                    id="edit_first_surname"
                    class="form-control"
                    [(ngModel)]="basicModel.first_surname"
                    #firstSurnameControl="ngModel"
                    required
                />
                <label *ngIf="firstSurnameControl.invalid" style="color:red">Inválido</label>
            </div>

            <div class="form-group">
                <label for="edit_second_surname">Last Name 2</label>
                <input
                    type="text"
                    name="second_surname"
                    id="edit_second_surname"
                    class="form-control"
                    [(ngModel)]="basicModel.second_surname"
                    #secondSurnameControl="ngModel"
                />
                <label *ngIf="secondSurnameControl.invalid && secondSurnameControl.value" style="color:red"
                    >Inválido</label
                >
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <img src="assets/img/backoffice/loading.gif" width="50px" height="50px" *ngIf="saving" />
        <button
            type="submit"
            [disabled]="editBasicForm.invalid || isSaving"
            class="btn btn-outline-dark"
            (click)="saveInfo(editBasicForm.value)"
        >
            {{ isSaving ? 'Saving' : 'Save' }}
        </button>
    </div>
</ng-template>
