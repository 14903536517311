import { DecimalPipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart, ChartConfiguration } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { forkJoin, SubscriptionLike as ISubscription } from 'rxjs';
import { KpiService } from '../../../services/kpi.service';

Chart.plugins.unregister(ChartDataLabels);

declare const moment: any;

@Component({
    selector: 'app-kpis-concessions',
    templateUrl: './kpis-concessions.component.html',
    styleUrls: ['./kpis-concessions.component.scss'],
    providers: [DecimalPipe],
})
export class KpisConcessionsComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('pieChart') pieChart: ElementRef;

    @ViewChild('leadWeeklyChart')
    leadWeeklyChart: ElementRef;
    @ViewChild('leadCompletedWeeklyChart')
    leadCompletedWeeklyChart: ElementRef;
    @ViewChild('leadIssuedWeeklyChart')
    leadIssuedWeeklyChart: ElementRef;

    @ViewChild('leadMonthlyChart')
    leadMonthlyChart: ElementRef;
    @ViewChild('leadCompletedMonthlyChart')
    leadCompletedMonthlyChart: ElementRef;
    @ViewChild('leadIssuedMonthlyChart')
    leadIssuedMonthlyChart: ElementRef;

    querySubscriber: ISubscription;
    forkJoinSubscription: ISubscription;

    conversions: any = {};

    startData = {};
    filters;
    queryString = '';

    loading = false;

    pieChartLabels: string[] = [];
    pieChartData: number[] = [];
    pieChartLegend: SafeHtml = '';
    pieChartTotal: number;
    pieChartInstance: any;

    emitedTotal: any = {};

    calendarDates = {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private readonly kpiService: KpiService,
        private _decimalPipe: DecimalPipe
    ) {}

    ngOnInit(): void {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    date_start: this.calendarDates.startDate,
                    date_end: this.calendarDates.endDate,
                };

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getData();
            }
        );

        this.getRangeLeadsAndLoans();
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.forkJoinSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.pieChart?.nativeElement) {
                this.createPieChart(this.pieChart.nativeElement);
            }
        });
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getRangeLeadsAndLoans() {
        const weeklyQuery = {
            date_start: moment()
                .subtract(11, 'week')
                .startOf('isoWeek')
                .format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            time_unit: 'week',
        };

        const monthlyQuery = {
            date_start: moment()
                .subtract(11, 'month')
                .startOf('month')
                .format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            time_unit: 'month',
        };

        const leadsWeekly = this.kpiService.getLeadsByDate(weeklyQuery);
        const leadsCompletedWeekly = this.kpiService.getLeadsByDate({
            ...weeklyQuery,
            status_id: 10,
        });
        const loansWeekly = this.kpiService.getEmitedByDate(weeklyQuery);

        const leadsMonthly = this.kpiService.getLeadsByDate(monthlyQuery);
        const leadsCompletedMonthly = this.kpiService.getLeadsByDate({
            ...monthlyQuery,
            status_id: 10,
        });
        const loansMonthly = this.kpiService.getEmitedByDate(monthlyQuery);

        this.forkJoinSubscription = forkJoin([
            leadsWeekly,
            leadsCompletedWeekly,
            loansWeekly,
            leadsMonthly,
            leadsCompletedMonthly,
            loansMonthly,
        ]).subscribe(
            ([
                leadsWeeklyResponse,
                leadsCompletedWeeklyResponse,
                loansWeeklyResponse,
                leadsMonthlyResponse,
                leadsCompletedMonthlyResponse,
                loansMonthlyResponse,
            ]) => {
                if (leadsWeeklyResponse) {
                    this.createBarChart(
                        this.leadWeeklyChart?.nativeElement,
                        this.createData(
                            this.formatData(leadsWeeklyResponse as [])
                        )
                    );
                }

                if (leadsCompletedWeeklyResponse) {
                    this.createBarChart(
                        this.leadCompletedWeeklyChart?.nativeElement,
                        this.createData(
                            this.formatData(leadsCompletedWeeklyResponse as [])
                        )
                    );
                }

                if (loansWeeklyResponse) {
                    this.createMixedChart(
                        this.leadIssuedWeeklyChart?.nativeElement,
                        this.createMixedData(
                            this.formatData(loansWeeklyResponse as [], {
                                issue_count: 0,
                                amount: 0,
                            })
                        )
                    );
                }

                if (leadsMonthlyResponse) {
                    this.createBarChart(
                        this.leadMonthlyChart?.nativeElement,
                        this.createData(
                            this.formatData(
                                leadsMonthlyResponse as [],
                                { lead_count: 0 },
                                'month'
                            ),
                            'month'
                        )
                    );
                }

                if (leadsCompletedMonthlyResponse) {
                    this.createBarChart(
                        this.leadCompletedMonthlyChart?.nativeElement,
                        this.createData(
                            this.formatData(
                                leadsCompletedMonthlyResponse as [],
                                { lead_count: 0 },
                                'month'
                            ),
                            'month'
                        )
                    );
                }

                if (loansMonthlyResponse) {
                    this.createMixedChart(
                        this.leadIssuedMonthlyChart?.nativeElement,
                        this.createMixedData(
                            this.formatData(
                                loansMonthlyResponse as [],
                                {
                                    issue_count: 0,
                                    amount: 0,
                                },
                                'month'
                            ),
                            'month'
                        )
                    );
                }
            }
        );
    }

    formatData(
        data: any[],
        replacement: any = { lead_count: 0 },
        type: 'isoWeek' | 'month' = 'isoWeek'
    ) {
        const array = this.generateDates(type).map((date) => {
            const exist = data.find(
                (item) => item.first_day_time_unit === date.first_day_time_unit
            );

            return exist ? { ...exist, ...date } : { ...date, ...replacement };
        });
        return array;
    }

    getData() {
        this.pieChartLabels = [];
        this.pieChartData = [];
        this.loading = true;

        const conversions = this.kpiService.getConversions(this.queryString);
        const rejectedByCallcenter = this.kpiService.getRejectedByCallCenter(
            this.queryString
        );
        const emitedTotal = this.kpiService.getEmitedTotal(this.queryString);

        forkJoin([conversions, rejectedByCallcenter, emitedTotal]).subscribe(
            ([
                conversionsResponse,
                rejectedByCallcenterResponse,
                emitedTotalResponse,
            ]) => {
                if (conversionsResponse) {
                    conversionsResponse.forEach((item) => {
                        const { status_name, status_count, percent } = item;

                        this.conversions[status_name.toLowerCase()] = {
                            status_count,
                            percent,
                        };
                    });
                }

                if (rejectedByCallcenterResponse) {
                    rejectedByCallcenterResponse.forEach((item) => {
                        const {
                            status_reason_id,
                            status_reason_name,
                            status_reason_count,
                        } = item;

                        if (status_reason_id) {
                            this.pieChartLabels.push(status_reason_name);
                            this.pieChartData.push(status_reason_count);
                        } else {
                            this.pieChartTotal = status_reason_count;
                        }
                    });

                    if (this.pieChartInstance) {
                        this.pieChartInstance.data.labels = this.pieChartLabels;
                        this.pieChartInstance.data.datasets[0].data = this.pieChartData;
                        this.pieChartInstance.update();

                        this.pieChartLegend = this.sanitizer.bypassSecurityTrustHtml(
                            this.pieChartInstance.generateLegend() as string
                        );
                    }
                }

                if (emitedTotalResponse) {
                    this.emitedTotal = emitedTotalResponse;
                }

                this.loading = false;
            }
        );
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    createPieChart(ctx) {
        if (ctx) {
            this.pieChartInstance = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: [],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                'rgba(105,53,110,1)',
                                'rgba(105,53,110,0.9)',
                                'rgba(105,53,110,0.8)',
                                'rgba(105,53,110,0.7)',
                                'rgba(105,53,110,0.6)',
                                'rgba(105,53,110,0.5)',
                                'rgba(105,53,110,0.4)',
                                'rgba(105,53,110,0.3)',
                                'rgba(105,53,110,0.2)',
                                'rgba(105,53,110,0.1)',
                            ],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    aspectRatio: 1.5,
                    legend: {
                        display: false,
                    },
                    legendCallback: (chart) => {
                        const html = [];
                        const { datasets, labels } = chart.data;

                        html.push('<ul class="pieChartLegend">');

                        datasets.forEach(({ data, backgroundColor }) => {
                            data.forEach((dataItem, index) => {
                                const percent = this.pieChartTotal
                                    ? this.transformDecimal(
                                          (dataItem / this.pieChartTotal) * 100
                                      )
                                    : 0;
                                const dataItemTransformed = this.transformDecimal(
                                    dataItem
                                );
                                html.push(`<li class="pieChartLegend__item">
                              <span class="pieChartLegend__box" style="background-color: ${backgroundColor[index]}"></span>
                              <span class="pieChartLegend__text">${labels[index]}</span>
                              <span class="pieChartLegend__value">
                                  <small class="mr-2">${dataItemTransformed}</small>
                                  <span class="d-inline text-primary">${percent}%</span>
                              </span>
                          </li>`);
                            });
                        });

                        html.push('</ul>');

                        return html.join('');
                    },
                },
            });
        }
    }

    transformDecimal(num) {
        return this._decimalPipe.transform(num, '1.0-0', 'es-ES');
    }

    createMixedChart(ctx, { data, labels }) {
        const datasets = [
            {
                type: 'line',
                label: 'Amount',
                data: data.amount,
                fill: false,
                borderColor: 'rgba(105,53,110,1)',
                yAxisID: 'y',
                datalabels: {
                    labels: {
                        amount: {
                            display: false,
                        },
                    },
                },
            },
            {
                type: 'bar',
                label: 'Count',
                data: data.count,
                backgroundColor: 'rgba(105,53,110,0.5)',
                yAxisID: 'y1',
            },
        ];

        if (ctx) {
            const chart = new Chart(
                ctx,
                this.createMixedOptions(datasets, labels)
            );
        }
    }

    createBarChart(ctx, { data, labels }) {
        if (ctx) {
            const chart = new Chart(ctx, this.createBarOptions(data, labels));
        }
    }

    createData(dataArray: any[], type: 'week' | 'month' = 'week') {
        const data = dataArray.map((item) => item?.lead_count);

        const labels = dataArray.map((item) =>
            type === 'week'
                ? `${moment(item?.first_day_time_unit).format(
                      'DD/MM/YYYY'
                  )} - ${moment(item?.last_day_time_unit).format('DD/MM/YYYY')}`
                : moment(item?.first_day_time_unit).format('MMM YYYY')
        );

        return { data, labels };
    }

    createMixedData(dataArray: any[], type: 'week' | 'month' = 'week') {
        const data = { amount: [], count: [] };
        dataArray.forEach((item) => {
            data.amount.push(item?.amount);
            data.count.push(item?.issue_count);
        });

        const labels = dataArray.map((item) =>
            type === 'week'
                ? `${moment(item?.first_day_time_unit).format(
                      'DD/MM/YYYY'
                  )} - ${moment(item?.last_day_time_unit).format('DD/MM/YYYY')}`
                : moment(item?.first_day_time_unit).format('MMM YYYY')
        );

        return { data, labels };
    }

    generateDates(type: 'isoWeek' | 'month' = 'isoWeek', startDate?: Date) {
        if (!startDate) {
            startDate = new Date();
        }

        const subtract = type === 'isoWeek' ? 'week' : type;

        const length = 12;
        const data: any[] = [
            {
                first_day_time_unit: moment()
                    .startOf(type)
                    .format('YYYY-MM-DD'),
                last_day_time_unit: moment()
                    .subtract(subtract)
                    .format('YYYY-MM-DD'),
            },
        ];

        for (let i = 1; i < length; i++) {
            data.push({
                first_day_time_unit: moment()
                    .subtract(i, subtract)
                    .startOf(type)
                    .format('YYYY-MM-DD'),
                last_day_time_unit: moment()
                    .subtract(i, subtract)
                    .endOf(type)
                    .format('YYYY-MM-DD'),
            });
        }

        data.reverse();

        return data;
    }

    createMixedOptions(datasets, labels): ChartConfiguration {
        const barOprtions: ChartConfiguration = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets,
            },
            plugins: [ChartDataLabels],
            options: {
                tooltips: {
                    mode: 'index',
                    intersect: true,
                },
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                responsive: true,
                aspectRatio: 4,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            display: false,
                        },
                    ],
                    yAxes: [
                        {
                            id: 'y',
                            display: false,
                            ticks: {
                                min: 0,
                            },
                            position: 'left',
                        },
                        {
                            id: 'y1',
                            display: false,
                            ticks: {
                                min: 0,
                            },
                            position: 'right',
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        offset: 0,
                    },
                },
            },
        };

        return barOprtions;
    }

    createBarOptions(dataset, labels): ChartConfiguration {
        const barOprtions: ChartConfiguration = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: dataset,
                        backgroundColor: 'rgba(105,53,110,0.5)',
                    },
                ],
            },
            plugins: [ChartDataLabels],
            options: {
                layout: {
                    padding: {
                        top: 16,
                    },
                },
                responsive: true,
                aspectRatio: 5,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            display: false,
                        },
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                min: 0,
                                stepSize: 100,
                            },
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        offset: 0,
                    },
                },
            },
        };

        return barOprtions;
    }
}
