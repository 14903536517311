import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { delay, tap, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
    public user: any = null;
    public allPermissions: any = null;
    public allRoles: any = null;
    public userPermissions: any = null;
    private userMapsPermissions: Array<any> = [];
    public userRoles: any = null;
    public rolePermissions: any = null;
    public users: any = null;
    public userList: any = null;

    public logged_in = false;

    // Assuming this would be cached somehow from a login call.
    public authTokenStale: string;
    public authTokenNew: string;
    public currentToken: string;
    public refreshingToken = false;
    cachedRequests: Array<HttpRequest<any>> = [];

    constructor(private http: HttpClient, private router: Router) {
        //this.currentToken = this.authTokenStale;
    }

    getAuthToken() {
        return localStorage.getItem('token_admin');
    }

    refreshToken(): Observable<string> {
        /*
        The call that goes in here will use the existing refresh token to call
        a method on the oAuth server (usually called refreshToken) to get a new
        authorization token for the API calls.
    */

        this.http
            .post<any>(environment.api_url + '/auth/refresh_admin', {})
            .subscribe((data: any) => {
                localStorage.setItem('token_admin', data.token);
                this.authTokenNew = data.token;
                this.currentToken = data.token;
            });

        this.currentToken = localStorage.getItem('token_admin');
        return of(this.currentToken).pipe(delay(200));
    }

    loggedIn() {}
    setToken(token) {
        this.authTokenStale = token;
        localStorage.setItem('token_admin', token);
    }
    check(permission_name): Observable<any> {
        return this.http.get(
            `${environment.api_url}/auth/can/${permission_name}`
        );
    }

    login(user: string, password: string): Observable<any> {
        return this.http
            .post(environment.api_url + '/auth/login', {
                username: user,
                password: password
            })
            .pipe(tap((data: any) => data));
    }

    logout(): void {
        //this.http.get(`${environment.api_url}/auth/logout_admin`).subscribe(resp => {
        localStorage.clear();
        this.userPermissions = null;
        this.userMapsPermissions = [];
        this.router.navigate(['']);
    }

    getUserPermissions(): Observable<any> {
        return this.http.get(environment.api_url + '/admin/permission/list');
    }

    mapUserPermissions(path: Array<string>): Observable<boolean> {
        if (!this.userMapsPermissions.length) {
            return this.getUserPermissions().pipe(
                tap(roles => {
                    const rolesArray = roles.map(role => role.name);
                    this.userMapsPermissions = rolesArray;
                }),
                map(() => this.doCheckPermissions(path))
            );
        }

        return of(this.doCheckPermissions(path));
    }

    private doCheckPermissions(path: Array<string>): boolean {
        if (path.length && this.userMapsPermissions.length) {
            const intersection = path.filter(element =>
                this.userMapsPermissions.includes(element)
            );
            return Boolean(intersection.length);
        }
        return false;
    }

    getLocalUser(): any {
        return localStorage.getItem('user')
            ? JSON.parse(atob(localStorage.getItem('user')))
            : null;
    }

    setUser(): Promise<boolean> {
        return this.http
            .get<any>(`${environment.api_url}/auth/me`)
            .toPromise()
            .then(data => {
                if (data.user) {
                    localStorage.setItem(
                        'user',
                        btoa(JSON.stringify(data.user))
                    );
                    return true;
                }
                return false;
            });
    }

    getUserByLeadId(lead_id) {
        return this.http
            .get(`${environment.api_url}/lead/${lead_id}/user/show`)
            .toPromise();
    }

    getAllPermissions(): Observable<any> {
        return this.http.get(`${environment.api_url}/permission/list`);
    }
    getAllUsersWithRoles() {
        return this.http
            .get(`${environment.api_url}/admin/list`)
            .subscribe(users => {
                if (users['data'].length > 0) {
                    this.users = users;
                }
            });
    }

    getUserList() {
        return this.http
            .get(environment.api_url + '/admin/list')
            .subscribe(list => {
                if (Object.values(list).length) {
                    this.userList = list;
                }
            });
    }

    getUsers(): Observable<any> {
        return this.http.get(environment.api_url + '/admin/list');
    }

    getFilteredUsersList(usersWithRole = ['admin', 'am', 'amc', 'bum']) {
        return this.http
            .get(environment.api_url + '/admin/list')
            .toPromise()
            .then((list: any) => {
                if (list.data && list.data.length > 0) {
                    const users = list.data;
                    const filteredList = {
                        actived: users.filter(
                            user =>
                                !user.blocked &&
                                user.roles.some(role =>
                                    usersWithRole.includes(role.name)
                                )
                        ),
                        blocked: users.filter(
                            user =>
                                user.blocked &&
                                user.roles.some(role =>
                                    usersWithRole.includes(role.name)
                                )
                        )
                    };
                    return filteredList;
                }
            })
            .catch(err => console.log(err));
    }

    getAllRoles(): Observable<any> {
        return this.http.get(`${environment.api_url}/role/list`);
    }

    /*
     * Carga los permisos de un Rol
     */
    getRolePermissions(roleId): Observable<any> {
        return this.http.get(
            `${environment.api_url}/role/${roleId}/permission/list`
        );
    }

    getUserRoles(userId): Observable<any> {
        return this.http
            .get(`${environment.api_url}/user/${userId}/roles`)
            .pipe(
                map(resp => {
                    this.userRoles = resp;
                })
            );
    }
    attachPermission(role_id, permission_id) {
        return this.http.post(
            `${environment.api_url}/role/${role_id}/permission/add`,
            {
                permission_id: permission_id
            }
        );
    }
    detachPermission(role_id, permission_id) {
        return this.http.post(
            `${environment.api_url}/role/${role_id}/permission/delete`,
            {
                permission_id: permission_id
            }
        );
    }
    createUser(user): Observable<any> {
        return this.http.post(`${environment.api_url}/admin/create`, user);
    }
    editUser(user_id, user): Observable<any> {
        return this.http.post(`${environment.api_url}/admin/update`, {
            ...user,
            admin_id: user_id
        });
    }
    createRole(role): Observable<any> {
        return this.http.post(`${environment.api_url}/role/create`, role);
    }

    createPermission(permission): Observable<any> {
        return this.http.post(
            `${environment.api_url}/permission/create`,
            permission
        );
    }
    getUser(user_id): Observable<any> {
        return this.http.get(`${environment.api_url}/admin/${user_id}/show`);
    }
}
