<form
    class="form-group row"
    [formGroup]="trackingForm"
    (ngSubmit)="onSubmit(trackingForm.value)"
    novalidate
>
    <div class="col-4 form-group">
        <select
            name="action"
            class="form-control"
            ngModel
            [(ngModel)]="trackingModel.action"
            (ngModelChange)="actionChanged()"
            [ngModelOptions]="{ standalone: true }"
            required
        >
            <option *ngFor="let key of events | keys" [value]="key">
                {{ events[key] }}
            </option>
        </select>
    </div>
    <div class="col-4 form-group"><ng-container #vc></ng-container></div>
    <div class="col-4 form-group"><ng-container #vc1></ng-container></div>
    <div class="col-4 form-group"><ng-container #vc2></ng-container></div>
    <div class="col-4 form-group"><ng-container #vc3></ng-container></div>
    <div class="col-4 form-group"><ng-container #vc4></ng-container></div>
    <div class="col-12 form-group">
        <input
            type="text"
            name="comment"
            class="form-control"
            formControlName="comment"
            ngModel
            [(ngModel)]="trackingModel.comment"
            placeholder="Add you note..."
            required
        />
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.comment.invalid &&
                trackingForm?.controls.comment.touched
            "
        >
            Note is required.
        </div>
    </div>
    <ng-template #buttonTpl>
        <button
            type="submit"
            [disabled]="trackingForm.invalid || isSaving"
            class="btn btn-accept"
        >
            {{ isSaving ? 'Saving' : 'Add action' }}
        </button>
    </ng-template>

    <ng-template #amountTpl>
        <input
            name="amount"
            type="number"
            class="form-control"
            formControlName="amount"
            ngModel
            [(ngModel)]="trackingModel.amount"
            placeholder="Amount"
            required
        />
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.amount.invalid &&
                trackingForm?.controls.amount.touched
            "
        >
            Amount is required.
        </div>
    </ng-template>

    <ng-template #weightedTpl>
        <input
            name="weighted"
            type="number"
            class="form-control"
            formControlName="weighted"
            ngModel
            [(ngModel)]="trackingModel.weighted"
            placeholder="Weighted"
            required
        />
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.weighted.invalid &&
                trackingForm?.controls.weighted.touched
            "
        >
            Weighted is required.
        </div>
    </ng-template>

    <ng-template #dateTpl>
        <input
            name="date"
            type="text"
            class="form-control"
            formControlName="date"
            ngModel
            [(ngModel)]="trackingModel.date"
            required
            placeholder="Choose date"
            daterangepicker
            [options]="singlePicker"
            (selected)="selectedDateSingle($event)"
            minlength="10"
            maxlength="10"
            autocomplete="off"
        />
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.date.invalid &&
                trackingForm?.controls.date.touched &&
                trackingForm?.controls.date.errors?.required
            "
        >
            Date is required.
        </div>
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.date.invalid &&
                trackingForm?.controls.date.touched &&
                !trackingForm?.controls.date.errors?.required
            "
        >
            Date is incorect.
        </div>
    </ng-template>

    <ng-template #timeTpl>
        <input
            name="hour"
            [textMask]="mask"
            type="text"
            class="form-control"
            formControlName="hour"
            ngModel
            [(ngModel)]="trackingModel.hour"
            placeholder="Time"
            minlength="5"
            maxlength="5"
        />
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.hour.invalid &&
                trackingForm?.controls.hour.touched &&
                trackingForm?.controls.hour.errors?.required
            "
        >
            Time is required.
        </div>
        <div
            class="text-danger"
            *ngIf="
                trackingForm?.controls.hour.invalid &&
                trackingForm?.controls.hour.touched &&
                !trackingForm?.controls.hour.errors?.required
            "
        >
            Time is incorect.
        </div>
    </ng-template>

    <ng-template #typeTpl>
        <select
            name="incident_id"
            class="form-control"
            formControlName="incident_id"
            ngModel
            [(ngModel)]="trackingModel.incident_id"
            required
        >
            <option *ngFor="let key of incidents | keys" [value]="key">
                {{ incidents[key] }}
            </option>
        </select>
    </ng-template>
</form>

<div *ngIf="trackingList.length" class="row">
    <div class="col-12">
        <app-pager
            [perPage]="15"
            [currentPage]="trackings?.current_page"
            [total]="trackings?.total"
            (changePage)="getTrackings($event)"
        >
        </app-pager>
        <img
            *ngIf="loading"
            width="50px"
            height="50px"
            src="assets/img/backoffice/loading.gif"
        />
        <div *ngIf="!loading" class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Created</th>
                        <th>Who</th>
                        <th>Event</th>
                        <th width="35%">Comment</th>
                        <th>Amount</th>
                        <th>Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of trackingList">
                        <td>{{ item?.created_at }}</td>
                        <td>{{ item?.created_by.name }}</td>
                        <td>{{ item?.type?.name }}</td>
                        <td>
                            {{ item?.comment }}
                            <div *ngIf="item?.weighted">
                                <strong>Weighted:</strong> {{ item?.weighted }}%
                            </div>
                            <div *ngIf="item?.incident_id">
                                <strong>Type:</strong>
                                {{ item?.incident?.name }}
                            </div>
                        </td>
                        <td>{{ item?.amount | currency: 'EUR' }}</td>
                        <td>{{ item?.date }} {{ item?.hour }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-pager
            [perPage]="15"
            [currentPage]="trackings?.current_page"
            [total]="trackings?.total"
            (changePage)="getTrackings($event)"
        >
        </app-pager>
    </div>
</div>
