import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';

@Component({
    selector: 'app-queue-table',
    templateUrl: './queue-table.component.html',
    styleUrls: ['./queue-table.component.scss'],
})
export class QueueTableComponent implements OnInit, OnDestroy {
    tableData;

    loading: boolean = true;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    startData: any = {};
    filters: any = {};

    queryString = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private debtRecovery: DebtRecoveryService
    ) {}

    ngOnInit() {
        const queryParams$ = this.activatedRoute.queryParams;

        this.querySubscriber = queryParams$
            .pipe(debounceTime(300))
            .subscribe((queryParams) => {
                this.startData = {
                    name: '',
                };

                if (this.dataSubscriber) {
                    this.dataSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getTableData();
            });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.debtRecovery
            .getQueueList(this.queryString)
            .subscribe((response) => {
                if (response) {
                    this.tableData = response;
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(e, page) {
        e.preventDefault();

        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }
}
