<button class="btn btn-sm btn-outline-primary" (click)="open(content)">
  Add New +
</button>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Create New Tree</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="treeCreateForm">
      <div class="form-group">
        <input
          type="text"
          name="name"
          formControlName="name"
          placeholder="Name"
          class="form-control"
          required
          [ngClass]="{
            'is--invalid':
              form.controls.name.invalid && form.controls.name.touched
          }"
        />
      </div>
      <div class="form-group">
        <textarea
          name="description"
          formControlName="description"
          placeholder="Description"
          class="form-control"
        ></textarea>
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="treeCreateEnabled"
          name="enabled"
          formControlName="enabled"
        />
        <label class="custom-control-label" for="treeCreateEnabled"
          >Enabled</label
        >
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="isSending || form.invalid"
      form="treeCreateForm"
    >
      {{ isSending ? 'Saving...' : 'Save' }}
    </button>
  </div>
</ng-template>
