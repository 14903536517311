import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LeadService } from '../../../services/lead.service';
import { SubscriptionLike as ISubscription, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var moment: any;

@Component({
    selector: 'app-lead-table',
    templateUrl: './lead-table.component.html',
    styleUrls: [
        /*'./lead-table.component.css', '../../../../app.component.css'*/
    ]
})
export class LeadTableComponent implements OnInit, OnDestroy {
    pageNames: any = {
        all: 'ALL',
        rejected: 'REJECTED',
        mobilePendingConfirmation: 'MOBILE PENDING CONFIRMATION',
        additionalInfo: 'ADDITIONAL INFO',
        riskValidation: 'RISK VALIDATION',
        lineConditionsProposed: 'LINE CONDITIONS PROPOSED',
        pendingContractSign: 'PENDING CONTRACT SIGN'
    };
    selectedPageName = '';

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    status: string;
    title: string = '';

    startData = {};

    filters = {};

    queryString: string = '';

    tableData;

    lead_status_filter: any = null;
    lead_rejected_reason_filter: any = null;

    loading = true;

    calendarDates = {
        startDate: moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    };

    permissionsArray: Array<string> = [];

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private leadService: LeadService) {}

    ngOnInit() {
        const params$ = this.activatedRoute.params;
        const queryParams$ = this.activatedRoute.queryParams;

        this.getListLeadStatusFilter();
        this.getListLeadRejectedReasonFilter();

        this.querySubscriber = combineLatest(params$, queryParams$)
            .pipe(debounceTime(300))
            .subscribe(([params, queryParams]) => {
                if (this.pageNames[params['status']]) {
                    this.selectedPageName = params['status'];
                    this.title = this.pageNames[params['status']];

                    const unCamelCaseStatus = this.selectedPageName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

                    if (this.selectedPageName === 'all') {
                        this.permissionsArray = ['underwriting-all', 'marketing-underwriting-all'];
                    } else {
                        this.permissionsArray = [`underwriting-${unCamelCaseStatus}`];
                    }

                    this.startData = {
                        code: '',
                        created_at_start: this.calendarDates.startDate,
                        created_at_end: this.calendarDates.endDate,
                        personalid: '',
                        full_name: '',
                        weeks: '',
                        amount_requested: '',
                        status_id: '',
                        status_reason_id: '',
                        source: '',
                        medium: '',
                        campaign: ''
                    };

                    if (this.dataSubscriber) {
                        this.dataSubscriber.unsubscribe();
                    }

                    // Set Init Filters
                    this.setInitFilters(queryParams);
                    // Create Query String
                    this.createQueryString(this.filters);

                    this.getTableData();
                }
            });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.leadService.getAllFiltered(this.queryString).subscribe(response => {
            if (response) {
                this.tableData = response;
                this.loading = false;
            }
        });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            key => (!clearFilters[key] || clearFilters[key] === 'empty') && delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');

        switch (this.selectedPageName) {
            case 'rejected':
                this.queryString += '&status_id=11';
                break;
            case 'mobilePendingConfirmation':
                this.queryString += '&status_id=1';
                break;
            case 'additionalInfo':
                this.queryString += '&status_id=3';
                break;
            case 'riskValidation':
                this.queryString += '&status_id=4';
                break;
            case 'lineConditionsProposed':
                this.queryString += '&status_id=5';
                break;
            case 'pendingContractSign':
                this.queryString += '&status_id=6';
                break;
            default:
                this.queryString = this.queryString;
        }
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(key => !filters[key] && delete filters[key]);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getListLeadRejectedReasonFilter() {
        return this.leadService.getLeadRejectedReason().then(data => {
            this.lead_rejected_reason_filter = data;
            return true;
        });
    }

    getListLeadStatusFilter() {
        return this.leadService.getLeadStatus().then(data => {
            this.lead_status_filter = data;
        });
    }

    viewLead(id) {
        this.router.navigate([`/backoffice/underwriting/overview/${id}/leadInfo/`, id]);
    }
}
