import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { DebtRecoveryService } from '../../../services/debt-recovery.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { Observable ,  Subject ,  concat ,  of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { CreditLineService } from '../../../services/credit-line.service';

@Component({
    selector: 'app-due-assignation',
    templateUrl: './due-assignation.component.html',
    styleUrls: ['./due-assignation.component.scss']
})
export class DueAssignationComponent implements OnInit {
    portfolioRotation = {
        debt_collector_id_from: '',
        debt_collector_id_to: '',
        tracking_type_id: '',
        action: 'reassign_to'
    };

    specificOnes = {
        loan_ids: [],
        debt_collector_id_to: '',
        action: 'assign_to'
    };

    filterLists = {
        traking: {
            link: '/tracking/filter/list',
            list: <any>null
        }
    };

    promises = [];

    loading = true;
    users: any;

    loansList: Observable<{} | any[]>;
    loanLoading = false;
    loanInput = new Subject<string>();

    constructor(
        private authService: AuthService,
        private debtRecovery: DebtRecoveryService,
        private errorMessageService: ErrorMessageService,
        private loanService: CreditLineService
    ) {}

    ngOnInit() {
        this.authService.getFilteredUsersList(['dc', 'admin']).then(data => (this.users = data));
        this.getFiltersList();

        Promise.all(this.promises).then(() => {
            this.loading = false;
        });

        this.loadLoans();
    }

    private loadLoans() {
        this.loansList = concat(
            of([]), // default items
            this.loanInput.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => (this.loanLoading = true)),
                switchMap(term =>
                    this.loanService.getByCodeStr(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.loanLoading = false))
                    )
                )
            )
        );
    }

    submitPortfolio(form, model) {
        if (this.confirming()) {
            this.debtRecovery
                .assignTo(model)
                .toPromise()
                .then(responce => {
                    if (responce) {
                        this.errorMessageService.showSuccess('Loans Assigned Successfully');
                        form.resetForm();
                        Object.keys(model).forEach(function(key) {
                            if (key !== 'action') {
                                model[key] = '';
                            }
                        });
                    } else {
                        this.errorMessageService.showError('Loans Assigned Failed');
                    }
                })
                .catch(error => {
                    if (error.error.error) {
                        this.errorMessageService.showError(error.error.error.show_message);
                    }
                });
        }
    }

    confirming() {
        if (confirm('¿Esta seguro que desea realizar esta operación?')) {
            return true;
        } else {
            return false;
        }
    }

    getFiltersList() {
        let _this = this;
        Object.keys(_this.filterLists).forEach(function(key) {
            _this.promises.push(
                _this.debtRecovery
                    .getFilters(_this.filterLists[key])
                    .toPromise()
                    .then(response => {
                        _this.filterLists[key].list = response;
                    })
            );
        });
    }
}
