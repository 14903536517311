<div *appHasPermissions="['debtrecovery-launcher-manager']">
    <div class="container">
        <h2>Launcher Manager</h2>
        <div class="row">
            <div *ngIf="step === 0" class="col-12 mb-4">
                <div class="card">
                    <div class="card-body pt-3 pl-3 pr-3 pb-0">
                        <form [formGroup]="queForm" (ngSubmit)="loadQue($event)" novalidate>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-row form-group">
                                        <label class="col-4 col-form-label text-primary">Date</label>
                                        <div class="col-8">
                                            <input
                                                type="text"
                                                name="date"
                                                formControlName="date"
                                                daterangepicker
                                                [options]="settings"
                                                class="form-control"
                                                (selected)="selectedDate($event)"
                                                placeholder="Choose date"
                                                (cancelDaterangepicker)="clearCalendar($event)"
                                                (keyup.enter)="clearCalendar($event)"
                                                autocomplete="off"
                                            />
                                            <small
                                                class="text-danger"
                                                *ngIf="
                                                    queForm?.controls.date.invalid &&
                                                    queForm?.controls.date.touched &&
                                                    queForm?.controls.date.errors?.required
                                                "
                                            >
                                                Date is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row form-group">
                                        <label for="queJob" class="col-4 col-form-label text-primary">Job Roles</label>
                                        <div class="col-8">
                                            <select
                                                id="queJob"
                                                formControlName="job_role"
                                                name="job_role"
                                                class="form-control"
                                            >
                                                <option value="all">All</option>
                                                <option
                                                    *ngFor="
                                                        let key of (filterLists?.jobRole?.list?.lead_job_type | keys)
                                                    "
                                                    value="{{ key }}"
                                                >
                                                    {{ (filterLists?.jobRole?.list?.lead_job_type)[key] }}</option
                                                >
                                            </select>
                                            <small
                                                class="text-danger"
                                                *ngIf="
                                                    queForm?.controls.job_role.invalid &&
                                                    queForm?.controls.job_role.touched &&
                                                    queForm?.controls.job_role.errors?.required
                                                "
                                            >
                                                Jobe Role is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row form-group">
                                        <label for="queGateway" class="col-4 col-form-label text-primary"
                                            >Gateway</label
                                        >
                                        <div class="col-8">
                                            <select
                                                formControlName="job_role"
                                                id="queGateway"
                                                name="gateway_id"
                                                class="form-control"
                                            >
                                                <option value="all">All</option>
                                                <ng-template
                                                    let-provider
                                                    ngFor
                                                    [ngForOf]="filterLists?.gateway?.list"
                                                    let-i="index"
                                                >
                                                    <option
                                                        *ngIf="
                                                            provider.name === 'UniversalPay' ||
                                                            provider.name === 'Redsys'
                                                        "
                                                        [value]="provider.id"
                                                        >{{ provider.name }}</option
                                                    >
                                                </ng-template>
                                            </select>
                                            <small
                                                class="text-danger"
                                                *ngIf="
                                                    queForm?.controls.gateway.invalid &&
                                                    queForm?.controls.gateway.touched &&
                                                    queForm?.controls.gateway.errors?.required
                                                "
                                            >
                                                Gateway is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row form-group">
                                        <label for="" class="col-4 col-form-label text-primary">Amount</label>
                                        <div class="col-8">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input
                                                            type="radio"
                                                            name="amountOrQuoata"
                                                            value="amount"
                                                            [(ngModel)]="amountOrQuoata"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            (ngModelChange)="onAmountOrQuoataChange($event)"
                                                        />
                                                    </div>
                                                </div>
                                                <input
                                                    formControlName="amount"
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="Amount"
                                                />
                                            </div>
                                            <small
                                                class="text-danger"
                                                *ngIf="
                                                    queForm?.controls.amount.invalid &&
                                                    queForm?.controls.amount.touched &&
                                                    queForm?.controls.amount.errors?.required
                                                "
                                            >
                                                Amount is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row form-group">
                                        <label for="" class="col-4 col-form-label text-primary">Quotas</label>
                                        <div class="col-8">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input
                                                            type="radio"
                                                            value="quotas"
                                                            [(ngModel)]="amountOrQuoata"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            (ngModelChange)="onAmountOrQuoataChange($event)"
                                                        />
                                                    </div>
                                                </div>
                                                <select formControlName="quotas" name="quotas" class="form-control">
                                                    <option value="all">All</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row form-group">
                                        <label for="" class="col-4 col-form-label text-primary">Credit Card</label>
                                        <div class="col-8">
                                            <select
                                                formControlName="credit_card"
                                                name="gateway_id"
                                                class="form-control"
                                            >
                                                <option value="all">All</option>
                                                <option
                                                    *ngFor="let key of (filterLists?.cardStatus?.list | keys)"
                                                    value="{{ key }}"
                                                    >{{ (filterLists?.cardStatus?.list)[key] }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary" [disabled]="queForm.invalid">
                                            Load Que
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div *ngIf="step === 1" class="col-12 mb-4">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <dni>
                            <div class="card mb-3">
                                <div class="card-header">
                                    <h5 class="card-header-title text-center">Date</h5>
                                </div>
                                <div class="card-body">
                                    <p class="text-center">2019-02-06 15:18:01</p>
                                </div>
                            </div>
                        </dni>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <dni>
                            <div class="card mb-3">
                                <div class="card-header">
                                    <h5 class="card-header-title text-center">Loans Processed</h5>
                                </div>
                                <div class="card-body">
                                    <p class="text-center">1.0000</p>
                                </div>
                            </div>
                        </dni>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <dni>
                            <div class="card mb-3">
                                <div class="card-header">
                                    <h5 class="card-header-title text-center">Loans Done</h5>
                                </div>
                                <div class="card-body">
                                    <p class="text-center">2</p>
                                </div>
                            </div>
                        </dni>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <dni>
                            <div class="card mb-3">
                                <div class="card-header">
                                    <h5 class="card-header-title text-center">Amount Collected</h5>
                                </div>
                                <div class="card-body">
                                    <p class="text-center">50.45€</p>
                                </div>
                            </div>
                        </dni>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-3">
                <div class="row align-items-center">
                    <div class="col-12 col-md-auto"><h5 class="mb-0">Recovery Collection Queue</h5></div>
                    <div class="col">
                        <button
                            *ngIf="step === 0"
                            [disabled]="queList.length === 0"
                            class="btn btn-success"
                            (click)="changeStep(1)"
                        >
                            Process Collection Queue
                        </button>
                        <button *ngIf="step === 1" class="btn btn-warning" (click)="openProcess(choose)">
                            Process Status Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="step === 0 && queList.length" class="table-responsive" style="max-height: 350px">
        <p>Debug. Load Ques with data:</p>
        {{ formData | json }}
        <table class="table table-hover">
            <thead>
                <th>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                        <label class="form-check-label" for="defaultCheck1"></label>
                    </div>
                </th>
                <th>Code</th>
                <th>Collection Date</th>
                <th>Status</th>
                <th>Collection Amount</th>
                <th>Due Access</th>
                <th>Credit Amount</th>
                <th>Total Debt</th>
                <th>Initial Debt Left</th>
                <th>Current Debt Left</th>
                <th>Recovered</th>
                <th>BankCard Status</th>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" />
                            <label class="form-check-label" for="defaultCheck1"></label>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="step === 1" class="table-responsive" style="max-height: 350px">
        <table class="table table-hover">
            <thead>
                <th>Collection Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Code</th>
                <th>Customer</th>
                <th>Credit Amount</th>
                <th>Total Debt</th>
                <th>Initial Debt Left</th>
                <th>Debt Left</th>
                <th>Recovered</th>
                <th>BankCard Status</th>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template *ngIf="step === 1" #choose let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Select and Process
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="c()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-row form-group">
                        <label for="" class="col-4 col-form-label text-primary">Amount</label>
                        <div class="col-8">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio" name="amountOrQuoata" checked />
                                    </div>
                                </div>
                                <input type="text" class="form-control" placeholder="Amount" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-row form-group">
                        <label for="" class="col-4 col-form-label text-primary">Quotas</label>
                        <div class="col-8">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio" name="amountOrQuoata" />
                                    </div>
                                </div>
                                <select name="job_role_id" class="form-control" disabled>
                                    <option value="">Select</option>
                                    <option value="all">All</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <button class="btn btn-primary" (click)="changeStep(0)">Process</button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
