<i
    style="font-size: 2em; cursor: pointer"
    class="fa fa-minus-circle text-primary"
    (click)="open(modal, payment)"
></i>

<ng-template #modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Payment Rectification
            <small class="text-primary">{{ payment?.id }}</small>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body">
            <div class="form-group">
                <label for="amount">Amount:</label>
                <input
                    id="amount"
                    type="text"
                    name="amount"
                    class="form-control"
                    [(ngModel)]="amount"
                    readonly
                />
            </div>
            <div class="form-group">
                <label for="date">Date:</label>
                <input
                    id="date"
                    name="date"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Choose date"
                    daterangepicker
                    [options]="singlePicker"
                    (applyDaterangepicker)="selectedDateSingle($event)"
                    autocomplete="off"
                    [(ngModel)]="date"
                    #dateControl="ngModel"
                />
                <small
                    class="text-danger text-danger--left"
                    *ngIf="dateControl.invalid && dateControl.touched"
                >
                    Date is required.
                </small>
            </div>
            <div class="form-group mb-0">
                <label for="type">Type:</label>
                <select
                    id="type"
                    class="form-control"
                    [(ngModel)]="type"
                    name="type"
                    #typeControl="ngModel"
                    required
                >
                    <option value="">Select type</option>
                    <option *ngFor="let key of types | keys" [value]="key">
                        {{ types[key] }}
                    </option>
                </select>
                <small
                    class="text-danger text-danger--left"
                    *ngIf="typeControl.invalid && typeControl.touched"
                >
                    Type is required.
                </small>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                [disabled]="form?.invalid || isSubmiting"
                class="btn btn-primary"
            >
                {{ isSubmiting ? 'Saving...' : 'Add' }}
            </button>
        </div>
    </form>
</ng-template>
