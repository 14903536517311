<!-- user info tabpage -->
<section>
    <div class="tab-content" id="pills-tabContent">
        <!-- user info tab page -->

        <section>
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <app-lead-info-basic-info
                                [loan_id]="loan_id"
                                [user]="lead?.user"
                                [editInfo]="editInfo"
                            ></app-lead-info-basic-info>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <app-lead-info-contact-info
                                [lead]="lead"
                                [loan_id]="loan_id"
                                [editInfo]="editInfo"
                            ></app-lead-info-contact-info>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <app-lead-info-address-info
                                [loan_id]="loan_id"
                                [lead]="lead"
                                [editInfo]="editInfo"
                            ></app-lead-info-address-info>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <app-lead-info-bank-aggregation-detail
                                [bankAggregation]="bank_aggregation"
                            ></app-lead-info-bank-aggregation-detail>
                        </div>
                        <div class="col-sm-6">
                            <app-lead-info-third-party></app-lead-info-third-party>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row">
                <div class="col-md-12">
                    <app-lead-info-contract-tracking
                        [contact_tracking]="contact_tracking"
                    ></app-lead-info-contract-tracking>
                </div>
            </div>
        </section>
        <!-- <section>
            <div class="row">
                <div class="col-md-12"><app-lead-info-address-tracking></app-lead-info-address-tracking></div>
            </div>
        </section> -->

        <!-- user info tab page -->
    </div>
</section>
