import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, SubscriptionLike as ISubscription } from 'rxjs';
import { KpiService } from '../../../services/kpi.service';

declare const moment: any;

@Component({
    selector: 'app-kpis-profitability',
    templateUrl: './kpis-profitability.component.html',
    styleUrls: ['./kpis-profitability.component.scss'],
})
export class KpisProfitabilityComponent implements OnInit, OnDestroy {
    querySubscriber: ISubscription;
    totalSubscriber: ISubscription;
    forkJoinSubscription: ISubscription;

    startData: any = {};
    filters;
    queryString = '';

    loading = false;
    total: any = null;
    startPortfolio: any = null;
    portfolio: any = null;
    portfolioTotal: any = null;

    minDate = '2015-01-01';
    maxDate = moment().format('YYYY-MM-DD');

    years = [];

    settings: any = {
        locale: {
            format: 'YYYY-MM-DD',
            cancelLabel: 'Clear',
            firstDay: 1,
        },
        alwaysShowCalendars: true,
        showDropdowns: true,
        singleDatePicker: true,
        minDate: moment().startOf('year').format('YYYY-MM-DD'),
        maxDate: this.maxDate,
    };

    monthes = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septienbre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private readonly kpiService: KpiService
    ) {}

    ngOnInit(): void {
        this.years = this.createYears();
        this.createPortfolio();

        this.totalSubscriber = this.kpiService
            .getProfitabilityTotal()
            .subscribe((response) => {
                if (response) {
                    this.total = response;
                }
            });

        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    portfolio_date_start: moment()
                        .startOf('year')
                        .format('YYYY-MM-DD'),
                    portfolio_date_end: moment().format('YYYY-MM-DD'),
                    variable_date_end: moment().format('YYYY-MM-DD'),
                    year: moment().format('YYYY'),
                };

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getData();
            }
        );
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.forkJoinSubscription.unsubscribe();
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getData() {
        this.loading = true;

        const portfolio = this.kpiService.getProfitabilityPortfolio(
            this.queryString
        );

        this.forkJoinSubscription = forkJoin([portfolio]).subscribe(
            ([portfolioResponse]: any) => {
                if (portfolioResponse) {
                    this.mapPortfolio(portfolioResponse?.data);
                    this.portfolioTotal = {
                        amount: portfolioResponse?.total_amount || 0,
                        debt_total: portfolioResponse?.total_debt_total || 0,
                        paid: portfolioResponse?.total_paid || 0,
                        revenue: portfolioResponse?.total_revenue || 0,
                        revenue_percent:
                            portfolioResponse?.total_revenue_percent || 0,
                        debt_left: portfolioResponse?.total_debt_left || 0,
                        debt_left_percent:
                            portfolioResponse?.total_debt_left_percent || 0,
                        tm: portfolioResponse?.total_tm || 0,
                    };

                    this.loading = false;
                }
            }
        );
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query: any = {}) {
        this.settings.startDate = query?.variable_date_end;
        this.settings.endDate = query?.variable_date_end;
        this.settings.minDate = query?.portfolio_date_start;
        this.filters = { ...this.startData, ...query };
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    createYears() {
        const startYear = moment(this.minDate).format('YYYY');
        let endYear = moment(this.maxDate).format('YYYY');
        const years = [];

        while (endYear >= startYear) {
            years.push(endYear--);
        }

        return years;
    }

    onChangeYear(e) {
        const value = e?.target?.value;
        const endDate =
            +moment().format('YYYY') === +value
                ? this.maxDate
                : moment().year(value).endOf('year').format('YYYY-MM-DD');

        const range = {
            portfolio_date_start: moment()
                .year(value)
                .startOf('year')
                .format('YYYY-MM-DD'),
            portfolio_date_end: endDate,
            variable_date_end: endDate,
        };

        this.settings.minDate = range.portfolio_date_start;
        this.settings.startDate = range.portfolio_date_end;
        this.settings.endDate = range.variable_date_end;

        this.onChangeDate(range);
    }

    selectedDate(e) {
        const oldDate = this.filters.variable_date_end;
        const date = e.picker.startDate.format('YYYY-MM-DD');

        if (oldDate !== date) {
            this.onChangeDate({ variable_date_end: date });
        }
    }

    clearCalendar() {
        const oldDate = this.startData.variable_date_end;
        const date = this.filters.variable_date_end;

        if (oldDate !== date) {
            this.onChangeDate({ variable_date_end: oldDate });
        }
    }

    createPortfolio() {
        this.startPortfolio = this.monthes.map((item, index) => ({
            amount: 0,
            debt_total: 0,
            paid: 0,
            revenue: 0,
            revenue_percent: 0,
            debt_left: 0,
            debt_left_percent: 0,
            tm: 0,
            month: index + 1,
            monthLabel: item,
        }));
    }

    mapPortfolio(data: any = []) {
        this.portfolio = this.startPortfolio.map((item) => {
            const dataItem = data.filter((row) => +row.month === +item.month);

            if (dataItem.length) {
                item = { ...item, ...dataItem[0] };
            }

            return item;
        });
    }
}
