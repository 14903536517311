<div *appHasPermissions="['debtrecovery-due-assignation']" class="container">
    <h2>Due Assignation</h2>
    <div *ngIf="!loading" class="row">
        <div class="col-12 mb-3">
            <div class="card">
                <div class="card-header pr-3 pl-3"><h5 class="text-uppercase mb-0">Portfolio Rotation</h5></div>
                <div class="card-body pt-3 pl-3 pr-3 pb-0">
                    <form #f="ngForm" class="row" (ngSubmit)="submitPortfolio(f, portfolioRotation)" novalidate>
                        <div class="col-12 col-md-6">
                            <div class="form-group form-row">
                                <label for="from" class="col-3 col-lg-2 col-form-label text-primary">FROM</label>
                                <div class="col-9 col-lg-8">
                                    <select
                                        id="from"
                                        name="debt_collector_id_from"
                                        class="form-control"
                                        validateIsNot="debt_collector_id_to"
                                        reverse="true"
                                        [(ngModel)]="portfolioRotation.debt_collector_id_from"
                                        #from="ngModel"
                                        required
                                    >
                                        <option value="">Select collector</option>
                                        <option *ngFor="let user of users?.actived" value="{{ user?.id }}">
                                            {{ user?.name }}
                                        </option>
                                        <option *ngIf="users?.blocked.length > 0" disabled>──────────</option>
                                        <option *ngFor="let user of users?.blocked" value="{{ user?.id }}">
                                            {{ user?.name }}
                                        </option>
                                    </select>
                                    <small *ngIf="from?.errors && from?.touched" class="text-danger">
                                        Select collector
                                    </small>
                                    <small
                                        *ngIf="from?.errors && from?.touched && from?.errors?.validateIsNot"
                                        class="text-danger"
                                    >
                                        Select another collector
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group form-row">
                                <label for="to" class="offset-lg-2 col-3 col-lg-2 col-form-label text-primary"
                                    >TO</label
                                >
                                <div class="col-9 col-lg-8">
                                    <select
                                        id="to"
                                        name="debt_collector_id_to"
                                        class="form-control"
                                        validateIsNot="debt_collector_id_from"
                                        [(ngModel)]="portfolioRotation.debt_collector_id_to"
                                        #to="ngModel"
                                        required
                                    >
                                        <option value="">Select collector</option>
                                        <option *ngFor="let user of users?.actived" value="{{ user?.id }}">
                                            {{ user?.name }}
                                        </option>
                                    </select>
                                    <small
                                        *ngIf="to?.errors && to?.touched && !to?.errors?.validateIsNot"
                                        class="text-danger"
                                    >
                                        Select collector
                                    </small>
                                    <small
                                        *ngIf="to?.errors && to?.touched && to?.errors?.validateIsNot"
                                        class="text-danger"
                                    >
                                        Select another collector
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group form-row">
                                <label for="status" class="col-4 col-form-label text-primary">STATUS TRACKING</label>
                                <div class="col-8">
                                    <select
                                        id="status"
                                        name="tracking_type_id"
                                        class="form-control"
                                        [(ngModel)]="portfolioRotation.tracking_type_id"
                                        #status="ngModel"
                                    >
                                        <option value="">Select status</option>
                                        <option
                                            *ngFor="let key of filterLists?.traking?.list?.types | keys"
                                            value="{{ key }}"
                                            >{{ (filterLists?.traking?.list?.types)[key] }}</option
                                        >
                                    </select>
                                    <!-- <small *ngIf="status.invalid && status?.touched" class="text-danger">
                                        Select status
                                    </small> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12 col-md-6 text-right">
                            <button type="submit" [disabled]="f.invalid" class="btn btn-primary">Process</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-header"><h5 class="text-uppercase mb-0">Specific Ones</h5></div>
                <div class="card-body pt-3 pl-3 pr-3 pb-0">
                    <form
                        #fSpecific="ngForm"
                        class="row"
                        (ngSubmit)="submitPortfolio(fSpecific, specificOnes)"
                        novalidate
                    >
                        <div class="col-12 col-md-7">
                            <div class="form-group">
                                <label class="text-primary">Select loans to assign</label>
                                <ng-select
                                    name="loan_ids"
                                    [items]="loansList | async"
                                    [multiple]="true"
                                    [notFoundText]="'No loans found'"
                                    [loading]="loanLoading"
                                    [typeahead]="loanInput"
                                    [hideSelected]="true"
                                    bindLabel="code_str"
                                    bindValue="id"
                                    placeholder="Select loans"
                                    [(ngModel)]="specificOnes.loan_ids"
                                    #loans="ngModel"
                                    required
                                >
                                </ng-select>
                                <small *ngIf="loans?.invalid && loans?.touched" class="text-danger">
                                    Choose loan/s
                                </small>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 d-flex justify-content-end flex-wrap align-content-between">
                            <div class="form-group w-100">
                                <label for="assignedTo" class="text-primary">Assigned To</label>
                                <select
                                    id="assignedTo"
                                    name="debt_collector_id_to"
                                    class="form-control"
                                    [(ngModel)]="specificOnes.debt_collector_id_to"
                                    #assignedTo="ngModel"
                                    required
                                >
                                    <option value="">Select collector</option>
                                    <option *ngFor="let user of users?.actived" value="{{ user?.id }}">
                                        {{ user?.name }}
                                    </option>
                                </select>
                                <small *ngIf="assignedTo?.invalid && assignedTo?.touched" class="text-danger">
                                    Select collector
                                </small>
                            </div>
                            <div class="form-group text-right">
                                <button type="submit" [disabled]="fSpecific.invalid" class="btn btn-primary">
                                    Process
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
