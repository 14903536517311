import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kpis-one-view',
    templateUrl: './kpis-one-view.component.html',
    styleUrls: ['./kpis-one-view.component.scss']
})
export class KpisOneViewComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
