import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SmsService {

  constructor(private http:HttpClient) {}
  
    getByLeadId(lead_id): Observable<any>{
      return this.http.get(environment.api_url + `/lead/${lead_id}/sms/list`);
    }
    getMessageType(): Observable<any>{
      return this.http.get(environment.api_url + `/sms_type/list`);
    }
}
