import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-lead-info-savso-validation',
    templateUrl: './lead-info-savso-validation.component.html',
    styleUrls: ['./lead-info-savso-validation.component.css']
})
export class LeadInfoSavsoValidationComponent implements OnInit {
    @Input() milinea_customer: any = null;

    constructor() {}

    ngOnInit() {}
}
