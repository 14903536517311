import {
    Component,
    OnInit,
    Input,
    ElementRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    SimpleChange
} from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';

const formStartEnd: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('start').value;
    const end = fg.get('end').value;

    return (end !== 0 && !end) || start <= end ? null : { startEnd: true };
};

@Component({
    selector: 'app-dropdown-range',
    templateUrl: './dropdown-range.component.html',
    styleUrls: ['./dropdown-range.component.scss'],
    host: {
        '(document:click)': 'onOutsideClickClick($event)',
        '(document:focusin)': 'onOutsideClickClick($event)',
        '(document:mousedown)': 'onOutsideClickClick($event)',
        '(document:touchend)': 'onOutsideClickClick($event)'
    }
})
export class DropdownRangeComponent implements OnInit, OnChanges {
    @Input() placeholder: string = 'Debt Left';
    @Input() name: string = 'debt_left';
    @Input() reset: boolean = false;
    @Input() start: number;
    @Input() end: number;
    @Output()
    changeRange: EventEmitter<any> = new EventEmitter();

    isOpen: boolean = false;
    inputRangeValue: string = '';

    rangeStart: string;
    rangeEnd: string;

    range = {};

    form: FormGroup;

    constructor(private _eref: ElementRef, private fb: FormBuilder) {}

    ngOnInit() {
        this.rangeStart = this.name + '_start';
        this.rangeEnd = this.name + '_end';

        this.range = {
            [this.rangeStart]: this.start || null,
            [this.rangeEnd]: this.end || null
        };

        this.form = this.fb.group(
            {
                start: [this.range[this.rangeStart], [Validators.required, Validators.min(0)]],
                end: [this.range[this.rangeEnd], Validators.min(0)]
            },
            { validator: formStartEnd }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        const reset: SimpleChange = changes.reset;

        if (reset && reset.previousValue) {
            this.onReset(reset.previousValue);
        }

        this.range = {
            [this.rangeStart]: this.start || null,
            [this.rangeEnd]: this.end || null
        };

        if ((this.range[this.rangeStart] || this.range[this.rangeStart] === 0) && this.range[this.rangeEnd]) {
            this.inputRangeValue = this.range[this.rangeStart];
            this.inputRangeValue +=
                (this.range[this.rangeStart] || this.range[this.rangeStart] === 0) && this.range[this.rangeEnd]
                    ? ' - '
                    : '';
            this.inputRangeValue += this.range[this.rangeEnd] ? this.range[this.rangeEnd] : '';
        } else {
            this.inputRangeValue = '';
        }
    }

    open() {
        if (!this.isOpen) this.isOpen = true;
    }

    close() {
        if (this.isOpen) this.isOpen = false;
    }

    onOutsideClickClick(event) {
        if (!this._eref.nativeElement.contains(event.target)) this.close();
    }

    onSubmit() {
        if ((this.range[this.rangeStart] || this.range[this.rangeStart] === 0) && this.range[this.rangeEnd]) {
            this.inputRangeValue = this.range[this.rangeStart];
            this.inputRangeValue +=
                (this.range[this.rangeStart] || this.range[this.rangeStart] === 0) && this.range[this.rangeEnd]
                    ? ' - '
                    : '';
            this.inputRangeValue += this.range[this.rangeEnd] ? this.range[this.rangeEnd] : '';
        }

        this.emitChange(this.range);
        this.close();
    }

    onReset(reset) {
        if (Object.values(this.range).filter(item => item === 0 || item).length > 0) {
            this.form.reset({ start: null, end: null });
            this.range = {
                [this.rangeStart]: null,
                [this.rangeEnd]: null
            };

            if (this.inputRangeValue) {
                this.inputRangeValue = '';
                if (!reset) {
                    this.emitChange(this.range);
                }
            }
        }

        this.close();
        return;
    }

    emitChange(range) {
        if (!range[this.rangeEnd] && (range[this.rangeStart] || range[this.rangeStart] === 0)) {
            range[this.rangeEnd] = range[this.rangeStart];
        }
        this.changeRange.emit(range);
    }
}
