<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">Lead info</h5>
    </div>
    <div class="card-body">
        <img
            src="assets/img/backoffice/loading.gif"
            width="50px"
            height="50px"
            *ngIf="loading"
        />
        <div class="row">
            <div class="col-12 col-sm-6">
                <ul class="list-group" *ngIf="!loading">
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Created</strong
                        ><span>{{ lead?.created_at }}</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Amount Request</strong
                        ><span>{{
                            lead?.amount_requested | currency: 'EUR'
                        }}</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Weeks Request</strong
                        ><span>{{ lead?.product?.weeks }}</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Am. Week request</strong
                        ><span>{{
                            lead?.product?.cost?.weekly | currency: 'EUR'
                        }}</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>IP Address</strong><span>{{ lead?.ip }}</span>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-sm-6">
                <ul class="list-group" *ngIf="!loading">
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Loan Purpose</strong><span>?</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Amount Approved</strong
                        ><span>{{
                            lead?.amount_approved | currency: 'EUR'
                        }}</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Weeks Approved</strong><span>?</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Debt Total</strong><span>?</span>
                    </li>
                    <li
                        class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <strong>Amount week</strong><span>?</span>
                    </li>
                </ul>
            </div>
            <div class="col-12">
                <hr />
            </div>
            <div *ngIf="!loading" class="col-12">
                <ul class="list-group">
                    <li class="list-group-item">
                        <strong>Source: </strong>
                        {{ lead?.source || 'Organic' }}
                    </li>
                    <li class="list-group-item">
                        <strong>Medium: </strong>
                        {{ lead?.medium || '-' }}
                    </li>
                    <li class="list-group-item">
                        <strong>Campaign: </strong>
                        {{ lead?.campaign || '-' }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
