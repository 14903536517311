<div class="range">
    <input
        type="text"
        [name]="name"
        [placeholder]="placeholder"
        [value]="inputRangeValue"
        readonly
        (focus)="open()"
        (click)="open()"
    />
    <div class="range__dropdown" *ngIf="isOpen">
        <form class="p-2" [formGroup]="form" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <div class="form-group mb-2">
                <input
                    id="from"
                    name="start"
                    [(ngModel)]="range[rangeStart]"
                    formControlName="start"
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    placeholder="From"
                    autocomplate="off"
                />
            </div>
            <div class="form-group mb-2">
                <input
                    id="to"
                    name="end"
                    [(ngModel)]="range[rangeEnd]"
                    formControlName="end"
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    placeholder="To"
                />
            </div>
            <button class="btn btn-sm btn-success" type="submit" [disabled]="form.invalid">
                Apply
            </button>
            <button class="btn btn-sm btn-default" type="button" (click)="onReset()">
                Clear
            </button>
        </form>
    </div>
</div>
