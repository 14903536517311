import { Component, OnInit } from '@angular/core';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { DiscardCriterionService } from '../../../services/discard-criterion.service';

@Component({
    selector: 'app-setting-validation-discard-criterion',
    templateUrl: './setting-validation-discard-criterion.component.html',
    styleUrls: ['./setting-validation-discard-criterion.component.scss']
})
export class SettingValidationDiscardCriterionComponent implements OnInit {
    forms: any[] = null;
    saving = false;
    validator_minimun_income = 1;
    validator_blacklisted = 2;
    validator_due = 3;
    validator_age = 4;
    validator_nie = 5;
    validator_job_role = 6;
    validator_zip_code = 7;

    validator_limit_debt_detail = 2;
    constructor(private errorMessage: ErrorMessageService, private discardCriterionService: DiscardCriterionService) {}
    ngOnInit() {
        this.discardCriterionService.getConfigValidatorList().subscribe(validators => {
            if (validators) {
                this.forms = validators;
            }
        });
    }

    save() {
        this.saving = true;

        if (this.forms.length > 0) {
            this.forms.forEach(form => {
                this.discardCriterionService.updateConfigValidator(form).subscribe(
                    resp => {
                        if (resp) {
                            this.errorMessage.showSuccess(`El validador ${form.description} ha sido actualizado`);
                        } else {
                            this.errorMessage.showError(
                                `No se puedo actualizar el validador ${form.description} debido a un error`
                            );
                        }
                    },
                    error => {
                        this.errorMessage.showError(error.error.error.show_message);
                    }
                );
            });
        }

        this.saving = false;
    }
}
