<div class="container pt-3" *appHasPermissions="['kpis-business']">
    <div class="row justify-content-between mb-3">
        <div class="col-auto">
            <app-daterange-filter
                fieldName="date"
                [start]="filters.date_start"
                [end]="filters.date_end"
                (changeDate)="onChangeDate($event)"
                [disabled]="loading"
                [resetDates]="{ startDate: minDate, endDate: maxDate }"
            ></app-daterange-filter>
            <small *ngIf="loading" class="ml-2 text-primary">Loading...</small>
        </div>
        <div class="col-auto">
            <small *ngIf="graphLoading" class="ml-2 text-primary"
                >Loading...</small
            >
            <select
                name="time_unit"
                (change)="getGraphDataInit()"
                [(ngModel)]="time_unit"
                [disabled]="graphLoading"
            >
                <option value="month">Mensual</option>
                <option value="week">Semanal</option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-7">
            <h5 class="text-primary text-uppercase mb-2">Dinero generado</h5>
            <div class="card mb-4">
                <div
                    class="card-body py-0"
                    style="padding-left: 15px; padding-right: 14px"
                >
                    <div class="row">
                        <div class="col border-right pt-3">
                            <div class="text-center">
                                <h6 class="text-uppercase">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Cobros</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-2 text-center">
                                    {{
                                        charges?.total
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}
                                </h4>
                                <div class="row mb-3">
                                    <div
                                        class="col-12 bg-primary text-white py-2"
                                    >
                                        <small class="text-uppercase"
                                            >Media por día</small
                                        >
                                        <h5 class="mb-0">
                                            {{
                                                charges?.media_by_day
                                                    | currency
                                                        : 'EUR'
                                                        : 'symbol'
                                                        : '1.0-0'
                                            }}
                                        </h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-left">
                                        <table
                                            class="table m-0 table-borderless table-sm"
                                        >
                                            <tr>
                                                <td class="pl-0 pt-0">Leads</td>
                                                <td class="pt-0">
                                                    <h5
                                                        class="mb-0 text-primary text-right"
                                                    >
                                                        {{
                                                            charges?.lead
                                                                | currency
                                                                    : 'EUR'
                                                                    : 'symbol'
                                                                    : '1.0-0'
                                                        }}
                                                    </h5>
                                                </td>
                                                <td
                                                    class="pt-0 pr-0 text-right"
                                                >
                                                    <small
                                                        class="d-inline-block text-primary"
                                                        >{{
                                                            charges?.lead_percent
                                                                | number
                                                                    : '1.0-0'
                                                                    : 'es'
                                                        }}%</small
                                                    >
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0">Collection</td>
                                                <td>
                                                    <h5
                                                        class="mb-0 text-primary text-right"
                                                    >
                                                        {{
                                                            charges?.collection
                                                                | currency
                                                                    : 'EUR'
                                                                    : 'symbol'
                                                                    : '1.0-0'
                                                        }}
                                                    </h5>
                                                </td>
                                                <td class="pr-0 text-right">
                                                    <small
                                                        class="d-inline-block text-primary"
                                                        >{{
                                                            charges?.collection_percent
                                                                | number
                                                                    : '1.0-0'
                                                                    : 'es'
                                                        }}%</small
                                                    >
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 pb-0">Due</td>
                                                <td class="pb-0">
                                                    <h5
                                                        class="mb-0 text-primary text-right"
                                                    >
                                                        {{
                                                            charges?.due
                                                                | currency
                                                                    : 'EUR'
                                                                    : 'symbol'
                                                                    : '1.0-0'
                                                        }}
                                                    </h5>
                                                </td>
                                                <td
                                                    class="pr-0 pb-0 text-right"
                                                >
                                                    <small
                                                        class="d-inline-block text-primary"
                                                        >{{
                                                            charges?.due_percent
                                                                | number
                                                                    : '1.0-0'
                                                                    : 'es'
                                                        }}%</small
                                                    >
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col border-right pt-3">
                            <div class="text-center">
                                <h6 class="text-uppercase">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Inversión Creditica</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-2 text-center">
                                    {{
                                        emmited?.amount
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}
                                </h4>
                                <div class="row mb-3">
                                    <div
                                        class="col-12 bg-primary text-white py-2"
                                    >
                                        <small class="text-uppercase"
                                            >Media por día</small
                                        >
                                        <h5 class="mb-0">
                                            {{
                                                emmited?.media_by_day
                                                    | currency
                                                        : 'EUR'
                                                        : 'symbol'
                                                        : '1.0-0'
                                            }}
                                        </h5>
                                    </div>
                                </div>
                                <h6 class="text-uppercase mb-1">
                                    <span class="d-inline-block"
                                        >Préstamos</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-2 text-center">
                                    {{ emmited?.count | number: '1.0-0' }}
                                </h4>
                                <div class="row">
                                    <div
                                        class="col-12 bg-primary text-white py-2"
                                    >
                                        <small class="text-uppercase"
                                            >Préstamos medio</small
                                        >
                                        <h5 class="mb-0">
                                            {{
                                                emmited?.amount_media
                                                    | number: '1.0-0'
                                            }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col pt-3">
                            <div class="text-center">
                                <h6 class="text-uppercase">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Diferencia</span
                                    >
                                </h6>
                                <h4 class="text-primary mb-2 text-center">
                                    {{
                                        difference?.total_amount
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}
                                </h4>
                                <div class="row">
                                    <div
                                        class="col-12 bg-primary text-white py-2"
                                    >
                                        <small class="text-uppercase"
                                            >Media por día</small
                                        >
                                        <h5 class="mb-0">
                                            {{
                                                difference?.total_amount_media_by_day
                                                    | currency
                                                        : 'EUR'
                                                        : 'symbol'
                                                        : '1.0-0'
                                            }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="text-primary text-uppercase mb-2">Facturación</h5>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 col-md text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Ventas</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        billing?.total
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Leads Fee</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        billing?.lead_fee
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="ml-3 d-inline-block"
                                        >{{
                                            billing?.lead_fee_percent
                                                | number: '1.0-0'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Fees</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        billing?.fee
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="d-inline-block ml-3"
                                        >{{
                                            billing?.fee_percent
                                                | number: '1.0-0':'es'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md text-center">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Late Fees</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        billing?.late_fee
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="d-inline-block ml-3"
                                        >{{
                                            billing?.late_fee_percent
                                                | number: '1.0-0':'es'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-5">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Cobros Totales
                    </h5>
                    <div class="chart">
                        <canvas #totalChart></canvas>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Cobros Collection
                    </h5>
                    <div class="chart">
                        <canvas #collectionChart></canvas>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Cobros DUE
                    </h5>
                    <div class="chart">
                        <canvas #dueChart></canvas>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Cobros Leads
                    </h5>
                    <div class="chart">
                        <canvas #leadsChart></canvas>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Inversión Creditica
                    </h5>
                    <div class="chart">
                        <canvas #inversionChart></canvas>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-right text-uppercase mb-3">
                        Diferencia
                    </h5>
                    <div class="chart">
                        <canvas #differenceChart></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
