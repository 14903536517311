<nav class="navbar fixed-top navbar-expand-lg navbar-milinea bg-green-dark"> 
    <span class="navbar-brand p-0"><img width="100" src="assets/img/logo@3x.png" alt="savso logo"></span>
</nav>

<main>
    <!-- content wrap -->
    <div class="container">
        <section id="loginform">
            <div class="row">
                <div class="offset-sm-2 col-sm-8 offset-md-3 col-md-6">
                    <div class="card text-white bg-secondary">
                        <div class="card-body">
                            <h5 class="text-center">Login Admin</h5>
                            <form novalidate (ngSubmit)="login(loginForm.value)" [formGroup]="loginForm">
                                <div class="form-row">
                                    <div class="form-group col-sm-7 offset-md-1 col-md-6">
                                        <label for="username">Identificación</label>
                                        <input type="text" class="form-control" formControlName="username" id="username" name="username"
                                            placeholder="usuario" style="width: 100%" required />
                                    </div>
                                </div>
                                <div class="form-row align-items-end">
                                    <div class="form-group col-sm-7 offset-md-1 col-md-6">
                                        <label for="password">contraseña</label>
                                        <input type="password" class="form-control" formControlName="password" id="password" placeholder="contraseña"
                                            required />
                                    </div>
                                    <div class="form-group col-sm-5 col-md-4 text-center">
                                        <button type="submit" class="btn btn-primary w-100" [disabled]="loginForm.invalid">Entrar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
    <!-- content wrap end -->
</main>

