import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter,
} from '@angular/core';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { take } from 'rxjs/operators';
import { ErrorMessageService } from '../../../../../shared/error-message.service';

interface NodeCreateModel {
  amount?: string;
  success?: boolean;
  failure?: boolean;
  success_node_id?: string | number;
  failure_node_id?: string | number;
}

@Component({
  selector: '[app-payment-tree-node]',
  templateUrl: './payment-tree-node.component.html',
  styleUrls: ['./payment-tree-node.component.scss'],
})
export class PaymentTreeNodeComponent implements OnInit, OnChanges {
  @Input() node;
  @Input() nodes;
  @Input() deletedId;

  @Output() deleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() updated: EventEmitter<any> = new EventEmitter();

  isEditing = false;
  isSending = false;

  startModel: NodeCreateModel;

  model: NodeCreateModel = {
    amount: '',
    success: false,
    failure: false,
    success_node_id: '',
    failure_node_id: '',
  };

  constructor(
    private debtRecoveryService: DebtRecoveryService,
    private errorService: ErrorMessageService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const nodeChange: SimpleChange = changes.node;
    const deletedIdChange: SimpleChange = changes.deletedId;

    if (nodeChange && nodeChange.currentValue) {
      this.setStartModel(nodeChange.currentValue);
    }

    if (deletedIdChange && deletedIdChange.currentValue) {
      this.startModel.success_node_id =
        Number(deletedIdChange.currentValue) ===
        Number(this.model.success_node_id)
          ? ''
          : this.startModel.success_node_id;

      this.model.success_node_id =
        Number(deletedIdChange.currentValue) ===
        Number(this.model.success_node_id)
          ? ''
          : this.model.success_node_id;

      this.startModel.failure_node_id =
        Number(deletedIdChange.currentValue) ===
        Number(this.model.failure_node_id)
          ? ''
          : this.startModel.failure_node_id;

      this.model.failure_node_id =
        Number(deletedIdChange.currentValue) ===
        Number(this.model.failure_node_id)
          ? ''
          : this.model.failure_node_id;
    }
  }

  setStartModel(node) {
    this.startModel = {
      amount: node.amount,
      success: !!node.success,
      failure: !!node.failure,
      success_node_id: node.success_node_id || '',
      failure_node_id: node.failure_node_id || '',
    };

    this.setModel(this.startModel);
  }

  setModel(startModel: NodeCreateModel) {
    Object.keys(startModel).forEach((key) => {
      this.model[key] = startModel[key];
    });
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
    this.setModel(this.startModel);
  }

  onModelChange(model) {
    this.model[`${model}_node_id`] = '';
  }

  update(treeId, nodeId) {
    if (
      Object.entries(this.startModel).toString() !==
      Object.entries(this.model).toString()
    ) {
      this.isSending = true;
      const data: NodeCreateModel = {};

      Object.keys(this.model).forEach((key) => {
        data[key] = this.model[key];
      });
      data.success_node_id = Number(data.success_node_id) || null;
      data.failure_node_id = Number(data.failure_node_id) || null;
      this.debtRecoveryService
        .updatePaymentTreeAmountNode(treeId, nodeId, data)
        .pipe(take(1))
        .toPromise()
        .then((response) => {
          this.errorService.showSuccess('Node updated successfuly!');

          this.isSending = false;

          this.updated.emit(response);
          this.isEditing = false;
        })
        .catch((err) => {
          this.isSending = false;
          this.errorService.showError(err.error.error.show_message);
        });
    }
  }

  delete(treeId, nodeId) {
    this.isSending = true;
    if (confirm('Do you want to delete Node?')) {
      this.debtRecoveryService
        .deletePaymentTreeAmountNode(treeId, nodeId)
        .pipe(take(1))
        .toPromise()
        .then((response) => {
          if (response) {
            this.isSending = false;
            this.errorService.showSuccess('Node deleted Successfuly!');

            this.deleted.emit(nodeId);
          }
        })
        .catch((err) => {
          this.isSending = false;
          if (err) {
            this.errorService.showError(err.error.error.show_message);
          } else {
            this.errorService.showError('Error Genérico');
          }
        });
    } else {
      this.isSending = false;
    }
  }
}
