import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class SaveFiltersService {
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;
    private urlParams: string = undefined;
    private filterObject: object = undefined;

    constructor(private router: Router) {
        this.currentUrl = this.router.url;

        router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e: any) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = e.url;
        });
    }

    public saveFilters(params) {
        this.urlParams = params;
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }

    public getCurrentUrl() {
        return this.currentUrl;
    }

    public getUrlParams() {
        return this.urlParams;
    }

    public getFilterObject() {
        this.filterObject = {};

        if (this.urlParams) {
            this.urlParams.split('&').forEach(item => {
                if (item) {
                    const splitString = item.split('=');
                    this.filterObject[splitString[0]] = splitString[1];
                }
            }, this);
        }

        return this.filterObject;
    }
}
