import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { PaymentTreeService } from '../../../services/payment-tree.service';

@Component({
  selector: 'app-payment-tree',
  templateUrl: './payment-tree.component.html',
  styleUrls: ['./payment-tree.component.scss'],
})
export class PaymentTreeComponent implements OnInit, OnDestroy {
  routerSubscribe: ISubscription;
  active: number;
  destroy$: Subject<boolean> = new Subject<boolean>();

  createdTree;

  constructor(private paymentTreeService: PaymentTreeService) {}

  ngOnInit() {
    this.routerSubscribe = this.paymentTreeService.active$.subscribe(
      (resp) => (this.active = resp)
    );
  }

  ngOnDestroy() {
    this.routerSubscribe.unsubscribe();
  }

  onCreated(e) {
    if (e) {
      this.createdTree = e;
    }
  }
}
