import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TrackingService {
    constructor(private http: HttpClient) {}

    getList(loan_id, page): Observable<any> {
        return this.http.get(environment.api_url + `/tracking/loan/${loan_id}/list?${page}`);
    }

    create(data): Observable<any> {
        return this.http.post(environment.api_url + `/tracking/create`, data);
    }

    filterList(): Observable<any> {
        return this.http.get(environment.api_url + `/tracking/filter/list`);
    }
}
