import { Component, OnInit, OnDestroy } from '@angular/core';
import { AffiliatesService } from '../../../services/affiliates.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

declare var moment: any;

@Component({
    selector: 'app-affiliates-summary',
    templateUrl: './affiliates-summary.component.html',
    styleUrls: ['./affiliates-summary.component.scss'],
})
export class AffiliatesSummaryComponent implements OnInit, OnDestroy {
    affiliates: {}[] = [];
    affiliatesSubscriber: ISubscription;
    querySubscriber: ISubscription;

    startData = {};

    filters: any = {};
    queryString: string = '';

    loading: boolean = true;

    resetFilters: boolean = false;
    withStartDate: boolean = false;

    totals: any = {
        received: 0,
        completed: 0,
        completed_duplicated_count: 0,
        net_completed: 0,
        percent: 0,
        to_pay: 0,
    };

    constructor(
        private affiliatesService: AffiliatesService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    created_at_start: '',
                    created_at_end: '',
                    affiliate_name: '',
                    source_name: '',
                    page: 0,
                };

                if (this.affiliatesSubscriber) {
                    this.affiliatesSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getAffiliates();
            }
        );
    }

    ngOnDestroy() {
        this.affiliatesSubscriber.unsubscribe();
        this.querySubscriber.unsubscribe();
    }

    getAffiliates() {
        this.loading = true;
        this.affiliatesSubscriber = this.affiliatesService
            .getStatistic(this.queryString)
            .subscribe((affiliates) => {
                if (affiliates) {
                    this.affiliates = affiliates;
                    this.setTotals(affiliates.data);
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };

        // Chack date filter
        this.checkDateSettings(
            this.filters.created_at_start,
            this.filters.created_at_end
        );
    }

    getDataOnPageChange(page) {
        this.filters.page = page;

        this.checkDateSettings(
            this.filters.created_at_start,
            this.filters.created_at_end
        );
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };

        this.checkDateSettings(
            this.filters.created_at_start,
            this.filters.created_at_end
        );
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    checkDateSettings(date_start, date_end) {
        this.withStartDate = !!(date_start && date_end);
    }

    setTotals(affiliates) {
        Object.keys(this.totals).forEach((key) => {
            this.totals[key] = affiliates
                .map((item) => item[key])
                .reduce((a, b) => +a + +b, 0);
        });

        this.totals.percent = this.totals.net_completed / this.totals.received;
    }
}
