<table class="table table-striped table-responsive">
    <thead>
      <tr>
        <th>FAQ</th>
        <th>Created</th>
        <th>Position</th>
        <th>Category</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let faq of faqService.faqs">
        <td>{{faq?.title}}</td>
        <td>{{faq?.created_at}}</td>
        <td>{{faq?.position}}</td>
        <td>{{faq?.category_id}}</td>
        <td><i class="fa fa-unlock" *ngIf="faq?.status===1"></i><i class="fa fa-lock" *ngIf="faq?.status===0"></i></td>
        <td><i class="fa fa-pencil" [routerLink]="'/backoffice/settings/users_overview/create_user/'+user?.id"></i></td>
      </tr>
    </tbody>
</table>