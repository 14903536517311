<ng-template [ngIf]="nodes && !error">
  <h5>Nodes attached to the Tree</h5>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Amount</th>
          <th>Success</th>
          <th>Failure</th>
          <th>Success Node</th>
          <th>Failure Node</th>
          <th class="text-right">Actions</th>
        </tr>
        <tr class="table-secondary noHover">
          <th colspan="6" class="border-0 pb-0">Create New Node:</th>
        </tr>
        <tr
          app-payment-tree-node-create
          [nodes]="nodes"
          [treeId]="treeId"
          [deletedId]="deletedId"
          class="table-secondary noHover"
          (created)="onCreate($event)"
        ></tr>
        <tr class="table-secondary noHover">
          <th colspan="6" class="border-0 pt-1">
            <span class="small text-muted">Amount 0 equals "All Debt"</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-template [ngIf]="nodes.length > 0">
          <tr
            app-payment-tree-node
            *ngFor="let node of nodes"
            [node]="node"
            [nodes]="nodes"
            [deletedId]="deletedId"
            (deleted)="onDelete($event)"
            (updated)="onUpdate($event)"
          ></tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</ng-template>
