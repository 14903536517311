import { Component, OnDestroy, OnInit } from '@angular/core';
import { FinanceService } from '../../../services/finance.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { saveAs } from 'file-saver';

declare const moment;
@Component({
    selector: 'app-provisions',
    templateUrl: './provisions.component.html',
    styleUrls: ['./provisions.component.scss'],
})
export class ProvisionsComponent implements OnInit, OnDestroy {
    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    startData: any = {};
    filters;
    clearFilters: any = {};

    loading = false;

    data: any = [];

    total = {
        amount: 0,
        amount_recovered: 0,
        net_loss: 0,
    };

    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    years = [];

    exportFile = null;
    exportLink = null;

    download = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private financeService: FinanceService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            (queryParams) => {
                this.startData = {
                    year: moment().year(),
                };

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getData();
            }
        );
        this.generateYears();
    }

    ngOnDestroy(): void {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getData() {
        this.loading = true;
        this.dataSubscriber = this.financeService
            .getProvisions(this.clearFilters)
            .subscribe(
                (response) => {
                    if (response) {
                        this.data = this.formatData(response);
                        this.total = this.formatTotal(this.data);
                        this.loading = false;
                    }
                },
                (err) => (this.loading = false),
                () => (this.loading = false)
            );
    }

    formatData(data: any[] = []) {
        if (data.length) {
            const currentYear = moment().year();
            const isCurrentYear = +currentYear === +this.filters.year;
            const currentMonth = moment().month();

            const replacData = (replacement: 0 | '-' = 0) => ({
                amount: replacement,
                amount_recovered: replacement,
                net_loss: replacement,
            });

            const array = this.months.map((month, index) => {
                const item = data.find((elem) => elem.month - 1 === index);

                const replacement =
                    isCurrentYear && index > currentMonth ? '-' : 0;

                return item
                    ? {
                          ...item,
                          amount: -item.amount,
                          net_loss: -item.amount + +item.amount_recovered,
                          month,
                      }
                    : { ...replacData(replacement), month };
            });

            return array;
        }

        return data;
    }

    formatTotal(data: any[] = []) {
        if (data.length) {
            const notDash = (val) => (val !== '-' ? +val : 0);

            const reducer = (prev, currentValue) => {
                return {
                    amount: +prev.amount + notDash(currentValue.amount),
                    amount_recovered:
                        +prev.amount_recovered +
                        notDash(currentValue.amount_recovered),
                    net_loss: +prev.net_loss + notDash(currentValue.net_loss),
                };
            };

            return data.reduce(reducer);
        }
        return;
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.clearFilters = { ...clearFilters };
    }

    generateYears() {
        const years = [];
        const dateStart = moment();
        const dateEnd = moment().year(2015);

        while (dateStart.diff(dateEnd, 'years') >= 0) {
            years.push(dateEnd.format('YYYY'));
            dateEnd.add(1, 'year');
        }

        years.reverse();
        this.years = years;
    }

    getExportLink(e, { id, month }) {
        e.preventDefault();
        this.download = true;
        this.financeService.getProvisionsExport(id).subscribe((exel) => {
            let file = new Blob([exel], {
                type: 'application/vnd.ms-excel',
            });
            this.exportFile = URL.createObjectURL(file);
            this.exportLink = this.sanitizer.bypassSecurityTrustUrl(
                this.exportFile
            );

            saveAs(
                file,
                `provisions-${month.toLowerCase()}-${this.filters.year}.xlsx`
            );

            this.download = false;
        });
    }
}
