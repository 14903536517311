import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpService {
    mobileNumber: String;

    // headers = new Headers({ 'Content-Type': 'application/json'} );
    // options = new RequestOptions({ headers: this.headers });

    constructor(private http: HttpClient) {}
    setMobile(mobile: string) {
        this.mobileNumber = mobile;
    }
    getMobile() {
        return this.mobileNumber;
    }

    requestInfo(): Observable<any> {
        return this.http
            .get(environment.api_url + '/lead/info/request' /*, this.options*/)
            .pipe(tap((data) => {}));
    }

    isRegisteredEmail(email: String): Observable<any> {
        return this.http
            .post(environment.api_url + '/email/exists', { email: email })
            .pipe(tap((data) => true));
    }

    isRegisteredUser(personalid: string): Promise<any> {
        return this.http
            .post(environment.api_url + '/auth/user/registered', {
                personalid: personalid,
            })
            .toPromise()
            .then((DNIdata) => {
                return DNIdata['exists'];
            });
    }

    saveInfo(
        Senddata: any,
        product_id: string,
        amount_requested: string
    ): Promise<any> {
        return this.http
            .post(
                environment.api_url + '/lead/info/save',
                {
                    name: Senddata.name,
                    first_surname: Senddata.first_surname,
                    second_surname: Senddata.second_surname,
                    sex: Senddata.sex,
                    birth_date:
                        Senddata.year +
                        '-' +
                        Senddata.month +
                        '-' +
                        Senddata.day,
                    job_type: Senddata.job_type,
                    personalid: Senddata.personalid.toUpperCase(),
                    street_type: Senddata.street_type,
                    street: Senddata.street,
                    street_number: Senddata.street_number,
                    street_additional: Senddata.street_additional,
                    city: Senddata.city,
                    postal_code: Senddata.postal_code,
                    mobile_phone: '34' + Senddata.mobile_phone,
                    other_phone: Senddata.other_phone,
                    product_id: product_id,
                    amount_requested: amount_requested,
                } /*, this.options*/
            )
            .toPromise()
            .then((data) => {
                localStorage.setItem('mobile', Senddata['mobile_phone']);
                //localStorage.setItem('lead_id', data['lead_id']);
                //localStorage.setItem('user_id', data['user_id']);
                //localStorage.setItem('personalid', Senddata['personalid']);
                if (data['token']) localStorage.setItem('token', data['token']);
                return data;
            });
    }

    generateCode(lead_id: string, user_id: string): Observable<any> {
        // const params = {lead_id:lead_id, user_id:user_id};

        return this.http
            .get(
                environment.api_url +
                    '/lead/validation_code/send' /*,{params: params, observe: 'response'}*/
            )
            .pipe(tap((data) => true));
    }

    validateCode(
        lead_id: String,
        user_id: String,
        sms_code: String
    ): Promise<any> {
        return this.http
            .post(environment.api_url + '/lead/validation_code/validate', {
                /*mobile_phone: '34'+ mobile_phone,*/ lead_id: lead_id,
                user_id: user_id,
                sms_code: sms_code,
            })
            .toPromise()
            .then((data) => {
                return data;
            });
    }

    requestAdditionalInfo(lead_id: string, user_id: string): Promise<any> {
        return this.http
            .get(environment.api_url + '/lead/additional_info/request')
            .toPromise();
    }

    saveAdditionalnfo(data: any): Promise<any> {
        return this.http
            .post(environment.api_url + '/lead/additional_info/save', {
                education: data.education,
                family_status: data.family_status,
                housing_type: data.housing_type,
                dependent_people: data.dependent_people,
                monthly_income: data.monthly_income,
                monthly_expenses: data.monthly_expenses,
                company_name: data.company_name,
                email: data.email,
            })
            .toPromise();
    }

    getPitchLoginInfo(provider: string): Promise<any> {
        const params = { provider: provider };

        return this.http
            .get(
                environment.api_url +
                    '/lead/bank_aggregation/info/' +
                    provider /*,{params: params, observe: 'response'}*/
            )
            .toPromise()
            .then((data) => {
                console.log(data);
                return data;
            });
    }

    /* bankAggregationPost(lead_id: String, mobile_phone: String, personalid: String, sessionId: String, sessionIdSignature: String):Promise<any>{
      return this.http.post(environment.api_url + '/bank/aggregation', {lead_id: lead_id, personalid: personalid, mobile_phone: mobile_phone, sessionid: sessionId, sessionid_signature: sessionIdSignature}).toPromise()
       .then(data=>{
         console.log(data);
         return true;
       });
    }*/

    bankList(): Promise<any> {
        return this.http
            .get(environment.api_url + '/bank/list')
            .toPromise()
            .then((data) => {
                return data;
            });
    }

    selectBank(bank: string): Promise<any> {
        return this.http
            .get(environment.api_url + '/bank/authentication_form/' + bank)
            .toPromise()
            .then((data) => {
                return data;
            });
    }

    bankLogin(bank: string): Promise<any> {
        const params = { bank: bank };
        return this.http
            .get(environment.api_url + '/bank/login', {
                params: params,
                observe: 'response',
            })
            .toPromise()
            .then((data) => {
                return data;
            });
    }

    bankAggregation(bankSelected, sendData): Promise<any> {
        return this.http
            .post(environment.api_url + '/lead/bank_aggregation/make', {
                bank_id: bankSelected,
                user: sendData[sendData.length - 2].value,
                pass: sendData[sendData.length - 1].value,
                documentType:
                    sendData[0].value !== 'undefined' ? sendData[0].value : 0,
            })
            .toPromise();
    }

    bankAggregationPost(
        lead_id: String,
        mobile_phone: String,
        personalid: String,
        authorization: String,
        successURL: String,
        bank_selected: String,
        provider: String
    ): Promise<any> {
        return this.http
            .post(environment.api_url + successURL, {
                lead_id: lead_id,
                personalid: personalid,
                mobile_phone: '34' + mobile_phone,
                bank_token: authorization,
                bank_id: bank_selected,
                provider: provider,
            })
            .toPromise()
            .then((data) => {
                console.log(data);
                return data;
            });
    }

    bankAggregationGet(
        lead_id: String,
        mobile_phone: String,
        personalid: String,
        authorization: String,
        successURL: String,
        bank_selected: String
    ): Promise<any> {
        return this.http
            .get(environment.api_url + successURL)
            .toPromise()
            .then((data) => {
                console.log(data);
                return true;
            });
    }

    getDataOffer(lead_id: string, user_id: string): Observable<any> {
        const params = { lead_id: lead_id, user_id: user_id };

        return this.http
            .get(environment.api_url + '/lead/contract/offer')
            .pipe(tap((data) => data));
    }

    acceptOffer(lead_id: string, user_id: string): Observable<any> {
        return this.http
            .get(environment.api_url + '/lead/contract/accept_offer')
            .pipe(tap((data) => data));
    }

    declineOffer(lead_id: string, user_id: string): Observable<any> {
        return this.http
            .get(environment.api_url + '/lead/contract/reject_offer')
            .pipe(tap((data) => data));
    }

    getContract(lead_id: string, user_id: string): Observable<any> {
        /*let params = new HttpParams();
      params = params.append('lead_id', lead_id);
      params = params.append('mobile_phone', mobile_phone);
      params = params.append('personalid', personalid);*/

        /*let params = new HttpParams().set('lead_id', lead_id,);
      params.append('mobile_phone', mobile_phone);
      params.append('personalid', personalid);*/

        const params = { lead_id: lead_id, user_id: user_id };

        return this.http.get(
            environment.api_url + '/lead/contract/request_sign'
        );
    }

    sign(lead_id: String, image: any, user_id: String): Promise<any> {
        return this.http
            .post(environment.api_url + '/lead/contract/sign', {
                lead_id: lead_id,
                contract_sign: image,
                user_id: user_id,
            })
            .toPromise()
            .then((data) => {
                console.log(data);
                return data;
            });
    }

    search(query): Observable<any> {
        return this.http.get(environment.api_url + '/tools/search', {
            params: query,
        });
    }

    getProvinces(): Promise<any> {
        const client = environment.api_url.replace('backoffice', 'v1/client');
        return this.http.get(client + '/province/list').toPromise();
    }

    getCity(id): Promise<any> {
        const client = environment.api_url.replace('backoffice', 'v1/client');
        return this.http.get(client + `/province/${id}/zip/list`).toPromise();
    }

    getCityByZip(zip: string): Promise<any> {
        const client = environment.api_url.replace('backoffice', 'v1/client');
        return this.http
            .get(environment.api_url + `/provinces/citys`, {
                params: { zip },
            })
            .toPromise();
    }
}
