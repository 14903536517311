<div *appHasPermissions="['debtrecovery-collection-incidence']">
    <div class="row justify-content-between mb-3">
        <div class="col-12 col-lg-2">
            <h2 class="mb-0">Collection Incidences</h2>
            <a *ngIf="selectedPageName === 'all'" [href]="exportLink" (click)="getExportLink($event)">Export</a>
        </div>
        <div class="col-12 col-lg-10">
            <div class="filter-tabs d-flex justify-content-end flex-wrap">
                <a
                    *ngFor="let page of pageNames | keys"
                    class="filter-tabs__item card bg-light text-center"
                    [ngClass]="{
                        'bg-secondary': page === 'all',
                        'bg-light': page != 'all',
                        is_active: page === selectedPageName
                    }"
                    href=""
                    (click)="gotoIncident($event, page)"
                    >{{ pageNames[page] }}</a
                >
            </div>
        </div>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="incidentsList?.current_page"
        [from]="incidentsList?.from"
        [to]="incidentsList?.to"
        [total]="incidentsList?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="table-responsive">
        <table class="table table-responsive">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Loan</th>
                    <th>Full Name</th>
                    <th>DNI/NIE</th>
                    <th>Quotas Unpaid</th>
                    <th>Amount Unpaid</th>
                    <th *ngIf="selectedPageName === 'payment_done'">Amount Paid</th>
                    <th *ngIf="selectedPageName === 'payment_done'">Payment Source</th>
                    <th *ngIf="selectedPageName === 'payment_incident'">Incident Type</th>
                    <th>Card Status</th>
                    <th>Job Type</th>
                    <th>AM</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-datepicker-filter
                            #datePicker
                            [start]="filters.date_start"
                            [end]="filters.date_end"
                            fieldName="date"
                            (changeDate)="onChangeDate($event)"
                            [reset]="resetFilters"
                            [withStartDate]="withStartDate"
                        ></app-datepicker-filter>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loan code"
                            name="loan_code"
                            [(ngModel)]="filters.loan_code"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Full name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="DNI / NIE"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Quotas unpaid"
                            name="count_quota_unpaid"
                            [(ngModel)]="filters.count_quota_unpaid"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount unpaid"
                            name="amount_unpaid"
                            [(ngModel)]="filters.amount_unpaid"
                        />
                    </td>
                    <td *ngIf="selectedPageName === 'payment_done'">
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount Paid"
                            name="amount_paid"
                            [(ngModel)]="filters.amountPaid"
                        />
                    </td>
                    <td *ngIf="selectedPageName === 'payment_done'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="payment_source_id"
                            [(ngModel)]="filters.payment_source_id"
                        >
                            <option value="">Select Source</option>
                            <option *ngFor="let source of filterLists?.paymentSource?.list" value="{{ source.id }}">{{
                                source.name
                            }}</option>
                        </select>
                    </td>
                    <td *ngIf="selectedPageName === 'payment_incident'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="tracking_type_incident_id"
                            [(ngModel)]="filters.tracking_type_incident_id"
                        >
                            <option value="">Select type</option>
                            <option
                                *ngFor="let key of filterLists?.traking?.list?.incidents | keys"
                                value="{{ key }}"
                                >{{ (filterLists?.traking?.list?.incidents)[key] }}</option
                            >
                        </select>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            type="text"
                            name="card_status_id"
                            [(ngModel)]="filters.card_status_id"
                        >
                            <option value="">Select status</option>
                            <option *ngFor="let key of filterLists?.cardStatus?.list | keys" value="{{ key }}">{{
                                (filterLists?.cardStatus?.list)[key]
                            }}</option>
                        </select>
                    </td>
                    <td>
                        <select (change)="getDataOnFilterSubmit()" name="job_type_id" [(ngModel)]="filters.job_type_id">
                            <option value="">Select role</option>
                            <option
                                *ngFor="let key of filterLists?.jobRole?.list?.lead_job_type | keys"
                                value="{{ key }}"
                                >{{ (filterLists?.jobRole?.list?.lead_job_type)[key] }}</option
                            >
                        </select>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="account_manager_id"
                            [(ngModel)]="filters.account_manager_id"
                        >
                            <option value>Added by</option>
                            <option *ngFor="let user of users?.actived" value="{{ user?.id }}">
                                {{ user?.name }}
                            </option>
                            <option *ngIf="users?.blocked.length > 0" disabled>──────────</option>
                            <option *ngFor="let user of users?.blocked" value="{{ user?.id }}">
                                {{ user?.name }}
                            </option>
                        </select>
                    </td>
                </tr>
                <ng-template [ngIf]="incidentsList?.data?.length > 0 && !loading">
                    <tr *ngFor="let incident of incidentsList?.data">
                        <td>{{ formatDate(incident?.date) | date: 'dd/MM/yyyy' }}</td>
                        <td>
                            <a class="miLineaFontColor" (click)="goto(incident?.lead_id)">{{ incident?.code_str }}</a>
                        </td>
                        <td>{{ incident?.full_name }}</td>
                        <td>{{ incident?.personalid }}</td>
                        <td>{{ incident?.count_quota_unpaid }}</td>
                        <td>{{ incident?.amount_unpaid | currency: 'EUR' }}</td>
                        <td *ngIf="selectedPageName === 'payment_done'">
                            {{ incident?.amount_paid | currency: 'EUR' }}
                        </td>
                        <td *ngIf="selectedPageName === 'payment_done'">{{ incident?.payment_source_name }}</td>
                        <td *ngIf="selectedPageName === 'payment_incident'">
                            {{ incident?.tracking_type_incident_name }}
                        </td>
                        <td>{{ incident?.bank_card_status }}</td>
                        <td>{{ incident?.job_type }}</td>
                        <td>{{ incident?.account_manager }}</td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!incidentsList?.data?.length && !loading">
                    <tr>
                        <td colspan="3">
                            <p class="py-5 mb-0">
                                Nothing Found
                            </p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="incidentsList?.current_page"
        [from]="incidentsList?.from"
        [to]="incidentsList?.to"
        [total]="incidentsList?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
