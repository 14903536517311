<div class="payment-tree__inner">
  <div
    [routerLink]="[url]"
    routerLinkActive="is--active"
    class="payment-tree px-3 py-2"
    [ngClass]="{ 'payment-tree--edit': editing }"
  >
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="d-flex justify-content-between">
        <div class="payment-tree__switch custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="'switch' + tree?.id"
            name="enabled"
            formControlName="enabled"
          />
          <label
            class="custom-control-label small"
            [for]="'switch' + tree?.id"
          ></label>
        </div>
        <div class="d-flex align-items-center payment-tree__controls">
          <a href="#0" (click)="switchEdit($event)" class="text-primary">
            <i *ngIf="!editing" class="fa fa-edit"></i>
            <i *ngIf="editing" class="fa fa-times-circle"></i>
          </a>
          <a
            href="#0"
            (click)="delete($event)"
            class="text-danger ml-2 invisible"
          >
            <i class="fa fa-trash"></i>
          </a>
        </div>
      </div>
      <div
        [contentEditable]="editing"
        placeholder="Name"
        class="payment-tree__title"
        [ngClass]="{
          'is--invalid':
            form.controls.name.invalid && form.controls.name.touched
        }"
        (keyup)="onKeyup($event, 'name')"
      >
        {{ tree?.name }}
      </div>
      <input type="hidden" formControlName="name" />
      <div
        [contentEditable]="editing"
        placeholder="Description"
        class="payment-tree__description text-mutted small"
        [ngClass]="{
          'is--invalid':
            form.controls.description.invalid &&
            form.controls.description.touched
        }"
        (keyup)="onKeyup($event, 'description')"
      >
        {{ tree?.description }}
      </div>
      <input type="hidden" formControlName="description" />
      <div class="payment-tree__update text-right mb-2">
        <button
          type="submit"
          class="btn btn-sm btn-primary ml-2"
          [disabled]="isSending || form.invalid"
        >
          {{ isSending ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </form>
  </div>
  <a href="#0" (click)="delete($event)" class="delete text-danger">
    <i class="fa fa-trash"></i>
  </a>
</div>
