import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-backoffice-datamanagement',
    templateUrl: './backoffice-datamanagement.component.html',
    styleUrls: ['./backoffice-datamanagement.component.css']
})
export class BackofficeDatamanagementComponent implements OnInit, DoCheck {
    show: boolean = true;

    constructor(private router: Router) {}

    ngDoCheck() {
        if (window.location.href.match('.backoffice/datamanagement$')) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    ngOnInit() {}

    goto(route) {
        this.show = false;
        this.router.navigate([route]);
    }
}
