<div *appHasPermissions="['datamanagement']">
    <!-- debtrecovery tab content -->
    <datamanagement *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 panel-main-action">
                    <div class="row">
                        <div *appHasPermissions="['datamanagement-users-all']" class="col-6 col-lg">
                            <div class="row">
                                <div class="col-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('/backoffice/datamanagement/users/all')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/datamanagement/system_users.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Users</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['datamanagement-users-dropout']" class="col-6 col-lg">
                            <div class="panel-section">
                                <div
                                    class="container-description"
                                    (click)="goto('/backoffice/datamanagement/users/dropout')"
                                >
                                    <img
                                        class="align-middle"
                                        width="90"
                                        height="90"
                                        src="assets/img/backoffice/datamanagement/drop_out.svg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="icon__text text-primary">Users <br />Drop Out</div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['datamanagement-users-blacklisted']" class="col-6 col-lg">
                            <div class="panel-section">
                                <div
                                    class="container-description"
                                    (click)="goto('/backoffice/datamanagement/users/blacklisted')"
                                >
                                    <img
                                        class="align-middle"
                                        width="90"
                                        height="90"
                                        src="assets/img/backoffice/datamanagement/black_list.svg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="icon__text text-primary">Black List</div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['datamanagement-users-customer']" class="col-6 col-lg">
                            <div class="panel-section">
                                <div
                                    class="container-description"
                                    (click)="goto('/backoffice/datamanagement/users/customer')"
                                >
                                    <img
                                        class="align-middle"
                                        width="90"
                                        height="90"
                                        src="assets/img/backoffice/datamanagement/customers.svg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="icon__text text-primary">Customers</div>
                                </div>
                            </div>
                        </div>
                        <div *appHasPermissions="['datamanagement-users-asnef-included']" class="col-6 col-lg">
                            <div class="panel-section">
                                <div
                                    class="container-description"
                                    (click)="goto('/backoffice/datamanagement/users/asnef_included')"
                                >
                                    <img
                                        class="align-middle"
                                        width="90"
                                        height="90"
                                        src="assets/img/backoffice/credit_lines/ansef_include.svg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="icon__text text-primary">Asnef <br />Included</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *appHasPermissions="['datamanagement-callcenter']" class="border-top border-primary">
                        <div class="row">
                            <div class="col-6 col-lg col-w-20">
                                <div class="panel-section">
                                    <div
                                        class="container-description"
                                        (click)="goto('/backoffice/datamanagement/call_center')"
                                    >
                                        <img
                                            class="align-middle"
                                            width="90"
                                            height="90"
                                            src="assets/img/backoffice/datamanagement/call_center.svg"
                                            alt="Generic placeholder image"
                                        />
                                        <div class="icon__text text-primary">Call center <br />activity</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </datamanagement>
    <!-- debtrecovery tab content end -->
    <router-outlet></router-outlet>
</div>
