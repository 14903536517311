<button
    class="btn-sm btn-primary"
    [disabled]="disabled"
    (click)="open(content)"
>
    Adjuntar Documento
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Adjuntar Documento</h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form
            *ngIf="form"
            id="attachmentsForm"
            [formGroup]="form"
            autocomplete="off"
            (ngSubmit)="onSubmit(action)"
        >
            <div class="form-group">
                <label for="type_id">
                    Tipo de Documento
                    <small class="text-danger">*</small>
                </label>
                <div class="input-group">
                    <div *ngIf="isTypesLoading" class="input-group-prepend">
                        <div class="input-group-text">
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </div>
                    </div>
                    <select
                        id="type_id"
                        class="form-control"
                        name="type_id"
                        formControlName="type_id"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.type_id.touched &&
                                !form.controls.type_id.valid,
                            'is-valid':
                                form.controls.type_id.touched &&
                                form.controls.type_id.valid
                        }"
                        required
                    >
                        <option value="" disabled>Seleccione</option>
                        <option *ngFor="let item of types" [value]="item.id">
                            {{ item.name
                            }}{{
                                item.description ? ' - ' + item.description : ''
                            }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button
                            class="btn btn-outline-primary btn-add"
                            type="button"
                            placement="left"
                            [ngbTooltip]="
                                isCollapsed ? 'Crear un tipo nuevo' : 'Cancelar'
                            "
                            (click)="collapseToggle()"
                            [attr.aria-expanded]="!isCollapsed"
                        >
                            <i
                                class="fa fa-plus"
                                [ngClass]="isCollapsed ? 'fa-plus' : 'fa-minus'"
                            ></i>
                        </button>
                    </div>
                </div>
                <div [ngbCollapse]="isCollapsed">
                    <div class="card bg-light mt-3 border-0">
                        <div class="card-body">
                            <form
                                id="typeForm"
                                [formGroup]="typeForm"
                                autocomplete="off"
                                (ngSubmit)="onSubmitType()"
                            >
                                <div class="form-group">
                                    <label for="typeName">
                                        Nombre
                                        <small class="text-danger">*</small>
                                    </label>
                                    <input
                                        id="typeName"
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        formControlName="name"
                                        [ngClass]="{
                                            'is-invalid':
                                                typeForm.controls.name
                                                    .touched &&
                                                !typeForm.controls.name.valid,
                                            'is-valid':
                                                typeForm.controls.name
                                                    .touched &&
                                                typeForm.controls.name.valid
                                        }"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="typeDesc">Descripción</label>
                                    <input
                                        id="typeDesc"
                                        type="text"
                                        class="form-control"
                                        name="description"
                                        formControlName="description"
                                        [ngClass]="{
                                            'is-invalid':
                                                typeForm.controls.description
                                                    .touched &&
                                                !typeForm.controls.description
                                                    .valid,
                                            'is-valid':
                                                typeForm.controls.description
                                                    .touched &&
                                                typeForm.controls.description
                                                    .valid
                                        }"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    form="typeForm"
                                    class="btn btn-primary w-100"
                                    [disabled]="
                                        typeForm.invalid ||
                                        isTypeSending ||
                                        isCollapsed
                                    "
                                >
                                    {{
                                        isTypeSending
                                            ? 'Guardando...'
                                            : 'Guardar'
                                    }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="name"
                    >Nombre <small class="text-danger">*</small></label
                >
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    formControlName="name"
                    [ngClass]="{
                        'is-invalid':
                            form.controls.name.touched &&
                            !form.controls.name.valid,
                        'is-valid':
                            form.controls.name.touched &&
                            form.controls.name.valid
                    }"
                    required
                />
            </div>
            <div class="form-group">
                <label for="description"
                    >Descripción <small class="text-danger">*</small></label
                >
                <input
                    type="text"
                    class="form-control"
                    id="description"
                    name="description"
                    formControlName="description"
                    [ngClass]="{
                        'is-invalid':
                            form.controls.description.touched &&
                            !form.controls.description.valid,
                        'is-valid':
                            form.controls.description.touched &&
                            form.controls.description.valid
                    }"
                    required
                />
            </div>
            <div class="form-group">
                <div class="custom-file">
                    <input
                        type="file"
                        class="custom-file-input"
                        id="attachment"
                        accept=".pdf"
                        name="attachment"
                        formControlName="attachment"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.attachment.touched &&
                                !form.controls.attachment.valid,
                            'is-valid':
                                form.controls.attachment.touched &&
                                form.controls.attachment.valid
                        }"
                        (change)="onFileChange($event)"
                        required
                    />
                    <label class="custom-file-label" for="attachment"
                        >Seleccione Archivo</label
                    >
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            Cerrar
        </button>
        <button
            *ngIf="form"
            type="submit"
            class="btn btn-primary"
            form="attachmentsForm"
            [disabled]="form.invalid || isSending || !isCollapsed"
        >
            {{ isSending ? 'Guardando...' : 'Adjuntar' }}
        </button>
    </div>
</ng-template>
