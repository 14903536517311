<app-pager
    [perPage]="tableData?.per_page"
    [currentPage]="tableData?.current_page"
    [from]="tableData?.from"
    [to]="tableData?.to"
    [total]="tableData?.total"
    (changePage)="getDataOnPageChange($event)"
>
</app-pager>

<div class="table-responsive table-hover">
    <table class="table">
        <thead>
            <tr>
                <th>Status</th>
                <th>Last Status At</th>
                <th>To</th>
                <th>Subject</th>
                <th>Type</th>
                <th>Text</th>
                <th>Channel</th>
                <th>Sent By</th>
            </tr>
        </thead>
        <tbody>
            <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                <tr *ngFor="let item of tableData?.data">
                    <td>{{ item?.status?.name }}</td>
                    <td>
                        {{
                            formatDate(item?.last_status_at)
                                | date: 'dd/MM/yyyy HH:mm'
                        }}
                    </td>
                    <td>{{ item?.to }}</td>
                    <td>{{ item?.subject }}</td>
                    <td>{{ item?.type?.name }}</td>
                    <td>{{ item?.content }}</td>
                    <td>{{ item?.channel?.name }}</td>
                    <td>{{ item?.sent_by?.name }}</td>
                </tr>
            </ng-template>
            <ng-template [ngIf]="!tableData?.data.length && !loading">
                <tr class="without_hover">
                    <td colspan="8">
                        <p class="py-5 mb-0">Nothing Found</p>
                    </td>
                </tr>
            </ng-template>
            <tr *ngIf="loading" class="without_hover">
                <td colspan="3">
                    <p class="py-5 mb-0">
                        <img
                            width="30px"
                            height="30px"
                            src="assets/img/backoffice/loading.gif"
                        />
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<app-pager
    [perPage]="tableData?.per_page"
    [currentPage]="tableData?.current_page"
    [from]="tableData?.from"
    [to]="tableData?.to"
    [total]="tableData?.total"
    (changePage)="getDataOnPageChange($event)"
>
</app-pager>
