import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { TEMPLATE_ACTIVATION_ENUM } from '../../../../../shared/enums/template-activation.enum';
import { EmailService } from '../../../../services/email.service';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-mail-templates-publishing',
  templateUrl: './mail-templates-publishing.component.html',
  styleUrls: ['./mail-templates-publishing.component.scss'],
})
export class MailTemplatesPublishingComponent implements OnInit, OnDestroy {
  @Input() class = 'btn-sm btn-outline-primary';
  @Input() id: number;
  @Input() type: TEMPLATE_ACTIVATION_ENUM;

  @Output() published = new EventEmitter<any>();

  isSending = false;
  subscriber: SubscriptionLike;

  constructor(
    private emailService: EmailService,
    private errorMessage: ErrorMessageService
  ) {}

  ngOnInit(): void {}
  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  onClick() {
    if (this.id && this.type) {
      this.isSending = true;

      this.subscriber = this.emailService
        .emailTemplatesPublishing(this.id, this.type)
        .subscribe(
          (response) => {
            this.errorMessage.showSuccess('Published Successuly');
            this.isSending = false;
            this.published.emit(response);
          },
          (error) => {
            this.errorMessage.showError(
              error?.error?.error?.details,
              'Publishing Failed'
            );

            this.isSending = false;
          }
        );
    }
  }
}
