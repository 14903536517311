import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactInfoService } from '../../../services/contact-info.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-lead-info-basic-info',
    templateUrl: './lead-info-basic-info.component.html',
    styleUrls: ['./lead-info-basic-info.component.css']
})
export class LeadInfoBasicInfoComponent implements OnInit, OnChanges {
    @Input() user: any = null;
    @Input() loan_id: any = null;
    @Input() editInfo: any = null;

    basicModel: any = null;
    data: any = null;

    isSaving: boolean = false;

    constructor(
        private modalService: NgbModal,
        private ContactInfoService: ContactInfoService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.user) {
            this.data = this.setData(this.user);
        }
    }

    openEdit(edit, user) {
        this.basicModel = this.setData(user);
        this.modalService.open(edit, { size: 'lg' });
    }

    setData(user) {
        let data = {
            name: user.name,
            first_surname: user.first_surname,
            second_surname: user.second_surname
        };

        return data;
    }

    saveInfo(value) {
        if (this.loan_id) {
            this.isSaving = true;
            value.loan_id = this.loan_id;
            value.added_by = 1;

            this.ContactInfoService.save(value).subscribe(
                data => {
                    if (data) {
                        this.data = this.setData(value);
                        setTimeout(
                            function(self) {
                                self.errorMessageService.showSuccess('Info updated successful!');
                                self.isSaving = false;
                            },
                            600,
                            this
                        );
                    }
                },
                error => {
                    setTimeout(
                        function(self) {
                            self.isSaving = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(error.error.error.show_message);
                }
            );
        }
    }
}
