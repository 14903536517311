<div class="process-payment">
    <button
        type="button"
        class="btn btn-accept btn-process-payment"
        (click)="open(payment)"
    >
        Process Payment
    </button>
</div>

<!-- Modal -->
<ng-template #payment let-c="close">
    <div class="modal-header">
        <h4 class="modal-title">Custom Collection</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-secondary" role="alert">
            <div class="row justify-content-between">
                <div class="col-auto">
                    <strong>Loan:</strong> {{ creditline?.code_str }}
                </div>
                <div class="col-auto">
                    <strong>Debt Left:</strong>
                    {{ debt?.debt_left | currency: 'EUR' }}
                </div>
            </div>
        </div>

        <div class="row justify-content-between">
            <div class="col-auto">
                <div
                    class="step-label"
                    [ngClass]="step === 0 ? 'step-label--active' : ''"
                >
                    <strong>Step 1:</strong> Amount<span
                        *ngIf="step != 0"
                        class="badge badge-pill badge-info"
                        >{{ amount | currency: 'EUR' }}</span
                    >
                </div>
            </div>
            <div class="col-auto">
                <div
                    class="step-label"
                    [ngClass]="step === 1 ? 'step-label--active' : ''"
                >
                    <strong>Step 2:</strong> Collection Method<span
                        *ngIf="step > 1"
                        class="badge badge-pill badge-info"
                        >{{ chooseMethods[chooseMethod].short_name }}</span
                    >
                </div>
            </div>
            <div class="col-auto">
                <div
                    class="step-label"
                    [ngClass]="step === 2 ? 'step-label--active' : ''"
                >
                    <strong>Step 3:</strong> Summary
                </div>
            </div>
        </div>
        <hr />

        <div *ngIf="step == 0" class="step">
            <div class="row align-items-center">
                <div class="col-auto">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            ngModel
                            [(ngModel)]="chooseAmount"
                            (change)="handleChange($event)"
                            type="radio"
                            name="chooseAmount"
                            id="chooseAmount1"
                            value="total"
                        />
                        <label class="form-check-label" for="chooseAmount1"
                            >Total debt</label
                        >
                    </div>
                </div>
                <div class="col-auto">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            ngModel
                            [(ngModel)]="chooseAmount"
                            (change)="handleChange($event)"
                            type="radio"
                            name="chooseAmount"
                            id="chooseAmount2"
                            value="partial"
                        />
                        <label class="form-check-label" for="chooseAmount2"
                            >Amount to Collect</label
                        >
                    </div>
                </div>
                <div class="col-auto col--margin-left_auto">
                    <input
                        id="amountControl"
                        type="number"
                        class="form-control amount-input text-right"
                        name="amount"
                        [(ngModel)]="amount"
                        [formControl]="amountControl"
                        required
                        [readonly]="readonly"
                    />
                    <div
                        class="text-danger payment__text-danger"
                        *ngIf="
                            amountControl.invalid && amountControl.errors.min
                        "
                    >
                        Debe colocar importes mayores a cero
                    </div>
                    <div
                        class="text-danger payment__text-danger"
                        *ngIf="
                            amountControl.invalid && amountControl.errors.max
                        "
                    >
                        La cantidad no puede ser mayor al total pendiente
                    </div>
                    <div
                        class="text-danger payment__text-danger"
                        *ngIf="
                            amountControl.invalid &&
                            amountControl.errors.required &&
                            amountControl.touched
                        "
                    >
                        Este campo es obligatorio
                    </div>
                    <div
                        class="text-danger payment__text-danger"
                        *ngIf="
                            amountControl.invalid &&
                            amountControl.errors.pattern
                        "
                    >
                        Por favor introduzca solo dos digitos despues de la coma
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="step == 1" class="step">
            <div class="row">
                <div class="col-4">
                    <div
                        *ngFor="let choose of chooseMethods"
                        class="form-group"
                    >
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                ngModel
                                [(ngModel)]="chooseMethod"
                                type="radio"
                                name="chooseMethod"
                                id="chooseMethod{{ choose.id }}"
                                value="{{ choose.id }}"
                            />
                            <label
                                class="form-check-label"
                                for="chooseMethod{{ choose.id }}"
                                >{{ choose.name }}</label
                            >
                        </div>
                    </div>
                </div>

                <div *ngIf="chooseMethod === '0'" class="col-8">
                    <div class="row">
                        <div class="col-6">
                            <label for="bankCard">Bank Card</label>
                        </div>
                        <div class="col-6">
                            <label for="paymentType">Payment Type</label>
                        </div>
                        <div class="col-6">
                            <select
                                name="card_id"
                                id="bankCard"
                                class="form-control"
                                [(ngModel)]="selectedCard"
                                [formControl]="cardControl"
                                required
                                (ngModelChange)="setTotalCardPan($event, '0')"
                            >
                                <option value="">Select Card</option>
                                <option
                                    *ngFor="let card of paymentBankCards"
                                    [value]="
                                        {
                                            cardId: card.id,
                                            cardPan: card.pan,
                                            cardMonth: card.expiration_month,
                                            cardYear: card.expiration_year
                                        } | json
                                    "
                                >
                                    {{ card?.pan }}
                                </option>
                            </select>
                            <div
                                class="text-danger payment__text-danger text-danger--left"
                                *ngIf="
                                    cardControl.invalid && cardControl.touched
                                "
                            >
                                Please select bank card.
                            </div>
                        </div>
                        <div class="col-6">
                            <select
                                name="payment_type"
                                id="paymentType"
                                [(ngModel)]="paymentType"
                                class="form-control"
                                required
                            >
                                <option value="1">Secure</option>
                                <option value="2">No Secure(Fast)</option>
                            </select>
                        </div>
                        <div class="col-12"></div>
                        <div class="col-3 mt-3" *ngIf="+paymentType === 1">
                            <div class="form-group">
                                <label for="cvc">CVC</label>
                                <input
                                    id="cvc"
                                    name="cvc"
                                    [formControl]="cvcControl"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-danger':
                                            cvcControl.invalid &&
                                            cvcControl.touched
                                    }"
                                    type="text"
                                    required
                                    minlength="3"
                                    maxlength="4"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="chooseMethod === '1'" class="col-8">
                    <form novalidate [formGroup]="cardForm">
                        <div class="row">
                            <div class="form-group col-6">
                                <label for="cardNumber">Bank Card Number</label>
                                <input
                                    class="form-control"
                                    id="cardNumber"
                                    type="number"
                                    name="card_number"
                                    formControlName="cardNumber"
                                    autocomplete="off"
                                    [ngClass]="{
                                        'has-danger':
                                            cardForm.controls.cardNumber
                                                .invalid &&
                                            cardForm.controls.cardNumber.touched
                                    }"
                                />
                                <div
                                    class="text-danger text-danger--left"
                                    *ngIf="
                                        cardForm.controls.cardNumber.invalid &&
                                        cardForm.controls.cardNumber.touched &&
                                        !cardForm.controls.cardNumber.value
                                    "
                                >
                                    Card number is required.
                                </div>
                                <div
                                    class="text-danger text-danger--left"
                                    *ngIf="
                                        cardForm.controls.cardNumber.invalid &&
                                        cardForm.controls.cardNumber.touched &&
                                        cardForm.controls.cardNumber.value
                                    "
                                >
                                    Card number is incorect.
                                </div>
                            </div>
                            <!-- <div class="form-group col-6">
                                <label for="paymentProvider"
                                    >Payment Provider</label
                                >
                                <select
                                    class="form-control"
                                    name="paymentProvider"
                                    id="paymentProvider"
                                    formControlName="paymentProvider"
                                >
                                    <option value="">Select Provider</option>
                                    <ng-template
                                        let-provider
                                        ngFor
                                        [ngForOf]="paymentProviders"
                                        let-i="index"
                                    >
                                        <option
                                            *ngIf="
                                                provider.name ===
                                                    'UniversalPay' ||
                                                provider.name === 'Redsys'
                                            "
                                            [value]="provider.id"
                                            >{{ provider.name }}</option
                                        >
                                    </ng-template>
                                </select>
                                <div
                                    class="text-danger text-danger--left"
                                    *ngIf="
                                        cardForm.controls.paymentProvider
                                            .invalid &&
                                        cardForm.controls.paymentProvider
                                            .touched
                                    "
                                >
                                    Please choose a provider.
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <div class="col-8">
                                        <label for="cardMonth"
                                            >Expired on</label
                                        >
                                    </div>
                                    <div class="col-4">
                                        <label for="cardCvv">CVV</label>
                                    </div>
                                    <div class="col-4 col-date">
                                        <select
                                            id="cardMonth"
                                            name="card_month"
                                            class="form-control"
                                            formControlName="cardMonth"
                                            [ngClass]="{
                                                'has-danger':
                                                    cardForm.controls.cardMonth
                                                        .invalid &&
                                                    cardForm.controls.cardMonth
                                                        .touched
                                            }"
                                            required
                                        >
                                            <option
                                                *ngFor="let month of listMonth"
                                                [value]="month.id"
                                            >
                                                {{ month.month }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-date--right">
                                        <select
                                            name="card_year"
                                            class="form-control"
                                            formControlName="cardYear"
                                            [ngClass]="{
                                                'has-danger':
                                                    cardForm.controls.cardYear
                                                        .invalid &&
                                                    cardForm.controls.cardYear
                                                        .touched
                                            }"
                                            required
                                        >
                                            <option value="">yy</option>
                                            <option
                                                *ngFor="let year of listYear"
                                                [value]="year.full"
                                            >
                                                {{ year.short }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <input
                                            id="cardCvv"
                                            class="form-control"
                                            [ngClass]="{
                                                'has-danger':
                                                    cardForm.controls.cardCvv
                                                        .invalid &&
                                                    cardForm.controls.cardCvv
                                                        .touched
                                            }"
                                            type="text"
                                            name="cvv_var"
                                            required
                                            formControlName="cardCvv"
                                            minlength="3"
                                            maxlength="4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="chooseMethod === '2'" class="col-8">
                    <form class="row" novalidate [formGroup]="bankForm">
                        <div class="col-4" style="padding-right: 0">
                            <label for="collectionDate">Collection Date</label>
                            <input
                                id="collectionDate"
                                name="collectionDate"
                                type="text"
                                class="form-control"
                                formControlName="collectionDate"
                                required
                                placeholder="Choose date"
                                daterangepicker
                                [options]="singlePicker"
                                (applyDaterangepicker)="
                                    onApplyDaterangepicker($event)
                                "
                                autocomplete="off"
                            />
                            <div
                                class="text-danger text-danger--left"
                                *ngIf="
                                    bankForm.controls.collectionDate.invalid &&
                                    bankForm.controls.collectionDate.touched
                                "
                            >
                                Date is required.
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-group">
                                <label for="bankAccount">Bank Account</label>
                                <select
                                    id="bankAccount"
                                    name="bankAccount"
                                    class="form-control"
                                    formControlName="bankAccount"
                                    required
                                >
                                    <option value="">
                                        Select Bank Account
                                    </option>
                                    <option
                                        *ngFor="let bank of paymentBanks"
                                        [value]="
                                            {
                                                bankId: bank.id,
                                                bankIban: bank.iban,
                                                bankName: bank.bank.full_name
                                            } | json
                                        "
                                    >
                                        {{
                                            bank.bank.full_name +
                                                ' - ' +
                                                bank.iban
                                        }}
                                    </option>
                                </select>
                                <div
                                    class="text-danger text-danger--left"
                                    *ngIf="
                                        bankForm.controls.bankAccount.invalid &&
                                        bankForm.controls.bankAccount.touched
                                    "
                                >
                                    Please choose an account.
                                </div>
                            </div>
                        </div>
                        <div class="col-8 offset-4">
                            <label for="paymentResource"
                                >Payment Resource</label
                            >
                            <select
                                id="paymentResource"
                                name="paymentResource"
                                class="form-control"
                                formControlName="paymentResource"
                                required
                            >
                                <option value="">
                                    Select Payment Resource
                                </option>
                                <option
                                    *ngFor="let resource of paymentResources"
                                    [value]="resource.id"
                                >
                                    {{ resource.name }}
                                </option>
                            </select>
                            <div
                                class="text-danger text-danger--left"
                                *ngIf="
                                    bankForm.controls.paymentResource.invalid &&
                                    bankForm.controls.paymentResource.touched
                                "
                            >
                                Please choose a resource.
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div *ngIf="step == 2" class="step">
            <div class="row">
                <div class="col-12">
                    <p>
                        <strong>Total Amount:</strong>
                        {{ amount | currency: 'EUR' }}
                    </p>
                    <p>
                        <strong>Collection Method:</strong>
                        {{ chooseMethods[chooseMethod].name }}({{
                            totalCardPan
                        }})
                    </p>
                    <p><strong>Collection Date:</strong> {{ totalDate }}</p>
                </div>
            </div>
        </div>

        <div *ngIf="step == 3" class="step">
            <iframe
                [src]="iframeUrl"
                frameborder="0"
                style="width: 100%; height: 500px"
            ></iframe>
        </div>
    </div>
    <div *ngIf="step != 3" class="modal-footer">
        <div class="row justify-content-between align-items-center">
            <div class="col-auto">
                <button
                    [ngClass]="step === 0 ? 'invisible' : ''"
                    type="button"
                    [disabled]="addingCard || sendingPayment"
                    class="btn btn-outline-dark"
                    (click)="changeStep()"
                >
                    <i class="fa fa-angle-left"></i> Back
                </button>
            </div>
            <div class="col-auto">
                <button
                    *ngIf="step === 0"
                    type="button"
                    [disabled]="amountControl.invalid"
                    class="btn btn-accept"
                    (click)="changeStep('next')"
                >
                    Next <i class="fa fa-angle-right"></i>
                </button>

                <button
                    *ngIf="step === 1 && chooseMethod === '0'"
                    type="button"
                    [disabled]="
                        cardControl.invalid ||
                        (+paymentType === 1 && cvcControl.invalid)
                    "
                    class="btn btn-accept"
                    (click)="changeStep('next')"
                >
                    Next <i class="fa fa-angle-right"></i>
                </button>

                <button
                    *ngIf="step === 1 && chooseMethod === '1'"
                    type="button"
                    [disabled]="cardForm.invalid"
                    class="btn btn-accept"
                    (click)="changeStep('next'); setTotalCardPan($event, 1)"
                >
                    Next <i *ngIf="!addingCard" class="fa fa-angle-right"></i>
                </button>

                <button
                    *ngIf="step === 1 && chooseMethod === '2'"
                    type="button"
                    [disabled]="bankForm.invalid"
                    class="btn btn-accept"
                    (click)="changeStep('next'); setTotalCardPan($event, 2)"
                >
                    Next <i class="fa fa-angle-right"></i>
                </button>

                <button
                    *ngIf="step === 2"
                    type="button"
                    [disabled]="sendingPayment"
                    class="btn btn-accept"
                    (click)="makePayment()"
                >
                    {{ sendingPayment ? 'Sending...' : 'Confirm' }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
