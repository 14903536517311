import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../../../../shared/error-message.service';
import { LegalMonitoringService } from '../../../../../../services/legal-monitoring.service';

@Component({
    selector: 'app-presentation-docs-add',
    templateUrl: './presentation-docs-add.component.html',
    styleUrls: ['./presentation-docs-add.component.scss'],
})
export class PresentationDocsAddComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() proceedingId: number;
    @Input() presentationId: number;

    @Output() added = new EventEmitter<any>();

    form: FormGroup;
    isSending = false;

    types = [];
    isTypesLoading = false;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private legalMonitoringService: LegalMonitoringService,
        private errorService: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    open(content) {
        this.modalService.open(content, {
            backdrop: 'static',
            scrollable: true,
        });

        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            proceeding_id: [this.proceedingId, Validators.required],
            presentation_id: [this.presentationId, Validators.required],
            type_id: ['', Validators.required],
            name: ['', Validators.required],
            description: ['', Validators.required],
        });

        if (!this.types.length) {
            this.getTypes();
        }
    }

    getTypes() {
        this.isTypesLoading = true;
        this.form.controls.type_id.disable();
        this.legalMonitoringService.getDocsTypes().then((response) => {
            this.types = response;
            this.isTypesLoading = false;
            this.form.controls.type_id.enable();
        });
    }

    onSubmit(): void {
        this.isSending = true;
        this.form.disable();
        const params = this.form.getRawValue();

        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'string') {
                params[key] = params[key].trim();
            }
            if (
                params[key] === null ||
                params[key] === undefined ||
                params[key] === ''
            ) {
                delete params[key];
            }
        });

        this.legalMonitoringService
            .sendDoc(params)
            .then((response) => {
                this.errorService.showSuccess('Guardando con Éxito.');
                this.isSending = false;
                this.added.emit(response);
                this.form.reset();
                this.form.enable();
            })
            .catch(this.showErrors);
    }

    private showErrors = (err) => {
        const { show_message, details } = err?.error?.error;
        const message =
            details && Object.keys(details).length
                ? Object.keys(details)
                      .map((key) => details[key].join('<br />'))
                      .join('<br />')
                : '';

        this.errorService.showError(message, show_message || 'Service Error', {
            timeOut: 6000,
            enableHtml: true,
        });
        this.isSending = false;
        this.form.enable();
    };
}
