<div *appHasPermissions="['loans']">
    <!-- creditline tab content -->
    <creditline *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-6 panel-main-action">
                    <div class="row">
                        <div *appHasPermissions="['loan-all']" class="col-lg-2 col-md-3 col-sm-6 panel-main-separator">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('all')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Loans</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            [routerLink]="['/backoffice/loans/loan-test']"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Loans Test</div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="row">
                                <div class="col-lg-6 panel-main-separator">
                                    <div class="row">
                                        <div *appHasPermissions="['loan-new']" class="col-lg-4">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('new')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/credit_lines/new.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">New</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *appHasPermissions="['loan-pending-activation']" class="col-lg-4">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('pendingActivation')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/credit_lines/pending_activation.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Pending Activation</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *appHasPermissions="['loan-checking']" class="col-lg-4">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="gotoChecking()">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/credit_lines/checking.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Checking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('activation-blocked')">
                                                    <img class="align-middle" width="90" height="90" src="assets/img/backoffice/credit_lines/blocked_lines.svg" alt="Generic placeholder image">
                                                    <div class="icon__text text-primary">Activation blocked</div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row">
                                        <div *appHasPermissions="['loan-active']" class="col-lg-3">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('active')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/credit_lines/active_lines.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Active loans</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('blocked')">
                                                    <img class="align-middle" width="90" height="90" src="assets/img/backoffice/credit_lines/activation_blocked.svg" alt="Generic placeholder image">
                                                    <div class="icon__text text-primary">Blocked loans</div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('asnef')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/credit_lines/ansef_include.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Asnef included</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *appHasPermissions="['loan-due']" class="col-lg-3">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('due')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/due_loans.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Due Loans</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *appHasPermissions="['loan-end']" class="col-lg-3">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('end')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/end_loans.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">End Loans</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *appHasPermissions="['loan-rejected']" class="col-lg-3">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('rejected')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/underwriting/rejected.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Rejected</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *appHasPermissions="['loan-trial']" class="col-lg-3 offset-lg-3">
                                            <div class="panel-section">
                                                <div class="container-description" (click)="goto('trial')">
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/backoffice/trial_loans.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text text-primary">Trial Loans</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </creditline>
    <!-- creditline tab content end -->

    <router-outlet></router-outlet>
</div>
