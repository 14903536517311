<app-backoffice-header></app-backoffice-header>
<app-backoffice-mini-kpis></app-backoffice-mini-kpis>

<main>
    <div class="container">
        <nav class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/kpis" id="nav-kpis-tab" data-toggle="tab"  role="tab" aria-controls="nav-kpis-tab" aria-selected="true">KPIs</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/underwriting" id="nav-underwriting-tab" data-toggle="tab"  role="tab" aria-controls="nav-underwriting-tab" aria-selected="false">Underwriting</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/loans" id="nav-creditlines-tab" data-toggle="tab"  role="tab" aria-controls="nav-creditlines-tab" aria-selected="false">Loans</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/debtrecovery" id="nav-debtrecovery-tab" data-toggle="tab"  role="tab" aria-controls="nav-debtrecovery-tab" aria-selected="false">Debt Recovery</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/legal" id="nav-legal-tab" data-toggle="tab"  role="tab" aria-controls="nav-legal-tab" aria-selected="false">Legal</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/datamanagement"  id="nav-datamanagement-tab" data-toggle="tab"  role="tab" aria-controls="nav-datamanagement-tab" aria-selected="false">Data Management</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/marketing" id="nav-marketing-tab" data-toggle="tab"  role="tab" aria-controls="nav-marketing-tab" aria-selected="false">Marketing</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/finance" id="nav-finanze-tab" data-toggle="tab"  role="tab" aria-controls="nav-finanze-tab" aria-selected="false">Finance</a>
            <a class="nav-item nav-link" routerLinkActive="active" routerLink="/backoffice/settings" id="nav-settings-tab" data-toggle="tab"  role="tab" aria-controls="nav-settings-tab" aria-selected="false">Settings</a>
        </nav>
        <!-- <div class="nav-item nav-btn ">
            <button class="btn btn-outline-accept">
                <i class="fa fa-bell-o fa-2x"> </i>
                <span class="badge badge-danger badge-pill">3</span>
            </button>
        </div> -->
    </div>
    <!-- tab content -->
    <div class="container-fluid">
        <div class="tab-content mt-3" id="myTabContent">
            <div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    
</main>