<div class="mb-3">
  <div class="mb-4">
    <div class="row justify-content-between">
      <div class="col-auto"><h2>Variables</h2></div>
      <div class="col-auto">
        <app-mail-templates-variables-create
          (saved)="onSaved($event)"
        ></app-mail-templates-variables-create>
      </div>
    </div>
  </div>

  <app-mail-templates-variables-list
    [addedItem]="addedItem"
  ></app-mail-templates-variables-list>
</div>
