import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AsnefService } from '../../../services/asnef.service';

@Component({
    selector: 'app-lead-info-asnef-validation',
    templateUrl: './lead-info-asnef-validation.component.html',
    styleUrls: ['./lead-info-asnef-validation.component.css']
})
export class LeadInfoAsnefValidationComponent implements OnInit, OnChanges {
    @Input() asnef_snapshot: any = null;
    @Input() asnef_validation: any = null;
    invalid_detail = false;

    constructor(private asnefService: AsnefService) {}

    ngOnInit() {}
    ngOnChanges() {
        if (this.asnef_snapshot) {
            if (Object.values(this.asnef_snapshot).length && this.asnef_snapshot.details.length) {
                for (let i = 0; i < this.asnef_snapshot.details.length; i++) {
                    if (this.asnef_snapshot.details[i].invalid) {
                        this.invalid_detail = true;
                    }
                }
            }
        }
    }
}
