import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    SimpleChange,
} from '@angular/core';
import { DaterangepickerComponent } from 'ng2-daterangepicker';

declare var moment: any;

@Component({
    selector: 'app-daterangepicker',
    templateUrl: './daterangepicker.component.html',
    styleUrls: ['./daterangepicker.component.scss'],
})
export class DaterangePickerComponent implements OnInit, OnChanges {
    @ViewChild(DaterangepickerComponent)
    private picker: DaterangepickerComponent;

    @Input()
    defaultDate: any = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };
    @Input()
    fieldName: string = '';
    @Input()
    withStartDate: boolean = false;
    @Input()
    reset: boolean = false;

    @Output()
    changeDate: EventEmitter<any> = new EventEmitter();

    settings: any = null;

    dateInputValue: string = '';

    minDate = '2015-01-01';

    constructor() {}

    ngOnInit() {
        this.settings = {
            locale: {
                format: 'YYYY-MM-DD',
                cancelLabel: 'Clear',
            },
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            showDropdowns: true,
            ranges: {
                Today: [
                    moment().format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                Yesterday: [
                    moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    moment().subtract(1, 'days').format('YYYY-MM-DD'),
                ],
                'Last 5 Days': [
                    moment().subtract(4, 'days').format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                'This Week': [
                    moment().startOf('week').format('YYYY-MM-DD'),
                    moment().endOf('week').format('YYYY-MM-DD'),
                ],
                'Last Week': [
                    moment()
                        .subtract(1, 'week')
                        .startOf('week')
                        .format('YYYY-MM-DD'),
                    moment()
                        .subtract(1, 'week')
                        .endOf('week')
                        .format('YYYY-MM-DD'),
                ],
                'This Month': [
                    moment().startOf('month').format('YYYY-MM-DD'),
                    moment().endOf('month').format('YYYY-MM-DD'),
                ],
                'Last Month': [
                    moment()
                        .subtract(1, 'month')
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                    moment()
                        .subtract(1, 'month')
                        .endOf('month')
                        .format('YYYY-MM-DD'),
                ],
            },
            minDate: this.minDate,
            maxDate: this.defaultDate.endDate,
            startDate: this.defaultDate.startDate,
            endDate: this.defaultDate.endDate,
        };

        this.setStartDate();
    }

    ngOnChanges(changes: SimpleChanges) {
        const reset: SimpleChange = changes.reset;
        if (reset && reset.previousValue) {
            this.clearCalendar('reset');
        }
    }

    selectedDate(e: any) {
        const oldDate = this.dateInputValue;
        const dates = {
            start: {
                target: {
                    name: this.fieldName + '_start',
                    value: e.picker.startDate.format('YYYY-MM-DD'),
                },
            },
            end: {
                target: {
                    name: this.fieldName + '_end',
                    value: e.picker.endDate.format('YYYY-MM-DD'),
                },
            },
        };
        this.dateInputValue =
            dates.start.target.value + ' - ' + dates.end.target.value;

        if (oldDate !== this.dateInputValue) {
            this.changeDate.emit(dates);
        }
    }

    setStartDate() {
        this.dateInputValue = this.withStartDate
            ? this.defaultDate.startDate + ' - ' + this.defaultDate.endDate
            : '';
    }

    clearCalendar(e: any) {
        const reset = e === 'reset';
        if (reset || (e.picker && this.dateInputValue)) {
            const dates = {
                start: {
                    target: {
                        name: this.fieldName + '_start',
                        value: '',
                    },
                },
                end: {
                    target: {
                        name: this.fieldName + '_end',
                        value: '',
                    },
                },
            };

            this.dateInputValue =
                reset && this.withStartDate
                    ? this.defaultDate.startDate +
                      ' - ' +
                      this.defaultDate.endDate
                    : '';

            const updateDates =
                reset && this.withStartDate
                    ? {
                          start: this.defaultDate.startDate,
                          end: this.defaultDate.endDate,
                      }
                    : {
                          start: moment().format('YYYY-MM-DD'),
                          end: moment().format('YYYY-MM-DD'),
                      };

            this.updateDateRange(updateDates);

            if (!reset) {
                this.changeDate.emit(dates);
            }
        }
    }

    updateDateRange(dates) {
        this.picker.datePicker.setStartDate(dates.start);
        this.picker.datePicker.setEndDate(dates.end);
    }
}
