import { Component, OnInit, OnChanges, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit, OnChanges {
    @Output() private changePage: EventEmitter<number> = new EventEmitter<number>();
    @Input() currentPage = 0;
    @Input() firstPageUrl = '';
    @Input() lastPage = 0;
    @Input() lastPageUrl = '';
    @Input() nextPageUrl = null;
    @Input() path = '';
    @Input() perPage = 0;
    @Input() prevPageUrl = null;
    @Input() from = 0;
    @Input() to = 0;
    @Input() total = 0;

    pages = [];
    constructor() {}

    ngOnInit() {}
    ngOnChanges() {
        this.getIntervalPage();
    }

    getIntervalPage() {
        this.pages = [];
        for (let i = 1; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }
    next() {
        this.changePage.emit(this.currentPage + 1);
    }
    previous() {
        this.changePage.emit(this.currentPage - 1);
    }

    last() {
        this.changePage.emit(this.lastPage);
    }
    first() {
        this.changePage.emit(1);
    }

    clickChangePage(event) {
        this.changePage.emit(event);
    }
}
