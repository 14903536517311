import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line-info-basic-info',
  templateUrl: './line-info-basic-info.component.html',
  styleUrls: ['./line-info-basic-info.component.scss']
})
export class LineInfoBasicInfoComponent implements OnInit {

  @Input() creditLine: any = null;
  @Input() creditLineCheck: any = null;
  @Input() accountManager: any = null;
  @Input() lead: any = null;
  @Input() link: any = null;

  constructor() { }

  ngOnInit() {
  }

}
