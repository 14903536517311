<div style="margin-left: 10vw" *ngIf="!pending">
    <button
        class="btn btn-accept btn-circle btn-md"
        [disabled]="pending"
        (click)="cancel()"
        style="margin-right: 10px"
        title="Cancel"
    >
        <i class="fa fa-arrow-left mr-2"></i>
    </button>
    <button
        class="btn btn-reject btn-circle btn-md"
        [disabled]="pending"
        (click)="creditLineReject(activationBlocked)"
        style="margin-right: 10px"
        title="Reject"
    >
        <i class="fa fa-times mr-2 "></i>
    </button>
    <button
        class="btn btn-primary btn-circle btn-md"
        [disabled]="pending"
        *ngIf="checkNavBarService.completed"
        (click)="creditLineActive()"
        title="Active"
    >
        <i class="fa fa-star mr-2"></i>
    </button>
    <div *ngIf="showCheck" class="d-inline">
        <button
            class="btn btn-accept btn-circle btn-md"
            [disabled]="pending"
            title="Check"
            *ngIf="
                ((checkNavBarService.checkingCustomerInfo && !checkNavBarService.checkedInfo) ||
                    (checkNavBarService.checkingAntifraud && !checkNavBarService.checkedAntifraud) ||
                    (checkNavBarService.checkingDocs &&
                        (!checkNavBarService.checkedPersonalIdFront ||
                            !checkNavBarService.checkedPersonalIdBack ||
                            !checkNavBarService.checkedSelfie ||
                            !checkNavBarService.checkedAddress))) &&
                !checkNavBarService.completed
            "
            (click)="check()"
        >
            <i class="fa fa-check mr-2"></i>
        </button>
    </div>
</div>

<ng-template #activationBlocked let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Activation Blocked</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-group" #activationBlockedForm="ngForm">
            <select
                class="form-control selectpicker dataSelect dropdown-select"
                type="text"
                name="blocked_reason"
                ngModel
                #blockedReasonControl="ngModel"
                required
            >
                <option value="">Select reason</option>
                <option *ngFor="let reason of reasons" value="{{ reason.id }}">{{ reason.name }}</option>
            </select>
            <label *ngIf="blockedReasonControl.invalid && blockedReasonControl.touched" style="color:red"
                >Please, select reason</label
            >
        </form>
    </div>
    <div class="modal-footer">
        <img src="assets/img/backoffice/loading.gif" width="50px" height="50px" *ngIf="saving" />
        <button type="button" class="btn btn-outline-dark" (click)="c()">Cancel</button>
        <button
            type="submit"
            [disabled]="activationBlockedForm.invalid || pending"
            class="btn btn-accept"
            (click)="confirmReject(activationBlockedForm.value)"
        >
            {{ pending ? 'Saving' : 'Confirm' }}
        </button>
    </div>
</ng-template>
