<div *appHasPermissions="['datamanagement']" class="row">
    <div class="col-12 col-sm-6 col-md-4">
        <dni>
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="card-header-title text-center">DNI</h5>
                </div>
                <div class="card-body">
                    <p class="text-center">{{ lead?.user?.personalid }}</p>
                </div>
            </div>
        </dni>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
        <customer>
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="card-header-title text-center">
                        <div>User</div>
                        <div class="badges">
                            <span
                                *ngIf="lead?.user?.blacklisted"
                                class="badge badge-pill badge-dark"
                                >Blacklist</span
                            >
                            <span
                                *ngIf="lead?.user?.dropout"
                                class="badge badge-pill badge-warning"
                                >Drop out</span
                            >
                            <span
                                *ngIf="lead?.user?.is_asnef"
                                class="badge badge-pill badge-danger"
                                >ASNEF</span
                            >
                        </div>
                    </h5>
                </div>
                <div class="card-body">
                    <p class="text-center">
                        {{ lead?.user?.name }} {{ lead?.user?.first_surname }}
                        {{
                            lead?.user?.second_surname
                                ? lead?.user?.second_surname
                                : ''
                        }}
                    </p>
                </div>
            </div>
        </customer>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
        <mobile>
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="card-header-title text-center">Mobile</h5>
                </div>
                <div class="card-body">
                    <p
                        class="text-center"
                        [innerHTML]="formatPhone(lead?.mobile_phone)"
                    ></p>
                </div>
            </div>
        </mobile>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
        <div class="row">
            <div class="col-12">
                <app-lead-info-basic-info
                    [loan_id]="loan_id"
                    [user]="lead?.user"
                    [editInfo]="editInfo"
                >
                </app-lead-info-basic-info>
            </div>
            <div class="col-12">
                <form novalidate>
                    <div class="row justify-content-between">
                        <div
                            class="col-12 col-lg"
                            *appHasPermissions="['blacklist-add-remove']"
                        >
                            <div
                                class="form-group"
                                *ngIf="!lead?.user?.blacklisted"
                            >
                                <select
                                    *ngIf="reason"
                                    [(ngModel)]="comment"
                                    name="comment"
                                    class="form-control"
                                    [disabled]="isSending"
                                    required
                                    (change)="checkComment()"
                                >
                                    <option value="">Select option</option>
                                    <option
                                        *ngFor="let item of reason"
                                        [value]="item?.id"
                                    >
                                        {{ item?.name }}
                                    </option>
                                </select>
                                <small *ngIf="commentError" class="text-danger"
                                    >Please enter the comment</small
                                >
                            </div>
                        </div>
                        <div class="col-12 col-lg-auto text-right">
                            <div
                                class="form-group"
                                *appHasPermissions="['blacklist-add-remove']"
                            >
                                <button
                                    name="blacklist"
                                    type="button"
                                    class="btn btn-primary"
                                    [disabled]="isSending"
                                    (click)="
                                        blacklist(
                                            $event,
                                            lead?.user?.blacklisted
                                        )
                                    "
                                >
                                    {{
                                        lead?.user?.blacklisted
                                            ? 'Exlude from Blacklist'
                                            : 'Insert into Blacklist'
                                    }}
                                </button>
                            </div>
                            <div
                                class="form-group"
                                *appHasPermissions="['dropout-add-remove']"
                            >
                                <button
                                    *ngIf="!lead?.user?.dropout"
                                    name="dropout"
                                    type="button"
                                    class="btn btn-primary"
                                    [disabled]="isSending"
                                    (click)="dropout($event)"
                                >
                                    Drop Out
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
        <app-lead-info-contact-info
            [lead]="lead"
            [loan_id]="loan_id"
            [editInfo]="editInfo"
        >
        </app-lead-info-contact-info>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
        <app-lead-info-address-info
            [loan_id]="loan_id"
            [lead]="lead"
            [editInfo]="editInfo"
        >
        </app-lead-info-address-info>
    </div>
    <div class="col-12 col-md-6">
        <app-lead-info-customer-lead
            *ngIf="leads?.data?.length"
            [leads]="leads"
            [loading]="false"
        >
        </app-lead-info-customer-lead>
    </div>
    <div class="col-12 col-md-6" *ngIf="loans && loans.data">
        <app-lead-info-customer-credit-line
            *ngIf="loans?.data?.length"
            [credit_lines]="loans"
        >
        </app-lead-info-customer-credit-line>
    </div>
    <div class="col-12 col-md-6" *ngIf="history && history.length">
        <app-blacklist-history [history]="history"> </app-blacklist-history>
    </div>
    <div class="col-12 col-md-6">
        <app-asnef-status
            [user_id]="user_id"
            [loan_id]="loan_id"
        ></app-asnef-status>
    </div>
</div>
