<!--
<table id="formValues" width="31%"  class="dataTable">
  <thead><td width="9%">FORM VALUES</td><td width="9%"></td></thead>
  <tbody>
      <tr><td width="9%" style="font-weight: bold;">Name</td><td>{{user?.name}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Last Name 1</td><td>{{user?.first_surname}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Last Name 2</td><td>{{user?.second_surname}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Gender</td><td>{{sex[user?.sex]}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Birth Date</td><td>{{user?.birth_date}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Black List</td><td>test</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Job Role</td><td>{{job_type[lead?.job_type]}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Company</td><td>{{lead?.company_name}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">House Contract</td><td>{{housing_type[lead?.housing_type]}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Marital Status</td><td>{{family_status[lead?.family_status]}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Dependents</td><td>{{lead?.dependent_people}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Incomes</td><td>{{lead?.monthly_income}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Expenses</td><td>{{lead?.monthly_expenses}}</td></tr>
      <tr><td width="9%" style="font-weight: bold;">Education Level</td><td>{{education[lead?.education]}}</td></tr>
  </tbody>
</table>
!-->

<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">Form Values</h5>
    </div>
    <div class="card-body">
        <img src="assets/img/backoffice/loading.gif" width="50px" height="50px" *ngIf="loading" />
        <ul class="list-group" *ngIf="!loading">
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Personal ID</strong><span>{{ user?.personalid }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Name</strong><span>{{ user?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Last Name 1</strong><span>{{ user?.first_surname }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Last Name 2</strong><span>{{ user?.second_surname }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Gender</strong><span>{{ user?.sex?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Birth Date</strong><span>{{ user?.birth_date }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Black List</strong><span>{{ user?.blacklisted === 0 ? 'No' : 'Si' }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Job Role</strong><span>{{ lead?.job_type?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Company</strong><span>{{ lead?.company_name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>House Contract</strong><span>{{ lead?.housing_type?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Marital Status</strong><span>{{ lead?.family_status?.name }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Dependents</strong><span>{{ lead?.dependent_people }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Incomes</strong><span>{{ lead?.monthly_income }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Expenses</strong><span>{{ lead?.monthly_expenses }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Seniority at work</strong><span>{{ lead?.work_experience?.name }}</span>
            </li>
        </ul>
    </div>
</div>
