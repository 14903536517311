import { FormControl, Validators } from '@angular/forms';
import { FilterBase } from './FilterBase.model';

export class FilterDateRangeForm {
    start: FormControl;
    end: FormControl;

    constructor(range: FilterBase<any>) {
        this.start = new FormControl(range['start'] || '');

        this.end = new FormControl(range['end'] || '');
    }
}
