import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LegalMonitoringService {
    private static prefix: string = '/trial-proceedings';

    // Observable string sources
    private emitListLoading = new Subject<any>();
    // Observable string streams
    listLoadingEmitted$ = this.emitListLoading.asObservable();
    // Service message commands
    emitListLoaded(change: any) {
        this.emitListLoading.next(change);
    }

    constructor(private http: HttpClient) {}

    getStatus(): Observable<any> {
        return this.http.get(
            environment.api_url + LegalMonitoringService.prefix + '/status'
        );
    }

    getSituations(): Observable<any> {
        return this.http.get(
            environment.api_url +
                LegalMonitoringService.prefix +
                '/presentations/situations'
        );
    }

    getProceedings(params): Observable<any> {
        return this.http.get(
            environment.api_url + LegalMonitoringService.prefix,
            { params }
        );
    }

    getTrialProceeding(id): Observable<any> {
        return this.http.get(
            `${environment.api_url}${LegalMonitoringService.prefix}/${id}`
        );
    }

    getPresentations(id): Promise<any> {
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/${id}/presentations`
            )
            .toPromise();
    }

    createPresentation(params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/presentations`,
                params
            )
            .toPromise();
    }

    editPresentation(id, params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/presentations/${id}`,
                params
            )
            .toPromise();
    }

    closePresentation(id, params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/presentations/${id}/close`,
                params
            )
            .toPromise();
    }

    getDocs(params): Promise<any> {
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/documents`,
                { params }
            )
            .toPromise();
    }

    getDocsTypes(): Promise<any> {
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/documents/types`
            )
            .toPromise();
    }

    sendDoc(params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/documents`,
                params
            )
            .toPromise();
    }

    viewDoc(id): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/documents/${id}/view`,
                {
                    headers: headers,
                    responseType: 'blob',
                }
            )
            .toPromise();
    }

    downloadDoc(id): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/documents/${id}/download`,
                {
                    headers: headers,
                    responseType: 'blob',
                }
            )
            .toPromise();
    }

    getAttachments(params): Promise<any> {
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/attachments`,
                { params }
            )
            .toPromise();
    }

    getAttachmentsType(): Promise<any> {
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/attachments/types`
            )
            .toPromise();
    }

    saveAttachmentsType(params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/attachments/types`,
                params
            )
            .toPromise();
    }

    sendAttachment(params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/attachments`,
                params
            )
            .toPromise();
    }

    viewAttachment(id): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/attachments/${id}/view`,
                {
                    headers: headers,
                    responseType: 'blob',
                }
            )
            .toPromise();
    }

    downloadAttachment(id): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });
        return this.http
            .get(
                `${environment.api_url}${LegalMonitoringService.prefix}/attachments/${id}/download`,
                {
                    headers: headers,
                    responseType: 'blob',
                }
            )
            .toPromise();
    }

    toNotPresent(id: number): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/${id}/to_not_submit`,
                {}
            )
            .toPromise();
    }

    createMonitoring(params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}`,
                params
            )
            .toPromise();
    }

    editProceeding(id, params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/${id}`,
                params
            )
            .toPromise();
    }

    closeProceeding(id: number, params): Promise<any> {
        return this.http
            .post(
                `${environment.api_url}${LegalMonitoringService.prefix}/${id}/to_finish`,
                params
            )
            .toPromise();
    }
}
