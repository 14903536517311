import { Injectable } from '@angular/core';

@Injectable()
export class OperationNavBarService {

  operatingLead: boolean = false;
  operatingCreditLine: boolean = false;
  operatingCreditLineCheck: boolean = false;

  constructor() { }

  lead()
  {
    this.operatingLead = true;
    this.operatingCreditLine = false;
    this.operatingCreditLineCheck = false;
  }
  creditLine()
  {
    this.operatingLead = false;
    this.operatingCreditLine = true;
    this.operatingCreditLineCheck = false;
  }
  creditLineCheck()
  {
    this.operatingLead = false;
    this.operatingCreditLine = false;
    this.operatingCreditLineCheck = true;
  }
}
