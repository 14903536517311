<div class="card">
    <div class="card-header" role="tab" id="headingLeftOne">
        <h5 class="mb-0">Basic info</h5>
    </div>
    <div
        id="collapseLeftOne"
        class="collapse show"
        role="tabpanel"
        aria-labelledby="headingLeftOne"
        data-parent="#accordion"
    >
        <div class="card-body">
            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Account Manager</strong
                    ><span
                        >{{ accountManager?.name }} {{ accountManager?.first_surname }}
                        {{ accountManager?.second_surname }}</span
                    >
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Status</strong><span>{{ creditLine?.status?.name }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Created</strong><span>{{ creditLine?.created_at }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Activated</strong><span>{{ creditLine?.activation_date }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>End</strong><span>{{ creditLine?.expiration_date }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Lead</strong><a class="miLineaFontColor" routerLink="{{ link }}">{{ lead?.code_str }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
