<div *appHasPermissions="['finance']">
    <!-- creditline tab content -->
    <finance *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-6 panel-main-action">
                    <div class="row">
                        <div
                            *appHasPermissions="['finance-loan-all']"
                            class="col-sm-6 col-md-3 col-lg-2 panel-main-separator"
                        >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('all')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">Loans</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            routerLink="/backoffice/finance/due-debt"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/due.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Due Debt
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 panel-main-separator">
                            <div class="row">
                                <div class="col-sm-6 col-md-3 col-lg-12">
                                    <div class="row">
                                        <div
                                            *appHasPermissions="[
                                                'finance-invoicing'
                                            ]"
                                            class="col-lg-3"
                                        >
                                            <div class="panel-section">
                                                <div
                                                    class="container-description"
                                                    (click)="
                                                        gotoFinance('invoice')
                                                    "
                                                >
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/invoce.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text">
                                                        INVOICING
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *appHasPermissions="[
                                                'finance-collection'
                                            ]"
                                            class="col-lg-3"
                                        >
                                            <div class="panel-section">
                                                <div
                                                    class="container-description"
                                                    (click)="
                                                        gotoFinance(
                                                            'collection'
                                                        )
                                                    "
                                                >
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/collection.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text">
                                                        COLLECTION
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *appHasPermissions="[
                                                'finance-cashin'
                                            ]"
                                            class="col-lg-3"
                                        >
                                            <div class="panel-section">
                                                <div
                                                    class="container-description"
                                                    (click)="
                                                        gotoFinance('cashIn')
                                                    "
                                                >
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/cashin.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text">
                                                        CASH IN
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *appHasPermissions="[
                                                'finance-cashout'
                                            ]"
                                            class="col-lg-3"
                                        >
                                            <div class="panel-section">
                                                <div
                                                    class="container-description"
                                                    (click)="
                                                        gotoFinance('cashOut')
                                                    "
                                                >
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/cashout.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text">
                                                        Cash Out
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="panel-section">
                                                <div
                                                    class="container-description"
                                                    (click)="
                                                        gotoFinance(
                                                            'provisions'
                                                        )
                                                    "
                                                >
                                                    <img
                                                        class="align-middle"
                                                        width="90"
                                                        height="90"
                                                        src="assets/img/cashout.svg"
                                                        alt="Generic placeholder image"
                                                    />
                                                    <div class="icon__text">
                                                        Provisions
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="row">
                                <div class="col-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="gotoFinance('losses')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">Losses</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </finance>
    <!-- creditline tab content end -->

    <router-outlet></router-outlet>
</div>
