import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { EmailService } from '../../../../services/email.service';

@Component({
  selector: 'app-mail-messages-send-config-list',
  templateUrl: './mail-messages-send-config-list.component.html',
  styleUrls: ['./mail-messages-send-config-list.component.scss'],
})
export class MailMessagesSendConfigListComponent implements OnInit, OnDestroy {
  constructor(private emailService: EmailService) {}

  requestSubscriber: SubscriptionLike;
  dataSubscriber: SubscriptionLike;

  loading = true;
  tableData = [];

  ngOnInit(): void {
    this.requestData();
  }

  ngOnDestroy() {
    if (this.dataSubscriber) {
      this.dataSubscriber.unsubscribe();
    }
    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
    }

    this.emailService.clearMessagesTypes();
  }

  requestData() {
    this.requestSubscriber = this.emailService
      .getMessagesSendConfigsRequest()
      .subscribe((response) => {
        this.setData(response);
        this.subscribeToData();
      });
  }

  subscribeToData() {
    this.dataSubscriber = this.emailService
      .getMessagesSendConfigs()
      .subscribe((response) => {
        this.tableData = response;
        if (this.loading) {
          this.loading = false;
        }
      });
  }

  setData(data) {
    this.emailService.setMessagesSendConfigs(data);
  }

  formatDate(stringDate) {
    let formatDateString;
    if (stringDate) {
      formatDateString = new Date(stringDate.replace(/-/g, '/'));
    }
    return formatDateString;
  }
}
