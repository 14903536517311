import { UpperCasePipe, TitleCasePipe } from '@angular/common';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    PipeTransform,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, SubscriptionLike } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { CreditLineService } from '../../../../services/credit-line.service';
import { HttpService } from '../../../../services/http.service';
import { LegalMonitoringService } from '../../../../services/legal-monitoring.service';

type Province = { id: number; name: string };
type City = { id: number; province_id: number; name: string; zip: string };

@Component({
    selector: 'app-monitoring-create',
    templateUrl: './monitoring-create.component.html',
    styleUrls: ['./monitoring-create.component.scss'],
    providers: [UpperCasePipe, TitleCasePipe],
})
export class MonitoringCreateComponent implements OnInit, OnDestroy {
    @Input() view: 'short' | 'full' = 'short';
    @Input() loan;

    formFieldsSubscribers: SubscriptionLike[] = [];

    isLoading = false;
    isDebtLoading = true;
    isCityTypesLoading = false;
    isProvincesLoading = false;
    isCitiesLoading = false;
    isSending = false;

    form: FormGroup;
    cityTypes: { key: number; value: string }[];
    provinces: Province[] = [];
    cities: City[] = [];

    prevProvince: Province;

    private formEventOptions = {
        onlySelf: true,
        emitEvent: false,
        emitViewToModelChange: false,
    };

    private upperCaseFields = ['loan_code_str', 'user_personalid'];

    private nameFields = [
        'user_name',
        'user_first_surname',
        'user_second_surname',
    ];

    constructor(
        private fb: FormBuilder,
        private modal: NgbModal,
        private upperCasePipe: UpperCasePipe,
        private titleCasePipe: TitleCasePipe,
        private loanService: CreditLineService,
        private legalService: LegalMonitoringService,
        private httpService: HttpService,
        private errorService: ErrorMessageService,
        private router: Router
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.unsubscribeFromFields();
    }

    unsubscribeFromFields() {
        if (this.formFieldsSubscribers && this.formFieldsSubscribers.length) {
            this.formFieldsSubscribers.forEach((subscribtion) =>
                subscribtion.unsubscribe()
            );
        }
    }

    open(content) {
        this.modal
            .open(content, {
                size: 'lg',
                scrollable: true,
                backdrop: 'static',
                keyboard: false,
            })
            .result.then(
                (result) => this.unsubscribeFromFields(),
                (reason) => this.unsubscribeFromFields()
            );

        this.initForm(this.loan);
    }

    initForm(loan = null) {
        this.form = this.fb.group({
            loan_id: [loan?.id || '', Validators.required],
            loan_code_str: [
                this.upperCasePipe.transform(loan?.code_str || ''),
                Validators.required,
            ],
            loan_activation_at: [
                this.formatDate(loan?.activation_date || ''),
                Validators.required,
            ],
            loan_expiration_at: [
                this.formatDate(loan?.expiration_date || ''),
                Validators.required,
            ],
            loan_due_at: [
                this.formatDate(loan?.due_status_date || ''),
                Validators.required,
            ],
            loan_amount: ['', [Validators.required, Validators.min(0)]],
            loan_total_pay: ['', [Validators.required, Validators.min(0)]],
            loan_amount_paid: ['', [Validators.required, Validators.min(0)]],
            loan_debt_left: ['', [Validators.required, Validators.min(0)]],
            loan_debt_left_percent: ['', Validators.required],
            loan_amount_quote: ['', [Validators.required, Validators.min(0)]],
            loan_debt_late_fee: ['', [Validators.required, Validators.min(0)]],
            loan_fee: ['', [Validators.required, Validators.min(0)]],

            user_personalid: [
                this.upperCasePipe.transform(loan?.user?.personalid || ''),
                Validators.required,
            ],
            user_name: [
                this.titleCasePipe.transform(loan?.user?.name || ''),
                Validators.required,
            ],
            user_first_surname: [
                this.titleCasePipe.transform(loan?.user?.first_surname || ''),
                Validators.required,
            ],
            user_second_surname: [
                this.titleCasePipe.transform(loan?.user?.second_surname || ''),
            ],
            user_address_street_type_id: [
                loan?.lead?.street_type_id || '',
                Validators.required,
            ],
            user_address_street: [
                loan?.lead?.street || '',
                Validators.required,
            ],
            user_address_street_number: [
                loan?.lead?.street_number || '',
                Validators.required,
            ],
            user_address_street_additional: [
                loan?.lead?.street_additional || '',
            ],
            user_address_province: [
                loan?.lead?.province_id
                    ? {
                          id: +loan.lead.province_id,
                          name: loan?.lead?.province,
                      }
                    : '',
                Validators.required,
            ],
            user_address_city: [
                loan?.lead?.province_id && loan?.lead?.city_id
                    ? {
                          id: loan.lead.city_id,
                          province_id: loan.lead.province_id,
                          name: loan.lead.city,
                          zip: loan.lead.postal_code,
                      }
                    : '',
                Validators.required,
            ],
        });

        this.applyPipes();

        if (loan) {
            this.form.markAllAsTouched();
            this.getLoanDebt(loan.id, this.form);

            if (!loan.lead.province_id) {
                this.getProvinceByZip(loan.lead.postal_code);
            }
        }

        this.getStreetType();
        this.getProvinces();
    }

    getLoan(loanId: number) {
        this.isLoading = true;

        this.loanService.getById(loanId).then((response) => {
            this.isLoading = false;
        });
    }

    getLoanDebt(loanId: number, form: FormGroup) {
        this.isDebtLoading = true;

        this.formFieldsSubscribers.push(
            this.loanService
                .getAllDebtLeftSummary(loanId)
                .subscribe(
                    ({
                        amount,
                        debt_left,
                        fee,
                        late_fee_amount,
                        total_amount_paid,
                        weekly,
                    }) => {
                        const totalPay = +amount + +fee + +late_fee_amount;
                        form.controls.loan_amount.setValue(+amount || 0);
                        form.controls.loan_total_pay.setValue(totalPay || 0);
                        form.controls.loan_amount_paid.setValue(
                            +total_amount_paid || 0
                        );
                        form.controls.loan_debt_left.setValue(+debt_left || 0);
                        form.controls.loan_debt_left_percent.setValue(
                            (+debt_left / totalPay).toPrecision(2) || 0
                        );

                        form.controls.loan_amount_quote.setValue(+weekly || 0);
                        form.controls.loan_fee.setValue(+fee || 0);
                        form.controls.loan_debt_late_fee.setValue(
                            +late_fee_amount || 0
                        );

                        this.isDebtLoading = false;
                    }
                )
        );
    }

    getStreetType() {
        if (this.cityTypes) {
            return;
        }

        this.isCityTypesLoading = true;
        this.form.controls.user_address_street_type_id.disable();
        this.httpService
            .requestInfo()
            .toPromise()
            .then(({ lead_street_type }) => {
                if (lead_street_type) {
                    this.cityTypes = Object.keys(lead_street_type).map(
                        (key) => ({
                            key: +key,
                            value: lead_street_type[key] as string,
                        })
                    );
                }

                this.isCityTypesLoading = false;
                this.form.controls.user_address_street_type_id.enable();
            });
    }

    getProvinces() {
        if (this.provinces.length) {
            return;
        }

        this.isProvincesLoading = true;
        this.form.controls.user_address_province.disable();

        this.httpService.getProvinces().then((response) => {
            this.provinces = response;

            this.isProvincesLoading = false;
            this.form.controls.user_address_province.enable();
        });
    }

    getCities(provinceId: number) {
        this.isCitiesLoading = true;
        this.form.controls.user_address_city.disable();

        this.httpService.getCity(provinceId).then((response) => {
            this.cities = response;

            this.isCitiesLoading = false;
            this.form.controls.user_address_city.enable();
        });
    }

    getProvinceByZip(zipCode) {
        this.httpService.getCityByZip(zipCode).then(({ data }) => {
            if (data && data.length) {
                const { id, name, province_id, province, zip } = data[0];

                this.form.controls.user_address_province.setValue({
                    id: province.id,
                    name: province.name,
                });

                this.form.controls.user_address_city.setValue({
                    id,
                    province_id,
                    name,
                    zip,
                });
            }
        });
    }

    applyPipes() {
        this.applyPipe(this.upperCaseFields, this.upperCasePipe);
        this.applyPipe(this.nameFields, this.titleCasePipe);

        this.formFieldsSubscribers.push(
            this.form.controls.loan_total_pay.valueChanges.subscribe((val) => {
                const debt_left = this.form.controls.loan_debt_left.value;
                this.form.controls.loan_debt_left_percent.setValue(
                    +val ? (+debt_left / +val).toPrecision(2) : 0
                );
            })
        );

        this.formFieldsSubscribers.push(
            this.form.controls.loan_debt_left.valueChanges.subscribe((val) => {
                const totalPay = this.form.controls.loan_total_pay.value;
                this.form.controls.loan_debt_left_percent.setValue(
                    +totalPay ? (+val / +totalPay).toPrecision(2) : 0
                );
            })
        );

        this.formFieldsSubscribers.push(
            this.form.controls.user_address_province.valueChanges.subscribe(
                (val) => {
                    if (!val) {
                        this.form.controls.user_address_city.disable();
                        this.form.controls.user_address_city.setValue('');
                    } else {
                        if (+this.prevProvince?.id !== +val.id) {
                            this.prevProvince = val;
                            this.getCities(val.id);
                        } else {
                            this.form.controls.user_address_city.enable();
                        }
                    }
                }
            )
        );
    }

    applyPipe(fields: string | string[], pipe: PipeTransform): void {
        const formSubscriber = (field: string) =>
            this.formFieldsSubscribers.push(
                this.form.controls[field].valueChanges.subscribe((val) =>
                    this.form.controls[field].setValue(
                        pipe.transform(val),
                        this.formEventOptions
                    )
                )
            );

        if (Array.isArray(fields) && fields.length) {
            fields.forEach((field) => formSubscriber(field));
        }

        if (typeof fields === 'string') {
            formSubscriber(fields);
        }
    }

    formatDate(date: string = ''): string {
        return date.includes('/') ? date.split('/').reverse().join('-') : date;
    }

    searchProvince: OperatorFunction<string, readonly { id; name }[]> = (
        text$: Observable<string>
    ) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (!term.length) {
                    this.form.controls.user_address_province.setValue('');
                }
                return term.length >= 2;
            }),
            map((term) =>
                this.provinces
                    .filter((province) =>
                        new RegExp(term, 'mi').test(province.name)
                    )
                    .slice(0, 15)
            )
        );

    provinceFormatter = (province: Province) => province.name;

    searchCity: OperatorFunction<string, readonly { id; name }[]> = (
        text$: Observable<string>
    ) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (!term.length) {
                    this.form.controls.user_address_city.setValue('');
                }
                return term.length >= 2;
            }),
            map((term) =>
                this.cities
                    .filter(({ name, zip }) =>
                        new RegExp(term, 'mi').test(`${zip} · ${name}`)
                    )
                    .slice(0, 15)
            )
        );

    cityFormatter = ({ name, zip }: City) => (zip ? `${zip} · ${name}` : '');

    onSubmit() {
        this.isSending = true;
        this.form.disable();

        const {
            user_address_city,
            user_address_province,
            user_address_street_number,
            user_address_street_additional,
            loan_debt_left_percent,
            ...rawParams
        } = this.form.getRawValue();
        const params = {
            ...rawParams,
            loan_debt_left_percent: +loan_debt_left_percent,
            user_address_street_number: `${user_address_street_number}`,
            user_address_street_additional: `${user_address_street_additional}`,
            user_address_city_id: user_address_city.id,
            user_address_province_id: user_address_province.id,
            user_address_zip: user_address_city.zip,
        };

        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'string') {
                params[key] = params[key].trim();
            }
            if (params[key] === null || params[key] === undefined) {
                delete params[key];
            }
        });

        this.legalService
            .createMonitoring(params)
            .then(({ id }) => {
                this.errorService.showSuccess('Añadido con Éxito.');
                this.modal.dismissAll();
                this.router.navigate([
                    `backoffice/legal/monitoring/trial-proceeding/${id}`,
                ]);
                this.form.enable();
            })
            .catch((err) => {
                const { show_message, details } = err?.error?.error;
                const message =
                    details && Object.keys(details).length
                        ? Object.keys(details)
                              .map((key) => details[key].join('<br />'))
                              .join('<br />')
                        : '';

                this.errorService.showError(
                    message,
                    show_message || 'Service Error',
                    { timeOut: 6000, enableHtml: true }
                );
                this.isSending = false;
                this.form.enable();
            });
    }
}
