import { Component, Input, OnInit } from '@angular/core';
import { LegalMonitoringService } from '../../../../../../services/legal-monitoring.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-presentation-attachments-list',
    templateUrl: './presentation-attachments-list.component.html',
    styleUrls: ['./presentation-attachments-list.component.scss'],
})
export class PresentationAttachmentsListComponent implements OnInit {
    @Input() proceedingId: number;
    @Input() presentationId: number;
    @Input() closed: boolean;

    isLoading = false;
    isFileLoading = false;
    tableData;

    files = new Map();
    src;

    constructor(private legalMonitoringService: LegalMonitoringService) {}

    ngOnInit(): void {
        if (this.proceedingId && this.presentationId) {
            this.getTableData({
                proceeding_id: this.proceedingId,
                presentation_id: this.presentationId,
            });
        }
    }

    getTableData(params) {
        this.isLoading = true;
        this.legalMonitoringService.getAttachments(params).then((response) => {
            this.tableData = response;
            this.isLoading = false;
        });
    }

    onAdded({ proceeding_id, presentation_id }) {
        this.getTableData({
            proceeding_id,
            presentation_id,
        });
    }

    view(id) {
        if (this.files.has(id)) {
            window.open(this.files.get(id));
        } else {
            this.legalMonitoringService.viewAttachment(id).then((pdf) => {
                const file = new Blob([pdf], { type: 'application/pdf' });
                const fileUrl = URL.createObjectURL(file);
                this.files.set(id, fileUrl);
                window.open(fileUrl);
                this.isFileLoading = false;
            });
        }
    }

    download(id, name) {
        this.isFileLoading = true;
        this.legalMonitoringService
            .downloadAttachment(id)
            .then((pdf) => {
                let file = new Blob([pdf], {
                    type: 'application/pdf',
                });
                saveAs(file, `${name}.pdf`);
                this.isFileLoading = false;
            })
            .catch((e) => {
                this.isFileLoading = false;
            });
    }
}
