<!--
<div class="tab">
        <button class="tablinks tablinks-selected" [routerLink]="'/backoffice/settings/validation_overview/discard_criterion'">Discard</button>
        <button class="tablinks" [routerLink]="'/backoffice/settings/validation_overview/discard_antifraud'">Discard Antifraud</button>
        <button class="tablinks" [routerLink]="'/backoffice/settings/validation_overview/advising_antifraud'">Advising Antifraud</button>
        <button class="tablinks" [routerLink]="'/backoffice/settings/validation_overview/asnef'">Asnef</button>
        <button class="tablinks" [routerLink]="'/backoffice/settings/validation_overview/scoring'">Scoring</button>
      </div>
!-->

                  <!-- horizontal menu -->
                  <section id='submenu'>
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" (click)="goto('discard_criterion', $event)" id="pills-leadinfo-tab" data-toggle="pill" role="tab" aria-controls="pills-leadinfo" aria-selected="true">Discard</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="goto('discard_antifraud', $event)" id="pills-userinfo-tab" data-toggle="pill"  role="tab" aria-controls="pills-userinfo" aria-selected="false">Discard Antifraud</a>
                        </li>
                        <li class="nav-item">
                                <a class="nav-link" (click)="goto('advising_antifraud', $event)" id="pills-antifraud-tab" data-toggle="pill" role="tab" aria-controls="pills-antifraud" aria-selected="false">Advising Antifraud</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="goto('asnef', $event)" id="pills-antifraud-tab" data-toggle="pill" role="tab" aria-controls="pills-antifraud" aria-selected="false">Asnef</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" (click)="goto('scoring', $event)" id="pills-antifraud-tab" data-toggle="pill" role="tab" aria-controls="pills-antifraud" aria-selected="false">Scoring</a>
                      </li>
                    </ul>
                </section>
            <!-- horizontal menu end -->

<router-outlet></router-outlet>