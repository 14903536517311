import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaderResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { ErrorMessageService } from '../../shared/error-message.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css', '../../app.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private http: HttpClient,
        private errorMessage: ErrorMessageService
    ) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    login(data) {
        this.loading = true;
        this.authService
            .login(data.username, data.password)
            .toPromise()
            .then(
                (loginData: any) => {
                    if (loginData.token) {
                        localStorage.setItem('token_admin', loginData.token);
                        this.authService.getUserPermissions().subscribe(permissions => {
                            this.authService.userPermissions = permissions;
                            this.loading = true;
                        });
                        this.router.navigate(['backoffice']);
                    } else {
                        this.errorMessage.showError('Credenciales Incorrecta');
                    }
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    if (error.error.error) {
                        this.errorMessage.showError(error.error.error.show_message);
                    } else {
                        this.errorMessage.showError('Ha ocurrido un error, póngase en contacto con support');
                    }
                }
            );
    }
}
