<!-- horizontal menu -->
<section>
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/leadInfo/{{id}}" routerLinkActive="active" id="pills-leadinfo-tab" data-toggle="pill" role="tab" aria-controls="pills-leadinfo" aria-selected="true">Leads Info</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/userInfo/{{id}}" routerLinkActive="active" id="pills-userinfo-tab" data-toggle="pill"  role="tab" aria-controls="pills-userinfo" aria-selected="false">User Info</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/antiFraud/{{id}}" routerLinkActive="active" id="pills-antifraud-tab" data-toggle="pill" role="tab" aria-controls="pills-antifraud" aria-selected="false">Anti-fraud</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/scoringDetails/{{id}}" routerLinkActive="active" id="pills-scoring-tab" data-toggle="pill" role="tab" aria-controls="pills-scoring" aria-selected="false">Scoring details</a>
            </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/docs/{{id}}" routerLinkActive="active" id="pills-leadoc-tab" data-toggle="pill"  role="tab" aria-controls="pills-leadoc" aria-selected="false">Leads Docs</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/smsLog/{{id}}" routerLinkActive="active" id="pills-smslog-tab" data-toggle="pill"  role="tab" aria-controls="pills-smslog" aria-selected="false">SMS log</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/backoffice/underwriting/overview/{{id}}/emailLog/{{id}}" routerLinkActive="active" id="pills-emailog-tab" data-toggle="pill"  role="tab" aria-controls="pills-emailog" aria-selected="false">Email log</a>
        </li>
        
        <app-operation-nav-bar [lead]="lead"></app-operation-nav-bar>

    </ul>
</section>
<!-- horizontal menu end -->


<!--
<div class="tab">
    <button class="tablinks tablinks-selected" (click)="goto('leadInfo', $event)">Lead Info</button>
    <button class="tablinks" (click)="goto('userInfo', $event)">User Info</button>
    <button class="tablinks" (click)="goto('antiFraud', $event)">Anti-fraud</button>
    <button class="tablinks" (click)="goto('scoringDetails', $event)">Scoring details</button>
    <button class="tablinks" (click)="goto('docs', $event)">Lead Docs</button>
    <button class="tablinks" (click)="goto('smsLog', $event)">SMS log</button>
    <button class="tablinks" (click)="goto('emailLog', $event)">Email log</button>
    <a class="tablinks" style=" width: 3% !important;">
        <img src="assets/img/backoffice/sms.svg">
    </a>
    <a class="tablinks" style=" width: 3% !important;">
        <img src="assets/img/backoffice/mail.svg">
    </a>
    <a class="tablinks" style=" width: 3% !important;">
        <img src="assets/img/backoffice/x.svg">
    </a>
    
</div>
!-->


<app-lead-info-summary [lead]="lead" ></app-lead-info-summary>

<router-outlet></router-outlet>