import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike } from 'rxjs';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { EmailService } from '../../../../services/email.service';

@Component({
    selector: 'app-messages-create',
    templateUrl: './messages-create.component.html',
    styleUrls: ['./messages-create.component.scss'],
})
export class MessagesCreateComponent implements OnInit, OnDestroy {
    @Input() userID;
    form = this.fb.group({
        type_id: ['', Validators.required],
        channel_id: ['', Validators.required],
        provider_id: [{ value: '', disabled: true }],
        content: [''],
    });

    isSending = false;

    channelsSubscriber: SubscriptionLike;
    providersSubscriber: SubscriptionLike;
    typesSubscriber: SubscriptionLike;

    channels: any[];
    providers: any[];
    types: any[];

    constructor(
        private emailService: EmailService,
        private fb: FormBuilder,
        private modalService: NgbModal,
        private errorMessage: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        this.unsubscribe();
    }

    open(content) {
        this.getLists();

        this.modalService.open(content);
    }

    resetForm() {
        this.form.setValue({
            type_id: '',
            channel_id: '',
            provider_id: '',
            content: '',
        });
    }

    onSubmit() {
        this.isSending = true;
        this.form.disable();
        const params = { ...this.form.getRawValue(), user_id: this.userID };

        this.emailService.sendMessageRequest(params).subscribe(
            (resp) => {
                this.errorMessage.showSuccess('', 'Sent Successfully');
                this.isSending = false;
                this.resetForm();
                this.form.enable();
            },
            (error) => {
                this.errorMessage.showError(
                    error?.error?.error?.details,
                    'Sending Failed'
                );
                this.isSending = false;
                this.form.enable();
            }
        );
    }

    getLists() {
        this.channelsSubscriber = this.emailService
            .getChannels()
            .subscribe(
                (resp) =>
                    (this.channels =
                        resp.filter(({ enabled }) => enabled) || [])
            );

        this.typesSubscriber = this.emailService
            .getMessagesTypesRequest()
            .subscribe(
                (resp) =>
                    (this.types =
                        resp.filter(
                            ({ enabled, published }) => enabled && published
                        ) || [])
            );
    }

    unsubscribe() {
        if (this.channelsSubscriber) this.channelsSubscriber.unsubscribe();
        if (this.providersSubscriber) this.providersSubscriber.unsubscribe();
        if (this.typesSubscriber) this.typesSubscriber.unsubscribe();
    }

    onChannelChanged() {
        const { channel_id } = this.form.getRawValue();
        this.form.controls.provider_id.setValue('');
        this.form.controls.provider_id.disable();

        if (channel_id) {
            this.providersSubscriber = this.emailService
                .getProvidersByChannel(channel_id)
                .subscribe((resp) => {
                    this.providers =
                        resp.filter(({ enabled }) => enabled) || [];
                    if (this.providers.length) {
                        this.form.controls.provider_id.enable();
                    }
                });
        } else {
            if (this.providersSubscriber) {
                this.providersSubscriber.unsubscribe();
            }
        }
    }
}
