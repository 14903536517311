import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreditLineService } from './components/services/credit-line.service';
import { LeadService } from './components/services/lead.service';
import { LineRequestInfoService } from './components/services/line-request-info.service';
import { HeaderService } from './components/services/header.service';
import { HttpService } from './components/services/http.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './components/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';

// import { Location, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';

import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { RefreshTokenInterceptor } from './shared/interceptors/refresh-token.interceptor';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { ValidDniDirective } from './shared/directives/valid-dni.directive';
import { ValidEmailDirective } from './shared/directives/valid-email.directive';
import { ValidJobTypeDirective } from './shared/directives/valid-job-type.directive';
import { ValidStreetTypeDirective } from './shared/directives/valid-street-type.directive';
import { ValidOtherPhoneDirective } from './shared/directives/valid-other-phone.directive';
import { ValidMonthDirective } from './shared/directives/valid-month.directive';

import { OutOfServiceComponent } from './shared/out-of-service/out-of-service.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';

import { ValidCreditCardDirective } from './shared/directives/valid-credit-card.directive';
import { ValidAmountPaymentDirective } from './shared/directives/valid-amount-payment.directive';
import { ValidBaccDirective } from './shared/directives/valid-bacc.directive';
import { SelectBankaccDirective } from './shared/directives/select-bankacc.directive';
import { ValidPinDirective } from './shared/directives/valid-pin.directive';
import { BackofficeLoginComponent } from './components/BackOffice/backoffice-login/backoffice-login.component';
import { BackofficeHomeComponent } from './components/BackOffice/backoffice-home/backoffice-home.component';
import { BackofficeMiniKpisComponent } from './components/BackOffice/backoffice-mini-kpis/backoffice-mini-kpis.component';
import { BackofficeHeaderComponent } from './components/BackOffice/backoffice-header/backoffice-header.component';

import { BackofficeCreditlinesComponent } from './components/BackOffice/creditlines/backoffice-creditlines/backoffice-creditlines.component';
import { BackofficeDatamanagementComponent } from './components/BackOffice/datamanagement/backoffice-datamanagement/backoffice-datamanagement.component';
import { BackofficeDebtrecoveryComponent } from './components/BackOffice/debtrecovery/backoffice-debtrecovery/backoffice-debtrecovery.component';
import { BackofficeFinanceComponent } from './components/BackOffice/finance/backoffice-finance/backoffice-finance.component';
import { BackofficeKpisComponent } from './components/BackOffice/kpis/backoffice-kpis/backoffice-kpis.component';
import { BackofficeLegalComponent } from './components/BackOffice/legal/backoffice-legal/backoffice-legal.component';
import { BackofficeMarketingComponent } from './components/BackOffice/marketing/backoffice-marketing/backoffice-marketing.component';
import { BackofficeSettingsComponent } from './components/BackOffice/settings/backoffice-settings/backoffice-settings.component';
import { BackofficeUnderwritingComponent } from './components/BackOffice/underwriting/backoffice-underwriting/backoffice-underwriting.component';
import { LeadTableComponent } from './components/BackOffice/underwriting/lead-table/lead-table.component';
import { LeadOverviewComponent } from './components/BackOffice/underwriting/lead-overview/lead-overview.component';
import { CreditLineTableComponent } from './components/BackOffice/creditlines/credit-line-table/credit-line-table.component';
import { CreditLineOverviewComponent } from './components/BackOffice/creditlines/credit-line-overview/credit-line-overview.component';
import { LeadUserInfoComponent } from './components/BackOffice/underwriting/lead-user-info/lead-user-info.component';
import { LeadAntiFraudComponent } from './components/BackOffice/underwriting/lead-anti-fraud/lead-anti-fraud.component';
import { LeadScoringDetailsComponent } from './components/BackOffice/underwriting/lead-scoring-details/lead-scoring-details.component';
import { LeadDocsComponent } from './components/BackOffice/underwriting/lead-docs/lead-docs.component';
import { LeadSmsLogComponent } from './components/BackOffice/underwriting/lead-sms-log/lead-sms-log.component';
import { LeadEmailLogComponent } from './components/BackOffice/underwriting/lead-email-log/lead-email-log.component';
import { LeadInfoComponent } from './components/BackOffice/underwriting/lead-info/lead-info.component';
import { PagerService } from './components/services/pager.service';

import { ShareService } from './components/services/share.service';

import { KeysPipe } from './shared/pipes/keys.pipe';
import { WithdrawalService } from './components/services/withdrawal.service';
import { PaymentBankAccountService } from './components/services/payment-bank-account.service';
import { PaymentBankCardService } from './components/services/payment-bank-card.service';
import { ErrorMessageComponent } from './shared/error-message/error-message.component';
import { PaymentService } from './components/services/payment.service';
import { PaymentRuleService } from './components/services/payment-rule.service';

import { LoginComponent } from './components/login/login.component';
import { LeadInfoFormValueComponent } from './components/BackOffice/shared/lead-info-form-value/lead-info-form-value.component';
import { LeadInfoSummaryComponent } from './components/BackOffice/shared/lead-info-summary/lead-info-summary.component';
import { LeadInfoOtherComponent } from './components/BackOffice/shared/lead-info-other/lead-info-other.component';
import { LeadInfoBankAggregationDetailComponent } from './components/BackOffice/shared/lead-info-bank-aggregation-detail/lead-info-bank-aggregation-detail.component';
import { LeadInfoCustomerLeadComponent } from './components/BackOffice/shared/lead-info-customer-lead/lead-info-customer-lead.component';
import { LeadInfoAsnefValidationComponent } from './components/BackOffice/shared/lead-info-asnef-validation/lead-info-asnef-validation.component';
import { LeadInfoSavsoValidationComponent } from './components/BackOffice/shared/lead-info-savso-validation/lead-info-savso-validation.component';
import { LeadInfoBasicInfoComponent } from './components/BackOffice/shared/lead-info-basic-info/lead-info-basic-info.component';
import { LeadInfoContactInfoComponent } from './components/BackOffice/shared/lead-info-contact-info/lead-info-contact-info.component';
import { LeadInfoAddressInfoComponent } from './components/BackOffice/shared/lead-info-address-info/lead-info-address-info.component';
import { LeadInfoThirdPartyComponent } from './components/BackOffice/shared/lead-info-third-party/lead-info-third-party.component';
import { LeadInfoContractTrackingComponent } from './components/BackOffice/shared/lead-info-contract-tracking/lead-info-contract-tracking.component';
import { LeadInfoAddressTrackingComponent } from './components/BackOffice/shared/lead-info-address-tracking/lead-info-address-tracking.component';
import { LeadInfoDiscardValidationComponent } from './components/BackOffice/shared/lead-info-discard-validation/lead-info-discard-validation.component';
import { LeadInfoAdvisingValidationComponent } from './components/BackOffice/shared/lead-info-advising-validation/lead-info-advising-validation.component';
import { LeadInfoBankAggregationComponent } from './components/BackOffice/shared/lead-info-bank-aggregation/lead-info-bank-aggregation.component';
import { LeadInfoCustomerCreditLineComponent } from './components/BackOffice/shared/lead-info-customer-credit-line/lead-info-customer-credit-line.component';
import { ContactTrackingService } from './components/services/contact-tracking.service';
import { DocsService } from './components/services/docs.service';
import { SmsService } from './components/services/sms.service';
import { EmailService } from './components/services/email.service';
import { CreditLineCheckingIdTableComponent } from './components/BackOffice/creditlines/credit-line-checking-id-table/credit-line-checking-id-table.component';
import { CreditLineCheckComponent } from './components/BackOffice/creditlines/credit-line-check/credit-line-check.component';
import { CreditLineLeadInfoComponent } from './components/BackOffice/creditlines/credit-line-lead-info/credit-line-lead-info.component';
import { CreditLineDocsComponent } from './components/BackOffice/creditlines/credit-line-docs/credit-line-docs.component';
import { CreditLineLineInfoComponent } from './components/BackOffice/creditlines/credit-line-line-info/credit-line-line-info.component';
import { LineInfoBasicInfoComponent } from './components/BackOffice/shared/line-info-basic-info/line-info-basic-info.component';
import { LineInfoCustomerInfoComponent } from './components/BackOffice/shared/line-info-customer-info/line-info-customer-info.component';
import { ContactPhoneFormComponent } from './components/BackOffice/shared/contact-phone-form/contact-phone-form.component';
import {
    ToastrModule,
    ToastNoAnimationModule,
    ToastNoAnimation,
} from 'ngx-toastr';
import { ErrorMessageService } from './shared/error-message.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LeadInfoScoringGraphicComponent } from './components/BackOffice/shared/lead-info-scoring-graphic/lead-info-scoring-graphic.component';
import { ScoringService } from './components/services/scoring.service';
import { AuthGuard } from './guards/auth.guard';
import { SettingOverviewComponent } from './components/BackOffice/settings/setting-overview/setting-overview.component';
import { SettingUsersComponent } from './components/BackOffice/settings/setting-users/setting-users.component';
import { SettingCreateUserComponent } from './components/BackOffice/settings/setting-create-user/setting-create-user.component';
import { SettingViewUserComponent } from './components/BackOffice/settings/setting-view-user/setting-view-user.component';
import { SettingUserListComponent } from './components/BackOffice/settings/setting-user-list/setting-user-list.component';
import { SettingUserOverviewComponent } from './components/BackOffice/settings/setting-user-overview/setting-user-overview.component';
import { SettingValidationsComponent } from './components/BackOffice/settings/setting-validations/setting-validations.component';
import { SettingValidationOverviewComponent } from './components/BackOffice/settings/setting-validation-overview/setting-validation-overview.component';
import { SettingValidationDiscardCriterionComponent } from './components/BackOffice/settings/setting-validation-discard-criterion/setting-validation-discard-criterion.component';
import { SettingValidationDiscardAntifraudComponent } from './components/BackOffice/settings/setting-validation-discard-antifraud/setting-validation-discard-antifraud.component';
import { SettingValidationAdvisingAntifraudComponent } from './components/BackOffice/settings/setting-validation-advising-antifraud/setting-validation-advising-antifraud.component';
import { SettingValidationAsnefComponent } from './components/BackOffice/settings/setting-validation-asnef/setting-validation-asnef.component';
import { SettingValidationScoringComponent } from './components/BackOffice/settings/setting-validation-scoring/setting-validation-scoring.component';
import { SettingValidationSavsoComponent } from './components/BackOffice/settings/setting-validation-savso/setting-validation-savso.component';
import { SettingCreateRoleComponent } from './components/BackOffice/settings/setting-create-role/setting-create-role.component';
import { SettingCreatePermissionComponent } from './components/BackOffice/settings/setting-create-permission/setting-create-permission.component';
import { SettingRolePermissionComponent } from './components/BackOffice/settings/setting-role-permission/setting-role-permission.component';
import { SettingsProductOverviewComponent } from './components/BackOffice/settings/settings-product-overview/settings-product-overview.component';
import { SettingsCreateProductComponent } from './components/BackOffice/settings/settings-create-product/settings-create-product.component';
import { SettingsProductListComponent } from './components/BackOffice/settings/settings-product-list/settings-product-list.component';
import { ProductService } from './components/services/product.service';

import { AsnefService } from './components/services/asnef.service';
import { DiscardCriterionService } from './components/services/discard-criterion.service';
import { DiscardAntifraudService } from './components/services/discard-antifraud.service';
import { AdvisingAntifraudService } from './components/services/advising-antifraud.service';
import { SettingsFaqOverviewComponent } from './components/BackOffice/settings/settings-faq-overview/settings-faq-overview.component';
import { SettingsFaqListComponent } from './components/BackOffice/settings/settings-faq-list/settings-faq-list.component';
import { SettingsCreateFaqComponent } from './components/BackOffice/settings/settings-create-faq/settings-create-faq.component';
import { FaqService } from './components/services/faq.service';
import { CheckNavBarComponent } from './components/BackOffice/shared/check-nav-bar/check-nav-bar.component';
import { CheckNavBarService } from './components/services/check-nav-bar.service';
import { OperationNavBarComponent } from './components/BackOffice/shared/operation-nav-bar/operation-nav-bar.component';
import { OperationNavBarService } from './components/services/operation-nav-bar.service';
import { LeadTrackingService } from './components/services/lead-tracking.service';
import { PagerComponent } from './shared/pager/pager.component';
import { RefreshTokenService } from './components/services/refresh-token.service';
import { RequestInterceptorService } from './components/services/request-interceptor.service';
import { DonutChartComponent } from './shared/donut-chart/donut-chart.component';
import { LeadInfoScoringGraphicsComponent } from './components/BackOffice/shared/lead-info-scoring-graphics/lead-info-scoring-graphics.component';
import { KpisAdminComponent } from './components/BackOffice/kpis/kpis-admin/kpis-admin.component';
import { KpisBumComponent } from './components/BackOffice/kpis/kpis-bum/kpis-bum.component';
import { KpisAmComponent } from './components/BackOffice/kpis/kpis-am/kpis-am.component';
import { KpisDcComponent } from './components/BackOffice/kpis/kpis-dc/kpis-dc.component';
import { FormatPhoneNumberService } from './components/services/format-phone-number.service';
import { BankTransactionService } from './components/services/bank_transaction.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { FinanceInvoiceComponent } from './components/BackOffice/finance/finance-invoice/finance-invoice.component';
import { FinanceService } from './components/services/finance.service';
import { FinanceCollectionComponent } from './components/BackOffice/finance/finance-collection/finance-collection.component';
import { FinanceCashinComponent } from './components/BackOffice/finance/finance-cashin/finance-cashin.component';
import { FinanceCashoutComponent } from './components/BackOffice/finance/finance-cashout/finance-cashout.component';
import { FinanceCashCommitedComponent } from './components/BackOffice/finance/finance-cash-commited/finance-cash-commited.component';
import { ProcessPaymentComponent } from './components/BackOffice/process-payment/process-payment.component';
import { ContactInfoService } from './components/services/contact-info.service';
import { CheckPaymentComponent } from './components/BackOffice/check-payment/check-payment.component';
import { TrackingComponent } from './components/BackOffice/tracking/tracking.component';
import { TrackingContentComponent } from './components/BackOffice/tracking/tracking-content/tracking-content.component';
import { TrackingService } from './components/services/tracking.service';
import { TextMaskModule } from 'angular2-text-mask';
import { MiniModalService } from './components/services/mini-modal.service';
import { DaterangePickerComponent } from './components/BackOffice/daterangepicker/daterangepicker.component';
import { CollectionIncidenceComponent } from './components/BackOffice/debtrecovery/collection-incidence/collection-incidence.component';
import { DebtrecoveryDueComponent } from './components/BackOffice/debtrecovery/debtrecovery-due/debtrecovery-due.component';
import { DueAssignationComponent } from './components/BackOffice/debtrecovery/due-assignation/due-assignation.component';
import { UsersTableComponent } from './components/BackOffice/datamanagement/users-table/users-table.component';
import { CallCenterActivityComponent } from './components/BackOffice/datamanagement/call-center-activity/call-center-activity.component';
import { DatamanagementUserInfoComponent } from './components/BackOffice/datamanagement/datamanagement-user-info/datamanagement-user-info.component';
import { ValidIsNotDirective } from './shared/directives/valid-is-not.directive';
import { DebtRecoveryService } from './components/services/debt-recovery.service';
import { UserService } from './components/services/user.service';
import { LaunchManagerComponent } from './components/BackOffice/debtrecovery/launch-manager/launch-manager.component';
import { SearchComponent } from './components/BackOffice/search/search.component';
import { HighlightPipe } from './shared/pipes/highlight.pipe';
import { KpisLoansComponent } from './components/BackOffice/kpis/kpis-loans/kpis-loans.component';
import { KpisOneViewComponent } from './components/BackOffice/kpis/kpis-one-view/kpis-one-view.component';
import { KpisFinancialDataComponent } from './components/BackOffice/kpis/kpis-financial-data/kpis-financial-data.component';
import { KpisDebtRecoveryComponent } from './components/BackOffice/kpis/kpis-debt-recovery/kpis-debt-recovery.component';
import { ReplacePipe } from './shared/pipes/replace.pipe';
import { KpiService } from './components/services/kpi.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HasPermissionsDirective } from './shared/directives/has-permissions.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SaveFiltersService } from './components/services/save-filters.service';
import { CreditLineTestComponent } from './components/BackOffice/creditlines/credit-line-test/credit-line-test.component';
import { DropdownRangeComponent } from './components/BackOffice/shared/dropdown-range/dropdown-range.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatepickerFilterComponent } from './components/BackOffice/shared/datepicker-filter/datepicker-filter.component';
import { AffiliatesSummaryComponent } from './components/BackOffice/marketing/affiliates-summary/affiliates-summary.component';
import { AffiliatesManagerComponent } from './components/BackOffice/marketing/affiliates-manager/affiliates-manager.component';
import { AffiliateInformationComponent } from './components/BackOffice/marketing/affiliate-information/affiliate-information.component';
import { AffiliatesService } from './components/services/affiliates.service';
import { CreateAffiliateComponent } from './components/BackOffice/shared/create-affiliate/create-affiliate.component';
import { CreateSourceComponent } from './components/BackOffice/shared/create-source/create-source.component';
import { AffiliateLogComponent } from './components/BackOffice/shared/affiliate-log/affiliate-log.component';

import { DaterangeFilterComponent } from './components/BackOffice/shared/filters/daterange-filter/daterange-filter.component';
import { FiltersFormService } from './components/services/filters-form.service';
import { PaymentRectificationComponent } from './components/BackOffice/shared/payment-rectification/payment-rectification.component';
import { LoanRectificationComponent } from './components/BackOffice/shared/loan-rectification/loan-rectification.component';
import { DataPageNavComponent } from './components/BackOffice/shared/data-page/data-page-nav/data-page-nav.component';
import { DueDebtComponent } from './components/BackOffice/finance/due-debt/due-debt.component';
import { BlacklistHistoryComponent } from './components/BackOffice/shared/blacklist-history/blacklist-history.component';
import { DateFormatPipe } from './shared/pipes/date-format.pipe';
import { AsnefStatusComponent } from './components/BackOffice/shared/asnef-status/asnef-status.component';
import { AsnefExludeComponent } from './components/BackOffice/shared/asnef-exlude/asnef-exlude.component';
import { LossesComponent } from './components/BackOffice/finance/losses/losses.component';
import { QueueManagerComponent } from './components/BackOffice/debtrecovery/queue-manager/queue-manager.component';
import { QueueCollectionComponent } from './components/BackOffice/debtrecovery/queue-manager/queue-collection/queue-collection.component';
import { QueueHistoryComponent } from './components/BackOffice/debtrecovery/queue-history/queue-history.component';
import { QueueTableComponent } from './components/BackOffice/debtrecovery/queue-manager/queue-table/queue-table.component';
import { PaymentTreeComponent } from './components/BackOffice/settings/payment-tree/payment-tree.component';
import { PaymentTreeNodeListComponent } from './components/BackOffice/settings/payment-tree/payment-tree-node-list/payment-tree-node-list.component';
import { PaymentTreeCreateComponent } from './components/BackOffice/settings/payment-tree/payment-tree-create/payment-tree-create.component';
import { PaymentTreeListComponent } from './components/BackOffice/settings/payment-tree/payment-tree-list/payment-tree-list.component';
import { PaymentTreeItemComponent } from './components/BackOffice/settings/payment-tree/payment-tree-item/payment-tree-item.component';
import { PaymentTreeNodeComponent } from './components/BackOffice/settings/payment-tree/payment-tree-node/payment-tree-node.component';
import { PaymentTreeNodeCreateComponent } from './components/BackOffice/settings/payment-tree/payment-tree-node-create/payment-tree-node-create.component';
import { PaymentTreeUpdateComponent } from './components/BackOffice/settings/payment-tree/payment-tree-update/payment-tree-update.component';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { KpisConcessionsComponent } from './components/BackOffice/kpis/kpis-concessions/kpis-concessions.component';
import { ChartsModule } from 'ng2-charts';
import { ProvisionsComponent } from './components/BackOffice/finance/provisions/provisions.component';
import { KpisProfitabilityComponent } from './components/BackOffice/kpis/kpis-profitability/kpis-profitability.component';
import { KpisProfitabilityEvolucionComponent } from './components/BackOffice/kpis/kpis-profitability-evolucion/kpis-profitability-evolucion.component';
import { KpisChargesComponent } from './components/BackOffice/kpis/kpis-charges/kpis-charges.component';
import { KpisBusinessComponent } from './components/BackOffice/kpis/kpis-business/kpis-business.component';
import { FeeRectificationComponent } from './components/BackOffice/shared/fee-rectification/fee-rectification.component';
import { SettingsMailTemplatesComponent } from './components/BackOffice/settings/settings-mail-templates/settings-mail-templates.component';
import { MailTemplatesComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates/mail-templates.component';
import { MailTemplatesVariblesComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates-varibles/mail-templates-varibles.component';
import { MailMessageTypesComponent } from './components/BackOffice/settings/settings-mail-templates/mail-message-types/mail-message-types.component';
import { MailMessagesSendConfigComponent } from './components/BackOffice/settings/settings-mail-templates/mail-messages-send-config/mail-messages-send-config.component';
import { CreateMailTemplateComponent } from './components/BackOffice/settings/settings-mail-templates/create-mail-template/create-mail-template.component';
import { EmailEditorComponent } from './components/BackOffice/settings/settings-mail-templates/email-editor/email-editor.component';
import { MailTemplatesVariablesListComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates-variables-list/mail-templates-variables-list.component';
import { MailTemplatesVariablesCreateComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates-variables-create/mail-templates-variables-create.component';
import { MailTemplatesActivationComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates-activation/mail-templates-activation.component';
import { MailTemplatesPublishingComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates-publishing/mail-templates-publishing.component';
import { MailMessageTypesListComponent } from './components/BackOffice/settings/settings-mail-templates/mail-message-types-list/mail-message-types-list.component';
import { MailMessageTypesCreateComponent } from './components/BackOffice/settings/settings-mail-templates/mail-message-types-create/mail-message-types-create.component';
import { MailTemplatesPreviewComponent } from './components/BackOffice/settings/settings-mail-templates/mail-templates-preview/mail-templates-preview.component';
import { MailMessagesSendConfigListComponent } from './components/BackOffice/settings/settings-mail-templates/mail-messages-send-config-list/mail-messages-send-config-list.component';
import { MailMessagesSendConfigCreateComponent } from './components/BackOffice/settings/settings-mail-templates/mail-messages-send-config-create/mail-messages-send-config-create.component';
import { MessagesComponent } from './components/BackOffice/shared/messages/messages.component';
import { MessagesListComponent } from './components/BackOffice/shared/messages/messages-list/messages-list.component';
import { MessagesCreateComponent } from './components/BackOffice/shared/messages/messages-create/messages-create.component';
import { MailProvidersStatusesComponent } from './components/BackOffice/settings/settings-mail-templates/mail-providers-statuses/mail-providers-statuses.component';
import { MailProvidersStatusesListComponent } from './components/BackOffice/settings/settings-mail-templates/mail-providers-statuses/mail-providers-statuses-list/mail-providers-statuses-list.component';
import { MailProvidersStatusesCreateComponent } from './components/BackOffice/settings/settings-mail-templates/mail-providers-statuses/mail-providers-statuses-create/mail-providers-statuses-create.component';
import { MonitoringComponent } from './components/BackOffice/legal/monitoring/monitoring.component';
import { MonitoringListComponent } from './components/BackOffice/legal/monitoring/monitoring-list/monitoring-list.component';
import { MonitoringShowComponent } from './components/BackOffice/legal/monitoring/monitoring-show/monitoring-show.component';
import { MonitoringAddressInfoComponent } from './components/BackOffice/legal/monitoring/monitoring-show/monitoring-address-info/monitoring-address-info.component';
import { MonitoringPersonalDocsComponent } from './components/BackOffice/legal/monitoring/monitoring-show/monitoring-personal-docs/monitoring-personal-docs.component';
import { MonitoringCreateComponent } from './components/BackOffice/legal/monitoring/monitoring-create/monitoring-create.component';
import { PresentationCreateComponent } from './components/BackOffice/legal/monitoring/presentation/presentation-create/presentation-create.component';
import { ValidDateDirective } from './shared/directives/valid-date.directive';
import { PresentationAttachmentsListComponent } from './components/BackOffice/legal/monitoring/presentation/attachments/presentation-attachments-list/presentation-attachments-list.component';
import { PresentationAttachmentsAddComponent } from './components/BackOffice/legal/monitoring/presentation/attachments/presentation-attachments-add/presentation-attachments-add.component';
import { PresentationDocsAddComponent } from './components/BackOffice/legal/monitoring/presentation/docs/presentation-docs-add/presentation-docs-add.component';
import { PresentationDocsListComponent } from './components/BackOffice/legal/monitoring/presentation/docs/presentation-docs-list/presentation-docs-list.component';
import { PresentationListComponent } from './components/BackOffice/legal/monitoring/presentation/presentation-list/presentation-list.component';
import { PresentationShowComponent } from './components/BackOffice/legal/monitoring/presentation/presentation-show/presentation-show.component';
import { MonitoringEditComponent } from './components/BackOffice/legal/monitoring/monitoring-edit/monitoring-edit.component';
import { MonitoringCloseComponent } from './components/BackOffice/legal/monitoring/monitoring-close/monitoring-close.component';

export const appRoutes: Routes = [
    { path: '', component: LoginComponent },
    {
        path: 'backoffice',
        component: BackofficeHomeComponent,
        children: [
            { path: 'check_payment:json', component: CheckPaymentComponent },
            {
                path: 'kpisadmin',
                component: KpisAdminComponent,
                // canActivate: [AuthGuard]
            },
            {
                path: 'kpisbum',
                component: KpisBumComponent,
                // canActivate: [AuthGuard]
            },
            {
                path: 'kpisam',
                component: KpisAmComponent,
                // canActivate: [AuthGuard]
            },
            {
                path: 'kpisdc',
                component: KpisDcComponent,
                // canActivate: [AuthGuard]
            },
            {
                path: 'kpis',
                component: BackofficeKpisComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: KpisBusinessComponent,
                    },
                    {
                        path: 'concessions',
                        component: KpisConcessionsComponent,
                    },
                    {
                        path: 'profitability',
                        component: KpisProfitabilityComponent,
                    },
                    {
                        path: 'profitability-evolution',
                        component: KpisProfitabilityEvolucionComponent,
                    },
                    {
                        path: 'charges',
                        component: KpisChargesComponent,
                    },
                    { path: 'loans', component: KpisLoansComponent },
                    { path: 'one-view', component: KpisOneViewComponent },
                    {
                        path: 'financial-data',
                        component: KpisFinancialDataComponent,
                    },
                    {
                        path: 'debt-recovery',
                        component: KpisDebtRecoveryComponent,
                    },
                ],
            },
            {
                path: 'underwriting',
                component: BackofficeUnderwritingComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: 'leads/:status', component: LeadTableComponent },
                    {
                        path: 'overview/:id',
                        component: LeadOverviewComponent,
                        children: [
                            {
                                path: 'leadInfo/:id',
                                component: LeadInfoComponent,
                            },
                            {
                                path: 'userInfo/:id',
                                component: LeadUserInfoComponent,
                            },
                            {
                                path: 'antiFraud/:id',
                                component: LeadAntiFraudComponent,
                            },
                            {
                                path: 'scoringDetails/:id',
                                component: LeadScoringDetailsComponent,
                            },
                            { path: 'docs/:id', component: LeadDocsComponent },
                            {
                                path: 'smsLog/:id',
                                component: LeadSmsLogComponent,
                            },
                            {
                                path: 'emailLog/:id',
                                component: LeadEmailLogComponent,
                            },
                            {
                                path: 'messages/:id',
                                component: MessagesComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'datamanagement',
                component: BackofficeDatamanagementComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: 'users/:status', component: UsersTableComponent },
                    {
                        path: 'user/:id',
                        component: DatamanagementUserInfoComponent,
                    },
                    {
                        path: 'call_center',
                        component: CallCenterActivityComponent,
                    },
                ],
            },
            {
                path: 'debtrecovery',
                component: BackofficeDebtrecoveryComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'loan/:status',
                        component: CreditLineTableComponent,
                    },
                    {
                        path: 'collection',
                        component: FinanceCollectionComponent,
                    },
                    {
                        path: 'collection_incidence/:status',
                        component: CollectionIncidenceComponent,
                    },
                    {
                        path: 'due_view/:status',
                        component: DebtrecoveryDueComponent,
                    },
                    {
                        path: 'due_assignation',
                        component: DueAssignationComponent,
                    },
                    {
                        path: 'launcher_manager',
                        component: LaunchManagerComponent,
                    },
                    {
                        path: 'queue-manager',
                        component: QueueManagerComponent,
                        children: [
                            {
                                path: '',
                                component: QueueTableComponent,
                            },
                            {
                                path: 'collection/:id',
                                component: QueueCollectionComponent,
                            },
                            {
                                path: 'create-queue',
                                component: QueueCollectionComponent,
                            },
                        ],
                    },
                    {
                        path: 'queue-history',
                        component: QueueHistoryComponent,
                    },
                ],
            },
            {
                path: 'finance',
                component: BackofficeFinanceComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'loan/:status',
                        component: CreditLineTableComponent,
                    },
                    { path: 'invoice', component: FinanceInvoiceComponent },
                    {
                        path: 'collection',
                        component: FinanceCollectionComponent,
                    },
                    { path: 'cashIn', component: FinanceCashinComponent },
                    { path: 'cashOut', component: FinanceCashoutComponent },
                    {
                        path: 'cashCommitted',
                        component: FinanceCashCommitedComponent,
                    },
                    { path: 'due-debt', component: DueDebtComponent },
                    { path: 'losses', component: LossesComponent },
                    { path: 'provisions', component: ProvisionsComponent },
                ],
            },
            {
                path: 'loans',
                component: BackofficeCreditlinesComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'loan-checking',
                        canActivate: [AuthGuard],
                        component: CreditLineCheckingIdTableComponent,
                    },
                    {
                        path: 'loan/:status',
                        component: CreditLineTableComponent,
                    },
                    { path: 'loan-test', component: CreditLineTestComponent },
                    {
                        path: 'overview/:id',
                        component: CreditLineOverviewComponent,
                        children: [
                            {
                                path: 'loanInfo/:id',
                                component: CreditLineLineInfoComponent,
                            },
                            {
                                path: 'leadInfo/:id',
                                component: LeadInfoComponent,
                            },
                            {
                                path: 'userInfo/:id',
                                component: LeadUserInfoComponent,
                            },
                            {
                                path: 'antiFraud/:id',
                                component: LeadAntiFraudComponent,
                            },
                            {
                                path: 'scoringDetails/:id',
                                component: LeadScoringDetailsComponent,
                            },
                            {
                                path: 'docs/:id',
                                component: CreditLineDocsComponent,
                            },
                            {
                                path: 'smsLog/:id',
                                component: LeadSmsLogComponent,
                            },
                            {
                                path: 'emailLog/:id',
                                component: LeadEmailLogComponent,
                            },
                            {
                                path: 'messages/:id',
                                component: MessagesComponent,
                            },
                        ],
                    },
                    {
                        path: 'loan-check/:id',
                        canActivate: [AuthGuard],
                        component: CreditLineCheckComponent,
                        children: [
                            {
                                path: 'userInfo/:id',
                                component: LeadUserInfoComponent,
                            },
                            {
                                path: 'antiFraud/:id',
                                component: LeadAntiFraudComponent,
                            },
                            {
                                path: 'docs/:id',
                                component: CreditLineDocsComponent,
                            },
                            {
                                path: 'leadInfo/:id',
                                component: LeadInfoComponent,
                            },
                            {
                                path: 'scoringDetails/:id',
                                component: LeadScoringDetailsComponent,
                            },
                            {
                                path: 'loanInfo/:id',
                                component: CreditLineLineInfoComponent,
                                data: { view: 'check' },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'legal',
                component: BackofficeLegalComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'loan/:status',
                        component: CreditLineTableComponent,
                    },
                    {
                        path: 'monitoring',
                        component: MonitoringComponent,
                        children: [
                            {
                                path: 'trial-proceeding/:id',
                                component: MonitoringShowComponent,
                            },
                            {
                                path: ':status',
                                component: MonitoringListComponent,
                            },
                            {
                                path: ':status/:situation',
                                component: MonitoringListComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'marketing',
                component: BackofficeMarketingComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'loan/:status',
                        component: CreditLineTableComponent,
                    },
                    { path: 'leads/:status', component: LeadTableComponent },
                    {
                        path: 'affiliates_summary',
                        component: AffiliatesSummaryComponent,
                    },
                    {
                        path: 'affiliates_manager',
                        component: AffiliatesManagerComponent,
                    },
                    {
                        path: 'affiliate_info/:id',
                        component: AffiliateInformationComponent,
                    },
                ],
            },
            {
                path: 'settings',
                component: BackofficeSettingsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'users_overview',
                        component: SettingUserOverviewComponent,
                        children: [
                            {
                                path: 'user_list',
                                component: SettingUserListComponent,
                            },
                            {
                                path: 'create_user',
                                component: SettingCreateUserComponent,
                            },
                            {
                                path: 'create_user/:id',
                                component: SettingCreateUserComponent,
                            },
                            {
                                path: 'create_role',
                                component: SettingCreateRoleComponent,
                            },
                            {
                                path: 'create_permission',
                                component: SettingCreatePermissionComponent,
                            },
                            {
                                path: 'role_permission',
                                component: SettingRolePermissionComponent,
                            },
                        ],
                    },
                    {
                        path: 'product_overview',
                        component: SettingsProductOverviewComponent,
                        children: [
                            {
                                path: 'product_list',
                                component: SettingsProductListComponent,
                            },
                            {
                                path: 'create_product',
                                component: SettingsCreateProductComponent,
                            },
                            {
                                path: 'create_product/:id',
                                component: SettingsCreateProductComponent,
                            },
                        ],
                    },
                    {
                        path: 'validation_overview',
                        component: SettingValidationOverviewComponent,
                        children: [
                            {
                                path: 'discard_criterion',
                                component:
                                    SettingValidationDiscardCriterionComponent,
                            },
                            {
                                path: 'discard_antifraud',
                                component:
                                    SettingValidationDiscardAntifraudComponent,
                            },
                            {
                                path: 'advising_antifraud',
                                component:
                                    SettingValidationAdvisingAntifraudComponent,
                            },
                            {
                                path: 'asnef',
                                component: SettingValidationAsnefComponent,
                            },
                            {
                                path: 'savso',
                                component: SettingValidationSavsoComponent,
                            },
                            {
                                path: 'scoring',
                                component: SettingValidationScoringComponent,
                            },
                        ],
                    },
                    {
                        path: 'faq_overview',
                        component: SettingsFaqOverviewComponent,
                        children: [
                            {
                                path: 'faq_list',
                                component: SettingsFaqListComponent,
                            },
                            {
                                path: 'create_faq',
                                component: SettingsCreateFaqComponent,
                            },
                        ],
                    },
                    {
                        path: 'payment-tree',
                        component: PaymentTreeComponent,
                        children: [
                            {
                                path: ':id',
                                component: PaymentTreeNodeListComponent,
                            },
                        ],
                    },
                    {
                        path: 'mail-templates',
                        component: SettingsMailTemplatesComponent,
                        children: [
                            {
                                path: '',
                                component: MailTemplatesComponent,
                            },
                            {
                                path: 'variables',
                                component: MailTemplatesVariblesComponent,
                            },
                            {
                                path: 'messages-types',
                                component: MailMessageTypesComponent,
                            },
                            {
                                path: 'messages-send-config',
                                component: MailMessagesSendConfigComponent,
                            },
                            {
                                path: 'providers-statuses',
                                component: MailProvidersStatusesComponent,
                            },
                            {
                                path: 'create',
                                component: CreateMailTemplateComponent,
                            },
                            {
                                path: ':id',
                                component: CreateMailTemplateComponent,
                            },
                        ],
                    },
                ],
            },
            { path: 'search', component: SearchComponent }, // canActivate: [AuthGuard]
        ],
    },
];

// ==================================================================================================================================//

@NgModule({
    declarations: [
        AppComponent,
        ValidDniDirective,
        ValidEmailDirective,
        ValidJobTypeDirective,
        ValidStreetTypeDirective,
        ValidOtherPhoneDirective,
        ValidMonthDirective,
        OutOfServiceComponent,
        LoadingComponent,
        ClickOutsideDirective,
        ErrorMessageComponent,
        ValidCreditCardDirective,
        ValidAmountPaymentDirective,
        ValidBaccDirective,
        SelectBankaccDirective,
        ValidPinDirective,
        BackofficeLoginComponent,
        BackofficeHomeComponent,
        BackofficeMiniKpisComponent,
        BackofficeHeaderComponent,
        BackofficeCreditlinesComponent,
        BackofficeDatamanagementComponent,
        BackofficeDebtrecoveryComponent,
        BackofficeFinanceComponent,
        BackofficeKpisComponent,
        BackofficeLegalComponent,
        BackofficeMarketingComponent,
        BackofficeSettingsComponent,
        BackofficeUnderwritingComponent,
        LeadTableComponent,
        LeadOverviewComponent,
        CreditLineTableComponent,
        CreditLineOverviewComponent,
        LeadUserInfoComponent,
        LeadAntiFraudComponent,
        LeadScoringDetailsComponent,
        LeadDocsComponent,
        LeadSmsLogComponent,
        LeadEmailLogComponent,
        LeadInfoComponent,
        LoginComponent,
        KeysPipe,
        LeadInfoFormValueComponent,
        LeadInfoSummaryComponent,
        LeadInfoOtherComponent,
        LeadInfoBankAggregationDetailComponent,
        LeadInfoCustomerLeadComponent,
        LeadInfoAsnefValidationComponent,
        LeadInfoSavsoValidationComponent,
        LeadInfoBasicInfoComponent,
        LeadInfoContactInfoComponent,
        LeadInfoAddressInfoComponent,
        LeadInfoThirdPartyComponent,
        LeadInfoContractTrackingComponent,
        LeadInfoAddressTrackingComponent,
        LeadInfoDiscardValidationComponent,
        LeadInfoAdvisingValidationComponent,
        LeadInfoBankAggregationComponent,
        LeadInfoCustomerCreditLineComponent,
        CreditLineCheckingIdTableComponent,
        CreditLineCheckComponent,
        CreditLineLeadInfoComponent,
        CreditLineDocsComponent,
        CreditLineLineInfoComponent,
        LineInfoBasicInfoComponent,
        LineInfoCustomerInfoComponent,
        ContactPhoneFormComponent,
        LeadInfoScoringGraphicComponent,
        SettingOverviewComponent,
        SettingUsersComponent,
        SettingCreateUserComponent,
        SettingViewUserComponent,
        SettingUserListComponent,
        SettingUserOverviewComponent,
        SettingValidationsComponent,
        SettingValidationOverviewComponent,
        SettingValidationDiscardCriterionComponent,
        SettingValidationDiscardAntifraudComponent,
        SettingValidationAdvisingAntifraudComponent,
        SettingValidationAsnefComponent,
        SettingValidationScoringComponent,
        SettingValidationSavsoComponent,
        SettingCreateRoleComponent,
        SettingCreatePermissionComponent,
        SettingRolePermissionComponent,
        SettingsProductOverviewComponent,
        SettingsCreateProductComponent,
        SettingsProductListComponent,
        SettingsFaqOverviewComponent,
        SettingsFaqListComponent,
        SettingsCreateFaqComponent,
        CheckNavBarComponent,
        OperationNavBarComponent,
        PagerComponent,
        DonutChartComponent,
        LeadInfoScoringGraphicsComponent,
        KpisAdminComponent,
        KpisBumComponent,
        KpisAmComponent,
        KpisDcComponent,
        FinanceInvoiceComponent,
        FinanceCollectionComponent,
        FinanceCashinComponent,
        FinanceCashoutComponent,
        FinanceCashCommitedComponent,
        ProcessPaymentComponent,
        CheckPaymentComponent,
        TrackingComponent,
        TrackingContentComponent,
        DaterangePickerComponent,
        CollectionIncidenceComponent,
        DebtrecoveryDueComponent,
        DueAssignationComponent,
        UsersTableComponent,
        CallCenterActivityComponent,
        DatamanagementUserInfoComponent,
        ValidIsNotDirective,
        LaunchManagerComponent,
        SearchComponent,
        HighlightPipe,
        KpisLoansComponent,
        KpisOneViewComponent,
        KpisFinancialDataComponent,
        KpisDebtRecoveryComponent,
        ReplacePipe,
        HasPermissionsDirective,
        CreditLineTestComponent,
        DropdownRangeComponent,
        DatepickerFilterComponent,
        AffiliatesSummaryComponent,
        AffiliatesManagerComponent,
        AffiliateInformationComponent,
        CreateAffiliateComponent,
        CreateSourceComponent,
        AffiliateLogComponent,
        DaterangeFilterComponent,
        PaymentRectificationComponent,
        LoanRectificationComponent,
        DataPageNavComponent,
        DueDebtComponent,
        BlacklistHistoryComponent,
        DateFormatPipe,
        AsnefStatusComponent,
        AsnefExludeComponent,
        LossesComponent,
        QueueManagerComponent,
        QueueCollectionComponent,
        QueueHistoryComponent,
        QueueTableComponent,
        PaymentTreeComponent,
        PaymentTreeCreateComponent,
        PaymentTreeListComponent,
        PaymentTreeItemComponent,
        PaymentTreeNodeComponent,
        PaymentTreeNodeListComponent,
        PaymentTreeNodeCreateComponent,
        PaymentTreeUpdateComponent,
        KpisConcessionsComponent,
        ProvisionsComponent,
        KpisProfitabilityComponent,
        KpisProfitabilityEvolucionComponent,
        KpisChargesComponent,
        KpisBusinessComponent,
        FeeRectificationComponent,
        SettingsMailTemplatesComponent,
        CreateMailTemplateComponent,
        EmailEditorComponent,
        MailTemplatesVariblesComponent,
        MailTemplatesComponent,
        MailTemplatesVariablesListComponent,
        MailTemplatesVariablesCreateComponent,
        MailTemplatesActivationComponent,
        MailTemplatesPublishingComponent,
        MailMessageTypesComponent,
        MailMessageTypesListComponent,
        MailMessageTypesCreateComponent,
        MailTemplatesPreviewComponent,
        MailMessagesSendConfigComponent,
        MailMessagesSendConfigListComponent,
        MailMessagesSendConfigCreateComponent,
        MessagesComponent,
        MessagesListComponent,
        MessagesCreateComponent,
        MailProvidersStatusesComponent,
        MailProvidersStatusesListComponent,
        MailProvidersStatusesCreateComponent,
        MonitoringComponent,
        MonitoringListComponent,
        MonitoringShowComponent,
        MonitoringAddressInfoComponent,
        MonitoringPersonalDocsComponent,
        MonitoringCreateComponent,
        PresentationCreateComponent,
        ValidDateDirective,
        PresentationAttachmentsListComponent,
        PresentationAttachmentsAddComponent,
        PresentationDocsAddComponent,
        PresentationDocsListComponent,
        PresentationListComponent,
        PresentationShowComponent,
        MonitoringEditComponent,
        MonitoringCloseComponent,
    ],
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: false }), // {useHash: true} mete # en la URL para que se pueda recargar la página en producción
        PdfViewerModule,
        ToastNoAnimationModule,
        ToastrModule.forRoot({
            toastComponent: ToastNoAnimation,
        }),
        NgbModule,
        BrowserModule,
        // HotTableModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        Daterangepicker,
        TextMaskModule,
        NgSelectModule,
        NgCircleProgressModule.forRoot({
            backgroundPadding: 0,
            backgroundStrokeWidth: 0,
            radius: 60,
            space: -30,
            outerStrokeWidth: 30,
            outerStrokeColor: '#783270',
            outerStrokeLinecap: 'butt',
            innerStrokeColor: '#bc7dbb',
            innerStrokeWidth: 30,
            animationDuration: 1000,
            showSubtitle: false,
            titleFontSize: '2rem',
            unitsFontSize: '1rem',
            // showBackground: false,
            responsive: true,
        }),
        NgMultiSelectDropDownModule.forRoot(),
        ImageViewerModule,
        HammerModule,
        ChartsModule,
        DragDropModule,
    ],
    schemas: [NO_ERRORS_SCHEMA],
    providers: [
        EmailService,
        SmsService,
        DocsService,
        ContactTrackingService,
        PaymentRuleService,
        PaymentService,
        PaymentBankCardService,
        PaymentBankAccountService,
        WithdrawalService,
        CreditLineService,
        LeadService,
        LineRequestInfoService,
        HeaderService,
        HttpService,
        ShareService,
        PagerService,
        AuthService,
        ErrorMessageService,
        ScoringService,
        ProductService,
        AsnefService,
        DiscardCriterionService,
        DiscardAntifraudService,
        AdvisingAntifraudService,
        FaqService,
        CheckNavBarService,
        OperationNavBarService,
        LeadTrackingService,
        AuthGuard,
        RefreshTokenService,
        RequestInterceptorService,
        FormatPhoneNumberService,
        BankTransactionService,
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        /*{provide: ErrorHandler, useClass: AplicationErrorHandle },*/
        /*{provide: LocationStrategy, useClass: PathLocationStrategy},*/
        /*{provide: LocationStrategy, useClass: HashLocationStrategy}*/
        FinanceService,
        ContactInfoService,
        TrackingService,
        MiniModalService,
        DebtRecoveryService,
        UserService,
        KpiService,
        SaveFiltersService,
        AffiliatesService,
        FiltersFormService,
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
