<button class="btn btn-primary" (click)="open(exlude)">
    Exclude{{ isShort ? '' : ' from ASNEF' }}
</button>

<ng-template #exlude let-c="close">
    <div class="modal-header">
        <h4 class="modal-title">Exlude from ASNEF</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form id="exlude" #exlude="ngForm" (ngSubmit)="onSubmit(exlude)">
            <div class="row">
                <label for="reasons" class="col-auto col-form-label"
                    >Reason</label
                >
                <div class="col">
                    <select
                        name="reason_id"
                        id="reasons"
                        class="form-control"
                        required
                        ngModel
                        #reason="ngModel"
                    >
                        <option value="">Select Reason</option>
                        <option
                            *ngFor="let item of reasonsList"
                            [value]="item.id"
                            >{{ item?.name }}</option
                        >
                    </select>
                    <small
                        class="text-danger"
                        *ngIf="reason.invalid && reason.touched"
                    >
                        Choose reason
                    </small>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            form="exlude"
            class="btn btn-primary"
            [disabled]="reason.invalid || isSending"
        >
            {{ isSending ? 'Sending...' : 'Confirm' }}
        </button>
    </div>
</ng-template>
