<button
    type="button"
    class="btn btn-sm btn-outline-primary"
    placement="right"
    ngbTooltip="Editar Monitorio"
    (click)="open(content)"
>
    <i class="fa fa-pencil"></i>
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            Editar Monitorio: {{ proceeding?.reference_code || '-' }}
        </h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form
            id="monitoringForm"
            [formGroup]="form"
            autocomplete="off"
            (ngSubmit)="onSubmit()"
        >
            <div class="form-row">
                <div class="col-md-6 form-group">
                    <label for="loanCode">
                        Loan Code
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="loanCode"
                        name="loan_code_str"
                        formControlName="loan_code_str"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_code_str.touched &&
                                !form.controls.loan_code_str.valid,
                            'is-valid':
                                form.controls.loan_code_str.touched &&
                                form.controls.loan_code_str.valid
                        }"
                        required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="activationDate">
                        Activation Date
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="date"
                        appValidDate
                        class="form-control"
                        id="activationDate"
                        name="loan_activation_at"
                        formControlName="loan_activation_at"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_activation_at.touched &&
                                !form.controls.loan_activation_at.valid,
                            'is-valid':
                                form.controls.loan_activation_at.touched &&
                                form.controls.loan_activation_at.valid
                        }"
                    />
                </div>
                <div class="col-md-6">
                    <label for="expirationDate">
                        Expiration Date
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="date"
                        appValidDate
                        class="form-control"
                        id="expirationDate"
                        name="loan_expiration_at"
                        formControlName="loan_expiration_at"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_expiration_at.touched &&
                                !form.controls.loan_expiration_at.valid,
                            'is-valid':
                                form.controls.loan_expiration_at.touched &&
                                form.controls.loan_expiration_at.valid
                        }"
                    />
                </div>
                <div class="col-md-6">
                    <label for="dueDate">
                        Due Date
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="date"
                        appValidDate
                        class="form-control"
                        id="dueDate"
                        name="loan_due_at"
                        formControlName="loan_due_at"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_due_at.touched &&
                                !form.controls.loan_due_at.valid,
                            'is-valid':
                                form.controls.loan_due_at.touched &&
                                form.controls.loan_due_at.valid
                        }"
                    />
                </div>

                <div class="col-12"><hr /></div>

                <div class="col-md-6 form-group">
                    <label for="loanAmount">
                        Importe
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="loanAmount"
                        name="loan_amount"
                        formControlName="loan_amount"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_amount.touched &&
                                !form.controls.loan_amount.valid,
                            'is-valid':
                                form.controls.loan_amount.touched &&
                                form.controls.loan_amount.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="loanTotalPay"
                        >Total a Pagar
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="loanTotalPay"
                        name="loan_total_pay"
                        formControlName="loan_total_pay"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_total_pay.touched &&
                                !form.controls.loan_total_pay.valid,
                            'is-valid':
                                form.controls.loan_total_pay.touched &&
                                form.controls.loan_total_pay.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="loanAmountPaid"
                        >Pagado
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="loanAmountPaid"
                        name="loan_amount_paid"
                        formControlName="loan_amount_paid"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_amount_paid.touched &&
                                !form.controls.loan_amount_paid.valid,
                            'is-valid':
                                form.controls.loan_amount_paid.touched &&
                                form.controls.loan_amount_paid.valid
                        }"
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="loanDebtLeft">
                        Deuda Pendiente
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <input
                            id="loanDebtLeft"
                            type="number"
                            class="form-control"
                            name="loan_debt_left"
                            formControlName="loan_debt_left"
                            min="0"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.loan_debt_left.touched &&
                                    !form.controls.loan_debt_left.valid,
                                'is-valid':
                                    form.controls.loan_debt_left.touched &&
                                    form.controls.loan_debt_left.valid
                            }"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text">{{
                                form.controls.loan_debt_left_percent.value
                                    ? (form.controls.loan_debt_left_percent
                                          .value | percent: '1.0-2':'es')
                                    : '-%'
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 form-group">
                    <label for="loanAmountQuote"
                        >Cuota
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="loanAmountQuote"
                        name="loan_amount_quote"
                        formControlName="loan_amount_quote"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_amount_quote.touched &&
                                !form.controls.loan_amount_quote.valid,
                            'is-valid':
                                form.controls.loan_amount_quote.touched &&
                                form.controls.loan_amount_quote.valid
                        }"
                    />
                </div>
                <div class="col-md-4 form-group">
                    <label for="loanFee"
                        >Loan Fee
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="loanFee"
                        name="loan_fee"
                        formControlName="loan_fee"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_fee.touched &&
                                !form.controls.loan_fee.valid,
                            'is-valid':
                                form.controls.loan_fee.touched &&
                                form.controls.loan_fee.valid
                        }"
                    />
                </div>
                <div class="col-md-4 form-group">
                    <label for="loanDebtLateFee"
                        >Late Fee
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="loanDebtLateFee"
                        name="loan_debt_late_fee"
                        formControlName="loan_debt_late_fee"
                        min="0"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.loan_debt_late_fee.touched &&
                                !form.controls.loan_debt_late_fee.valid,
                            'is-valid':
                                form.controls.loan_debt_late_fee.touched &&
                                form.controls.loan_debt_late_fee.valid
                        }"
                    />
                </div>
                <div class="col-12"><hr /></div>

                <div class="col-md-6 form-group">
                    <label for="personalId">
                        DNI/NIE
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="personalId"
                        name="user_personalid"
                        [ValidDni]
                        formControlName="user_personalid"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.user_personalid.touched &&
                                !form.controls.user_personalid.valid,
                            'is-valid':
                                form.controls.user_personalid.touched &&
                                form.controls.user_personalid.valid
                        }"
                        required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="name"
                        >Nombre
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        name="user_name"
                        formControlName="user_name"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.user_name.touched &&
                                !form.controls.user_name.valid,
                            'is-valid':
                                form.controls.user_name.touched &&
                                form.controls.user_name.valid
                        }"
                        required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="firstSurname">
                        Appelido 1
                        <small class="text-danger">*</small>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="firstSurname"
                        name="user_first_surname"
                        formControlName="user_first_surname"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.user_first_surname.touched &&
                                !form.controls.user_first_surname.valid,
                            'is-valid':
                                form.controls.user_first_surname.touched &&
                                form.controls.user_first_surname.valid
                        }"
                        required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label for="secondSurname">Appelido 2</label>
                    <input
                        type="text"
                        class="form-control"
                        id="secondSurname"
                        name="user_second_surname"
                        formControlName="user_second_surname"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.user_second_surname.touched &&
                                !form.controls.user_second_surname.valid,
                            'is-valid':
                                form.controls.user_second_surname.touched &&
                                form.controls.user_second_surname.valid
                        }"
                    />
                </div>
                <div class="col-12"><hr /></div>

                <div class="col-md-4 form-group">
                    <label for="streetType">
                        Tipo de Calle
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <div
                            *ngIf="isCityTypesLoading"
                            class="input-group-prepend"
                        >
                            <div class="input-group-text">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        </div>
                        <select
                            id="streetType"
                            class="form-control"
                            name="user_address_street_type_id"
                            formControlName="user_address_street_type_id"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.user_address_street_type_id
                                        .touched &&
                                    !form.controls.user_address_street_type_id
                                        .valid,
                                'is-valid':
                                    form.controls.user_address_street_type_id
                                        .touched &&
                                    form.controls.user_address_street_type_id
                                        .valid
                            }"
                            required
                        >
                            <option value="" disabled>Seleccione</option>
                            <option
                                *ngFor="let type of cityTypes"
                                [value]="type.key"
                            >
                                {{ type.value }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-8 form-group">
                    <label for="street">
                        Calle
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            id="street"
                            placeholder="Nombre*"
                            name="user_address_street"
                            formControlName="user_address_street"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.user_address_street.touched &&
                                    !form.controls.user_address_street.valid,
                                'is-valid':
                                    form.controls.user_address_street.touched &&
                                    form.controls.user_address_street.valid
                            }"
                            required
                        />
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Numéro*"
                            name="user_address_street_number"
                            formControlName="user_address_street_number"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.user_address_street_number
                                        .touched &&
                                    !form.controls.user_address_street_number
                                        .valid,
                                'is-valid':
                                    form.controls.user_address_street_number
                                        .touched &&
                                    form.controls.user_address_street_number
                                        .valid
                            }"
                            required
                        />
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Dato Adicional"
                            name="user_address_street_additional"
                            formControlName="user_address_street_additional"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.user_address_street_additional
                                        .touched &&
                                    !form.controls
                                        .user_address_street_additional.valid,
                                'is-valid':
                                    form.controls.user_address_street_additional
                                        .touched &&
                                    form.controls.user_address_street_additional
                                        .valid
                            }"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="province">
                        Provincia
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <div
                            *ngIf="isProvincesLoading"
                            class="input-group-prepend"
                        >
                            <div class="input-group-text">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        </div>
                        <input
                            type="text"
                            id="province"
                            class="form-control"
                            name="user_address_province"
                            formControlName="user_address_province"
                            [ngbTypeahead]="searchProvince"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.user_address_province
                                        .touched &&
                                    !form.controls.user_address_province.valid,
                                'is-valid':
                                    form.controls.user_address_province
                                        .touched &&
                                    form.controls.user_address_province.valid
                            }"
                            [inputFormatter]="provinceFormatter"
                            [resultFormatter]="provinceFormatter"
                            [editable]="false"
                            placement="top-left"
                            required
                            autocomplete="off"
                        />
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="city">
                        Código Postal · Población
                        <small class="text-danger">*</small>
                    </label>
                    <div class="input-group">
                        <div
                            *ngIf="isCitiesLoading"
                            class="input-group-prepend"
                        >
                            <div class="input-group-text">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        </div>
                        <input
                            type="text"
                            id="city"
                            class="form-control"
                            name="user_address_city"
                            formControlName="user_address_city"
                            [ngbTypeahead]="searchCity"
                            [ngClass]="{
                                'is-invalid':
                                    form.controls.user_address_city.touched &&
                                    !form.controls.user_address_city.valid,
                                'is-valid':
                                    form.controls.user_address_city.touched &&
                                    form.controls.user_address_city.valid
                            }"
                            [inputFormatter]="cityFormatter"
                            [resultFormatter]="cityFormatter"
                            [editable]="false"
                            placement="top-left"
                            required
                            autocomplete="off"
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            Cerrar
        </button>
        <button
            type="submit"
            class="btn btn-primary"
            form="monitoringForm"
            [disabled]="form.invalid || isSending"
        >
            {{ isSending ? 'Guardando...' : 'Guardar' }}
        </button>
    </div>
</ng-template>
