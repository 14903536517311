import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../../services/email.service';
import { ErrorMessageService } from '../../../../../shared/error-message.service';

@Component({
  selector: 'app-mail-templates-variables-create',
  templateUrl: './mail-templates-variables-create.component.html',
  styleUrls: ['./mail-templates-variables-create.component.scss'],
})
export class MailTemplatesVariablesCreateComponent implements OnInit {
  @Input() variable = null;

  @Output() saved = new EventEmitter<any>();

  id;
  isSending = false;

  form = this.fb.group({
    name: ['', Validators.required],
    name_template: ['', [Validators.required, Validators.pattern(/^\w+$/)]],
    description: ['', Validators.required],
    value: ['', Validators.required],
    enabled: [false],
  });

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private emailService: EmailService,
    private errorMessage: ErrorMessageService
  ) {}

  ngOnInit(): void {}

  edit(e, variable, content) {
    e.preventDefault();

    const { name, name_template, description, value, enabled } = variable;

    this.form.setValue({
      name,
      name_template,
      description,
      value,
      enabled,
    });

    this.open(content);
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.resetForm();
      },
      (reason) => {
        this.resetForm();
      }
    );
  }

  resetForm() {
    this.form.setValue({
      name: '',
      name_template: '',
      description: '',
      value: '',
      enabled: false,
    });
  }

  onSubmit() {
    const data = this.form.getRawValue();
    this.form.disable();

    if (this.variable || this.id) {
      this.emailService
        .editVarible(this.variable?.id || this.id, data)
        .subscribe(
          (response) => {
            this.isSending = false;
            this.form.enable();
            this.errorMessage.showSuccess('', 'Saved Successfully');
            this.saved.emit(response);
          },
          (error) => {
            this.errorMessage.showError(
              error?.error?.error?.details,
              'Saving Failed'
            );
            this.isSending = false;
            this.form.enable();
          }
        );
    } else {
      this.emailService.createVarible(data).subscribe(
        (response) => {
          this.form.enable();
          this.isSending = false;
          this.errorMessage.showSuccess('', 'Saved Successfully');
          this.id = response.id;
          this.saved.emit(response);
        },
        (error) => {
          this.errorMessage.showError(
            error?.error?.error?.details,
            'Saving Failed'
          );
          this.isSending = false;
          this.form.enable();
        }
      );
    }
  }
}
