import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-backoffice-marketing',
    templateUrl: './backoffice-marketing.component.html',
    styleUrls: ['./backoffice-marketing.component.css']
})
export class BackofficeMarketingComponent implements OnInit, DoCheck {
    show: boolean;

    constructor(private router: Router) {}

    ngDoCheck() {
        if (window.location.href.match('.backoffice/marketing$')) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    ngOnInit() {}

    showPanel() {
        return this.show;
    }

    goto(route) {
        this.show = false;
        this.router.navigate([route]);
    }
}
