import { Component, OnInit } from '@angular/core';
import { ScoringService } from '../../../services/scoring.service';
import { ActivatedRoute } from '@angular/router';
import { BankTransactionService } from '../../../services/bank_transaction.service';

declare var moment: any;

declare let zingchart: any;

@Component({
    selector: 'app-lead-scoring-details',
    templateUrl: './lead-scoring-details.component.html',
    styleUrls: ['./lead-scoring-details.component.css'],
})
export class LeadScoringDetailsComponent implements OnInit {
    lead_id: any = null;
    scoring: any = null;
    history = null;
    myConfig = null;
    months = [];
    shortDebtSummary = null;
    largeDebtSummary = null;
    unidentifiedDebtSummary = null;
    transactionLoading = false;
    showFilter: boolean;
    paramsFilter = new Map();
    urlParams: string = '';
    pageFilter = '';
    score = 0;
    monthTitle = {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Obtubre',
        11: 'Noviembre',
        12: 'Diciembre',
    };

    dateInputValStart: string = '';
    dateInputValEnd: string = '';
    bankTransaction;
    options: any = {};

    accounts: any = [];

    constructor(
        private activate: ActivatedRoute,
        private scoringService: ScoringService,
        private bankTransactionService: BankTransactionService
    ) {
        this.options = {
            locale: { format: 'YYYY-MM-DD' },
            autoUpdateInput: false,
            singleDatePicker: true,
            alwaysShowCalendars: true,
            maxDate: moment().format('YYYY-MM-DD'),
        };
    }

    ngOnInit() {
        this.activate.params.subscribe((params) => {
            this.lead_id = params['id'];
            this.getScoring();
            this.getBankTransactions();
            this.getAccountsList();
        });
    }

    getScoring() {
        if (this.lead_id) {
            this.scoringService
                .getByLeadId(this.lead_id)
                .subscribe((scoring) => {
                    if (Object.values(scoring).length) {
                        this.scoring = scoring;

                        if (scoring.history) {
                            this.history = Object.values(
                                JSON.parse(scoring.history)
                            );
                            this.months = this.convertToMonthTitle(
                                Object.keys(JSON.parse(scoring.history))
                            );
                        }
                        // this.shortDebtSummary = JSON.parse(scoring.scoring.short_debt_summary);
                        // this.largeDebtSummary = JSON.parse(scoring.scoring.large_debt_summary);
                        // this.unidentifiedDebtSummary = JSON.parse(scoring.scoring.unidentified_debt_summary);
                        this.creatingScoringGraphics();
                        this.creatingEvolutionGraphics();
                    }
                });
        }
    }

    getBankTransactions(page = 0) {
        this.bankTransaction = null;
        this.setPageFilter(page);
        this.bankTransactionService
            .getPaginatedList(this.lead_id, this.urlParams + this.pageFilter)
            .subscribe((list) => {
                this.bankTransaction = list;
            });
    }

    getAccountsList() {
        this.bankTransactionService
            .getAccountList(this.lead_id)
            .subscribe((list) => {
                this.accounts = list;
            });
    }
    getParamsFilter(event: any) {
        this.paramsFilter.set(event.target.name, event.target.value);
    }
    makeFilter() {
        this.urlParams = '';
        this.paramsFilter.forEach(function (value, key) {
            if (value) {
                this.urlParams += key + '=' + value + '&';
            }
        }, this);
        this.getBankTransactions();
    }
    convertToMonthTitle(months) {
        for (let i = 0; i < months.length; i++) {
            months[i] = this.monthTitle[months[i]];
        }
        return months;
    }
    setPageFilter(page) {
        this.pageFilter = '';
        if (page) {
            this.pageFilter = 'page=' + page;
        }
    }
    creatingEvolutionGraphics() {
        this.myConfig = {
            type: 'line',
            utc: false,
            title: {
                text: 'Scoring Analytics',
                'font-size': '24px',
                'adjust-layout': true,
            },
            plotarea: {
                margin: 'dynamic 45 60 dynamic',
            },
            legend: {
                layout: 'float',
                'background-color': 'none',
                'border-width': 0,
                shadow: 0,
                align: 'center',
                'adjust-layout': true,
                item: {
                    padding: 7,
                    marginRight: 17,
                    cursor: 'hand',
                },
            },

            'scale-x': {
                'min-value': 1383292800000,
                shadow: 0,
                step: 3600000,
                transform: {
                    type: 'date',
                    all: '%D, %d %M<br />%h:%i %A',
                    guide: {
                        visible: false,
                    },
                    item: {
                        visible: false,
                    },
                },
                label: {
                    visible: true,
                },
                'minor-ticks': 0,

                labels: this.months,
            },

            'scale-y': {
                values: '0:1000:250',
                'line-color': '#f6f7f8',
                visible: false,
                shadow: 0,
                guide: {
                    'line-style': 'dashed',
                },
                'minor-ticks': 0,
                'thousands-separator': ',',
            },
            'crosshair-x': {
                'line-color': '#efefef',
                'plot-label': {
                    'border-radius': '5px',
                    'border-width': '1px',
                    'border-color': '#f6f7f8',
                    padding: '10px',
                    'font-weight': 'bold',
                },
                'scale-label': {
                    'font-color': '#000',
                    'background-color': '#f6f7f8',
                    'border-radius': '5px',
                },
            },
            tooltip: {
                visible: false,
            },
            plot: {
                highlight: true,
                'tooltip-text': '%t views: %v<br>%k',
                shadow: 5,
                'line-width': '3px',
                marker: {
                    type: 'circle',
                    size: 3,
                },

                animation: {
                    effect: 1,
                    sequence: 2,
                    speed: 100,
                },
                'value-box': {
                    visible: true,
                    'font-color': 'blue',
                },
            },
            series: [
                {
                    values: this.history,

                    text: 'Scoring',
                    'line-color': '#6699ff',
                    'line-style': 'solid',
                    'line-width': 3,

                    'legend-marker': {
                        visible: false,
                    },

                    marker: {
                        'background-color': '#00bfff',
                        'border-width': 3,
                        shadow: 5,
                        'border-color': 'blue',
                        size: 4,
                    },
                },
            ],
        };

        zingchart.render({
            id: 'myChart',
            data: this.myConfig,
            height: 400,
            width: 600,
        });
    }

    creatingScoringGraphics() {
        let myConfig = {
            type: 'gauge',
            globals: {
                fontSize: 25,
            },
            plotarea: {
                marginTop: 80,
            },
            plot: {
                size: '100%',
                valueBox: {
                    placement: 'center',
                    text: '%v',
                    fontSize: 35,
                    rules: [
                        {
                            rule: '%v >= 850',
                            text: '%v<br>EXCELLENT',
                        },
                        {
                            rule: '%v < 850 && %v > 700',
                            text: '%v<br>Good',
                        },
                        {
                            rule: '%v <= 700 && %v >= 600',
                            text: '%v<br>Regular',
                        },
                        {
                            rule: '%v <  600',
                            text: '%v<br>Bad',
                        },
                    ],
                },
            },
            tooltip: {
                borderRadius: 5,
            },
            scaleR: {
                aperture: 180,
                minValue: 200,
                maxValue: 1000,
                step: 50,
                center: {
                    visible: false,
                },
                tick: {
                    visible: false,
                },
                item: {
                    offsetR: 0,
                    rules: [
                        {
                            rule: '%i == 9',
                            offsetX: 15,
                        },
                    ],
                },
                labels: [
                    '200',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '600',
                    '',
                    '700',
                    '',
                    '',
                    '850',
                    '',
                    '',
                    '1000',
                ],

                ring: {
                    size: 50,
                    rules: [
                        {
                            rule: '%v < 600',
                            backgroundColor: '#FF0000',
                        },
                        {
                            rule: '%v >= 600 && %v < 700',
                            backgroundColor: '#FF8000',
                        },
                        {
                            rule: '%v >= 700 && %v < 849',
                            backgroundColor: '#0080FF',
                        },
                        {
                            rule: '%v >= 849',
                            backgroundColor: '#3ADF00',
                        },
                    ],
                },
            },
            series: [
                {
                    values: [+this.scoring.score], // starting value
                    backgroundColor: 'black',
                    indicator: [5, 10, 10, 10, 0.75],
                    animation: {
                        effect: 2,
                        method: 1,
                        sequence: 4,
                        speed: 900,
                    },
                },
            ],
        };

        zingchart.render({
            id: 'scoring',
            data: myConfig,
            height: 500,
            width: '100%',
        });
    }
    selectedDate(value: any, input_name) {
        let start = {
            target: {
                name: input_name,
                value: value.start.format('YYYY-MM-DD'),
            },
        };
        let end = {
            target: {
                name: input_name,
                value: value.end.format('YYYY-MM-DD'),
            },
        };

        if (input_name === 'created_at_start') {
            this.dateInputValStart = start.target.value;
        }

        if (input_name === 'created_at_end') {
            this.dateInputValEnd = end.target.value;
        }

        this.getParamsFilter(start);
        this.getParamsFilter(end);
        this.makeFilter();
    }

    clearFilter(event) {
        if (!event.target.value) {
            if (event.target.name === 'created_at_start') {
                let start = {
                    target: {
                        name: event.target.name,
                        value: '',
                    },
                };

                this.dateInputValStart = '';

                this.getParamsFilter(start);
                this.makeFilter();
            }

            if (event.target.name === 'created_at_end') {
                let end = {
                    target: {
                        name: event.target.name,
                        value: '',
                    },
                };

                this.dateInputValEnd = '';

                this.getParamsFilter(end);
                this.makeFilter();
            }
        }
    }
}
