<div *appHasPermissions="['datamanagement-callcenter']">
    <div class="row mb-3">
        <div class="col-12">
            <h2 class="mb-0">Call Center Activity</h2>
            <a [href]="exportLink" (click)="getExportLink($event)">Export</a>
        </div>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="credit_lines?.current_page"
        [from]="credit_lines?.from"
        [to]="credit_lines?.to"
        [total]="credit_lines?.total"
        (changePage)="getCreditLines($event)"
    >
    </app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Date</th>
                    <th>User</th>
                    <th>Role</th>
                    <th>Activity</th>
                    <th>Time Spent</th>
                    <th>Activity</th>
                    <th>Time Spent</th>
                    <th>Activity</th>
                    <th>Time Spent</th>
                    <th>Activity</th>
                    <th>Time Spent</th>
                    <th>Total Time Spent</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-daterangepicker
                            #datePicker
                            [defaultDate]="calendarDates"
                            fieldName="created_at"
                            (changeDate)="onChangeDate($event)"
                            [withStartDate]="true"
                        ></app-daterangepicker>
                    </td>
                    <td>
                        <select (change)="getParamsFilter($event); makeFilter()" type="text" name="user">
                            <option value="">Select</option>
                            <option
                                *ngFor="let key of credit_line_status_filter | keys"
                                value="{{ credit_line_status_filter[key].id }}"
                                >{{ credit_line_status_filter[key].name }}</option
                            >
                        </select>
                    </td>
                    <td>
                        <select (change)="getParamsFilter($event); makeFilter()" type="text" name="role">
                            <option value="">Select</option>
                            <option
                                *ngFor="let key of credit_line_status_filter | keys"
                                value="{{ credit_line_status_filter[key].id }}"
                                >{{ credit_line_status_filter[key].name }}</option
                            >
                        </select>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngFor="let cl of credit_lines?.data">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-pager
        [perPage]="15"
        [currentPage]="credit_lines?.current_page"
        [from]="credit_lines?.from"
        [to]="credit_lines?.to"
        [total]="credit_lines?.total"
        (changePage)="getCreditLines($event)"
    >
    </app-pager>
</div>
