import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-info-third-party',
  templateUrl: './lead-info-third-party.component.html',
  styleUrls: ['./lead-info-third-party.component.css']
})
export class LeadInfoThirdPartyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
