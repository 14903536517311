<div class="container pt-3" *appHasPermissions="['kpis-charges']">
    <div class="row justify-content-between mb-3">
        <div class="col-auto">
            <app-daterange-filter
                fieldName="date"
                [start]="filters.date_start"
                [end]="filters.date_end"
                (changeDate)="onChangeDate($event)"
                [disabled]="loading"
                [resetDates]="{ startDate: minDate, endDate: maxDate }"
                [minDate]="minDate"
            ></app-daterange-filter>
            <small *ngIf="loading" class="ml-2 text-primary">Loading...</small>
        </div>
    </div>

    <div class="form-row justify-content-between mb-4">
        <div class="col-md-auto">
            <div class="card">
                <div class="card-body text-center">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >Cash In (Cobros)</span
                            >
                        </h6>
                        <h6 class="text-primary mb-0">
                            {{
                                total?.total || 0
                                    | currency: 'EUR':'symbol':'1.0-0'
                            }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-auto">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-6 col-md-auto text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Payment Service</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.payment_service || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="ml-3 d-inline-block"
                                        >{{
                                            total?.payment_service_percent || 0
                                                | number: '1.0-0'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md-auto text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Back Office</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.backoffice || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="ml-3 d-inline-block"
                                        >{{
                                            total?.backoffice_percent || 0
                                                | number: '1.0-0'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md-auto text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Front End</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.front_end || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="d-inline-block ml-3"
                                        >{{
                                            total?.front_end_percent || 0
                                                | number: '1.0-0':'es'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md-auto text-center">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Launcher</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.launcher || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="d-inline-block ml-3"
                                        >{{
                                            total?.launcher_percent || 0
                                                | number: '1.0-0':'es'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-6 col-md-auto text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >TPV</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.tpv || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="ml-3 d-inline-block"
                                        >{{
                                            total?.tpv_percent || 0
                                                | number: '1.0-0'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md-auto text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Bank Transfer</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.bank_transfer || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="ml-3 d-inline-block"
                                        >{{
                                            total?.bank_transfer_percent || 0
                                                | number: '1.0-0'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="col-6 col-md-auto text-center">
                            <div class="py-2">
                                <h6 style="font-size: 14px">
                                    <span
                                        class="border-bottom d-inline-block pb-1"
                                        >Express</span
                                    >
                                </h6>
                                <h6 class="text-primary mb-0">
                                    {{
                                        total?.express || 0
                                            | currency: 'EUR':'symbol':'1.0-0'
                                    }}<small class="d-inline-block ml-3"
                                        >{{
                                            total?.express_percent || 0
                                                | number: '1.0-0':'es'
                                        }}%</small
                                    >
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="col-12 mb-3">
            <select
                name="time_unit"
                (change)="getDataOnFilterSubmit()"
                [(ngModel)]="filters.time_unit"
                [disabled]="loading"
            >
                <option value="month">Mensual</option>
                <option value="week">Semanal</option>
            </select>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Payment Service
                    </h5>
                    <div class="chart">
                        <canvas #paymentServiceChart></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Back Office
                    </h5>
                    <div class="chart">
                        <canvas #backOfficeChart></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Front End
                    </h5>
                    <div class="chart">
                        <canvas #frontEndChart></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Launcher
                    </h5>
                    <div class="chart">
                        <canvas #launcherChart></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        TPV
                    </h5>
                    <div class="chart">
                        <canvas #tpvChart></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Bank Transfer
                    </h5>
                    <div class="chart">
                        <canvas #bankTransferChart></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
