<button
  *ngIf="!item"
  type="button"
  class="btn btn-sm btn-primary"
  (click)="open(content)"
>
  Create New
</button>

<a *ngIf="item" href="#0" (click)="edit($event, item, content)">{{
  item?.name || 'Noname'
}}</a>

<ng-template #content let-modal>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Message Type</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      *ngIf="!templates?.length && !isLoading"
      class="modal-body text-center"
    >
      <p>There are no templates.</p>
      <a
        routerLink="/backoffice/settings/mail-templates/create"
        class="btn btn-sm btn-primary"
        (click)="modal.dismiss('Redirect')"
        >Create New</a
      >
    </div>
    <div
      *ngIf="templates?.length > 0 && !isLoading"
      [ngClass]="{ 'd-none': !templates.length }"
    >
      <div class="modal-body">
        <div class="form-group">
          <label class="mb-0" for="name"
            >Name<sup class="text-danger">*</sup></label
          >
          <input
            id="name"
            type="text"
            name="name"
            formControlName="name"
            class="form-control"
            placeholder="Name"
            required
          />
        </div>
        <div class="form-group">
          <label class="mb-0" for="subject"
            >Subject<sup class="text-danger">*</sup></label
          >
          <input
            id="subject"
            type="text"
            name="subject"
            formControlName="subject"
            class="form-control"
            placeholder="Subject"
            required
          />
        </div>
        <div class="form-group">
          <label class="mb-0" for="description"
            >Description<sup class="text-danger">*</sup></label
          >
          <input
            id="description"
            type="text"
            name="description"
            formControlName="description"
            class="form-control"
            placeholder="Description"
            required
          />
        </div>
        <div class="form-group">
          <label class="mb-0" for="template_id"
            >Template<sup class="text-danger">*</sup></label
          >
          <div class="d-flex align-items-center">
            <select
              *ngIf="!item?.published"
              id="template_id"
              name="template_id"
              formControlName="template_id"
              class="form-control"
              required
            >
              <option value="">Select Template</option>
              <option *ngFor="let item of templates" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
            <div *ngIf="item?.published">{{ item?.template?.name }}</div>
            <app-mail-templates-preview
              class="ml-2"
              [disabled]="form.controls.template_id.invalid || isSending"
              [id]="form.controls.template_id.value"
            ></app-mail-templates-preview>
          </div>
        </div>
        <div class="form-group mb-0">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              formControlName="enabled"
              id="typeCustomCheckEnabled"
            />
            <label
              class="mb-0"
              class="custom-control-label"
              for="typeCustomCheckEnabled"
              >Enabled</label
            >
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="isSending || form.invalid || item?.published"
        >
          {{ isSending ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </div>
    <div *ngIf="isLoading" class="modal-body text-center">
      <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
    </div>
  </form>
</ng-template>
