import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-setting-role-permission',
    templateUrl: './setting-role-permission.component.html',
    styleUrls: ['./setting-role-permission.component.scss']
})
export class SettingRolePermissionComponent implements OnInit {
    rolePermissionForm: FormGroup;
    role_id: any = null;
    allPermissions: any = null;
    promises: Array<any> = [];
    constructor(fb: FormBuilder, public authService: AuthService, private errorMessage: ErrorMessageService) {
        this.rolePermissionForm = fb.group({
            role_id: ['', Validators.required]
        });
    }
    ngOnInit() {
        this.authService.getAllRoles();
    }
    changePermission(checkbox) {
        if (checkbox.target.checked) {
            this.authService
                .attachPermission(this.rolePermissionForm.controls.role_id.value, checkbox.target.id)
                .subscribe(
                    permission => {
                        if (permission['accepted'] === true) {
                            this.errorMessage.showSuccess('El Permiso ha sido añadido');
                        } else {
                            this.errorMessage.showError('El permiso no se ha podido añadir');
                        }
                    },
                    error => {
                        if (error.error.error) {
                            this.errorMessage.showError(error.error.error.show_message);
                        }
                    }
                );
        } else {
            this.authService
                .detachPermission(this.rolePermissionForm.controls.role_id.value, checkbox.target.id)
                .subscribe(
                    permission => {
                        if (permission['accepted'] === true) {
                            this.errorMessage.showSuccess('El Permiso ha sido quitado');
                        } else {
                            this.errorMessage.showError('El permiso no se ha podido añadir');
                        }
                    },
                    error => {
                        if (error.error.error) {
                            this.errorMessage.showError(error.error.error.show_message);
                        }
                    }
                );
        }
    }
    getRolePermissions() {
        this.authService.getAllPermissions().subscribe(permissions => {
            if (Object.values(permissions).length) {
                this.allPermissions = permissions;
                this.authService
                    .getRolePermissions(this.rolePermissionForm.controls.role_id.value)
                    .subscribe(rolePermissions => {
                        if (Object.values(permissions).length) {
                            for (let i = 0; i < rolePermissions.length; i++) {
                                document.getElementById(rolePermissions[i].name).setAttribute('checked', 'true');
                            }
                        }
                    });
            }
        });
    }
    onFormSubmit() {
        if (this.rolePermissionForm.valid) {
            this.authService.createUser(this.rolePermissionForm.value).subscribe(
                resp => {
                    if (resp['accepted'] === true) {
                        this.errorMessage.showSuccess(`El usuario ${resp.user.username} ha sido creado`);
                    }
                },
                error => {
                    this.errorMessage.showError(error.error.error.show_message);
                }
            );
        }
    }
}
