import { Component, OnInit, Input } from '@angular/core';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';

@Component({
  selector: 'app-line-info-customer-info',
  templateUrl: './line-info-customer-info.component.html',
  styleUrls: ['./line-info-customer-info.component.scss']
})
export class LineInfoCustomerInfoComponent implements OnInit {
  @Input() user: any = null;
  @Input() lead: any = null;
  @Input() paymentBankCard: any = null;
  @Input() loading = true;
  constructor(private formatPhoneService: FormatPhoneNumberService) { }

  ngOnInit() {
  }

  formatPhone(phone) {
    if (phone) {
      return this.formatPhoneService.format(phone);
    }
  }

}
