import {
    Component,
    ViewChild,
    AfterViewInit,
    Input,
    OnChanges,
} from '@angular/core';
import {
    NgbModal,
    NgbModalRef,
    NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { MiniModalService } from '../../services/mini-modal.service';

declare var $: any;

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements AfterViewInit, OnChanges {
    @Input() creditline: any = null;
    @ViewChild('tracking') tracking;

    modalReference: NgbModalRef;

    options: NgbModalOptions = {
        size: 'lg',
        backdrop: false,
        windowClass: 'wide-modal',
    };

    isOpen: boolean = false;
    isMinimized: boolean = false;

    constructor(private modal: NgbModal, private minModal: MiniModalService) {}

    ngAfterViewInit() {}

    ngOnChanges() {
        if (this.creditline) {
            this.minModal.checkCreditline(this.creditline);
        }
    }

    openModal(content) {
        if (this.isOpen) {
            this.maximizeModal();
        } else {
            this.isOpen = true;
            this.modalReference = this.modal.open(content, this.options);

            document.body.classList.remove('modal-open');

            this.minModal.setReference(this.creditline, this.modalReference);
            this.modalReference.result
                .then((result) => {
                    this.isMinimized = false;
                    this.isOpen = false;
                })
                .catch((result) => {
                    this.isOpen = false;
                    this.isMinimized = false;
                });
        }
    }

    minimizeModal() {
        // const modalBackdrop = document.getElementsByClassName('modal-backdrop');
        const modal = document.getElementsByClassName('modal');
        const body = document.body;

        // modalBackdrop[0].setAttribute('style', 'display:none;');
        modal[0].classList.add('modal--minimized');
        // body.classList.remove('modal-open');

        this.isMinimized = true;
    }

    maximizeModal() {
        // const modalBackdrop = document.getElementsByClassName('modal-backdrop');
        const modal = document.getElementsByClassName('modal');
        const body = document.body;

        // modalBackdrop[0].setAttribute('style', 'display:block;');
        modal[0].classList.remove('modal--minimized');
        // body.classList.add('modal-open');

        this.isMinimized = false;
    }
}
