import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class FaqService {

  faqs: any = null;
  constructor(private http: HttpClient) { }

  getAll()
  {
    return this.http.get(environment.api_url + `/faq/list`).subscribe(
      list => {
        if(Object.values(list).length){
          this.faqs = list;
        }
      }
    );
  }
  get(faq_id): Observable<any>
  {
    return this.http.get(environment.api_url + `/faq/${faq_id}/show`);
  }
  update(faq_id, faq)
  {
    return this.http.post(environment.api_url + `/faq/${faq_id}/update`, faq);
  }
  create(faq)
  {
    return this.http.post(environment.api_url + `/faq/create`, faq);
  }
}
