import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { LeadService } from '../../../services/lead.service';
import { CreditLineService } from '../../../services/credit-line.service';
import { CheckNavBarService } from '../../../services/check-nav-bar.service';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';

@Component({
    selector: 'app-credit-line-check',
    templateUrl: './credit-line-check.component.html',
    styleUrls: ['./credit-line-check.component.css']
})
export class CreditLineCheckComponent implements OnInit {
    id: string;
    private sub: any;
    lead: any = null;
    leadStatus: any = null;
    leadRejectedReason: any = null;
    urlSegment: string = '';

    credit_line_check: any = null;
    user: any = null;
    check_completed: boolean = true;
    credit_line_active: boolean = false;
    credit_line_id: number;
    creditLine: any = null;

    checkLink = {};

    showCheck = true;

    constructor(
        private activate: ActivatedRoute,
        private router: Router,
        private leadService: LeadService,
        private authService: AuthService,
        private creditLineService: CreditLineService,
        private checkNavBarService: CheckNavBarService,
        private formatPhoneService: FormatPhoneNumberService
    ) { }

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];
            this.getLead(this.id);
        });
    }

    getLead(id) {
        this.leadService.get(id).then(lead => {
            /*console.log(resp);*/
            if (Object.values(lead).length) {
                this.lead = lead;

                this.creditLineService.getByLeadId(this.lead.id).subscribe(creditLine => {
                    if (Object.values(creditLine).length) {
                        this.creditLine = creditLine;
                        this.getCreditLineCheck(this.creditLine.id);
                    }
                });
                /*Obteniendo los status del LEAD */
                /*
            this.leadService.getLeadStatus().then(
              status => {
                if(Object.values(status).length){
                  this.leadStatus = status;
                }
              }
            );*/
                /*Obtiene el chequeo de la linea de credito */
                /*Obtiene el chequeo de la validacion de la linea de credito para especificar en pantalla las partes chequeada */

                /*
          this.leadService.getLeadRejectedReason().then(
            reason => {
              if(Object.values(reason).length){
                this.leadRejectedReason = reason;
              }
            }
          );
          */
                //   this.authService.getUserByLeadId(this.lead.id).then(
                //     user => {
                //       if(Object.values(user).length){
                //         this.user = user;
                //       }
                //     }
                //   );
            }
        });
    }
    getCreditLineCheck(loan_id) {
        this.leadService.getCreditLineCheck(loan_id).subscribe(_lead => {
            if (Object.values(_lead).length) {
                /*Verificando si la linea de credito puede ser checkeada */
                this.creditLineService.canCheck(_lead.loan_id).subscribe(can => {
                    if (!can) {
                        this.router.navigate([`/backoffice/loans/loan/all`]);
                    } else {
                        this.credit_line_check = _lead;
                        console.log(_lead);
                        if (
                            this.credit_line_check.doc_document_confirming_address &&
                            this.credit_line_check.doc_personalid_back_face &&
                            this.credit_line_check.doc_personalid_front_face &&
                            this.credit_line_check.doc_credit_card_selfie
                        ) {
                            this.credit_line_check.docs = true;
                        }
                        if (_lead.completed) {
                            this.checkNavBarService.completed = true;
                        } else {
                            this.checkNavBarService.completed = false;
                        }
                        this.credit_line_id = _lead.loan_id;
                    }
                });
            }
        });
    }
    creditLineActive() {
        if (this.credit_line_id) {
            this.creditLineService.active(this.credit_line_id).subscribe(resp => {
                if (resp === true) {
                    this.router.navigate([`/backoffice/loans/loan/loan-checking`]);
                }
            });
        }
    }
    creditLineReject() {
        this.creditLineService.reject(this.credit_line_id).subscribe(resp => {
            if (resp['accepted'] === true) {
                this.router.navigate([`/backoffice/loans/loan/loan-checking`]);
            }
        });
    }
    goto(route, event) {
        if (event) {
            let target = event.target || event.srcElement || event.currentTarget;
            if (document.getElementsByClassName('tablinks-selected').length > 0) {
                document.getElementsByClassName('tablinks-selected')[0].className = 'tablinks';
            }
            (<HTMLInputElement>target).className = 'tablinks-selected';
        }
        this.router.navigate([`/backoffice/loans/loan-check/${this.id}/${route}`, this.id]);
    }

    onChangeActivation(creditLineChecking) {
        this.checkLink = creditLineChecking;
    }

    showCheckTrue() {
        this.showCheck = true;
    }
    showCheckFalse() {
        this.showCheck = false;
    }

    formatPhone(phone) {
        if (phone) {
            return this.formatPhoneService.format(phone);
        }
    }
}
