import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorMessageService {
    constructor(private toastr: ToastrService) {}

    showSuccess(
        message = '',
        title = '',
        config: Partial<IndividualConfig> = {}
    ) {
        this.toastr.success(message, title, config);
    }
    showError(
        message = '',
        title = '',
        config: Partial<IndividualConfig> = {}
    ) {
        this.toastr.error(message, title, { ...config });
    }
}
