import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-lead-info-bank-aggregation',
    templateUrl: './lead-info-bank-aggregation.component.html',
    styleUrls: ['./lead-info-bank-aggregation.component.css']
})
export class LeadInfoBankAggregationComponent implements OnInit {
    @Input() bank_owner: any = null;
    @Input() loading = true;
    constructor() {}

    ngOnInit() {}
}
