<!-- *appHasPermissions="['finance-losses']" -->
<div>
    <div class="row justify-content-between">
        <div class="col-sm-12 col-lg-auto">
            <h2>Losses</h2>
            <a
                [href]="exportLink"
                (click)="getExportLink($event)"
                [ngClass]="{ disabled: loadingExport }"
                >{{ loadingExport ? 'Loading. Please Wait...' : 'Export' }}</a
            >
        </div>
        <div class="col-sm-6 col-lg-2 ml-auto">
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="card-header-title text-center">LOSSES</h5>
                </div>
                <div class="card-body">
                    <p class="text-center">
                        {{ total?.debt_lost | currency: 'EUR' }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Loss Date</th>
                    <th>Loan</th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                    <th>Loss Amount</th>
                    <!-- <th>Type</th> -->
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-daterange-filter
                            fieldName="last_status_date"
                            [start]="filters.last_status_date_start"
                            [end]="filters.last_status_date_end"
                            (changeDate)="onChangeDate($event)"
                            [withStartDate]="true"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loan Code"
                            name="code_str"
                            [(ngModel)]="filters.code_str"
                        />
                    </td>
                    <td>
                        <input
                            size="10"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="DNI / NIE"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Customer Name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loss Amount"
                            name="amount"
                            [(ngModel)]="filters.amount"
                        />
                    </td>
                    <!-- <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="type_id"
                            [(ngModel)]="filters.type_id"
                        >
                            <option value="">Select Type</option>
                            <option
                                *ngFor="let key of invoicesTypes | keys"
                                value="{{ key }}"
                            >
                                {{ invoicesTypes[key] }}
                            </option>
                        </select>
                    </td> -->
                </tr>

                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>{{ item?.last_status_date }}</td>
                        <td>
                            <a
                                class="miLineaFontColor"
                                (click)="goto(item.lead_id)"
                                >{{ item?.code_str }}</a
                            >
                        </td>
                        <td>{{ item?.personalid }}</td>
                        <td>{{ item?.full_name }}</td>
                        <td>{{ item?.debt_lost | currency: 'EUR' }}</td>
                        <!-- <td>{{ item?.type ? item?.type : '-' }}</td> -->
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
