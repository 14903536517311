import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { take } from 'rxjs/operators';
import { ErrorMessageService } from '../../../../../shared/error-message.service';

declare const moment;

@Component({
    selector: 'app-queue-collection',
    templateUrl: './queue-collection.component.html',
    styleUrls: ['./queue-collection.component.scss'],
})
export class QueueCollectionComponent implements OnInit, OnDestroy {
    paramsSubscriber: ISubscription;

    pageTitle = 'Create';

    form: FormGroup;

    paymentProvidersList = [];
    hourList = [];
    monthList = [];
    monthDayList = [];
    weekDayList = [];
    loanStatusList = [];
    cardStatusList = [];
    bankCardList = [];
    jobTypesList = [];
    paymentTreeAmountList = [];
    paymentSourceList = [];

    dropdownSettings = {};
    hourSelectSettings = {};

    isSending = false;
    isLoading = true;
    taskId = null;

    minuteMask = [/[0-5]/, /[0-9]/];

    constructor(
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private debtrecoveryService: DebtRecoveryService,
        private errorMessageService: ErrorMessageService,
        private router: Router
    ) {
        this.form = this.fb.group({
            name: ['', Validators.required],
            payment_provider_ids: ['', Validators.required],
            payment_source_id: ['', Validators.required],
            month: ['', Validators.required],
            month_day: ['', Validators.required],
            week_day: ['', Validators.required],
            hour: ['', Validators.required],
            minute: ['', [Validators.required, Validators.minLength(2)]],
            loan_status_ids: ['', Validators.required],
            bank_card_status_ids: [''],
            bank_card_bank_ids: [''],
            user_job_type_ids: [''],
            loan_expiration_day_min: [''],
            loan_expiration_day_max: [''],
            payment_amount: [''],
            payment_tree_amount_id: [''],
            enabled: [true, Validators.required],
        });
    }

    ngOnInit() {
        this.setMultiselect();
        this.createHours();
        this.createMonthList();
        this.createMonthDaysList();
        this.createWeekDaysList();

        this.setDropdownLists(this.debtrecoveryService);

        const params$ = this.activatedRoute.params;

        this.paramsSubscriber = params$.subscribe((params) => {
            if (params && params.id) {
                this.pageTitle = 'Update';

                this.debtrecoveryService
                    .showTask(params.id)
                    .pipe(take(1))
                    .subscribe((response) => {
                        this.setStartForm(response);
                        this.taskId = response.id;
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        });
    }

    ngOnDestroy() {
        this.paramsSubscriber.unsubscribe();
    }

    setMultiselect() {
        this.dropdownSettings = {
            singleSelection: false,
            allowSearchFilter: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
        };

        this.hourSelectSettings = {
            singleSelection: false,
            allowSearchFilter: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
        };
    }

    createHours() {
        for (let i = 0; i < 24; i++) {
            this.hourList.push({
                id: i,
                name: String(i).length === 1 ? `0${i}` : `${i}`,
            });
        }
    }

    createMonthList() {
        this.monthList = this.createMoments(1, 12, {
            input: 'M',
            output: 'MMM',
        });
    }

    createMonthDaysList() {
        this.monthDayList = this.createMoments(1, 31);
    }

    createWeekDaysList() {
        this.weekDayList = this.createMoments(0, 6, {
            input: 'd',
            output: 'ddd',
        });
    }

    createMoments(start, end, format?: { input: string; output: string }) {
        const array = [];

        for (let index = start; index <= end; index++) {
            array.push({
                id: index,
                name: format
                    ? this.momentFormat(index, format.input, format.output)
                    : `${index}`,
            });
        }

        console.log(array);
        return array;
    }

    momentFormat(input, inputFormat, outputFormat) {
        return moment(input, inputFormat).format(outputFormat);
    }

    setDropdownLists(service: DebtRecoveryService) {
        const dropdownLists = [
            {
                serviceMethod: () => service.getPaymentProviders(),
                list: 'paymentProvidersList',
            },
            {
                serviceMethod: () => service.getLoanStatuses(),
                list: 'loanStatusList',
            },
            {
                serviceMethod: () => service.getCardStatuses(),
                list: 'cardStatusList',
            },
            {
                serviceMethod: () => service.getBankList(),
                list: 'bankCardList',
            },
            {
                serviceMethod: () => service.getLeadInfo(),
                list: 'jobTypesList',
            },
            {
                serviceMethod: () => service.getPaymentTreeAmount(),
                list: 'paymentTreeAmountList',
            },
            {
                serviceMethod: () => service.getPaymentSources(),
                list: 'paymentSourceList',
            },
        ];

        this.getDropdownLists(dropdownLists);
    }

    getDropdownLists(
        list: { serviceMethod: () => Observable<any>; list: string }[]
    ) {
        const _this = this;
        list.forEach((item) => {
            item.serviceMethod()
                .pipe(take(1))
                .subscribe((response) => {
                    if (
                        Array.isArray(response) &&
                        item.list !== 'bankCardList'
                    ) {
                        _this[item.list] = response.map((entry) => ({
                            id: Number(entry.id),
                            name: entry.name,
                        }));
                    }
                    if (
                        Array.isArray(response) &&
                        item.list === 'bankCardList'
                    ) {
                        _this[item.list] = response.map((bank) => ({
                            id: Number(bank.id),
                            name: bank.full_name,
                        }));
                    }
                    if (
                        response instanceof Object &&
                        item.list !== 'jobTypesList' &&
                        item.list !== 'bankCardList' &&
                        !Array.isArray(response)
                    ) {
                        _this[item.list] = Object.keys(response).map((key) => ({
                            id: Number(key),
                            name: response[key],
                        }));
                    }
                    if (
                        response instanceof Object &&
                        item.list === 'jobTypesList'
                    ) {
                        _this[item.list] = Object.keys(
                            response.lead_job_type
                        ).map((key) => ({
                            id: Number(key),
                            name: response.lead_job_type[key],
                        }));
                    }
                });
        });
    }

    setStartForm(response) {
        this.form.controls.name.setValue(response.name || '');

        const filters = response.filter || null;
        const featurePayment =
            response.operation_feature && response.operation_feature.payment
                ? response.operation_feature.payment
                : null;
        const schedule =
            response.schedule && response.schedule.calendar
                ? response.schedule.calendar
                : null;

        if (featurePayment) {
            this.form.controls.payment_source_id.setValue(
                String(featurePayment.source_id || '')
            );
            this.form.controls.payment_amount.setValue(
                featurePayment.amount || ''
            );
            this.form.controls.payment_tree_amount_id.setValue(
                String(featurePayment.tree_amount_id || '')
            );
        }

        if (filters) {
            const { bank_card, loan, payment, user } = filters;
            if (
                payment &&
                payment.provider_ids &&
                payment.provider_ids.length > 0
            ) {
                this.form.controls.payment_provider_ids.setValue(
                    this.converFilterValue(
                        payment.provider_ids,
                        this.paymentProvidersList
                    )
                );
            }
            if (loan) {
                if (loan.status_ids && loan.status_ids.length > 0) {
                    this.form.controls.loan_status_ids.setValue(
                        this.converFilterValue(
                            loan.status_ids,
                            this.loanStatusList
                        )
                    );
                }

                const expirationDay = loan.expiration_day || null;
                if (expirationDay) {
                    this.form.controls.loan_expiration_day_min.setValue(
                        expirationDay.min || ''
                    );
                    this.form.controls.loan_expiration_day_max.setValue(
                        expirationDay.max || ''
                    );
                }
            }
            if (bank_card) {
                const { bank_ids, status_ids } = bank_card;
                if (bank_ids && bank_ids.length > 0) {
                    this.form.controls.bank_card_bank_ids.setValue(
                        this.converFilterValue(bank_ids, this.bankCardList)
                    );
                }
                if (status_ids && status_ids.length > 0) {
                    this.form.controls.bank_card_status_ids.setValue(
                        this.converFilterValue(status_ids, this.cardStatusList)
                    );
                }
            }
            if (user && user.job_type_ids && user.job_type_ids.length > 0) {
                this.form.controls.user_job_type_ids.setValue(
                    this.converFilterValue(user.job_type_ids, this.jobTypesList)
                );
            }
        }

        if (schedule) {
            const { hour, minute, month, month_day, week_day } = schedule;

            this.form.controls.hour.setValue(
                hour[0] === '*'
                    ? this.hourList
                    : this.converFilterValue(hour, this.hourList)
            );

            this.form.controls.minute.setValue(
                String(minute).length === 1 ? `0${minute}` : minute
            );

            this.form.controls.month.setValue(
                month[0] === '*'
                    ? this.monthList
                    : this.converFilterValue(month, this.monthList)
            );
            this.form.controls.month_day.setValue(
                month_day[0] === '*'
                    ? this.monthDayList
                    : this.converFilterValue(month_day, this.monthDayList)
            );
            this.form.controls.week_day.setValue(
                week_day[0] === '*'
                    ? this.weekDayList
                    : this.converFilterValue(week_day, this.weekDayList)
            );
        }

        this.form.controls.enabled.setValue(response.enabled);
    }

    converFilterValue(filters, list) {
        const array = [];

        filters.forEach((item) => {
            array.push(list.filter((i) => Number(i.id) === Number(item))[0]);
        });

        return array;
    }

    onSubmit() {
        this.isSending = true;

        const form = this.form.getRawValue();

        if (form.payment_provider_ids && form.payment_provider_ids.length > 0) {
            form.payment_provider_ids = form.payment_provider_ids.map((item) =>
                Number(item.id)
            );
        }

        if (form.month && form.month.length > 0) {
            form.month = form.month.map((item) => Number(item.id));
            if (form.month.length === 12) {
                form.month = ['*'];
            }
        }

        if (form.month_day && form.month_day.length > 0) {
            form.month_day = form.month_day.map((item) => Number(item.id));
            if (form.month_day.length === 31) {
                form.month_day = ['*'];
            }
        }

        if (form.week_day && form.week_day.length > 0) {
            form.week_day = form.week_day.map((item) => Number(item.id));
            if (form.week_day.length === 7) {
                form.week_day = ['*'];
            }
        }

        if (form.loan_status_ids && form.loan_status_ids.length > 0) {
            form.loan_status_ids = form.loan_status_ids.map((item) =>
                Number(item.id)
            );
        }

        if (form.bank_card_status_ids && form.bank_card_status_ids.length > 0) {
            form.bank_card_status_ids = form.bank_card_status_ids.map((item) =>
                Number(item.id)
            );
        }

        if (form.bank_card_bank_ids && form.bank_card_bank_ids.length > 0) {
            form.bank_card_bank_ids = form.bank_card_bank_ids.map((item) =>
                Number(item.id)
            );
        }

        if (form.user_job_type_ids && form.user_job_type_ids.length > 0) {
            form.user_job_type_ids = form.user_job_type_ids.map((item) =>
                Number(item.id)
            );
        }

        if (form.payment_tree_amount_id) {
            form.payment_tree_amount_id = Number(form.payment_tree_amount_id);
        }

        if (form.payment_amount) {
            form.payment_amount = Number(form.payment_amount);
        }

        if (form.loan_expiration_day_min) {
            form.loan_expiration_day_min = Number(form.loan_expiration_day_min);
        }

        if (form.loan_expiration_day_max) {
            form.loan_expiration_day_max = Number(form.loan_expiration_day_max);
        }

        if (form.payment_source_id) {
            form.payment_source_id = Number(form.payment_source_id);
        }

        if (form.hour && form.hour.length > 0) {
            form.hour = form.hour.map((item) => Number(item.id));
            if (form.hour.length === 24) {
                form.hour = ['*'];
            }
        }

        if (form.minute) {
            form.minute = Number(form.minute);
        }

        Object.keys(form).forEach((key) => {
            if (form[key] === '' || form[key] === null || key === 'time') {
                delete form[key];
            }
        });

        if (this.taskId) {
            this.debtrecoveryService
                .updateQueue(this.taskId, form)
                .pipe(take(1))
                .toPromise()
                .then((response) => {
                    if (response.name) {
                        this.errorMessageService.showSuccess(
                            `Task ${response.name} updated successfuly!`
                        );
                    }
                    this.isSending = false;
                })
                .catch((err) => {
                    this.errorMessageService.showError(
                        err.error.error.show_message
                    );
                    this.isSending = false;
                });
        } else {
            this.debtrecoveryService
                .createQueue(form)
                .pipe(take(1))
                .toPromise()
                .then((response) => {
                    if (response.name) {
                        this.errorMessageService.showSuccess(
                            `Task ${response.name} created successfuly!`
                        );

                        this.router.navigate([
                            `/backoffice/debtrecovery/queue-manager/collection/${response.id}`,
                        ]);
                    }
                    this.isSending = false;
                })
                .catch((err) => {
                    this.errorMessageService.showError(
                        err.error.error.show_message
                    );
                    this.isSending = false;
                });
        }
    }
}
