import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../../../services/email.service';
import { ErrorMessageService } from '../../../../../../shared/error-message.service';
import { SubscriptionLike } from 'rxjs';

@Component({
    selector: 'app-mail-providers-statuses-create',
    templateUrl: './mail-providers-statuses-create.component.html',
    styleUrls: ['./mail-providers-statuses-create.component.scss'],
})
export class MailProvidersStatusesCreateComponent implements OnInit, OnDestroy {
    @Input() item;
    @Output() itemCreated = new EventEmitter<any>();
    @Output() itemUpdated = new EventEmitter<any>();

    providersSubscriber: SubscriptionLike;
    statusesSubscriber: SubscriptionLike;

    id;
    isSending = false;
    isLoading = true;

    form = this.fb.group({
        status_id: ['', Validators.required],
        provider_id: ['', Validators.required],
        provider_status_name: ['', Validators.required],
    });

    providers: any[];
    statuses: any[];

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private emailService: EmailService,
        private errorMessage: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        this.unsubscribe();
    }

    edit(item, content) {
        const { status_id, provider_id, provider_status_name } = item;

        this.form.setValue({
            status_id,
            provider_id,
            provider_status_name,
        });

        this.open(content);
    }

    open(content) {
        this.getLists();

        this.modalService.open(content).result.then(
            (result) => {
                this.resetForm();
            },
            (reason) => {
                this.resetForm();
            }
        );
    }

    onSubmit() {
        let args;
        const formValues = this.form.getRawValue();
        const params = {
            provider_id: +formValues.provider_id,
            status_id: +formValues.status_id,
            provider_status_name: formValues.provider_status_name,
        };

        const id = this.item?.id || this.id;
        const method = id ? 'editProvidersStatuses' : 'createProvidersStatuses';

        args = id ? { id, params } : { params };

        this.form.disable();

        this.emailService[method](args).subscribe(
            (response) => {
                this.onSuccess(response, id);
            },
            (error) => {
                this.onError(error);
            }
        );
    }

    onSuccess(item, id = null) {
        this.errorMessage.showSuccess('', 'Saved Successfully');
        this.isSending = false;
        this.form.enable();

        if (id) {
            this.itemUpdated.emit(item);
        } else {
            this.itemCreated.emit(item);
            this.form.setValue({
                status_id: '',
                provider_id: '',
                provider_status_name: '',
            });
        }
    }

    onError(error) {
        this.errorMessage.showError(
            error?.error?.error?.details,
            'Saving Failed'
        );
        this.isSending = false;
        this.form.enable();
    }

    resetForm() {
        this.form.setValue({
            status_id: '',
            provider_id: '',
            provider_status_name: '',
        });

        this.providers = [];
        this.statuses = [];
        this.unsubscribe();
    }

    getLists() {
        this.providersSubscriber = this.emailService
            .getProviders()
            .subscribe(
                (resp) =>
                    (this.providers =
                        resp.filter(({ enabled }) => enabled) || [])
            );

        this.statusesSubscriber = this.emailService
            .getStatuses()
            .subscribe((resp) => (this.statuses = resp || []));
    }

    unsubscribe() {
        if (this.providersSubscriber) this.providersSubscriber.unsubscribe();
        if (this.statusesSubscriber) this.statusesSubscriber.unsubscribe();
    }
}
