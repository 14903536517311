<button
    *ngIf="item"
    type="button"
    class="btn btn-sm btn-primary"
    (click)="edit(item, content)"
>
    Edit
</button>

<button
    *ngIf="!item"
    type="button"
    class="btn btn-sm btn-primary"
    (click)="open(content)"
>
    Create New
</button>

<ng-template #content let-modal>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Messages Provider Status
            </h4>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
                type="button"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label class="mb-0" for="status_id"
                    >Status<sup class="text-danger">*</sup></label
                >
                <select
                    id="status_id"
                    name="status_id"
                    formControlName="status_id"
                    class="form-control"
                    required
                >
                    <option value="">Select Status</option>
                    <option *ngFor="let item of statuses" [value]="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-0" for="provider_id"
                    >Provider<sup class="text-danger">*</sup></label
                >
                <select
                    id="provider_id"
                    name="provider_id"
                    formControlName="provider_id"
                    class="form-control"
                    required
                >
                    <option value="">Select Provider</option>
                    <option *ngFor="let item of providers" [value]="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="form-group mb-0">
                <label class="mb-0" for="provider_status_name"
                    >Provider Status Name<sup class="text-danger">*</sup></label
                >
                <input
                    id="provider_status_name"
                    type="text"
                    name="provider_status_name"
                    formControlName="provider_status_name"
                    class="form-control"
                    placeholder="Provider Status Name"
                    required
                />
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="isSending || form.invalid"
            >
                {{ isSending ? 'Saving...' : 'Save' }}
            </button>
        </div>
    </form>
</ng-template>
