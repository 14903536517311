import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FinanceService } from '../../../services/finance.service';

import { saveAs } from 'file-saver';
import { CreditLineService } from '../../../services/credit-line.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-due-debt',
    templateUrl: './due-debt.component.html',
    styleUrls: ['./due-debt.component.scss']
})
export class DueDebtComponent implements OnInit, OnDestroy {
    loading: boolean = true;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    tableData;
    total: any = [];

    startData: any = {};

    filters: any = {};

    queryString: string = '';

    selects: any = {
        asnef_statuses: [],
        statuses: []
    };

    promises = [];

    exportFile = null;
    exportLink = null;

    totalQueryString = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private financeService: FinanceService,
        private router: Router,
        private loanService: CreditLineService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.getSatuses();
        this.getAsnefStatuses();
        Promise.all(this.promises).then();

        this.querySubscriber = this.activatedRoute.queryParams.subscribe(
            queryParams => {
                this.startData = {
                    loan_code_str: '',
                    activation_date_at_start: '',
                    activation_date_at_end: '',
                    due_status_date_at_start: '',
                    due_status_date_at_end: '',
                    amount_at_start: '',
                    amount_at_end: '',

                    total_debt_at_start: '',
                    total_debt_at_end: '',
                    initial_debt_at_start: '',
                    initial_debt_at_end: '',

                    debt_left_at_start: '',
                    debt_left_at_end: '',
                    personalid: '',
                    full_name: '',
                    status_id: '',
                    asnef_status_id: ''
                };

                if (this.dataSubscriber) {
                    this.dataSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getTableData();
            }
        );

        this.getTotals(this.totalQueryString);
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.financeService
            .getDueDebtList(this.queryString)
            .subscribe(response => {
                if (response) {
                    this.tableData = response;
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            key =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            key => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDateTotal(e: any) {
        const date = e;
        const clearFilters = { ...date };

        Object.keys(clearFilters).forEach(
            key =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.totalQueryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');

        this.getTotals(this.totalQueryString);
    }

    onChangeRange(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getExportLink(e) {
        e.preventDefault();

        this.financeService
            .getDueDebtExport(this.queryString)
            .subscribe(exel => {
                let file = new Blob([exel], {
                    type: 'application/vnd.ms-excel'
                });
                this.exportFile = URL.createObjectURL(file);
                this.exportLink = this.sanitizer.bypassSecurityTrustUrl(
                    this.exportFile
                );

                saveAs(file, 'due_debt.xlsx');
            });
    }

    getTotals(filterUrlParams) {
        this.financeService
            .getDueDebtTotals(filterUrlParams)
            .toPromise()
            .then(response => {
                if (response) {
                    this.total = response;
                }
            });
    }

    getAsnefStatuses() {
        this.promises.push(
            this.userService
                .getAsnefStatuses()
                .toPromise()
                .then(response => {
                    if (response) {
                        this.selects.asnef_statuses = response;
                    }
                })
                .catch(err => console.log(err))
        );
    }

    getSatuses() {
        this.promises.push(
            this.loanService
                .getStatus()
                .toPromise()
                .then(response => {
                    if (response) {
                        this.selects.statuses = response;
                    }
                })
        );
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([
                `/backoffice/loans/overview/${lead_id}/loanInfo/`,
                lead_id
            ]);
        }
    }

    formatDate(stringDate) {
        let formatDateString;
        if (stringDate) {
            formatDateString = new Date(stringDate.replace(/-/g, '/'));
        }
        return formatDateString;
    }
}
