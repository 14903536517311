import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    loading: boolean = true;

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    tableData: any = null;

    startData = {};

    filters = {};

    queryString = {};

    searchString = '';

    constructor(private activatedRoute: ActivatedRoute, private http: HttpService, private router: Router) {}

    ngOnInit() {
        this.querySubscriber = this.activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams['text']) {
                this.searchString = queryParams['text'];
                if (this.dataSubscriber) {
                    this.dataSubscriber.unsubscribe();
                }

                // Set Init Filters
                this.setInitFilters(queryParams);
                // Create Query String
                this.createQueryString(this.filters);

                this.getTableData();
            }
        });
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.http.search(this.queryString).subscribe(response => {
            if (response) {
                this.tableData = response;
                this.loading = false;
            }
        });
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            key => (!clearFilters[key] || clearFilters[key] === 'empty') && delete clearFilters[key]
        );

        this.queryString = clearFilters;
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(key => !filters[key] && delete filters[key]);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([`/backoffice/loans/overview/${lead_id}/loanInfo/`, lead_id]);
        }
    }
}
