<div *ngIf="list.length || currentStatus" class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">ASNEF Status</h5>
    </div>
    <div class="card-body">
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-6">
                        <strong>Asnef Current Status:</strong>
                    </div>
                    <div class="col-6">
                        {{ currentStatus?.status?.name || '-' }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-4"><strong>Loan:</strong></div>
                    <div class="col-8">
                        {{ currentStatus?.loan?.code_str || '-' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-6"><strong>Asnef Last Update:</strong></div>
                    <div class="col-6">
                        {{ currentStatus?.last_status_at || '-' }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-4"><strong>Debt Left:</strong></div>
                    <div class="col-8">
                        {{
                            currentStatus?.loan?.debt_left || 0
                                | currency: 'EUR'
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="currentStatus?.status?.name === 'Included'"
            class="text-right mb-3"
        >
            <app-asnef-exlude
                [isShort]="false"
                [reasonsList]="reasonsList"
                [record_id]="currentStatus?.id"
                (userExluded)="onUserExluded($event)"
            ></app-asnef-exlude>
        </div>
        <div *ngIf="list.length" class="table-responsive">
            <h5>Status History</h5>
            <table class="table table-condensed table-hover">
                <thead>
                    <th width="33.33333%">Date</th>
                    <th width="33.33333%">Status</th>
                    <th width="33.33333%">Who</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of list">
                        <td>{{ item?.created_at }}</td>
                        <td>{{ item?.status?.name }}</td>
                        <td>{{ item?.admin?.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
