<!-- antifraud tab page -->

<div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12">
        <app-lead-info-advising-validation
            [validation_advising_antifrauds]="validation_advising_antifrauds"
            [loading]="loading"
        ></app-lead-info-advising-validation>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12">
        <app-lead-info-discard-validation
            [validation_discard_antifraud]="validation_discard_antifraud"
            [loading]="loading"
        >
        </app-lead-info-discard-validation>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12">
        <app-lead-info-bank-aggregation [bank_owner]="bank_owner" [loading]="loading"></app-lead-info-bank-aggregation>
    </div>

    <!-- antifraud tab page end -->
</div>
