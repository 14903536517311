import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { LegalMonitoringService } from '../../../../services/legal-monitoring.service';

@Component({
    selector: 'app-monitoring-list',
    templateUrl: './monitoring-list.component.html',
    styleUrls: ['./monitoring-list.component.scss'],
})
export class MonitoringListComponent implements OnInit, OnDestroy {
    isLoading: boolean = true;

    dataSubscriber: SubscriptionLike;
    querySubscriber: SubscriptionLike;

    tableData;

    constructor(
        private activatedRoute: ActivatedRoute,
        private legalMonitoringService: LegalMonitoringService
    ) {}

    ngOnInit(): void {
        const params$ = this.activatedRoute.params;

        this.querySubscriber = params$.subscribe(
            ({ status: status_id, situation: situation_id }) => {
                this.getData(
                    situation_id ? { status_id, situation_id } : { status_id }
                );
            }
        );
    }

    ngOnDestroy(): void {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getData(params): any {
        this.isLoading = true;

        this.dataSubscriber = this.legalMonitoringService
            .getProceedings(params)
            .subscribe((response) => {
                this.tableData = response;
                const count = this.tableData?.data.length;

                this.legalMonitoringService.emitListLoaded({
                    status: params.status_id,
                    count,
                });

                this.isLoading = false;
            });
    }
}
