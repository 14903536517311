import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-backoffice-kpis',
    templateUrl: './backoffice-kpis.component.html',
    styleUrls: ['./backoffice-kpis.component.css'],
})
export class BackofficeKpisComponent implements OnInit {
    menu: Array<any> = [
        {
            title: 'Negocio',
            href: '/backoffice/kpis',
        },
        {
            title: 'Concesiones',
            href: 'concessions',
        },
        {
            title: 'Cobros',
            href: 'charges',
        },
        {
            title: 'Rentabilidad',
            href: 'profitability',
        },
        {
            title: 'Evolución rentabilidad',
            href: 'profitability-evolution',
        },
        // 'loans',
        // 'one-view',
        // 'financial-data',
        // 'debt-recovery',
    ];
    constructor() {}

    ngOnInit() {}
}
