import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BankTransactionService {
    constructor(private http: HttpClient) {}
    getPaginatedList(lead_id, params = ''): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/lead/${lead_id}/bank_aggregation_transaction/list?` +
                params
        );
    }

    getAccountList(lead_id): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/lead/${lead_id}/bank_aggregation_account/list`
        );
    }
}
