<div>
    <div class="row justify-content-between mb-3">
        <div class="col-sm-12 col-lg-auto">
            <h2 class="d-inline-block">Provisions</h2>
        </div>
        <div class="col-12">
            <label class="mr-2">Select Year:</label>
            <select
                name="year"
                (change)="getDataOnFilterSubmit()"
                [(ngModel)]="filters.year"
                [disabled]="loading"
            >
                <option *ngFor="let year of years" [value]="year">
                    {{ year }}
                </option>
            </select>
            <small *ngIf="loading" class="ml-2 text-primary">Loading...</small>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead *ngIf="!loading && data.length">
                <tr>
                    <th></th>
                    <th *ngFor="let item of data" style="white-space: nowrap">
                        {{ item?.month }}
                        <a
                            *ngIf="item?.id"
                            [href]="exportLink"
                            (click)="
                                getExportLink($event, {
                                    id: item.id,
                                    month: item?.month
                                })
                            "
                            class="ml-1"
                            [ngClass]="{ disabled: download }"
                            title="Export"
                        >
                            <i class="fa fa-download" aria-hidden="true"></i>
                        </a>
                    </th>
                    <th>TOTAL</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!loading && data.length">
                    <th style="padding: 0">
                        <table class="w-100 table mb-0 table-borderless">
                            <tr>
                                <td>Loss</td>
                            </tr>
                            <tr>
                                <td>Recovered</td>
                            </tr>
                            <tr>
                                <td>Net Loss</td>
                            </tr>
                        </table>
                    </th>
                    <td *ngFor="let item of data" style="padding: 0">
                        <table class="w-100 table mb-0 table-borderless">
                            <tr>
                                <td>
                                    {{
                                        item?.amount !== '-'
                                            ? (item?.amount | currency: 'EUR')
                                            : item?.amount
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        item?.amount_recovered !== '-'
                                            ? (item?.amount_recovered
                                              | currency: 'EUR')
                                            : item?.amount_recovered
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    [ngClass]="{
                                        'text-success':
                                            item?.net_loss > 0 &&
                                            item?.net_loss !== '-',
                                        'text-danger':
                                            item?.net_loss < 0 &&
                                            item?.net_loss !== '-'
                                    }"
                                >
                                    {{
                                        item?.net_loss !== '-'
                                            ? (item?.net_loss | currency: 'EUR')
                                            : item?.net_loss
                                    }}
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td style="padding: 0" class="border-left">
                        <table
                            class="w-100 table mb-0 table-borderless font-weight-bold"
                        >
                            <tr>
                                <td>{{ total?.amount | currency: 'EUR' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        total?.amount_recovered
                                            | currency: 'EUR'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    [ngClass]="{
                                        'text-success': total?.net_loss > 0,
                                        'text-danger': total?.net_loss < 0
                                    }"
                                >
                                    {{ total?.net_loss | currency: 'EUR' }}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr *ngIf="!loading && !data.length">
                    <td colspan="15">
                        <p class="py-5 mb-0">Nothing Found</p>
                    </td>
                </tr>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="15">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
