<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">Customer Loans</h5>
    </div>
    <div class="card-body">
        <div class="margin-top-20 table-responsive">
            <table class="table table-condensed table-hover">
                <thead>
                    <th>Code</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Weeks</th>
                </thead>
                <tbody>
                    <tr *ngFor="let line of credit_lines?.data">
                        <td>
                            <a
                                target="_blank"
                                href="/backoffice/loans/overview/{{
                                    line?.lead_id
                                }}/loanInfo/{{ line?.lead_id }}"
                                >{{ line.code_str }}</a
                            >
                        </td>
                        <td>{{ line.status.name }}</td>
                        <td>{{ line.amount | currency: 'EUR' }}</td>
                        <td>{{ line.product?.weeks }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
