import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-templates-varibles',
  templateUrl: './mail-templates-varibles.component.html',
  styleUrls: ['./mail-templates-varibles.component.scss'],
})
export class MailTemplatesVariblesComponent implements OnInit {
  addedItem;

  ngOnInit(): void {}

  onSaved(e) {
    if (e) {
      this.addedItem = e;
    }
  }
}
