import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validateIsNot][formControlName],[validateIsNot][formControl],[validateIsNot][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidIsNotDirective), multi: true }]
})
export class ValidIsNotDirective implements Validator {
    constructor(
        @Attribute('validateIsNot') public validateIsNot: string,
        @Attribute('reverse') public reverse: string
    ) {}

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true : false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        let v = c.value;

        // control vlaue
        let e = c.root.get(this.validateIsNot);

        // value not equal
        if (v && e && v === e.value && !this.isReverse) {
            return { validateIsNot: true };
        }

        // value equal and reverse
        if (e && v !== e.value && this.isReverse && e.errors) {
            delete e.errors['validateIsNot'];
            if (!Object.keys(e.errors).length) e.setErrors(null);
        }

        // value not equal and reverse
        if (v && e && v === e.value && this.isReverse) {
            e.setErrors({ validateIsNot: true });
        }

        return null;
    }
}
