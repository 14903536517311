import { Injectable } from '@angular/core';

@Injectable()
export class MiniModalService {
    creditline: any = null;
    modalReference: any = null;

    constructor() {}

    checkCreditline(creditline) {
        if (this.modalReference && this.creditline) {
            let compare = this.compareCreditlines(creditline);

            if (!compare) {
                this.modalReference.close();

                this.creditline = null;
                this.modalReference = null;
            }
        }
    }

    setReference(creditline, modalReference) {
        this.creditline = creditline.id;
        this.modalReference = modalReference;
    }

    compareCreditlines(creditline) {
        let compare = creditline.id === this.creditline;

        return compare;
    }
}
