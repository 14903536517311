<a (click)="openModal(tracking)"
    ><img src="assets/img/backoffice/x.svg" width="40" height="40"
/></a>
<ng-template #tracking let-c="close" let-d="dismiss">
    <div cdkDrag cdkDragRootElement=".wide-modal">
        <div class="modal-header" cdkDragHandle>
            <h4 class="modal-title">Tracking</h4>
            <span
                *ngIf="!isMinimized"
                class="minimize"
                (click)="minimizeModal()"
                title="Minimize"
                >-</span
            >
            <span
                *ngIf="isMinimized"
                class="minimize"
                (click)="maximizeModal()"
                title="Maximize"
                ><i class="fa fa-clone"></i
            ></span>

            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="c()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-tracking-content
                [creditline]="creditline"
            ></app-tracking-content>
        </div>
    </div>
</ng-template>
