<div class="d-flex flex-nowrap justify-content-between">
  <div style="width: 350px; min-width: 350px;">
    <div class="d-flex justify-content-between mb-3">
      <h3 class="mb-0">Payment Trees</h3>
      <app-payment-tree-create
        (created)="onCreated($event)"
      ></app-payment-tree-create>
    </div>
  </div>
  <div>
    <a
      [routerLink]="['/backoffice/debtrecovery/queue-manager/create-queue']"
      class="btn btn-sm btn-outline-primary"
      >Go to create queue</a
    >
  </div>
</div>

<div class="d-flex flex-nowrap">
  <div style="width: 350px; min-width: 350px;">
    <app-payment-tree-list
      [active]="active"
      [created]="createdTree"
    ></app-payment-tree-list>
  </div>
  <div class="w-100 pl-3 border-left border-primary">
    <router-outlet></router-outlet>
  </div>
</div>
