<div class="row justify-content-between">
    <div class="col-12">
        <h2 class="d-inline mr-3">Due Debt</h2>
        <a [href]="exportLink" (click)="getExportLink($event)">Export</a>
    </div>
    <div class="col-12">
        <div class="form-row mb-3">
            <div class="col-12 col-xl mb-3 align-self-end">
                <app-daterange-filter
                    fieldName="time_at"
                    (changeDate)="onChangeDateTotal($event)"
                ></app-daterange-filter>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-primary mb-0">
                            Total Initial
                            <br />
                            Debt Left
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-primary mb-0">
                            {{ total[0]?.debt_due | currency: 'EUR' }}
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-primary mb-0">
                            Total Debt
                            <br />
                            Left
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-danger mb-0">{{ total[0]?.debt_left | currency: 'EUR' }}</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-primary mb-0">
                            Total
                            <br />
                            Recovered
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-success mb-0">{{ total[0]?.debt_recovered | currency: 'EUR' }}</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-primary mb-0">
                            {{ total[0]?.debt_recovered / total[0]?.debt_due | percent: '1.0-2' }}
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-xl mb-3">
                <div class="card rounded-0 h-100">
                    <div class="card-header bg-white border-bottom-0">
                        <h5 class="card-title text-primary mb-0">
                            <small class="text-body">Loans in Due</small>
                            <br />
                            {{ total[0]?.loan_count | number }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-pager
    [perPage]="tableData?.per_page"
    [currentPage]="tableData?.current_page"
    [from]="tableData?.from"
    [to]="tableData?.to"
    [total]="tableData?.total"
    (changePage)="getDataOnPageChange($event)"
></app-pager>
<div class="table-responsive">
    <table class="table table-hover">
        <!-- row -->
        <thead>
            <tr>
                <!-- column -->
                <th>Loan</th>
                <th>Activated</th>
                <th>Due Date</th>
                <th>Amount</th>
                <th>Total Debt</th>
                <th>Initial Debt</th>
                <th>Discount</th>
                <th>Debt Left</th>
                <th>Recovered</th>
                <th>Debt Lost</th>
                <th>DNI/NIE</th>
                <th>Cusomer Name</th>
                <th>Status</th>
                <th>Asnef Status</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <input
                        size="20"
                        type="text"
                        (keyup.enter)="getDataOnFilterSubmit()"
                        placeholder="Loan Code"
                        name="loan_code_str"
                        [(ngModel)]="filters.loan_code_str"
                    />
                </td>
                <td>
                    <app-daterange-filter
                        fieldName="activation_date_at"
                        [start]="filters.activation_date_at_start"
                        [end]="filters.activation_date_at_end"
                        (changeDate)="onChangeDate($event)"
                    ></app-daterange-filter>
                </td>
                <td>
                    <app-daterange-filter
                        fieldName="due_status_date_at"
                        [start]="filters.due_status_date_at_start"
                        [end]="filters.due_status_date_at_end"
                        (changeDate)="onChangeDate($event)"
                    ></app-daterange-filter>
                </td>
                <td>
                    <app-dropdown-range
                        [start]="filters.amount_at_start"
                        [end]="filters.amount_at_end"
                        [placeholder]="'Amount'"
                        [name]="'amount_at'"
                        (changeRange)="onChangeRange($event)"
                    ></app-dropdown-range>
                </td>
                <td>
                    <app-dropdown-range
                        [start]="filters.total_debt_at_start"
                        [end]="filters.total_debt_at_end"
                        [placeholder]="'Total Debt'"
                        [name]="'total_debt_at'"
                        (changeRange)="onChangeRange($event)"
                    ></app-dropdown-range>
                </td>
                <td>
                    <app-dropdown-range
                        [start]="filters.initial_debt_at_start"
                        [end]="filters.initial_debt_at_end"
                        [placeholder]="'Initial Debt'"
                        [name]="'initial_debt_at'"
                        (changeRange)="onChangeRange($event)"
                    ></app-dropdown-range>
                </td>
                <td></td>
                <td>
                    <app-dropdown-range
                        [start]="filters.debt_left_at_start"
                        [end]="filters.debt_left_at_end"
                        [placeholder]="'Debt Left'"
                        [name]="'debt_left_at'"
                        (changeRange)="onChangeRange($event)"
                    ></app-dropdown-range>
                </td>
                <td></td>
                <td></td>
                <td>
                    <input
                        type="text"
                        (keyup.enter)="getDataOnFilterSubmit()"
                        placeholder="DNI"
                        name="personalid"
                        [(ngModel)]="filters.personalid"
                    />
                </td>
                <td>
                    <input
                        type="text"
                        (keyup.enter)="getDataOnFilterSubmit()"
                        placeholder="Customer"
                        name="full_name"
                        [(ngModel)]="filters.full_name"
                    />
                </td>
                <td>
                    <select (change)="getDataOnFilterSubmit()" name="status_id" [(ngModel)]="filters.status_id">
                        <option value="">Select Status</option>
                        <option *ngFor="let item of selects?.statuses" value="{{ item.id }}">{{ item.name }}</option>
                    </select>
                </td>
                <td>
                    <select
                        (change)="getDataOnFilterSubmit()"
                        name="asnef_status_id"
                        [(ngModel)]="filters.asnef_status_id"
                    >
                        <option value="">Select Status</option>
                        <option *ngFor="let item of selects?.asnef_statuses | keys" value="{{ item.id }}">{{
                            item.name
                        }}</option>
                    </select>
                </td>
            </tr>
            <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                <tr *ngFor="let item of tableData?.data">
                    <td>
                        <a class="miLineaFontColor" (click)="goto(item?.lead_id)">{{ item?.code_str }}</a>
                    </td>
                    <td>{{ item?.activation_date }}</td>
                    <td>{{ formatDate(item?.due_status_date) | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ item?.amount | currency: 'EUR' }}</td>
                    <td>{{ item?.total_to_pay | currency: 'EUR' }}</td>
                    <td>{{ item?.due?.debt_left | currency: 'EUR' }}</td>
                    <td>{{ item?.discount_amount | currency: 'EUR' }}</td>
                    <td>{{ item?.debt_left_time | currency: 'EUR' }}</td>
                    <td>{{ item?.due?.recovered | currency: 'EUR' }}</td>
                    <td>{{ item?.debt_lost | currency: 'EUR' }}</td>
                    <td>{{ item?.user?.personalid }}</td>
                    <td>{{ item?.user?.full_name }}</td>
                    <td>{{ item?.status?.name }}</td>
                    <td>{{ item?.user?.asnef_status?.name }}</td>
                </tr>
            </ng-template>

            <ng-template [ngIf]="!tableData?.data.length && !loading">
                <tr class="without_hover">
                    <td colspan="14">
                        <p class="py-5 mb-0">
                            Nothing Found
                        </p>
                    </td>
                </tr>
            </ng-template>
            <tr *ngIf="loading" class="without_hover">
                <td colspan="14">
                    <p class="py-5 mb-0">
                        <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-pager
    [perPage]="tableData?.per_page"
    [currentPage]="tableData?.current_page"
    [from]="tableData?.from"
    [to]="tableData?.to"
    [total]="tableData?.total"
    (changePage)="getDataOnPageChange($event)"
>
</app-pager>
