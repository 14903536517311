
  <h3>Create Permission</h3>

<form [formGroup]="permissionForm" style="width: 22rem;" (ngSubmit)="onFormSubmit()" >
    
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" formControlName="name" id="name" aria-describedby="name" placeholder="Enter name">
        <!--<small id="name" class="form-text text-muted">Enter the role name</small>!-->
      </div>
      <div class="form-group">
        <label for="display_name">Display Name</label>
        <input type="text" class="form-control" formControlName="display_name" id="display_name" aria-describedby="display_name" placeholder="Enter display name">
        <!--<small id="display_name" class="form-text text-muted">Enter the display name</small>!-->
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <input type="text" class="form-control" formControlName="description" id="description" aria-describedby="description" placeholder="Enter description">
        <!--<small id="description" class="form-text text-muted">Enter the description</small>!-->
      </div>
    
      <div class="text-center py-4 mt-3">
        <button class="btn btn-cyan waves-light" type="submit" [disabled]="permissionForm.invalid" mdbWavesEffect>Create</button>
      </div>
    
    </form>