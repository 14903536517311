import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lead-info-other',
  templateUrl: './lead-info-other.component.html',
  styleUrls: ['./lead-info-other.component.css']
})
export class LeadInfoOtherComponent implements OnInit {

  @Input() lead: any = null;
  @Input() credit_line: any = null;
  @Input() loading = true;

  constructor() { }

  ngOnInit() {
  }

}
