import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DebtRecoveryService {
    constructor(private http: HttpClient) {}

    getDueList(status, params): Observable<any> {
        return this.http.get(
            environment.api_url + `/debt_recovery_due/${status}/list?${params}`
        );
    }

    getIncidentList(status, params): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/debt_recovery_collection_incidence/${status}/list?${params}`
        );
    }

    getFilters(filterType): Observable<any> {
        return this.http.get(environment.api_url + filterType.link);
    }

    assignTo(model): Observable<any> {
        return this.http.post(
            environment.api_url +
                `/debt_recovery_due_assignation/${model.action}`,
            model
        );
    }

    getIncidenceExport(filterUrlParams): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(
            environment.api_url +
                `/debt_recovery_collection_incidence/all/export?${filterUrlParams}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );
    }

    getDueExport(filterUrlParams, pageName): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(
            environment.api_url +
                `/debt_recovery_due/${pageName}/export?${filterUrlParams}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );
    }

    getQueueList(params): Observable<any> {
        return this.http.get(environment.api_url + `/task/list?${params}`);
    }

    getHistoryList(params): Observable<any> {
        return this.http.get(
            environment.api_url + `/task/executed/list?${params}`
        );
    }

    createQueue(params): Observable<any> {
        return this.http.post(environment.api_url + `/task/create`, params);
    }

    updateQueue(id, params): Observable<any> {
        return this.http.post(
            environment.api_url + `/task/${id}/update`,
            params
        );
    }

    showTask(id): Observable<any> {
        return this.http.get(environment.api_url + `/task/${id}/show`);
    }

    getPaymentProviders(): Observable<any> {
        return this.http.get(environment.api_url + '/payment/provider/list');
    }

    getPaymentSources(): Observable<any> {
        return this.http.get(environment.api_url + '/payment/source/list');
    }

    getLoanStatuses(): Observable<any> {
        return this.http.get(environment.api_url + `/loan/status/list`);
    }

    getCardStatuses(): Observable<any> {
        return this.http.get(environment.api_url + '/bank_card/status/list');
    }

    getBankList() {
        return this.http.get(environment.api_url + '/bank/list');
    }

    getLeadInfo(): Observable<any> {
        return this.http.get(environment.api_url + '/lead/info/request');
    }

    getPaymentTreeAmount(): Observable<any> {
        return this.http.get(environment.api_url + '/payment_tree_amount/list');
    }

    createPaymentTreeAmount(params): Observable<any> {
        return this.http.post(
            environment.api_url + '/payment_tree_amount/create',
            params
        );
    }

    updatePaymentTreeAmount(id, params): Observable<any> {
        return this.http.post(
            environment.api_url + `/payment_tree_amount/${id}/update`,
            params
        );
    }

    deletePaymentTreeAmount(id): Observable<any> {
        return this.http.post(
            environment.api_url + `/payment_tree_amount/${id}/delete`,
            {}
        );
    }

    getPaymentTreeAmountNodes(id): Observable<any> {
        return this.http.get(
            environment.api_url + `/payment_tree_amount/${id}/node/list`
        );
    }

    createPaymentTreeAmountNode(id, params): Observable<any> {
        return this.http.post(
            environment.api_url + `/payment_tree_amount/${id}/node/create`,
            params
        );
    }

    updatePaymentTreeAmountNode(treeId, nodeId, params): Observable<any> {
        return this.http.post(
            environment.api_url +
                `/payment_tree_amount/${treeId}/node/${nodeId}/update`,
            params
        );
    }

    deletePaymentTreeAmountNode(treeId, nodeId): Observable<any> {
        return this.http.post(
            environment.api_url +
                `/payment_tree_amount/${treeId}/node/${nodeId}/delete`,
            {}
        );
    }
}
