<div *appHasPermissions="['marketing']">
    <div class="row justify-content-between mb-3">
        <div class="col-12">
            <h2>Affiliates Summary</h2>
        </div>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="affiliates?.current_page"
        [from]="affiliates?.from"
        [to]="affiliates?.to"
        [total]="affiliates?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="row pb-1">
        <label class="col-auto col-form-label pb-2"
            ><strong class="pl-1"><span class="pl-2">Date</span></strong></label
        >
        <div class="col">
            <app-daterange-filter
                fieldName="created_at"
                [start]="filters.created_at_start"
                [end]="filters.created_at_end"
                (changeDate)="onChangeDate($event)"
            >
            </app-daterange-filter>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Affiliate</th>
                    <th>Source</th>
                    <th>Leads Received</th>
                    <th>Leads Completed</th>
                    <th>ID Duplicated</th>
                    <th>Net Leads Completed</th>
                    <th>%</th>
                    <th>€</th>
                    <th>Logs</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tr-no-hover">
                    <td>
                        <input
                            type="text"
                            placeholder="Affiliate"
                            name="affiliate_name"
                            [(ngModel)]="filters.affiliate_name"
                            (keyup.enter)="getDataOnFilterSubmit()"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            placeholder="Source"
                            name="source_name"
                            [(ngModel)]="filters.source_name"
                            (keyup.enter)="getDataOnFilterSubmit()"
                        />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-template [ngIf]="affiliates?.data?.length > 0 && !loading">
                    <tr *ngFor="let affiliate of affiliates?.data">
                        <td>
                            <a
                                [routerLink]="[
                                    '/backoffice/marketing/affiliate_info/' +
                                        affiliate.id
                                ]"
                                >{{ affiliate?.affiliate_name }}</a
                            >
                        </td>
                        <td>
                            {{ affiliate?.source_name }}
                        </td>
                        <td>{{ affiliate?.received }}</td>
                        <td>{{ affiliate?.completed }}</td>
                        <td>{{ affiliate?.completed_duplicated_count }}</td>
                        <td>{{ affiliate?.net_completed }}</td>
                        <td>{{ affiliate?.percent | percent }}</td>
                        <td>{{ affiliate?.to_pay | currency: 'EUR' }}</td>
                        <td>
                            <app-affiliate-log
                                [start]="filters.created_at_start"
                                [end]="filters.created_at_end"
                                [id]="affiliate?.id"
                            ></app-affiliate-log>
                        </td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!affiliates?.data?.length && !loading">
                    <tr class="tr-no-hover">
                        <td colspan="9">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr class="tr-no-hover" *ngIf="loading">
                    <td colspan="9">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>

                <tr class="text-primary tr-no-hover">
                    <td class="td-top-border" colspan="2"></td>
                    <td class="td-top-border">
                        <strong>{{ totals.received }}</strong>
                    </td>
                    <td class="td-top-border">
                        <strong>{{ totals.completed }}</strong>
                    </td>
                    <td class="td-top-border">
                        <strong>{{ totals.completed_duplicated_count }}</strong>
                    </td>
                    <td class="td-top-border">
                        <strong>{{ totals.net_completed }}</strong>
                    </td>
                    <td class="td-top-border">
                        <strong>{{ totals.percent | percent }}</strong>
                    </td>
                    <td class="td-top-border">
                        <strong>{{ totals.to_pay | currency: 'EUR' }}</strong>
                    </td>
                    <td class="td-top-border"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="affiliates?.current_page"
        [from]="affiliates?.from"
        [to]="affiliates?.to"
        [total]="affiliates?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
