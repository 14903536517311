<div
    *appHasPermissions="['kpis-profitability-evolution']"
    class="container pt-3"
>
    <div class="row justify-content-between mb-3">
        <div class="col-auto">
            <app-daterange-filter
                fieldName="portfolio_date"
                [ranges]="ranges"
                [start]="filters.portfolio_date_start"
                [end]="filters.portfolio_date_end"
                [resetDates]="{ startDate: minDate, endDate: maxDate }"
                (changeDate)="onChangeDate($event)"
                [disabled]="loading"
            ></app-daterange-filter>
            <small *ngIf="loading" class="ml-2 text-primary">Loading...</small>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-6">
            <div class="card">
                <div class="card-body px-3">
                    <div class="row">
                        <div
                            class="col-6 text-center border-right d-flex align-items-center justify-content-center"
                        >
                            <div class="py-2">
                                <h5 class="mb-0">% MOROSIDAD</h5>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <div class="py-2">
                                <h3 class="text-primary mb-0">
                                    {{ data?.tm | number: '1.0-2' }}%
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-body px-3">
                    <div class="row">
                        <div
                            class="col-6 text-center border-right d-flex align-items-center justify-content-center"
                        >
                            <div class="py-2">
                                <h5 class="mb-0">PMC</h5>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <div class="py-2">
                                <h3 class="text-primary mb-0">- días</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12"></div>

        <div class="col-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Evolucíon morosidad
                    </h5>
                    <div class="chart">
                        <canvas #revenueChart></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="text-primary text-center text-uppercase mb-3">
                        Evolucíon PMC
                    </h5>
                </div>
            </div>
        </div>
    </div>
</div>
