<div class="row justify-content-between mb-3">
    <div class="col-12">
        <h2 *ngIf="searchString" class="mb-0">
            Search Results for:
            <small>{{ searchString }}</small>
        </h2>
        <p class="text-center pt-4" *ngIf="!searchString">
            Search page. Try to find something.
        </p>
    </div>
</div>
<div *ngIf="searchString">
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [lastPage]="tableData?.last_page"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>

    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Loan</th>
                    <th>Lead</th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                    <th>Email</th>
                    <th>Mobile Phone</th>
                    <th>Other Phone</th>
                    <th>Work Phone</th>
                    <th>Work Phone 2</th>
                    <th>Additional Phone</th>
                    <th>Additional Phone 2</th>
                    <th>Address</th>
                    <th>Source</th>
                    <th>Medium</th>
                    <th>Campaign</th>
                </tr>
            </thead>
            <tbody>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>
                            <a
                                [routerLink]="[
                                    '/backoffice/loans/overview/' + item.lead_id + '/loanInfo/' + item.lead_id
                                ]"
                                innerHTML="{{ item?.loan_code_str | highlight: searchString }}"
                            >
                            </a>
                        </td>
                        <td>
                            <a
                                [routerLink]="[
                                    '/backoffice/underwriting/overview/' + item.lead_id + '/leadInfo/' + item.lead_id
                                ]"
                                innerHTML="{{ item?.lead_code_str | highlight: searchString }}"
                            >
                            </a>
                        </td>
                        <td innerHTML="{{ item?.personalid | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.full_name | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.email | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.mobile_phone | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.other_phone | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.work_phone_one | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.work_phone_two | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.additional_phone_one | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.additional_phone_two | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.address | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.source | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.medium | highlight: searchString }}"></td>
                        <td innerHTML="{{ item?.campaign | highlight: searchString }}"></td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">
                                Nothing Found
                            </p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [lastPage]="tableData?.last_page"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>
</div>
