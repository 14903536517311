import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-backoffice-home',
    templateUrl: './backoffice-home.component.html',
    styleUrls: ['./backoffice-home.component.css']
})
export class BackofficeHomeComponent implements OnInit {
    selected: string;

    kpisAdmin = false;
    kpisBum = false;
    kpisAm = false;
    kpisDc = false;
    underwriting = false;
    creditLines = false;
    debtRecovery = false;
    legal = false;
    datamanagement = false;
    marketing = false;
    finance = false;
    settings = false;

    constructor(private router: Router, private authService: AuthService) {}

    ngOnInit() {
        this.confPermissions();
    }
    /*
  route(route){
    this.selected = route;
    this.router.navigate([route]);
  }
*/
    confPermissions() {
        // this.authService.check(' ').subscribe( resp => { if(resp['accepted']===true){ this.kpisAdmin = true;} });
        // this.authService.check('kpisbum').subscribe( resp => { if(resp['accepted']===true){ this.kpisBum = true;} });
        // this.authService.check('kpisam').subscribe( resp => { if(resp['accepted']===true){ this.kpisAm = true;} });
        // this.authService.check('kpisdc').subscribe( resp => { if(resp['accepted']===true){ this.kpisDc = true;} });
        // this.authService.check('underwriting').subscribe( resp => { if(resp['accepted']===true){ this.underwriting = true;} });
        // this.authService.check('loans').subscribe( resp => { if(resp['accepted']===true){ this.creditLines = true;} });
        // this.authService.check('debtrecovery').subscribe( resp => { if(resp['accepted']===true){ this.debtRecovery = true;} });
        // this.authService.check('legal').subscribe( resp => { if(resp['accepted']){ this.legal = true;} });
        // this.authService.check('datamanagement').subscribe( resp => { if(resp['accepted']===true){ this.datamanagement = true;} });
        // this.authService.check('marketing').subscribe( resp => { if(resp['accepted']===true){ this.marketing = true;} });
        // this.authService.check('finance').subscribe( resp => { if(resp['accepted']===true){ this.finance = true;} });
        // this.authService.check('settings').subscribe( resp => { if(resp['accepted']===true){ this.settings = true;} });
        this.kpisAdmin = true;
        this.kpisBum = true;
        this.kpisAm = true;
        this.kpisDc = true;
        this.underwriting = true;
        this.creditLines = true;
        this.debtRecovery = true;
        this.legal = true;
        this.datamanagement = true;
        this.marketing = true;
        this.finance = true;
        this.settings = true;
    }
    select(e) {
        let target = event.target || event.srcElement || event.currentTarget;
        if (document.getElementsByClassName('tablinks-selected').length > 0) {
            document.getElementsByClassName('tablinks-selected')[0].className = 'tablinks';
        }
        (<HTMLInputElement>target).className = 'tablinks-selected';
    }
}
