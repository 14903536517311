import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import BenkiSimpeImage from '../email-editor-blocks/benki-simple-image';

import { Delimiter } from '../email-editor-blocks/delimiter';

@Component({
    selector: 'app-email-editor',
    templateUrl: './email-editor.component.html',
    styleUrls: ['./email-editor.component.scss'],
})
export class EmailEditorComponent implements OnInit, OnChanges {
    @Input() startEditorData: OutputData;
    @Input() templateVariables;
    @Input() isReadonly = false;
    @Input() isHTML = true;
    @Output() onChange = new EventEmitter<OutputData>();
    editor: EditorJS;

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges({ isReadonly, isHTML }: SimpleChanges) {
        const currentIsReadonly: SimpleChange = isReadonly;
        const currentIsHTML: SimpleChange = isHTML;

        if (currentIsReadonly?.currentValue) {
            this.editor?.readOnly.toggle();
        }

        if (currentIsHTML?.currentValue) {
            this.initEditor();
        } else {
            this?.editor?.destroy();
            this.editor = null;
        }
    }

    initEditor() {
        this.editor = new EditorJS({
            readOnly: this.isReadonly,
            holder: 'editorjs',
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: ['link', 'italic'],
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                image: {
                    class: BenkiSimpeImage,
                    config: {
                        varibles: this.templateVariables,
                    },
                },
                delimiter: {
                    class: Delimiter,
                },
            },
            onChange: async () => {
                const data = await this.editor.save();
                this.onChange.emit(data);
            },
            data: this.startEditorData,
        });
    }
}
