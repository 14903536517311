import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-lead-info-discard-validation',
    templateUrl: './lead-info-discard-validation.component.html',
    styleUrls: ['./lead-info-discard-validation.component.css']
})
export class LeadInfoDiscardValidationComponent implements OnInit, OnChanges {
    @Input() validation_discard_antifraud: any = null;
    @Input() loading = true;

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {}
}
