import { Component, OnInit } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl
} from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Component({
    selector: 'app-setting-create-user',
    templateUrl: './setting-create-user.component.html',
    styleUrls: ['./setting-create-user.component.scss']
})
export class SettingCreateUserComponent implements OnInit {
    userForm: FormGroup;
    fieldForm: any = null;
    rolePermissionForm: FormGroup;
    user_id: number = null;
    folders: any = null;
    content: any = null;
    roles = null;
    userRoles = null;
    loading = true;

    userName = '';

    dropdownList = [];

    dropdownSettings = {};

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private errorMessage: ErrorMessageService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.userForm = this.fb.group({
            role_id: ['', Validators.required],
            name: ['', Validators.required],
            first_surname: ['', Validators.required],
            second_surname: [''],
            blocked: [0]
        });
    }
    ngOnInit() {
        this.dropdownSettings = {
            singleSelection: false,
            allowSearchFilter: false,
            idField: 'id',
            textField: 'text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5
        };

        this.getRoles().then(() => {
            this.activatedRoute.params.subscribe(params => {
                if (params['id'] != null) {
                    this.user_id = +params['id'];
                    this.authService
                        .getUser(this.user_id)
                        .toPromise()
                        .then(user => {
                            if (Object.values(user).length) {
                                const selectRoles = user.roles.map(role => {
                                    return {
                                        id: role.id,
                                        text: role.display_name
                                    };
                                });
                                this.userName = user.username;
                                this.userForm.addControl(
                                    'password',
                                    new FormControl()
                                );
                                this.userForm.controls.name.setValue(user.name);
                                this.userForm.controls.first_surname.setValue(
                                    user.first_surname
                                );
                                this.userForm.controls.second_surname.setValue(
                                    user.second_surname
                                );
                                this.userForm.controls.blocked.setValue(
                                    user.blocked
                                );
                                this.userForm.controls.role_id.setValue(
                                    selectRoles
                                );
                            }
                            this.loading = false;
                        });
                } else {
                    this.userForm.addControl(
                        'username',
                        new FormControl('', Validators.required)
                    );
                    this.userForm.addControl(
                        'password',
                        new FormControl('', Validators.required)
                    );
                    this.userForm.addControl(
                        'confirm_password',
                        new FormControl('', Validators.required)
                    );
                    this.loading = false;
                }
            });
        });
    }
    getRolePermissions(role_id) {
        this.authService.getRolePermissions(role_id);
    }
    getUserRoles(userId) {
        this.authService.getUserRoles(userId).subscribe(resp => {
            this.userRoles = resp;
        });
    }
    onFormSubmit() {
        if (this.user_id) {
            if (this.userForm.valid) {
                this.authService
                    .editUser(this.user_id, this.userForm.value)
                    .subscribe(
                        resp => {
                            if (resp === true) {
                                this.errorMessage.showSuccess(
                                    `El usuario ${this.userName} ha sido editado`
                                );
                                this.router.navigate([
                                    '/backoffice/settings/users_overview/user_list'
                                ]);
                            }
                        },
                        error => {
                            this.errorMessage.showError(
                                error.error.error.show_message
                            );
                        }
                    );
            }
        } else {
            if (this.userForm.valid) {
                this.authService.createUser(this.userForm.value).subscribe(
                    resp => {
                        if (resp && resp.username) {
                            this.errorMessage.showSuccess(
                                `El usuario ${resp.username} ha sido creado`
                            );
                            this.userForm.reset();
                        }
                    },
                    error => {
                        this.errorMessage.showError(
                            error.error.error.show_message
                        );
                    }
                );
            }
        }
    }

    async getRoles() {
        await this.authService
            .getAllRoles()
            .toPromise()
            .then(roles => {
                if (roles) {
                    this.roles = roles;

                    this.dropdownList = this.roles.map(item => {
                        return { id: item.id, text: item.display_name };
                    });
                }
            });
    }
}
