import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ErrorMessageService } from '../../shared/error-message.service';

@Injectable()
export class ContactTrackingService {

  trackings: any = null;
  tracking_type: any = null;
  constructor(private http: HttpClient, 
              private errorMessageService: ErrorMessageService) { }
  
  getAllByLeadId(lead_id): Observable<any>
  {
    return this.http.get(environment.api_url + `/lead/${lead_id}/contact_tracking/list`);
  }
  getAllPaginatedByLeadId(lead_id)
  {
    this.http.get(environment.api_url + `/lead/${lead_id}/contact_tracking/list`).subscribe(
      (resp: any) => {
        if(Object.values(resp.trackings.data).length){
          this.trackings = resp.trackings;
          
        }
        this.tracking_type = resp.tracking_type;
      }, 
      error => {
        if(error.error.error){
          this.errorMessageService.showError(error.error.error.show_message);
        }else{
          this.errorMessageService.showError(error.error.message);
        }
      }
    );
  }
  save(lead_id, value): Observable<any>
  {
    return this.http.post(environment.api_url + `/lead/${lead_id}/contact_tracking/create`, value );
  }
}
