import { Component, OnInit } from '@angular/core';
import { FinanceService } from '../../../services/finance.service';
import { Router } from '@angular/router';

declare var moment: any;

@Component({
    selector: 'app-finance-cash-commited',
    templateUrl: './finance-cash-commited.component.html',
    styleUrls: ['./finance-cash-commited.component.scss']
})
export class FinanceCashCommitedComponent implements OnInit {
    loading: boolean = true;
    urlParams: string = '';
    pageFilter: string = '';

    list: any = null;
    total: any = null;

    settings = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: true,
        autoUpdateInput: false,
        ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 5 Days': [moment().subtract(4, 'days'), moment()],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'Last Week': [
                moment()
                    .subtract(1, 'week')
                    .startOf('week'),
                moment()
                    .subtract(1, 'week')
                    .endOf('week')
            ],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [
                moment()
                    .subtract(1, 'month')
                    .startOf('month'),
                moment()
                    .subtract(1, 'month')
                    .endOf('month')
            ]
        },
        maxDate: moment().format('YYYY-MM-DD'),
        minDate: '2015-01-01',
        startDate: '2015-01-01',
        endDate: moment().format('YYYY-MM-DD')
    };

    dateInputVal: string = '';

    paramsFilter = new Map();

    constructor(private financeService: FinanceService, private router: Router) {}

    ngOnInit() {
        this.setInitPageFilter();
        this.getList();
    }

    getList(page = 0) {
        this.loading = true;
        this.setPageFilter(page);
        this.financeService.getCashCommitted(this.urlParams + this.pageFilter).subscribe(data => {
            this.list = data.cash_committed;
            this.total = data.totals;
            this.loading = false;
        });
    }

    setInitPageFilter() {
        this.urlParams = '';
    }

    setPageFilter(page) {
        this.pageFilter = '';
        if (page) {
            this.pageFilter = 'page=' + page;
        }
    }

    getParamsFilter(event: any) {
        this.paramsFilter.set(event.target.name, event.target.value);
    }
    makeFilter() {
        this.setInitPageFilter();
        this.paramsFilter.forEach(function(value, key) {
            if (value) {
                this.urlParams += key + '=' + value + '&';
            }
        }, this);
        this.getList();
    }

    selectedDate(value: any) {
        let start = {
            target: {
                name: 'created_at_start',
                value: value.start.format('YYYY-MM-DD')
            }
        };
        let end = {
            target: {
                name: 'created_at_end',
                value: value.end.format('YYYY-MM-DD')
            }
        };

        this.dateInputVal = start.target.value + ' - ' + end.target.value;

        this.getParamsFilter(start);
        this.getParamsFilter(end);
        this.makeFilter();
    }

    clearFilter(event) {
        if (!event.target.value) {
            let start = {
                target: {
                    name: 'created_at_start',
                    value: ''
                }
            };
            let end = {
                target: {
                    name: 'created_at_end',
                    value: ''
                }
            };

            this.dateInputVal = '';

            this.getParamsFilter(start);
            this.getParamsFilter(end);
            this.makeFilter();
        }
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([`/backoffice/loans/overview/${lead_id}/loanInfo/`, lead_id]);
        }
    }
}
