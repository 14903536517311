<div class="card">
    <div class="card-header" role="tab" id="headingCenterOne">
        <h5 class="mb-0">Customer info</h5>
    </div>
    <div
        id="collapseCenterOne"
        class="collapse show"
        role="tabpanel"
        aria-labelledby="headingCenterOne"
        data-parent="#accordion"
    >
        <div class="card-body">
            <img *ngIf="loading" width="50px" height="50px" src="assets/img/backoffice/loading.gif" />
            <ul class="list-group" *ngIf="!loading">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Name</strong
                    ><span>{{ user?.name }} {{ user?.first_surname }} {{ user?.second_surname }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>DNI/NIF</strong><span>{{ user?.personalid }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Mobile</strong><span [innerHTML]="formatPhone(lead?.mobile_phone)"></span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Additional Phone 1</strong
                    ><span [innerHTML]="formatPhone(lead?.additional_phone_one)"></span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Additional Phone 2</strong
                    ><span [innerHTML]="formatPhone(lead?.additional_phone_two)"></span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Work Phone</strong><span [innerHTML]="formatPhone(lead?.work_phone_one)"></span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Email</strong><span>{{ lead?.email }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Asnef Status</strong><span></span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Asnef Update </strong><span></span>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="card mt-3" *ngIf="paymentBankCard?.length">
    <div class="card-header" role="tab" id="headingCenterTwo">
        <h5 class="mb-0">Credit Card info</h5>
    </div>
    <div
        id="collapseCenterTwo"
        class="collapse show"
        role="tabpanel"
        aria-labelledby="headingCenterTwo"
        data-parent="#accordion"
    >
        <div class="card-body" style="max-height: 230px; overflow: auto;">
            <ul class="list-group" *ngFor="let card of paymentBankCard">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Number</strong><span>{{ card?.pan }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Expiration Month</strong><span>{{ card?.expiration_month }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Expiration Year</strong><span>{{ card?.expiration_year }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Card Type</strong><span>{{ card?.type.name }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <strong>Card Status</strong><span>{{ card?.status?.name }}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
