import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { CreditLineService } from '../../../services/credit-line.service';
import { CheckNavBarService } from '../../../services/check-nav-bar.service';

@Component({
    selector: 'app-lead-user-info',
    templateUrl: './lead-user-info.component.html',
    styleUrls: ['./lead-user-info.component.css'],
})
export class LeadUserInfoComponent implements OnInit {
    id: any = '';
    lead_status: any = null;
    lead_rejected_reason: any = null;
    lead = null;
    lead_request_info: any = null;
    contact_tracking: any = null;
    tracking_contact_type: any = null;
    editInfo: boolean = false;
    loan_id: any = null;

    bank_aggregation;

    constructor(
        private activate: ActivatedRoute,
        private leadService: LeadService,
        private creditLineService: CreditLineService,
        private checkNavBarService: CheckNavBarService,
        private router: Router
    ) {}

    ngOnInit() {
        this.editInfo = !this.router.url.includes('underwriting');

        this.activate.params.subscribe((params) => {
            this.id = params['id'];
            this.creditLineService
                .getByLeadId(this.id)
                .toPromise()
                .then((loan) => {
                    if (Object.values(loan).length) {
                        this.getContactTracking(loan.id);
                        this.loan_id = loan.id;
                    }
                });

            this.leadService
                .getBackAggregation(this.id)
                .toPromise()
                .then((answer) => {
                    this.bank_aggregation = answer;
                });

            this.leadService.get(this.id).then((lead: any) => {
                if (Object.values(lead).length) {
                    this.lead = lead;
                    this.activate.parent.url.subscribe((url) => {
                        if (url[0].path === 'loan-check') {
                            this.checkNavBarService.customerInfo();
                        }
                    });
                }
            });
        });
    }

    getContactTracking(loan_id) {
        this.creditLineService
            .contactTracking(loan_id)
            .toPromise()
            .then((contact_tracking) => {
                if (contact_tracking.data.length) {
                    this.contact_tracking = contact_tracking;
                }
            });
    }
}
