<!--

<div class="tab">
    <button class="tablinks tablinks-selected" [routerLink]="'/backoffice/settings/faq_overview/faq_list'">FAQ List</button>
    <button class="tablinks" [routerLink]="'/backoffice/settings/faq_overview/create_faq'">Create</button>
  </div>
  !-->

  <!-- horizontal menu -->
  <section id='submenu'>
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" (click)="goto('faq_list', $event)" id="pills-leadinfo-tab" data-toggle="pill" role="tab" aria-controls="pills-leadinfo" aria-selected="true">FAQ List</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="goto('create_faq', $event)" id="pills-userinfo-tab" data-toggle="pill"  role="tab" aria-controls="pills-userinfo" aria-selected="false">Create</a>
        </li>
    </ul>
</section>
<!-- horizontal menu end -->

  <router-outlet></router-outlet>