<button
    class="btn-sm btn-primary"
    [disabled]="disabled"
    (click)="open(content)"
>
    Generar Documento
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Generar Documento</h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form
            *ngIf="form"
            id="attachmentsForm"
            [formGroup]="form"
            autocomplete="off"
            (ngSubmit)="onSubmit(action)"
        >
            <div class="form-group">
                <label for="type_id">
                    Tipo de Documento
                    <small class="text-danger">*</small>
                </label>
                <div class="input-group">
                    <div *ngIf="isTypesLoading" class="input-group-prepend">
                        <div class="input-group-text">
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </div>
                    </div>
                    <select
                        id="type_id"
                        class="form-control"
                        name="type_id"
                        formControlName="type_id"
                        [ngClass]="{
                            'is-invalid':
                                form.controls.type_id.touched &&
                                !form.controls.type_id.valid,
                            'is-valid':
                                form.controls.type_id.touched &&
                                form.controls.type_id.valid
                        }"
                        required
                    >
                        <option value="" disabled>Seleccione</option>
                        <option *ngFor="let item of types" [value]="item.id">
                            {{ item.name }} - {{ item.description }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="name"
                    >Nombre <small class="text-danger">*</small></label
                >
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    formControlName="name"
                    [ngClass]="{
                        'is-invalid':
                            form.controls.name.touched &&
                            !form.controls.name.valid,
                        'is-valid':
                            form.controls.name.touched &&
                            form.controls.name.valid
                    }"
                    required
                />
            </div>
            <div class="form-group">
                <label for="description"
                    >Descripción <small class="text-danger">*</small></label
                >
                <input
                    type="text"
                    class="form-control"
                    id="description"
                    name="description"
                    formControlName="description"
                    [ngClass]="{
                        'is-invalid':
                            form.controls.description.touched &&
                            !form.controls.description.valid,
                        'is-valid':
                            form.controls.description.touched &&
                            form.controls.description.valid
                    }"
                    required
                />
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            Cerrar
        </button>
        <button
            *ngIf="form"
            type="submit"
            class="btn btn-primary"
            form="attachmentsForm"
            [disabled]="form.invalid || isSending"
        >
            {{ isSending ? 'Guardando...' : 'Generar' }}
        </button>
    </div>
</ng-template>
