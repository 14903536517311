import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-backoffice-header',
    templateUrl: './backoffice-header.component.html',
    styleUrls: ['./backoffice-header.component.css']
})
export class BackofficeHeaderComponent implements OnInit {
    select: boolean = false;
    name: any = null;
    constructor(public authService: AuthService, private router: Router) {}

    ngOnInit() {
        if (this.authService.user) {
            this.name = this.authService.user.name;
        } else {
            if (this.authService.getLocalUser()) {
                this.name = this.authService.user;
            }
        }
    }
    logout() {
        this.authService.logout();
    }

    search(form) {
        this.router.navigate([`/backoffice/search`], { queryParams: { text: form.value.search_query } });
        form.reset();
    }
}
