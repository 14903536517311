import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CreditLineService } from '../../../services/credit-line.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

import { AuthService } from '../../../services/auth.service';
import { SubscriptionLike as ISubscription, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { saveAs } from 'file-saver';

declare var moment: any;

@Component({
    selector: 'app-credit-line-table',
    templateUrl: './credit-line-table.component.html',
    styleUrls: ['./credit-line-table.component.css'],
})
export class CreditLineTableComponent implements OnInit, OnDestroy {
    pageNames: any = {
        all: 'ALL',
        new: 'NEW',
        pendingActivation: 'PENDING ACTIVATION',
        checking: 'CHECKING',
        active: 'ACTIVE LOANS',
        asnef: 'ASNEF INCLUDED',
        due: 'DUE LOANS',
        end: 'END LOANS',
        rejected: 'REJECTED LOANS',
        trial: 'TRIAL LOANS',
    };
    selectedPageName = '';

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    status: string;
    title: string = '';

    startData = {};

    filters = {};

    queryString: string = '';

    tableData;

    loading = true;

    loadingExport = false;
    exportFile = null;
    exportLink = null;

    calendarDates = {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };

    permissionsArray: Array<string> = [];

    users: any;
    reasons: any;
    credit_line_status_filter: any = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private creditLineService: CreditLineService,
        private errorMessageService: ErrorMessageService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        const params$ = this.activatedRoute.params;
        const queryParams$ = this.activatedRoute.queryParams;

        this.getSelectsLists();

        this.querySubscriber = combineLatest(params$, queryParams$)
            .pipe(debounceTime(300))
            .subscribe(([params, queryParams]) => {
                if (this.pageNames[params['status']]) {
                    this.selectedPageName = params['status'];
                    this.title = this.pageNames[params['status']];

                    const unCamelCaseStatus = this.selectedPageName
                        .replace(/([a-z])([A-Z])/g, '$1-$2')
                        .toLowerCase();

                    const usersWithRole =
                        this.selectedPageName === 'due'
                            ? ['dc']
                            : ['admin', 'am', 'amc', 'bum'];
                    this.authService
                        .getFilteredUsersList(usersWithRole)
                        .then((data) => (this.users = data));

                    if (this.selectedPageName === 'all') {
                        this.permissionsArray = [
                            'loan-all',
                            'debtrecovery-loan-all',
                            'legal-loan-all',
                            'finance-loan-all',
                            'marketing-loan-all',
                        ];
                    } else if (this.selectedPageName === 'trial') {
                        this.permissionsArray = [
                            'loan-trial',
                            'legal-loan-trial',
                        ];
                    } else {
                        this.permissionsArray = [`loan-${unCamelCaseStatus}`];
                    }

                    this.startData = {
                        code_str: '',
                        due_status_date_start: '',
                        due_status_date_end: '',
                        created_at_start: '',
                        created_at_end: '',
                        activation_date_start: '',
                        activation_date_end: '',
                        status_id: '',
                        personalid: '',
                        full_name: '',
                        reason_id: '',
                        product_weeks: '',
                        amount: '',
                        debt_left_start: '',
                        debt_left_end: '',
                        quota_refused_count: '',
                        who: '',
                        source: '',
                        medium: '',
                        campaign: '',
                        debt_collector_id: '',
                        rejected_date_start: '',
                        rejected_date_end: '',
                    };

                    if (this.dataSubscriber) {
                        this.dataSubscriber.unsubscribe();
                    }

                    // Set Init Filters
                    this.setInitFilters(queryParams);
                    // Create Query String
                    this.createQueryString(this.filters);

                    this.getTableData();
                }
            });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.creditLineService
            .getAll(this.queryString)
            .subscribe((response) => {
                if (response) {
                    this.tableData = response;
                    this.loading = false;
                }
            });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            (key) =>
                (!clearFilters[key] || clearFilters[key] === 'empty') &&
                delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map((key) => key + '=' + clearFilters[key])
            .join('&');

        switch (this.selectedPageName) {
            case 'new':
                this.queryString += '&status_id=1&';
                break;
            case 'pendingActivation':
                this.queryString += '&status_id=2&';
                break;
            case 'active':
                this.queryString += '&status_id=4&';
                break;
            case 'asnef':
                this.queryString += '&status_id=10&';
                break;
            case 'due':
                this.queryString += '&status_id=5&';
                break;
            case 'end':
                this.queryString += '&status_id=6&';
                break;
            case 'rejected':
                this.queryString += '&status_id=9&';
                break;
            case 'trial':
                this.queryString += '&status_id=10&';
                break;
            default:
                this.queryString = this.queryString;
        }
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(
            (key) => !filters[key] && delete filters[key]
        );

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl,
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeRange(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getSelectsLists() {
        this.creditLineService.getStatus().subscribe((data) => {
            this.credit_line_status_filter = data;
        });

        this.creditLineService.getReasons().subscribe((reasons) => {
            if (reasons) {
                this.reasons = reasons;
            }
        });
    }

    check(lead_id, credit_line_id) {
        this.creditLineService.canCheck(credit_line_id).subscribe((can) => {
            if (can) {
                this.setCreditLineChecking(credit_line_id);
                this.router.navigate([
                    `/backoffice/loans/loan-check/${lead_id}/userInfo/`,
                    lead_id,
                ]);
            } else {
                this.errorMessageService.showError(
                    'Otro operador ha iniciado el proceso activación'
                );
            }
        });
    }

    setCreditLineChecking(credit_line_id) {
        return this.creditLineService.setChecking(credit_line_id).subscribe();
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([
                `/backoffice/loans/overview/${lead_id}/loanInfo/`,
                lead_id,
            ]);
        }
    }

    async getExportLink(e) {
        e.preventDefault();
        this.loadingExport = true;
        try {
            const exel = await this.creditLineService.getLoansExport(
                this.queryString
            );

            const file = new Blob([exel], {
                type: 'application/vnd.ms-excel',
            });
            this.exportFile = URL.createObjectURL(file);
            this.exportLink = this.sanitizer.bypassSecurityTrustUrl(
                this.exportFile
            );

            saveAs(file, 'loans.xlsx');

            this.loadingExport = false;
        } catch (error) {
            console.error(error);
        }
    }
}
