import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-backoffice-creditlines',
    templateUrl: './backoffice-creditlines.component.html',
    styleUrls: ['./backoffice-creditlines.component.css']
})
export class BackofficeCreditlinesComponent implements OnInit, DoCheck {
    show: boolean;

    constructor(private router: Router) {}

    ngDoCheck() {
        if (window.location.href.match('.backoffice/loans$')) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    ngOnInit() {}

    showPanel() {
        return this.show;
    }

    goto(route) {
        this.show = false;
        this.router.navigate(['/backoffice/loans/loan', route]);
    }

    gotoChecking() {
        this.show = false;
        this.router.navigate(['/backoffice/loans/loan-checking']);
    }
}
