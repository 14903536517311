<div *appHasPermissions="['settings']">
    <!-- settings tab content end -->
    <settings *ngIf="show">
        <div class="container settings_section">
            <div class="row">
                <div class="col-sm-12 panel-main-action">
                    <div class="row">
                        <div class="col-lg-3 panel-main-separator">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div class="container-description">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/settings.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                System settings
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('users_overview')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/user.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                System users
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('product_overview')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/products.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Products
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('payment-tree')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/bank.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Payment Tree
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 panel-main-separator">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto('validation_overview')
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/validation.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Automatic validations
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div class="container-description">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/lead_parameters.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Lead recover parameters
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div class="container-description">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/scoring.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Scoring
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div class="container-description">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/asnef_checking.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Asnef checking
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 panel-main-separator">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('faq_overview')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/FAQ.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">FAQ</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div class="container-description">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/pictures.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Pictures
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div class="container-description">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/sms.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                SMS Templates
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="goto('mail-templates')"
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/settings/mail_templates.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text">
                                                Mail Templates
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </settings>
    <!-- settings tab content end -->

    <router-outlet></router-outlet>
</div>
