import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpClient,
    HttpResponse
} from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    request;
    constructor(private injector: Injector, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map(event => {
                if (event instanceof HttpResponse && event.status === 200 && event.headers.has('authorization')) {
                    const token = event.headers.get('authorization').split(' ')[1];
                    localStorage.setItem('token_admin', token);
                }
                return event;
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                const error = typeof errorResponse.error !== 'object' ? JSON.parse(errorResponse.error) : errorResponse;

                if (errorResponse.status === 401 && error.error.error.code === 'TOKEN_EXPIRED') {
                    this.router.navigate(['']);
                    return observableThrowError(errorResponse);
                }
                if (
                    (errorResponse.status === 400 || errorResponse.status === 401) &&
                    error.error.error === 'token_invalid' &&
                    errorResponse.url === environment.api_url + '/auth/refresh_admin'
                ) {
                    this.router.navigate(['']);
                    return observableThrowError(errorResponse);
                }
                if (
                    errorResponse.status === 400 &&
                    error.error.error === 'token_expired' &&
                    errorResponse.url === environment.api_url + '/auth/refresh_admin'
                ) {
                    this.router.navigate(['']);
                    return observableThrowError(errorResponse);
                }

                if (
                    ((errorResponse.status === 400 || errorResponse.status === 401) &&
                        error.error.error === 'token_expired') ||
                    ((errorResponse.status === 400 || errorResponse.status === 401) &&
                        error.error.size &&
                        error.error.type === 'application/json')
                ) {
                    const http = this.injector.get(HttpClient);

                    //this.refreshToken.request.push(request);
                    return http.post<any>(environment.api_url + '/auth/refresh_admin', {}).pipe(
                        mergeMap(data => {
                            console.log('actualizó el token');
                            localStorage.setItem('token_admin', data.token);
                            const cloneRequest = request.clone({
                                setHeaders: { Authorization: `Bearer ${data.token}` }
                            });
                            return next.handle(cloneRequest);
                        })
                    );
                }
                if (errorResponse.status === 400 && error.error.error === 'token_not_provided') {
                    this.router.navigate(['']);
                }

                //this.router.navigate(['']);

                return observableThrowError(errorResponse);
            })
        );
    }
}
