<div *appHasPermissions="['kpis-profitability']" class="container pt-3">
    <div class="row mb-3">
        <div class="col-auto ml-auto">
            <div class="card">
                <div class="card-body px-3">
                    <div class="row">
                        <div class="col text-center border-right">
                            <div class="py-2">
                                <h6 style="font-size: 14px" class="mb-0">
                                    PMC<br />(Periodo Medio De Cobro)
                                </h6>
                            </div>
                        </div>
                        <div class="col-auto text-center">
                            <div class="py-2">
                                <h3 class="text-primary mb-0">-</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="total" class="card mb-5">
        <div class="card-body px-3">
            <div class="row">
                <div class="col text-center border-right">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >Créditos Emitidos</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{
                                total?.amount | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </h5>
                    </div>
                </div>
                <div class="col text-center border-right">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >A Recuperar</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{
                                total?.debt_total
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </h5>
                    </div>
                </div>
                <div class="col text-center border-right">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >Recuperado</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{ total?.paid | currency: 'EUR':'symbol':'0.0-0'
                            }}<small class="ml-3"
                                >{{
                                    (total?.paid / total?.debt_total) * 100
                                        | number: '1.0-0'
                                }}%</small
                            >
                        </h5>
                    </div>
                </div>
                <div class="col text-center border-right">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >Margen</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{
                                total?.revenue
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}<small class="ml-3"
                                >{{
                                    total?.revenue_percent | number: '1.0-0'
                                }}%</small
                            >
                        </h5>
                    </div>
                </div>
                <div class="col text-center border-right">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >Deuda Pendiente</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{
                                total?.debt_left
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </h5>
                    </div>
                </div>
                <div class="col text-center border-right">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >% Pendiente</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{ total?.debt_left_percent | number: '1.0-0' }}%
                        </h5>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="py-2">
                        <h6 style="font-size: 14px">
                            <span class="border-bottom d-inline-block pb-1"
                                >% Morosidad</span
                            >
                        </h6>
                        <h5 class="text-primary mb-0">
                            {{ total?.tm | number: '1.0-0' }}%
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <select
        name="date"
        class="mb-3 mr-2"
        [(ngModel)]="filters.year"
        (change)="onChangeYear($event)"
        [disabled]="loading"
    >
        <option *ngFor="let year of years" value="{{ year }}">
            {{ year }}
        </option>
    </select>

    <input
        type="text"
        name="variable_date_end"
        placeholder="Choose end date"
        daterangepicker
        [options]="settings"
        [(ngModel)]="filters.variable_date_end"
        readonly
        [disabled]="loading"
        (applyDaterangepicker)="selectedDate($event)"
        (cancelDaterangepicker)="clearCalendar()"
    />

    <small *ngIf="loading" class="ml-2 text-primary">Loading...</small>

    <div class="card">
        <div class="table-responsive">
            <table class="table table-borderless mb-0">
                <thead class="text-uppercase text-primary">
                    <tr>
                        <td></td>
                        <td>Créditos Emitidos</td>
                        <td>A Recuperar</td>
                        <td>Recuperado</td>
                        <td class="pr-1">Margen</td>
                        <td class="pl-1">%</td>
                        <td class="pr-1">Deuda Pendiente</td>
                        <td class="pl-1">%</td>
                        <td>TM</td>
                        <td>PMC</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of portfolio">
                        <td class="text-primary border-right">
                            {{ item?.monthLabel }}
                        </td>
                        <td class="border-right">
                            {{
                                item?.amount | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="border-right">
                            {{
                                item?.debt_total
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="border-right">
                            {{ item?.paid | currency: 'EUR':'symbol':'0.0-0' }}
                        </td>
                        <td class="pr-1">
                            {{
                                item?.revenue | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="pl-1 border-right">
                            {{ item?.revenue_percent | number: '1.0-0' }}%
                        </td>
                        <td class="pr-1">
                            {{
                                item?.debt_left
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="pl-1 border-right">
                            {{ item?.debt_left_percent | number: '1.0-0' }}%
                        </td>
                        <td class="border-right">
                            {{ item?.tm | number: '1.0-0' }}%
                        </td>
                        <td>-</td>
                    </tr>
                    <tr class="text-primary font-weight-bold">
                        <td>Total</td>
                        <td>
                            {{
                                portfolioTotal?.amount
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td>
                            {{
                                portfolioTotal?.debt_total
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td>
                            {{
                                portfolioTotal?.paid
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="pr-1">
                            {{
                                portfolioTotal?.revenue
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="pl-1">
                            {{
                                portfolioTotal?.revenue_percent
                                    | number: '1.0-0'
                            }}%
                        </td>
                        <td class="pr-1">
                            {{
                                portfolioTotal?.debt_left
                                    | currency: 'EUR':'symbol':'0.0-0'
                            }}
                        </td>
                        <td class="pl-1">
                            {{
                                portfolioTotal?.debt_left_percent
                                    | number: '1.0-0'
                            }}%
                        </td>
                        <td>{{ portfolioTotal?.tm | number: '1.0-0' }}%</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
