import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class RefreshTokenService {

  public processing: boolean = false;
  public storage: Subject<any> = new Subject<any>();
  public semaforo = false;
  public request: Array<HttpRequest<any>> = [];
  public publish(value: any) {
    this.storage.next(value);
  }
  next(next)
  {
    next(next);
    let token = localStorage.getItem('token_admin');
    const cloneRequest = this.request.pop().clone({setHeaders: {'Authorization': `Bearer ${token}`}});
    return next.handle(cloneRequest);
  }

}
