import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { PaymentBankAccountService } from '../../../services/payment-bank-account.service';

@Component({
    selector: 'app-payment-rectification',
    templateUrl: './payment-rectification.component.html',
    styleUrls: ['./payment-rectification.component.scss'],
})
export class PaymentRectificationComponent implements OnInit {
    @Input() payment: any = null;

    modalReference: NgbModalRef;

    date = '';
    payment_id = null;
    type = '';
    amount = '';
    isSubmiting = false;

    types = {
        amount_error: 'Ammount Error',
        charge_back: 'Charge Back',
        in_excess: 'Payment in Excess',
    };

    singlePicker = {
        locale: { format: 'DD/MM/YYYY' },
        singleDatePicker: true,
        alwaysShowCalendars: true,
        autoUpdateInput: false,
        autoApply: true,
    };

    constructor(
        private modalService: NgbModal,
        private errorMessageService: ErrorMessageService,
        private paymentBankAccount: PaymentBankAccountService
    ) {}

    ngOnInit() {}

    open(content, payment) {
        this.amount = payment.amount;
        this.payment_id = payment.id;

        this.modalReference = this.modalService.open(content, { size: 'lg' });

        this.modalReference.result.then(
            (result) => {
                this.resetData();
            },
            (reason) => {
                this.resetData();
            }
        );
    }

    resetData() {
        this.date = '';
        this.payment_id = null;
        this.type = '';
    }

    selectedDateSingle(e: any) {
        const picker = e.picker || null;
        if (picker) {
            this.date = picker.startDate.format('DD/MM/YYYY');
        }
    }

    onSubmit() {
        const data = {
            type: this.type,
            payment_id: this.payment_id,
            date: this.date
                .toString()
                .replace(/\//g, '-')
                .split('-')
                .reverse()
                .join('-'),
        };

        this.isSubmiting = true;

        this.paymentBankAccount
            .rectificationPayment(data)
            .toPromise()
            .then((response) => {
                if (response) {
                    setTimeout(
                        function (self) {
                            self.errorMessageService.showSuccess(
                                'Payment Rectification succesfully!'
                            );
                            self.isSubmiting = false;
                            self.modalReference.close(true);
                        },
                        600,
                        this
                    );
                }
            })
            .catch((error) => {
                if (error.error.error) {
                    setTimeout(
                        function (self) {
                            self.isSubmiting = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                }
            });
    }
}
