import { Component, OnInit, AfterViewInit, QueryList, ViewChildren } from '@angular/core';

declare var moment: any;

@Component({
    selector: 'app-call-center-activity',
    templateUrl: './call-center-activity.component.html',
    styleUrls: ['./call-center-activity.component.scss']
})
export class CallCenterActivityComponent implements OnInit, AfterViewInit {
    @ViewChildren('datePicker') appdaterangepickers: QueryList<any>;
    urlParams: string = '';

    exportFile = null;
    exportLink = null;

    calendarDates = {
        startDate: moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    };

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.appdaterangepickers.forEach(child => this.setStartDate(child));
        //this.makeFilter();
    }

    setStartDate(datePicker) {
        const start = {
            target: {
                name: datePicker.fieldName + '_start',
                value: this.calendarDates.startDate
            }
        };
        const end = {
            target: {
                name: datePicker.fieldName + '_end',
                value: this.calendarDates.endDate
            }
        };

        //this.getParamsFilter(start);
        //this.getParamsFilter(end);
    }

    getExportLink(e) {
        e.preventDefault();

        // this.financeService.getCashInExport(this.urlParams).subscribe(exel => {
        //     let file = new Blob([exel], {
        //         type: 'application/vnd.ms-excel'
        //     });
        //     this.exportFile = URL.createObjectURL(file);
        //     this.exportLink = this.sanitizer.bypassSecurityTrustUrl(this.exportFile);

        //     saveAs(file, 'call_center_activity.xlsx');
        // });
    }
}
