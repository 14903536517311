<div *appHasPermissions="['finance-cashout']">
    <div class="row justify-content-between">
        <div class="col-sm-12 col-lg-2">
            <h2>Cash Out</h2>
            <a [href]="exportLink" (click)="getExportLink($event)">Export</a>
        </div>
        <div class="col-sm-6 col-sm-3 col-lg-2">
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="card-header-title text-center">TOTAL CAPITAL</h5>
                </div>
                <div class="card-body">
                    <p class="text-center">{{ total | currency: 'EUR' }}</p>
                </div>
            </div>
        </div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Cash Out Date</th>
                    <th>Loan</th>
                    <th>Order ID</th>
                    <th>Amount</th>
                    <th>IBAN</th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                    <th>Source</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <app-daterange-filter
                            fieldName="created_at"
                            [start]="filters.created_at_start"
                            [end]="filters.created_at_end"
                            (changeDate)="onChangeDate($event)"
                            [withStartDate]="true"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loan Code"
                            name="loan_code_str"
                            [(ngModel)]="filters.loan_code_str"
                        />
                    </td>
                    <td>
                        <input
                            size="20"
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Order ID"
                            name="provider_transaction_id"
                            [(ngModel)]="filters.provider_transaction_id"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount"
                            name="amount"
                            [(ngModel)]="filters.amount"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="IBAN"
                            name="iban"
                            [(ngModel)]="filters.iban"
                        />
                    </td>
                    <td>
                        <input
                            size="10"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="DNI / NIE"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Customer Name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Source"
                            name="source"
                            [(ngModel)]="filters.source"
                        />
                    </td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>{{ item?.created_at }}</td>
                        <td>
                            <a
                                class="miLineaFontColor"
                                (click)="goto(item?.lead_id)"
                                >{{ item?.code_str }}</a
                            >
                        </td>
                        <td>{{ item?.provider_transaction_id }}</td>
                        <td>{{ item?.amount | currency: 'EUR' }}</td>
                        <td>{{ item?.iban }}</td>
                        <td>{{ item?.personalid }}</td>
                        <td>{{ item?.full_name }}</td>
                        <td>{{ item?.source || '-' }}</td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    ></app-pager>
</div>
