<div *appHasPermissions="['debtrecovery']">
    <!-- debtrecovery tab content -->
    <debtrecovery *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 panel-main-action">
                    <div class="row">
                        <div
                            *appHasPermissions="['debtrecovery-loan-all']"
                            class="col-lg-2 col-6 panel-main-separator"
                        >
                            <div class="row">
                                <div class="col-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/loan/all'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Loans
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-6 panel-main-separator">
                            <div class="row">
                                <div
                                    *appHasPermissions="[
                                        'debtrecovery-collection'
                                    ]"
                                    class="col-6"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/collection'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/collection.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Collection
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="[
                                        'debtrecovery-collection-incidence'
                                    ]"
                                    class="col-6"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/collection_incidence/all'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/collection_incidence.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Collection Incidence
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="[
                                        'debtrecovery-incidence-kpis'
                                    ]"
                                    class="col-6"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto('/backoffice/debtrecovery')
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/collection_incidence.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Incidence <br />KPI<span
                                                    class="text-lowercase"
                                                    >s</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-6 panel-main-separator">
                            <div class="row">
                                <div
                                    *appHasPermissions="[
                                        'debtrecovery-loan-due'
                                    ]"
                                    class="col-6"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/due_view/new'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/due.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Due <br />Loans
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="[
                                        'debtrecovery-due-assignation'
                                    ]"
                                    class="col-6"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/due_assignation'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/da.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Due <br />assignation
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="[
                                        'debtrecovery-due-kpis'
                                    ]"
                                    class="offset-6 col-6"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto('/backoffice/debtrecovery')
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/due_kpis.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Due <br />KPI<span
                                                    class="text-lowercase"
                                                    >s</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6">
                            <div class="row">
                                <div
                                    *appHasPermissions="['queue-manager']"
                                    class="col-12"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/queue-manager'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/launcher_manager.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Queue <br />manager
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="['queue-history']"
                                    class="col-12"
                                >
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/debtrecovery/queue-history'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/debt_recovery/launcher_history.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Queue <br />history
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </debtrecovery>
    <!-- debtrecovery tab content end -->
    <router-outlet></router-outlet>
</div>
