import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-lead-info-form-value',
  templateUrl: './lead-info-form-value.component.html',
  styleUrls: ['./lead-info-form-value.component.css']
})
export class LeadInfoFormValueComponent implements OnInit, OnChanges {

  @Input() lead: any = null;
  @Input() user: any = null;
  @Input() sex: any = null;
  @Input() job_type: any = null;
  @Input() housing_type: any = null;
  @Input() family_status: any = null;
  @Input() education: any = null;
  @Input() loading = true;
  
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(){}

}
