<app-pager
    [perPage]="tableData?.per_page"
    [currentPage]="tableData?.current_page"
    [from]="tableData?.from"
    [to]="tableData?.to"
    [total]="tableData?.total"
    (changePage)="getDataOnPageChange($event)"
>
</app-pager>
<div class="table-responsive table-hover">
    <table class="table">
        <!-- row -->
        <thead>
            <tr>
                <!-- column -->
                <th>Provider</th>
                <th>Status</th>
                <th>Provider Status Name</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <select
                        (change)="getDataOnFilterSubmit()"
                        name="provider_id"
                        [(ngModel)]="filters.provider_id"
                    >
                        <option value>Select Provider</option>
                        <option
                            *ngFor="let item of providers"
                            value="{{ item?.id }}"
                        >
                            {{ item?.name }}
                        </option>
                    </select>
                </td>
                <td>
                    <select
                        (change)="getDataOnFilterSubmit()"
                        name="status_id"
                        [(ngModel)]="filters.status_id"
                    >
                        <option value>Select Status</option>
                        <option
                            *ngFor="let item of statuses"
                            value="{{ item?.id }}"
                        >
                            {{ item?.name }}
                        </option>
                    </select>
                </td>
                <td>
                    <input
                        type="text"
                        (keyup.enter)="getDataOnFilterSubmit()"
                        placeholder="Status Name"
                        name="provider_status_name"
                        [(ngModel)]="filters.provider_status_name"
                    />
                </td>
                <td></td>
            </tr>

            <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                <tr *ngFor="let item of tableData?.data">
                    <td>{{ item?.provider?.name }}</td>
                    <td>{{ item?.status?.name }}</td>
                    <td>{{ item?.provider_status_name }}</td>
                    <td class="text-right">
                        <app-mail-providers-statuses-create
                            [item]="item"
                            (itemUpdated)="onUpdated($event)"
                        ></app-mail-providers-statuses-create>
                    </td>
                </tr>
            </ng-template>
            <ng-template [ngIf]="!tableData?.data.length && !loading">
                <tr class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">Nothing Found</p>
                    </td>
                </tr>
            </ng-template>
            <tr *ngIf="loading" class="without_hover">
                <td colspan="3">
                    <p class="py-5 mb-0">
                        <img
                            width="30px"
                            height="30px"
                            src="assets/img/backoffice/loading.gif"
                        />
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<app-pager
    [perPage]="tableData?.per_page"
    [currentPage]="tableData?.current_page"
    [from]="tableData?.from"
    [to]="tableData?.to"
    [total]="tableData?.total"
    (changePage)="getDataOnPageChange($event)"
>
</app-pager>
