import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';
import { LeadService } from '../../../services/lead.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { ContactInfoService } from '../../../services/contact-info.service';


@Component({
    selector: 'app-lead-info-address-info',
    templateUrl: './lead-info-address-info.component.html',
    styleUrls: ['./lead-info-address-info.component.css']
})
export class LeadInfoAddressInfoComponent implements OnInit, OnChanges {
    @Input() lead: any = null;
    @Input() street_type: any = null;
    @Input() editInfo: any = null;
    @Input() loan_id: any = null;

    addressModel: any = null;
    data: any = null;

    streetTypes: any = null;

    isSaving: any = null;

    constructor(
        private leadService: LeadService,
        private modalService: NgbModal,
        private ContactInfoService: ContactInfoService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {
        this.getSteetTypes();
    }

    ngOnChanges() {
        if (this.lead) {
            this.data = this.setData(this.lead);
        }
    }

    openEdit(edit, data) {
        this.addressModel = this.setData(data);
        this.modalService.open(edit, { size: 'lg' });
    }

    getSteetTypes() {
        this.leadService
            .getRequestInfo()
            .then(result => {
                if (result) {
                    this.streetTypes = result.lead_street_type;
                }
            })
            .catch(error => console.log(error));
    }

    setData(lead) {
        let data = {
            street_type: lead.street_type,
            street: lead.street,
            street_number: lead.street_number,
            street_additional: lead.street_additional,
            city: lead.city,
            postal_code: lead.postal_code,
            updated_at: this.lead.updated_at
        };
        return data;
    }

    saveInfo(value) {
        if (this.loan_id) {
            this.isSaving = true;

            value.loan_id = this.loan_id;
            value.added_by = 1;
            value.street_type_id = value.street_type;

            this.ContactInfoService.save(value).subscribe(
                data => {
                    if (data) {
                        this.data = this.setData(value);
                        setTimeout(
                            function(self) {
                                self.errorMessageService.showSuccess('Info updated successful!');
                                self.isSaving = false;
                            },
                            600,
                            this
                        );
                    }
                },
                error => {
                    setTimeout(
                        function(self) {
                            self.isSaving = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(error.error.error.show_message);
                }
            );
        }
    }
}
