<div class="margin-top-20 table-responsive">
    <table class="table table-bordered table-hover">
        <img *ngIf="loading" width="50px" src="/assets/img/backoffice/loading.gif" />
        <thead class="table-head bg-green-light" *ngIf="!loading">
            <th width="10%">To</th>
            <th width="15%">Sent</th>
            <th width="35%">Text</th>
            <td width="15%">Type</td>
            <td width="15%">Notification</td>
            <th width="10%">Added By</th>
        </thead>
        <tbody *ngIf="!loading">
            <tr *ngFor="let sms of sms_list">
                <td [innerHTML]="formatPhone(sms?.mobile_phone)"></td>
                <td>{{ sms?.created_at }}</td>
                <td>{{ sms?.content }}</td>
                <td>{{ sms?.type?.description }}</td>
                <td>{{ sms?.notification }}</td>
                <td>{{ sms?.sent_by?.name }}</td>
            </tr>
        </tbody>
    </table>
</div>
