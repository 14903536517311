<div class="mb-3">
    <div class="mb-4 d-flex align-items-center">
        <h2 class="mb-0">Messages</h2>
        <app-messages-create
            class="ml-3"
            [userID]="userID"
        ></app-messages-create>
    </div>
    <app-messages-list [userID]="userID"></app-messages-list>
</div>
