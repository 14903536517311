<div class="mb-3">
  <div class="mb-4">
    <div class="row justify-content-between">
      <div class="col-auto"><h2>Messages Types</h2></div>
      <div class="col-auto">
        <app-mail-message-types-create></app-mail-message-types-create>
      </div>
    </div>
  </div>

  <app-mail-message-types-list></app-mail-message-types-list>
</div>
