<button
    *appHasPermissions="['late-fee-rectification']"
    class="btn btn-primary btn-sm"
    (click)="refund()"
    [disabled]="isSending"
>
    <i
        class="fa"
        [ngClass]="{ 'fa-undo': !isSending, 'fa-spinner fa-spin': isSending }"
        aria-hidden="true"
    ></i>
</button>
