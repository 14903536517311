<ul class="nav nav-pills mb-3">
  <li *ngFor="let item of links" class="nav-item">
    <!-- *appHasPermissions="['kpis-' + item]" -->
    <span>
      <a
        class="nav-link"
        [routerLink]="item?.href"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ item?.title }}</a
      >
    </span>
  </li>
</ul>

<router-outlet></router-outlet>
