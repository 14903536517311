import { Directive, OnInit, OnDestroy, TemplateRef, Input, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../components/services/auth.service';

@Directive({
    selector: '[appHasPermissions]'
})
export class HasPermissionsDirective implements OnInit, OnDestroy {
    @Input() appHasPermissions: Array<string>;
    private permission$: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private auth: AuthService
    ) {}

    ngOnInit(): void {
        this.applyPermission();
    }

    private applyPermission(): void {
        if (this.appHasPermissions && this.appHasPermissions.length > 0) {
            this.permission$ = this.auth.mapUserPermissions(this.appHasPermissions).subscribe(authorized => {
                if (authorized) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    ngOnDestroy(): void {
        if (this.permission$ && this.appHasPermissions && this.appHasPermissions.length > 0) {
            this.permission$.unsubscribe();
        }
    }
}
