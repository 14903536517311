<div class="row">
    <div class="col-xl-6">
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title">SCORING</h5>
            </div>
            <div class="card-body">
                <div id="scoring"></div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title">SUMMARY</h5>
            </div>
            <div class="card-body">
                <div class="container">
                    <div style="border-bottom: 3px solid">
                        <ul class="list-group">
                            <li
                                class="list-group-item d-flex justify-content-between align-items-center"
                            >
                                Day of maximum balance
                                <span>
                                    {{
                                        scoring?.scoring
                                            ?.date_max_balance_total_monthly
                                            | date: 'EEEE, d MMMM'
                                    }}
                                </span>
                            </li>
                            <li
                                class="list-group-item border-dark-top d-flex justify-content-between align-items-center"
                            >
                                Maximum account balance
                                <span>
                                    {{
                                        scoring?.scoring
                                            ?.max_balance_total_monthly
                                    }}
                                    €
                                </span>
                            </li>
                            <li
                                class="list-group-item border-dark-top d-flex justify-content-between align-items-center"
                            >
                                Bank account balance
                                <span
                                    >{{
                                        scoring?.scoring?.account_balance
                                    }}
                                    €</span
                                >
                            </li>
                            <li
                                class="list-group-item border-dark-top d-flex justify-content-between align-items-center"
                            >
                                Net debt balance
                                <span>
                                    {{ scoring?.scoring?.net_debt_balance }} €
                                </span>
                            </li>
                        </ul>
                    </div>

                    <h5 class="margin-top-10">DEBT SUMMARY LAST # MONTHS</h5>
                    <div class="margin-top-20 table-responsive">
                        <table class="table table-hover">
                            <tbody>
                                <tr *ngFor="let debt of largeDebtSummary">
                                    <td>{{ debt?.date }}</td>
                                    <td>{{ debt?.entity }}</td>
                                    <td class="text-right">
                                        {{ debt?.amount }} €
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr *ngFor="let debt of shortDebtSummary">
                                    <td>{{ debt?.date }}</td>
                                    <td>{{ debt?.entity }}</td>
                                    <td class="text-right">
                                        {{ debt?.amount }} €
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr *ngIf="unidentifiedDebtSummary">
                                    <td>{{ unidentifiedDebtSummary?.date }}</td>
                                    <td>
                                        {{ unidentifiedDebtSummary?.entity }}
                                    </td>
                                    <td class="text-right">
                                        {{ unidentifiedDebtSummary?.amount }} €
                                    </td>
                                </tr>
                                <tr></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6">
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title">SCORING ORIGINE</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-4">
                        <div class="card mb-3">
                            <div class="card-body">
                                <h1 class="text-center">
                                    {{ scoring?.origine_base }}
                                </h1>
                                <h5 class="text-center">BASE</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="card mb-3">
                            <div class="card-body">
                                <h1 class="text-center">
                                    {{ scoring?.origine_demographic }}
                                </h1>
                                <h5 class="text-center">DEMOGRAPHIC</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="card mb-3">
                            <div class="card-body">
                                <h1 class="text-center">
                                    {{ scoring?.origine_bank }}
                                </h1>
                                <h5 class="text-center">BANK</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title">SCORING HISTORY</h5>
            </div>
            <div class="card-body text-center">
                <div id="myChart" height="300" width="500"></div>
            </div>
        </div>
        <div *ngIf="accounts?.length" class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title">Bank Accounts</h5>
            </div>
            <div class="card-body">
                <div
                    *ngFor="let item of accounts; let last = last"
                    [ngClass]="{ 'mb-3': !last }"
                    class="row justify-content-beetwen"
                >
                    <div class="col">
                        <h6 class="mb-0">{{ item?.iban }}</h6>
                        <small
                            >{{ item?.description }}({{ item?.code }}) /
                            Transactions:
                            {{ item?.transactions_count || '-' }}</small
                        >
                    </div>
                    <div class="col-auto">
                        {{ item?.balance | currency: item?.code }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class>
    <div class="card mb-3">
        <div class="card-header">
            <h5 class="card-header-title">TRANSACTIONS</h5>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div style="border-bottom: 3px solid #69356e">
                    <app-pager
                        [currentPage]="bankTransaction?.current_page"
                        [firstPageUrl]="bankTransaction?.first_page_url"
                        [from]="bankTransaction?.from"
                        [lastPage]="bankTransaction?.last_page"
                        [lastPageUrl]="bankTransaction?.last_page_url"
                        [nextPageUrl]="bankTransaction?.next_page_url"
                        [path]="bankTransaction?.path"
                        [perPage]="bankTransaction?.per_page"
                        [prev_page_url]="bankTransaction?.prev_page_url"
                        [to]="bankTransaction?.to"
                        [total]="bankTransaction?.total"
                        (changePage)="getBankTransactions($event)"
                    >
                    </app-pager>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th width="8%">Start Date</th>
                                <th width="8%">End Date</th>
                                <th width="6%">Currency Amount</th>
                                <th width="6%">Balance</th>
                                <th width="40%">Description</th>
                                <th width="32%">Iban</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <!-- <input
                                        type="text"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup.enter)="makeFilter()"
                                        placeholder="Start Date"
                                        name="start_date"
                                    > -->
                                    <input
                                        type="text"
                                        name="created_at_start"
                                        placeholder="Choose start date"
                                        daterangepicker
                                        value="{{ dateInputValStart }}"
                                        [options]="options"
                                        (keyup.enter)="clearFilter($event)"
                                        (selected)="
                                            selectedDate(
                                                $event,
                                                'created_at_start'
                                            )
                                        "
                                    />
                                </td>
                                <td>
                                    <!-- <input
                                        type="text"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup.enter)="makeFilter()"
                                        placeholder="End Date"
                                        name="end_date"
                                    > -->
                                    <input
                                        type="text"
                                        name="created_at_end"
                                        placeholder="Choose end date"
                                        daterangepicker
                                        value="{{ dateInputValEnd }}"
                                        [options]="options"
                                        (keyup.enter)="clearFilter($event)"
                                        (selected)="
                                            selectedDate(
                                                $event,
                                                'created_at_end'
                                            )
                                        "
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup.enter)="makeFilter()"
                                        placeholder="Currency Amount"
                                        name="amount"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup.enter)="makeFilter()"
                                        placeholder="New Balance"
                                        name="balance"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        placeholder="description"
                                        name="description"
                                        (keyup)="getParamsFilter($event)"
                                        (keyup.enter)="makeFilter()"
                                    />
                                </td>
                                <td>
                                    <select
                                        name="iban"
                                        *ngIf="accounts?.length"
                                        (change)="
                                            getParamsFilter($event);
                                            makeFilter()
                                        "
                                    >
                                        <option value="">Select IBAN</option>
                                        <option
                                            *ngFor="let item of accounts"
                                            value="{{ item.iban }}"
                                        >
                                            {{ item?.iban }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <!-- Esto es para cuando se haga el filtrado se muestre solamente una file en la tabla con el  efecto loading ! -->
                            <!-- <tr *ngIf="loading">
                                                <img *ngIf="loading" width="50px" src="/assets/img/backoffice/loading.gif">
                                            </tr>
                                            ! -->

                            <tr
                                *ngFor="
                                    let transaction of bankTransaction?.data
                                "
                            >
                                <td>{{ transaction?.start_date }}</td>
                                <td>{{ transaction?.end_date }}</td>
                                <td>
                                    {{ transaction?.amount | currency: 'EUR' }}
                                </td>
                                <td>
                                    {{ transaction?.balance | currency: 'EUR' }}
                                </td>
                                <td>{{ transaction?.description }}</td>
                                <td>{{ transaction?.iban }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <app-pager
                        [currentPage]="bankTransaction?.current_page"
                        [firstPageUrl]="bankTransaction?.first_page_url"
                        [from]="bankTransaction?.from"
                        [lastPage]="bankTransaction?.last_page"
                        [lastPageUrl]="bankTransaction?.last_page_url"
                        [nextPageUrl]="bankTransaction?.next_page_url"
                        [path]="bankTransaction?.path"
                        [perPage]="bankTransaction?.per_page"
                        [prev_page_url]="bankTransaction?.prev_page_url"
                        [to]="bankTransaction?.to"
                        [total]="bankTransaction?.total"
                        (changePage)="getBankTransactions($event)"
                    >
                    </app-pager>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- scoring tab page -->
