import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SmsService } from '../../../services/sms.service';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';

@Component({
    selector: 'app-lead-sms-log',
    templateUrl: './lead-sms-log.component.html',
    styleUrls: ['./lead-sms-log.component.css']
})
export class LeadSmsLogComponent implements OnInit {
    id: any = '';
    sms_list: any = null;
    loading = true;
    constructor(
        private activate: ActivatedRoute,
        private smsService: SmsService,
        private formatPhoneService: FormatPhoneNumberService
    ) {}

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];
            this.getSmsList(this.id);
        });
    }

    getSmsList(lead_id) {
        this.smsService.getByLeadId(lead_id).subscribe(sms => {
            if (Object.values(sms).length) {
                this.sms_list = sms;

                if (Object.values(sms).length) {
                    sms.forEach((current_value, index) => {
                        switch (current_value.notification_code) {
                            case 1:
                                this.sms_list[index].notification = 'El mensaje ha sido entregado al destinatario';
                                break;
                            case 2:
                                this.sms_list[index].notification =
                                    'El mensaje no se ha podido entregar al destinatario';
                                break;
                            case 4:
                                this.sms_list[index].notification =
                                    'El mensaje ha sido entregado al SMSC, es una notificación intermedia, no un resultado final';
                                break;
                            case 16:
                                this.sms_list[index].notification = 'No se ha podido entregar a la operadora final';
                                break;
                            default:
                                this.sms_list[index].notification = '-';
                                break;
                        }
                    });
                }
            }
            this.loading = false;
        });
    }

    formatPhone(phone) {
        if (phone) {
            return this.formatPhoneService.format(phone);
        }
    }
}
