<div class="card mb-3">
    <div class="card-header">
        <div *ngIf="asnef_snapshot?.invalid || invalid_detail" class="circle-red"></div>
        <div *ngIf="!asnef_snapshot?.invalid && !invalid_detail" class="circle-green"></div>
        <h5 class="card-header-title">ASNEF VALIDATION</h5>
    </div>
    <div class="card-body">
        <div class="fluid-row">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Current Debt</strong
                                    ><span>{{ asnef_snapshot?.current_debt_amount | currency: 'EUR' }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Maximum Debt</strong
                                    ><span>{{ asnef_snapshot?.maximun_debt_amount | currency: 'EUR' }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Asnef Status</strong
                                    ><span>{{ asnef_snapshot?.worst_operation?.description }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Date:</strong><span>{{ asnef_snapshot?.current_debt_date }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Date:</strong><span>{{ asnef_snapshot?.maximun_debt_date }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Date:</strong><span>{{ asnef_snapshot?.worst_operation_date }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header"><h5 class="card-header-title">Debt Details</h5></div>
                <div class="card-body pl-0 pr-0 pt-0 pb-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover mb-0">
                            <thead class="table-head bg-green-light">
                                <th>Result</th>
                                <th>Date</th>
                                <th>Role</th>
                                <th>Source</th>
                                <th>Initial</th>
                                <th>Current</th>
                                <th>Limit</th>
                                <th>Who</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let detail of asnef_snapshot?.details">
                                    <td><div *ngIf="detail?.invalid" class="circle-red"></div></td>
                                    <td>{{ detail?.inclusion_date }}</td>
                                    <td>{{ detail?.asnef_snapshot_concept_nature?.description }}</td>
                                    <td>{{ detail?.asnef_snapshot_concept_product?.description }}</td>
                                    <td>{{ detail?.nominal_amount }}</td>
                                    <td>{{ detail?.unpaid_amount }}</td>
                                    <td></td>
                                    <td>{{ detail?.entity_description }}</td>
                                    <td>{{ detail?.asnef_snapshot_concept_operation?.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
