import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class FinanceService {
    constructor(private http: HttpClient) {}

    getInvoices(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/finance/invoice/list?${filterUrlParams}`
        );
    }

    getCashIn(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/finance/cashin/list?${filterUrlParams}`
        );
    }

    getCollection(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/finance/collection/list?${filterUrlParams}`
        );
    }

    getCashOut(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/finance/cashout/list?${filterUrlParams}`
        );
    }

    getCashCommitted(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/finance/cash_committed/list?${filterUrlParams}`
        );
    }

    getCashOutExport(filterUrlParams): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(
            environment.api_url + `/finance/cashout/export?${filterUrlParams}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );
    }

    getCashInExport(filterUrlParams): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(
            environment.api_url + `/finance/cashin/export/?${filterUrlParams}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );
    }

    getInvoiceExport(filterUrlParams): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(
            environment.api_url + `/finance/invoice/export?${filterUrlParams}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );
    }

    getPaymentDetail(payment_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/payment/${payment_id}/detail/show`
        );
    }

    getStatuses(): Observable<any> {
        return this.http.get(environment.api_url + `/payment/status/list`);
    }

    getGateways(): Observable<any> {
        return this.http.get(environment.api_url + `/payment/provider/list`);
    }

    getSources(): Observable<any> {
        return this.http.get(environment.api_url + `/payment/source/list`);
    }

    getCashinTotals(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/finance/cashin/total/show?${filterUrlParams}`
        );
    }

    getCashoutTotals(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/finance/cashout/total/show?${filterUrlParams}`
        );
    }

    getInvoiceTotals(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/finance/invoice/total/show?${filterUrlParams}`
        );
    }

    getInvoiceTypes(): Observable<any> {
        return this.http.get(
            environment.api_url + `/finance/invoice/type/list`
        );
    }

    getDueDebtList(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/finance/duedebt/list?${filterUrlParams}`
        );
    }
    getDueDebtTotals(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url +
                `/finance/duedebt/total/show?${filterUrlParams}`
        );
    }
    getDueDebtExport(filterUrlParams): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(
            environment.api_url + `/finance/duedebt/export?${filterUrlParams}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );
    }

    getPaymentTypes(): Observable<any> {
        return this.http.get(environment.api_url + `/payment/type/list`);
    }

    getProvisions(params): Observable<any> {
        return this.http.get(environment.api_url + '/provisions', {
            params,
        });
    }

    getProvisionsExport(id): Observable<any> {
        const headers = new HttpHeaders({
            timeout: `${120000}`,
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http.get(environment.api_url + `/provisions/${id}/export`, {
            headers: headers,
            responseType: 'blob',
        });
    }
}
