import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DiscardAntifraudService {
    constructor(private http: HttpClient) {}
    getConfigValidatorList(): Observable<any> {
        return this.http.get(environment.api_url + `/config/validator/discard_antifraud/list`);
    }
    updateConfigValidator(validator): Observable<any> {
        return this.http.post(
            environment.api_url + `/config/validator/discard_antifraud/${validator.id}/edit`,
            validator
        );
    }
}
