import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CreditLineService } from '../../../services/credit-line.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckNavBarService } from '../../../services/check-nav-bar.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-check-nav-bar',
    templateUrl: './check-nav-bar.component.html',
    styleUrls: ['./check-nav-bar.component.scss']
})
export class CheckNavBarComponent implements OnInit, OnChanges {
    @Input() creditLineCheck = null;
    @Input() showCheck = true;
    @Output() changeActivation: EventEmitter<any> = new EventEmitter();
    creditLineId = null;
    pending: boolean = true;

    modalControl: any;

    creditLineChecking = {
        info: false,
        antifraud: false,
        docs: false
    };

    reasons;

    constructor(
        private creditLineService: CreditLineService,
        private router: Router,
        route: ActivatedRoute,
        private checkNavBarService: CheckNavBarService,
        private errorMessageService: ErrorMessageService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.checkNavBarService.completed = false;
        this.checkNavBarService.checkedSelfie = false;
        this.checkNavBarService.checkedPersonalIdBack = false;
        this.checkNavBarService.checkedPersonalIdFront = false;
        this.checkNavBarService.checkedAddress = false;
        this.checkNavBarService.checkedInfo = false;
        this.checkNavBarService.checkedAntifraud = false;

        this.creditLineService.getReasons().subscribe(reasons => {
            this.reasons = reasons;
        });
    }
    ngOnChanges() {
        if (this.creditLineCheck) {
            /**  Configurando en el servicio cuales de los estados se ha checkeado ya */
            this.creditLineId = this.creditLineCheck.loan_id;
            if (this.creditLineCheck.completed) {
                this.checkNavBarService.completed = true;
            }
            if (this.creditLineCheck.doc_credit_card_selfie) {
                this.checkNavBarService.checkedSelfie = true;
            }
            if (this.creditLineCheck.doc_personalid_back_face) {
                this.checkNavBarService.checkedPersonalIdBack = true;
            }
            if (this.creditLineCheck.doc_personalid_front_face) {
                this.checkNavBarService.checkedPersonalIdFront = true;
            }
            if (this.creditLineCheck.doc_document_confirming_address) {
                this.checkNavBarService.checkedAddress = true;
            }
            if (this.creditLineCheck.customer_info) {
                this.checkNavBarService.checkedInfo = true;
            }
            if (this.creditLineCheck.antifraud) {
                this.checkNavBarService.checkedAntifraud = true;
            }
            this.pending = false;
        }
    }

    creditLineActive() {
        if (this.confirming()) {
            if (this.creditLineId) {
                this.pending = true;
                this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                    if (can) {
                        this.creditLineService.active(this.creditLineId).subscribe(
                            resp => {
                                if (resp) {
                                    this.errorMessageService.showSuccess('La linea ha sido activada con éxito');
                                    this.router.navigate([`/backoffice/loans/loan/active`]);
                                    this.pending = false;
                                }
                            },
                            error => {
                                if (error.error.error) {
                                    this.errorMessageService.showError(error.error.error.show_message);
                                    this.pending = false;
                                } else {
                                    alert(error.error);
                                    this.pending = false;
                                }
                            }
                        );
                    } else {
                        this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                        this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                        this.pending = false;
                    }
                });
            }
        }
    }
    creditLineReject(content) {
        this.modalControl = this.modalService.open(content, { size: 'lg' });
    }
    cancel() {
        this.router.navigate([`/backoffice/loans/loan-checking`]);
    }

    /* CHECKEA LA PANTALLA ACTUAL */
    check() {
        if (this.confirming()) {
            if (this.checkNavBarService.checkingCustomerInfo) {
                this.checkCustomerInfo();
            }
            if (this.checkNavBarService.checkingDocs) {
                this.checkDocs();
            }
            if (this.checkNavBarService.checkingAntifraud) {
                this.checkAntifraud();
            }
        }
    }
    checkCustomerInfo() {
        if (this.creditLineId) {
            this.pending = true;

            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    if (this.creditLineId) {
                        this.creditLineService.checkCustomerInfo(this.creditLineId).subscribe(
                            resp => {
                                if (resp.customer_info) {
                                    this.checkCompleted(resp);
                                    this.checkNavBarService.checkedInfo = true;
                                    this.checkNavBarService.checked();

                                    this.creditLineChecking.info = true;

                                    this.changeActivation.emit(this.creditLineChecking);
                                }
                                this.pending = false;
                            },
                            error => {
                                if (error.error.error) {
                                    this.errorMessageService.showError(error.error.error.show_message);
                                } else {
                                    alert(error.error);
                                }
                                this.pending = false;
                            }
                        );
                    }
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }
    checkDocs() {
        if (!this.checkNavBarService.checkedPersonalIdFront) {
            this.checkPersonalidFrontFace();
        }
        if (!this.checkNavBarService.checkedPersonalIdBack) {
            this.checkPersonalidBackFace();
        }
        if (!this.checkNavBarService.checkedSelfie) {
            this.checkCreditCardSelfie();
        }
        if (!this.checkNavBarService.checkedAddress) {
            this.checkDocumentConfirmingAddress();
        }

        this.checkNavBarService.checked();
    }
    checkAntifraud() {
        if (this.creditLineId) {
            this.pending = true;
            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    if (this.creditLineId) {
                        this.creditLineService.checkAntifraud(this.creditLineId).subscribe(
                            resp => {
                                if (resp.antifraud) {
                                    this.checkCompleted(resp);
                                    this.checkNavBarService.checkedAntifraud = true;
                                    this.checkNavBarService.checked();

                                    this.creditLineChecking.antifraud = true;
                                    this.changeActivation.emit(this.creditLineChecking);
                                }
                                this.pending = false;
                            },
                            error => {
                                if (error.error.error) {
                                    this.errorMessageService.showError(error.error.error.show_message);
                                    this.pending = false;
                                } else {
                                    alert(error.error);
                                    this.pending = false;
                                }
                            }
                        );
                    }
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }

    checkPersonalidFrontFace() {
        if (this.creditLineId) {
            this.pending = true;
            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    this.creditLineService.checkDocPersonalidFrontFace(this.creditLineId).subscribe(resp => {
                        if (resp.doc_personalid_front_face) {
                            this.checkNavBarService.checkedPersonalIdFront = true;
                            this.checkCompleted(resp);
                        }
                        this.pending = false;
                    });
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }
    checkPersonalidBackFace() {
        if (this.creditLineId) {
            this.pending = true;
            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    this.creditLineService.checkDocPersonalidBackFace(this.creditLineId).subscribe(resp => {
                        if (resp.doc_personalid_back_face) {
                            this.checkNavBarService.checkedPersonalIdBack = true;
                            this.checkCompleted(resp);
                        }
                        this.pending = false;
                    });
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }
    checkCreditCardSelfie() {
        if (this.creditLineId) {
            this.pending = true;
            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    this.creditLineService.checkDocCreditCardSelfie(this.creditLineId).subscribe(resp => {
                        if (resp.doc_credit_card_selfie) {
                            this.checkNavBarService.checkedSelfie = true;
                            this.checkCompleted(resp);
                        }
                        this.pending = false;
                    });
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }
    checkDocumentConfirmingAddress() {
        if (this.creditLineId) {
            this.pending = true;
            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    this.creditLineService.checkDocDocumentConfirmingAddress(this.creditLineId).subscribe(resp => {
                        if (resp.doc_document_confirming_address) {
                            this.checkNavBarService.checkedAddress = true;
                            this.checkCompleted(resp);
                        }
                        this.pending = false;
                    });
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }

    confirming() {
        if (confirm('¿Esta seguro que desea realizar esta operación?')) {
            return true;
        } else {
            return false;
        }
    }

    confirmReject(reason) {
        if (this.creditLineId && reason.blocked_reason) {
            this.pending = true;
            this.creditLineService.canCheck(this.creditLineId).subscribe(can => {
                if (can) {
                    if (this.creditLineId) {
                        this.creditLineService.sentReason(this.creditLineId, reason.blocked_reason).subscribe(
                            resp => {
                                if (resp) {
                                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                                    this.errorMessageService.showSuccess('Line blocked successful!');
                                    this.modalControl.close();
                                }
                                this.pending = false;
                            },
                            error => {
                                if (error.error.error) {
                                    this.errorMessageService.showError(error.error.error.show_message);
                                } else {
                                    alert(error.error);
                                }
                                this.pending = false;
                            }
                        );
                    }
                } else {
                    this.errorMessageService.showError('Ya hay otro operador gestionando esta linea');
                    this.router.navigate([`/backoffice/loans/loan/pendingActivation`]);
                    this.pending = false;
                }
            });
        }
    }
    checkCompleted(resp) {
        if (resp.completed) {
            this.checkNavBarService.completed = true;
            if (
                this.checkNavBarService.checkedPersonalIdFront &&
                this.checkNavBarService.checkedPersonalIdBack &&
                this.checkNavBarService.checkedSelfie &&
                this.checkNavBarService.checkedAddress
            ) {
                this.creditLineChecking.docs = true;
                this.changeActivation.emit(this.creditLineChecking);
            }
        }
    }
}
