import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { EmailService } from '../../../../services/email.service';
import { TEMPLATE_ACTIVATION_ENUM } from '../../../../../shared/enums/template-activation.enum';

@Component({
  selector: 'app-mail-templates-variables-list',
  templateUrl: './mail-templates-variables-list.component.html',
  styleUrls: ['./mail-templates-variables-list.component.scss'],
})
export class MailTemplatesVariablesListComponent implements OnInit, OnChanges {
  @Input() addedItem;
  loading = true;
  tableData;

  startData = {};

  filters: any = {};

  queryString: string = '';

  querySubscriber: ISubscription;
  dataSubscriber: ISubscription;

  type = TEMPLATE_ACTIVATION_ENUM.TEMPLATE_VARIABLE;

  constructor(
    private emailService: EmailService,
    public activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.querySubscriber = this.activatedRoute.queryParams.subscribe(
      (queryParams) => {
        this.startData = {
          code: '',
          created_at_start: '',
          created_at_end: '',
          rejected_date_start: '',
          rejected_date_end: '',
          credit_line_status_id: '',
          personalid: '',
          full_name: '',
          limit_amount: '',
          payment_rule_way_id: '',
          added_by: '',
          source: '',
          medium: '',
          campaign: '',
        };

        if (this.dataSubscriber) {
          this.dataSubscriber.unsubscribe();
        }

        // Set Init Filters
        this.setInitFilters(queryParams);
        // Create Query String
        this.createQueryString(this.filters);

        this.getTableData();
      }
    );
  }

  ngOnChanges({ addedItem }: SimpleChanges) {
    const currentAddedItem: SimpleChange = addedItem;
    const { currentValue } = currentAddedItem;

    this.updateTable(currentValue);
  }

  getTableData() {
    this.loading = true;
    this.dataSubscriber = this.emailService
      .getTemplateVariables(this.queryString)
      .subscribe((response) => {
        if (response) {
          this.tableData = response;
          this.loading = false;
        }
      });
  }

  getDataOnFilterSubmit() {
    // Set Init Filters
    this.filters = { ...this.filters, page: 0 };

    // Create Query String
    this.createQueryString(this.filters);

    // Push Query String to history.
    // Navigates without replacing the current state in history.
    this.pushFiltersToHistory(this.filters);
  }

  createQueryString(filters = {}) {
    const clearFilters = { ...filters };

    Object.keys(clearFilters).forEach(
      (key) =>
        (!clearFilters[key] || clearFilters[key] === 'empty') &&
        delete clearFilters[key]
    );

    this.queryString = Object.keys(clearFilters)
      .map((key) => key + '=' + clearFilters[key])
      .join('&');
  }

  pushFiltersToHistory(filters, replaceUrl = false) {
    Object.keys(filters).forEach((key) => !filters[key] && delete filters[key]);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: Object.keys(filters).length > 0 ? filters : {},
      replaceUrl: replaceUrl,
    });
  }

  setInitFilters(query = {}) {
    this.filters = { ...this.startData, ...query };
  }

  getDataOnPageChange(page) {
    this.filters['page'] = page;

    // Create Query String
    this.createQueryString(this.filters);

    // Push Query String to history.
    // Navigates without replacing the current state in history.
    this.pushFiltersToHistory(this.filters);
  }

  onChangeDate(e: any) {
    this.filters = { ...this.filters, ...e, page: 0 };
    // Create Query String
    this.createQueryString(this.filters);

    // Push Query String to history.
    // Navigates without replacing the current state in history.
    this.pushFiltersToHistory(this.filters);
  }

  formatDate(stringDate) {
    let formatDateString;
    if (stringDate) {
      formatDateString = new Date(stringDate.replace(/-/g, '/'));
    }
    return formatDateString;
  }

  onUpdated(e) {
    this.updateTable(e);
  }

  updateTable(value) {
    if (value) {
      const update =
        this.tableData.filter((item) => +item.id === +value.id)?.length > 0 ||
        false;

      if (update) {
        this.tableData.forEach((element, index) => {
          if (+element.id === +value.id) {
            this.tableData[index].name = value.name;
            this.tableData[index].name_template = value.name_template;
            this.tableData[index].value = value.value;
            this.tableData[index].enabled = value.enabled;
            this.tableData[index].description = value.description;
          }
        });
      } else {
        this.tableData.push(value);
      }
    }
  }
}
