<div class="row align-items-center mb-2">
    <h3 class="col-auto mb-0">Validation Asnef</h3>
    <div class="col-auto">
        <div class="custom-control custom-switch">
            <input class="custom-control-input" id="asnefConsult" type="checkbox" (change)="switchValidation()" [disabled]="saving" [(ngModel)]="asnefConsult">
            <label class=" custom-control-label"
                    for="asnefConsult"></label>
        </div>
    </div>
</div>
<table *ngIf="asnefConsult" class="table table-striped table-responsive">
    <thead>
        <tr>
            <th>Validator type</th>
            <th>Value</th>
            <th>Enabled</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let form of forms; let index = index">
            <td>
                {{ form?.description }}
            </td>
            <td>
                <input type="number" class="form-control" [(ngModel)]="form.amount" />
            </td>
            <td>
                <input type="checkbox" class="form-control" [(ngModel)]="form.actived" />
                <label class="form-check-label">Actived</label>
            </td>
        </tr>
    </tbody>
</table>
<div *ngIf="asnefConsult" class="text-center py-4 mt-3">
    <button
        class="btn btn-cyan waves-light"
        type="submit"
        [disabled]="!forms || saving"
        (click)="save()"
        mdbWavesEffect
    >
        Save
    </button>
</div>
