<div class="card mb-3" *ngIf="history && history?.length > 0">
    <div class="card-header">
        <h5 class="card-header-title">Blacklist Status History</h5>
    </div>
    <div class="card-body">
        <div class="margin-top-20 table-responsive">
            <table class="table table-condensed table-hover">
                <thead>
                    <th width="33%">Date</th>
                    <th width="33%">Status</th>
                    <th width="33%">Who</th>
                    <th width="33%">Comment</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of history">
                        <td>{{item?.created_at | dateFormat | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item?.status?.name}}</td>
                        <td>{{item?.added_by?.name}} {{item?.added_by?.first_surname}}
                            {{item?.added_by?.second_surname}}</td>
                        <td>{{item?.comment}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
