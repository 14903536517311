<form
    [formGroup]="productForm"
    class="col-12 col-lg-6"
    (ngSubmit)="onFormSubmit()"
>
    <!-- input names -->
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="form-group">
                <label for="name" class="font-weight-light">Name</label>
                <input
                    type="text"
                    id="name"
                    formControlName="name"
                    class="form-control"
                    mdbInputDirective
                />
            </div>

            <div class="form-group">
                <label for="number" class="font-weight-light">Min Limit</label>
                <input
                    type="number"
                    id="min"
                    formControlName="min"
                    class="form-control"
                    mdbInputDirective
                />
            </div>

            <div class="form-group">
                <label for="max" class="font-weight-light">Max Limit</label>
                <input
                    type="number"
                    id="max"
                    formControlName="max"
                    class="form-control"
                    mdbInputDirective
                />
            </div>

            <div class="form-group">
                <label for="interest_rate" class="font-weight-light"
                    >Interest Rate</label
                >
                <input
                    type="number"
                    id="interest_rate"
                    formControlName="interest_rate"
                    class="form-control"
                    mdbInputDirective
                />
            </div>

            <div class="form-group">
                <label for="weeks" class="font-weight-light">Weeks</label>
                <input
                    type="number"
                    id="weeks"
                    formControlName="weeks"
                    class="form-control"
                    mdbInputDirective
                />
            </div>

            <div class="form-group">
                <label for="late_fee_amount" class="font-weight-light"
                    >Late Fee</label
                >
                <input
                    type="number"
                    id="late_fee_amount"
                    formControlName="late_fee_amount"
                    class="form-control"
                    mdbInputDirective
                />
            </div>

            <div class="form-group">
                <label for="max_limit_approved" class="font-weight-light"
                    >Max Limit Approved</label
                >
                <input
                    type="number"
                    id="max_limit_approved"
                    formControlName="max_limit_approved"
                    class="form-control"
                    mdbInputDirective
                />
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="contract_id" class="font-weight-light"
                    >Contract</label
                >
                <select
                    name="contract_id"
                    id="contract_id"
                    class="form-control"
                    formControlName="contract_id"
                >
                    <option value="">Select Contract</option>
                    <option *ngFor="let item of contracts" [value]="item?.id">
                        {{ item?.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="eu_form_id" class="font-weight-light"
                    >EU Form</label
                >
                <select
                    name="eu_form_id"
                    id="eu_form_id"
                    class="form-control"
                    formControlName="eu_form_id"
                >
                    <option value="">Select EU Form</option>
                    <option *ngFor="let item of euforms" [value]="item?.id">
                        {{ item?.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="text-center py-4 mt-3">
        <button
            class="btn btn-primary waves-light"
            type="submit"
            [disabled]="productForm.invalid"
            mdbWavesEffect
        >
            {{ product_id ? 'Edit' : 'Create' }}
        </button>
    </div>
</form>
