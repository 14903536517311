import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AffiliatesService } from '../../../services/affiliates.service';

@Component({
    selector: 'app-affiliate-log',
    templateUrl: './affiliate-log.component.html',
    styleUrls: ['./affiliate-log.component.scss']
})
export class AffiliateLogComponent implements OnInit {
    modalReference: NgbModalRef;

    @Input() start: string = '';
    @Input() end: string = '';
    @Input() id: number = null;

    logs: any = null;

    loading = true;

    constructor(private modal: NgbModal, private affiliatesService: AffiliatesService) {}

    ngOnInit() {}

    open(e, content) {
        e.preventDefault();

        this.getLogs();

        this.modalReference = this.modal.open(content, { windowClass: 'modal-wide' });

        this.modalReference.result.then(
            result => {
                this.logs = null;
            },
            reason => {
                this.logs = null;
            }
        );
    }

    getLogs(page = 0) {
        let params: any = '';

        if (this.start) {
            params += `created_at_start=${this.start}&`;
        }

        if (this.end) {
            params += `created_at_end=${this.end}&`;
        }

        if (page !== 0 && page !== 1) {
            params += `page=${page}`;
        }

        this.affiliatesService
            .getAffiliateLog(this.id, params)
            .toPromise()
            .then(response => {
                if (response) {
                    this.logs = response;
                    this.loading = false;
                }
            });
    }
}
