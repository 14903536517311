<div class="container-fluid">
    <div class="form-row align-items-stretch mb-3">
        <div *ngFor="let loan of loans" class="col-6 col-sm-4 col-md-3 col-lg-2 col-kpi">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">
                        {{ loan?.status }}
                        <br />
                        <small class="text-body">in units</small>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-end pt-2">
                        <h3 class="mb-0">{{ loan?.units?.today | number }}</h3>
                        <span>Today</span>
                    </div>
                    <ng-container *ngFor="let item of (loan?.units | keys); let last = last">
                        <div
                            *ngIf="item != 'today'"
                            class="d-flex justify-content-between pt-3"
                            [ngClass]="{ 'border-bottom': !last, 'pb-1': !last }"
                        >
                            <span>
                                {{ item | replace: '_':' ' | titlecase }}
                            </span>
                            <span>{{ (loan?.units)[item] | number }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-kpi-2 mb-3">
            <input
                type="text"
                name="date"
                daterangepicker
                [options]="settings"
                class="form-control"
                (selected)="selectedDate($event)"
                placeholder="Choose date"
                (cancelDaterangepicker)="clearCalendar($event)"
                (keyup.enter)="clearCalendar($event)"
                autocomplete="off"
                [value]="formDateValue"
            />
            <!-- <select [(ngModel)]="selectRange" class="form-control">
                <option *ngFor="let item of selectRanges" [value]="item">
                    {{ item | replace: '_':' ' | titlecase }}
                </option>
            </select> -->
        </div>
    </div>

    <div class="form-row">
        <div *ngFor="let loan of loans" class="col-6 col-sm-4 col-md-3 col-lg-2 col-kpi">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-white">
                    <h5 class="card-title text-primary mb-0">
                        {{ loan?.status }}
                        <br />
                        <small class="text-body">in units</small>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-end pt-2 pb-2">
                        <h3 class="mb-0">
                            {{ (loan?.units)[selectRange] | number }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-9 col-lg-6 col-kpi-3 mb-3">
            <div class="card rounded-0 h-100">
                <div class="card-header bg-white border-bottom-0 pb-0">
                    <h4 class="card-title">Loans Quality</h4>
                </div>
                <div class="card-body pt-0">
                    <div class="row h-100 align-items-center">
                        <div class="offset-md-3 col-12 col-md-8">
                            <div class="row mb-3">
                                <div class="col-4"></div>
                                <div class="col-4">Month</div>
                                <div class="col-4">YTY</div>
                            </div>
                            <div class="row align-items-end mb-3">
                                <div class="col-4">APQ</div>
                                <div class="col-4">
                                    <h3 class="text-primary mb-0">84,5%</h3>
                                </div>
                                <div class="col-4">
                                    <h3 class="text-secondary mb-0">15,5%</h3>
                                </div>
                            </div>
                            <div class="row align-items-end">
                                <div class="col-4">Default</div>
                                <div class="col-4">
                                    <h3 class="text-primary mb-0">89,5%</h3>
                                </div>
                                <div class="col-4">
                                    <h3 class="text-secondary mb-0">11,5%</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngFor="let loan of loans; let i = index">
            <div
                *ngIf="i > 2"
                [ngClass]="{ 'd-none': !loan?.money, 'd-xl-block': true }"
                class="col-6 col-sm-4 col-md-3 col-lg-2 col-kpi"
            >
                <div *ngIf="loan?.money">
                    <div class="card rounded-0 mb-3">
                        <div class="card-header bg-white">
                            <h5 class="card-title text-primary mb-0">
                                {{ loan?.status }}
                                <br />
                                <small class="text-body">in €</small>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="d-flex justify-content-end align-items-end pt-2 pb-2">
                                <h3 class="mb-0">
                                    {{ (loan?.money)[selectRange] | number }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-0 mb-3">
                        <div class="card-header bg-white">
                            <h5 class="card-title text-primary mb-0">
                                Average Size
                                <br />
                                <small class="text-body">in €</small>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="d-flex justify-content-end align-items-end pt-2 pb-2">
                                <h3 class="mb-0">
                                    {{ (loan?.money)[selectRange] / (loan?.units)[selectRange] | number: '1.0-2' }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
