import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class PaymentRuleService {

  constructor(private http: HttpClient) { }

  getUnPaidList(): Observable<any>{
    return this.http.get(environment.api_url + '/payment_rule/unpaid/list');
  }
}
