<div class="container" *ngIf="!isLoading">
    <div class="row">
        <div class="col-12">
            <div class="row justify-content-between mb-3">
                <div class="col-12 col-lg-auto">
                    <h2 class="mb-0">{{ pageTitle }} Queue</h2>
                </div>
                <div *ngIf="taskId" class="col-12 col-lg-auto text-right">
                    <a
                        [routerLink]="[
                            '/backoffice/debtrecovery/queue-manager/create-queue'
                        ]"
                        class="btn btn-primary"
                        >Add New +</a
                    >
                </div>
            </div>
        </div>

        <div class="col-12">
            <form [formGroup]="form" id="form" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label class="text-primary" for="name"
                                >Queue Name</label
                            >
                            <input
                                type="text"
                                name="name"
                                id="name"
                                formControlName="name"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls.name.touched &&
                                        !form.controls.name.valid
                                }"
                                class="form-control"
                                placeholder="Name"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label
                                class="text-primary"
                                for="payment_tree_amount_id"
                                >Payment Source</label
                            >
                            <select
                                name="payment_source_id"
                                id="payment_source_id"
                                formControlName="payment_source_id"
                                class="form-control"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls.payment_source_id
                                            .touched &&
                                        !form.controls.payment_source_id.valid
                                }"
                            >
                                <option value="">Select Source</option>
                                <option
                                    *ngFor="let item of paymentSourceList"
                                    value="{{ item?.id }}"
                                    >{{ item?.name }}</option
                                >
                            </select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Payment Provider</label>
                            <ng-multiselect-dropdown
                                name="payment_provider_ids"
                                formControlName="payment_provider_ids"
                                [placeholder]="'Select Provider'"
                                [settings]="dropdownSettings"
                                [data]="paymentProvidersList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Month</label>
                            <ng-multiselect-dropdown
                                name="month"
                                formControlName="month"
                                [placeholder]="'Select Month'"
                                [settings]="dropdownSettings"
                                [data]="monthList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary"
                                >Days of the Month</label
                            >
                            <ng-multiselect-dropdown
                                name="month_day"
                                formControlName="month_day"
                                [placeholder]="'Select Days'"
                                [settings]="dropdownSettings"
                                [data]="monthDayList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Days of the Week</label>
                            <ng-multiselect-dropdown
                                name="week_day"
                                formControlName="week_day"
                                [placeholder]="'Select Days'"
                                [settings]="dropdownSettings"
                                [data]="weekDayList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Time</label>
                            <div class="form-row align-items-center">
                                <div class="col-7">
                                    <ng-multiselect-dropdown
                                        name="hour"
                                        formControlName="hour"
                                        [placeholder]="'HH'"
                                        [settings]="hourSelectSettings"
                                        [data]="hourList"
                                    >
                                    </ng-multiselect-dropdown>
                                </div>
                                :
                                <div class="col-4">
                                    <input
                                        name="minute"
                                        formControlName="minute"
                                        placeholder="MM"
                                        type="text"
                                        class="form-control"
                                        [textMask]="{
                                            mask: minuteMask,
                                            guide: false,
                                            modelClean: true
                                        }"
                                        [ngClass]="{
                                            'is-invalid':
                                                form.controls.minute.touched &&
                                                !form.controls.minute.valid
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Loan Status</label>
                            <ng-multiselect-dropdown
                                name="loan_status_ids"
                                formControlName="loan_status_ids"
                                [placeholder]="'Select Status'"
                                [settings]="dropdownSettings"
                                [data]="loanStatusList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Card Status</label>
                            <ng-multiselect-dropdown
                                name="bank_card_status_ids"
                                formControlName="bank_card_status_ids"
                                [placeholder]="'Select Status'"
                                [settings]="dropdownSettings"
                                [data]="cardStatusList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Card Bank</label>
                            <ng-multiselect-dropdown
                                name="bank_card_bank_ids"
                                formControlName="bank_card_bank_ids"
                                [placeholder]="'Select Bank'"
                                [settings]="dropdownSettings"
                                [data]="bankCardList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group queue-select">
                            <label class="text-primary">Job Type</label>
                            <ng-multiselect-dropdown
                                name="user_job_type_ids"
                                formControlName="user_job_type_ids"
                                [placeholder]="'Select Type'"
                                [settings]="dropdownSettings"
                                [data]="jobTypesList"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label
                                class="text-primary"
                                for="loan_expiration_day_min"
                                >Expiration Day Min</label
                            >
                            <input
                                type="number"
                                name="loan_expiration_day_min"
                                id="loan_expiration_day_min"
                                formControlName="loan_expiration_day_min"
                                class="form-control"
                                placeholder="Expiration Day Min"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls.loan_expiration_day_min
                                            .touched &&
                                        !form.controls.loan_expiration_day_min
                                            .valid
                                }"
                            />
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label
                                class="text-primary"
                                for="loan_expiration_day_max"
                                >Expiration Day Max</label
                            >
                            <input
                                type="number"
                                name="loan_expiration_day_max"
                                id="loan_expiration_day_max"
                                formControlName="loan_expiration_day_max"
                                class="form-control"
                                placeholder="Expiration Day Max"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls.loan_expiration_day_max
                                            .touched &&
                                        !form.controls.loan_expiration_day_max
                                            .valid
                                }"
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label class="text-primary" for="payment_amount"
                                >Amount</label
                            >
                            <input
                                type="number"
                                name="payment_amount"
                                id="payment_amount"
                                formControlName="payment_amount"
                                class="form-control"
                                placeholder="Amount"
                                [ngClass]="{
                                    'is-invalid':
                                        form.controls.payment_amount.touched &&
                                        !form.controls.payment_amount.valid
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label
                                    class="text-primary"
                                    for="payment_tree_amount_id"
                                >
                                    <span>Amount Tree</span>
                                </label>
                                <a
                                    class="h5 mb-0 text-primary"
                                    title="Edit Amount Trees"
                                    [routerLink]="[
                                        '/backoffice/settings/payment-tree'
                                    ]"
                                    ><i class="fa fa-edit"></i
                                ></a>
                            </div>

                            <select
                                name="payment_tree_amount_id"
                                id="payment_tree_amount_id"
                                formControlName="payment_tree_amount_id"
                                class="form-control"
                            >
                                <option value="">Select Amount Tree</option>
                                <option
                                    *ngFor="let item of paymentTreeAmountList"
                                    value="{{ item?.id }}"
                                    >{{ item?.name }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3 align-self-end">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="enabled"
                                    name="enabled"
                                    formControlName="enabled"
                                />
                                <label
                                    class="custom-control-label text-primary"
                                    for="enabled"
                                    >Enabled</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-12 text-right">
            <a
                [routerLink]="['/backoffice/debtrecovery/queue-manager']"
                class="btn btn-outline-primary mr-3"
                >Cancel</a
            >
            <button
                type="submit"
                form="form"
                class="btn btn-primary"
                [disabled]="isSending || form.invalid"
            >
                {{ isSending ? 'Saving...' : pageTitle }}
            </button>
        </div>
    </div>
</div>
