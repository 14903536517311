<button type="button" class="btn btn-sm btn-primary" (click)="open(modal)">
    Send NEW
</button>

<ng-template #modal let-modal>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Send Message</h4>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
                type="button"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label class="mb-0" for="type_id"
                    >Message Type<sup class="text-danger">*</sup></label
                >
                <select
                    id="type_id"
                    name="type_id"
                    formControlName="type_id"
                    class="form-control"
                    required
                >
                    <option value="">Select Type</option>
                    <option *ngFor="let item of types" [value]="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-0" for="channel_id"
                    >Channel<sup class="text-danger">*</sup></label
                >
                <select
                    id="channel_id"
                    name="channel_id"
                    formControlName="channel_id"
                    class="form-control"
                    required
                    (change)="onChannelChanged()"
                >
                    <option value="">Select Channel</option>
                    <option *ngFor="let item of channels" [value]="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-0" for="provider_id">Provider</label>
                <select
                    id="provider_id"
                    name="provider_id"
                    formControlName="provider_id"
                    class="form-control"
                >
                    <option value="">Select Provider</option>
                    <option *ngFor="let item of providers" [value]="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="form-group mb-0">
                <label class="mb-0" for="content">Content</label>
                <textarea
                    name="content"
                    formControlName="content"
                    id="content"
                    class="form-control"
                ></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="isSending || form.invalid"
            >
                {{ isSending ? 'Sending...' : 'Send' }}
            </button>
        </div>
    </form>
</ng-template>
