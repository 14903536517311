import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class KpiService {
    constructor(private http: HttpClient) {}

    getConversions(query): Observable<any> {
        return this.http.get(
            environment.api_url +
                '/indicators/concessions/leads/conversions?' +
                query
        );
    }

    getRejectedByCallCenter(query): Observable<any> {
        return this.http.get(
            environment.api_url +
                '/indicators/concessions/loans/rejecteds?' +
                query
        );
    }

    getLeadsByDate(query: any = {}) {
        return this.http.get(
            environment.api_url +
                '/indicators/concessions/leads/group_by_time_unit',
            {
                params: query,
            }
        );
    }

    getEmitedByDate(query: any = {}) {
        return this.http.get(
            environment.api_url + '/indicators/concessions/loans/emited',
            {
                params: query,
            }
        );
    }

    getEmitedTotal(query = '') {
        return this.http.get(
            environment.api_url +
                '/indicators/concessions/loans/emited/total?' +
                query
        );
    }

    getEvolutionDelinquency(query = '') {
        return this.http.get(
            environment.api_url +
                '/indicators/profitability/loans/evolution/delinquency?' +
                query
        );
    }

    getProfitabilityPortfolio(query = '') {
        return this.http.get(
            environment.api_url +
                '/indicators/profitability/loans/portfolio_yearly?' +
                query
        );
    }

    getProfitabilityTotal() {
        return this.http.get(
            environment.api_url + '/indicators/profitability/loans/total'
        );
    }

    getChargesTotal(query: any = {}) {
        return this.http.get(
            environment.api_url +
                '/indicators/cashin/resource_concepts/source_types/total',
            {
                params: query,
            }
        );
    }

    getChargesEvolution(query: any = {}) {
        return this.http.get(
            environment.api_url +
                '/indicators/cashin/evolutions/source_type_by_time_unit',
            {
                params: query,
            }
        );
    }

    getBilling(query: any = {}) {
        return this.http.get(
            environment.api_url +
                '/indicators/invoice/resource_concepts/types/total',
            {
                params: query,
            }
        );
    }

    getBusinessCharges(query: any = {}) {
        return this.http.get(
            environment.api_url +
                '/indicators/cashin/resource_concepts/loan_condition/total',
            {
                params: query,
            }
        );
    }

    getBusinessInversion(query: any = {}) {
        return this.http.get(
            environment.api_url + '/indicators/concessions/loans/emited',
            {
                params: query,
            }
        );
    }

    getBusinessDifference(query: any = {}) {
        return this.http.get(
            environment.api_url +
                '/indicators/invoice/evolutions/revenue_by_time_unit',
            {
                params: query,
            }
        );
    }

    getBusinessDifferenceTotal(query: any = {}) {
        return this.http.get(
            environment.api_url + '/indicators/cashin/revenue/total',
            {
                params: query,
            }
        );
    }

    getLoans(): any {
        const data = [
            {
                status: 'New',
                units: {
                    today: 1000,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
            },
            {
                status: 'Pending Activation',
                units: {
                    today: 300,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
            },
            {
                status: 'Checking',
                units: {
                    today: 150,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
            },
            {
                status: 'Loans Activated',
                units: {
                    today: 100,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
                money: {
                    today: 30000,
                    yesterday: 133312,
                    this_week: 298281,
                    last_week: 477433,
                    this_month: 1424244,
                    last_month: 1224245,
                    year_to_date: 282882828,
                    last_year: 282882828,
                },
            },
            {
                status: 'Rejected',
                units: {
                    today: 40,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
            },
            {
                status: 'Loans in Collection',
                units: {
                    today: 1300,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
                money: {
                    today: 410150,
                    yesterday: 133312,
                    this_week: 298281,
                    last_week: 477433,
                    this_month: 1424244,
                    last_month: 1224245,
                    year_to_date: 282882828,
                    last_year: 653451,
                },
            },
            {
                status: 'Due Lines',
                units: {
                    today: 50,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
                money: {
                    today: 25775,
                    yesterday: 133312,
                    this_week: 298281,
                    last_week: 477433,
                    this_month: 1424244,
                    last_month: 1224245,
                    year_to_date: 282882828,
                    last_year: 653451,
                },
            },
            {
                status: 'Ended Lines',
                units: {
                    today: 100,
                    yesterday: 1500,
                    this_week: 3233,
                    last_week: 3154,
                    this_month: 13412,
                    last_month: 14567,
                    year_to_date: 63456,
                    last_year: 653451,
                },
                money: {
                    today: 25775,
                    yesterday: 133312,
                    this_week: 298281,
                    last_week: 477433,
                    this_month: 1424244,
                    last_month: 1224245,
                    year_to_date: 282882828,
                    last_year: 653451,
                },
            },
        ];

        return data;
    }

    getFinance(): any {
        const data = [
            {
                status: 'Invoicing',
                units: {
                    today: 254.45,
                    yesterday: 354.28,
                    this_week: 21232.23,
                    last_week: 181354.09,
                    this_month: 180345.08,
                    last_month: 75436.45,
                    year_to_date: 754363.45,
                    last_year: 653456.78,
                },
            },
            {
                status: 'Cash In',
                units: {
                    today: 1254.45,
                    yesterday: 354.28,
                    this_week: 21232.23,
                    last_week: 181354.09,
                    this_month: 180345.08,
                    last_month: 75436.45,
                    year_to_date: 754363.45,
                    last_year: 653456.78,
                },
            },
            {
                status: 'Cash Out',
                units: {
                    today: 254.45,
                    yesterday: 354.28,
                    this_week: 21232.23,
                    last_week: 181354.09,
                    this_month: 180345.08,
                    last_month: 75436.45,
                    year_to_date: 754363.45,
                    last_year: 653456.78,
                },
            },
            {
                status: 'Difference In/Out',
                units: {
                    today: 254.45,
                    yesterday: 354.28,
                    this_week: 21232.23,
                    last_week: 181354.09,
                    this_month: 180345.08,
                    last_month: 75436.45,
                    year_to_date: 754363.45,
                    last_year: 653456.78,
                },
            },
            {
                status: 'Unpaid',
                units: {
                    today: 2575.35,
                    yesterday: 354.28,
                    this_week: 21232.23,
                    last_week: 181354.09,
                    this_month: 180345.08,
                    last_month: 75436.45,
                    year_to_date: 754363.45,
                    last_year: 653456.78,
                },
            },
        ];

        return data;
    }
}
