import { Component, OnInit, ViewChild } from '@angular/core';
import { KpiService } from '../../../services/kpi.service';

import { DaterangepickerComponent } from 'ng2-daterangepicker';

declare var moment: any;

@Component({
    selector: 'app-kpis-financial-data',
    templateUrl: './kpis-financial-data.component.html',
    styleUrls: ['./kpis-financial-data.component.scss'],
})
export class KpisFinancialDataComponent implements OnInit {
    @ViewChild(DaterangepickerComponent)
    private picker: DaterangepickerComponent;

    finances: any = null;
    selectRange: any = 'today';
    selectRanges: Array<any> = [];

    calendarDates = {
        startDate: '01/01/2015',
        endDate: moment().format('DD/MM/YYYY'),
    };

    settings: any = null;

    formDate: any;
    formDateValue: any =
        moment().format('DD/MM/YYYY') + ' - ' + moment().format('DD/MM/YYYY');

    constructor(private kpis: KpiService) {}

    ngOnInit() {
        this.setCalendarSettings();
        this.getFinance();
    }

    dataToList(obj, predicate) {
        let result = {},
            key;

        for (key in obj) {
            if (key != predicate) {
                result[key] = obj[key];
            }
        }

        return result;
    }

    getFinance() {
        this.finances = this.kpis.getFinance();
        this.createSelectList(this.finances[0].units);
        // this.kpis
        //     .getLoans()
        //     .toPromise()
        //     .then(response => console.log(response));
    }

    createSelectList(units) {
        Object.keys(units).forEach((key) => {
            this.selectRanges.push(key);
        });
    }

    setCalendarSettings() {
        this.settings = {
            locale: {
                format: 'DD/MM/YYYY',
                cancelLabel: 'Clear',
            },
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            ranges: {
                All: [this.calendarDates.startDate, this.calendarDates.endDate],
                Today: [moment(), moment()],
                Yesterday: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                ],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                ],
                'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                ],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                ],
                'Year To Date': [
                    moment().startOf('year'),
                    moment().endOf('year'),
                ],
                'Last year': [
                    moment().subtract(1, 'year').startOf('year'),
                    moment().subtract(1, 'year').endOf('year'),
                ],
            },
            minDate: this.calendarDates.startDate,
            maxDate: this.calendarDates.endDate,
            startDate: moment(),
            endDate: moment(),
        };
    }

    selectedDate(value: any) {
        const start = value.start.format('DD/MM/YYYY');
        const end = value.end.format('DD/MM/YYYY');

        this.formDate = { que_start: start, que_end: end };
        this.formDateValue = start + ' - ' + end;
    }

    clearCalendar(e: any) {
        if (e.picker || !e.target.value) {
            this.updateDateRange();

            const startDate = moment().format('DD/MM/YYYY');

            this.formDate = { que_start: startDate, que_end: startDate };
            this.formDateValue = startDate + ' - ' + startDate;
        }
    }

    updateDateRange() {
        this.picker.datePicker.setStartDate(moment());
        this.picker.datePicker.setEndDate(moment());
    }
}
