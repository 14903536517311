<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">Bank Aggregation Details</h5>
    </div>
    <div class="card-body">
        <img src="assets/img/backoffice/loading.gif" width="50px" height="50px" *ngIf="loading">
        <ul class="list-group" *ngIf="!loading">
            <li *ngFor="let account of bank_owner?.accounts" class="list-group-item d-flex justify-content-between align-items-center">
                <strong>IBAN</strong><span>{{account?.iban}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Entity</strong><span>{{bank_owner?.bank?.short_name}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Full Name</strong><span>{{bank_owner?.full_name}}</span>
            </li>
        </ul>
    </div>
</div>