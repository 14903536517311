<div class="row">
    <div class="col-12 col-lg-3 col-xl-2">
        <!-- <div class="card mb-3">
            <div class="card-body">1</div>
        </div> -->
        <div class="card mb-3">
            <div *ngIf="statuses.length" class="list-group list-group-flush">
                <ng-template ngFor let-status [ngForOf]="statuses">
                    <a
                        [routerLink]="['./' + status.id]"
                        routerLinkActive
                        #rla="routerLinkActive"
                        class="list-group-item list-group-item-action py-2"
                        [ngClass]="rla.isActive ? 'active' : ''"
                        >{{ status.name }}
                    </a>
                    <div
                        *ngIf="
                            rla.isActive &&
                            +status.id === TRIAL_PROCEEDING_STATUS.SUBMITTED &&
                            showSublist &&
                            situations.length
                        "
                        class="list-group list-group-flush"
                    >
                        <a
                            *ngFor="let situation of situations"
                            [routerLink]="[
                                './' + status.id + '/' + situation.id
                            ]"
                            routerLinkActive
                            #srla="routerLinkActive"
                            [ngClass]="
                                srla.isActive
                                    ? 'active bg-secondary text-white'
                                    : 'text-muted'
                            "
                            class="list-group-item list-group-item-action py-2 pl-4 rounded-0"
                            ><small class="pl-1">{{ situation.name }}</small>
                        </a>
                    </div>
                </ng-template>
            </div>
        </div>
        <!-- <app-monitoring-create class="mb-3 w-100"></app-monitoring-create> -->
    </div>
    <div class="col-12 col-lg-9 col-xl-10">
        <router-outlet></router-outlet>
    </div>
</div>
