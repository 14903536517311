<table class="table table-striped table-responsive">
    <thead>
        <tr>
            <th>Product</th>
            <th>Created</th>
            <th>Visible</th>
            <th>Min Limit</th>
            <th>Max Limit</th>
            <th>IR</th>
            <th>Weeks</th>
            <th>Late Fee</th>
            <th>Max Limit Approved</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let product of productService.products">
            <th scope="row">{{ product?.name }}</th>
            <td>{{ product?.created_at }}</td>
            <td>
                {{ product?.visible ? 'Yes' : 'No' }}
                <button
                    class="btn btn-sm"
                    [ngClass]="{
                        'btn-success': !product?.visible,
                        'btn-warning': product?.visible
                    }"
                    [ngbTooltip]="product?.visible ? 'Disable' : 'Enable'"
                    [disabled]="isSending"
                >
                    <i
                        class="fa"
                        [ngClass]="{
                            'fa-check': !product?.visible,
                            'fa-times': product?.visible
                        }"
                        (click)="active(product.id, product?.visible)"
                    ></i>
                </button>
            </td>
            <td>{{ product?.min }}</td>
            <td>{{ product?.max }}</td>
            <td>{{ product?.interest_rate }}</td>
            <td>{{ product?.weeks }}</td>
            <td>{{ product?.late_fee_amount }}</td>
            <td>{{ product?.max_limit_approved }}</td>
            <td>
                <i
                    class="fa fa-pencil"
                    [routerLink]="
                        '/backoffice/settings/product_overview/create_product/' +
                        product?.id
                    "
                ></i>
            </td>
        </tr>
    </tbody>
</table>
