import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class WithdrawalService {

  constructor(private http:HttpClient) {}

  existsFirst(): Observable<any>{
    return this.http.get(environment.api_url + '/withdrawal/exists_first');
  }
  firstMake(formData: any): Observable<any>{
    return this.http.post(environment.api_url + '/withdrawal/first/make', formData);
  }
  make(formData: any): Observable<any>{
    return this.http.post(environment.api_url + '/withdrawal/make', formData);
  }
  list(): Observable<any>{
    return this.http.get(environment.api_url + '/withdrawal/list');
  }
}
