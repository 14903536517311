import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-presentation-show',
    templateUrl: './presentation-show.component.html',
    styleUrls: ['./presentation-show.component.scss'],
})
export class PresentationShowComponent implements OnInit {
    @Input() presentation: any;

    constructor() {}

    ngOnInit(): void {}
}
