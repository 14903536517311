import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {
    transform(value: any, findString: any, replaceString: any): any {
        const re = new RegExp(findString, 'gi');
        return value.replace(re, replaceString);
    }
}
