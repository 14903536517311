import {
    Component,
    OnInit,
    Input,
    OnChanges,
    Renderer2,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import {
    NgbModal,
    NgbModalRef,
    ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaymentBankAccountService } from '../../services/payment-bank-account.service';
import { ErrorMessageService } from '../../../shared/error-message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { Daterangepicker } from 'ng2-daterangepicker';
import { SubscriptionLike as ISubscription, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-process-payment',
    templateUrl: './process-payment.component.html',
    styleUrls: ['./process-payment.component.scss'],
})
export class ProcessPaymentComponent implements OnInit, OnChanges, OnDestroy {
    @Input() debt: any = null;
    @Input() creditline: any = null;
    @Input() pageUrl: any = null;

    @ViewChild('testFormSubmit') testFormSubmit: ElementRef;
    @Output()
    paymentDone: EventEmitter<any> = new EventEmitter();

    paymentBankCards: any = null;
    paymentBanks: any = null;
    totalCardPan: string = '';

    chooseMethods = [
        {
            id: 0,
            name: 'Bank Card Registered',
            short_name: 'card',
        },
        {
            id: 1,
            name: 'Other Bank Card',
            short_name: 'new card',
        },
        {
            id: 2,
            name: 'Bank Transfer',
            short_name: 'bank',
        },
    ];

    chooseAmount: string = 'total';
    chooseMethod: string = '0';
    selectedCard: any = '';
    paymentType: number = 1;

    amountControl: FormControl;
    cardControl: FormControl;
    cvcControl: FormControl;
    cardForm: FormGroup;
    bankForm: FormGroup;

    profileForm: FormGroup;

    redsysForm = {
        inputs: [],
    };

    readonly: boolean = true;
    amount: number = null;

    step: number = 0;

    listMonth = [
        { id: '', month: 'mm' },
        { id: '01', month: '01' },
        { id: '02', month: '02' },
        { id: '03', month: '03' },
        { id: '04', month: '04' },
        { id: '05', month: '05' },
        { id: '06', month: '06' },
        { id: '07', month: '07' },
        { id: '08', month: '08' },
        { id: '09', month: '09' },
        { id: '10', month: '10' },
        { id: '11', month: '11' },
        { id: '12', month: '12' },
    ];
    listYear = [];
    currentTime = new Date();
    totalDate;

    sendingPayment: boolean = false;
    addingCard: boolean = false;
    modalReference: NgbModalRef;

    newCardId: number = null;

    iframeUrl: any = '';

    i = 0;

    paymentId;
    paymentProviders;
    paymentResources;

    singlePicker = {
        locale: { format: 'DD/MM/YYYY' },
        singleDatePicker: true,
        alwaysShowCalendars: true,
        autoUpdateInput: false,
        autoApply: true,
    };

    querySubscriber: ISubscription;

    constructor(
        private modalService: NgbModal,
        private renderer2: Renderer2,
        private paymentBankAccount: PaymentBankAccountService,
        private errorMessageService: ErrorMessageService,
        private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.createYearsList();
        this.createBanksList();
        this.createProviderList();
        this.createResourcesList();

        console.log('Payment Init');

        const queryParams$ = this.activatedRoute.queryParams;
        this.querySubscriber = queryParams$.subscribe(({ payment_status }) => {
            if (payment_status) {
                switch (payment_status) {
                    case 'ok':
                        this.errorMessageService.showSuccess(
                            'Pyment DONE succesfully!'
                        );
                        break;
                    case 'ko':
                        this.errorMessageService.showError('Pyment REFUSED!');
                        break;
                }
            }
        });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
    }

    ngOnChanges() {
        if (this.debt) {
            this.amountControl = new FormControl('', [
                Validators.required,
                Validators.min(0.01),
                Validators.max(this.debt.debt_left),
                Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
            ]);
            this.setTotalAmount(this.debt);
        }

        if (this.creditline) {
            this.getBankCardList(this.creditline.user_id);
            this.cardControl = new FormControl('', [Validators.required]);
            this.cvcControl = new FormControl('', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(4),
            ]);

            this.cardForm = new FormGroup({
                cardNumber: new FormControl('', [
                    Validators.required,
                    Validators.minLength(16),
                    Validators.maxLength(16),
                    Validators.pattern(/^\d{16}$/),
                ]),
                cardMonth: new FormControl('', [Validators.required]),
                cardYear: new FormControl('', [Validators.required]),
                cardCvv: new FormControl('', [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(4),
                ]),
                // paymentProvider: new FormControl('', [Validators.required]),
            });

            this.bankForm = new FormGroup({
                collectionDate: new FormControl('', [Validators.required]),
                bankAccount: new FormControl('', [Validators.required]),
                paymentResource: new FormControl('', [Validators.required]),
            });
        }
    }

    open(content) {
        this.modalReference = this.modalService.open(content, { size: 'lg' });

        this.modalReference.result.then(
            (result) => {
                this.resetData(result);
            },
            (reason) => {
                if (reason === ModalDismissReasons.ESC) {
                    this.resetData(false);
                }
            }
        );
        // this.testFormEl.nativeElement.submit();
    }

    handleChange(event) {
        let value = event.target.value || event.srcElement.value;
        let onElement = this.renderer2.selectRootElement('#amountControl');

        switch (value) {
            case 'total':
                this.readonly = true;
                onElement.blur();
                this.setTotalAmount(this.debt);
                break;
            case 'partial':
                this.readonly = false;
                onElement.focus();
                break;
        }
    }

    setTotalAmount(debt) {
        this.amount = debt.debt_left;
    }

    changeStep(direction) {
        if (this.step >= 0) {
            this.step = direction === 'next' ? this.step + 1 : this.step - 1;
        }
    }

    getBankCardList(user_id) {
        this.paymentBankAccount
            .getListByUserId(user_id)
            .subscribe((paymentBankCard) => {
                if (paymentBankCard.length) {
                    this.paymentBankCards = paymentBankCard;
                }
            });
    }

    createYearsList() {
        let j = 0;
        for (
            let i = this.currentTime.getFullYear();
            i <= this.currentTime.getFullYear() + 10;
            i++
        ) {
            this.listYear[j] = {
                full: i,
                short: i.toString().substr(-2),
            };
            j++;
        }
    }

    setTotalCardPan(e, id) {
        if (e) {
            switch (id) {
                case 1:
                    this.totalCardPan =
                        this.cardForm.value.cardNumber +
                        ', ' +
                        this.cardForm.value.cardMonth +
                        '/' +
                        this.cardForm.value.cardYear +
                        ', ' +
                        this.cardForm.value.cardCvv;
                    this.totalDate = this.setDate();
                    break;
                case 2:
                    let bank = JSON.parse(this.bankForm.value.bankAccount);
                    this.totalCardPan = bank.bankName + ' - ' + bank.bankIban;
                    this.totalDate = this.bankForm.value.collectionDate;
                    break;
                default:
                    if (typeof e === 'string') {
                        let card = JSON.parse(e);
                        this.totalCardPan =
                            card.cardPan +
                            ', ' +
                            card.cardMonth +
                            '/' +
                            card.cardYear;
                        this.totalDate = this.setDate();
                    }

                    break;
            }
        }
    }

    setDate() {
        const options = {
            month: '2-digit' as '2-digit',
            day: '2-digit' as '2-digit',
            year: 'numeric' as 'numeric',
        };
        let n = this.currentTime.toLocaleDateString('es-ES', options);

        return n;
    }

    createBanksList() {
        this.paymentBankAccount.getListBankAccounts().subscribe((banks) => {
            if (banks.length) {
                this.paymentBanks = banks;
            }
        });
    }

    createProviderList() {
        this.paymentBankAccount.getListProviders().subscribe((providers) => {
            if (providers.length) {
                this.paymentProviders = providers;
            }
        });
    }

    createResourcesList() {
        this.paymentBankAccount.getListResources().subscribe((resources) => {
            if (resources.length) {
                this.paymentResources = resources;
            }
        });
    }

    makePayment() {
        this.sendingPayment = true;

        if (this.chooseMethod === '0' && Number(this.paymentType) === 1) {
            let sendData = {
                amount: +this.amount,
                loan_id: this.creditline.id,
                bank_card_id: Number(JSON.parse(this.cardControl.value).cardId),
                url_ok: this.pageUrl + '?payment_status=ok',
                url_ko: this.pageUrl + '?payment_status=ko',
                cvc: this.cvcControl.value,
            };

            this.paymentBankAccount
                .makePaymentBankCardSecure(sendData)
                .subscribe(
                    (data) => {
                        if (data.redirection_url !== '') {
                            setTimeout(
                                function (self) {
                                    self.changeStep('next');
                                    self.sendingPayment = false;
                                },
                                600,
                                this
                            );

                            let iframeUrl = data.redirection_url;
                            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                                iframeUrl
                            );

                            this.paymentId = data.id;
                            this.startTime();
                        }
                    },
                    (error) => {
                        if (error.error.error) {
                            setTimeout(
                                function (self) {
                                    self.sendingPayment = false;
                                },
                                600,
                                this
                            );
                            this.errorMessageService.showError(
                                error.error.error.show_message
                            );
                        }
                    }
                );
        }

        if (this.chooseMethod === '0' && Number(this.paymentType) === 2) {
            let sendData = {
                amount: +this.amount,
                loan_id: this.creditline.id,
                bank_card_id: Number(JSON.parse(this.cardControl.value).cardId),
            };

            this.paymentBankAccount.makePaymentBankCard(sendData).subscribe(
                (data) => {
                    if (data) {
                        setTimeout(
                            function (self) {
                                self.errorMessageService.showSuccess(
                                    'Pyment DONE succesfully!'
                                );
                                self.sendingPayment = false;
                                self.modalReference.close(true);
                            },
                            600,
                            this
                        );
                    }
                },
                (error) => {
                    if (error.error.error) {
                        this.errorMessageService.showError(
                            error.error.error.show_message
                        );
                        setTimeout(
                            function (self) {
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );
                    }
                }
            );
        }

        if (this.chooseMethod === '1') {
            let sendData = {
                pan: +this.cardForm.value.cardNumber,
                expiration_year: +this.cardForm.value.cardYear,
                expiration_month:
                    +this.cardForm.value.cardMonth < 10 &&
                    ('' + this.cardForm.value.cardMonth).length < 2
                        ? `0${this.cardForm.value.cardMonth}`
                        : `${this.cardForm.value.cardMonth}`,
                cvc: this.cardForm.value.cardCvv,
                loan_id: +this.creditline.id,
                amount: +this.amount,
                url_ok: this.pageUrl + '?payment_status=ok',
                url_ko: this.pageUrl + '?payment_status=ko',
            };

            this.paymentBankAccount.makePaymentNewCard(sendData).subscribe(
                (data) => {
                    if (data.redirection_url !== '') {
                        setTimeout(
                            function (self) {
                                self.changeStep('next');
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );

                        let iframeUrl = data.redirection_url;
                        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                            iframeUrl
                        );

                        this.paymentId = data.id;
                        this.startTime();
                    }
                },
                (error) => {
                    if (error.error.error) {
                        setTimeout(
                            function (self) {
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );
                        this.errorMessageService.showError(
                            error.error.error.show_message
                        );
                    }
                }
            );
        }

        if (this.chooseMethod === '2') {
            let sendData = {
                amount: this.amount,
                loan_id: this.creditline.id,
                company_bank_account_id: JSON.parse(
                    this.bankForm.value.bankAccount
                ).bankId,
                date: this.bankForm.value.collectionDate
                    .toString()
                    .replace(/\//g, '-'),
                payment_resource_id: this.bankForm.value.paymentResource,
            };

            this.paymentBankAccount.makePaymentBankAccount(sendData).subscribe(
                (data) => {
                    if (data === true) {
                        setTimeout(
                            function (self) {
                                self.errorMessageService.showSuccess(
                                    'Pyment DONE succesfully!'
                                );
                                self.sendingPayment = false;
                                self.bankForm.reset({
                                    collectionDate: '',
                                    bankAccount: '',
                                });
                                self.modalReference.close(true);
                            },
                            600,
                            this
                        );
                    } else {
                        this.errorMessageService.showError('Pyment REFUSED!');
                        setTimeout(
                            function (self) {
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );
                    }
                },
                (error) => {
                    if (error.error.error) {
                        setTimeout(
                            function (self) {
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );
                        this.errorMessageService.showError(
                            error.error.error.show_message
                        );
                    }
                }
            );
        }
    }

    setUrlData(form) {
        let formInputs = '';

        Object.keys(form.field).forEach((key) => {
            let item = `<input type="text" name="${key}" value="${form.field[key]}">`;
            formInputs += item;
        });

        let template = `
      <!DOCTYPE html>
      <html lang="es">
        <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
          <title>Redsys Form</title>
          <style>.redsyForm{display: none}</style>
        </head>
        <body>
          <h3 style="font-family: sans-serif; text-align: center;">Wait Please. Sending data to Redsys...</h3>
          <form name="redsysForm" class="redsyForm" enctype="application/x-www-form-urlencoded" action="${form.action}" method="${form.method}">
            ${formInputs}
            <button class="btn" type="submit">Submit</button>
          </form>
          <script>
            const sendForm = () => {
              document.redsysForm.submit();
            };
            window.onload = sendForm;
          </script>
        </body>
      </html>`;

        return 'data:text/html;charset=utf-8,' + encodeURI(template);
    }

    addCard() {
        this.addingCard = true;

        let cardData = this.cardForm.value;

        let sendData = {
            pan: cardData.cardNumber,
            expiration_year: cardData.cardYear,
            expiration_month: cardData.cardMonth,
            cvc: cardData.cardCvv,
            user_id: this.creditline.user_id,
        };

        this.paymentBankAccount.addCard(sendData).subscribe(
            (data) => {
                if (data.card.id) {
                    this.newCardId = data.card.id;
                    setTimeout(
                        function (self) {
                            self.addingCard = false;
                            self.changeStep('next');
                            self.setTotalCardPan(true, 1);
                        },
                        600,
                        this
                    );
                }
            },
            (error) => {
                if (error.error.error) {
                    setTimeout(
                        function (self) {
                            self.addingCard = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                }
            }
        );
    }

    resetData(emit) {
        this.setTotalAmount(this.debt);
        this.chooseAmount = 'total';
        this.chooseMethod = '0';
        this.step = 0;
        this.newCardId = null;
        this.cardControl.reset('');
        this.cvcControl.reset('');
        this.selectedCard = '';

        if (emit) {
            this.paymentDone.emit(true);
        }
    }

    startTime() {
        this.paymentBankAccount.checkStatus(this.paymentId).subscribe(
            (data) => {
                if (data) {
                    this.i++;
                    if (data != 'processing' && this.i < 41) {
                        if (data === 'done') {
                            setTimeout(
                                function (self) {
                                    self.errorMessageService.showSuccess(
                                        'Pyment DONE succesfully!'
                                    );
                                    self.sendingPayment = false;
                                    self.modalReference.close(true);
                                    this.i = 0;
                                },
                                600,
                                this
                            );
                        } else {
                            this.errorMessageService.showError(
                                'Pyment REFUSED!'
                            );
                            this.sendingPayment = false;
                            this.step = 2;
                            this.i = 0;
                        }
                    } else {
                        let t = setTimeout(
                            function (self) {
                                self.startTime();
                            },
                            3000,
                            this
                        );
                    }
                }
            },
            (error) => {
                if (error.error.error) {
                    setTimeout(
                        function (self) {
                            self.sendingPayment = false;
                        },
                        600,
                        this
                    );
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                }
            }
        );
    }

    // selectedDateSingle(value: any) {
    //     this.bankForm.controls.collectionDate.setValue(
    //         value.end.format('DD/MM/YYYY')
    //     );
    // }

    sendRedsys(e) {
        e.preventDefault();
    }

    onApplyDaterangepicker(e) {
        const picker = e.picker || null;
        if (picker) {
            this.bankForm.controls.collectionDate.setValue(
                picker.startDate.format('DD/MM/YYYY')
            );
        }
    }
}
