import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { OutputBlockData, OutputData } from '@editorjs/editorjs';
import { TEMPLATE_ACTIVATION_ENUM } from '../../shared/enums/template-activation.enum';

@Injectable()
export class EmailService {
    constructor(private http: HttpClient) {}
    private editorData$ = new BehaviorSubject<OutputData>(null);
    private messagesTypes = [];
    private messagesTypes$ = new BehaviorSubject<any>([]);
    private messagesSendConfigs = [];
    private messagesSendConfigs$ = new BehaviorSubject<any>([]);

    getMessagesRequest(params): Observable<any> {
        return this.http.get(environment.api_url + '/messages', {
            params,
        });
    }

    sendMessageRequest(params): Observable<any> {
        return this.http.post(environment.api_url + '/messages/send', params);
    }

    getByLeadId(lead_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/lead/${lead_id}/email/list`
        );
    }
    getEmailType(): Observable<any> {
        return this.http.get(environment.api_url + `/email_type/list`);
    }

    getTemplates(query): Observable<any> {
        return this.http.get(environment.api_url + '/templates', {
            params: query,
        });
    }

    getTemplate(id): Observable<any> {
        return this.http.get(environment.api_url + `/templates/${id}`);
    }

    getTemplateDraft(id): Observable<any> {
        return this.http.get(environment.api_url + `/templates/${id}/draft`);
    }

    getTemplateVariables(query): Observable<any> {
        return this.http.get(
            environment.api_url + `/templates/variables`,
            query
        );
    }

    createTemplate(data): Observable<any> {
        return this.http.post(environment.api_url + '/templates', data);
    }

    editTemplate(id, data): Observable<any> {
        return this.http.post(environment.api_url + '/templates/' + id, data);
    }

    createVarible(data): Observable<any> {
        return this.http.post(
            environment.api_url + '/templates/variables',
            data
        );
    }

    editVarible(id, data): Observable<any> {
        return this.http.post(
            environment.api_url + '/templates/variables/' + id,
            data
        );
    }

    emailTemplatesActivation(
        id: number,
        type: TEMPLATE_ACTIVATION_ENUM,
        active = false
    ): Observable<any> {
        let activationUrl = '';

        switch (type) {
            case TEMPLATE_ACTIVATION_ENUM.TEMPLATE_VARIABLE:
                activationUrl = 'templates/variables/';
                break;
            case TEMPLATE_ACTIVATION_ENUM.MESSAGE_TYPE:
                activationUrl = 'messages/types/';
                break;
            default:
                activationUrl = 'templates/';
                break;
        }

        return this.http.post(
            environment.api_url +
                `/${activationUrl}${id}/${active ? 'disable' : 'enable'}`,
            {}
        );
    }

    emailTemplatesPublishing(
        id: number,
        type: TEMPLATE_ACTIVATION_ENUM
    ): Observable<any> {
        let publishingUrl = '';

        switch (type) {
            case TEMPLATE_ACTIVATION_ENUM.TEMPLATE_VARIABLE:
                publishingUrl = 'templates/variables/';
                break;
            case TEMPLATE_ACTIVATION_ENUM.MESSAGE_TYPE:
                publishingUrl = 'messages/types/';
                break;
            default:
                publishingUrl = 'templates/';
                break;
        }

        return this.http.post(
            environment.api_url + `/${publishingUrl}${id}/publish`,
            {}
        );
    }

    getMessagesTypesRequest(): Observable<any> {
        return this.http.get(environment.api_url + '/messages/types');
    }

    createMessagesType({ params }): Observable<any> {
        return this.http.post(environment.api_url + '/messages/types', params);
    }

    editMessagesType({ id, params }): Observable<any> {
        return this.http.post(
            environment.api_url + '/messages/types/' + id,
            params
        );
    }

    getMessagesTypes(): Observable<any> {
        return this.messagesTypes$;
    }

    setMessagesTypes(data): void {
        if (!this.messagesTypes.length) {
            this.messagesTypes = data;
        }

        this.messagesTypes$.next(data);
    }

    updateMessagesTypes(item) {
        this.messagesTypes.forEach((element, index) => {
            if (+element?.id === +item?.id) {
                this.messagesTypes[index] = { ...element, ...item };
            }
        });

        this.setMessagesTypes(this.messagesTypes);
    }

    addMessageType(item) {
        this.messagesTypes.push(item);
        this.setMessagesTypes(this.messagesTypes);
    }

    clearMessagesTypes() {
        this.messagesTypes = [];
    }

    getMessagesSendConfigsRequest(params = {}): Observable<any> {
        return this.http.get(environment.api_url + '/messages/send_configs', {
            params,
        });
    }

    createMessagesSendConfig({ params }): Observable<any> {
        return this.http.post(
            environment.api_url + '/messages/send_configs',
            params
        );
    }

    editMessagesSendConfig({ id, params }): Observable<any> {
        return this.http.post(
            environment.api_url + '/messages/send_configs/' + id,
            params
        );
    }

    getMessagesSendConfigs(): Observable<any> {
        return this.messagesSendConfigs$;
    }

    setMessagesSendConfigs(data): void {
        if (!this.messagesSendConfigs.length) {
            this.messagesSendConfigs = data;
        }

        this.messagesSendConfigs$.next(data);
    }

    updateMessagesSendConfigs(item) {
        this.messagesSendConfigs.forEach((element, index) => {
            if (+element?.id === +item?.id) {
                this.messagesSendConfigs[index] = { ...element, ...item };
            }
        });

        this.setMessagesSendConfigs(this.messagesSendConfigs);
    }

    addMessagesSendConfig(item) {
        this.messagesSendConfigs.push(item);
        this.setMessagesSendConfigs(this.messagesSendConfigs);
    }

    clearMessagesSendConfigs() {
        this.messagesSendConfigs = [];
    }

    getEvents(): Observable<any> {
        return this.http.get(environment.api_url + '/events');
    }

    getStatuses(): Observable<any> {
        return this.http.get(environment.api_url + '/messages/status');
    }

    getProviders(): Observable<any> {
        return this.http.get(environment.api_url + '/messages/providers');
    }

    getProvidersStatuses(query): Observable<any> {
        return this.http.get(
            environment.api_url + '/messages/provider-status?' + query
        );
    }

    createProvidersStatuses({ params }): Observable<any> {
        return this.http.post(
            environment.api_url + '/messages/provider-status',
            params
        );
    }

    editProvidersStatuses({ id, params }): Observable<any> {
        return this.http.post(
            environment.api_url + '/messages/provider-status/' + id,
            params
        );
    }

    getProvidersByChannel(id): Observable<any> {
        return this.http.get(
            environment.api_url + '/messages/channels/' + id + '/providers'
        );
    }

    getChannels(): Observable<any> {
        return this.http.get(environment.api_url + '/messages/channels');
    }

    getParsedHtml(data: OutputData, preview = false): string {
        return this._parseHtml(data, preview);
    }

    saveEditorData(data: OutputData): void {
        this.editorData$.next(data);
    }

    getEditorData(): Observable<OutputData> {
        return this.editorData$;
    }

    private _parseHtml({ blocks }: OutputData, preview: boolean): string {
        let convertedHtml = '';

        if (blocks) {
            blocks.map((block) => {
                switch (block.type) {
                    case 'header':
                        convertedHtml += this._checkLinks(
                            `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
                        );
                        break;
                    case 'paragraph':
                        convertedHtml += this._checkLinks(
                            `<p>${block.data.text}</p>`
                        );
                        break;
                    case 'delimiter':
                        convertedHtml += '<hr />';
                        break;
                    case 'image':
                        convertedHtml += this._imageTemplate(block, preview);
                        break;
                    case 'list':
                        convertedHtml +=
                            '<' +
                            (block.data.style === 'ordered' ? 'ol' : 'ul') +
                            '>';
                        block.data.items.forEach((li) => {
                            convertedHtml += this._checkLinks(`<li>${li}</li>`);
                        });
                        convertedHtml +=
                            '</' +
                            (block.data.style === 'ordered' ? 'ol' : 'ul') +
                            '>';
                        break;
                    default:
                        console.log('Unknown block type', block.type);
                        break;
                }
            });
        }

        return convertedHtml;
    }

    private _imageTemplate(block: OutputBlockData, preview: boolean): string {
        const style = block.data.stretched ? 'b_img_full' : 'b_img_fluid';
        const title = block.data.caption || 'image';
        const caption = block.data.caption
            ? `<p><small><em>${block.data.caption}</em></small></p>`
            : '';

        return `<img class="${style}" src="${
            preview ? block.data.link : block.data.url
        }" title="${title}" alt="${title}" width="${
            block.data.width
        }" height="${block.data.height}"/>${caption}`;
    }

    private _checkLinks(text: string) {
        return text.replace(/http:\/\/{{/g, '{{');
    }
}
