import { Component, OnInit, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DebtRecoveryService } from '../../../services/debt-recovery.service';
import { PlatformLocation } from '@angular/common';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

declare var moment: any;

@Component({
    selector: 'app-collection-incidence',
    templateUrl: './collection-incidence.component.html',
    styleUrls: ['./collection-incidence.component.scss']
})
export class CollectionIncidenceComponent implements OnInit, OnDestroy {
    @ViewChildren('datePicker') appdaterangepickers: QueryList<any>;
    status: string;
    pageNames: any = {
        all: 'All',
        payment_commitment: 'Payment Commitments',
        payment_undone: 'Payment Undone',
        recall: 'Recall',
        payment_done: 'Payment Done',
        no_contact: 'No Contact',
        unlocatable: 'Unlocatable',
        payment_refused: 'Payment Refused',
        payment_incident: 'Incident'
    };
    selectedPageName = '';

    calendarDates: any = {};

    promises = [];

    paramsFilter = new Map();
    pageFilter: string;
    urlParams: string;

    startData: any = {
        date_start: moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        loan_code: '',
        full_name: '',
        personalid: '',
        count_quota_unpaid: '',
        amount_unpaid: '',
        amount_paid: '',
        payment_source_id: '',
        tracking_type_incident_id: '',
        card_status_id: '',
        job_type_id: '',
        account_manager_id: '',
        page: 0
    };

    resetFilters: boolean = false;

    filters: any = {};

    filterLists = {
        cardStatus: {
            link: '/bank_card/status/list',
            list: <any>null
        },
        traking: {
            link: '/tracking/filter/list',
            list: <any>null
        },
        jobRole: {
            link: '/lead/info/request',
            list: <any>null
        },
        paymentSource: {
            link: '/payment/source/list',
            list: <any>null
        }
    };

    incidentsList;
    users: any;

    requestParams: string = '';

    loading: boolean = true;

    statusSubscriber;
    routerSubscriber;

    routerChanges: any = {
        query: false,
        status: false
    };

    init;

    popState = false;

    queryString: string = '';

    withStartDate: boolean = true;
    exportFile = null;
    exportLink = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private debtRecovery: DebtRecoveryService,
        private location: PlatformLocation,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.init = true;

        this.statusSubscriber = this.activatedRoute.params.subscribe(({ status }) => {
            if (status) {
                this.authService.getFilteredUsersList(['admin', 'am', 'amc', 'bum']).then(data => (this.users = data));
                this.setPageTitleOnStatusChange(status);
                this.getFiltersSelectsOnStatusChange();
            }
        });

        this.location.onPopState(locationChange => {
            if (locationChange && !this.init) {
                this.routerSubscriber = this.router.events
                    .pipe(filter(event => event instanceof NavigationEnd))
                    .subscribe(e => {
                        if (e) {
                            this.routerSubscriber.unsubscribe();
                            this.getDataOnPopState();
                        }
                    });
            }
        });

        this.getDataOnInit();
    }

    ngOnDestroy() {
        this.statusSubscriber.unsubscribe();

        this.init = true;

        if (this.routerSubscriber) {
            this.routerSubscriber.unsubscribe();
        }
    }

    getDataOnInit() {
        const query = this.activatedRoute.snapshot.queryParams;
        const queryLength = Object.keys(query).length > 0;

        // Set Init Filters
        this.setInitFilters(query);
        // Create Query String
        this.createQueryString(this.filters);
        // Get datalist
        this.getincidentsList();

        if (!queryLength) {
            // Push Query String to history.
            // Navigates while replacing the current state in history.
            this.pushFiltersToHistory(this.filters, true);
        }
    }

    getDataOnNavClick() {
        const query = this.activatedRoute.snapshot.queryParams;

        // Set Init Filters
        this.setInitFilters(query);
        // Create Query String
        this.createQueryString(this.filters);
        // Get datalist
        this.getincidentsList();
        // Push Query String to history.
        // Navigates while replacing the current state in history.
        this.pushFiltersToHistory(this.filters, true);
    }

    getDataOnPopState() {
        const query = this.activatedRoute.snapshot.queryParams;
        // Set Init Filters
        this.setInitFilters(query);
        // Create Query String
        this.createQueryString(this.filters);
        // Get datalist
        this.getincidentsList();
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Get datalist
        this.getincidentsList();

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };

        this.checkDateSettings(this.filters.date_start, this.filters.date_end);
        // Create Query String
        this.createQueryString(this.filters);
        // Get datalist
        this.getincidentsList();

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getDataOnPageChange(page) {
        this.filters.page = page;

        this.checkDateSettings(this.filters.date_start, this.filters.date_end);
        // Create Query String
        this.createQueryString(this.filters);
        // Get datalist
        this.getincidentsList();

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    getFiltersSelectsOnStatusChange() {
        this.resetFilters = true;

        this.getFiltersList();

        Promise.all(this.promises).then(() => {
            this.resetFilters = false;
        });
    }

    setPageTitleOnStatusChange(status) {
        this.selectedPageName = status;
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };

        // Chack date filter
        this.checkDateSettings(this.filters.date_start, this.filters.date_end);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(key => !clearFilters[key] && delete clearFilters[key]);

        this.queryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    checkDateSettings(date_start, date_end) {
        this.withStartDate = !!(date_start && date_end);
    }

    getincidentsList() {
        this.loading = true;
        this.debtRecovery
            .getIncidentList(this.selectedPageName, this.queryString)
            .toPromise()
            .then(response => {
                this.incidentsList = response;
                this.loading = false;
                if (this.init) {
                    this.init = false;
                }
            });
    }

    getFiltersList() {
        Object.keys(this.filterLists).forEach(key => {
            this.promises.push(
                this.debtRecovery
                    .getFilters(this.filterLists[key])
                    .toPromise()
                    .then(response => {
                        this.filterLists[key].list = response;
                    })
            );
        });
    }

    goto(lead_id) {
        if (this.routerSubscriber) {
            this.routerSubscriber.unsubscribe();
        }
        if (lead_id) {
            this.router.navigate([`/backoffice/loans/overview/${lead_id}/loanInfo/`, lead_id]);
        }
    }

    gotoIncident(e, dueStatus) {
        e.preventDefault();
        if (dueStatus) {
            this.router.navigate([`/backoffice/debtrecovery/collection_incidence/`, dueStatus]).then(() => {
                this.getDataOnNavClick();
            });
        }
    }

    formatDate(stringDate) {
        let formatDateString;
        if (stringDate) {
            formatDateString = new Date(stringDate.replace(/-/g, '/'));
        }
        return formatDateString;
    }

    getExportLink(e) {
        e.preventDefault();

        this.debtRecovery.getIncidenceExport(this.queryString).subscribe(exel => {
            let file = new Blob([exel], {
                type: 'application/vnd.ms-excel'
            });
            this.exportFile = URL.createObjectURL(file);
            this.exportLink = this.sanitizer.bypassSecurityTrustUrl(this.exportFile);

            saveAs(file, 'collection_incidences.xlsx');
        });
    }
}
