<div *appHasPermissions="permissionsArray">
    <div class="row">
        <div class="col-12">
            <h2 class="mb-0">{{ title }}</h2>
        </div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div *ngIf="!loading" class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th *ngIf="selectedPageName === 'asnef_included'">
                        Status Updated At
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'all' &&
                            selectedPageName !== 'customer' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        {{ dateName }} Date
                    </th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                    <th
                        *ngIf="
                            selectedPageName === 'customer' ||
                            selectedPageName === 'asnef_included'
                        "
                    >
                        Asnef Status
                    </th>
                    <th *ngIf="selectedPageName === 'asnef_included'">Loan</th>
                    <th *ngIf="selectedPageName === 'asnef_included'">
                        Debt Left
                    </th>
                    <th *ngIf="selectedPageName === 'asnef_included'">
                        Quotas Unpaid
                    </th>
                    <th *ngIf="selectedPageName === 'asnef_included'">
                        Exclude
                    </th>
                    <th *ngIf="selectedPageName === 'customer'">
                        Repeat Customer
                    </th>
                    <th *ngIf="selectedPageName !== 'asnef_included'">Email</th>
                    <th *ngIf="selectedPageName !== 'asnef_included'">
                        Mobile Phone
                    </th>
                    <th *ngIf="selectedPageName !== 'asnef_included'">
                        Other Phone
                    </th>
                    <th *ngIf="selectedPageName !== 'asnef_included'">
                        Work Phone
                    </th>
                    <th *ngIf="selectedPageName !== 'asnef_included'">
                        Additional Phone
                    </th>
                    <th *ngIf="selectedPageName !== 'asnef_included'">
                        Gender
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'customer' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        Customer
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'dropout' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        Drop Out
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'blacklisted' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        Blacklisted
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'all' &&
                            selectedPageName !== 'customer' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        Who
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td *ngIf="selectedPageName === 'asnef_included'">
                        <app-daterange-filter
                            fieldName="last_status_at"
                            [start]="filters.last_status_at_start"
                            [end]="filters.last_status_at_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'all' &&
                            selectedPageName !== 'customer' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        <app-daterange-filter
                            fieldName="date"
                            [start]="filters.date_start"
                            [end]="filters.date_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.personalid"
                            placeholder="DNI / NIE"
                            name="personalid"
                        />
                    </td>
                    <td *ngIf="selectedPageName === 'asnef_included'">
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.name"
                            placeholder="Customer name"
                            name="name"
                        />
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.full_name"
                            placeholder="Customer name"
                            name="full_name"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName === 'customer' ||
                            selectedPageName === 'asnef_included'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.status_id"
                            name="status_id"
                        >
                            <option value="">Select</option>
                            <option
                                *ngFor="let item of asnef_statuses"
                                [value]="item?.id"
                            >
                                {{ item?.name }}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="selectedPageName === 'asnef_included'">
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.loan_code_str"
                            placeholder="Code"
                            name="loan_code_str"
                        />
                    </td>
                    <td *ngIf="selectedPageName === 'asnef_included'">
                        <!-- [start]="filters.debt_left_start"
                            [end]="filters.debt_left_end" -->
                        <app-dropdown-range
                            [start]="filters.debt_left_start"
                            [end]="filters.debt_left_end"
                            (changeRange)="onChangeRange($event)"
                        ></app-dropdown-range>
                    </td>
                    <td *ngIf="selectedPageName === 'asnef_included'"></td>
                    <td *ngIf="selectedPageName === 'asnef_included'"></td>
                    <td *ngIf="selectedPageName === 'customer'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.repeat_customer"
                            name="repeat_customer"
                        >
                            <option value="">Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.email"
                            placeholder="Email"
                            name="email"
                        />
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.mobile_phone"
                            placeholder="Mobile phone"
                            name="mobile_phone"
                        />
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.other_phone"
                            placeholder="Other phone"
                            name="other_phone"
                        />
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.work_phone"
                            placeholder="Work phone"
                            name="work_phone"
                        />
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.additional_phone"
                            placeholder="Additional phone"
                            name="additional_phone"
                        />
                    </td>
                    <td *ngIf="selectedPageName !== 'asnef_included'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.sex_id"
                            name="sex_id"
                        >
                            <option value="">Select</option>
                            <option
                                *ngFor="let gender of genders"
                                value="{{ gender?.id }}"
                            >
                                {{ gender?.name }}
                            </option>
                        </select>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'customer' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.is_customer"
                            name="is_customer"
                        >
                            <option value="">Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'dropout' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.dropout"
                            name="dropout"
                        >
                            <option value="">Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'blacklisted' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.blacklisted"
                            name="blacklisted"
                        >
                            <option value="">Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'all' &&
                            selectedPageName !== 'customer' &&
                            selectedPageName !== 'asnef_included'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            [(ngModel)]="filters.who"
                            name="who"
                        >
                            <option value="">Select</option>
                            <option
                                *ngFor="let admin of admins?.actived"
                                value="{{ admin?.id }}"
                            >
                                {{ admin?.name }}
                            </option>
                            <option *ngIf="admins?.blocked.length > 0" disabled>
                                ──────────
                            </option>
                            <option
                                *ngFor="let admin of admins?.blocked"
                                value="{{ admin?.id }}"
                            >
                                {{ admin?.name }}
                            </option>
                        </select>
                    </td>
                    <td></td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td *ngIf="selectedPageName === 'asnef_included'">
                            {{ item?.last_status_at }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'all' &&
                                selectedPageName !== 'customer' &&
                                selectedPageName !== 'asnef_included'
                            "
                        >
                            <span *ngIf="selectedPageName === 'blacklisted'">{{
                                item?.blacklisted_date
                            }}</span>
                            <span *ngIf="selectedPageName === 'dropout'">{{
                                item?.dropout_date
                            }}</span>
                        </td>
                        <td>
                            <span
                                *ngIf="selectedPageName === 'asnef_included'"
                                >{{ item?.user?.personalid || '-' }}</span
                            >
                            <span
                                *ngIf="selectedPageName !== 'asnef_included'"
                                >{{ item?.personalid }}</span
                            >
                        </td>
                        <td>
                            <span *ngIf="selectedPageName === 'asnef_included'">
                                <a
                                    href="#"
                                    (click)="openUser($event, item?.loan_id)"
                                >
                                    {{ item?.user?.name }}
                                    {{ item?.user?.first_surname }}
                                    {{ item?.user?.second_surname }}
                                </a>
                            </span>
                            <span *ngIf="selectedPageName !== 'asnef_included'">
                                {{ item?.name }} {{ item?.first_surname }}
                                {{ item?.second_surname }}
                            </span>
                        </td>
                        <td
                            *ngIf="
                                selectedPageName === 'customer' ||
                                selectedPageName === 'asnef_included'
                            "
                        >
                            {{ item?.status?.name }}
                        </td>
                        <td *ngIf="selectedPageName === 'asnef_included'">
                            {{ item?.loan?.code_str }}
                        </td>
                        <td *ngIf="selectedPageName === 'asnef_included'">
                            {{ item?.loan?.debt_left | currency: 'EUR' }}
                        </td>
                        <td *ngIf="selectedPageName === 'asnef_included'">
                            {{ item?.loan?.count_quota_unpaid }}
                        </td>
                        <td *ngIf="selectedPageName === 'asnef_included'">
                            <app-asnef-exlude
                                *ngIf="item?.status?.name === 'Included'"
                                [record_id]="item?.id"
                                [reasonsList]="reasonsList"
                                (userExluded)="onUserExluded($event)"
                            ></app-asnef-exlude>
                        </td>
                        <td *ngIf="selectedPageName === 'customer'">
                            Repeat Customer
                        </td>
                        <td *ngIf="selectedPageName !== 'asnef_included'">
                            {{ item?.current_lead?.email }}
                        </td>
                        <td *ngIf="selectedPageName !== 'asnef_included'">
                            {{ item?.current_lead?.mobile_phone }}
                        </td>
                        <td *ngIf="selectedPageName !== 'asnef_included'">
                            {{ item?.current_lead?.other_phone }}
                        </td>
                        <td *ngIf="selectedPageName !== 'asnef_included'">
                            {{ item?.current_lead?.work_phone_one }}
                        </td>
                        <td *ngIf="selectedPageName !== 'asnef_included'">
                            {{ item?.current_lead?.work_phone_two }}
                        </td>
                        <td *ngIf="selectedPageName !== 'asnef_included'">
                            {{ item?.sex?.name }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'customer' &&
                                selectedPageName !== 'asnef_included'
                            "
                        >
                            {{ item?.is_customer ? 'Yes' : 'No' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'dropout' &&
                                selectedPageName !== 'asnef_included'
                            "
                        >
                            {{ item?.dropout ? 'Yes' : 'No' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'blacklisted' &&
                                selectedPageName !== 'asnef_included'
                            "
                        >
                            {{ item?.blacklisted ? 'Yes' : 'No' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'all' &&
                                selectedPageName !== 'customer' &&
                                selectedPageName !== 'asnef_included'
                            "
                        ></td>
                        <td class="text-center">
                            <i
                                *ngIf="item?.current_lead?.id"
                                style="font-size: 2em; cursor: pointer"
                                (click)="userInfo(item?.current_lead?.id)"
                                class="fa fa-eye text-primary"
                            ></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
