import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class PaymentBankCardService {
    constructor(private http: HttpClient) {}

    list(): Observable<any> {
        return this.http.get(environment.api_url + '/bank_cards/list');
    }
    create(
        pan: any,
        expiration_month: any,
        expiration_year: any,
        cvv: any
    ): Observable<any> {
        return this.http.post(environment.api_url + '/bank_cards/create', {
            pan: pan,
            expiration_month: expiration_month,
            expiration_year: expiration_year,
            cvc: cvv,
        });
    }
}
