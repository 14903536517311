import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../../services/email.service';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-mail-messages-send-config-create',
  templateUrl: './mail-messages-send-config-create.component.html',
  styleUrls: ['./mail-messages-send-config-create.component.scss'],
})
export class MailMessagesSendConfigCreateComponent
  implements OnInit, OnDestroy
{
  @Input() item;

  id;
  isSending = false;

  form = this.fb.group({
    event_id: ['', Validators.required],
    type_id: ['', Validators.required],
    channel_id: ['', Validators.required],
    provider_id: [''],
    enabled: [false],
  });

  channelsSubscriber: SubscriptionLike;
  providersSubscriber: SubscriptionLike;
  typesSubscriber: SubscriptionLike;
  eventsSubscriber: SubscriptionLike;

  channels: any[];
  providers: any[];
  types: any[];
  events: any[];

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private emailService: EmailService,
    private errorMessage: ErrorMessageService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  edit(item, content) {
    const { event_id, type_id, channel_id, provider_id, enabled } = item;

    this.form.setValue({
      event_id,
      type_id,
      channel_id,
      provider_id,
      enabled,
    });

    if (item?.published) {
      this.form.disable();
    }

    this.open(content);
  }

  open(content) {
    this.getLists();

    this.modalService.open(content).result.then(
      (result) => {
        this.resetForm();
      },
      (reason) => {
        this.resetForm();
      }
    );
  }

  resetForm() {
    this.form.setValue({
      event_id: '',
      type_id: '',
      channel_id: '',
      provider_id: '',
      enabled: false,
    });

    this.channels = [];
    this.providers = [];
    this.types = [];
    this.events = [];
    this.unsubscribe();
  }

  onSubmit() {
    let args;
    const formValues = this.form.getRawValue();
    const params = {
      channel_id: +formValues.channel_id,
      event_id: +formValues.event_id,
      provider_id: +formValues.provider_id || '',
      type_id: +formValues.type_id,
      enabled: formValues.enabled,
    };
    const id = this.item?.id || this.id;
    const method = id ? 'editMessagesSendConfig' : 'createMessagesSendConfig';

    args = id ? { id, params } : { params };

    this.form.disable();

    this.emailService[method](args).subscribe(
      (response) => {
        this.onSuccess(response, id);
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  onSuccess(item, id = null) {
    this.errorMessage.showSuccess('', 'Saved Successfully');
    this.isSending = false;
    this.form.enable();

    if (id) {
      this.emailService.updateMessagesSendConfigs(item);
    } else {
      this.emailService.addMessagesSendConfig(item);
      this.id = item?.id;
    }
  }

  onError(error) {
    this.errorMessage.showError(error?.error?.error?.details, 'Saving Failed');
    this.isSending = false;
    this.form.enable();
  }

  getLists() {
    this.eventsSubscriber = this.emailService
      .getEvents()
      .subscribe(
        (resp) => (this.events = resp.filter(({ enabled }) => enabled) || [])
      );

    this.channelsSubscriber = this.emailService
      .getChannels()
      .subscribe(
        (resp) => (this.channels = resp.filter(({ enabled }) => enabled) || [])
      );

    this.providersSubscriber = this.emailService
      .getProviders()
      .subscribe(
        (resp) => (this.providers = resp.filter(({ enabled }) => enabled) || [])
      );

    this.typesSubscriber = this.emailService
      .getMessagesTypesRequest()
      .subscribe(
        (resp) =>
          (this.types =
            resp.filter(({ enabled, published }) => enabled && published) || [])
      );
  }

  unsubscribe() {
    if (this.channelsSubscriber) this.channelsSubscriber.unsubscribe();
    if (this.providersSubscriber) this.providersSubscriber.unsubscribe();
    if (this.typesSubscriber) this.typesSubscriber.unsubscribe();
    if (this.eventsSubscriber) this.eventsSubscriber.unsubscribe();
  }
}
