import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-backoffice-finance',
    templateUrl: './backoffice-finance.component.html',
    styleUrls: ['./backoffice-finance.component.css']
})
export class BackofficeFinanceComponent implements OnInit, DoCheck {
    show: boolean;

    constructor(private router: Router) {}

    ngDoCheck() {
        if (window.location.href.match('.backoffice/finance$')) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    ngOnInit() {}

    showPanel() {
        return this.show;
    }

    goto(route) {
        this.show = false;
        this.router.navigate(['/backoffice/finance/loan', route]);
    }
    gotoFinance(route) {
        this.show = false;
        this.router.navigate(['/backoffice/finance', route]);
    }
    gotoChecking() {
        this.show = false;
        this.router.navigate(['/backoffice/loans/loan-checking']);
    }
}
