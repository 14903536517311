<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">
            Contact info
            <span *ngIf="editInfo" (click)="openEdit(edit, data)">Edit</span>
        </h5>
    </div>
    <div class="card-body">
        <ul *ngIf="lead" class="list-group">
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Mobile(L1)</strong
                ><span [innerHTML]="formatPhone(data?.mobile_phone)"></span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Mobile Verified by SMS</strong
                ><span>{{
                    data?.verified_mobile_phone === 0 ? 'No' : 'Si'
                }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Other Phone(L2)</strong
                ><span [innerHTML]="formatPhone(data?.other_phone)"></span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Work Phone(L3)</strong
                ><span [innerHTML]="formatPhone(data?.work_phone_one)"></span
                ><button
                    type="button"
                    *ngIf="!data.work_phone_one && editInfo"
                    class="btn btn-accept"
                    (click)="open(content, 'work_phone_one')"
                >
                    Add
                </button>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Work Phone 2(L4)</strong
                ><span [innerHTML]="formatPhone(data?.work_phone_two)"></span
                ><button
                    *ngIf="!data.work_phone_two && editInfo"
                    class="btn btn-accept"
                    (click)="open(content, 'work_phone_two')"
                >
                    Add
                </button>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Additional Phone 1(L5)</strong
                ><span
                    [innerHTML]="formatPhone(data?.additional_phone_one)"
                ></span
                ><button
                    *ngIf="!data.additional_phone_one && editInfo"
                    class="btn btn-accept"
                    (click)="open(content, 'additional_phone_one')"
                >
                    Add
                </button>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Additional Phone 2(L6)</strong
                ><span
                    [innerHTML]="formatPhone(data?.additional_phone_two)"
                ></span>
                <button
                    *ngIf="!data.additional_phone_two && editInfo"
                    class="btn btn-accept"
                    (click)="open(content, 'additional_phone_two')"
                >
                    Add
                </button>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Email</strong><span>{{ data?.email }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Last Update</strong><span>{{ data?.updated_at }}</span>
            </li>
        </ul>
    </div>
</div>

<!-- Modal -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="number_phone">Number Phone</label>
            <div class="input-group">
                <input
                    id="number_phone"
                    class="form-control"
                    [(ngModel)]="phone_number"
                    pattern="[6|7|8|9][0-9]{8}"
                    #phone="ngModel"
                    required
                />
                <img
                    src="assets/img/backoffice/loading.gif"
                    width="50px"
                    height="50px"
                    *ngIf="saving"
                />
            </div>
            <div class="input-group">
                <label *ngIf="phone.invalid && phone.value" style="color:red"
                    >Inválido</label
                >
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            [disabled]="phone.invalid || isSaving"
            class="btn btn-outline-dark"
            (click)="saveNumberPhone('Save click')"
        >
            {{ isSaving ? 'Saving' : 'Save' }}
        </button>
    </div>
</ng-template>

<ng-template #edit let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Contact update</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-group" #editContactsForm="ngForm">
            <div
                *ngIf="contactModel.verified_mobile_phone === 0"
                class="form-group"
            >
                <label for="edit_phone">Mobile</label>
                <input
                    type="text"
                    name="mobile_phone"
                    id="edit_mobile"
                    class="form-control"
                    [(ngModel)]="contactModel.mobile_phone"
                    #mobilePhone="ngModel"
                    pattern="[6|7|8|9][0-9]{8}"
                    required
                />
                <label
                    *ngIf="mobilePhone.invalid && mobilePhone.value"
                    style="color:red"
                    >Inválido</label
                >
            </div>
            <label for="edit_other">Other Phone</label>
            <div class="form-group">
                <input
                    type="text"
                    name="other_phone"
                    id="edit_other"
                    class="form-control"
                    [(ngModel)]="contactModel.other_phone"
                    #otherPhone="ngModel"
                    pattern="[6|7|8|9][0-9]{8}"
                    required
                />
                <label *ngIf="otherPhone.invalid" style="color:red"
                    >Inválido</label
                >
            </div>
            <label for="edit_work">Work Phone</label>
            <div class="form-group">
                <input
                    type="text"
                    name="work_phone_one"
                    id="edit_work"
                    class="form-control"
                    [(ngModel)]="contactModel.work_phone_one"
                    #workPhone="ngModel"
                    pattern="[6|7|8|9][0-9]{8}"
                />
                <label
                    *ngIf="workPhone.invalid && workPhone.value"
                    style="color:red"
                    >Inválido</label
                >
            </div>
            <label for="edit_work_two">Work Phone 2</label>
            <div class="form-group">
                <input
                    type="text"
                    name="work_phone_two"
                    id="edit_work_two"
                    class="form-control"
                    [(ngModel)]="contactModel.work_phone_two"
                    #work2Phone="ngModel"
                    pattern="[6|7|8|9][0-9]{8}"
                />
                <label
                    *ngIf="work2Phone.invalid && work2Phone.value"
                    style="color:red"
                    >Inválido</label
                >
            </div>
            <label for="edit_additional">Additional Phone 1</label>
            <div class="form-group">
                <input
                    type="text"
                    name="additional_phone_one"
                    id="edit_additional"
                    class="form-control"
                    [(ngModel)]="contactModel.additional_phone_one"
                    #addPhone="ngModel"
                    pattern="[6|7|8|9][0-9]{8}"
                />
                <label
                    *ngIf="addPhone.invalid && addPhone.value"
                    style="color:red"
                    >Inválido</label
                >
            </div>
            <label for="edit_additional_two">Additional Phone 2</label>
            <div class="form-group">
                <input
                    type="text"
                    name="additional_phone_two"
                    id="edit_additional_two"
                    class="form-control"
                    [(ngModel)]="contactModel.additional_phone_two"
                    #add2Phone="ngModel"
                    pattern="[6|7|8|9][0-9]{8}"
                />
                <label
                    *ngIf="add2Phone.invalid && add2Phone.value"
                    style="color:red"
                    >Inválido</label
                >
            </div>
            <label for="edit_mail">Email</label>
            <div class="form-group">
                <input
                    type="email"
                    name="email"
                    id="edit_mail"
                    class="form-control"
                    [(ngModel)]="contactModel.email"
                    #emailEdit="ngModel"
                    pattern="[a-zA-Z0-9_.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+"
                    required
                />
                <label *ngIf="emailEdit.invalid" style="color:red"
                    >Inválido</label
                >
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <img
            src="assets/img/backoffice/loading.gif"
            width="50px"
            height="50px"
            *ngIf="saving"
        />
        <button
            type="submit"
            [disabled]="editContactsForm.invalid || isSaving"
            class="btn btn-outline-dark"
            (click)="saveContactInfo(editContactsForm.value, false)"
        >
            {{ isSaving ? 'Saving' : 'Save' }}
        </button>
    </div>
</ng-template>
