import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-lead-overview',
    templateUrl: './lead-overview.component.html',
    styleUrls: ['./lead-overview.component.css']
})
export class LeadOverviewComponent implements OnInit {
    id: string;
    private sub: any;
    lead: any = null;
    leadStatus: any = null;
    leadRejectedReason: any = null;
    user: any = null;
    constructor(
        private activate: ActivatedRoute,
        private router: Router,
        private leadService: LeadService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        /*this.router.events.forEach((event: any)=>{if(event instanceof NavigationStart){console.log(event.url);}});*/
        this.sub = this.activate.params.subscribe(params => {
            this.id = params['id'];
            this.getLead(this.id);
        });
    }

    getLead(id) {
        this.leadService.get(id).then(resp => {
            if (Object.values(resp).length) {
                this.lead = resp;
            }
        });
    }

    goto(route, event) {
        if (event) {
            let target = event.target || event.srcElement || event.currentTarget;
            if (document.getElementsByClassName('tablinks-selected').length > 0) {
                document.getElementsByClassName('tablinks-selected')[0].className = 'tablinks';
            }
            (<HTMLInputElement>target).className = 'tablinks-selected';
        }
        /*this.router.events.forEach((event: any)=>{if(event instanceof NavigationStart){console.log(event.url);}});*/
        this.router.navigate([`/backoffice/underwriting/overview/${this.id}/${route}`, this.id]);
    }
}
