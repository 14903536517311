<div class="card mb-3">
    <div class="card-header"><h5 class="card-header-title">User data changes Tracking</h5></div>
    <div class="card-body pt-0 pb-0 pl-0 pr-0">
        <div class="table-responsive">
            <table class="table ml-0 mt-0 mb-0 mr-0 table-bordered table-hover">
                <thead class="table-head bg-green-light">
                    <th>Date</th>
                    <th>Event</th>
                    <th>Comment</th>
                    <th>Added By</th>
                </thead>
                <tbody>
                    <tr *ngFor="let tracking of contact_tracking?.data">
                        <td>{{ tracking?.created_at }}</td>
                        <td>{{ tracking?.event?.name }}</td>
                        <td>{{ tracking?.comment }}</td>
                        <td>{{ tracking?.created_by?.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
