<table class="table table-striped table-responsive">
    <thead>
        <tr>
            <th>Username</th>
            <th>Name</th>
            <th>Last Name</th>
            <th>Created</th>
            <th>Role</th>
            <th>Status</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of authService?.users?.data">
            <th scope="row">{{ user?.username }}</th>
            <td>{{ user?.name }}</td>
            <td>{{ user?.first_surname }} {{ user?.second_surname }}</td>
            <td>{{ user?.created_at }}</td>
            <td>{{ separateByComma(user?.roles) }}</td>
            <td>{{ user?.blocked ? 'Blocked' : 'Actived' }}</td>
            <td>
                <i
                    class="fa fa-pencil"
                    [routerLink]="'/backoffice/settings/users_overview/create_user/' + user?.id"
                ></i>
            </td>
        </tr>
    </tbody>
</table>
