import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { PaymentBankAccountService } from '../../../services/payment-bank-account.service';

@Component({
    selector: 'app-fee-rectification',
    templateUrl: './fee-rectification.component.html',
    styleUrls: ['./fee-rectification.component.scss'],
})
export class FeeRectificationComponent implements OnInit {
    @Input() quota: any = null;
    @Input() loan: any = null;

    @Output() sended: EventEmitter<any> = new EventEmitter();

    isSending = false;

    constructor(
        private errorMessageService: ErrorMessageService,
        private paymentBankAccount: PaymentBankAccountService
    ) {}

    ngOnInit(): void {}

    refund() {
        this.isSending = true;
        const quotaId = this.quota?.id;

        if (
            confirm(
                `Late Fee Rectification. Quota: ${quotaId}\nYou are sure to perform this operation?`
            )
        ) {
            this.paymentBankAccount
                .lateFeeRectification(this.loan, quotaId)
                .toPromise()
                .then((response) => {
                    if (response) {
                        this.errorMessageService.showSuccess(
                            'Late Fee Rectification succesfully!'
                        );
                        this.isSending = false;

                        this.sended.emit(true);
                    }
                })
                .catch((error) => {
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                    this.isSending = false;
                });
        } else {
            this.isSending = false;
        }
    }
}
