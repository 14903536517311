import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-settings-mail-templates',
    templateUrl: './settings-mail-templates.component.html',
    styleUrls: ['./settings-mail-templates.component.scss'],
})
export class SettingsMailTemplatesComponent implements OnInit {
    links = [
        { title: 'Templates', href: '/backoffice/settings/mail-templates' },
        {
            title: 'Variables',
            href: 'variables',
        },
        {
            title: 'Messages Types',
            href: 'messages-types',
        },
        {
            title: 'Messages Send Config',
            href: 'messages-send-config',
        },
        {
            title: 'Providers Statuses',
            href: 'providers-statuses',
        },
    ];

    constructor() {}

    ngOnInit() {}
}
