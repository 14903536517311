import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DebtRecoveryService } from '../../../services/debt-recovery.service';

import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DaterangepickerComponent } from 'ng2-daterangepicker';

declare var moment: any;

@Component({
    selector: 'app-launch-manager',
    templateUrl: './launch-manager.component.html',
    styleUrls: ['./launch-manager.component.scss'],
})
export class LaunchManagerComponent implements OnInit {
    @ViewChild(DaterangepickerComponent)
    private picker: DaterangepickerComponent;

    calendarDates = {
        startDate: '01/01/2015',
        endDate: moment().format('DD/MM/YYYY'),
    };

    settings: any = null;

    filterLists = {
        cardStatus: {
            link: '/bank_card/status/list',
            list: <any>null,
        },
        gateway: {
            link: '/payment/provider/list',
            list: <any>null,
        },
        jobRole: {
            link: '/lead/info/request',
            list: <any>null,
        },
    };

    queForm = new FormGroup({
        date: new FormControl('', Validators.required),
        job_role: new FormControl('all', Validators.required),
        gateway: new FormControl('all', Validators.required),
        amount: new FormControl('', Validators.required),
        quotas: new FormControl(
            { value: 'all', disabled: true },
            Validators.required
        ),
        credit_card: new FormControl('all', Validators.required),
    });

    amountOrQuoata: string = 'amount';

    step: number = 0;
    promises: any = [];
    loading: boolean = true;
    formDate: any;
    formData: any = {};
    queList: any = [];

    constructor(
        private modalService: NgbModal,
        private debtRecovery: DebtRecoveryService
    ) {}

    ngOnInit() {
        this.settings = {
            locale: {
                format: 'DD/MM/YYYY',
                cancelLabel: 'Clear',
            },
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            ranges: {
                Today: [moment(), moment()],
                Yesterday: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                ],
                'Last 5 Days': [moment().subtract(4, 'days'), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                ],
                'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                ],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                ],
            },
            minDate: this.calendarDates.startDate,
            maxDate: this.calendarDates.endDate,
            startDate: this.calendarDates.startDate,
            endDate: this.calendarDates.endDate,
        };

        this.getFiltersList();

        Promise.all(this.promises).then(() => {
            this.loading = false;
        });
    }

    onAmountOrQuoataChange(e) {
        const quotas = this.queForm.get('quotas');
        const amount = this.queForm.get('amount');

        quotas.reset('all');
        amount.reset('');

        if (e === 'quotas') {
            quotas.enable();
            amount.disable();
        } else {
            quotas.disable();
            amount.enable();
        }
    }

    onChangeDate(e: any) {
        //this.getParamsFilter(e.start);
        //this.getParamsFilter(e.end);
        //this.makeFilter();
    }

    openProcess(content) {
        this.modalService.open(content);
    }

    loadQue(e) {
        let formDate = this.formDate;
        let queForm = this.queForm.getRawValue();

        let formData = { ...queForm, ...formDate };

        delete formData.date;

        this.formData = formData;

        this.queList = [1, 2, 3];
    }

    changeStep(step) {
        this.step = step;
    }

    getFiltersList() {
        let _this = this;
        Object.keys(_this.filterLists).forEach(function (key) {
            _this.promises.push(
                _this.debtRecovery
                    .getFilters(_this.filterLists[key])
                    .toPromise()
                    .then((response) => {
                        _this.filterLists[key].list = response;
                    })
            );
        });
    }

    selectedDate(value: any) {
        const start = value.start.format('DD/MM/YYYY');
        const end = value.end.format('DD/MM/YYYY');

        this.queForm.patchValue({
            date: start + ' - ' + end,
        });

        this.formDate = { que_start: start, que_end: end };
    }

    clearCalendar(e: any) {
        if (e.picker || !e.target.value) {
            this.queForm.patchValue({
                date: '',
            });

            this.updateDateRange();

            this.formDate = { que_start: '', que_end: '' };
        }
    }

    updateDateRange() {
        this.picker.datePicker.setStartDate(this.calendarDates.startDate);
        this.picker.datePicker.setEndDate(this.calendarDates.endDate);
    }
}
