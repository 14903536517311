import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike } from 'rxjs';
import { EmailService } from '../../../../services/email.service';

@Component({
  selector: 'app-mail-templates-preview',
  templateUrl: './mail-templates-preview.component.html',
  styleUrls: ['./mail-templates-preview.component.scss'],
})
export class MailTemplatesPreviewComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() cssClass = 'btn-primary';
  @Input() id;

  private oldID;

  subscriber: SubscriptionLike;

  draft;
  isLoading = true;

  constructor(
    private emailService: EmailService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  open(content) {
    if (this.id) {
      if (!this.oldID || (this.oldID && +this.oldID !== +this.id)) {
        this.oldID = this.id;
        this.getDraft(this.id);
      }

      this.modalService.open(content, { size: 'lg' });
    }
  }

  getDraft(id) {
    this.isLoading = true;
    this.subscriber = this.emailService
      .getTemplateDraft(id)
      .subscribe((response) => {
        this.draft = this.sanitizer.bypassSecurityTrustHtml(
          response?.content || ''
        );

        this.isLoading = false;
      });
  }
}
