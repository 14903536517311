import { Component, OnInit } from '@angular/core';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { DiscardAntifraudService } from '../../../services/discard-antifraud.service';

@Component({
    selector: 'app-setting-validation-discard-antifraud',
    templateUrl: './setting-validation-discard-antifraud.component.html',
    styleUrls: ['./setting-validation-discard-antifraud.component.scss']
})
export class SettingValidationDiscardAntifraudComponent implements OnInit {
    forms: any[] = null;
    saving = false;

    validator_limit_debt_detail = 2;
    constructor(private errorMessage: ErrorMessageService, private discardAntifraudService: DiscardAntifraudService) {}
    ngOnInit() {
        this.discardAntifraudService.getConfigValidatorList().subscribe(validators => {
            if (validators) {
                this.forms = validators;
            }
        });
    }

    save() {
        this.saving = true;

        if (this.forms.length > 0) {
            this.forms.forEach(form => {
                this.discardAntifraudService.updateConfigValidator(form).subscribe(
                    resp => {
                        if (resp) {
                            this.errorMessage.showSuccess(`El validador ${form.description} ha sido actualizado`);
                        } else {
                            this.errorMessage.showError(
                                `No se puedo actualizar el validador ${form.description} debido a un error`
                            );
                        }
                    },
                    error => {
                        this.errorMessage.showError(error.error.error.show_message);
                    }
                );
            });
        }

        this.saving = false;
    }
}
