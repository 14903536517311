<div class="d-flex align-items-center justify-content-between mb-3">
    <h6 class="mb-0">Documentos adjuntos</h6>
    <app-presentation-attachments-add
        *ngIf="!closed"
        class="ml-auto"
        [proceedingId]="proceedingId"
        [presentationId]="presentationId"
        [disabled]="isLoading"
        (added)="onAdded($event)"
    ></app-presentation-attachments-add>
</div>

<div *ngIf="isLoading" class="text-center mb-3">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading && !tableData?.data?.length">-</div>
<div *ngIf="!isLoading && tableData?.data?.length" class="mb-3">
    <div class="table-responsive">
        <table class="table table-sm">
            <tr *ngFor="let item of tableData.data; let first = first">
                <td [ngClass]="{ 'border-top-0': first }" class="pl-0">
                    <small class="d-block">
                        <strong>{{ item.name }}</strong>
                    </small>
                    <small class="text-muted">{{ item.description }}</small>
                </td>
                <td [ngClass]="{ 'border-top-0': first }">
                    <small>{{
                        item?.generated_at
                            | dateFormat
                            | date: 'dd/MM/yyyy HH:mm'
                    }}</small>
                </td>
                <td [ngClass]="{ 'border-top-0': first }">
                    <small>{{ item.type.name }}</small>
                </td>
                <td
                    [ngClass]="{ 'border-top-0': first }"
                    class="px-0 text-right"
                >
                    <button
                        class="btn btn-success btn-sm"
                        (click)="download(item.id, item.name)"
                        [disabled]="isFileLoading"
                    >
                        <i class="fa fa-download"></i>
                    </button>
                    <button
                        class="btn btn-info btn-sm ml-1"
                        (click)="view(item.id)"
                        [disabled]="isFileLoading"
                    >
                        <i class="fa fa-eye"></i>
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>
