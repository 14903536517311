import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-message-types',
  templateUrl: './mail-message-types.component.html',
  styleUrls: ['./mail-message-types.component.scss'],
})
export class MailMessageTypesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
