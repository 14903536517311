import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

declare var moment: any;

@Component({
    selector: 'app-backoffice-debtrecovery',
    templateUrl: './backoffice-debtrecovery.component.html',
    styleUrls: ['./backoffice-debtrecovery.component.css']
})
export class BackofficeDebtrecoveryComponent implements OnInit, DoCheck {
    show: boolean = true;

    startData: any = {
        date_start: moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        loan_code: '',
        full_name: '',
        personalid: '',
        count_quota_unpaid: '',
        amount_unpaid: '',
        amount_paid: '',
        payment_source_id: '',
        tracking_type_incident_id: '',
        card_status_id: '',
        job_type_id: '',
        account_manager_id: '',
        page: 0
    };

    constructor(private router: Router) {}

    ngDoCheck() {
        if (window.location.href.match('.backoffice/debtrecovery$')) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    ngOnInit() {}

    goto(route) {
        this.show = false;

        this.router.navigate([route]);
    }
}
