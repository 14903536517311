<form [formGroup]="faqForm" style="width: 22rem;" (ngSubmit)="onFormSubmit()">
    <!-- input names -->
    <div class="md-form">
      <i class="fa fa-user prefix grey-text"></i>
      <input type="text" id="title" formControlName="title" class="form-control" mdbInputDirective>
      <label for="title" class="font-weight-light">Title</label>
    </div>
    <div class="md-form">
        <textarea type="text" id="content" formControlName="content" class="md-textarea md-textarea-auto form-control" mdbInputDirective></textarea>
        <label for="form7">Content</label>
    </div>
    <div class="md-form">
        <i class="fa fa-user prefix grey-text"></i>
      <input type="number" id="position" formControlName="position" class="form-control" mdbInputDirective>
      <label for="position" class="font-weight-light">Position</label>
    </div>
    <!-- input username -->
    <div class="md-form">
        <i class="fa fa-user-o prefix grey-text"></i>
      <input type="text" id="category_id" formControlName="category_id" class="form-control" mdbInputDirective>
      <label for="category_id" class="font-weight-light">Category Id</label>
    </div>
  
    <div class="form-check mr-3">
      <input type="checkbox" id="status" formControlName="status" class="form-control form-check-input" mdbInputDirective>
      <label for="status" class="form-check-label"><i class="fa fa-lock prefix grey-text"></i></label>
    </div>
  
    <div class="text-center py-4 mt-3">
      <button class="btn btn-cyan waves-light" type="submit" [disabled]="faqForm.invalid" mdbWavesEffect>{{faq_id?'Edit': 'Create'}}</button>
    </div>
  
  </form>