import { Component, OnInit, Input } from '@angular/core';
import { OnChanges } from '@angular/core';

declare let zingchart: any;

@Component({
  selector: 'app-lead-info-scoring-graphics',
  templateUrl: './lead-info-scoring-graphics.component.html',
  styleUrls: ['./lead-info-scoring-graphics.component.scss']
})
export class LeadInfoScoringGraphicsComponent implements OnInit, OnChanges {

  @Input() bad_start: number = 0;
  @Input() bad_end: number = 0;
  @Input() regular_start: number = 0;
  @Input() regular_end: number = 0;
  @Input() good_start: number = 0;
  @Input() good_end: number = 0;
  @Input() very_good_start: number = 0;
  @Input() very_good_end: number = 0;
  @Input() score: number = 0;
  @Input() score_label: string = '';
  loading = true;
  constructor() { }

  ngOnInit() {

  }

  ngOnChanges()
  {
    if(this.score)
    {
      this.creatingScoringGraphics();
    }

  }
  creatingScoringGraphics()
  {
    let myConfig = {
      type: "gauge",
      globals: {
        fontSize: 25
      },
      plotarea: {
        marginTop: 80
      },
      plot: {
        size: '100%',
        valueBox: {
          placement: 'center',
          text: '%v', //default
          fontSize: 35,
          rules: [{
            rule: '%v >= 850',
            text: '%v<br>EXCELLENT'
          }, {
            rule: '%v < 850 && %v > 700',
            text: '%v<br>Good'
          }, {
            rule: '%v <= 700 && %v >= 600',
            text: '%v<br>Regular'
          }, {
            rule: '%v <  600',
            text: '%v<br>Bad'
          }]
        }
      },
      tooltip: {
        borderRadius: 5
      },
      scaleR: {
        aperture: 180, 
        minValue: 200,
        maxValue: 1000,
        step: 50,
        center: {
          visible: false
        },
        tick: {
          visible: false
        },
        item: {
          offsetR: 0,
          rules: [{
            rule: '%i == 9',
            offsetX: 15
          }]
        },
        labels: ['200', '', '', '', '', '', '', '', '600','', '700', '', '','850','','', '1000'],

        ring: {
          size: 50,
          rules: [{
            rule: '%v < 600',
            backgroundColor: '#FF0000'
          }, {
            rule: '%v >= 600 && %v < 700',
            backgroundColor: '#FF8000'
          }, {
            rule: '%v >= 700 && %v < 849',
            backgroundColor: '#0080FF'
          }, {
            rule: '%v >= 849',
            backgroundColor: '#3ADF00'
          }]
        }
      },
      
      /*refresh: {
        type: "feed",
        transport: "js",
        url: "feed()",
        interval: 1500,
        resetTimeout: 1000
      },*/
      series: [{
        values: [this.score], // starting value
        backgroundColor: 'black',
        indicator: [5, 10, 10, 10, 0.75],
        animation: {
          effect: 2,
          method: 1,
          sequence: 4,
          speed: 900
        },
      }]
    };

    zingchart.render({
      id: 'scoring_graphics',
      data: myConfig,
      height: 500,
      width: '100%'
    });
  }
}
