import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CreditLineService } from '../../../services/credit-line.service';
import { AuthService } from '../../../services/auth.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'app-credit-line-checking-id-table',
    templateUrl: './credit-line-checking-id-table.component.html',
    styleUrls: ['./credit-line-checking-id-table.component.css']
})
export class CreditLineCheckingIdTableComponent implements OnInit, OnDestroy {
    title: string = 'Checking ID';

    querySubscriber: ISubscription;
    dataSubscriber: ISubscription;

    startData = {};
    filters = {};

    queryString: string = '';

    tableData;

    loading: boolean = true;
    checking: boolean = false;

    users: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private creditLineService: CreditLineService,
        private authService: AuthService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {
        this.authService.getFilteredUsersList().then(data => (this.users = data));

        this.querySubscriber = this.activatedRoute.queryParams.subscribe(queryParams => {
            this.startData = {
                code_str: '',
                updated_at_start: '',
                updated_at_end: '',
                created_at_start: '',
                created_at_end: '',
                personalid: '',
                full_name: '',
                checked_by: ''
            };

            if (this.dataSubscriber) {
                this.dataSubscriber.unsubscribe();
            }

            // Set Init Filters
            this.setInitFilters(queryParams);
            // Create Query String
            this.createQueryString(this.filters);

            this.getTableData();
        });
    }

    ngOnDestroy() {
        this.querySubscriber.unsubscribe();
        this.dataSubscriber.unsubscribe();
    }

    getTableData() {
        this.loading = true;
        this.dataSubscriber = this.creditLineService.getAllCheckingId(this.queryString).subscribe(response => {
            if (response) {
                this.tableData = response;
                this.loading = false;
            }
        });
    }

    getDataOnFilterSubmit() {
        // Set Init Filters
        this.filters = { ...this.filters, page: 0 };

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    createQueryString(filters = {}) {
        const clearFilters = { ...filters };

        Object.keys(clearFilters).forEach(
            key => (!clearFilters[key] || clearFilters[key] === 'empty') && delete clearFilters[key]
        );

        this.queryString = Object.keys(clearFilters)
            .map(key => key + '=' + clearFilters[key])
            .join('&');
    }

    pushFiltersToHistory(filters, replaceUrl = false) {
        Object.keys(filters).forEach(key => !filters[key] && delete filters[key]);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: Object.keys(filters).length > 0 ? filters : {},
            replaceUrl: replaceUrl
        });
    }

    setInitFilters(query = {}) {
        this.filters = { ...this.startData, ...query };
    }

    getDataOnPageChange(page) {
        this.filters['page'] = page;

        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    onChangeDate(e: any) {
        this.filters = { ...this.filters, ...e, page: 0 };
        // Create Query String
        this.createQueryString(this.filters);

        // Push Query String to history.
        // Navigates without replacing the current state in history.
        this.pushFiltersToHistory(this.filters);
    }

    check(lead_id, credit_line_id) {
        this.checking = true;

        this.creditLineService.canCheck(credit_line_id).subscribe(can => {
            if (can) {
                this.setCreditLineChecking(credit_line_id).subscribe(
                    () => {
                        this.checking = false;
                        this.router.navigate([`/backoffice/loans/loan-check/${lead_id}/userInfo/`, lead_id]);
                    },
                    error => {
                        this.checking = false;
                        if (error.error.error) {
                            this.errorMessageService.showError(error.error.error.show_message);
                        }
                    }
                );
            } else {
                this.checking = false;
                this.errorMessageService.showError('Otro operador ha iniciado el proceso de activación');
            }
        });
    }

    setCreditLineChecking(credit_line_id) {
        return this.creditLineService.setChecking(credit_line_id);
    }

    goto(lead_id) {
        if (lead_id) {
            this.router.navigate([`/backoffice/loans/overview/${lead_id}/leadInfo/`, lead_id]);
        }
    }
}
