<div *ngIf="trees.length > 0" class="row">
  <div class="col-12 tree-list">
    <app-payment-tree-item
      *ngFor="let item of trees"
      [tree]="item"
      [isActive]="active === item.id"
      (deleted)="onDelete($event)"
    ></app-payment-tree-item>
  </div>
</div>
