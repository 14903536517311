<!-- lead info tabpage -->
<section>
    <div class="tab-content" id="pills-tabContent">
        <!-- lead info tab page -->
        <div class="tab-pane fade show active" id="pills-leadinfo" role="tabpanel" aria-labelledby="pills-leadinfo-tab">
            <!-- first row -->
            <div class="row">
                <div class="col-xl-5">
                    <app-lead-info-form-value [lead]="lead" [user]="lead?.user" [loading]="loading">
                    </app-lead-info-form-value>
                    <app-lead-info-bank-aggregation
                        [bank_owner]="bank_owner"
                        [loading]="loading"
                    ></app-lead-info-bank-aggregation>

                    <app-lead-info-customer-lead [leads]="leads" [loading]="loading"> </app-lead-info-customer-lead>
                    <app-lead-info-customer-credit-line [credit_lines]="credit_lines" [loading]="loading">
                    </app-lead-info-customer-credit-line>
                </div>
                <div class="col-xl-7">
                    <div class="fluid-row">
                        <div class="row">
                            <div class="col-xl-12">
                                <app-lead-info-other
                                    [lead]="lead"
                                    [credit_line]="lead?.credit_line"
                                    [loading]="loading"
                                >
                                </app-lead-info-other>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header"><h5 class="card-header-title">Scoring</h5></div>
                            <div class="card-body">
                                <app-lead-info-scoring-graphics
                                    [score]="+scoring?.score"
                                    [loading]="loading"
                                ></app-lead-info-scoring-graphics>
                            </div>
                        </div>
                        <app-lead-info-savso-validation
                            [milinea_customer]="milinea_customer"
                            [loading]="loading"
                        ></app-lead-info-savso-validation>
                        <app-lead-info-asnef-validation [asnef_snapshot]="asnef_snapshot">
                        </app-lead-info-asnef-validation>
                    </div>
                </div>
            </div>
            <!-- first row end -->
        </div>
        <!-- lead info tab page end -->
    </div>
</section>
