import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../../environments/environment';

@Injectable()
export class ContactInfoService {
    constructor(private http: HttpClient) {}

    save(value): Observable<any> {
        return this.http.post(environment.api_url + `/tracking/contact/change`, value);
    }
}
