<div class="card mb-3" *ngIf="!process">
    <div class="card-header"><h5 class="card-header-title">Advising Validations</h5></div>
    <div class="card-body" *ngIf="!process && validation_advising_antifraud_list?.length > 0">
        <div id="accordionright" role="tablist">
            <div *ngFor="let item of validation_advising_antifraud_list; let last = last">
                <div class="card" [ngClass]="{ 'mb-3': validation_advising_antifraud_list.length > 1 && !last }">
                    <div class="card-header" role="tab" id="headingRigthFour">
                        <h5 class="mb-0">
                            <a
                                class="collapsed"
                                data-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="collapseRigthFour"
                            >
                                {{ item?.name }} ({{ item?.list?.length }})
                            </a>
                        </h5>
                    </div>
                    <div
                        id="collapseRigthFour"
                        class="collapse show"
                        role="tabpanel"
                        aria-labelledby="headingRigthFour"
                        data-parent="#accordion"
                    >
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-condensed table-hover">
                                    <thead>
                                        <th>Value</th>
                                        <th>Coincidence</th>
                                        <th>Customer</th>
                                        <th>Black Listed?</th>
                                        <th>Lead Coincidence</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let validation of item?.list">
                                            <td>{{ validation?.checked_value }}</td>
                                            <td>{{ validation?.matched_value }}</td>
                                            <td>
                                                {{ validation?.lead?.user?.name }}
                                                {{ validation?.lead?.user?.first_surname }}
                                                {{ validation?.lead?.user?.second_surname }}
                                            </td>
                                            <td>{{ validation?.lead?.user?.blacklisted === 1 ? 'Si' : 'No' }}</td>
                                            <td>
                                                <a
                                                    target="_blank"
                                                    href="/backoffice/underwriting/overview/{{
                                                        validation?.lead_id
                                                    }}/leadInfo/{{ validation?.lead_id }}"
                                                    >{{ validation?.matched_lead?.code_str }}</a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
