import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { CreditLineService } from '../../../services/credit-line.service';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';
import { UserService } from '../../../services/user.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-datamanagement-user-info',
    templateUrl: './datamanagement-user-info.component.html',
    styleUrls: ['./datamanagement-user-info.component.scss']
})
export class DatamanagementUserInfoComponent implements OnInit {
    editInfo: boolean = false;
    loan_id: any = null;
    lead: any = null;
    id: any = '';
    user_id: any = '';

    leads: any = null;
    loans: any = null;

    comment = '';
    commentError = false;
    isSending = false;

    reason = null;

    history = null;

    constructor(
        private activate: ActivatedRoute,
        private leadService: LeadService,
        private creditLineService: CreditLineService,
        private formatPhoneService: FormatPhoneNumberService,
        private userService: UserService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];

            this.getLoan();
            this.getLead();
            this.getReasons();
        });
    }

    getLead() {
        this.leadService.get(this.id).then((lead: any) => {
            if (Object.values(lead).length) {
                this.lead = lead;
                this.user_id = this.lead.user.id;

                this.getLeads();
                this.getLoans();
                this.getHistory();
            }
        });
    }

    getLoan() {
        this.creditLineService
            .getByLeadId(this.id)
            .toPromise()
            .then(loan => {
                if (Object.values(loan).length) {
                    this.loan_id = loan.id;
                    this.editInfo = Boolean(this.loan_id);
                }
            });
    }

    getReasons() {
        this.userService.getBlacklistReasonsList().then(response => {
            this.reason = response;
        });
    }

    getHistory() {
        this.userService.getBlacklistHistory(this.user_id).then(response => {
            this.history = response;
        });
    }

    getLeads() {
        this.leadService.getAllByUserId(this.user_id).then((leads: any) => {
            this.leads = leads;
        });
    }

    getLoans() {
        this.creditLineService
            .getAllByUserId(this.user_id)
            .toPromise()
            .then((loans: any) => {
                this.loans = loans;
            });
    }

    formatPhone(phone) {
        if (phone) {
            return this.formatPhoneService.format(phone);
        }
    }

    blacklist(e, include: boolean) {
        e.preventDefault();
        this.isSending = true;

        if (!this.comment && !include) {
            this.checkComment();
            this.isSending = false;
            return;
        } else {
            if (!include) {
                if (confirm('You are sure to perform this operation?')) {
                    this.userService
                        .addToBlacklist(this.comment, this.user_id)
                        .toPromise()
                        .then(response => {
                            if (response) {
                                this.errorMessageService.showSuccess(
                                    'User added to blacklist succesfully!'
                                );
                                this.getLoan();
                                this.getLead();
                                this.isSending = false;
                            }
                        })
                        .catch(error => {
                            this.errorMessageService.showError(
                                error.error.error.show_message
                            );
                            this.isSending = false;
                        });
                } else {
                    this.isSending = false;
                }
            } else {
                if (confirm('You are sure to perform this operation?')) {
                    this.userService
                        .removeFromBlacklist(this.user_id)
                        .toPromise()
                        .then(response => {
                            if (response) {
                                this.errorMessageService.showSuccess(
                                    'User removed from blacklist succesfully!'
                                );
                                this.getLoan();
                                this.getLead();
                                this.isSending = false;
                            }
                        })
                        .catch(error => {
                            this.errorMessageService.showError(
                                error.error.error.show_message
                            );
                            this.isSending = false;
                        });
                } else {
                    this.isSending = false;
                }
            }
        }
    }

    dropout(e) {
        e.preventDefault();
        this.isSending = true;

        if (confirm('You are sure to perform this operation?')) {
            this.userService
                .addToDropOut(this.user_id)
                .then(response => {
                    if (response) {
                        this.errorMessageService.showSuccess(
                            'User added to dropout succesfully!'
                        );
                        this.getLoan();
                        this.getLead();
                        this.isSending = false;
                    }
                })
                .catch(error => {
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                    this.isSending = false;
                });
        } else {
            this.isSending = false;
        }
    }

    checkComment() {
        if (!this.comment) {
            this.commentError = true;
            return;
        } else {
            this.commentError = false;
        }
    }
}
