<div *appHasPermissions="['underwriting']">
    <!-- underwriting tab content -->
    <underwriting *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-sm-11 panel-main-action">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 col-sm-6  panel-main-separator">
                            <div class="row">
                                <div *appHasPermissions="['underwriting-all']" class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('all')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/all_leads.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">All leads</div>
                                        </div>
                                    </div>
                                </div>
                                <div *appHasPermissions="['underwriting-rejected']" class="col-md-12">
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('rejected')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/rejected.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Rejected</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="row">
                                <div
                                    *appHasPermissions="['underwriting-mobile-pending-confirmation']"
                                    class="col-lg-2 panel-main-section"
                                >
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('mobilePendingConfirmation')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/mobile_pending_confirmation.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Mobile Pending Confirmation</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="['underwriting-additional-info']"
                                    class="col-lg-2 panel-main-section"
                                >
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('additionalInfo')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/additional_info.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Additional Info</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="['underwriting-risk-validation']"
                                    class="col-lg-2 panel-main-section"
                                >
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('riskValidation')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/risk_validation.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Risk Validation</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="['underwriting-line-conditions-proposed']"
                                    class="col-lg-2 panel-main-section"
                                >
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('lineConditionsProposed')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/line_condition_proposed.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Line conditions proposed</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *appHasPermissions="['underwriting-pending-contract-sign']"
                                    class="col-lg-2 panel-main-section"
                                >
                                    <div class="panel-section">
                                        <div class="container-description" (click)="goto('pendingContractSign')">
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/underwriting/pending_contract_sign.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div class="icon__text text-primary">Pending contract sign</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *appHasPermissions="['loans']" class="offset-lg-1 col-lg-2 col-sm-6 panel-main-action">
                    <div class="row">
                        <div class="col-lg-12 panel-main-section">
                            <div class="panel-section">
                                <div class="container-description" (click)="gotoCL()">
                                    <img
                                        class="align-middle"
                                        width="90"
                                        height="90"
                                        src="assets/img/backoffice/loans.svg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="icon__text text-primary">Loans</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </underwriting>
    <!-- underwriting tab content end -->

    <router-outlet></router-outlet>
</div>
