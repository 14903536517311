<div class="container">
    <div class="form-row">
        <div class="col-12 col-lg-2">
            <div class="card rounded-0 mb-3">
                <div class="card-header bg-light border-bottom-0 text-center text-body mb-0">Me</div>
            </div>
            <div class="form-row">
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="card text-center rounded-0 mb-3">
                        <div class="card-header bg-white text-body">Today</div>
                        <div class="card-body bg-light text-primary pt-3 pb-3">
                            <div class="h1 mb-0">45</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="card text-center rounded-0 mb-3">
                        <div class="card-header bg-white text-body">This week</div>
                        <div class="card-body bg-light text-primary pt-3 pb-3">
                            <div class="h1 mb-0">45</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="card text-center rounded-0 mb-3">
                        <div class="card-header bg-white text-body">This month</div>
                        <div class="card-body bg-light text-primary pt-3 pb-3">
                            <div class="h1 mb-0">45</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="card text-center rounded-0 mb-3">
                        <div class="card-header bg-white text-body">Year to date</div>
                        <div class="card-body bg-light text-primary pt-3 pb-3">
                            <div class="h1 mb-0">45</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-9 offset-lg-1">
            <div class="form-row">
                <div class="col-12 col-lg-11">
                    <div class="card rounded-0 mb-3">
                        <div class="card-header bg-white border-bottom-0 text-center text-body mb-0">Team</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="form-row">
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Today</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Goal</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-2">
                            <circle-progress [percent]="(45 / 80) * 100"></circle-progress>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Yesterday</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-1 align-self-center text-center">
                            <div class="kpi-difference-plus text-success">+5</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="form-row">
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">This week</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Goal</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-2">
                            <circle-progress [percent]="(45 / 80) * 100"></circle-progress>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Last week</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-1 align-self-center text-center">
                            <div class="kpi-difference-minus text-danger">-10</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="form-row">
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">This month</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Goal</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-2">
                            <circle-progress [percent]="(45 / 80) * 100"></circle-progress>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Last month</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-1 align-self-center text-center">
                            <div class="kpi-difference-minus text-danger">-2</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-12">
                    <div class="form-row">
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Year to date</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Goal</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-2">
                            <circle-progress [percent]="(45 / 80) * 100"></circle-progress>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card text-center rounded-0 mb-3">
                                <div class="card-header bg-white text-body">Last year</div>
                                <div class="card-body pt-3 pb-3">
                                    <div class="h1 mb-0">45</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-1 align-self-center text-center">
                            <div class="kpi-difference-plus text-success">+174</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
