<div *appHasPermissions="['loan-check/:id']">
    <!-- horizontal menu -->
    <section>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link"
                    (click)="showCheckTrue()"
                    routerLink="/backoffice/loans/loan-check/{{
                        id
                    }}/userInfo/{{ id }}"
                    routerLinkActive="active"
                    id="pills-leadinfo-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-leadinfo"
                    aria-selected="true"
                    >Customer Info<span
                        *ngIf="
                            credit_line_check?.customer_info || checkLink?.info
                        "
                    >
                        <i class="fa fa-check mr-2"></i></span
                ></a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    (click)="showCheckTrue()"
                    routerLink="/backoffice/loans/loan-check/{{
                        id
                    }}/antiFraud/{{ id }}"
                    routerLinkActive="active"
                    id="pills-userinfo-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-userinfo"
                    aria-selected="false"
                    >Anti-fraud<span
                        *ngIf="
                            credit_line_check?.antifraud || checkLink?.antifraud
                        "
                    >
                        <i class="fa fa-check mr-2"></i></span
                ></a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    (click)="showCheckTrue()"
                    routerLink="/backoffice/loans/loan-check/{{ id }}/docs/{{
                        id
                    }}"
                    routerLinkActive="active"
                    id="pills-antifraud-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-antifraud"
                    aria-selected="false"
                    >Docs<span>
                        <i
                            *ngIf="
                                credit_line_check?.docs ||
                                (checkNavBarService.checkedPersonalIdFront &&
                                    checkNavBarService.checkedPersonalIdBack &&
                                    checkNavBarService.checkedSelfie &&
                                    checkNavBarService.checkedAddress)
                            "
                            class="fa fa-check mr-2"
                        ></i></span
                ></a>
            </li>

            <li class="nav-item">
                <a
                    class="nav-link"
                    (click)="showCheckFalse()"
                    routerLink="/backoffice/loans/loan-check/{{
                        id
                    }}/leadInfo/{{ id }}"
                    routerLinkActive="active"
                    id="pills-antifraud-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-antifraud"
                    aria-selected="false"
                    >Lead info</a
                >
            </li>

            <li class="nav-item">
                <a
                    class="nav-link"
                    (click)="showCheckFalse()"
                    routerLink="/backoffice/loans/loan-check/{{
                        id
                    }}/scoringDetails/{{ id }}"
                    routerLinkActive="active"
                    id="pills-scoring-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-scoring"
                    aria-selected="false"
                    >Scoring details</a
                >
            </li>

            <li class="nav-item">
                <a
                    class="nav-link"
                    (click)="showCheckFalse()"
                    routerLink="/backoffice/loans/loan-check/{{
                        id
                    }}/loanInfo/{{ id }}"
                    routerLinkActive="active"
                    id="pills-scoring-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="pills-scoring"
                    aria-selected="false"
                    >Loan info</a
                >
            </li>

            <div style="margin-left: 3vw">
                <a href="#0" style="margin-right: 10px"
                    ><img
                        src="assets/img/backoffice/sms.svg"
                        width="40"
                        height="40"
                /></a>
                <a href="#0" style="margin-right: 10px"
                    ><img
                        src="assets/img/backoffice/mail.svg"
                        width="40"
                        height="40"
                /></a>
            </div>
            <app-tracking [creditline]="creditLine"></app-tracking>
            <app-check-nav-bar
                class="tablinks"
                [showCheck]="showCheck"
                [creditLineCheck]="credit_line_check"
                (changeActivation)="onChangeActivation($event)"
            ></app-check-nav-bar>
        </ul>
    </section>
    <!-- horizontal menu end -->

    <section>
        <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
                <lead>
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title text-center">Loan</h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center">
                                {{ creditLine?.code_str }}
                            </p>
                        </div>
                    </div>
                </lead>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
                <status>
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title text-center">
                                STATUS
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center">
                                {{ creditLine?.status?.name }}
                            </p>
                        </div>
                    </div>
                </status>
            </div>
            <!-- <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <am>
                <div class="card mb-3">
                    <div class="card-header"><h5 class="card-header-title text-center">AM</h5></div>
                    <div class="card-body"><p class="text-center">-</p></div>
                </div>
            </am>
        </div> -->
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
                <dni>
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title text-center">DNI</h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center">
                                {{ lead?.user?.personalid }}
                            </p>
                        </div>
                    </div>
                </dni>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
                <customer>
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title text-center">
                                <div>CUSTOMER</div>
                            </h5>
                        </div>
                        <div class="card-body">
                            <p class="text-center">
                                {{ lead?.user?.name }}
                                {{ lead?.user?.first_surname }}
                                {{
                                    lead?.user?.second_surname
                                        ? lead?.user?.second_surname
                                        : ''
                                }}
                            </p>
                            <div class="badges d-flex justify-content-center">
                                <span
                                    *ngIf="lead?.user?.blacklisted"
                                    class="badge badge-pill badge-dark"
                                    >Blacklist</span
                                >
                                <span
                                    *ngIf="lead?.user?.dropout"
                                    class="badge badge-pill badge-warning"
                                    >Drop out</span
                                >
                                <span
                                    *ngIf="lead?.user?.is_asnef"
                                    class="badge badge-pill badge-danger"
                                    >ASNEF</span
                                >
                            </div>
                        </div>
                    </div>
                </customer>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
                <mobile>
                    <div class="card mb-3">
                        <div class="card-header">
                            <h5 class="card-header-title text-center">
                                Mobile
                            </h5>
                        </div>
                        <div class="card-body">
                            <p
                                class="text-center"
                                [innerHTML]="formatPhone(lead?.mobile_phone)"
                            ></p>
                        </div>
                    </div>
                </mobile>
            </div>
        </div>
    </section>

    <router-outlet></router-outlet>
</div>
