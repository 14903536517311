<div class="filter-tabs d-flex justify-content-end flex-wrap">
    <a
        *ngFor="let item of itemsList?.menu"
        class="filter-tabs__item card bg-light text-center"
        [ngClass]="{
            'bg-secondary': item.slug === itemsList.secondaryItem,
            'bg-light': item.slug != itemsList.secondaryItem
        }"
        [routerLink]="['../' + item.slug]"
        routerLinkActive="is_active"
        >{{ item?.label }}</a
    >
</div>
