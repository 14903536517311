import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { LeadTrackingService } from '../../../services/lead-tracking.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
    selector: 'app-operation-nav-bar',
    templateUrl: './operation-nav-bar.component.html',
    styleUrls: ['./operation-nav-bar.component.scss'],
})
export class OperationNavBarComponent implements OnInit, OnChanges {
    @Input() lead = null;
    @Input() creditLine = null;
    pending: boolean = true;
    loading = false;

    messageUrl = '';
    user_id;

    trackings = null;

    formModel = {
        sms: {
            templates: [],
            form: new FormGroup({
                type: new FormControl('', Validators.required),
                text: new FormControl(
                    { value: '', disabled: true },
                    Validators.maxLength(160)
                ),
            }),
            maxlength: 160,
            placeholders: {
                type: 'Select SMS type',
                text: 'Type text to send(max 160 characters) with no accents',
            },
            errors: {
                type: 'Please choose SMS type.',
                text: 'Please type SMS text.',
            },
        },
        email: {
            templates: [],
            form: new FormGroup({
                type: new FormControl('', Validators.required),
                text: new FormControl({ value: '', disabled: true }),
            }),
            maxlength: 5000,
            placeholders: {
                type: 'Select Email type',
                text: 'Type text to send',
            },
            errors: {
                type: 'Please choose Email type.',
                text: 'Please type Email text.',
            },
        },
    };

    openModal;
    pageFilter = '';

    constructor(
        private errorMessageService: ErrorMessageService,
        private leadTrackingService: LeadTrackingService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.setForm(this.formModel.sms.form, 'sms');
        this.setForm(this.formModel.email.form, 'email');
    }
    ngOnChanges() {
        if (this.lead) {
            const { id, user_id } = this.lead;
            this.genMessagesLink(id, user_id);
        }
        if (this.creditLine) {
            const { lead_id, user_id } = this.creditLine;
            this.genMessagesLink(lead_id, user_id, 'loan');
        }

        console.log(this.lead);
        console.log(this.creditLine);
    }

    open(content, type) {
        switch (type) {
            case 'tracking':
                if (this.lead) {
                    this.getTrackingLead();
                }
                break;
            default:
                if (!this.formModel[type].templates.length)
                    this.getTemplatesList(type);
                this.openModal = type;
                break;
        }

        this.modalService.open(content, { size: 'lg' });
    }
    getTrackingLead(page = 0) {
        this.loading = true;
        this.setPageFilter(page);

        this.leadTrackingService.trackings = null;
        this.leadTrackingService
            .getAllPaginatedByLeadId(this.lead.id, this.pageFilter)
            .then(() => {
                this.trackings = this.leadTrackingService.trackings;
            });

        this.loading = false;
    }

    setPageFilter(page) {
        this.pageFilter = '';
        if (page) {
            this.pageFilter = 'page=' + page;
        }
    }

    getTemplatesList(type) {
        this.leadTrackingService
            .getTemplates(type)
            .toPromise()
            .then((result) => {
                if (result.length) {
                    this.formModel[type].templates = result;
                }
            });
    }

    formSubmit(type) {
        const submitForm = this.formModel[type].form.getRawValue();
        let data = {
            lead_id: this.lead ? this.lead.id : this.creditLine.lead_id,
            type_id: Number(submitForm.type),
            message: submitForm.text,
            added_by: 1,
        };

        this.leadTrackingService.sendSms(data).subscribe(
            (result) => {
                if (result) {
                    this.formModel[type].form.reset({
                        type: '',
                        text: { value: '', disabled: true },
                    });
                    this.errorMessageService.showSuccess(
                        type.toUpperCase() + ' Send succesfully!'
                    );
                }
            },
            (error) => {
                if (error.error.error) {
                    this.errorMessageService.showError(
                        error.error.error.show_message
                    );
                }
            }
        );
    }

    setForm(form, type) {
        form.get('type').valueChanges.subscribe((val) => {
            const formText = form.get('text');
            const formList = this.formModel[type].templates;
            const listIndex = Number(val) - 1;
            if (
                listIndex >= 0 &&
                formList[listIndex].parameters === 'content'
            ) {
                formText.enable();
                formText.setValue('');
            } else {
                formText.disable();
                if (val) {
                    formText.setValue(formList[listIndex].content);
                }
            }
        });
    }

    genMessagesLink(id, user_id, type: 'lead' | 'loan' = 'lead') {
        this.messageUrl =
            type === 'lead'
                ? `/backoffice/underwriting/overview/${id}/messages/${user_id}`
                : `/backoffice/loans/overview/${id}/messages/${user_id}`;
    }
}
