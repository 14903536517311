<div class="mb-3">
    <div class="mb-4">
        <div class="row justify-content-between">
            <div class="col-auto"><h2>Messages Providers Statuses</h2></div>
            <div class="col-auto">
                <app-mail-providers-statuses-create
                    (itemCreated)="onItemCreated($event)"
                ></app-mail-providers-statuses-create>
            </div>
        </div>
    </div>

    <app-mail-providers-statuses-list
        [newItem]="item"
    ></app-mail-providers-statuses-list>
</div>
