import { Component, OnInit, Input } from '@angular/core';
import { FormatPhoneNumberService } from '../../../services/format-phone-number.service';

@Component({
  selector: 'app-lead-info-summary',
  templateUrl: './lead-info-summary.component.html',
  styleUrls: ['./lead-info-summary.component.css']
})
export class LeadInfoSummaryComponent implements OnInit {

  @Input() lead: any = null;

  constructor(private formatPhoneService: FormatPhoneNumberService) { }

  ngOnInit() {
  }

  formatPhone(phone) {
    if (phone) {
      return this.formatPhoneService.format(phone);
    }
  }

}
