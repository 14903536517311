import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DebtRecoveryService } from '../../../../services/debt-recovery.service';
import { take } from 'rxjs/operators';
import { ErrorMessageService } from '../../../../../shared/error-message.service';

@Component({
  selector: 'app-payment-tree-item',
  templateUrl: './payment-tree-item.component.html',
  styleUrls: ['./payment-tree-item.component.scss'],
})
export class PaymentTreeItemComponent implements OnInit, OnChanges {
  @Input() tree: any = null;
  @Input() isActive: boolean = false;

  @Output() deleted: EventEmitter<number> = new EventEmitter<number>();

  url: string;
  editing = false;
  isSending = false;

  form: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private elRef: ElementRef,
    private debtRecoveryService: DebtRecoveryService,
    private errorService: ErrorMessageService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const isActive = changes.isActive;

    if (changes.tree) {
      this.url = `/backoffice/settings/payment-tree/${this.tree.id}`;
      this.buildForm();
      this.setStartFormValues(this.tree);
    }

    if (isActive && !isActive.currentValue && this.editing) {
      this.editing = false;
      this.setStartFormValues(this.tree);
      this.elRef.nativeElement.querySelectorAll(
        '.payment-tree__title'
      )[0].innerText = this.tree.name;
      this.elRef.nativeElement.querySelectorAll(
        '.payment-tree__description'
      )[0].innerText = this.tree.description;
    }
  }

  switchEdit(e) {
    e.preventDefault();

    if (!this.editing) {
      this.router.navigate([this.url]);
    }

    if (this.editing) {
      this.setStartFormValues(this.tree);
      this.elRef.nativeElement.querySelectorAll(
        '.payment-tree__title'
      )[0].innerText = this.tree.name;
      this.elRef.nativeElement.querySelectorAll(
        '.payment-tree__description'
      )[0].innerText = this.tree.description;
    }

    this.editing = !this.editing;
    this.switchEnabled(this.editing);
  }

  buildForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      enabled: [false],
    });
  }

  setStartFormValues(tree) {
    this.form.controls.name.setValue(tree.name);
    this.form.controls.description.setValue(tree.description);
    this.form.controls.enabled.setValue(!!tree.enabled);

    this.switchEnabled(this.editing);
  }

  onSubmit() {
    const data = this.form.getRawValue();

    if (
      this.tree.name !== data.name ||
      this.tree.description !== data.description ||
      !!this.tree.enabled !== !!data.enabled
    ) {
      this.isSending = true;

      this.debtRecoveryService
        .updatePaymentTreeAmount(this.tree.id, data)
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.editing = !this.editing;
            this.switchEnabled(this.editing);

            this.errorService.showSuccess('Tree updated successfuly!');
            this.tree = response;
            this.tree.description = this.tree.description
              ? this.tree.description
              : '';
            this.isSending = false;
          },
          (err) => {
            this.errorService.showError(err.error.error.show_message);
            this.isSending = false;
          }
        );
    }
  }

  onKeyup(e, formControl: string) {
    const value = e.target.innerText;
    const control = this.form.get(formControl);

    control.setValue(value);
    control.markAsTouched();
  }

  switchEnabled(editing) {
    editing
      ? this.form.controls.enabled.enable()
      : this.form.controls.enabled.disable();
  }

  delete(e) {
    e.preventDefault();

    this.isSending = true;
    if (confirm('Do you want to delete Tree?')) {
      this.debtRecoveryService
        .deletePaymentTreeAmount(this.tree.id)
        .pipe(take(1))
        .subscribe(
          (response) => {
            if (response) {
              this.isSending = false;
              this.errorService.showSuccess('Tree deleted Successfuly!');

              this.deleted.emit(this.tree.id);
            }
          },
          (err) => {
            this.isSending = false;
            if (err) {
              this.errorService.showError(err.error.error.show_message);
            } else {
              this.errorService.showError('Error Genérico');
            }
          }
        );
    } else {
      this.isSending = false;
    }
  }
}
