import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { CheckNavBarService } from '../../../services/check-nav-bar.service';

@Component({
    selector: 'app-lead-anti-fraud',
    templateUrl: './lead-anti-fraud.component.html',
    styleUrls: ['./lead-anti-fraud.component.css']
})
export class LeadAntiFraudComponent implements OnInit {
    id: any = '';
    validation_discard_antifraud: any = null;
    validation_advising_antifrauds: any = null;
    bank_owner;
    loading = true;
    constructor(
        private activate: ActivatedRoute,
        private leadService: LeadService,
        private checkNavBarService: CheckNavBarService
    ) {}

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];
            this.getbankAggregationShow(this.id);
            this.getAntifraudShow(this.id);

            this.loading = false;

            this.activate.parent.url.subscribe(url => {
                if (url[0].path === 'loan-check') {
                    this.checkNavBarService.antifraud();
                    /*
                        this.leadService.getCreditLineCheck(this.lead.id).subscribe(
                        _lead => {
                          if(Object.values(_lead).length){
                              this.credit_line_id = _lead.credit_line.id;
                                this.checkNavBarService.antifraud();
                          }
                        }
                      );
                        */
                }
            });
        });
    }

    getbankAggregationShow(lead_id) {
        this.leadService.bankAggregationShow(lead_id).then(response => {
            this.bank_owner = response;
        });
    }

    getAntifraudShow(lead_id) {
        this.leadService
            .antifraudShow(lead_id)
            .toPromise()
            .then(antifraud => {
                if (Object.values(antifraud).length) {
                    this.validation_advising_antifrauds = antifraud.advising;
                    this.validation_discard_antifraud = antifraud.discard;
                }
            });
    }
}
