<div class="card mb-3">
    <div class="card-header">
        <h5 class="card-header-title">Bank aggregation info</h5>
    </div>
    <div class="card-body">
        <ul class="list-group">
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Full name</strong
                ><span class="text-right">{{
                    bankAggregation?.full_name
                }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Mobile Phone</strong
                ><span class="text-right">{{ bankAggregation?.phone }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Fix Phone</strong
                ><span class="text-right">{{
                    bankAggregation?.fix_phone
                }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Personal ID</strong
                ><span class="text-right">{{
                    bankAggregation?.personalid
                }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Birth Date</strong
                ><span class="text-right">{{
                    bankAggregation?.birth_date
                }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Job Role</strong
                ><span class="text-right">{{ bankAggregation?.job }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Email</strong
                ><span class="text-right">{{ bankAggregation?.email }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Address</strong
                ><span class="text-right">{{ bankAggregation?.address }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Zip Code</strong
                ><span class="text-right">{{ bankAggregation?.zip_code }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>City</strong
                ><span class="text-right">{{ bankAggregation?.city }}</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <strong>Town</strong
                ><span class="text-right">{{ bankAggregation?.town }}</span>
            </li>
        </ul>
    </div>
</div>
