import { Injectable, ElementRef, ViewChild, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Directive()
@Injectable()
export class CreditLineService {
    public creditline: any;
    @ViewChild('loan_check_reject')
    loan_check_reject: ElementRef;
    @ViewChild('loan_active') loan_active: ElementRef;

    constructor(private http: HttpClient) {
        this.creditline = [];
    }

    active(loan_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/${loan_id}/activation/emit`
        );
    }
    reject(loan_id): Observable<any> {
        return this.http.get(environment.api_url + `/loan/${loan_id}/reject`);
    }
    getActive(): Observable<any> {
        return this.http.get(environment.api_url + '/product/active/show');
    }
    isActive(): Observable<any> {
        return this.http.get(environment.api_url + '/loan/is_active');
    }
    get(): Observable<any> {
        return this.http.get(environment.api_url + '/loan/show');
    }
    getSummary(): Observable<any> {
        return this.http.get(environment.api_url + '/loan/summary');
    }
    exists(): Observable<any> {
        return this.http.get(environment.api_url + '/loan/exists');
    }
    hasEarlyResolution(): Observable<any> {
        return this.http.get(
            environment.api_url + '/loan/has_early_resolution'
        );
    }
    getAllByUserId(user_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/user/${user_id}/loan/list`
        );
    }
    getStatus(): Observable<any> {
        return this.http.get(environment.api_url + `/loan/status/list`);
    }
    getAll(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/list?${filterUrlParams}`
        );
    }
    getByStatus(status_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/list?status_id=${status_id}`
        );
    }
    getByLeadId(lead_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/lead/${lead_id}/loan/show`
        );
    }
    getByLeadIdWithRelationshipedEntities(lead_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/lead/${lead_id}/loan/show`
        );
    }
    getAllCheckingId(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/list?status_id=3&${filterUrlParams}`
        );
    }
    canCheck(loan_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/${loan_id}/activation/can_check`
        );
    }
    checkDocPersonalidFrontFace(loan_id): Observable<any> {
        let data = {
            doc_personalid_front_face: 1,
            loan_id: loan_id,
        };
        return this.http.post(
            environment.api_url + `/loan/activation/check`,
            data
        );
    }
    checkDocPersonalidBackFace(loan_id): Observable<any> {
        return this.http.post(environment.api_url + `/loan/activation/check`, {
            doc_personalid_back_face: 1,
            loan_id: loan_id,
        });
    }
    checkDocCreditCardSelfie(loan_id): Observable<any> {
        return this.http.post(environment.api_url + `/loan/activation/check`, {
            doc_credit_card_selfie: 1,
            loan_id: loan_id,
        });
    }
    checkDocDocumentConfirmingAddress(loan_id): Observable<any> {
        return this.http.post(environment.api_url + `/loan/activation/check`, {
            doc_document_confirming_address: 1,
            loan_id: loan_id,
        });
    }
    checkCustomerInfo(loan_id): Observable<any> {
        return this.http.post(environment.api_url + `/loan/activation/check`, {
            customer_info: 1,
            loan_id: loan_id,
        });
    }
    checkAntifraud(loan_id): Observable<any> {
        return this.http.post(environment.api_url + `/loan/activation/check`, {
            antifraud: 1,
            loan_id: loan_id,
        });
    }
    setChecking(loan_id): Observable<any> {
        return this.http.post(environment.api_url + `/loan/activation/check`, {
            loan_id: loan_id,
        });
    }
    operationSummary(loan_id, page = 0) {
        return this.http
            .get(
                environment.api_url + `/loan/${loan_id}/quota/list?page=${page}`
            )
            .toPromise();
    }
    getAllDebtLeftSummary(loan_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/${loan_id}/summary_debt_left/show`
        );
    }
    sentReason(loan_id, reason_id) {
        return this.http.post(environment.api_url + '/loan/activation/reject', {
            loan_id: loan_id,
            reason_id: Number(reason_id),
        });
    }
    getReasons(): Observable<any> {
        return this.http.get(environment.api_url + '/loan/status_reason/list');
    }
    getLoanByLeadId(lead_id) {
        return this.http.get(
            environment.api_url + `/lead/${lead_id}/loan/show`
        );
    }

    getQuotaPaymentList(quota_id) {
        return this.http.get(
            environment.api_url + `/quota/${quota_id}/payment/list`
        );
    }

    getPaymentDetail(payment_id) {
        return this.http.get(
            environment.api_url + `/payment/${payment_id}/detail/show`
        );
    }

    getPaymentsRequests(quota_id) {
        return this.http.get(
            environment.api_url + `/quota/${quota_id}/payment_request/list`
        );
    }

    contactTracking(loan_id): Observable<any> {
        return this.http.get(
            environment.api_url + `/tracking/contact/loan/${loan_id}/list`
        );
    }

    getByCodeStr(codeStr): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/${codeStr}/search/list`
        );
    }

    getLoansExport(filterUrlParams): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return this.http
            .get(environment.api_url + `/loan/all/export?${filterUrlParams}`, {
                headers: headers,
                responseType: 'blob',
            })
            .toPromise();
    }

    getById(id): Promise<any> {
        return this.http
            .get(environment.api_url + `/loan/${id}/show`)
            .toPromise();
    }

    getLoansTotal(filterUrlParams): Observable<any> {
        return this.http.get(
            environment.api_url + `/loan/amount/total?${filterUrlParams}`
        );
    }
}
