import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../../../shared/error-message.service';
import { LegalMonitoringService } from '../../../../../services/legal-monitoring.service';

type Situaion = {
    description: string;
    name: string;
    id: number;
};

type Action = 'create' | 'edit' | 'close';

@Component({
    selector: 'app-presentation-create',
    templateUrl: './presentation-create.component.html',
    styleUrls: ['./presentation-create.component.scss'],
})
export class PresentationCreateComponent implements OnInit {
    @Input() proceedingId: number;
    @Input() action: Action = 'create';
    @Input() presentation: any = null;

    @Output() changed = new EventEmitter<boolean>();
    @Output() edited = new EventEmitter<any>();

    form: FormGroup;
    isSending = false;

    situations: Situaion[] = [];
    isSituationsLoading = false;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private legalMonitoringService: LegalMonitoringService,
        private errorService: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    open(content, action: Action) {
        this.modalService.open(content, {
            size: action === 'create' || action === 'edit' ? 'lg' : 'sm',
            backdrop: 'static',
            scrollable: true,
        });

        this.initForm(action);
    }

    initForm(action: Action) {
        if (action === 'create' || action === 'edit') {
            this.form = this.fb.group({
                proceeding_id: [this.proceedingId, Validators.required],
                start_at: [
                    this.presentation?.start_at
                        ? this.formatDate(this.presentation.start_at)
                        : '',
                    Validators.required,
                ], // Fecha de presentacion
                status_id: [
                    this.presentation?.status_id || '',
                    Validators.required,
                ], // Situacion
                comment: [this.presentation?.comment || ''],
                tribunal_code: [this.presentation?.tribunal_code || ''], // Juzgado
                tribunal_proceeding_reference: [
                    this.presentation?.tribunal_proceeding_reference || '',
                ], // Referencia Procedimiento
                tribunal_proceeding_execution_reference: [
                    this.presentation
                        ?.tribunal_proceeding_execution_reference || '',
                ], // Referencia Ejecución
                tribunal_amount_accepted: [
                    +this.presentation?.tribunal_amount_accepted || '',
                ], // Importe Aceptado
                tribunal_hearing_at: [
                    this.presentation?.tribunal_hearing_at
                        ? this.formatDate(
                              this.presentation?.tribunal_hearing_at
                          )
                        : '',
                ], // Fecha de la Vista
                tribunal_population: [
                    this.presentation?.tribunal_population || '',
                ],
                tribunal_acceptance_amount_proposal_auto_date: [
                    this.presentation
                        ?.tribunal_acceptance_amount_proposal_auto_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_acceptance_amount_proposal_auto_date
                          )
                        : '',
                ],
                tribunal_proceeding_verbal_reference: [
                    this.presentation?.tribunal_proceeding_verbal_reference ||
                        '',
                ],
                tribunal_document_type_title: [
                    this.presentation?.tribunal_document_type_title || '',
                ],
                tribunal_debtor_company_cif: [
                    this.presentation?.tribunal_debtor_company_cif || '',
                ],
                tribunal_providence_date: [
                    this.presentation?.tribunal_providence_date
                        ? this.formatDate(
                              this.presentation?.tribunal_providence_date
                          )
                        : '',
                ],
                tribunal_providence_number: [
                    this.presentation?.tribunal_providence_number || '',
                ],
                tribunal_providence_reception_date: [
                    this.presentation?.tribunal_providence_reception_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_providence_reception_date
                          )
                        : '',
                ],
                tribunal_approval_execution_sentence_decree_number: [
                    this.presentation
                        ?.tribunal_approval_execution_sentence_decree_number ||
                        '',
                ],
                tribunal_approval_execution_sentence_decree_date: [
                    this.presentation
                        ?.tribunal_approval_execution_sentence_decree_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_approval_execution_sentence_decree_date
                          )
                        : '',
                ],
                tribunal_approval_execution_sentence_decree_reception_date: [
                    this.presentation
                        ?.tribunal_approval_execution_sentence_decree_reception_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_approval_execution_sentence_decree_reception_date
                          )
                        : '',
                ],
                tribunal_execution_sentence_number: [
                    this.presentation?.tribunal_execution_sentence_number || '',
                ],
                tribunal_execution_sentence_date: [
                    this.presentation?.tribunal_execution_sentence_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_execution_sentence_date
                          )
                        : '',
                ],
                tribunal_execution_sentence_reception_date: [
                    this.presentation
                        ?.tribunal_execution_sentence_reception_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_execution_sentence_reception_date
                          )
                        : '',
                ],
                tribunal_liquidation_legal_interest_strat_date: [
                    this.presentation
                        ?.tribunal_liquidation_legal_interest_strat_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_liquidation_legal_interest_strat_date
                          )
                        : '',
                ],
                tribunal_liquidation_legal_interest_end_date: [
                    this.presentation
                        ?.tribunal_liquidation_legal_interest_end_date
                        ? this.formatDate(
                              this.presentation
                                  ?.tribunal_liquidation_legal_interest_end_date
                          )
                        : '',
                ],
                tribunal_liquidation_legal_interest_amount: [
                    this.presentation
                        ?.tribunal_liquidation_legal_interest_amount || '',
                ],
                tribunal_liquidation_legal_interest_thirty_percent_amount: [
                    this.presentation
                        ?.tribunal_liquidation_legal_interest_thirty_percent_amount ||
                        '',
                ],
            });
        }

        if (action === 'close') {
            this.form = this.fb.group({
                proceeding_id: [this.proceedingId, Validators.required],
                end_at: ['', Validators.required], // Fecha de presentacion
                status_id: ['', Validators.required], // Situacion
                comment: [''],
            });
        }

        if (!this.situations.length) {
            this.getStatuses();
        }
    }

    getStatuses() {
        this.isSituationsLoading = true;
        this.form.controls.status_id.disable();
        this.legalMonitoringService
            .getSituations()
            .toPromise()
            .then((response: Situaion[]) => {
                this.situations = response;
                this.isSituationsLoading = false;
                this.form.controls.status_id.enable();
            });
    }

    onSubmit(action: Action) {
        this.isSending = true;
        this.form.disable();
        const params = this.form.getRawValue();

        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'string') {
                params[key] = params[key].trim();
            }
            if (
                params[key] === null ||
                params[key] === undefined ||
                params[key] === ''
            ) {
                delete params[key];
            }
        });

        switch (action) {
            case 'create':
                this.create(params);
                break;
            case 'edit':
                this.edit(params);
                break;
            case 'close':
                this.close(params);
                break;
        }
    }

    create(params) {
        this.legalMonitoringService
            .createPresentation(params)
            .then(() => {
                this.changed.emit(true);
                this.errorService.showSuccess('Presentado con Éxito.');
                this.modalService.dismissAll();
                this.enableForm();
            })
            .catch(this.showErrors);
    }

    edit(params) {
        this.legalMonitoringService
            .editPresentation(this.presentation.id, params)
            .then((response) => {
                this.edited.emit(response);
                this.errorService.showSuccess('Guardando con Éxito.');
                this.enableForm();
            })
            .catch(this.showErrors);
    }

    close(params) {
        if (confirm('¿Estás seguro(a) de realizar esta operación?')) {
            this.legalMonitoringService
                .closePresentation(this.presentation.id, params)
                .then(() => {
                    this.changed.emit(true);
                    this.errorService.showSuccess('Terminado con Éxito.');
                    this.modalService.dismissAll();
                    this.enableForm();
                })
                .catch(this.showErrors);
        } else {
            this.enableForm();
        }
    }

    private enableForm() {
        this.isSending = false;
        this.form.enable();
    }

    private showErrors = (err) => {
        const { show_message, details } = err?.error?.error;
        const message =
            details && Object.keys(details).length
                ? Object.keys(details)
                      .map((key) => details[key].join('<br />'))
                      .join('<br />')
                : '';

        this.errorService.showError(message, show_message || 'Service Error', {
            timeOut: 6000,
            enableHtml: true,
        });
        this.isSending = false;
        this.form.enable();
    };

    switchTitle(action: Action) {
        switch (action) {
            case 'create':
                return 'Crear';
            case 'edit':
                return 'Editar';
            case 'close':
                return 'Terminar';
        }
    }

    formatDate(date: string = ''): string {
        return date.includes('/')
            ? date.replace(' 00:00:00', '').split('/').reverse().join('-')
            : date.replace(' 00:00:00', '');
    }
}
