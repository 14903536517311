<div class="mb-3">
  <div class="mb-4">
    <div class="row justify-content-between">
      <div class="col-auto"><h2>Templates</h2></div>
      <div class="col-auto">
        <a
          routerLink="/backoffice/settings/mail-templates/create"
          class="btn btn-sm btn-primary"
          >Create New</a
        >
      </div>
    </div>
  </div>

  <div class="table-responsive table-hover">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Content</th>
          <th>Published</th>
          <th>Published At</th>
          <th>Active</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        <ng-template [ngIf]="tableData?.length > 0 && !loading">
          <tr *ngFor="let item of tableData">
            <td>
              <a
                [routerLink]="'/backoffice/settings/mail-templates/' + item.id"
                >{{ item?.name }}</a
              >
            </td>
            <td>{{ item?.description }}</td>
            <td>
              {{ item?.content | slice: 0:150
              }}<span *ngIf="item?.content?.length > 150">...</span><br />
              <a
                href="#0"
                *ngIf="item?.content"
                (click)="open($event, item.id, content)"
                >Preview</a
              >
            </td>
            <td>
              <span *ngIf="item?.published" class="badge badge-success"
                >Yes</span
              >
              <app-mail-templates-publishing
                *ngIf="!item?.published"
                [id]="item?.id"
                [type]="type"
                (published)="onPublish($event)"
              ></app-mail-templates-publishing>
            </td>
            <td>
              {{ formatDate(item?.published_at) | date: 'dd/MM/yyyy HH:mm' }}
            </td>
            <td>
              <app-mail-templates-activation
                [id]="item?.id"
                [enabled]="item?.enabled"
                [type]="type"
              ></app-mail-templates-activation>
            </td>
            <td>
              {{ formatDate(item?.created_at) | date: 'dd/MM/yyyy HH:mm' }}
            </td>
          </tr>
        </ng-template>
        <ng-template [ngIf]="!tableData?.length && !loading">
          <tr class="without_hover">
            <td colspan="3">
              <p class="py-5 mb-0">Nothing Found</p>
            </td>
          </tr>
        </ng-template>
        <tr *ngIf="loading" class="without_hover">
          <td colspan="3">
            <p class="py-5 mb-0">
              <img
                width="30px"
                height="30px"
                src="assets/img/backoffice/loading.gif"
              />
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Template Preview</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="selectedTemplate"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
      [routerLink]="'/backoffice/settings/mail-templates/' + selectedTemplateId"
    >
      Edit
    </button>
  </div>
</ng-template>
