import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    transform(value: any): any {
        if (value) {
            return new Date(value.replace(/-/g, '/'));
        }
        return null;
    }

}
