import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadService } from '../../../services/lead.service';
import { DocsService } from '../../../services/docs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-lead-docs',
    templateUrl: './lead-docs.component.html',
    styleUrls: ['./lead-docs.component.css']
})
export class LeadDocsComponent implements OnInit {
    id: any = '';
    contract: any = null;
    euform: any = null;
    url: string = '';
    fileURL: any;
    loading: boolean = false;
    constructor(
        private activate: ActivatedRoute,
        private router: Router,
        private docsService: DocsService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.activate.params.subscribe(params => {
            this.id = params['id'];
            this.getDocs(this.id);
        });
    }
    open(content) {
        this.modalService.open(content, { size: 'lg' });
    }
    getDocs(lead_id) {
        this.docsService.getLeadDocs(lead_id).subscribe(docs => {
            if (Object.values(docs).length) {
                this.contract = docs.contract;
                this.euform = docs.eu_form;
            }
        });
    }

    viewContract() {
        if (this.contract) {
            this.loading = true;
            this.docsService.view(this.id).subscribe(pdf => {
                let file = new Blob([pdf], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                this.url = this.fileURL;
                this.loading = false;
            });
        }
    }
    viewEuform() {
        if (this.euform) {
            this.loading = true;
            this.docsService.viewEuform(this.id).subscribe(pdf => {
                let file = new Blob([pdf], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                this.url = this.fileURL;
                this.loading = false;
            });
        }
    }
}
