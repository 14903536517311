<div class="margin-top-20 table-responsive">
    <table class="table table-bordered table-hover" *ngIf="!loading">
        <img *ngIf="loading" width="50px" src="/assets/img/backoffice/loading.gif" />
        <thead class="table-head bg-green-light">
            <th width="20%">To</th>
            <th width="20%">Send</th>
            <th width="40%">Subject</th>
            <td>Type</td>
            <th width="20%">Added By</th>
        </thead>
        <tbody *ngIf="!loading">
            <tr *ngFor="let email of email_list?.data">
                <td>{{ email?.email }}</td>
                <td>{{ email?.created_at }}</td>
                <td>{{ email?.content }}</td>
                <td>{{ email?.type?.description }}</td>
                <td>{{ email?.sent_by?.name + ' ' + email?.sent_by?.first_surname }}</td>
            </tr>
        </tbody>
    </table>
</div>
