<div *appHasPermissions="['kpis']">
    <div class="container">
        <ul class="nav nav-pills mb-3">
            <li *ngFor="let item of menu" class="nav-item">
                <!-- *appHasPermissions="['kpis-' + item]" -->
                <span>
                    <a
                        class="nav-link"
                        [routerLink]="item?.href"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >{{ item?.title }}</a
                    >
                </span>
            </li>
        </ul>
    </div>

    <router-outlet></router-outlet>
</div>
