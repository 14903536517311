<div *appHasPermissions="['marketing']">
    <div class="row justify-content-between mb-3">
        <div class="col-6">
            <h2 class="mb-0">Affiliates Manager</h2>
        </div>
        <div class="col-auto ml-auto"><app-create-affiliate></app-create-affiliate></div>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="affiliates?.current_page"
        [from]="affiliates?.from"
        [to]="affiliates?.to"
        [total]="affiliates?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Affiliate</th>
                    <th>Source</th>
                    <th>Active</th>
                    <th>Pixel</th>
                    <th>Api</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="tr-no-hover">
                    <td>
                        <input
                            type="text"
                            placeholder="Affiliate"
                            name="name"
                            [(ngModel)]="filters.name"
                            (keyup.enter)="getDataOnFilterSubmit()"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            placeholder="Source"
                            name="source_name"
                            [(ngModel)]="filters.source_name"
                            (keyup.enter)="getDataOnFilterSubmit()"
                        />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-template [ngIf]="affiliates?.data?.length > 0 && !loading">
                    <tr *ngFor="let affiliate of affiliates?.data">
                        <td>
                            <a [routerLink]="['/backoffice/marketing/affiliate_info/' + affiliate.id]">{{
                                affiliate?.name
                            }}</a>
                        </td>
                        <td>
                            <ng-template ngFor let-last="last" let-source [ngForOf]="affiliate?.sources">
                                {{ source?.key }}<span *ngIf="!last">, </span>
                            </ng-template>
                        </td>
                        <td>{{ affiliate?.enabled ? 'On' : 'Off' }}</td>
                        <td>{{ affiliate?.pixel ? 'Yes' : 'No' }}</td>
                        <td>{{ affiliate?.api ? 'Yes' : 'No' }}</td>
                        <td>
                            <a
                                [routerLink]="['/backoffice/marketing/affiliate_info/' + affiliate.id]"
                                style="text-decoration: none"
                            >
                                <i
                                    class="fa fa-eye d-block"
                                    style="font-size: 2rem; cursor: pointer; line-height: 24px;"
                                ></i>
                            </a>
                        </td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!affiliates?.data?.length && !loading">
                    <tr>
                        <td colspan="3">
                            <p class="py-5 mb-0">
                                Nothing Found
                            </p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img width="30px" height="30px" src="assets/img/backoffice/loading.gif" />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="15"
        [currentPage]="affiliates?.current_page"
        [from]="affiliates?.from"
        [to]="affiliates?.to"
        [total]="affiliates?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
