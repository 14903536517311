<div *appHasPermissions="['legal']">
    <!-- creditline tab content -->
    <creditline *ngIf="show">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-6 panel-main-action">
                    <div class="row">
                        <div
                            *appHasPermissions="['legal-loan-all']"
                            class="col-lg-2 col-md-3 col-sm-6 panel-main-separator"
                        >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/legal/loan/due'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Loans
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *appHasPermissions="['legal-loan-trial']"
                            class="col-lg-2 col-md-3 col-sm-6"
                        >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/legal/loan/trial'
                                                )
                                            "
                                        >
                                            <img
                                                class="align-middle"
                                                width="90"
                                                height="90"
                                                src="assets/img/backoffice/trial_loans.svg"
                                                alt="Generic placeholder image"
                                            />
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Trial Loans
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel-section">
                                        <div
                                            class="container-description"
                                            (click)="
                                                goto(
                                                    '/backoffice/legal/monitoring'
                                                )
                                            "
                                        >
                                            <div
                                                class="text-white d-flex align-items-center justify-content-center"
                                                style="
                                                    background: #783270;
                                                    width: 90px;
                                                    height: 90px;
                                                    border-radius: 50%;
                                                    font-size: 60px;
                                                    line-height: 1em;
                                                    font-weight: bold;
                                                "
                                            >
                                                M
                                            </div>
                                            <div
                                                class="icon__text text-primary"
                                            >
                                                Monitorios
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </creditline>
    <!-- creditline tab content end -->
    <router-outlet></router-outlet>
</div>
