<div class="process-payment" *appHasPermissions="['loan-rectification']">
    <button
        type="button"
        class="btn btn-accept btn-process-payment"
        (click)="open(modal)"
    >
        Loan Rectification
    </button>
</div>

<ng-template #modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Loan Rectification <small class="text-primary">{{ loan_id }}</small>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body">
            <div class="form-group mb-0">
                <label for="type">Type:</label>
                <select
                    id="type"
                    class="form-control"
                    [(ngModel)]="type"
                    name="type"
                    #typeControl="ngModel"
                    required
                    (change)="onTypeChange()"
                >
                    <option value="">Select type</option>
                    <option *ngFor="let key of types | keys" [value]="key">
                        {{ types[key]?.name }}
                    </option>
                </select>
                <small
                    class="text-danger text-danger--left"
                    *ngIf="typeControl.invalid && typeControl.touched"
                >
                    Type is required.
                </small>
            </div>
            <div
                class="form-group mt-3 mb-0"
                *ngIf="types[type]?.reasones.length"
            >
                <label for="reason">Reason:</label>
                <select
                    id="reason"
                    class="form-control"
                    [(ngModel)]="reason"
                    name="reason"
                    #reasonControl="ngModel"
                    required
                >
                    <option value="">Select Reason</option>
                    <option
                        *ngFor="let reason of types[type].reasones"
                        [value]="reason.id"
                    >
                        {{ reason.name }}
                    </option>
                </select>
                <small
                    class="text-danger text-danger--left"
                    *ngIf="reasonControl.invalid && reasonControl.touched"
                >
                    Reason is required.
                </small>
            </div>
            <!--<div class="form-group mb-0">
                <label for="type">Amount:</label>
                <input
                    type="number"
                    name="amount"
                    class="form-control"
                    placeholder="Amount"
                    [(ngModel)]="amount"
                    #amountControl="ngModel"
                    required
                />
                <small
                    class="text-danger text-danger--left"
                    *ngIf="amountControl.invalid && amountControl.touched"
                >
                    Amount is required.
                </small>
            </div>-->
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                [disabled]="form?.invalid || isSubmiting"
                class="btn btn-primary"
            >
                {{ isSubmiting ? 'Saving...' : 'Submit' }}
            </button>
        </div>
    </form>
</ng-template>
