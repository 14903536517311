import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { TRIAL_PROCEEDING_STATUS } from '../../../../../shared/enums/legal.enum';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { CreditLineService } from '../../../../services/credit-line.service';
import { LegalMonitoringService } from '../../../../services/legal-monitoring.service';

@Component({
    selector: 'app-monitoring-show',
    templateUrl: './monitoring-show.component.html',
    styleUrls: ['./monitoring-show.component.scss'],
})
export class MonitoringShowComponent implements OnInit, OnDestroy {
    id;

    trialProceeding;
    trialProceedingLoadingError = { show: false, title: '', message: '' };

    isLoading = false;

    dataSubscriber: SubscriptionLike;
    querySubscriber: SubscriptionLike;

    isSending = false;

    TRIAL_PROCEEDING_STATUS = TRIAL_PROCEEDING_STATUS;

    loan;

    lastPresentationDocsIsLoading = false;
    lastPresentationDocs;

    constructor(
        private activatedRoute: ActivatedRoute,
        private legalMonitoringService: LegalMonitoringService,
        private errorService: ErrorMessageService,
        private loansService: CreditLineService
    ) {}

    ngOnInit(): void {
        const { id } = this.activatedRoute.snapshot.params;

        if (id) {
            this.id = id;
            this.getTrialProceedings(id);
        }
    }

    ngOnDestroy(): void {}

    getTrialProceedings(id: number) {
        this.isLoading = true;
        this.legalMonitoringService
            .getTrialProceeding(id)
            .toPromise()
            .then((response) => {
                this.trialProceeding = response;

                this.getLoanById(this.trialProceeding.loan_id);
            })
            .catch((err) => {
                this.trialProceedingLoadingError = {
                    show: true,
                    title: err?.error?.error.message,
                    message: err?.error?.error.show_message,
                };

                this.isLoading = false;
            });
    }

    toNotPresent(id: number) {
        this.toggleSending();
        this.legalMonitoringService
            .toNotPresent(id)
            .then(({ status }) => {
                this.errorService.showSuccess('Updated successfully');
                this.trialProceeding.status = status;
                this.toggleSending();
            })
            .catch((err) => {
                this.errorService.showError(err?.error?.error?.show_message);
                this.toggleSending();
            });
    }

    toggleSending() {
        this.isSending = !this.isSending;
    }

    getLoanById(loanId) {
        this.loansService.getById(loanId).then((response) => {
            if (response.length > 0) {
                this.loan = response[0];
            }

            this.isLoading = false;
        });
    }

    onPresentationChanged(e: boolean) {
        if (e) {
            this.getTrialProceedings(this.id);
        }
    }

    onPresentationEdited(e) {
        if (e) {
            this.trialProceeding.presentation = e;
        }
    }

    onProceedingEdited(e) {
        if (e) {
            this.trialProceeding = { ...this.trialProceeding, ...e };
        }
    }

    onProceedingClosed(e) {
        console.log(e);
        if (e) {
            this.getTrialProceedings(this.id);
        }
    }

    // closeProceeding() {
    //     if (confirm('¿Estás seguro(a) de realizar esta operación?')) {
    //         this.toggleSending();
    //         this.legalMonitoringService
    //             .closeProceeding(this.id)
    //             .then(() => {
    //                 this.errorService.showSuccess('Updated successfully');
    //                 this.toggleSending();
    //                 this.getTrialProceedings(this.id);
    //             })
    //             .catch((err) => {
    //                 this.errorService.showError(
    //                     err?.error?.error?.show_message
    //                 );
    //                 this.toggleSending();
    //             });
    //     }
    // }
}
