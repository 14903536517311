import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProductService {
    products: any = null;
    constructor(private http: HttpClient) {}

    get(product_id): Observable<any> {
        return this.http.get(environment.api_url + `/products/${product_id}`);
    }
    create(product): Observable<any> {
        return this.http.post(environment.api_url + '/products', product);
    }
    active(product_id): Observable<any> {
        return this.http.post(
            environment.api_url + `/products/${product_id}/active`,
            {}
        );
    }

    deActive(product_id): Observable<any> {
        return this.http.post(
            environment.api_url + `/products/${product_id}/deactive`,
            {}
        );
    }
    edit(product_id, product): Observable<any> {
        return this.http.post(
            environment.api_url + `/products/${product_id}`,
            product
        );
    }
    list() {
        return this.http
            .get(environment.api_url + `/products`)
            .subscribe((products) => {
                if (Object.values(products).length) {
                    this.products = products;
                }
            });
    }

    getContracts(): Observable<any> {
        return this.http.get(environment.api_url + `/contracts/versions`);
    }

    getEuForms(): Observable<any> {
        return this.http.get(environment.api_url + `/euforms/versions`);
    }
}
