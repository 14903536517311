import { Injectable } from '@angular/core';



@Injectable()
export class CheckNavBarService {

  /* Estados que Indican en cual de las pantallas se está chequeando */
  checkingCustomerInfo = false;
  checkingDocs = false;
  checkingAntifraud = false;
  /*********** */

  /* Indican si se muestra o no los botones */
  active = false;
  reject = true;
  check = false;
  completed = false;
/*************************** */

/* Indican cuales de los pasos se han chequeado */
  checkedPersonalIdFront = false;
  checkedPersonalIdBack = false;
  checkedSelfie = false;
  checkedAddress = false;
  checkedInfo = false;
  checkedAntifraud = false;
  /************* */

  constructor() { }

  /* Informan al servicio cual de las pantalla está activa en el momento para chequear */
  customerInfo()
  {
    this.checkingCustomerInfo = true;
    this.checkingDocs = false;
    this.checkingAntifraud = false;
  }
  docs()
  {
    this.checkingDocs = true; 
    this.checkingCustomerInfo = false;
    this.checkingAntifraud = false;
  }
  antifraud()
  {
    this.checkingAntifraud = true;
    this.checkingCustomerInfo = false;
    this.checkingDocs = false;
  }
  /****************************** */

  checked()
  {
    this.check = false;
  }


  /* HACIENDO CHECKING */

}
