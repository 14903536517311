import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-backoffice-mini-kpis',
    templateUrl: './backoffice-mini-kpis.component.html',
    styleUrls: ['./backoffice-mini-kpis.component.css']
})
export class BackofficeMiniKpisComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
