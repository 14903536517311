import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting-validation-overview',
  templateUrl: './setting-validation-overview.component.html',
  styleUrls: ['./setting-validation-overview.component.scss']
})
export class SettingValidationOverviewComponent implements OnInit {

  constructor(private router: Router) { }
  
    ngOnInit() {
      this.router.navigate(['backoffice/settings/validation_overview/discard_criterion']);
    }
  
    goto(route, event){
      if(event){
        let target = event.target || event.srcElement || event.currentTarget;
        let a = document.querySelectorAll('section[id="submenu"] a');
        for(let i=0; i<a.length; i++){
          a[i].className = 'nav-link';
        } 
        (<HTMLInputElement>target).className= "nav-link active";
      }
      this.router.navigate(['/backoffice/settings/validation_overview',route]);
    }
}
