import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lead-info-customer-lead',
  templateUrl: './lead-info-customer-lead.component.html',
  styleUrls: ['./lead-info-customer-lead.component.css']
})
export class LeadInfoCustomerLeadComponent implements OnInit {

  @Input() leads: any = null;
  @Input() loading = true;

  constructor() { }

  ngOnInit() {
  }

}
