<div>Displaying {{ from ? from : '00' }}-{{ to ? to : '00' }} of {{ total ? total : '00' }} results</div>
<ngb-pagination
    [collectionSize]="total"
    [page]="currentPage"
    [pageSize]="perPage"
    [maxSize]="15"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="clickChangePage($event)"
></ngb-pagination>
