<div>
    <div class="row justify-content-between mb-3">
        <div class="col-12 col-lg-auto">
            <h2 class="mb-0">Queue Manager</h2>
        </div>
        <div class="col-12 col-lg-auto text-right">
            <a
                [routerLink]="[
                    '/backoffice/debtrecovery/queue-manager/create-queue'
                ]"
                class="btn btn-primary"
                >Add New +</a
            >
        </div>
    </div>

    <div class="py-3">
        <a
            *ngIf="tableData?.current_page > 1"
            href="#"
            class="mr-2"
            (click)="getDataOnPageChange($event, tableData?.current_page - 1)"
            ><i class="fa fa-angle-left"></i> Prev</a
        >
        <span>Page {{ tableData?.current_page }}</span>
        <a
            *ngIf="tableData?.has_more_pages"
            href="#"
            class="ml-2"
            (click)="getDataOnPageChange($event, tableData?.current_page + 1)"
            >Next <i class="fa fa-angle-right"></i
        ></a>
    </div>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <th>Queue Name</th>
                    <th>Active</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Queue Name"
                            name="name"
                            [(ngModel)]="filters.name"
                        />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr *ngFor="let item of tableData?.data">
                        <td>
                            <a
                                [routerLink]="[
                                    '/backoffice/debtrecovery/queue-manager/collection/' +
                                        item.id
                                ]"
                                >{{ item.name }}</a
                            >
                        </td>
                        <td>{{ item?.enabled ? 'On' : 'Off' }}</td>
                        <td>
                            <a
                                [routerLink]="[
                                    '/backoffice/debtrecovery/queue-manager/collection/' +
                                        item.id
                                ]"
                                style="text-decoration: none;"
                            >
                                <i
                                    class="fa fa-eye d-block"
                                    style="
                                        font-size: 2rem;
                                        cursor: pointer;
                                        line-height: 24px;
                                    "
                                ></i>
                            </a>
                        </td>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">
                                Nothing Found
                            </p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="py-3">
        <a
            *ngIf="tableData?.current_page > 1"
            href="#"
            class="mr-2"
            (click)="getDataOnPageChange($event, tableData?.current_page - 1)"
            ><i class="fa fa-angle-left"></i> Prev</a
        >
        <span>Page {{ tableData?.current_page }}</span>
        <a
            *ngIf="tableData?.has_more_pages"
            href="#"
            class="ml-2"
            (click)="getDataOnPageChange($event, tableData?.current_page + 1)"
            >Next <i class="fa fa-angle-right"></i
        ></a>
    </div>
</div>
