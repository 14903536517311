<div class="mb-3">
  <div class="mb-4">
    <div class="row justify-content-between">
      <div class="col-auto"><h2>Messages Send Config</h2></div>
      <div class="col-auto">
        <app-mail-messages-send-config-create></app-mail-messages-send-config-create>
      </div>
    </div>
  </div>

  <app-mail-messages-send-config-list></app-mail-messages-send-config-list>
</div>
