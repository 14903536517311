import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqService } from '../../../services/faq.service';

@Component({
  selector: 'app-settings-create-faq',
  templateUrl: './settings-create-faq.component.html',
  styleUrls: ['./settings-create-faq.component.scss']
})
export class SettingsCreateFaqComponent implements OnInit {

  faqForm: FormGroup;
  fieldForm: any = null;
  rolePermissionForm: FormGroup;
  faq_id: number = null;

  
    constructor(private fb: FormBuilder, 
                private errorMessage: ErrorMessageService, 
                private activatedRoute: ActivatedRoute, 
                private router: Router, 
                private faqService: FaqService) {

        this.faqForm = fb.group({
          title: ['', Validators.required],
          content: ['', Validators.required],
          position: ['', Validators.required],
          category_id: ['', Validators.required],
          status: ['', Validators.required]
        });
  
    }
  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      if(params['id']!=null){
        this.faq_id = +params['id'];
        this.faqService.get(this.faq_id).subscribe(
          faq => {
            if(Object.values(faq).length){
              this.faqForm.controls.title.setValue(faq.title);
              this.faqForm.controls.content.setValue(faq.content);
              this.faqForm.controls.position.setValue(faq.position);
              this.faqForm.controls.category_id.setValue(faq.category_id);
              this.faqForm.controls.status.setValue(faq.status);
            }
          }
        );
      }
   });

   
  
  }
  onFormSubmit()
  {
    if(this.faq_id){
      if(this.faqForm.valid){
        this.faqService.update(this.faq_id, this.faqForm.value).subscribe(
          resp => {
            if(resp){
              this.errorMessage.showSuccess(`El faq ha sido editado`);
              this.router.navigate(['/backoffice/settings/faq_overview/faq_list']);
            }
          }, 
          error => { 
              this.errorMessage.showError(error.error.error.show_message);
          }
        );
      }
    }else{
      if(this.faqForm.valid){
        this.faqService.create(this.faqForm.value).subscribe(
          resp => {
            if(resp){
              this.errorMessage.showSuccess(`El faq ha sido creado`);
              this.faqForm.reset();
            }
          }, 
          error => { 
              this.errorMessage.showError(error.error.error.show_message);
          }
        );
      }
    }
  }


}
