<input
    type="text"
    [name]="fieldName"
    [value]="dateInputValue"
    daterangepicker
    [options]="settings"
    (applyDaterangepicker)="selectedDate($event)"
    placeholder="Choose date"
    (cancelDaterangepicker)="clearCalendar($event)"
    autocomplete="off"
    readonly
/>
