<input
    type="text"
    [name]="fieldName"
    [value]="dateInputValue"
    daterangepicker
    [options]="settings"
    placeholder="Choose date"
    (applyDaterangepicker)="selectedDate($event)"
    (cancelDaterangepicker)="clearCalendar($event)"
    autocomplete="off"
    readonly
    [disabled]="disabled"
/>
