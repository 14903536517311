import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { EmailService } from '../../../../services/email.service';
import { TEMPLATE_ACTIVATION_ENUM } from '../../../../../shared/enums/template-activation.enum';

@Component({
  selector: 'app-mail-message-types-list',
  templateUrl: './mail-message-types-list.component.html',
  styleUrls: ['./mail-message-types-list.component.scss'],
})
export class MailMessageTypesListComponent implements OnInit, OnDestroy {
  constructor(private emailService: EmailService) {}

  requestSubscriber: SubscriptionLike;
  dataSubscriber: SubscriptionLike;

  loading = true;
  tableData = [];

  type = TEMPLATE_ACTIVATION_ENUM.MESSAGE_TYPE;

  ngOnInit(): void {
    this.requestData();
  }

  ngOnDestroy() {
    if (this.dataSubscriber) {
      this.dataSubscriber.unsubscribe();
    }
    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
    }

    this.emailService.clearMessagesTypes();
  }

  requestData() {
    this.requestSubscriber = this.emailService
      .getMessagesTypesRequest()
      .subscribe((response) => {
        this.setData(response);
        this.subscribeToData();
      });
  }

  subscribeToData() {
    this.dataSubscriber = this.emailService
      .getMessagesTypes()
      .subscribe((response) => {
        this.tableData = response;
        if (this.loading) {
          this.loading = false;
        }
      });
  }

  setData(data) {
    this.emailService.setMessagesTypes(data);
  }

  formatDate(stringDate) {
    let formatDateString;
    if (stringDate) {
      formatDateString = new Date(stringDate.replace(/-/g, '/'));
    }
    return formatDateString;
  }

  onPublish(e) {
    this.emailService.updateMessagesTypes(e);
  }
}
