<button
    class="btn btn-sm btn-outline-danger"
    (click)="open(content)"
    [disabled]="disabled"
>
    Cerrar Monitorio
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Cerrar Monitorio</h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
            [disabled]="isSending"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form
            *ngIf="form"
            id="monitoringCloseForm"
            [formGroup]="form"
            autocomplete="off"
            (ngSubmit)="onSubmit()"
        >
            <div class="form-group">
                <label for="end_at">
                    Fecha de Finalización
                    <small class="text-danger">*</small>
                </label>
                <input
                    type="date"
                    class="form-control"
                    id="closed_at"
                    name="closed_at"
                    formControlName="closed_at"
                    appValidDate
                    [ngClass]="{
                        'is-invalid':
                            form.controls.closed_at.touched &&
                            !form.controls.closed_at.valid,
                        'is-valid':
                            form.controls.closed_at.touched &&
                            form.controls.closed_at.valid
                    }"
                    required
                />
            </div>
            <div>
                <label for="comment"> Comentarios </label>
                <textarea
                    type="text"
                    class="form-control"
                    id="comment"
                    name="comment"
                    formControlName="comment"
                    [ngClass]="{
                        'is-invalid':
                            form.controls.comment.touched &&
                            !form.controls.comment.valid,
                        'is-valid':
                            form.controls.comment.touched &&
                            form.controls.comment.valid
                    }"
                    rows="3"
                ></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            *ngIf="form"
            type="submit"
            class="btn btn-primary"
            form="monitoringCloseForm"
            [disabled]="form.invalid || isSending"
        >
            {{ isSending ? 'Guardando...' : 'Cerrar Monitorio' }}
        </button>
    </div>
</ng-template>
