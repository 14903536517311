<div class="row justify-content-between">
    <div class="col-sm-12 col-lg-2">
        <h2>Cash Committed</h2>
    </div>
    <div class="col-sm-6 col-sm-3 offset-lg-4 col-lg-2">
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title text-center">TOTAL USED</h5>
            </div>
            <div class="card-body">
                <p class="text-center">{{ total?.used | currency: 'EUR' }}</p>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-sm-3 col-lg-2">
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title text-center">TOTAL CREDIT LIMIT</h5>
            </div>
            <div class="card-body">
                <p class="text-center">{{ total?.credit_limit | currency: 'EUR' }}</p>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-sm-3 col-lg-2">
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="card-header-title text-center">TOTAL COMMITTED</h5>
            </div>
            <div class="card-body">
                <p class="text-center">{{ total?.committed | currency: 'EUR' }}</p>
            </div>
        </div>
    </div>
</div>
<div>
    Displaying {{ list?.from ? list?.from : '00' }}-{{ list?.to ? list?.to : '00' }} of
    {{ list?.total ? list?.total : '00' }} results
</div>
<app-pager [perPage]="15" [currentPage]="list?.current_page" [total]="list?.total" (changePage)="getList($event)">
</app-pager>
<div class="table-responsive">
    <table class="table">
        <!-- row -->
        <thead>
            <tr>
                <!-- column -->
                <th>
                    Date
                </th>
                <th>
                    Credit Line
                </th>
                <th>
                    Used
                </th>
                <th>
                    Credit Limit
                </th>
                <th>
                    Available (Committed)
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <input
                        type="text"
                        name="daterangeInput"
                        value="{{ dateInputVal }}"
                        daterangepicker
                        [options]="settings"
                        (selected)="selectedDate($event)"
                        placeholder="Choose date"
                        (keyup.enter)="clearFilter($event)"
                    />
                    <!-- <input  type="date"  (keyup)="getParamsFilter($event)" (keyup.enter)="makeFilter()" placeholder="Created" name="created_at" > -->
                </td>
                <td>
                    <input
                        size="20"
                        type="text"
                        (keyup)="getParamsFilter($event)"
                        (keyup.enter)="makeFilter()"
                        placeholder="Credit Line"
                        name="credit_line_code"
                    />
                </td>
                <td>
                    <!-- <input type="number" (keyup)="getParamsFilter($event)" (keyup.enter)="makeFilter()" placeholder="Used" name="used"> -->
                </td>
                <td>
                    <input
                        type="number"
                        (keyup)="getParamsFilter($event)"
                        (keyup.enter)="makeFilter()"
                        placeholder="Credit Limit"
                        name="credit_limit"
                    />
                </td>
                <td>
                    <!-- <input type="number" (keyup)="getParamsFilter($event)" (keyup.enter)="makeFilter()" placeholder="Available"
            name="limit_amount"> -->
                </td>
            </tr>
            <tr *ngFor="let item of list?.data" [ngClass]="item?.id ? 'tr--green' : ''">
                <td>{{ item?.created_at ? item?.created_at : '-' }}</td>
                <td>
                    <a class="miLineaFontColor" (click)="goto(item.lead_id)">{{ item?.code }}</a>
                </td>
                <td>{{ item?.used | currency: 'EUR' }}</td>
                <td>{{ item?.credit_limit | currency: 'EUR' }}</td>
                <td>{{ item?.available | currency: 'EUR' }}</td>
            </tr>
        </tbody>
    </table>
</div>
<div>
    Displaying {{ list?.from ? list?.from : '00' }}-{{ list?.to ? list?.to : '00' }} of
    {{ list?.total ? list?.total : '00' }} results
</div>
<app-pager [perPage]="15" [currentPage]="list?.current_page" [total]="list?.total" (changePage)="getList($event)">
</app-pager>
