import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PipeTransform,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, SubscriptionLike } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';
import { ErrorMessageService } from '../../../../../shared/error-message.service';
import { HttpService } from '../../../../services/http.service';
import { LegalMonitoringService } from '../../../../services/legal-monitoring.service';

type Province = { id: number; name: string };
type City = { id: number; province_id: number; name: string; zip: string };

@Component({
    selector: 'app-monitoring-edit',
    templateUrl: './monitoring-edit.component.html',
    styleUrls: ['./monitoring-edit.component.scss'],
    providers: [UpperCasePipe, TitleCasePipe],
})
export class MonitoringEditComponent implements OnInit, OnDestroy {
    @Input() proceeding;
    @Output() edited = new EventEmitter<any>();

    formFieldsSubscribers: SubscriptionLike[] = [];

    isCityTypesLoading = false;
    isProvincesLoading = false;
    isCitiesLoading = false;
    isSending = false;

    form: FormGroup;
    cityTypes: { key: number; value: string }[];
    provinces: Province[] = [];
    cities: City[] = [];

    prevProvince: Province;

    prevProceeding = {};

    private formEventOptions = {
        onlySelf: true,
        emitEvent: false,
        emitViewToModelChange: false,
    };

    private upperCaseFields = ['loan_code_str', 'user_personalid'];

    private nameFields = [
        'user_name',
        'user_first_surname',
        'user_second_surname',
    ];

    constructor(
        private fb: FormBuilder,
        private modal: NgbModal,
        private upperCasePipe: UpperCasePipe,
        private titleCasePipe: TitleCasePipe,
        private httpService: HttpService,
        private legalService: LegalMonitoringService,
        private errorService: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.unsubscribeFromFields();
    }

    unsubscribeFromFields() {
        if (this.formFieldsSubscribers && this.formFieldsSubscribers.length) {
            this.formFieldsSubscribers.forEach((subscribtion) =>
                subscribtion.unsubscribe()
            );
        }
    }

    open(content) {
        this.modal
            .open(content, {
                size: 'lg',
                scrollable: true,
                backdrop: 'static',
                keyboard: false,
            })
            .result.then(
                (result) => this.unsubscribeFromFields(),
                (reason) => this.unsubscribeFromFields()
            );

        this.initForm(this.proceeding);
    }

    initForm(proceeding) {
        const {
            loan_code_str,
            loan_activation_at,
            loan_expiration_at,
            loan_due_at,
            loan_amount,
            loan_total_pay,
            loan_amount_paid,
            loan_debt_left,
            loan_debt_left_percent,
            loan_amount_quote,
            loan_debt_late_fee,
            loan_fee,
            user_personalid,
            user_name,
            user_first_surname,
            user_second_surname,
            user_address_street_type_id,
            user_address_street,
            user_address_street_number,
            user_address_street_additional,
            user_address_province_id,
            user_address_province_name,
            user_address_city_id,
            user_address_city_name,
            user_address_zip,
        } = proceeding;

        this.setPrevProceeding(proceeding);

        this.form = this.fb.group({
            loan_code_str: [
                this.upperCasePipe.transform(loan_code_str || ''),
                Validators.required,
            ],
            loan_activation_at: [
                this.formatDate(loan_activation_at || ''),
                Validators.required,
            ],
            loan_expiration_at: [
                this.formatDate(loan_expiration_at || ''),
                Validators.required,
            ],
            loan_due_at: [
                this.formatDate(loan_due_at || ''),
                Validators.required,
            ],
            loan_amount: [
                loan_amount || 0,
                [Validators.required, Validators.min(0)],
            ],
            loan_total_pay: [
                loan_total_pay || 0,
                [Validators.required, Validators.min(0)],
            ],
            loan_amount_paid: [
                loan_amount_paid || 0,
                [Validators.required, Validators.min(0)],
            ],
            loan_debt_left: [
                loan_debt_left || 0,
                [Validators.required, Validators.min(0)],
            ],
            loan_debt_left_percent: [
                loan_debt_left_percent || 0,
                Validators.required,
            ],
            loan_amount_quote: [
                loan_amount_quote || 0,
                [Validators.required, Validators.min(0)],
            ],
            loan_debt_late_fee: [
                loan_debt_late_fee || 0,
                [Validators.required, Validators.min(0)],
            ],
            loan_fee: [loan_fee || 0, [Validators.required, Validators.min(0)]],

            user_personalid: [
                this.upperCasePipe.transform(user_personalid || ''),
                Validators.required,
            ],
            user_name: [
                this.titleCasePipe.transform(user_name || ''),
                Validators.required,
            ],
            user_first_surname: [
                this.titleCasePipe.transform(user_first_surname || ''),
                Validators.required,
            ],
            user_second_surname: [
                this.titleCasePipe.transform(user_second_surname || ''),
            ],
            user_address_street_type_id: [
                user_address_street_type_id || '',
                Validators.required,
            ],
            user_address_street: [
                user_address_street || '',
                Validators.required,
            ],
            user_address_street_number: [
                user_address_street_number || '',
                Validators.required,
            ],
            user_address_street_additional: [
                user_address_street_additional || '',
            ],
            user_address_province: [
                user_address_province_id
                    ? {
                          id: user_address_province_id,
                          name: user_address_province_name,
                      }
                    : '',
                Validators.required,
            ],
            user_address_city: [
                user_address_province_id && user_address_city_id
                    ? {
                          id: user_address_city_id,
                          province_id: user_address_province_id,
                          name: user_address_city_name,
                          zip: user_address_zip,
                      }
                    : '',
                Validators.required,
            ],
        });

        this.applyPipes();

        this.getStreetType();
        this.getProvinces();
    }

    setPrevProceeding(proceeding) {
        this.prevProceeding = {
            loan_code_str: proceeding.loan_code_str,
            loan_activation_at: proceeding.loan_activation_at,
            loan_expiration_at: proceeding.loan_expiration_at,
            loan_due_at: proceeding.loan_due_at,
            loan_amount: proceeding.loan_amount,
            loan_total_pay: proceeding.loan_total_pay,
            loan_amount_paid: proceeding.loan_amount_paid,
            loan_debt_left: proceeding.loan_debt_left,
            loan_debt_left_percent: proceeding.loan_debt_left_percent,
            loan_amount_quote: proceeding.loan_amount_quote,
            loan_debt_late_fee: proceeding.loan_debt_late_fee,
            loan_fee: proceeding.loan_fee,
            user_personalid: proceeding.user_personalid,
            user_name: proceeding.user_name,
            user_first_surname: proceeding.user_first_surname,
            user_second_surname: proceeding.user_second_surname,
            user_address_street_type_id: proceeding.user_address_street_type_id,
            user_address_street: proceeding.user_address_street,
            user_address_street_number: proceeding.user_address_street_number,
            user_address_street_additional:
                proceeding.user_address_street_additional || '',
            user_address_province_id: proceeding.user_address_province_id,
            user_address_city_id: proceeding.user_address_city_id,
            user_address_zip: proceeding.user_address_zip,
        };
    }

    getStreetType() {
        if (this.cityTypes) {
            return;
        }

        this.isCityTypesLoading = true;
        this.form.controls.user_address_street_type_id.disable();
        this.httpService
            .requestInfo()
            .toPromise()
            .then(({ lead_street_type }) => {
                if (lead_street_type) {
                    this.cityTypes = Object.keys(lead_street_type).map(
                        (key) => ({
                            key: +key,
                            value: lead_street_type[key] as string,
                        })
                    );
                }

                this.isCityTypesLoading = false;
                this.form.controls.user_address_street_type_id.enable();
            });
    }

    getProvinces() {
        if (this.provinces.length) {
            return;
        }

        this.isProvincesLoading = true;
        this.form.controls.user_address_province.disable();

        this.httpService.getProvinces().then((response) => {
            this.provinces = response;

            this.isProvincesLoading = false;
            this.form.controls.user_address_province.enable();
        });
    }

    getCities(provinceId: number) {
        this.isCitiesLoading = true;
        this.form.controls.user_address_city.disable();

        this.httpService.getCity(provinceId).then((response) => {
            this.cities = response;

            this.isCitiesLoading = false;
            this.form.controls.user_address_city.enable();
        });
    }

    formatDate(date: string = ''): string {
        return date.includes('/') ? date.split('/').reverse().join('-') : date;
    }

    applyPipes() {
        this.applyPipe(this.upperCaseFields, this.upperCasePipe);
        this.applyPipe(this.nameFields, this.titleCasePipe);

        this.formFieldsSubscribers.push(
            this.form.controls.loan_total_pay.valueChanges.subscribe((val) => {
                const debt_left = this.form.controls.loan_debt_left.value;
                this.form.controls.loan_debt_left_percent.setValue(
                    +val ? (+debt_left / +val).toPrecision(2) : 0
                );
            })
        );

        this.formFieldsSubscribers.push(
            this.form.controls.loan_debt_left.valueChanges.subscribe((val) => {
                const totalPay = this.form.controls.loan_total_pay.value;
                this.form.controls.loan_debt_left_percent.setValue(
                    +totalPay ? (+val / +totalPay).toPrecision(2) : 0
                );
            })
        );

        this.formFieldsSubscribers.push(
            this.form.controls.user_address_province.valueChanges.subscribe(
                (val) => {
                    if (!val) {
                        this.form.controls.user_address_city.disable();
                        this.form.controls.user_address_city.setValue('');
                    } else {
                        if (+this.prevProvince?.id !== +val.id) {
                            this.prevProvince = val;
                            this.getCities(val.id);
                        } else {
                            this.form.controls.user_address_city.enable();
                        }
                    }
                }
            )
        );
    }

    applyPipe(fields: string | string[], pipe: PipeTransform): void {
        const formSubscriber = (field: string) =>
            this.formFieldsSubscribers.push(
                this.form.controls[field].valueChanges.subscribe((val) =>
                    this.form.controls[field].setValue(
                        pipe.transform(val),
                        this.formEventOptions
                    )
                )
            );

        if (Array.isArray(fields) && fields.length) {
            fields.forEach((field) => formSubscriber(field));
        }

        if (typeof fields === 'string') {
            formSubscriber(fields);
        }
    }

    searchProvince: OperatorFunction<string, readonly { id; name }[]> = (
        text$: Observable<string>
    ) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (!term.length) {
                    this.form.controls.user_address_province.setValue('');
                }
                return term.length >= 2;
            }),
            map((term) =>
                this.provinces
                    .filter((province) =>
                        new RegExp(term, 'mi').test(province.name)
                    )
                    .slice(0, 15)
            )
        );

    provinceFormatter = (province: Province) => province.name;

    searchCity: OperatorFunction<string, readonly { id; name }[]> = (
        text$: Observable<string>
    ) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (!term.length) {
                    this.form.controls.user_address_city.setValue('');
                }
                return term.length >= 2;
            }),
            map((term) =>
                this.cities
                    .filter(({ name, zip }) =>
                        new RegExp(term, 'mi').test(`${zip} · ${name}`)
                    )
                    .slice(0, 15)
            )
        );

    cityFormatter = ({ name, zip }: City) => (zip ? `${zip} · ${name}` : '');

    onSubmit() {
        const {
            user_address_city,
            user_address_province,
            user_address_street_number,
            user_address_street_additional,
            loan_debt_left_percent,
            ...rawParams
        } = this.form.getRawValue();
        const params = {
            ...rawParams,
            loan_debt_left_percent: +loan_debt_left_percent,
            user_address_street_number: `${user_address_street_number}`,
            user_address_street_additional: `${user_address_street_additional}`,
            user_address_city_id: user_address_city.id,
            user_address_province_id: user_address_province.id,
            user_address_zip: user_address_city.zip,
        };

        if (this.isEqual(this.prevProceeding, params)) {
            return;
        } else {
            Object.keys(params).forEach((key) => {
                if (typeof params[key] === 'string') {
                    params[key] = params[key].trim();
                }
                if (params[key] === null || params[key] === undefined) {
                    delete params[key];
                }
            });
            this.legalService
                .editProceeding(this.proceeding.id, params)
                .then((response) => {
                    this.setPrevProceeding(response);
                    this.edited.emit({
                        ...this.prevProceeding,
                        user_address_city_name: response.user_address_city_name,
                        user_address_province_name:
                            response.user_address_province_name,
                    });
                    this.errorService.showSuccess('Guardando con Éxito.');
                    this.form.enable();
                })
                .catch((err) => {
                    const { show_message, details } = err?.error?.error;
                    const message =
                        details && Object.keys(details).length
                            ? Object.keys(details)
                                  .map((key) => details[key].join('<br />'))
                                  .join('<br />')
                            : '';

                    this.errorService.showError(
                        message,
                        show_message || 'Service Error',
                        { timeOut: 6000, enableHtml: true }
                    );
                    this.isSending = false;
                    this.form.enable();
                });
        }
    }

    isEqual(prevModel, newModel) {
        return (
            JSON.stringify(this.sortObjectByKeys(prevModel)) ===
            JSON.stringify(this.sortObjectByKeys(newModel))
        );
    }

    sortObjectByKeys(o) {
        return Object.keys(o)
            .sort()
            .reduce((r, k) => ((r[k] = o[k]), r), {});
    }
}
