import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormatPhoneNumberService } from '../../../../services/format-phone-number.service';
import { EmailService } from '../../../../services/email.service';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';

@Component({
    selector: 'app-messages-list',
    templateUrl: './messages-list.component.html',
    styleUrls: ['./messages-list.component.scss'],
})
export class MessagesListComponent implements OnInit, OnDestroy {
    @Input() userID;
    subscriber: SubscriptionLike;
    tableData;
    loading = true;
    params = {};

    constructor(
        private formatPhoneService: FormatPhoneNumberService,
        private emailService: EmailService
    ) {}

    ngOnInit(): void {
        if (this.userID) {
            this.params = { user_id: this.userID };
            this.getTableData(this.params);
        }
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    getTableData(params) {
        this.subscriber = this.emailService
            .getMessagesRequest(params)
            .subscribe((resp) => {
                this.tableData = resp;
                this.loading = false;
            });
    }

    getDataOnPageChange(page) {
        this.params['page'] = page;

        this.getTableData(this.params);
    }

    formatPhone(phone) {
        if (phone) {
            return this.formatPhoneService.format(phone);
        }
    }

    formatDate(stringDate) {
        let formatDateString;
        if (stringDate) {
            formatDateString = new Date(stringDate.replace(/-/g, '/'));
        }
        return formatDateString;
    }
}
