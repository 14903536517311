import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../../../../shared/error-message.service';
import { LegalMonitoringService } from '../../../../../../services/legal-monitoring.service';

@Component({
    selector: 'app-presentation-attachments-add',
    templateUrl: './presentation-attachments-add.component.html',
    styleUrls: ['./presentation-attachments-add.component.scss'],
})
export class PresentationAttachmentsAddComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() proceedingId: number;
    @Input() presentationId: number;

    @Output() added = new EventEmitter<any>();

    form: FormGroup;
    typeForm: FormGroup;
    isSending = false;
    isTypeSending = false;

    types = [];
    isTypesLoading = false;

    isCollapsed = true;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private legalMonitoringService: LegalMonitoringService,
        private errorService: ErrorMessageService
    ) {}

    ngOnInit(): void {}

    open(content) {
        this.modalService.open(content, {
            backdrop: 'static',
            scrollable: true,
        });

        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            proceeding_id: [this.proceedingId, Validators.required],
            presentation_id: [this.presentationId, Validators.required],
            type_id: ['', Validators.required],
            name: ['', Validators.required],
            description: ['', Validators.required],
            attachment: ['', Validators.required],
            attachmentFile: ['', Validators.required],
        });

        this.typeForm = this.fb.group({
            name: ['', Validators.required],
            description: [''],
        });

        this.typeForm.disable();

        if (!this.types.length) {
            this.getTypes();
        }
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.patchValue({
                attachmentFile: file,
            });
        }
    }

    getTypes() {
        this.isTypesLoading = true;
        this.form.controls.type_id.disable();
        this.legalMonitoringService.getAttachmentsType().then((response) => {
            this.types = response;
            this.isTypesLoading = false;
            this.form.controls.type_id.enable();
        });
    }

    onSubmit(): void {
        this.isSending = true;
        this.form.disable();
        const { attachment, attachmentFile, ...params } = this.form.value;

        params.attachment = attachmentFile;

        const formData = new FormData();

        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'string') {
                params[key] = params[key].trim();
            }
            if (
                params[key] === null ||
                params[key] === undefined ||
                params[key] === ''
            ) {
                delete params[key];
            }
            const value = params[key];
            formData.append(key, value);
        });

        this.legalMonitoringService
            .sendAttachment(formData)
            .then((response) => {
                this.errorService.showSuccess('Guardando con Éxito.');
                this.isSending = false;
                this.added.emit(response);
                this.form.reset();
                this.form.enable();
            })
            .catch(this.showErrors);
    }

    private showErrors = (err) => {
        const { show_message, details } = err?.error?.error;
        const message =
            details && Object.keys(details).length
                ? Object.keys(details)
                      .map((key) => details[key].join('<br />'))
                      .join('<br />')
                : '';

        this.errorService.showError(message, show_message || 'Service Error', {
            timeOut: 6000,
            enableHtml: true,
        });
        this.isSending = false;
        this.form.enable();
    };

    private showTypesErrors = (err) => {
        const { show_message, details } = err?.error?.error;
        const message =
            details && Object.keys(details).length
                ? Object.keys(details)
                      .map((key) => details[key].join('<br />'))
                      .join('<br />')
                : '';

        this.errorService.showError(message, show_message || 'Service Error', {
            timeOut: 6000,
            enableHtml: true,
        });
        this.isTypeSending = false;
        this.typeForm.enable();
    };

    collapseToggle = () => {
        this.isCollapsed = !this.isCollapsed;
        if (!this.isCollapsed) {
            this.form.disable();
            this.typeForm.enable();
        } else {
            this.form.enable();
            this.typeForm.disable();
        }
    };

    onSubmitType() {
        this.isTypeSending = true;
        this.typeForm.disable();

        const params = this.typeForm.getRawValue();
        this.legalMonitoringService
            .saveAttachmentsType(params)
            .then((response) => {
                this.types.push(response);
                this.form.controls.type_id.patchValue(response.id);
                this.form.controls.type_id.markAsUntouched();
                this.errorService.showSuccess('Guardando con Éxito.');
                this.typeForm.reset();
                this.typeForm.enable();
                this.isTypeSending = false;
            })
            .catch(this.showTypesErrors);
    }
}
