<div class="container ml-0">
    <div *ngIf="isLoading" class="text-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div *ngIf="!isLoading && trialProceeding" class="d-flex flex-wrap">
        <div class="w-100 d-flex align-items-center mb-3">
            <app-monitoring-edit
                *ngIf="
                    +trialProceeding?.status?.id !==
                        +TRIAL_PROCEEDING_STATUS.SUCCESSFUL &&
                    +trialProceeding?.status?.id !==
                        +TRIAL_PROCEEDING_STATUS.FAILURE
                "
                class="mr-3"
                [proceeding]="trialProceeding"
                (edited)="onProceedingEdited($event)"
            ></app-monitoring-edit>
            <h6 class="mb-0">
                Referencia Monitorio:
                {{ trialProceeding?.reference_code || '-' }}
            </h6>
            <app-monitoring-close
                *ngIf="
                    +trialProceeding?.status?.id ===
                        +TRIAL_PROCEEDING_STATUS.SUBMITTED &&
                    trialProceeding?.presentation?.closed
                "
                class="ml-auto"
                [id]="trialProceeding.id"
                [disabled]="isSending"
                (closed)="onProceedingClosed($event)"
            ></app-monitoring-close>
        </div>

        <div class="card mr-3 mb-3">
            <div class="card-header px-3">
                <h5 class="card-header-title text-center">Loan</h5>
            </div>
            <div class="card-body text-center">
                <a
                    *ngIf="loan"
                    [routerLink]="[
                        '/backoffice/loans/overview/' +
                            loan.lead.id +
                            '/loanInfo/' +
                            loan.lead.id
                    ]"
                    >{{ trialProceeding?.loan_code_str }}</a
                >
            </div>
        </div>

        <div class="card mr-3 mb-3">
            <div class="card-header px-3">
                <h5 class="card-header-title text-center">Importe</h5>
            </div>
            <div class="card-body text-center">
                {{ trialProceeding?.loan_amount | currency: 'EUR' }}
            </div>
        </div>

        <div class="card mr-3 mb-3">
            <div class="card-header px-3">
                <h5 class="card-header-title text-center">Total a pagar</h5>
            </div>
            <div class="card-body text-center">
                {{ trialProceeding?.loan_total_pay | currency: 'EUR' }}
            </div>
        </div>

        <div class="card mr-3 mb-3">
            <div class="card-header px-3">
                <h5 class="card-header-title text-center">Pagado</h5>
            </div>
            <div class="card-body text-center">
                {{ trialProceeding?.loan_amount_paid | currency: 'EUR' }}
            </div>
        </div>

        <div class="card mr-3 mb-3">
            <div class="card-header px-3">
                <h5 class="card-header-title text-center">Pendiente</h5>
            </div>
            <div class="card-body text-center">
                {{ trialProceeding?.loan_debt_left | currency: 'EUR' }}
            </div>
        </div>

        <div class="precent-circle align-self-center mb-3">
            {{
                trialProceeding?.loan_debt_left_percent
                    ? (trialProceeding?.loan_debt_left_percent
                      | percent: '1.0-2':'es')
                    : '-%'
            }}
        </div>

        <div class="card ml-auto mb-3">
            <div class="card-header px-3">
                <h5 class="card-header-title text-center">Status Legal</h5>
            </div>
            <div class="card-body text-center">
                {{ trialProceeding?.status?.name }}
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading && trialProceeding" class="row">
        <div class="col-12 col-lg-6 mb-3">
            <div class="card mb-3">
                <div
                    class="card-header px-3 d-flex justify-content-between align-items-center"
                >
                    <h5 class="card-header-title">Datos Cliente</h5>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-6 d-flex justify-content-between mb-2">
                            <strong>Nombre</strong>
                            {{ trialProceeding?.user_name }}
                        </div>
                        <div class="col-6 d-flex justify-content-between mb-2">
                            <strong>DNI/NIE</strong>
                            {{ trialProceeding?.user_personalid }}
                        </div>
                        <div class="col-6 d-flex justify-content-between">
                            <strong>Appelido 1</strong>
                            {{ trialProceeding?.user_first_surname }}
                        </div>
                        <div class="col-6 d-flex justify-content-between">
                            <strong>Appelido 2</strong>
                            {{ trialProceeding?.user_second_surname }}
                        </div>
                    </div>
                </div>
                <div class="card-header px-3 bg-secondary">
                    <h5 class="card-header-title">Dirección Inicial</h5>
                </div>
                <div class="card-body p-3">
                    <div class="d-flex justify-content-between mb-2">
                        <strong>Tipo de Calle</strong>
                        {{ trialProceeding?.user_address_street_type_name }}
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <strong>Calle</strong>
                        {{ trialProceeding?.user_address_street }}
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <strong>Numéro</strong>
                        {{ trialProceeding?.user_address_street_number }}
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <strong>Dato Adicional</strong>
                        {{ trialProceeding?.user_address_street_additional }}
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <strong>Provincia</strong>
                        {{ trialProceeding?.user_address_province_name }}
                    </div>
                    <div class="d-flex justify-content-between">
                        <strong>Población · Código Postal</strong>
                        {{ trialProceeding?.user_address_city_name }} ·
                        {{ trialProceeding?.user_address_zip }}
                    </div>
                </div>
                <div class="card-header px-3 bg-secondary">
                    <h5 class="card-header-title">Docs</h5>
                </div>
                <div *ngIf="loan" class="card-body p-3">
                    <app-monitoring-personal-docs
                        [loan]="loan"
                    ></app-monitoring-personal-docs>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-6 mb-3">
            <!-- <div>
               
                <div class="form-row mb-3">
                    <div class="col-6">
                        <button
                            class="btn btn-success w-100"
                            (click)="closeProceeding(true)"
                            [disabled]="isSending"
                        >
                            con Éxito
                        </button>
                    </div>
                    <div class="col-6"></div>
                </div>
            </div> -->

            <div class="form-row mb-3">
                <div class="col-6">
                    <app-presentation-create
                        *ngIf="
                            +trialProceeding?.status?.id ===
                                +TRIAL_PROCEEDING_STATUS.NEW ||
                            +trialProceeding?.status?.id ===
                                +TRIAL_PROCEEDING_STATUS.NO_SUBMITTED ||
                            (+trialProceeding?.status?.id ===
                                +TRIAL_PROCEEDING_STATUS.SUBMITTED &&
                                trialProceeding?.presentation?.closed)
                        "
                        class="w-100"
                        (changed)="onPresentationChanged($event)"
                        [proceedingId]="trialProceeding.id"
                    >
                    </app-presentation-create>
                </div>
                <div class="col-6">
                    <button
                        *ngIf="
                            +trialProceeding?.status?.id ===
                                +TRIAL_PROCEEDING_STATUS.NEW ||
                            (+trialProceeding?.status?.id ===
                                +TRIAL_PROCEEDING_STATUS.SUBMITTED &&
                                trialProceeding?.presentation?.closed)
                        "
                        class="btn btn-outline-primary w-100"
                        (click)="toNotPresent(+trialProceeding?.id)"
                    >
                        No Presentar
                    </button>
                </div>
            </div>

            <div *ngIf="trialProceeding?.presentation" class="card mb-3">
                <div class="card-header d-flex align-items-center">
                    <h5 class="card-header-title">Última presentación</h5>
                    <app-presentation-create
                        *ngIf="!trialProceeding?.presentation?.closed"
                        class="ml-auto"
                        (changed)="onPresentationChanged($event)"
                        [action]="'close'"
                        [proceedingId]="trialProceeding.id"
                        [presentation]="trialProceeding.presentation"
                    >
                    </app-presentation-create>
                    <app-presentation-create
                        *ngIf="!trialProceeding?.presentation?.closed"
                        class="ml-3"
                        (edited)="onPresentationEdited($event)"
                        [action]="'edit'"
                        [proceedingId]="trialProceeding.id"
                        [presentation]="trialProceeding.presentation"
                    >
                    </app-presentation-create>
                    <span
                        *ngIf="trialProceeding?.presentation?.closed"
                        class="ml-auto badge badge-danger"
                        >Terminado</span
                    >
                </div>
                <div class="card-body p-3">
                    <app-presentation-show
                        [presentation]="trialProceeding.presentation"
                    ></app-presentation-show>
                </div>
            </div>
        </div>

        <div class="col-12">
            <app-presentation-list
                *ngIf="trialProceeding.presentation_id"
                [proceedingId]="trialProceeding.id"
                [presentationId]="trialProceeding.presentation_id"
            ></app-presentation-list>
        </div>
    </div>

    <div
        *ngIf="trialProceedingLoadingError.show"
        class="alert alert-warning fade show"
        role="alert"
    >
        <h6>{{ trialProceedingLoadingError.title }}</h6>
        <p>{{ trialProceedingLoadingError.message }}</p>
    </div>
</div>
