import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'app-lead-info-advising-validation',
    templateUrl: './lead-info-advising-validation.component.html',
    styleUrls: ['./lead-info-advising-validation.component.css']
})
export class LeadInfoAdvisingValidationComponent implements OnInit, OnChanges {
    @Input() validation_advising_antifrauds: any[] = null;
    validation_advising_antifraud_list = [];
    validator_id: any = 0;
    process = true;
    constructor() {}

    ngOnInit() {}
    ngOnChanges() {
        if (this.validation_advising_antifrauds) {
            this.validation_advising_antifraud_list = this.setAdvisingList(this.validation_advising_antifrauds);
            this.process = false;
        }
    }

    setAdvisingList(antifrauds) {
        const filteredList = [];

        antifrauds.map(item => {
            let validatorId = item.config_validator_advising_antifraud_id;
            filteredList[validatorId] = {
                name: item.validator.description,
                list: antifrauds.filter(item => item.config_validator_advising_antifraud_id === validatorId)
            };
        });

        return filteredList.filter(item => item);
    }
}
