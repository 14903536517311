import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-backoffice-underwriting',
    templateUrl: './backoffice-underwriting.component.html',
    styleUrls: ['./backoffice-underwriting.component.css', '../../backoffice-home/backoffice-home.component.css']
})
export class BackofficeUnderwritingComponent implements OnInit, DoCheck {
    show: boolean;

    constructor(private router: Router, private auth: AuthService) {}

    ngDoCheck() {
        if (window.location.href.match('.backoffice/underwriting$')) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    ngOnInit() {}

    showPanel() {
        return this.show;
    }

    goto(route) {
        this.show = false;
        this.router.navigate(['/backoffice/underwriting/leads', route]);
    }

    gotoCL() {
        this.router.navigate(['/backoffice/loans']);
    }
}
