import { Component, OnInit, Input } from '@angular/core';
import { DataPagesMenu } from '../../../../../shared/models/data-pages-menu';

@Component({
    selector: 'app-data-page-nav',
    templateUrl: './data-page-nav.component.html',
    styleUrls: ['./data-page-nav.component.scss']
})
export class DataPageNavComponent implements OnInit {
    @Input() itemsList: DataPagesMenu;

    constructor() {}

    ngOnInit() {}
}
